import _ from 'lodash';
import * as chartSelectors from '../../../state/selectors/chart';
import { ENTITY_PROPERTY } from '../../../utils/constants';
import createBucketedDataSelector from './createBucketedDataSelector';
import createDataSelector from './createDataSelector';

export default createDataSelector(
  createBucketedDataSelector(chartSelectors.getOutputData),
  chartSelectors.getOutputProperties,
  _.constant({}),
  chartSelectors.getOutputAlarmRanges,
  chartSelectors.getOutputEnumerations,
  properties =>
    _.get(properties, ENTITY_PROPERTY.MODEL.OUTPUT.UNITS_OF_MEASUREMENT) === '%' ? 100 : 1
);