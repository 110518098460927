import { COLOUR } from 'utils/constants';

export default {
  labelFlex: {
    display: 'flex'
  },
  progress: {
    width: '6em',
    display: 'inline-block',
    margin: '1px',
    marginLeft: '1em'
  },
  progressFlex: {
    flexGrow: 1,
    display: 'inline-block',
    margin: '3px 0px 3px 1em'
  },
  iconButton: {
    margin: '0em 0em 0em 0.75em'
  },
  iconButtonPopup: {
    background: COLOUR.MEDIUM_GREY,
    border: '1px solid white'
  }
};