import _ from 'lodash';
import { Fragment, useEffect } from 'react';
import { Divider } from 'semantic-ui-react';
import IsInRole from '../../../containers/auth/IsInRole';
import EntityPaginationContainer from '../../../containers/entity/EntityPaginationContainer';
import EntityTableContainer from '../../../containers/entity/EntityTableContainer';
import { ROLE } from '../../../utils/constants';
import AdminButtons from './AdminButtons';
import SearchSegment from './SearchSegment';

const parseQuery = _.flow(decodeURIComponent, JSON.parse);

const EntityPage = ({
  isLoading,
  toggleModal,
  entityType,
  form,
  createEntity,
  isConnector,
  addEnabled,
  importEnabled,
  additionalImports,
  searchEnabled,
  searchAddEntityTypes,
  urlQuery,
  formQuery,
  page,
  parentId,
  fetchTypes,
  labels,
  isOnPage,
  fetchLabels,
  fetchConditionTypes,
  fetchPage,
  onSearch,
  onClearSearch,
  onSearchAndDelete,
  onToggleOtherEntityModal
}) => {
  useEffect(() => {
    fetchTypes(parentId);
    labels && fetchLabels();
    fetchConditionTypes();
  }, [labels, fetchConditionTypes, fetchLabels, fetchTypes, parentId]);

  useEffect(() => {
    if (isOnPage) {
      fetchPage(page || 0, parentId, parseQuery(urlQuery));
    };
  }, [page, urlQuery, fetchPage, parentId, isOnPage]);

  return (
    <Fragment>
      <IsInRole role={ROLE.ADMIN}>
        <AdminButtons
          entityType={entityType}
          form={form}
          createEntity={createEntity}
          isConnector={isConnector}
          parentId={parentId}
          addEnabled={addEnabled}
          importEnabled={importEnabled}
          additionalImports={additionalImports}
          onToggleModal={toggleModal}
          onToggleOtherEntityModal={onToggleOtherEntityModal} />
      </IsInRole>
      <div className='page-content'>
        {searchEnabled && (
          <SearchSegment
            entityType={entityType}
            parentId={parentId}
            isLoading={isLoading}
            clearButtonDisabled={!urlQuery}
            searchAddEntityTypes={searchAddEntityTypes}
            formQuery={formQuery}
            onSearch={onSearch}
            onClearSearch={onClearSearch}
            onToggleModal={toggleModal}
            onSearchAndDelete={onSearchAndDelete}
            onToggleOtherEntityModal={onToggleOtherEntityModal} />
        )}
        <EntityTableContainer
          entityType={entityType}
          parentId={parentId} />
        <Divider hidden />
        <EntityPaginationContainer
          entityType={entityType}
          parentId={parentId}
          page={page} />
      </div>
    </Fragment>
  );
};

EntityPage.displayName = 'EntityPage';

export default EntityPage;