import { COLOUR, LINE_CHART_YAXIS_WIDTH } from '../../../utils/constants';

export default {
  timelineWrapper: {
    paddingLeft: `${LINE_CHART_YAXIS_WIDTH}px`
  },
  chartSegment: {
    minHeight: '500px',
    padding: 0,
    marginLeft: `${LINE_CHART_YAXIS_WIDTH}px`,
    marginTop: 0
  },
  chartTable: {
    borderSpacing: '2px'
  },
  popup: {
    background: COLOUR.MEDIUM_GREY,
    border: '1px solid white'
  },
  gaugeWrapper: {
    display: 'flex',
    justifyContent: 'center'
  },
  alarmLevelsDiagramWrapper: {
    display: 'flex',
    justifyContent: 'center'
  },
  cell: colour => ({
    background: colour,
    padding: '1.5rem 0 0 0'
  }),
  highlightedCell: colour => ({
    background: colour,
    padding: '1.5rem 0 0 0',
    boxShadow: 'inset 0 0 100px 100px rgba(255, 255, 255, 0.4)'
  })
};