import { connect } from 'react-redux';
import Breadcrumbs from '../../components/navigation/Breadcrumbs';
import * as fromState from '../../state/reducers';
import * as dashboardSelectors from '../../state/selectors/dashboard';
import { ENTITY_TYPE } from '../../utils/constants';
import { createSelector } from 'reselect';

const mapStateToProps = createSelector(
  [
    state => state.router.location.pathname,
    fromState.getUnitPath,
    dashboardSelectors.getUnits,
    state => fromState.getAll(state, ENTITY_TYPE.TREND)
  ],
  (pathname, unitPath, units, trends) => ({
    pathname,
    unitPath,
    units,
    trends
  })
);

export default connect(mapStateToProps)(Breadcrumbs);