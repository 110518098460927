import _ from 'lodash';
import { createSelector } from 'reselect';
import * as fromState from '../../../state/reducers';
import { ATTRIBUTE_SOURCE, ENTITY_TYPE } from '../../constants';
import { getUnitTypeId } from 'state/selectors/dashboard';

const getAttributes = (allUnitTypes, allUnitTypeAttributes, unitTypeId) => {
  const unitType = allUnitTypes[unitTypeId];
  return unitType
    ? [
      ..._.filter(allUnitTypeAttributes, attr => attr.unitType === unitTypeId),
      ...getAttributes(allUnitTypes, unitType.baseType)
    ]
    : [];
};

const getParameters = (allUnitTypes, allUnitTypeParameters, unitTypeId) => {
  const unitType = allUnitTypes[unitTypeId];
  return unitType
    ? [
      ..._.filter(allUnitTypeParameters, attr => attr.unitType === unitTypeId),
      ...getAttributes(allUnitTypes, unitType.baseType)
    ]
    : [];
};

export default createSelector(
  [
    state => fromState.getAll(state, ENTITY_TYPE.UNIT_TYPE),
    state => fromState.getAll(state, ENTITY_TYPE.UNIT_TYPE_ATTRIBUTE),
    state => fromState.getAll(state, ENTITY_TYPE.UNIT_TYPE_PARAMETER),
    state => getUnitTypeId(state)
  ],
  (allUnitTypes, allUnitTypeAttributes, allUnitTypeParameters, unitTypeId) => {
    const unitTypeAttributes = _.map(
      getAttributes(allUnitTypes, allUnitTypeAttributes, unitTypeId),
      attribute => ({
        ...attribute,
        value: attribute.name
      })
    );

    const unitTypeParameters = _.map(
      getParameters(allUnitTypes, allUnitTypeParameters, unitTypeId),
      parameter => ({
        ...parameter,
        value: parameter.name
      })
    );

    return {
      inputs: unitTypeAttributes,
      parameters: unitTypeParameters,
      attributes: [
        ..._.map(unitTypeAttributes, input => ({
          ...input,
          source: ATTRIBUTE_SOURCE.UNIT_TYPE_ATTRIBUTE
        })),
        ..._.map(unitTypeParameters, input => ({
          ...input,
          source: ATTRIBUTE_SOURCE.UNIT_TYPE_PARAMETER
        }))
      ]
    };
  }
);