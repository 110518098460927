import { ofType } from 'redux-observable';
import { EDIT_FUNCTION } from '../../actions/chart';
import { map, withLatestFrom } from 'rxjs/operators';
import * as chartSelectors from '../../selectors/chart';
import { edit } from '../../actions/entity';

export default (action$, state$) => action$.pipe(
  ofType(EDIT_FUNCTION),
  withLatestFrom(state$),
  map(([action, state]) => {
    const predictor = chartSelectors.getPredictor(state, action.chartType);
    const functionMappings = predictor.functionMappings;
    const editedFunctionMappings = [
      ...functionMappings.slice(0, action.payload.index),
      {
        ...functionMappings[action.payload.index],
        function: action.payload.description
      },
      ...functionMappings.slice(action.payload.index + 1)
    ];
    return edit('predictor', {
      ...predictor,
      functionMappings: editedFunctionMappings 
    });
  })
);
