import { PERFORM_AUTH_ERROR, PERFORM_AUTH_FULFILLED, AUTH_FORM_VALUE_CHANGED } from '../../../actions/auth';
import _ from 'lodash';

export default (state = [], action) => {
  switch (action.type) {
    case PERFORM_AUTH_ERROR:
      return _.chain(action.payload.err).get('response.errors').keys().value();
    case PERFORM_AUTH_FULFILLED:
      return [];
    case AUTH_FORM_VALUE_CHANGED:
      return _.without(state, action.payload.key);
    default:
      return state;
  }
};

export const getErrorKeys = state => state;