import _ from 'lodash';
import { createSelector } from 'reselect';
import * as fromState from '../../../state/reducers';
import { ATTRIBUTE_SOURCE, ENTITY_PROPERTY, ENTITY_TYPE } from '../../constants';

export default createSelector(
  [
    (state, { parentId }) => fromState.getById(state, ENTITY_TYPE.PREDICTOR, parentId),
    state => fromState.getAll(state, ENTITY_TYPE.MODEL),
    state => fromState.getAll(state, ENTITY_TYPE.SENSOR)
  ],
  (predictor, allModels, allSensors) => ({
    attributes: [
      ..._.chain(allSensors)
        .values()
        .filter(sensor => predictor && sensor.unitId === predictor.unitId)
        .map(sensor => ({
          id: `${ATTRIBUTE_SOURCE.SENSOR}_${sensor.id}`,
          text: sensor.name,
          description: sensor.description,
          valueType: sensor.valueType,
          enumeration: sensor.enumeration,
          unitsOfMeasurement: sensor.unitsOfMeasurement,
          source: ATTRIBUTE_SOURCE.SENSOR
        }))
        .value(),
      ..._.chain(allModels)
        .get(predictor && predictor.type)
        .get(ENTITY_PROPERTY.MODEL.OUTPUTS)
        .map(output => ({
          source: ATTRIBUTE_SOURCE.MODEL_OUTPUT,
          ...output,
          id: `${ATTRIBUTE_SOURCE.MODEL_OUTPUT}_${output.name}`,
          text: output.name
        }))
        .value()
    ]
  })
);