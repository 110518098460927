import fetchPage from './fetchPage';
import debounceFormSearch from './debounceFormSearch';
import fetchFormSearchResults from './fetchFormSearchResults';
import deleteById from './deleteById';
import fetchTypes from './fetchTypes';
import fetchLabels from './fetchLabels';
import fetchAll from './fetchAll';
import fetchEntitiesOnLoad from './fetchEntitiesOnLoad';
import add from './add';
import edit from './edit';
import fetchById from './fetchById';
import fetchConditionTypes from './fetchConditionTypes';
import navigateOrOpenOnAdd from './navigateOrOpenOnAdd';
import navigateOnCloseAccessTokenModal from './navigateOnCloseAccessTokenModal';
import hideModalOnAdd from './hideModalOnAdd';
import hideModalOnEdit from './hideModalOnEdit';
import hideAlarmRangesModalOnEdit from './hideAlarmRangesModalOnEdit';
import hideModalOnDelete from './hideModalOnDelete';
import showAccessTokenModalOnAddConnector from './showAccessTokenModalOnAddConnector';
import navigateOnPageChanged from './navigateOnPageChanged';
import fetchParentOnFetchPage from './fetchParentOnFetchPage';
import insertSearchResultsOnFormSearch from './insertSearchResultsOnFormSearch';
import fetchByIdOnFormSearch from './fetchByIdOnFormSearch';
import setFormValuesOnOpenEditModal from './setFormValuesOnOpenEditModal';
import clearFormValuesOnOpenAlternativeEditModal from './clearFormValuesOnOpenAlternativeEditModal';
import clearFormValuesOnOpenAddModal from './clearFormValuesOnOpenAddModal';
import fetchFormSearchResultsOnSetFormValues from './fetchFormSearchResultsOnSetFormValues';
import setAlarmsOnOpenModal from './setAlarmsOnOpenModal';
import editOnSaveAlarmRanges from './editOnSaveAlarmRanges';
import fetchLabelsOnSave from './fetchLabelsOnSave';
import importFile from './importFile';
import clearImportModalOnClose from './clearImportModalOnClose';
import downloadImportTemplate from './downloadImportTemplate';
import performSearch from './performSearch';
import deleteByQuery from './deleteByQuery';
import setDefaultSettingsOnSetType from './setDefaultSettingsOnSetType';
import navigateOnOpenReview from './navigateOnOpenReview';
import fetchSensorsOnAddPredictor from './fetchSensorsOnAddPredictor';
import addUnitTypeAttribute from './addUnitTypeAttribute';
import addUnitTypeParameter from './addUnitTypeParameter';
import deleteUnitTypeAttribute from './deleteUnitTypeAttribute';
import deleteUnitTypeParameter from './deleteUnitTypeParameter';
import setDefaultSensorMappingsOnChangeValueType from './setDefaultSensorMappingsOnChangeValueType';
import { combineEpicsWithNames } from '../../../utils/helpers';

export default combineEpicsWithNames({
  addUnitTypeAttribute,
  addUnitTypeParameter,
  fetchPage,
  debounceFormSearch,
  fetchFormSearchResults,
  deleteById,
  deleteUnitTypeAttribute,
  deleteUnitTypeParameter,
  fetchTypes,
  fetchLabels,
  fetchAll,
  add,
  edit,
  fetchById,
  fetchConditionTypes,
  navigateOrOpenOnAdd,
  navigateOnCloseAccessTokenModal,
  hideModalOnAdd,
  hideModalOnEdit,
  hideAlarmRangesModalOnEdit,
  hideModalOnDelete,
  showAccessTokenModalOnAddConnector,
  navigateOnPageChanged,
  fetchParentOnFetchPage,
  insertSearchResultsOnFormSearch,
  fetchByIdOnFormSearch,
  setFormValuesOnOpenEditModal,
  clearFormValuesOnOpenAlternativeEditModal,
  clearFormValuesOnOpenAddModal,
  fetchFormSearchResultsOnSetFormValues,
  setAlarmsOnOpenModal,
  editOnSaveAlarmRanges,
  fetchLabelsOnSave,
  importFile,
  clearImportModalOnClose,
  downloadImportTemplate,
  performSearch,
  deleteByQuery,
  setDefaultSettingsOnSetType,
  navigateOnOpenReview,
  fetchSensorsOnAddPredictor,
  setDefaultSensorMappingsOnChangeValueType,
  fetchEntitiesOnLoad
});