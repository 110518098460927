import { Fragment } from 'react';
import { Breadcrumb, Icon, Popup } from 'semantic-ui-react';
import entityConfig from '../../utils/entities';
import _ from 'lodash';
import { injectIntl, defineMessages } from 'react-intl';
import { COLOUR, INVERTED_THEME, PAGE_URL } from '../../utils/constants';
import { matchesPath } from '../../utils/helpers';
import PredictorBreadcrumbSectionContainer from '../../containers/navigation/PredictorBreadcrumbSectionContainer';
import { pure } from 'recompose';
import QuerySyncLink from '../../containers/navigation/QuerySyncLink';

const messages = defineMessages({
  'breadcrumb.login': {
    id: 'breadcrumb.login',
    defaultMessage: 'Login'
  },
  'breadcrumb.register': {
    id: 'breadcrumb.register',
    defaultMessage: 'Register'
  },
  'breadcrumb.configuration': {
    id: 'breadcrumb.configuration',
    defaultMessage: 'Configuration'
  },
  'breadcrumb.forgot': {
    id: 'breadcrumb.forgot',
    defaultMessage: 'Forgot Password'
  },
  'breadcrumb.change': {
    id: 'breadcrumb.change',
    defaultMessage: 'Change Password'
  },
  'breadcrumb.join': {
    id: 'breadcrumb.join',
    defaultMessage: 'Join Organisation'
  },
  'breadcrumb.confirm': {
    id: 'breadcrumb.confirm',
    defaultMessage: 'Confirm Email Address'
  },
  'breadcrumb.resend': {
    id: 'breadcrumb.resend',
    defaultMessage: 'Resend Confirmation'
  }
});

const routeNameMessages = _.chain(entityConfig)
  .filter(config => !config.parentEntityType && config.pageUrl)
  .map(config => [`/${config.pageUrl}`, config.messages['title']])
  .fromPairs()
  .extend({
    '/login': messages['breadcrumb.login'],
    '/register': messages['breadcrumb.register'],
    'configuration': messages['breadcrumb.configuration'],
    '/forgot': messages['breadcrumb.forgot'],
    '/change': messages['breadcrumb.change'],
    '/join': messages['breadcrumb.join'],
    '/confirm': messages['breadcrumb.confirm'],
    '/resend': messages['breadcrumb.resend']
  })
  .value();

const routeIcons = _.chain(entityConfig)
  .filter(config => !config.parentEntityType)
  .map(config => [config.pageUrl, config.icon])
  .fromPairs()
  .value();

const BreadcrumbsItem = injectIntl(({
  intl: {
    formatMessage
  },
  url,
  path,
  isExact,
  description,
  icon,
  routeName
}) => {
  const pathEnd = url.split('/').pop();
  const text = routeName || pathEnd;
  const routeNameMessage = routeNameMessages[url] || routeNameMessages[pathEnd];
  const routeIcon = icon || routeIcons[url.substr(1)] || routeIcons[url];
  if (matchesPath(path, PAGE_URL.PREDICTOR)) {
    return (
      <Fragment>
        <Breadcrumb.Divider
          icon='chevron right'
          style={{ color: COLOUR.TEXT }} />
        <PredictorBreadcrumbSectionContainer id={text} />
      </Fragment>
    );
  } else if (text || routeNameMessage) {
    return (
      <Fragment>
        <Breadcrumb.Divider
          icon='chevron right'
          style={{ color: COLOUR.TEXT }} />
        <Breadcrumb.Section
          active
          {...isExact
            ? {}
            : {
              as: QuerySyncLink,
              to: url || ''
            }
          }
          style={{
            display: 'flex',
            alignItems: 'center',
            fontSize: description ? '0.875rem' : '1.125rem'
          }}>
          {routeIcon && (
            <Icon
              name={routeIcon}
              size='small' />
          )}
          <span>
            {routeNameMessage ? formatMessage(routeNameMessage) : text}
            {description && (
              <Popup
                content={description}
                inverted={INVERTED_THEME}
                style={{
                  background: COLOUR.MEDIUM_GREY,
                  border: '1px solid white'
                }}
                trigger={(
                  <div
                    style={{
                      fontSize: '0.75rem',
                      fontWeight: 'normal',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      maxWidth: '10rem'
                    }}>
                    {description}
                  </div>
                )} />
            )}
          </span>
        </Breadcrumb.Section>
      </Fragment>
    );
  }

  return null;
});

BreadcrumbsItem.displayName = 'BreadcrumbsItem';

export default pure(BreadcrumbsItem);