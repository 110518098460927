import { COLOUR, LINE_CHART_YAXIS_WIDTH } from '../../../utils/constants';

export default {
  timelineWrapper: {
    width: '100%',
    paddingLeft: `${LINE_CHART_YAXIS_WIDTH}px`,
    paddingRight: '3px'
  },
  plotSegment: {
    height: '100%',
    padding: 0,
    margin: 0
  },
  plotSegmentLoading: {
    height: '100%',
    padding: 0,
    marginTop: 0,
    marginBottom: 0,
    marginRight: '0px',
    marginLeft: '0px'
  },
  tooltipSegment: {
    background: COLOUR.DARK_GREY,
    fontSize: '.7rem',
    border: '1px solid #777',
    boxShadow: '0 0 5px 5px rgba(0, 0, 0, 0.4)'
  },
  tooltipTableBody: {
    display: 'block',
    maxHeight: '70vh',
    overflowY: 'scroll'
  },
  tooltipEmptyRow: {
    height: '1rem'
  },
  referenceArea: {
    display: 'none'
  },
  resizer: {
    border: '5px solid black',
    cursor: 'row-resize',
    marginLeft: `${LINE_CHART_YAXIS_WIDTH}px`,
    marginRight: '3px'
  },
  tooltipRowIcon: colour => ({
    color: colour
  }),
  plotWrapperDiv: {
    width: '100%',
    height: '100%',
    position: 'relative'
  },
  resetZoomButton: {
    position: 'absolute',
    right: '5px',
    top: '5px',
    zIndex: 1
  },
  mostRecentValue: {
    color: 'darkgray'
  },
  zoomButtonGroup: {
    position: 'absolute',
    top: '10px',
    zIndex: 1
  },
  zoomButton: {
    border: 0
  },
  notesTextArea: {
    borderBottomRightRadius: '0px',
    borderBottomLeftRadius: '0px',
    borderColor: 'white'
  }
};