import { EMPTY_STRING } from '../../../utils/constants';
import { CHANGE_UNIT_PATH } from '../../actions/dashboard';

export default (state: string = EMPTY_STRING, action) => {
  switch (action.type) {
    case CHANGE_UNIT_PATH:
      return action.payload.path;
    default:
      return state;
  }
};

export const getUnitPath = (state: string) => state;