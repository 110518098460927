import { SET_COMPARE_ALL } from '../../../actions/chart';

export default (state = false, action) => {
  switch (action.type) {
    case SET_COMPARE_ALL:
      return action.payload.value;
    default:
      return state;
  }
};

export const getAll = state => state;