import _ from 'lodash';
import queryString from 'query-string';

export default (location, search) => {
  const syncedSearch = _.pick(
    queryString.parse(search),
    ['time', 'mode', 'isPlaying', 'window', 'view', 'dashboard', 'review', 'reviewTrend', 'tenant', 'cardSize', 'showCardDetails']
  );
  return _.isObject(location)
    ? {
      ...location,
      search: queryString.stringify({
        ...syncedSearch,
        ...queryString.parse(location.search)
      })
    }
    : {
      pathname: location,
      search: queryString.stringify(syncedSearch)
    };
};