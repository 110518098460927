import moment from 'moment';
import createCachedSelector from 're-reselect';
import { DATE_FORMAT } from '../../../../utils/constants';
import * as fromState from '../../../reducers';

export const getIsoToDate = createCachedSelector(
  [
    (state, chartType) => fromState.getToDate(state, chartType)
  ],
  toDate => moment.utc(toDate, DATE_FORMAT).toISOString()
)(
  (state, chartType) => chartType
);