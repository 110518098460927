import { Action } from 'types';
import { SET_HOVER_LINE } from '../../../../actions/chart';

export type HoverLineState = string | null;

interface SetHoverLinePayload {
  line: HoverLineState;
}

export default (
  state: HoverLineState = null,
  action: Action<SetHoverLinePayload>
): HoverLineState => {
  switch (action.type) {
    case SET_HOVER_LINE:
      return action.payload.line;
    default:
      return state;
  }
};

export const getHoverLine = (state: HoverLineState): HoverLineState => state;