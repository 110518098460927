import ChartDropdown from '../../components/chart/ChartDropdown';
import { connect } from 'react-redux';
import * as fromState from '../../state/reducers';
import { changeView } from '../../state/actions/chart';
import { createSelector } from 'reselect';
import * as chartSelectors from '../../state/selectors/chart';
import { CHART_TYPE, ENTITY_PROPERTY, VALUE_TYPE } from '../../utils/constants';
import _ from 'lodash';

const mapStateToProps = createSelector(
  [
    state => fromState.getView(state),
    state => chartSelectors.getInputSensors(state, CHART_TYPE.MAIN),
    state => chartSelectors.getOutputProperties(state, CHART_TYPE.MAIN),
    state => chartSelectors.getFunctionSensors(state, CHART_TYPE.MAIN)
  ],
  (
    view,
    inputSensors,
    outputProperties,
    functionSensors
  ) => ({
    value: view,
    mapViewEnabled: _.some([
      ..._.values(inputSensors),
      ..._.values(outputProperties),
      ..._.values(functionSensors)
    ], [ENTITY_PROPERTY.SENSOR.VALUE_TYPE, VALUE_TYPE.GEO_POINT])
  })
);

const mapDispatchToProps = {
  onChange: changeView
};

export default connect(mapStateToProps, mapDispatchToProps)(ChartDropdown);