import { compose } from 'recompose';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { querySyncPush } from 'state/actions/navigation';
import DashboardButtonBar from '../../components/dashboard/DashboardButtonBar';
import * as fromState from '../../state/reducers';
import { bindActionCreators } from 'redux';
import { setCardSize, setDashboardView, setShowCardDetails } from 'state/actions/dashboard';
import { PAGE_URL } from '../../utils/constants';

const mapStateToProps = createSelector(
  [
    fromState.getDashboardView,
    fromState.getCardSize,
    fromState.getShowCardDetails
  ],
  (view, cardSize, showCardDetails) => ({
    view,
    cardSize,
    showCardDetails
  })
);

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({
    onChangeCardSize: setCardSize,
    onChangeShowCardDetails: setShowCardDetails
  }, dispatch),
  onChangeView: view => {
    dispatch(setDashboardView(view));
    dispatch(querySyncPush(PAGE_URL.HOME));
  }
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(DashboardButtonBar);