import AlarmRangesButton from '../../components/alarm/AlarmRangesButton';
import { connect } from 'react-redux';
import * as fromState from '../../state/reducers'; 
import { setAlarmRanges, setAlarmModalIsOpen, savePredictorAlarmRanges } from '../../state/actions/alarm';
import { DEFAULT_ALARM_RANGES } from '../../utils/constants';
import { compose, withHandlers } from 'recompose';

const mapStateToProps = (state, { id, index, entityType }) => ({
  alarmRanges:
    fromState.getById(state, entityType, id).outputMappings[index].alarmRanges
      || DEFAULT_ALARM_RANGES,
  type: 'output'
});

const mapDispatchToProps = (dispatch, { id, index, entityType }) => ({
  openModal: alarmRanges =>
    dispatch(setAlarmModalIsOpen(true)) && dispatch(setAlarmRanges(alarmRanges)),
  onSave: alarmRanges => dispatch(savePredictorAlarmRanges(entityType, alarmRanges, index, id))
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    onClick: ({ alarmRanges, openModal }) => () => openModal(alarmRanges)
  })
)(AlarmRangesButton);