import { SET_IMPORT_ERROR } from '../../../actions/entity';

export default (state = null, action) => {
  switch (action.type) {
    case SET_IMPORT_ERROR:
      return action.payload.row;
    default:
      return state;
  }
};

export const getRow = state => state;