import _ from 'lodash';
import createCachedSelector from 're-reselect';
import getBuckets from './getBuckets';
import getBucketSize from './getBucketSize';

export default createCachedSelector(
  [
    getBuckets,
    getBucketSize
  ],
  (buckets, bucketSize) => _.map(buckets, bucket => `${bucket.start.local().format(bucketSize.format)} - ${bucket.end.local().format(bucketSize.format)}`)
)(
  (state, chartType) => chartType
);