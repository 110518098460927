import { ADD, ADD_ERROR, TOGGLE_MODAL, CLEAR_ADD_ERROR } from '../../actions/entity';
import { ResponseWrapper, ErrorResponse } from 'types';
import _ from 'lodash';

export default (state: ResponseWrapper<ErrorResponse> | null = null, action) => {
  switch (action.type) {
    case TOGGLE_MODAL:
    case ADD:
    case CLEAR_ADD_ERROR:
      return null;
    case ADD_ERROR:
      return action.payload.err;
    default:
      return state;
  }
};

export const getAddErrorResponse = (state: ResponseWrapper<ErrorResponse> | null) => state;
export const getAddErrorRequest = (state: ResponseWrapper<ErrorResponse> | null) =>
  JSON.parse(_.get(state, 'request.body') || 'null');

export const getAddErrorMessages = (
  state: ResponseWrapper<ErrorResponse> | null,
  errorPathPrefixes: string[]
) => {
  if (_.isNull(state)) {
    return [];
  }

  return _.chain(state.response.errors)
    .filter((val, errorPath) => _.some(errorPathPrefixes, prefix => _.startsWith(errorPath, prefix)))
    .flatMap()
    .map(err => err.message)
    .value();
};

export const getAddErrorCodes = (
  state: ResponseWrapper<ErrorResponse> | null,
  errorPathPrefixes: string[]
) => {
  if (_.isNull(state)) {
    return [];
  }

  return _.chain(state.response.errors)
    .filter((val, errorPath) => _.some(errorPathPrefixes, prefix => _.startsWith(errorPath, prefix)))
    .flatMap()
    .map(err => err.code)
    .value();
};