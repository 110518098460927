import entityConfig from '../../utils/entities';
import _ from 'lodash';

export default function EntityText({
  id,
  entityType
}) {
  const Component = _.get(entityConfig[entityType], 'textComponent');
  return Component
    ? <Component id={id} />
    : id;
};