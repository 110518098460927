import _ from 'lodash';
import createCachedSelector from 're-reselect';
import * as fromState from '../../../reducers';
import { getFunctionSensorIds } from '../getFunctionSensorIds';

const getAllSensors = state => fromState.getAll(state, 'sensor');

export const getFunctionAlarmRanges = createCachedSelector(
  [getFunctionSensorIds, getAllSensors],
  (ids, allSensors) => _.chain(ids)
    .map(id => [
      id,
      _.get(allSensors[id], 'alarmRanges') || []
    ])
    .fromPairs()
    .value()
)(
  (state, chartType) => chartType
);