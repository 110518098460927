import uuid from 'uuid/v1';
import { defineMessages } from 'react-intl';
import { EntityConfig, Sequence } from 'types';
import moment from 'moment';
import { DATE_FORMAT, ENTITY_FORM_ELEMENT_TYPE, ENTITY_TYPE } from 'utils/constants';
import _ from 'lodash';

const messages = defineMessages({
  'title': {
    id: 'sequence.title',
    defaultMessage: 'Sequences'
  },
  'columns.name.title': {
    id: 'sequence.columns.name.title',
    defaultMessage: 'Name'
  },
  'columns.description.title': {
    id: 'sequence.columns.description.title',
    defaultMessage: 'Description'
  },
  'form.name.label': {
    id: 'sequence.form.name.label',
    defaultMessage: 'Name'
  },
  'form.name.placeholder': {
    id: 'sequence.form.name.placeholder',
    defaultMessage: 'Name'
  },
  'form.description.label': {
    id: 'sequence.form.description.label',
    defaultMessage: 'Description'
  },
  'form.description.placeholder': {
    id: 'sequence.form.description.placeholder',
    defaultMessage: 'Description'
  },
  'form.queryString.label': {
    id: 'sequence.form.queryString.label',
    defaultMessage: 'Query String'
  },
  'form.queryString.placeholder': {
    id: 'sequence.form.queryString.placeholder',
    defaultMessage: 'Query String'
  },
  'form.unitId.label': {
    id: 'sequence.form.unitId.label',
    defaultMessage: 'Unit'
  },
  'form.unitId.placeholder': {
    id: 'sequence.form.unitId.placeholder',
    defaultMessage: 'Unit'
  },
  'form.maxGap.label': {
    id: 'sequence.form.maxGap.label',
    defaultMessage: 'Maximum Gap'
  },
  'form.maxGap.placeholder': {
    id: 'sequence.form.maxGap.placeholder',
    defaultMessage: 'Maximum gap between two events in the sequence'
  },
  'form.maxGap.option.PT1S': {
    id: 'sequence.form.maxGap.option.PT1S',
    defaultMessage: '1 Second'
  },
  'form.maxGap.option.PT10S': {
    id: 'sequence.form.maxGap.option.PT10S',
    defaultMessage: '10 Seconds'
  },
  'form.maxGap.option.PT1M': {
    id: 'sequence.form.maxGap.option.PT1M',
    defaultMessage: '1 Minute'
  },
  'form.maxGap.option.PT10M': {
    id: 'sequence.form.maxGap.option.PT10M',
    defaultMessage: '10 Minutes'
  },
  'form.maxGap.option.PT1H': {
    id: 'sequence.form.maxGap.option.PT1H',
    defaultMessage: '1 Hour'
  },
  'form.maxGap.option.PT12H': {
    id: 'sequence.form.maxGap.option.PT12H',
    defaultMessage: '12 Hours'
  },
  'form.maxGap.option.PT1D': {
    id: 'sequence.form.maxGap.option.PT1D',
    defaultMessage: '1 Day'
  }
});

const config: EntityConfig<Sequence> = {
  apiPath: 'sequence',
  pageUrl: 'sequences',
  icon: 'object group',
  messages,
  disableFetchTypes: true,
  columns: [
    {
      name: 'name',
      width: 3,
      render: (sequence: Sequence): string => sequence.name
    },
    {
      name: 'description',
      width: 3,
      render: (sequence: Sequence): string => sequence.description
    }
  ],
  form: [
    {
      name: 'name',
      type: ENTITY_FORM_ELEMENT_TYPE.INPUT,
      disableOnEdit: true
    },
    {
      name: 'description',
      type: ENTITY_FORM_ELEMENT_TYPE.INPUT,
      disableOnEdit: true
    },
    {
      name: 'queryString',
      type: ENTITY_FORM_ELEMENT_TYPE.INPUT,
      disableOnEdit: true
    },
    {
      name: 'maxGap',
      type: ENTITY_FORM_ELEMENT_TYPE.SELECT,
      options: [
        'PT1S',
        'PT10S',
        'PT1M',
        'PT10M',
        'PT1H',
        'PT12H',
        'PT1D'
      ],
      disableOnEdit: true
    },
    {
      name: 'unitId',
      type: ENTITY_FORM_ELEMENT_TYPE.SELECT_ENTITY,
      entityType: ENTITY_TYPE.UNIT,
      disableOnEdit: true
    },
    {
      name: 'dateRange',
      type: ENTITY_FORM_ELEMENT_TYPE.DATE_RANGE,
      errorPaths: ['from', 'to'],
      fromErrorPath: 'from',
      toErrorPath: 'to',
      disableOnEdit: true
    }
  ],
  createEntity: (values) => ({
    id: uuid(),
    name: values.name,
    description: values.description,
    queryString: values.queryString,
    window: values.window,
    from: moment.utc(_.get(values, 'dateRange.from'), DATE_FORMAT).toISOString(),
    to: moment.utc(_.get(values, 'dateRange.to'), DATE_FORMAT).toISOString(),
    maxGap: values.maxGap,
    unitId: values.unitId
  }),
  populateForm: (sequence: Sequence) => ({
    name: sequence.name,
    description: sequence.description,
    queryString: sequence.queryString,
    window: sequence.window,
    dateRange: {
      from: sequence.from,
      to: sequence.to
    },
    maxGap: sequence.maxGap,
    unitId: sequence.unitId
  })
};

export default config;