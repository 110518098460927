import _ from 'lodash';
import uuid from 'uuid/v1';
import { Icon } from 'semantic-ui-react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { ENTITY_CONFIG, ENTITY_FORM_ELEMENT_TYPE, ENTITY_TYPE, ROLE } from '../../../utils/constants';
import SensorAlarmButton from '../../../components/alarm/SensorAlarmButton';
import { unescapeRegex } from '../../../utils/helpers';
import valueTypeMessages from '../../../utils/i18n/shared/valueTypes';
import EntityText from '../../../components/entity/EntityText';
import optionsSelector from './optionsSelector';

const messages = defineMessages({
  'columns.name.title': {
    id: 'sensor.columns.name.title',
    defaultMessage: 'Name'
  },
  'columns.description.title': {
    id: 'sensor.columns.description.title',
    defaultMessage: 'Description'
  },
  'columns.unit.title': {
    id: 'sensor.columns.unit.title',
    defaultMessage: 'Unit'
  },
  'columns.uom.title': {
    id: 'sensor.columns.uom.title',
    defaultMessage: 'UoM'
  },
  'columns.status.title': {
    id: 'sensor.columns.status.title',
    defaultMessage: 'Status'
  },
  'columns.status.pending': {
    id: 'sensor.columns.status.pending',
    defaultMessage: 'Pending'
  },
  'columns.status.validated': {
    id: 'sensor.columns.status.validated',
    defaultMessage: 'Validated'
  },
  'columns.status.invalid': {
    id: 'sensor.columns.status.invalid',
    defaultMessage: 'Invalid'
  },
  'columns.valueType.title': {
    id: 'sensor.columns.valueType.title',
    defaultMessage: 'Value Type'
  },
  'columns.alarms.title': {
    id: 'sensor.columns.alarms.title',
    defaultMessage: 'Alarms'
  },
  'form.name.label': {
    id: 'sensor.form.name.label',
    defaultMessage: 'Name'
  },
  'form.name.placeholder': {
    id: 'sensor.form.name.placeholder',
    defaultMessage: 'Name'
  },
  'form.description.label': {
    id: 'sensor.form.description.label',
    defaultMessage: 'Description'
  },
  'form.description.placeholder': {
    id: 'sensor.form.description.placeholder',
    defaultMessage: 'Description'
  },
  'form.unitId.label': {
    id: 'sensor.form.unitId.label',
    defaultMessage: 'Unit'
  },
  'form.unitId.placeholder': {
    id: 'sensor.form.unitId.placeholder',
    defaultMessage: 'Unit'
  },
  'form.valueType.label': {
    id: 'sensor.form.valueType.label',
    defaultMessage: 'Value Type'
  },
  'form.valueType.placeholder': {
    id: 'sensor.form.valueType.placeholder',
    defaultMessage: 'Value Type'
  },
  'type.api.api.name': {
    id: 'sensor.type.api.api.name',
    defaultMessage: 'API'
  },
  'type.api.api.options.valueType.label': {
    id: 'sensor.type.api.api.options.valueType.label',
    defaultMessage: 'Value Type'
  },
  'type.api.api.options.valueType.placeholder': {
    id: 'sensor.type.api.api.options.valueType.placeholder',
    defaultMessage: 'Value Type'
  },
  'type.api.api.options.enumeration.label': {
    id: 'sensor.type.api.api.options.enumeration.label',
    defaultMessage: 'Enumeration'
  },
  'type.api.api.options.enumeration.placeholder': {
    id: 'sensor.type.api.api.options.enumeration.placeholder',
    defaultMessage: 'Enumeration'
  },
  'type.api.api.options.unitsOfMeasurement.label': {
    id: 'sensor.type.api.api.options.unitsOfMeasurement.label',
    defaultMessage: 'Units Of Measurement'
  },
  'type.api.api.options.unitsOfMeasurement.placeholder': {
    id: 'sensor.type.api.api.options.unitsOfMeasurement.placeholder',
    defaultMessage: 'Units Of Measurement'
  },
  'type.pi.piPoint.name': {
    id: 'sensor.type.pi.piPoint.name',
    defaultMessage: 'PI Point'
  },
  'type.pi.piPoint.options.server.label': {
    id: 'sensor.type.pi.piPoint.options.server.label',
    defaultMessage: 'Server'
  },
  'type.pi.piPoint.options.server.placeholder': {
    id: 'sensor.type.pi.piPoint.options.server.placeholder',
    defaultMessage: 'Server'
  },
  'type.pi.piPoint.options.tag.label': {
    id: 'sensor.type.pi.piPoint.options.tag.label',
    defaultMessage: 'Tag'
  },
  'type.pi.piPoint.options.tag.placeholder': {
    id: 'sensor.type.pi.piPoint.options.tag.placeholder',
    defaultMessage: 'Tag'
  },
  'type.phalanx.formAnswer.name': {
    id: 'sensor.type.phalanx.formAnswer.name',
    defaultMessage: 'Dataform Answer'
  },
  'type.phalanx.formAnswer.options.companyId.label': {
    id: 'sensor.type.phalanx.formAnswer.options.companyId.label',
    defaultMessage: 'Company Id'
  },
  'type.phalanx.formAnswer.options.companyId.placeholder': {
    id: 'sensor.type.phalanx.formAnswer.options.companyId.placeholder',
    defaultMessage: 'Company Id'
  },
  'type.phalanx.formAnswer.options.formName.label': {
    id: 'sensor.type.phalanx.formAnswer.options.formName.label',
    defaultMessage: 'Dataform Name'
  },
  'type.phalanx.formAnswer.options.formName.placeholder': {
    id: 'sensor.type.phalanx.formAnswer.options.formName.placeholder',
    defaultMessage: 'Dataform Name'
  },
  'type.phalanx.formAnswer.options.answerAlias.label': {
    id: 'sensor.type.phalanx.formAnswer.options.answerAlias.label',
    defaultMessage: 'Answer Alias'
  },
  'type.phalanx.formAnswer.options.answerAlias.placeholder': {
    id: 'sensor.type.phalanx.formAnswer.options.answerAlias.placeholder',
    defaultMessage: 'Answer Alias'
  },
  'type.phalanx.equipmentAttribute.name': {
    id: 'sensor.type.phalanx.equipmentAttribute.name',
    defaultMessage: 'Equipment Attribute'
  },
  'type.phalanx.equipmentAttribute.options.serialisedEquipmentId.label': {
    id: 'sensor.type.phalanx.equipmentAttribute.options.serialisedEquipmentId.label',
    defaultMessage: 'Serialised Equipment Id'
  },
  'type.phalanx.equipmentAttribute.options.serialisedEquipmentId.placeholder': {
    id: 'sensor.type.phalanx.equipmentAttribute.options.serialisedEquipmentId.placeholder',
    defaultMessage: 'Serialised Equipment Id'
  },
  'type.phalanx.equipmentAttribute.options.attributeId.label': {
    id: 'sensor.type.phalanx.equipmentAttribute.options.attributeId.label',
    defaultMessage: 'Attribute Name'
  },
  'type.phalanx.equipmentAttribute.options.attributeId.placeholder': {
    id: 'sensor.type.phalanx.equipmentAttribute.options.attributeId.placeholder',
    defaultMessage: 'Attribute Name'
  },
  'type.phalanx.equipmentAttribute.options.valueType.label': {
    id: 'sensor.type.phalanx.equipmentAttribute.options.valueType.label',
    defaultMessage: 'Value Type'
  },
  'type.phalanx.equipmentAttribute.options.valueType.placeholder': {
    id: 'sensor.type.phalanx.equipmentAttribute.options.valueType.placeholder',
    defaultMessage: 'Value Type'
  },
  'type.phalanx.equipmentAttribute.options.enumeration.label': {
    id: 'sensor.type.phalanx.equipmentAttribute.options.enumeration.label',
    defaultMessage: 'Enumeration'
  },
  'type.phalanx.equipmentAttribute.options.enumeration.placeholder': {
    id: 'sensor.type.phalanx.equipmentAttribute.options.enumeration.placeholder',
    defaultMessage: 'Enumeration'
  },
  'type.phalanx.equipmentAttribute.options.unitsOfMeasurement.label': {
    id: 'sensor.type.phalanx.equipmentAttribute.options.unitsOfMeasurement.label',
    defaultMessage: 'Units Of Measurement'
  },
  'type.phalanx.equipmentAttribute.options.unitsOfMeasurement.placeholder': {
    id: 'sensor.type.phalanx.equipmentAttribute.options.unitsOfMeasurement.placeholder',
    defaultMessage: 'Units Of Measurement'
  },
  'type.aemp.equipmentField.name': {
    id: 'sensor.type.aemp.equipmentField.name',
    defaultMessage: 'Equipment Field'
  },
  'type.aemp.equipmentField.options.serialNumber.label': {
    id: 'sensor.type.aemp.equipmentField.options.serialNumber.label',
    defaultMessage: 'Serial Number'
  },
  'type.aemp.equipmentField.options.serialNumber.placeholder': {
    id: 'sensor.type.aemp.equipmentField.options.serialNumber.placeholder',
    defaultMessage: 'Serial Number'
  },
  'type.aemp.equipmentField.options.fieldName.label': {
    id: 'sensor.type.aemp.equipmentField.options.fieldName.label',
    defaultMessage: 'Field Name'
  },
  'type.aemp.equipmentField.options.fieldName.placeholder': {
    id: 'sensor.type.aemp.equipmentField.options.fieldName.placeholder',
    defaultMessage: 'Field Name'
  },
  'type.prophes.predictorOutput.name': {
    id: 'sensor.type.prophes.predictorOutput.name',
    defaultMessage: 'Predictor Output'
  },
  'type.prophes.predictorOutput.options.predictor.label': {
    id: 'sensor.type.prophes.predictorOutput.options.predictor.label',
    defaultMessage: 'Predictor'
  },
  'type.prophes.predictorOutput.options.predictor.placeholder': {
    id: 'sensor.type.prophes.predictorOutput.options.predictor.placeholder',
    defaultMessage: 'Predictor'
  },
  'type.prophes.predictorOutput.options.output.label': {
    id: 'sensor.type.prophes.predictorOutput.options.output.label',
    defaultMessage: 'Output'
  },
  'type.prophes.predictorOutput.options.output.placeholder': {
    id: 'sensor.type.prophes.predictorOutput.options.output.placeholder',
    defaultMessage: 'Output'
  },
  'deckHeader.sensors': {
    id: 'sensor.deckHeader.sensors',
    defaultMessage: 'Sensors'
  }
});

const validationStatusValues = {
  0: {
    cell: 'warning',
    icon: 'attention',
    name: 'pending'
  },
  1: {
    cell: 'positive',
    icon: 'checkmark',
    name: 'validated'
  },
  2: {
    cell: 'error',
    icon: 'attention',
    name: 'invalid'
  }
};

export default {
  apiPath: 'sensor',
  apiPrefix: 'connector',
  pageUrl: 'sensors',
  parentEntityType: ENTITY_TYPE.SENSOR_CONNECTOR,
  viewRole: ROLE.VIEW_SENSOR,
  modifyRole: ROLE.MODIFY_SENSOR,
  optionsSelector,
  fetchAllOnLoad: true,
  [ENTITY_CONFIG.IMPORT]: true,
  [ENTITY_CONFIG.SEARCH]: [
    {
      name: 'name',
      type: ENTITY_FORM_ELEMENT_TYPE.INPUT
    },
    {
      name: 'description',
      type: ENTITY_FORM_ELEMENT_TYPE.INPUT
    }
  ],
  [ENTITY_CONFIG.CREATE_SEARCH_QUERY]: ({
    name,
    description
  }, {
    by,
    direction
  }) => {
    const filter = {};
    if (name) {
      filter.Name = {
        $regex: _.escapeRegExp(name),
        $options: 'i'
      };
    };
    if (description) {
      filter.Description = {
        $regex: _.escapeRegExp(description),
        $options: 'i'
      };
    };
    return {
      filter
    };
  },
  [ENTITY_CONFIG.PARSE_SEARCH_FILTER]: filter => ({
    name: unescapeRegex(_.get(filter, 'Name.$regex') || ''),
    description: unescapeRegex(_.get(filter, 'Description.$regex') || '')
  }),
  messages,
  columns: [
    {
      name: 'name',
      width: 3,
      render: ({ name }) => name
    },
    {
      name: 'description',
      width: 3,
      render: ({ description }) => description
    },
    {
      name: 'unit',
      width: 3,
      render: ({ unitId }) => (
        <EntityText
          id={unitId}
          entityType={ENTITY_TYPE.UNIT} />
      )
    },
    {
      name: 'status',
      width: 3,
      props: ({ validationStatus = 0 }) => ({ [validationStatusValues[validationStatus].cell]: true }),
      render: ({ validationStatus = 0 }) => (
        <div>
          <Icon name={validationStatusValues[validationStatus].icon} /><FormattedMessage {...messages[`columns.status.${validationStatusValues[validationStatus].name}`]} />
        </div>
      )
    },
    {
      name: 'valueType',
      width: 3,
      render: ({ valueType }) => valueType
        ? <FormattedMessage {...valueTypeMessages[valueType]} />
        : null
    },
    {
      name: 'alarms',
      width: 3,
      render: ({ id, valueType }) => (
        <SensorAlarmButton
          id={id}
          valueType={valueType} />
      )
    }
  ],
  form: [
    {
      name: 'name',
      type: ENTITY_FORM_ELEMENT_TYPE.INPUT
    },
    {
      name: 'description',
      type: ENTITY_FORM_ELEMENT_TYPE.INPUT
    },
    {
      name: 'unitId',
      type: ENTITY_FORM_ELEMENT_TYPE.SEARCH,
      searchEntityType: ENTITY_TYPE.UNIT,
      transformResult: unit => ({
        key: unit.id,
        text: unit.name,
        value: unit.id
      })
    },
    {
      name: 'valueType',
      type: ENTITY_FORM_ELEMENT_TYPE.SELECT,
      selectListName: 'valueTypes'
    },
    {
      type: 'types'
    }
  ],
  createEntity: (values, sensorConnector) => ({
    id: uuid(),
    name: values.name,
    description: values.description,
    type: values.type,
    settings: values.settings || {},
    unitId: values.unitId,
    valueType: values.valueType,
    connector: sensorConnector.id,
    active: true
  }),
  populateForm: entity => ({
    name: entity.name,
    description: entity.description,
    unitId: entity.unitId,
    valueType: entity.valueType,
    type: entity.type,
    settings: entity.settings
  }),
  deckHeaderMessage: (dashboardView, isTopLevel) => messages['deckHeader.sensors']
};