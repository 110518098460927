import _ from 'lodash';
import { createSelector } from 'reselect';
import * as fromState from '../../../state/reducers';
import { ENTITY_TYPE } from '../../../utils/constants';

export default createSelector(
  [
    state => fromState.getAll(state, ENTITY_TYPE.ENUMERATION)
  ],
  (
    allEnumerations
  ) => _.chain(allEnumerations)
    .toPairs()
    .map(([id, enumeration]) => ({
      key: enumeration.id,
      text: enumeration.name,
      value: enumeration.id
    }))
    .sortBy('text')
    .value()
);