import { ADD, ADD_FULFILLED, ADD_ERROR, SET_IS_ADDING } from '../../actions/entity';

export default (state = false, action) => {
  switch (action.type) {
    case ADD:
      return true;
    case ADD_FULFILLED:
    case ADD_ERROR:
      return false;
    case SET_IS_ADDING:
      return action.payload.value;
    default:
      return state;
  }
};

export const getIsAdding = state => state;