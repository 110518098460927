import { FETCH_FORM_SEARCH_RESULTS, FETCH_FORM_SEARCH_RESULTS_FULFILLED } from '../../actions/entity';
import { FORM_SEARCH_RESULT } from '../../../utils/constants';

const formSearchResultsReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_FORM_SEARCH_RESULTS:
      return {
        ...state,
        [action.payload.key]: {
          ...state[action.payload.key],
          [FORM_SEARCH_RESULT.IS_LOADING]: true
        }
      };
    case FETCH_FORM_SEARCH_RESULTS_FULFILLED:
      return {
        ...state,
        [action.payload.key]: {
          ...state[action.payload.key],
          [FORM_SEARCH_RESULT.IS_LOADING]: false,
          [FORM_SEARCH_RESULT.RESULTS]: action.payload.results
        }
      };
    default:
      return state;
  }
};

export default (state = { settings: {} }, action) => {
  switch (action.type) {
    case FETCH_FORM_SEARCH_RESULTS:
    case FETCH_FORM_SEARCH_RESULTS_FULFILLED:
      if (action.payload.isSetting) {
        return {
          ...state,
          [FORM_SEARCH_RESULT.SETTINGS]: formSearchResultsReducer(state[FORM_SEARCH_RESULT.SETTINGS], action)
        };
      };
      return {
        ...state,
        ...formSearchResultsReducer(state, action)
      };
    default:
      return state;
  };
};

export const getAllFormSearchResults = state => state;