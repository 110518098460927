import createCachedSelector from 're-reselect';
import { connect } from 'react-redux';
import HeatMapChart from '../../../components/chart/HeatMapChart';
import * as fromState from '../../../state/reducers';
import { transformChartTypeSelectors } from '../../../utils/helpers';
import getFunctionData from './getFunctionData';
import getInputData from './getInputData';
import getOutputData from './getOutputData';
import getYDomain from './getYDomain';

export const mapStateToProps = createCachedSelector(
  transformChartTypeSelectors([
    getYDomain,
    getInputData,
    getOutputData,
    getFunctionData,
    fromState.getChartIsLoading
  ]),
  (
    yDomain,
    inputData,
    outputData,
    functionData,
    isLoading
  ) => ({
    yDomain,
    data: {
      ...inputData,
      ...outputData,
      ...functionData
    },
    isLoading
  })
)(
  (state, { chartType }) => chartType
);

export default connect(mapStateToProps)(HeatMapChart);