import moment from 'moment';
import { ofType } from 'redux-observable';
import { map, withLatestFrom } from 'rxjs/operators';
import { setDates } from '../../actions/chart';
import { JUMP_TO_LIVE_PLAYBACK } from '../../actions/playback';
import { CHART_TYPE, DATE_FORMAT } from '../../../utils/constants';
import * as fromState from '../../reducers';

export default (action$, state$) => action$.pipe(
  ofType(JUMP_TO_LIVE_PLAYBACK),
  withLatestFrom(state$),
  map(([action, state]) => {
    const duration = moment.duration(fromState.getQuickRange(state, CHART_TYPE.MAIN));
    const toDate = moment.utc();
    return setDates(
      CHART_TYPE.MAIN,
      toDate.clone().subtract(duration).format(DATE_FORMAT),
      toDate.format(DATE_FORMAT)
    );
  })
);