import AlarmRangesButton from '../../components/alarm/AlarmRangesButton';
import { connect } from 'react-redux';
import { toggleModal, saveAlarmRanges } from '../../state/actions/entity';
import * as fromState from '../../state/reducers';
import _ from 'lodash';
import { DEFAULT_ALARM_RANGES, ENTITY_TYPE, ENTITY_PROPERTY, ENTITY_MODAL } from '../../utils/constants';
import { bindActionCreators } from 'redux';

const mapStateToProps = (state, { id }) => ({
  alarmRanges:
    _.get(fromState.getById(state, ENTITY_TYPE.SENSOR, id), ENTITY_PROPERTY.SENSOR.ALARM_RANGES)
      || DEFAULT_ALARM_RANGES,
  type: ENTITY_TYPE.SENSOR
});

const mapDispatchToProps = (dispatch, { id }) => bindActionCreators({
  onClick: () => toggleModal(ENTITY_TYPE.SENSOR, ENTITY_MODAL.ALARM, id),
  onSave: alarmRanges => saveAlarmRanges(ENTITY_TYPE.SENSOR, alarmRanges, id)
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AlarmRangesButton);