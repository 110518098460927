import _ from 'lodash';
import { ofType } from 'redux-observable';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import * as api from '../../../utils/api';
import { ENTITY_CONFIG } from '../../../utils/constants';
import { fetchFormSearchResultsFulfilled, FETCH_FORM_SEARCH_RESULTS } from '../../actions/entity';
import * as fromState from '../../reducers';
import entityConfig from '../../../utils/entities';
import { takeUntilAppReset } from 'utils/helpers';

export default (action$, state$) => action$.pipe(
  ofType(FETCH_FORM_SEARCH_RESULTS),
  withLatestFrom(state$),
  mergeMap(([action, state]) =>
    api.query({
      controller: entityConfig[action.payload.searchEntityType][ENTITY_CONFIG.API_PATH],
      page: 0,
      query: {
        sort: {},
        filter: {
          $and: [
            action.payload.searchFilter || {},
            {
              $or: [
                {
                  Name: {
                    $regex: _.escapeRegExp(action.payload.value),
                    $options: 'i'
                  }              
                },
                {
                  Description: {
                    $regex: _.escapeRegExp(action.payload.value),
                    $options: 'i'
                  }
                },
                {
                  _id: {
                    $eq: action.payload.value
                  }
                }
              ]
            }
          ]
        }
      },
      token: fromState.getUser(state).token
    }).pipe(
      takeUntilAppReset(action$),
      map(({ items: results }) => fetchFormSearchResultsFulfilled(
        action.entityType,
        action.payload.isSetting,
        action.payload.key,
        results,
        action.payload.searchEntityType
      )),
      catchError(api.onError)
    ))
);