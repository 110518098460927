import { catchError, distinctUntilChanged, filter, map, mergeMap } from 'rxjs/operators';
import * as api from '../../../utils/api';
import { CHART_TYPE, ENTITY_CONFIG, ENTITY_TYPE, TREND_TIMELINE_QUERY_PAGE_SIZE } from '../../../utils/constants';
import { insertMany, fetchLabels } from '../../actions/entity';
import * as fromState from '../../reducers';
import * as chartSelectors from '../../selectors/chart';
import { getUnitId } from '../../selectors/dashboard';
import { of, merge } from 'rxjs';
import entityConfig from '../../../utils/entities';
import { takeUntilAppReset } from 'utils/helpers';

export default (action$, state$) => state$.pipe(
  filter(state => fromState.getUser(state)),
  map(state => [getUnitId(state), state]),
  distinctUntilChanged(([previousId], [currentId]) => previousId === currentId),
  mergeMap(([id, state]) => merge(
    api.query({
      controller: entityConfig[ENTITY_TYPE.TREND][ENTITY_CONFIG.API_PATH],
      page: 0,
      pageSize: TREND_TIMELINE_QUERY_PAGE_SIZE,
      query: chartSelectors.getTimelineTrendQuery(state, CHART_TYPE.MAIN),
      token: fromState.getUser(state).token
    }).pipe(
      takeUntilAppReset(action$),
      map(({ items }) => insertMany(ENTITY_TYPE.TREND, items)),
      catchError(api.onError)
    ),
    of(
      fetchLabels(ENTITY_TYPE.TREND)
    )
  ))
);