import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { EDIT, editError, editFulfilled } from '../../actions/entity';
import * as api from '../../../utils/api';
import * as fromState from '../../reducers';
import { ENTITY_CONFIG } from '../../../utils/constants';
import entityConfig from '../../../utils/entities';
import { takeUntilAppReset } from 'utils/helpers';

export default (action$, state$) => action$.pipe(
  ofType(EDIT),
  withLatestFrom(state$),
  mergeMap(([action, state]) =>
    api.edit({
      controller: entityConfig[action.entityType][ENTITY_CONFIG.API_PATH],
      entity: action.payload.entity,
      token: fromState.getUser(state).token
    }).pipe(
      takeUntilAppReset(action$),
      map(({ response }) => editFulfilled(action.entityType, response)),
      catchError((err, caught) => {
        if (err.status === 400) {
          return of(editError(action.entityType, err));
        }
        return api.onError(err, caught);
      })
    ))
);