import _ from 'lodash';
import getFeatureUnitsOfMeasurement from '../getFeatureUnitsOfMeasurement';
import { ENTITY_PROPERTY, EMPTY_STRING } from 'utils/constants';

export default (model, output, inputSensors) => {
  if (model.type !== 'featureGroup') {
    return _.chain(model.outputs)
      .find([ENTITY_PROPERTY.MODEL.OUTPUT.NAME, output])
      .get(ENTITY_PROPERTY.MODEL.OUTPUT.UNITS_OF_MEASUREMENT)
      .value() || EMPTY_STRING;
  };
  
  const [input, feature, featureInput] = _.split(output, '|');
  return getFeatureUnitsOfMeasurement(
    feature,
    _.get(inputSensors[input], ENTITY_PROPERTY.SENSOR.UNITS_OF_MEASUREMENT),
    _.get(inputSensors[featureInput], ENTITY_PROPERTY.SENSOR.UNITS_OF_MEASUREMENT)
  );
};