import _ from 'lodash';
import { connect } from 'react-redux';
import TypesElement from 'components/entity/TypesElement';
import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';
import * as fromState from '../../state/reducers';
import { ENTITY_CONFIG, ENTITY_FORM_TYPE, FORM_SEARCH_RESULT, TYPE, INPUT_TYPE } from 'utils/constants';
import entityConfig from '../../utils/entities';
import { transformEntityTypeSelectors } from '../../utils/helpers';
import { bindActionCreators } from 'redux';
import { addCondition, removeCondition, updateCondition, formSettingChanged, formComplexSettingChanged, setConditionFilter } from 'state/actions/entity';

const getParentEntityTypeValue = createCachedSelector(
  [
    ...transformEntityTypeSelectors([
      (state, entityType) => entityConfig[entityType][ENTITY_CONFIG.PARENT_ENTITY_TYPE]
    ]),
    (state, { parentId }) => parentId,
    state => state.entities
  ],
  (
    parentEntityType,
    parentId,
    entities
  ) => {
    const state = { entities };
    return _.get(parentEntityType && fromState.getById(state, parentEntityType, parentId), TYPE);
  }
)(
  (state, { entityType }) => entityType
);

const getTypesById = createCachedSelector(
  [
    (state, { entityType }) => fromState.getTypesById(state, entityType),
    (state, { filterTypes }) => filterTypes,
    (state, { entityType }) => entityConfig[entityType].parentTypeSelector
      ? entityConfig[entityType].parentTypeSelector(state)
      : null,
    getParentEntityTypeValue
  ],
  (
    typesById,
    filterTypes,
    parentType,
    parentEntityTypeValue
  ) => {
    return _.pickBy(
      typesById,
      type => (!filterTypes || filterTypes(type))
        && (
          parentType
            ? parentType === type.parentType
            : !parentEntityTypeValue || parentEntityTypeValue === type.parentType)
    );
  }
)(
  (state, { entityType }) => entityType
);

const mapStateToProps = createSelector(
  [
    (state, { element }) => element,
    (state, { entityType }) => fromState.getAllFormSearchResults(state, entityType),
    (state, { entityType }) => fromState.getFormValues(state, entityType, ENTITY_FORM_TYPE.ADD_OR_EDIT),
    getTypesById
  ],
  (element, allFormSearchResults, formValues, typesById) => {
    const typeOptions = element.search
      ? _.chain(allFormSearchResults[TYPE])
        .get(FORM_SEARCH_RESULT.RESULTS)
        .map(type => [type.id, type])
        .fromPairs()
        .value()
      : typesById;

    return {
      typeEntityType: element.entityType,
      hideType: element.hideType,
      hideSettings: element.hideSettings,
      search: !!element.search,
      create: !!element.create,
      hideCreateFields: element.hideCreateFields,
      typesById: typeOptions,
      isLoading: element.search
        ? _.get(allFormSearchResults[TYPE], FORM_SEARCH_RESULT.IS_LOADING)
        : false,
      selectedTypeId: formValues.type || null
    };
  }
);

const mapDispatchToProps = (dispatch, { entityType }) => bindActionCreators({
  onChangeSetting: (key, value) => formSettingChanged(
    entityType,
    ENTITY_FORM_TYPE.ADD_OR_EDIT,
    key,
    value
  ),
  ..._.mapValues({
    onAddSettingCondition: addCondition,
    onRemoveSettingCondition: removeCondition,
    onChangeSettingCondition: updateCondition,
    onChangeSettingConditionFilter: setConditionFilter
  }, actionCreator => (key, ...args) => formComplexSettingChanged(
    entityType,
    ENTITY_FORM_TYPE.ADD_OR_EDIT,
    INPUT_TYPE.CONDITIONS,
    key,
    actionCreator(entityType, ENTITY_FORM_TYPE.ADD_OR_EDIT, ...args)
  ))
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TypesElement);