import { defineMessages } from 'react-intl';

export default defineMessages({
  'api.errorCode.authGroup.delete.inUse': {
    id: 'api.errorCode.authGroup.delete.inUse',
    defaultMessage: 'Users belong to this auth group. Please assign them to a different group before deleting'
  },
  'api.errorCode.authGroup.role.notFound': {
    id: 'api.errorCode.authGroup.role.notFound',
    defaultMessage: 'Role not found'
  },
  'api.errorCode.alertMapping.predictor.notEmpty': {
    id: 'api.errorCode.alertMapping.predictor.notEmpty',
    defaultMessage: 'Predictor is required'
  },
  'api.errorCode.alertMapping.predictor.notFound': {
    id: 'api.errorCode.alertMapping.predictor.notFound',
    defaultMessage: 'Predictor {value} not found'
  },
  'api.errorCode.alertMapping.conditions.list.notEmpty': {
    id: 'api.errorCode.alertMapping.conditions.list.notEmpty',
    defaultMessage: 'Conditions is required'
  },
  'api.errorCode.type.notEmpty': {
    id: 'api.errorCode.type.notEmpty',
    defaultMessage: 'Type is required'
  },
  'api.errorCode.name.notEmpty': {
    id: 'api.errorCode.name.notEmpty',
    defaultMessage: 'Name is required'
  },
  'api.errorCode.id.exists': {
    id: 'api.errorCode.id.exists',
    defaultMessage: 'An entity exists with the same id'
  },
  'api.errorCode.id.simpleCharacters': {
    id: 'api.errorCode.id.simpleCharacters',
    defaultMessage: 'Id can contain only letters, numbers, spaces and the characters ._-'
  },
  'api.errorCode.name.simpleCharacters': {
    id: 'api.errorCode.name.simpleCharacters',
    defaultMessage: 'Name can contain only letters, numbers, spaces and the characters ._-'
  },
  'api.errorCode.predictor.inputMapping.sensor.notEmpty': {
    id: 'api.errorCode.predictor.inputMapping.sensor.notEmpty',
    defaultMessage: 'Sensor is required'
  },
  'api.errorCode.predictor.inputMapping.sensor.notFound': {
    id: 'api.errorCode.predictor.inputMapping.sensor.notFound',
    defaultMessage: 'Sensor not found'
  },
  'api.errorCode.model.type.docker.settings.serviceName.notValidDockerServiceModel': {
    id: 'api.errorCode.model.settings.notValidDockerServiceModel',
    defaultMessage: 'Service Name must be a valid docker service model'
  },
  'api.errorCode.model.type.decision.settings.decisionModel.notValidModelDecisionModel': {
    id: 'api.errorCode.model.type.decision.settings.decisionModel.notValidModelDecisionModel',
    defaultMessage: 'Decision Model must be a valid decision model containing a decision with name given by Decision Name'
  },
  'api.errorCode.model.type.decision.settings.decisionModel.isDecisionModel': {
    id: 'api.errorCode.model.type.decision.settings.decisionModel.isDecisionModel',
    defaultMessage: 'Decision Model must be a valid decision model'
  },
  'api.errorCode.model.type.decision.settings.decisionModel.notEmpty': {
    id: 'api.errorCode.model.type.decision.settings.decisionModel.notEmpty',
    defaultMessage: 'Decision Model is required'
  },
  'api.errorCode.model.type.decision.settings.window.notEmpty': {
    id: 'api.errorCode.model.type.decision.settings.window.notEmpty',
    defaultMessage: 'Decision Window is required'
  },
  'api.errorCode.model.type.decision.settings.window.isWindowDuration': {
    id: 'api.errorCode.model.type.decision.settings.window.isWindowDuration',
    defaultMessage: 'Decision Window must be one of PT1H, PT12H, P1D, P2D or P3D'
  },
  'api.errorCode.model.type.rule.settings.conditions.notEmpty': {
    id: 'api.errorCode.model.type.rule.settings.conditions.notEmpty',
    defaultMessage: 'Conditions is required'
  },
  'api.errorCode.model.type.rule.settings.window.notEmpty': {
    id: 'api.errorCode.model.type.rule.settings.window.notEmpty',
    defaultMessage: 'Time Window is required'
  },
  'api.errorCode.model.type.rule.settings.conditions.list.input.notEmpty': {
    id: 'api.errorCode.model.type.rule.settings.conditions.list.input.notEmpty',
    defaultMessage: 'Input is required'
  },
  'api.errorCode.model.type.rule.settings.conditions.list.feature.notEmpty': {
    id: 'api.errorCode.model.type.rule.settings.conditions.list.feature.notEmpty',
    defaultMessage: 'Feature is required'
  },
  'api.errorCode.model.type.rule.settings.conditions.list.property.notEmpty': {
    id: 'api.errorCode.model.type.rule.settings.conditions.list.property.notEmpty',
    defaultMessage: 'Property is required'
  },
  'api.errorCode.model.type.featureGroup.settings.window.notEmpty': {
    id: 'api.errorCode.model.type.featureGroup.settings.window.notEmpty',
    defaultMessage: 'Time Window is required'
  },
  'api.errorCode.model.type.featureGroup.settings.features.notEmpty': {
    id: 'api.errorCode.model.type.featureGroup.settings.features.notEmpty',
    defaultMessage: 'Features is required'
  },
  'api.errorCode.review.trends.notEmpty': {
    id: 'api.errorCode.review.trends.notEmpty',
    defaultMessage: 'No trends match query'
  },
  'api.errorCode.review.namedRegion.name.notEmpty': {
    id: 'api.errorCode.review.namedRegion.name.notEmpty',
    defaultMessage: 'Name is required'
  },
  'api.errorCode.review.region.name.notUnique': {
    id: 'api.errorCode.review.region.name.notUnique',
    defaultMessage: 'Name must be unique'
  },
  'api.errorCode.review.region.trendType.notFound': {
    id: 'api.errorCode.review.region.trendType.notFound',
    defaultMessage: 'Trend type not found'
  },
  'api.errorCode.sensorConnector.type.cannotCreateProphes': {
    id: 'api.errorCode.sensorConnector.type.cannotCreateProphes',
    defaultMessage: 'Unable to create sensor connector of type PROPHES'
  },
  'api.errorCode.sensor.interval.isIsoDuration': {
    id: 'api.errorCode.sensor.interval.isIsoDuration',
    defaultMessage: 'Interval must be a duration in ISO 8601 format (e.g. PT1H)'
  },
  'api.errorCode.sensor.unitId.notFound': {
    id: 'api.errorCode.sensor.unitId.notFound',
    defaultMessage: 'Unit not found'
  },
  'api.errorCode.sensor.type.piPoint.settings.server.notEmpty': {
    id: 'api.errorCode.sensor.piPoint.settings.server.notEmpty',
    defaultMessage: 'Server is required'
  },
  'api.errorCode.sensor.type.piPoint.settings.tag.notEmpty': {
    id: 'api.errorCode.sensor.type.piPoint.settings.tag.notEmpty',
    defaultMessage: 'Tag is required'
  },
  'api.errorCode.sensor.type.PROPHESLoadTestSensor.settings.id.notEmpty': {
    id: 'api.errorCode.sensor.type.PROPHESLoadTestSensor.settings.id.notEmpty',
    defaultMessage: 'Id is required'
  },
  'api.errorCode.sensor.type.PROPHESLoadTestSensor.settings.uom.notEmpty': {
    id: 'api.errorCode.sensor.type.PROPHESLoadTestSensor.settings.uom.notEmpty',
    defaultMessage: 'Uom is required'
  },
  'api.errorCode.sensor.type.PROPHESLoadTestSensor.settings.valuetype.notEmpty': {
    id: 'api.errorCode.sensor.type.PROPHESLoadTestSensor.settings.valuetype.notEmpty',
    defaultMessage: 'Value Type is required'
  },
  'api.errorCode.sensor.type.PROPHESLoadTestSensor.settings.testvalues.notEmpty': {
    id: 'api.errorCode.sensor.type.PROPHESLoadTestSensor.settings.testvalues.notEmpty',
    defaultMessage: 'Test Values is required'
  },
  'api.errorCode.sensor.type.predictorOutput.settings.predictor.notEmpty': {
    id: 'api.errorCode.sensor.type.predictorOutput.settings.predictor.notEmpty',
    defaultMessage: 'Predictor is required'
  },
  'api.errorCode.sensor.type.predictorOutput.settings.output.notEmpty': {
    id: 'api.errorCode.sensor.type.predictorOutput.settings.output.notEmpty',
    defaultMessage: 'Output is required'
  },
  'api.errorCode.sensor.type.predictorOutput.settings.output.notFound': {
    id: 'api.errorCode.sensor.type.predictorOutput.settings.output.notFound',
    defaultMessage: 'Predictor output not found'
  },
  'api.errorCode.userMapping.unitId.notFound': {
    id: 'api.errorCode.userMapping.unitId.notFound',
    defaultMessage: 'Unit not found'
  },
  'api.errorCode.userMapping.email.empty': {
    id: 'api.errorCode.userMapping.email.empty',
    defaultMessage: 'Email Address is required'
  },
  'api.errorCode.userMapping.email.invalid': {
    id: 'api.errorCode.userMapping.email.invalid',
    defaultMessage: 'Email Address must be a valid email address'
  },
  'api.errorCode.userMapping.id.empty': {
    id: 'api.errorCode.userMapping.id.empty',
    defaultMessage: 'Id must not be empty'
  },
  'api.errorCode.userMapping.email.exists': {
    id: 'api.errorCode.userMapping.email.exists',
    defaultMessage: 'A user exists with the same email'
  },
  'api.errorCode.model.type.docker.settings.serviceName.notEmpty': {
    id: 'api.errorCode.model.type.docker.settings.serviceName.notEmpty',
    defaultMessage: 'Service Name is required'
  },
  'api.errorCode.alertConnector.type.smtp.settings.serverAddress.isUrl': {
    id: 'api.errorCode.alertConnector.type.smtp.settings.serverAddress.isUrl',
    defaultMessage: 'Server Address must be a valid url'
  },
  'api.errorCode.alertConnector.type.smtp.settings.serverAddress.notEmpty': {
    id: 'api.errorCode.alertConnector.type.smtp.settings.serverAddress.notEmpty',
    defaultMessage: 'Server Address is required'
  },
  'api.errorCode.alertConnector.type.smtp.settings.port.isInteger': {
    id: 'api.errorCode.alertConnector.type.smtp.settings.port.isInteger',
    defaultMessage: 'Port must be an integer'
  },
  'api.errorCode.alertConnector.type.smtp.settings.port.notEmpty': {
    id: 'api.errorCode.alertConnector.type.smtp.settings.port.notEmpty',
    defaultMessage: 'Port is required'
  },
  'api.errorCode.alertConnector.type.smtp.settings.username.notEmpty': {
    id: 'api.errorCode.alertConnector.type.smtp.settings.username.notEmpty',
    defaultMessage: 'Username is required'
  },
  'api.errorCode.alertConnector.type.smtp.settings.password.notEmpty': {
    id: 'api.errorCode.alertConnector.type.smtp.settings.password.notEmpty',
    defaultMessage: 'Password is required'
  },
  'api.errorCode.alertConnector.type.smtp.settings.template.isHtml': {
    id: 'api.errorCode.alertConnector.type.smtp.settings.template.isHtml',
    defaultMessage: 'Template must be valid HTML'
  },
  'api.errorCode.alertConnector.type.smtp.settings.template.notEmpty': {
    id: 'api.errorCode.alertConnector.type.smtp.settings.template.notEmpty',
    defaultMessage: 'Template is required'
  },
  'api.errorCode.alertMapping.type.label.settings.name.notEmpty': {
    id: 'api.errorCode.alertMapping.type.label.settings.name.notEmpty',
    defaultMessage: 'Name is required'
  },
  'api.errorCode.alertMapping.type.label.settings.window.notEmpty': {
    id: 'api.errorCode.alertMapping.type.label.settings.window.notEmpty',
    defaultMessage: 'Time Window is required'
  },
  'api.errorCode.alertMapping.type.builtInEmail.settings.emailAddress.isEmail': {
    id: 'api.errorCode.alertMapping.type.builtInEmail.settings.emailAddress.isEmail',
    defaultMessage: 'Email Address must be a valid email address'
  },
  'api.errorCode.alertMapping.type.builtInEmail.settings.emailAddress.notEmpty': {
    id: 'api.errorCode.alertMapping.type.builtInEmail.settings.emailAddress.notEmpty',
    defaultMessage: 'Email Address is required'
  },
  'api.errorCode.alertMapping.type.email.settings.emailAddress.isEmail': {
    id: 'api.errorCode.alertMapping.type.email.settings.emailAddress.isEmail',
    defaultMessage: 'Email Address must be a valid email address'
  },
  'api.errorCode.alertMapping.type.email.settings.emailAddress.notEmpty': {
    id: 'api.errorCode.alertMapping.type.email.settings.emailAddress.notEmpty',
    defaultMessage: 'Email Address is required'
  },
  'api.errorCode.alertMapping.type.phalanxCustomerAlert.settings.serialisedEquipmentId.notEmpty': {
    id: 'api.errorCode.alertMapping.type.phalanxCustomerAlert.settings.serialisedEquipmentId.notEmpty',
    defaultMessage: 'Serialised Equipment Id is required'
  },
  'api.errorCode.alertMapping.type.phalanxCustomerAlert.settings.template.notEmpty': {
    id: 'api.errorCode.alertMapping.type.phalanxCustomerAlert.settings.template.notEmpty',
    defaultMessage: 'Template is required'
  },
  'api.errorCode.alertMapping.type.phalanxCreateServiceWork.settings.location.notEmpty': {
    id: 'api.errorCode.alertMapping.type.phalanxCreateServiceWork.settings.location.notEmpty',
    defaultMessage: 'Location is required'
  },
  'api.errorCode.alertMapping.type.phalanxCreateServiceWork.settings.serialisedEquipmentId.notEmpty': {
    id: 'api.errorCode.alertMapping.type.phalanxCreateServiceWork.settings.serialisedEquipmentId.notEmpty',
    defaultMessage: 'Serialised Equipment Id'
  },
  'api.errorCode.alertMapping.type.phalanxReceiptEquipment.settings.location.notEmpty': {
    id: 'api.errorCode.alertMapping.type.phalanxReceiptEquipment.settings.location.notEmpty',
    defaultMessage: 'Location is required'
  },
  'api.errorCode.alertMapping.type.phalanxReceiptEquipment.settings.serialisedEquipmentId.notEmpty': {
    id: 'api.errorCode.alertMapping.type.phalanxReceiptEquipment.settings.serialisedEquipmentId.notEmpty',
    defaultMessage: 'Serialised Equipment Id is required'
  },
  'api.errorCode.actionConnector.type.phalanx.settings.portalUrl.isUrl': {
    id: 'api.errorCode.actionConnector.type.phalanx.settings.portalUrl.isUrl',
    defaultMessage: 'Portal Url must be a valid url'
  },
  'api.errorCode.actionConnector.type.phalanx.settings.portalUrl.notEmpty': {
    id: 'api.errorCode.actionConnector.type.phalanx.settings.portalUrl.notEmpty',
    defaultMessage: 'Portal Url is required'
  },
  'api.errorCode.actionConnector.type.phalanx.settings.appId.notEmpty': {
    id: 'api.errorCode.actionConnector.type.phalanx.settings.appId.notEmpty',
    defaultMessage: 'App Id is required'
  },
  'api.errorCode.actionConnector.type.phalanx.settings.appSecret.notEmpty': {
    id: 'api.errorCode.actionConnector.type.phalanx.settings.appSecret.notEmpty',
    defaultMessage: 'App Secret is required'
  },
  'api.errorCode.actionConnector.type.phalanx.settings.cookie.notEmpty': {
    id: 'api.errorCode.actionConnector.type.phalanx.settings.cookie.notEmpty',
    defaultMessage: 'Cookie is required'
  },
  'api.errorCode.actionMapping.type.createWork.settings.location.notEmpty': {
    id: 'api.errorCode.actionMapping.type.createWork.settings.location.notEmpty',
    defaultMessage: 'Location is required'
  },
  'api.errorCode.actionMapping.type.createWork.settings.serialisedEquipmentId.notEmpty': {
    id: 'api.errorCode.actionMapping.type.createWork.settings.serialisedEquipmentId.notEmpty',
    defaultMessage: 'Serialised Equipment Id is required'
  },
  'api.errorCode.condition.type.greaterThan.settings.value.notEmpty': {
    id: 'api.errorCode.condition.type.greaterThan.settings.value.notEmpty',
    defaultMessage: 'Value is required'
  },
  'api.errorCode.condition.type.greaterThan.settings.value.isNumber': {
    id: 'api.errorCode.condition.type.greaterThan.settings.value.isNumber',
    defaultMessage: 'Value must be a number'
  },
  'api.errorCode.condition.type.lessThan.settings.value.notEmpty': {
    id: 'api.errorCode.condition.type.lessThan.settings.value.notEmpty',
    defaultMessage: 'Value is required'
  },
  'api.errorCode.condition.type.lessThan.settings.value.isNumber': {
    id: 'api.errorCode.condition.type.lessThan.settings.value.isNumber',
    defaultMessage: 'Value must be a number'
  },
  'api.errorCode.condition.type.equals.settings.value.notEmpty': {
    id: 'api.errorCode.condition.type.equals.settings.value.notEmpty',
    defaultMessage: 'Value is required'
  },
  'api.errorCode.condition.type.inState.settings.value.notEmpty': {
    id: 'api.errorCode.condition.type.inState.settings.value.notEmpty',
    defaultMessage: 'Value is required'
  },
  'api.errorCode.trend.from.notEmpty': {
    id: 'api.errorCode.trend.from.notEmpty',
    defaultMessage: 'From is required'
  },
  'api.errorCode.trend.to.notEmpty': {
    id: 'api.errorCode.trend.to.notEmpty',
    defaultMessage: 'To is required'
  },
  'api.errorCode.trend.description.notEmpty': {
    id: 'api.errorCode.trend.description.notEmpty',
    defaultMessage: 'Description is required'
  },
  'api.errorCode.trend.predictor.notEmpty': {
    id: 'api.errorCode.trend.predictor.notEmpty',
    defaultMessage: 'Predictor is required'
  },
  'api.errorCode.trend.predictor.notFound': {
    id: 'api.errorCode.trend.predictor.notFound',
    defaultMessage: 'Predictor not found'
  },
  'api.errorCode.trend.type.investigate.settings.instructions.notEmpty': {
    id: 'api.errorCode.trend.type.investigate.settings.instructions.notEmpty',
    defaultMessage: 'Instructions is required'
  },
  'api.errorCode.import.invalidCsv': {
    id: 'api.errorCode.import.invalidCsv',
    defaultMessage: 'Ensure all required columns are present'
  },
  'api.errorCode.trend.from.isoFormat': {
    id: 'api.errorCode.trend.from.isoFormat',
    defaultMessage: 'From must be in ISO 8601 format (e.g. 2000-01-01T00:00:00Z)'
  },
  'api.errorCode.trend.to.isoFormat': {
    id: 'api.errorCode.trend.to.isoFormat',
    defaultMessage: 'To must be in ISO 8601 format (e.g. 2000-01-01T00:00:00Z)'
  },
  'api.errorCode.trend.to.afterFrom': {
    id: 'api.errorCode.trend.to.afterFrom',
    defaultMessage: 'To must be later than From'
  },
  'api.errorCode.trendType.icon.notEmpty': {
    id: 'api.errorCode.trendType.icon.notEmpty',
    defaultMessage: 'Icon is required'
  },
  'api.errorCode.trendType.colour.notEmpty': {
    id: 'api.errorCode.trendType.colour.notEmpty',
    defaultMessage: 'Colour is required'
  },
  'api.errorCode.trendType.existsWithSameName': {
    id: 'api.errorCode.trendType.existsWithSameName',
    defaultMessage: 'A trend type exists with the same name'
  },
  'api.errorCode.change.password.notEmpty': {
    id: 'api.errorCode.change.password.notEmpty',
    defaultMessage: 'Password is required'
  },
  'api.errorCode.change.password.distinct': {
    id: 'api.errorCode.change.password.distinct',
    defaultMessage: 'Password must contain at least 6 different characters'
  },
  'api.errorCode.change.password.length': {
    id: 'api.errorCode.change.password.length',
    defaultMessage: 'Password must be at least 8 characters'
  },
  'api.errorCode.change.password.characters': {
    id: 'api.errorCode.change.password.characters',
    defaultMessage: 'Password can only contain letters, numbers and the characters *#?!@$%^&*-'
  },
  'api.errorCode.forgot.email.notEmpty': {
    id: 'api.errorCode.forgot.email.notEmpty',
    defaultMessage: 'Email is required'
  },
  'api.errorCode.forgot.email.valid': {
    id: 'api.errorCode.forgot.email.valid',
    defaultMessage: 'Email must be a valid email address'
  },
  'api.errorCode.forgot.email.notFound': {
    id: 'api.errorCode.forgot.email.notFound',
    defaultMessage: 'User with that email address not found'
  },
  'api.errorCode.forgot.email.notConfirmed': {
    id: 'api.errorCode.forgot.email.notConfirmed',
    defaultMessage: 'Email address must be confirmed'
  },
  'api.errorCode.forgot.email.notLocal': {
    id: 'api.errorCode.forgot.email.notLocal',
    defaultMessage: 'User is not authorised to sign-in using this method'
  },
  'api.errorCode.register.name.notEmpty': {
    id: 'api.errorCode.register.name.notEmpty',
    defaultMessage: 'Name is required'
  },
  'api.errorCode.register.name.maxLength': {
    id: 'api.errorCode.register.name.maxLength',
    defaultMessage: 'Name must be less than 100 characters'
  },
  'api.errorCode.login.password.notEmpty': {
    id: 'api.errorCode.login.password.notEmpty',
    defaultMessage: 'Password is required'
  },
  'api.errorCode.login.password.incorrect': {
    id: 'api.errorCode.login.password.incorrect',
    defaultMessage: 'The password provided is incorrect'
  },
  'api.errorCode.register.organisation.notEmpty': {
    id: 'api.errorCode.register.organisation.notEmpty',
    defaultMessage: 'Organisation is required'
  },
  'api.errorCode.register.organisation.simpleCharacters': {
    id: 'api.errorCode.register.organisation.simpleCharacters',
    defaultMessage: 'Organisation must contain only letters, numbers and spaces'
  },
  'api.errorCode.register.organisation.length': {
    id: 'api.errorCode.register.organisation.length',
    defaultMessage: 'Organisation must be between 2 and 30 characters'
  },
  'api.errorCode.register.email.notEmpty': {
    id: 'api.errorCode.register.email.notEmpty',
    defaultMessage: 'Email is required'
  },
  'api.errorCode.register.email.valid': {
    id: 'api.errorCode.register.email.valid',
    defaultMessage: 'Email must be a valid email address'
  },
  'api.errorCode.register.email.exists': {
    id: 'api.errorCode.register.email.exists',
    defaultMessage: 'A user exists with the same email'
  },
  'api.errorCode.resend.email.notEmpty': {
    id: 'api.errorCode.resend.email.notEmpty',
    defaultMessage: 'Email is required'
  },
  'api.errorCode.resend.email.valid': {
    id: 'api.errorCode.resend.email.valid',
    defaultMessage: 'Email must be a valid email address'
  },
  'api.errorCode.resend.email.notFound': {
    id: 'api.errorCode.resend.email.notFound',
    defaultMessage: 'User with that email address not found'
  },
  'api.errorCode.resend.email.confirmed': {
    id: 'api.errorCode.resend.email.confirmed',
    defaultMessage: 'Email address is already confirmed'
  },
  'api.errorCode.resend.email.resending': {
    id: 'api.errorCode.resend.email.resending',
    defaultMessage: 'Already resending confirmation'
  },
  'api.errorCode.resend.email.notLocal': {
    id: 'api.errorCode.resend.email.notLocal',
    defaultMessage: 'User is not authorised to sign-in using this method'
  },
  'api.errorCode.enumeration.states.notEmpty': {
    id: 'api.errorCode.enumeration.states.notEmpty',
    defaultMessage: 'States must contain at least one state'
  },
  'api.errorCode.enumeration.states.stateNotEmpty': {
    id: 'api.errorCode.enumeration.states.stateNotEmpty',
    defaultMessage: 'A state must not be an empty string'
  },
  'api.errorCode.enumeration.delete.inUseBySensor': {
    id: 'api.errorCode.enumeration.delete.inUseBySensor',
    defaultMessage: 'Unable to delete as in use by a sensor'
  },
  'api.errorCode.enumeration.delete.inUseByModel': {
    id: 'api.errorCode.enumeration.delete.inUseByModel',
    defaultMessage: 'Unable to delete as in use by a model'
  },
  'api.errorCode.delete.builtIn': {
    id: 'api.errorCode.delete.builtIn',
    defaultMessage: 'Unable to delete built-in entity'
  },
  'api.errorCode.unit.geoShape.isValidGeoJson': {
    id: 'api.errorCode.unit.geoShape.isValidGeoJson',
    defaultMessage: 'Geo Shape must be a valid polygon'
  },
  'api.errorCode.unitCategory.delete.inUseByUnitType': {
    id: 'api.errorCode.unitCategory.delete.inUseByUnitType',
    defaultMessage: 'Unable to delete as in use by a unit type'
  },
  'api.errorCode.unitCategory.delete.inUseByUnit': {
    id: 'api.errorCode.unitCategory.delete.inUseByUnit',
    defaultMessage: 'Unable to delete as in use by a unit'
  },
  'api.errorCode.edit.builtIn': {
    id: 'api.errorCode.edit.builtIn',
    defaultMessage: 'Unable to edit built-in entity'
  },
  'api.errorCode.unitCategory.icon.notEmpty': {
    id: 'api.errorCode.unitCategory.icon.notEmpty',
    defaultMessage: 'Icon is required'
  },
  'api.errorCode.unitCategory.existsWithSameName': {
    id: 'api.errorCode.unitCategory.existsWithSameName',
    defaultMessage: 'A unit category with the same name already exists'
  },
  'api.errorCode.unitCategory.existsWithSameIcon': {
    id: 'api.errorCode.unitCategory.existsWithSameIcon',
    defaultMessage: 'A unit category with the same icon already exists'
  },
  'api.errorCode.unitCategory.delete.hasChildTypes': {
    id: 'api.errorCode.unitCategory.delete.hasChildTypes',
    defaultMessage: 'Unable to delete as has child types'
  },
  'api.errorCode.unitCategory.parentType.notFound': {
    id: 'api.errorCode.unitCategory.parentType.notFound',
    defaultMessage: 'Category is required'
  },
  'api.errorCode.unitCategory.baseType.notFound': {
    id: 'api.errorCode.unitCategory.baseType.notFound',
    defaultMessage: 'Base Type is required'
  },
  'api.errorCode.unitCategory.baseType.notInSameCategory': {
    id: 'api.errorCode.unitCategory.baseType.notInSameCategory',
    defaultMessage: 'Base Type must belong to the same category as this type'
  },
  'api.errorCode.unitCategory.baseType.notCircular': {
    id: 'api.errorCode.unitCategory.baseType.notCircular',
    defaultMessage: 'Base Type hierarchy must not be circular'
  },
  'api.errorCode.unitTypeAttribute.name.notEmpty': {
    id: 'api.errorCode.unitTypeAttribute.name.notEmpty',
    defaultMessage: 'Name is required'
  },
  'api.errorCode.unitTypeAttribute.name.notUnique': {
    id: 'api.errorCode.unitTypeAttribute.name.notUnique',
    defaultMessage: 'Name must be unique among attributes and parameters'
  },
  'api.errorCode.unitTypeAttribute.valueType.isValid': {
    id: 'api.errorCode.unitTypeAttribute.valueType.isValid',
    defaultMessage: 'Value type must be one of float, date, geoPoint, string or enumeration'
  },
  'api.errorCode.unitTypeAttribute.enumeration.nullForOtherValueTypes': {
    id: 'api.errorCode.unitTypeAttribute.enumeration.nullForOtherValueTypes',
    defaultMessage: 'Enumeration must be null when value type is not enumeration'
  },
  'api.errorCode.unitTypeAttribute.enumeration.notFound': {
    id: 'api.errorCode.unitTypeAttribute.enumeration.notFound',
    defaultMessage: 'Enumeration is required'
  },
  'api.errorCode.unitTypeAttribute.name.notUniqueInBaseTypes': {
    id: 'api.errorCode.unitTypeAttribute.name.notUniqueInBaseTypes',
    defaultMessage: 'Name must not match any attribute or parameter on any base type'
  },
  'api.errorCode.unitTypeAttribute.sensorMappings.missingMapping': {
    id: 'api.errorCode.unitTypeAttribute.sensorMappings.missingMapping',
    defaultMessage: 'A sensor must be provided for each existing instance of the unit type'
  },
  'api.errorCode.unitTypeAttribute.sensorMappingTemplate.empty': {
    id: 'api.errorCode.unitTypeAttribute.sensorMappingTemplate.empty',
    defaultMessage: 'The template must not be empty'
  },
  'api.errorCode.unitTypeAttribute.sensorMappingTemplate.tooLong': {
    id: 'api.errorCode.unitTypeAttribute.sensorMappingTemplate.tooLong',
    defaultMessage: 'The template is limited to 100 characters'
  },
  'api.errorCode.unitTypeAttribute.sensorMappingTemplate.syntaxError': {
    id: 'api.errorCode.unitTypeAttribute.sensorMappingTemplate.syntaxError',
    defaultMessage: 'Invalid syntax for sensor mapping template'
  },
  'api.errorCode.unitTypeAttribute.sensorMappingTemplate.undefinedMacro': {
    id: 'api.errorCode.unitTypeAttribute.sensorMappingTemplate.undefinedMacro',
    defaultMessage: 'Unknown property used in template. The available properties are "id", "name" and "description"'
  },
  'api.errorCode.unitTypeAttribute.sensorMappingTemplate.missingSensor': {
    id: 'api.errorCode.unitTypeAttribute.sensorMappingTemplate.missingSensor',
    defaultMessage: 'For at least one instance of the unit type, there was no sensor whose ID matched the template'
  },
  'api.errorCode.unitTypeInstance.delete.inUseByChildType': {
    id: 'api.errorCode.unitTypeInstance.delete.inUseByChildType',
    defaultMessage: 'Unable to delete as unit is an instance of a child type'
  },
  'api.errorCode.unitTypeInstance.type.exists': {
    id: 'api.errorCode.unitTypeInstance.type.exists',
    defaultMessage: 'Unit is already an instance of this type'
  },
  'api.errorCode.unitTypeInstance.unitId.notFound': {
    id: 'api.errorCode.unitTypeInstance.unitId.notFound',
    defaultMessage: 'Unit not found'
  },
  'api.errorCode.unitTypeInstance.type.notFound': {
    id: 'api.errorCode.unitTypeInstance.type.notFound',
    defaultMessage: 'Unit Type not found'
  },
  'api.errorCode.unitTypeInstance.type.mustMatchUnitCategory': {
    id: 'api.errorCode.unitTypeInstance.type.mustMatchUnitCategory',
    defaultMessage: 'Unit cannot be an instance of a type in a different category'
  },
  'api.errorCode.unitTypeInstance.type.noBaseTypeInstance': {
    id: 'api.errorCode.unitTypeInstance.type.noBaseTypeInstance',
    defaultMessage: 'Unit must be an instance of all base types'
  },
  'api.errorCode.setting.sensorNotEmpty': {
    id: 'api.errorCode.setting.sensorNotEmpty',
    defaultMessage: 'Sensor is required'
  },
  'api.errorCode.setting.invalidValueType': {
    id: 'api.errorCode.setting.invalidValueType',
    defaultMessage: 'Sensor must have valid value type'
  },
  'api.errorCode.setting.sensorNotFound': {
    id: 'api.errorCode.setting.sensorNotFound',
    defaultMessage: 'Sensor not found'
  },
  'api.errorCode.unitTypeParameter.unitType.notFound': {
    id: 'api.errorCode.unitTypeParameter.unitType.notFound',
    defaultMessage: 'Unit Type not found'
  },
  'api.errorCode.unitTypeParameter.name.empty': {
    id: 'api.errorCode.unitTypeParameter.name.empty',
    defaultMessage: 'Name is required'
  },
  'api.errorCode.unitTypeParameter.valueType.isValid': {
    id: 'api.errorCode.unitTypeParameter.valueType.isValid',
    defaultMessage: 'Unit Type not found'
  },
  'api.errorCode.unitTypeParameter.name.notEmpty': {
    id: 'api.errorCode.unitTypeParameter.unitType.notFound',
    defaultMessage: 'Value Type must be one of float, date, geoPoint, string or enumeration'
  },
  'api.errorCode.unitTypeParameter.enumeration.notFound': {
    id: 'api.errorCode.unitTypeParameter.enumeration.notFound',
    defaultMessage: 'Enumeration not found'
  },
  'api.errorCode.unitTypeParameter.name.notUnique': {
    id: 'api.errorCode.unitTypeParameter.name.notUnique',
    defaultMessage: 'Name must be unique among attributes and parameters'
  },
  'api.errorCode.unitTypeParameter.name.notInBaseType': {
    id: 'api.errorCode.unitTypeParameter.name.notInBaseType',
    defaultMessage: 'Name must not match any attribute or parameter on any base type'
  },
  'api.errorCode.unitTypeParameter.valueMappings.missingMapping': {
    id: 'api.errorCode.unitTypeParameter.valueMappings.missingMapping',
    defaultMessage: 'A value must be provided for each existing instance of the unit type'
  },
  'api.errorCode.unitTypePredictor.unitType.notFound': {
    id: 'api.errorCode.unitTypePredictor.unitType.notFound',
    defaultMessage: 'Unit Type not found'
  },
  'api.errorCode.unitTypePredictor.attributes.notEmpty': {
    id: 'api.errorCode.unitTypePredictor.attributes.notEmpty',
    defaultMessage: 'Attributes must not be empty'
  },
  'api.errorCode.setting.attributeNotEmpty': {
    id: 'api.errorCode.setting.attributeNotEmpty',
    defaultMessage: 'Attribute is required'
  },
  'api.errorCode.type.notValidForConnectorType': {
    id: 'api.errorCode.type.notValidForConnectorType',
    defaultMessage: 'Type is not valid for connector'
  },
  'api.errorCode.connector.notEmpty': {
    id: 'api.errorCode.connector.notEmpty',
    defaultMessage: 'Connector is required'
  },
  'api.errorCode.connector.notFound': {
    id: 'api.errorCode.connector.notFound',
    defaultMessage: 'Connector not found'
  },
  'api.errorCode.label.delete.inUseByTrend': {
    id: 'api.errorCode.label.delete.inUseByTrend',
    defaultMessage: 'Unable to delete as in use by a trend'
  },
  'api.errorCode.label.name.exists': {
    id: 'api.errorCode.label.name.exists',
    defaultMessage: 'Label exists with the same name'
  },
  'api.errorCode.label.unitId.notFound': {
    id: 'api.errorCode.label.unitId.notFound',
    defaultMessage: 'Unit not found'
  }
});