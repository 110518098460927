import _ from 'lodash';
import createCachedSelector from 're-reselect';
import * as fromState from '../../../state/reducers';
import * as chartSelectors from '../../../state/selectors/chart';
import { ENTITY_PROPERTY, FEATURE_SETTINGS, SELECTION_TYPE, VALUE_TYPE } from '../../../utils/constants';

export default createCachedSelector(
  [
    chartSelectors.getFunctionSensorIds,
    _.partialRight(fromState.getSelected, SELECTION_TYPE.FUNCTION),
    chartSelectors.getFunctionSensors,
    chartSelectors.getFunctionDescriptions,
    chartSelectors.getFunctionOffsetScale,
    chartSelectors.getFunctionAlarmRanges,
    chartSelectors.getColours,
    fromState.getFeaturesToggle,
    fromState.getSelectedFeature,
    fromState.getSelectedProperty
  ],
  (functionSensorIds, selectedFunctions, functionSensors, functionDescriptions, functionOffsetScale, functionAlarmRanges, colours, featuresToggle, selectedFeature, selectedProperty) => {
    return _.chain(functionSensorIds)
      .filter(functionSensorId => _.includes(selectedFunctions, functionSensorId))
      .map(functionSensorId => {
        const valueType = _.get(functionSensors[functionSensorId], ENTITY_PROPERTY.SENSOR.VALUE_TYPE);
        return {
          id: functionSensorId,
          className: _.replace(functionSensorId, / /g, '-'),
          offset: _.get(functionOffsetScale[functionSensorId], 'offset'),
          scale: _.get(functionOffsetScale[functionSensorId], 'scale'),
          ranges: (featuresToggle && FEATURE_SETTINGS[selectedFeature].HIDE_ALARM_RANGES)
            ? []
            : _.filter(functionAlarmRanges[functionSensorId], alarmRange => alarmRange.min !== alarmRange.max),
          colour: colours[functionSensorId],
          valueScale: 1,
          strokeWidthMultiplier: _.includes([VALUE_TYPE.FLOAT, VALUE_TYPE.ENUMERATION], valueType)
            ? 1
            : 0,
          description: functionDescriptions[functionSensorId] || _.get(functionSensors[functionSensorId], ENTITY_PROPERTY.SENSOR.NAME) || functionSensorId,
          unitsOfMeasurement: (featuresToggle && FEATURE_SETTINGS[selectedFeature].HIDE_UNITS_OF_MEASUREMENT)
            ? ''
            : `${_.get(functionSensors[functionSensorId], ENTITY_PROPERTY.SENSOR.UNITS_OF_MEASUREMENT)}${featuresToggle ? FEATURE_SETTINGS[selectedFeature].UNITS_OF_MEASUREMENT_SUFFIX || '' : ''}`,
          valueType
        };
      })
      .value();
  }
)(
  (state, chartType) => chartType
);