import { FETCH_PAGE_FULFILLED } from '../../actions/entity';

export default (state = null, action) => {
  switch (action.type) {
    case FETCH_PAGE_FULFILLED:
      return action.payload.entityPage.page;
    default:
      return state;
  }
};

export const getPage = state => state;