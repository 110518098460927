import setDatesOnSetQuickRange from './setDatesOnSetQuickRange';
import loadGroupOnNavigate from './loadGroupOnNavigate';
import loadDataOnSetDates from './loadDataOnSetDates';
import clearCopiedTextAfterAShortTime from './clearCopiedTextAfterAShortTime';
import editPredictorOnSetFunctionSensors from './editPredictorOnSetFunctionSensors';
import editPredictorOnRemoveFunction from './editPredictorOnRemoveFunction';
import editPredictorOnEditFunction from './editPredictorOnEditFunction';
import clearEditingFunctionOnEditPredictorFulfilled from './clearEditingFunctionOnEditPredictorFulfilled';
import loadDataOnSetFunctionSensors from './loadDataOnSetFunctionSensors';
import loadCompareTrend from './loadCompareTrend';
import syncSelectedInputsAndOutputs from './syncSelectedInputsAndOutputs';
import syncQuickRange from './syncQuickRange';
import loadMoreTrends from './loadMoreTrends';
import loadTrendsOnSetTrendSearchQuery from './loadTrendsOnSetTrendSearchQuery';
import setDatesOnEditModel from './setDatesOnEditModel';
import { combineEpicsWithNames } from '../../../utils/helpers';

export default combineEpicsWithNames({
  setDatesOnSetQuickRange,
  loadGroupOnNavigate,
  loadDataOnSetDates,
  clearCopiedTextAfterAShortTime,
  editPredictorOnSetFunctionSensors,
  editPredictorOnRemoveFunction,
  editPredictorOnEditFunction,
  clearEditingFunctionOnEditPredictorFulfilled,
  loadDataOnSetFunctionSensors,
  loadCompareTrend,
  syncSelectedInputsAndOutputs,
  syncQuickRange,
  loadMoreTrends,
  loadTrendsOnSetTrendSearchQuery,
  setDatesOnEditModel
});