import moment from 'moment';
import { UNIT_SHORTHAND, VALUE_TYPE, DATE_FORMAT } from '../../constants';

export default (value, valueType, unitsOfMeasurement) => {
  if (valueType === VALUE_TYPE.FLOAT) {
    return `${value}${UNIT_SHORTHAND[unitsOfMeasurement] || unitsOfMeasurement}`;
  };
  if (valueType === VALUE_TYPE.STRING) {
    return value;
  };
  if (valueType === VALUE_TYPE.DATE) {
    return moment.utc(value).local().format(DATE_FORMAT);
  };
  if (valueType === VALUE_TYPE.ENUMERATION) {
    return value;
  };
  return `${value.latitude}, ${value.longitude}`;
};