import _ from 'lodash';
import createCachedSelector from 're-reselect';
import * as fromState from '../../../state/reducers';
import * as chartSelectors from '../../../state/selectors/chart';
import { SELECTION_TYPE } from '../../../utils/constants';

export default createCachedSelector(
  [
    chartSelectors.getInputs,
    chartSelectors.getOutputs,
    chartSelectors.getFunctionSensorIds,
    _.partialRight(fromState.getSelected, SELECTION_TYPE.INPUT),
    _.partialRight(fromState.getSelected, SELECTION_TYPE.OUTPUT),
    _.partialRight(fromState.getSelected, SELECTION_TYPE.FUNCTION)
  ],
  (inputs, outputs, functionSensorIds, selectedInputs, selectedOutputs, selectedFunctions) => _.reject([
    _.filter(outputs, output => _.includes(selectedOutputs, output)),
    _.filter(inputs, input => _.includes(selectedInputs, input)),
    _.filter(functionSensorIds, functionSensorId => _.includes(selectedFunctions, functionSensorId))
  ], _.isEmpty)
)(
  (state, chartType) => chartType
);