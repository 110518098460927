import { Component, Fragment } from 'react';
import { from, Observable, of } from 'rxjs';
import { flatMap, catchError } from 'rxjs/operators';
import _ from 'lodash';
import * as api from '../../utils/api';
const signalR = require('@aspnet/signalr');

export default class EntityEvents extends Component {
  componentDidMount() {
    if (!this.props.token) {
      return;
    }

    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(`${api.ROOT_URL}stream/entityEvents`, { accessTokenFactory: () => this.props.token })
      .build();

    from(this.connection.start()).pipe(
      flatMap(() =>
        Observable.create(observer =>
          this.connection.stream('Stream').subscribe({
            next: e => observer.next(e)
          }))),
      catchError(err => of(err))
    ).subscribe(e => {
      if (e instanceof Error) {
        return null;
      }

      const entityType = _.camelCase(e.type);
      switch (e.event.eventType) {
        case 1:
          return this.props.update(entityType, e.event.entity);
        default:
          return null;
      }
    });
  }

  componentWillUnmount() {
    this.connection.stop();
  }

  render() {
    return <Fragment />;
  }
}