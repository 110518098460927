import { ofType } from 'redux-observable';
import { merge, of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import * as api from '../../../utils/api';
import { ENTITY_CONFIG, ENTITY_FORM_TYPE, EMPTY_STRING } from '../../../utils/constants';
import entityConfig from '../../../utils/entities';
import { fetchPageFulfilled, FETCH_PAGE, setFormValues, setSort } from '../../actions/entity';
import * as fromState from '../../reducers';
import * as entitySelectors from '../../selectors/entity';
import { querySyncReplace } from '../../actions/navigation';
import { takeUntilAppReset } from 'utils/helpers';

export default (action$, state$) => action$.pipe(
  ofType(FETCH_PAGE),
  withLatestFrom(state$),
  mergeMap(([action, state]) => {
    const formSearchQuery = entitySelectors.getSearchQuery(state, action.entityType);
    if (!action.payload.query && formSearchQuery) {
      const page = fromState.getPage(state, action.entityType);
      return of(
        querySyncReplace({
          pathname: `/${
            entityConfig[action.entityType][ENTITY_CONFIG.PAGE_URL]
          }${
            action.payload.parentId
              ? `/${action.payload.parentId}`
              : EMPTY_STRING
          }`,
          search: `page=${(page || 0) + 1}&query=${encodeURIComponent(JSON.stringify(formSearchQuery))}`
        })
      );
    };
    const query = action.payload.query || (
      entityConfig[action.entityType].parentSearchFilter
        ? {
          filter: entityConfig[action.entityType].parentSearchFilter(action.payload.parentId),
          sort: {}
        }
        : null
    );
    return merge(
      (
        query
          ? api.query({
            controller: entityConfig[action.entityType][ENTITY_CONFIG.API_PATH],
            prefix: entityConfig[action.entityType][ENTITY_CONFIG.API_PREFIX],
            parentId: action.payload.parentId,
            page: action.payload.page,
            query,
            token: fromState.getUser(state).token
          })
          : api.page({
            controller: entityConfig[action.entityType][ENTITY_CONFIG.API_PATH],
            prefix: entityConfig[action.entityType][ENTITY_CONFIG.API_PREFIX],
            parentId: action.payload.parentId,
            page: action.payload.page,
            token: fromState.getUser(state).token
          })
      ).pipe(
        takeUntilAppReset(action$),
        map(entityPage => fetchPageFulfilled(action.entityType, entityPage, action.payload.parentId)),
        catchError(api.onError)
      ),
      of(
        setFormValues(
          action.entityType,
          ENTITY_FORM_TYPE.SEARCH,
          action.payload.query
            ? entityConfig[action.entityType][ENTITY_CONFIG.PARSE_SEARCH_FILTER](action.payload.query.filter)
            : {}
        ),
        ...query && entityConfig[action.entityType][ENTITY_CONFIG.PARSE_SEARCH_SORT]
          ? [
            setSort(
              action.entityType,
              entityConfig[action.entityType][ENTITY_CONFIG.PARSE_SEARCH_SORT](query.sort)
            )
          ]
          : []
      )
    );
  })
);