import { ofType } from 'redux-observable';
import { filter, map, withLatestFrom } from 'rxjs/operators';
import { setFormValues, TOGGLE_MODAL } from '../../actions/entity';
import * as fromState from '../../reducers';
import { ENTITY_FORM_TYPE, ENTITY_MODAL } from '../../../utils/constants';

export default (action$, state$) => action$.pipe(
  ofType(TOGGLE_MODAL),
  withLatestFrom(state$),
  filter(([action, state]) => action.payload.modal === ENTITY_MODAL.ALTERNATIVE_EDIT && fromState.getModalOpen(state, action.entityType, ENTITY_MODAL.ALTERNATIVE_EDIT)),
  map(([action, state]) =>
    setFormValues(action.entityType, ENTITY_FORM_TYPE.ADD_OR_EDIT, {}))
);