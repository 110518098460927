import _ from 'lodash';

export default (value, alarmRanges) => (_.isNull(value) || _.isUndefined(value))
  ? null
  : _.isEmpty(alarmRanges)
    ? -1
    : _.chain(alarmRanges)
      .filter((range, i) => (i === _.size(alarmRanges) - 1 || value <= range.max) && (i === 0 || value >= range.min))
      .map('severity')
      .max()
      .value();