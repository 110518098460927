import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { fetchById, FETCH_FORM_SEARCH_RESULTS_FULFILLED } from '../../actions/entity';
import entityConfig from '../../../utils/entities';

export default action$ => action$.pipe(
  ofType(FETCH_FORM_SEARCH_RESULTS_FULFILLED),
  filter(action => !!entityConfig[action.payload.searchEntityType].fetchByIdSchema),
  mergeMap(action =>
    from(action.payload.results).pipe(
      mergeMap(result =>
        from(entityConfig[action.payload.searchEntityType].fetchByIdSchema).pipe(
          filter(schema => !!result[schema.name]),
          map(schema => fetchById(schema.entityType, result[schema.name]))
        ))
    ))
);