import { Component } from 'react';
import { Button, Modal, Form, Input, Message } from 'semantic-ui-react';
import AnimatedButton from '../shared/AnimatedButton';
import { defineMessages, injectIntl } from 'react-intl';
import _ from 'lodash';
import apiMessages from '../../utils/i18n/shared/api';
import { INVERTED_THEME } from '../../utils/constants';

const messages = defineMessages({
  'header.text': {
    id: 'entityImportModal.header.text',
    defaultMessage: 'Import'
  },
  'cancelButton.text': {
    id: 'entityImportModal.cancelButton.text',
    defaultMessage: 'Cancel'
  },
  'importButton.text': {
    id: 'entityImportModal.importButton.text',
    defaultMessage: 'Import'
  },
  'downloadTemplateButton.text': {
    id: 'entityImportModal.downloadTemplateButton.text',
    defaultMessage: 'Download Template'
  },
  'noFileChosen': {
    id: 'entityImportModal.noFileChosen',
    defaultMessage: 'No file chosen'
  },
  'chooseFileButton.text': {
    id: 'entityImportModal.chooseFileButton.text',
    defaultMessage: 'Choose File'
  },
  'errorMessage.header.invalidCsvFormat': {
    id: 'entityImportModal.errorMessage.header.invalidCsvFormat',
    defaultMessage: 'Invalid .csv format'
  },
  'errorMessage.header.errorInRow': {
    id: 'entityImportModal.errorMessage.header.errorInRow',
    defaultMessage: 'Error in row {row, number} of {total, number}'
  }
});

class EntityImportModal extends Component {
  constructor() {
    super();
    this.state = {
      file: null
    };
  };

  handleFileChange = e => {
    this.setState({ file: e.target.files[0] || null });
  };

  handleClose = () => {
    this.setState({ file: null });
    this.props.onClose();
  };

  static getDerivedStateFromProps(nextProps) {
    if (nextProps.open) {
      return null;
    }

    return {
      file: null
    };
  };

  render() {
    const {
      open,
      trigger,
      performImport,
      isImporting,
      errorRow,
      errorCodes,
      errorTotal,
      onDownloadTemplate,
      intl: {
        formatMessage
      }
    } = this.props;
    const { file } = this.state;
    return (
      <Modal
        open={open}
        trigger={trigger}
        centered
        closeOnDimmerClick={false}
        closeIcon
        size='tiny'
        closeOnEscape
        onClose={this.handleClose}>
        <Modal.Header content={formatMessage(messages['header.text'])} />
        <Modal.Content>
          {_.some(errorCodes) && (
            <Message
              negative
              list={_.map(errorCodes, code => apiMessages[code] ? formatMessage(apiMessages[code]) : code)}
              header={_.isNull(errorRow)
                ? formatMessage(messages['errorMessage.header.invalidCsvFormat'])
                : formatMessage(messages['errorMessage.header.errorInRow'], {
                  row: errorRow,
                  total: errorTotal
                })} />
          )}
          <Form inverted={INVERTED_THEME}>
            <Form.Field>
              <input
                type='file'
                id='file'
                style={{ display: 'none' }}
                accept='.csv'
                onChange={this.handleFileChange} />
              <Input
                disabled
                value={file ? file.name : formatMessage(messages['noFileChosen'])}
                style={{ opacity: 1 }}
                action={(
                  <Button
                    as='label'
                    primary
                    htmlFor='file'
                    type='button'
                    style={{ WebkitAppearance: 'none' }}>
                    {formatMessage(messages['chooseFileButton.text'])}
                  </Button>
                )} />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            content={formatMessage(messages['cancelButton.text'])}
            onClick={this.handleClose} />
          <AnimatedButton
            text={formatMessage(messages['downloadTemplateButton.text'])}
            icon='download'
            primary
            onClick={onDownloadTemplate} />
          <AnimatedButton
            text={formatMessage(messages['importButton.text'])}
            icon='upload'
            loading={isImporting}
            positive
            disabled={!file}
            onClick={() => performImport(file)} />
        </Modal.Actions>
      </Modal>
    );
  };
};

export default injectIntl(EntityImportModal);