import _ from 'lodash';
import { createSelector } from 'reselect';
import * as fromState from '../../../state/reducers';
import { ENTITY_TYPE, ENTITY_PROPERTY, ATTRIBUTE_SOURCE } from '../../constants';

const getAttributes = (allUnitTypes, allUnitTypeAttributes, unitTypeId) => {
  const unitType = allUnitTypes[unitTypeId];
  return unitType
    ? [
      ..._.filter(allUnitTypeAttributes, attr => attr.unitType === unitTypeId),
      ...getAttributes(allUnitTypes, unitType.baseType)
    ]
    : [];
};

export default createSelector(
  [
    (state, { parentId }) => fromState.getById(state, ENTITY_TYPE.UNIT_TYPE_PREDICTOR, parentId),
    state => fromState.getAll(state, ENTITY_TYPE.MODEL),
    state => fromState.getAll(state, ENTITY_TYPE.UNIT_TYPE),
    state => fromState.getAll(state, ENTITY_TYPE.UNIT_TYPE_ATTRIBUTE)
  ],
  (unitTypePredictor, allModels, allUnitTypes, allUnitTypeAttributes) => ({
    attributes: [
      ..._.map(
        getAttributes(allUnitTypes, allUnitTypeAttributes, unitTypePredictor && unitTypePredictor.unitType),
        attribute => ({
          id: `${ATTRIBUTE_SOURCE.UNIT_TYPE_ATTRIBUTE}_${attribute.name}`,
          text: attribute.name,
          source: ATTRIBUTE_SOURCE.UNIT_TYPE_ATTRIBUTE,
          ...attribute
        })
      ),
      ..._.chain(allModels)
        .get(unitTypePredictor && unitTypePredictor.type)
        .get(ENTITY_PROPERTY.MODEL.OUTPUTS)
        .map(output => ({
          id: `${ATTRIBUTE_SOURCE.MODEL_OUTPUT}_${output.name}`,
          source: ATTRIBUTE_SOURCE.MODEL_OUTPUT,
          text: output.name,
          ...output
        }))
        .value()
    ]
  })
);