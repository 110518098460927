import _ from 'lodash';
import { Label } from 'semantic-ui-react';
import { InjectedIntl, injectIntl } from 'react-intl';
import ProgressLabel from 'components/shared/ProgressLabel';
import { StateMachineInstance } from 'types';
import messages from 'utils/i18n/shared/status';

interface Props {
  state: StateMachineInstance;
  intl: InjectedIntl;
}
const StatusColumn = ({
  state,
  intl: {
    formatMessage
  }
}: Props) => {
  if (!state) {
    return null;
  };
  switch (state.state) {
    case 'historical':
      return (
        <ProgressLabel
          content={formatMessage(messages['historical'])}
          color='yellow'
          percent={_.chain(state.history)
            .findLast(['type', 'progress'])
            .get('settings.percentage')
            .toNumber()
            .value() || 0} />
      );
    case 'live':
      return (
        <Label
          color='green'
          content={formatMessage(messages['live'])} />
      );
    case 'complete':
      return (
        <Label
          content={formatMessage(messages['complete'])} />
      );
    case 'error':
      return (
        <Label
          color='red'
          content={formatMessage(messages['error'])}
          detail={_.chain(state.history)
            .findLast(['type', 'error'])
            .get('settings.message')
            .value()} />
      );
    default:
      return null;
  };
};

StatusColumn.displayName = 'StatusColumn';

export default injectIntl(StatusColumn);