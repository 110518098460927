import _ from 'lodash';
import createCachedSelector from 're-reselect';
import { ENTITY_PROPERTY, SELECTION_TYPE } from '../../../utils/constants';
import * as fromState from '../../../state/reducers';
import * as chartSelectors from '../../../state/selectors/chart';

export default createCachedSelector(
  [
    _.partialRight(fromState.getSelected, SELECTION_TYPE.FUNCTION),
    chartSelectors.getFunctionSensorIds,
    chartSelectors.getFunctionSensors,
    chartSelectors.getFunctionDescriptions,
    chartSelectors.getColours
  ],
  (
    selectedFunctions,
    functionSensorIds,
    functionSensors,
    functionDescriptions,
    colours
  ) => _.map(functionSensorIds, functionSensorId => ({
    id: functionSensorId,
    title: functionDescriptions[functionSensorId],
    description: _.get(functionSensors[functionSensorId], ENTITY_PROPERTY.SENSOR.NAME) || functionSensorId,
    colour: colours[functionSensorId],
    selected: _.includes(selectedFunctions, functionSensorId),
    selectionType: SELECTION_TYPE.FUNCTION,
    copyText: _.get(functionSensors[functionSensorId], ENTITY_PROPERTY.SENSOR.NAME),
    editable: true,
    removable: true
  }))
)(
  (state, chartType) => chartType
);