import _ from 'lodash';
import { Trend, TrendType } from 'types';
import TrendLabelPopup from './TrendLabelPopup';
import styles from './TrendTimeline.styles';
import TrendLabelContainer from 'containers/chart/Timeline/TrendLabelContainer';
import { useRef } from 'react';

interface TrendMarker {
  start: number;
  end: number;
  trend: Trend;
  highlighted: boolean;
}

interface GroupPosition {
  index: number;
  overlapIndex: number;
}

interface GroupItem {
  item: TrendMarker;
  overlapIndex: number;
}

interface Group {
  items: GroupItem[];
  overlap: number;
}

export const partitionIntoOverlappingGroups = (items: TrendMarker[]): Group[] => {
  const sorted = _.sortBy(items, 'start');
  const sortedEnds = _.sortBy(items, 'end');
  const starts = _.map(sorted, 'start');
  const ends = _.map(sortedEnds, 'end');

  let currentOverlap = 0;
  let maxGroupOverlap = 0;
  let currentGroup: GroupPosition[] = [];
  const groups: Group[] = [];
  let startsIndex = 0;
  let endsIndex = 0;
  const startsSize = _.size(starts);
  const endsSize = _.size(ends);
  const overlapIndexes: (TrendMarker | undefined)[] = [];
  while (startsIndex < startsSize && endsIndex < endsSize) {
    if (starts[startsIndex] <= ends[endsIndex]) {
      currentOverlap++;
      maxGroupOverlap = Math.max(maxGroupOverlap, currentOverlap);
      const overlapIndex = _.find(_.range(0, 100), i => !overlapIndexes[i]);

      if (_.isUndefined(overlapIndex)) {
        return [];
      }

      overlapIndexes[overlapIndex] = sorted[startsIndex];
      currentGroup.push({
        index: startsIndex,
        overlapIndex
      });
      startsIndex++;
    } else {
      overlapIndexes[_.findIndex(overlapIndexes, sortedEnds[endsIndex])] = undefined;
      currentOverlap--;
      endsIndex++;
    };

    if (currentOverlap === 0 || startsIndex >= startsSize || endsIndex >= endsSize) {
      groups.push({
        items: _.map(currentGroup, ({ index, overlapIndex }) => ({
          item: sorted[index],
          overlapIndex
        })),
        overlap: maxGroupOverlap
      });
      currentGroup = [];
      maxGroupOverlap = 0;
    };
  };

  return groups;
};

interface Props {
  items: TrendMarker[];
  allTrendTypes: { [id: string]: TrendType; };
  height: number;
}
export default function Timeline({
  items,
  allTrendTypes,
  height
}: Props) {
  const overlappingGroups = partitionIntoOverlappingGroups(items);
  const maxOverlap = _.chain(overlappingGroups)
    .maxBy(group => group.overlap)
    .get('overlap')
    .value() || 1;

  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <div
      style={styles.timeline(height)}
      ref={containerRef}>
      {_.flatMap(overlappingGroups, ({ items, overlap }) => _.map(items, ({ item, overlapIndex }) => (
        <TrendLabelPopup
          key={item.trend.id}
          trend={item.trend}
          allTrendTypes={allTrendTypes}
          trigger={(
            <TrendLabelContainer
              trend={item.trend}
              start={item.start}
              end={item.end}
              overlap={maxOverlap}
              overlapIndex={overlapIndex}
              highlighted={item.highlighted}
              isChild={item.trend.isChild || false}
              timelineHeight={height}
              timelineWidth={
                containerRef.current
                  ? containerRef.current.clientWidth
                  : 0
              } />
          )} />
      )))}
    </div>
  );
}