import _ from 'lodash';
import moment from 'moment';
import createCachedSelector from 're-reselect';
import { CHART_TYPE, ENTITY_PROPERTY } from '../../../../utils/constants';
import { getUnitId } from '../../dashboard';
import { getPlaybackTime, getQuickRange } from '../../../reducers';
import { getIsoToDate } from '../getIsoToDate';
import { getPredictor } from '../getPredictor';

export const getTimelineTrendQuery = createCachedSelector(
  [
    (state, chartType) => chartType === CHART_TYPE.MAIN
      ? getPlaybackTime(state)
      : getIsoToDate(state, chartType),
    (state, chartType) => chartType === CHART_TYPE.MAIN
      ? getUnitId(state)
      : _.get(getPredictor(state, chartType), ENTITY_PROPERTY.PREDICTOR.UNIT),
    state => getQuickRange(state, CHART_TYPE.MAIN)
  ],
  (toDate, unitId, quickRange) => {
    const toMoment = _.min([moment.utc(), moment.utc(toDate)]);
    const quickRangeDuration = moment.duration(quickRange);
    const fromMoment = toMoment
      .clone()
      .subtract(quickRangeDuration);
    return {
      sort: {},
      filter: {
        To: {
          $gte: fromMoment.toISOString()
        },
        From: {
          $lte: toMoment.toISOString()
        },
        UnitId: {
          $eq: unitId
        }
      }
    };
  }
)(
  (state, chartType) => chartType
);