import { ofType } from 'redux-observable';
import { filter, withLatestFrom, mergeMap } from 'rxjs/operators';
import { setAlarmRanges, setAlarmLevels } from '../../actions/alarm';
import { TOGGLE_MODAL } from '../../actions/entity';
import * as fromState from '../../reducers';
import { of } from 'rxjs';
import { ENTITY_TYPE, DEFAULT_ALARM_RANGES } from '../../../utils/constants';
import { alarmRangesToLevels } from '../../../utils/helpers';

export default (action$, state$) => action$.pipe(
  ofType(TOGGLE_MODAL),
  filter(action => action.payload.modal === 'alarm'),
  withLatestFrom(state$),
  filter(([action, state]) => fromState.getModalOpen(state, action.entityType, action.payload.modal)),
  mergeMap(([action, state]) => {
    const entity = fromState.getById(state, action.entityType, action.payload.id);
    const alarmRanges = entity.alarmRanges;
    const enumeration = fromState.getById(state, ENTITY_TYPE.ENUMERATION, entity.enumeration);
    return of(
      setAlarmRanges(alarmRanges || DEFAULT_ALARM_RANGES),
      setAlarmLevels(alarmRangesToLevels(alarmRanges, enumeration))
    );
  })
);