import _ from 'lodash';
import { createSelector } from 'reselect';
import * as fromState from '../../../state/reducers';
import { ATTRIBUTE_SOURCE, ENTITY_TYPE } from '../../constants';
import { getUnitId } from '../../../state/selectors/dashboard';

export default createSelector(
  [
    state => fromState.getAll(state, ENTITY_TYPE.SENSOR),
    state => getUnitId(state)
  ],
  (allSensors, unitId) => {
    const inputs = _.chain(allSensors)
      .values()
      .map(sensor => ({
        id: sensor.id,
        value: sensor.description || sensor.name,
        text: sensor.description || sensor.name,
        description: sensor.description
          ? sensor.name
          : sensor.description,
        valueType: sensor.valueType,
        enumeration: sensor.enumeration,
        unitsOfMeasurement: sensor.unitsOfMeasurement
      }))
      .orderBy(sensor => sensor.unitId === unitId)
      .value();
    return {
      inputs,
      attributes: _.map(inputs, input => ({
        source: ATTRIBUTE_SOURCE.SENSOR,
        ...input,
        text: input.description || input.text,
        description: input.description
          ? input.text
          : input.description,
        value: input.id
      }))
    };
  }
);