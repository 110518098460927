import { RootState } from 'index';
import _ from 'lodash';
import createCachedSelector from 're-reselect';
import { Predictor, Sensor, Unit } from 'types';
import { CHART_TYPE, ENTITY_TYPE, ENTITY_PROPERTY } from '../../../../../utils/constants';
import * as fromState from '../../../../reducers';
import { getSeverity } from '../getSeverity';

const isChildOf = (childId: string, parentId: string, allUnits: { [id: string]: Unit; }) => {
  if (!childId) {
    return false;
  };
  return childId === parentId
    || isChildOf(_.get(allUnits[childId], ENTITY_PROPERTY.UNIT.UNIT), parentId, allUnits);
};

export const getUnitSeverity = createCachedSelector(
  [
    (state: RootState, { id }: { id: string; }) => id,
    state => fromState.getAll<Predictor>(state, ENTITY_TYPE.PREDICTOR),
    state => fromState.getAll<Unit>(state, ENTITY_TYPE.UNIT),
    state => fromState.getAllLatestSensorValues(state, CHART_TYPE.MAIN),
    state => fromState.getAllLatestOutputValues(state, CHART_TYPE.MAIN),
    state => fromState.getAll<Sensor>(state, ENTITY_TYPE.SENSOR)
  ],
  (parentId, allPredictors, allUnits, allLatestSensorValues, allLatestOutputValues, allSensors) => {
    return _.chain(allPredictors)
      .values()
      .filter(predictor => isChildOf(predictor.unitId, parentId, allUnits))
      .map(predictor => getSeverity(predictor, allLatestSensorValues, allLatestOutputValues, allSensors))
      .max()
      .value();
  }
)(
  (state, { id }) => id
);