import _ from 'lodash';
import createCachedSelector from 're-reselect';
import { ENTITY_PROPERTY, VALUE_TYPE } from '../../../../utils/constants';

export default (dataSelector, alarmRangesSelector, propertiesSelector) => createCachedSelector(
  [dataSelector, alarmRangesSelector, propertiesSelector],
  (data, alarmRanges, properties) => _.mapValues(alarmRanges, (ranges, key) => {
    const valueType = _.get(properties[key], ENTITY_PROPERTY.SENSOR.VALUE_TYPE);
    const value = _.some(data[key]) ? _.chain(data[key]).map(ENTITY_PROPERTY.SENSOR_VALUE.VALUE).last().value() : null;
    const noData = !_.some(data[key]);
    if (!_.includes([VALUE_TYPE.FLOAT, VALUE_TYPE.ENUMERATION], valueType)) {
      return {
        min: null,
        max: null,
        value,
        noData,
        severity: noData ? null : -1
      };
    };

    const min = _.some(data[key]) ? _.chain(data[key]).map(ENTITY_PROPERTY.SENSOR_VALUE.VALUE).min().value() : null;
    const max = _.some(data[key]) ? _.chain(data[key]).map(ENTITY_PROPERTY.SENSOR_VALUE.VALUE).max().value() : null;
    return {
      min,
      max,
      value,
      noData,
      severity: _.some(data[key])
        ? (
          _.some(ranges)
            ? _.chain(ranges)
              .filter((range, i) => (i === _.size(ranges) - 1 || value <= range.max) && (i === 0 || value >= range.min))
              .map('severity')
              .max()
              .value()
            : -1
        )
        : null
    };
  })
)(
  (state, chartType) => chartType
);