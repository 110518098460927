import _ from 'lodash';
import { injectIntl } from 'react-intl';
import { Icon, Label, Popup, Progress } from 'semantic-ui-react';
import { INVERTED_THEME } from 'utils/constants';
import styles from './ProgressLabel.styles';

export default injectIntl(function ProgressLabel({
  content,
  color,
  percent,
  flex,
  iconButtons,
  onClickIconButton,
  intl: {
    formatMessage
  },
  ...props
}) {
  return (
    <Label
      color={color}
      {...props}
      style={flex
        ? styles.labelFlex
        : null}>
      {content}
      <Progress
        percent={percent}
        color='green'
        size='tiny'
        style={flex
          ? styles.progressFlex
          : styles.progress} />
      {_.map(iconButtons, button => (
        <Popup
          key={button.name}
          inverted={INVERTED_THEME}
          trigger={(
            <Icon
              name={button.loading
                ? 'circle notch'
                : button.icon}
              loading={button.loading}
              disabled={button.disabled}
              link={!button.disabled}
              style={styles.iconButton}
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                if (button.disabled) {
                  return;
                };
                onClickIconButton(button.name);
              }} />
          )}
          on='hover'
          position='top center'
          style={styles.iconButtonPopup}>
          {formatMessage({
            id: button.popupMessageId,
            defaultMessage: button.popupDefaultMessage
          })}
        </Popup>
      ))}
    </Label>
  );
});