import _ from 'lodash';
import moment from 'moment';
import { ofType } from 'redux-observable';
import { EMPTY, merge } from 'rxjs';
import { bufferCount, catchError, distinctUntilChanged, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import * as api from '../../../utils/api';
import { CHART_TYPE, ENTITY_TYPE, MIN_CHART_UDPATE_PERIOD, PAGE_URL, SECONDS_IN_MINUTE, TREND_TIMELINE_QUERY_PAGE_SIZE, MAX_PLAYBACK_SPEED, DASHBOARD_VIEW } from '../../../utils/constants';
import { matchesPath, takeUntilAppReset } from '../../../utils/helpers';
import { SET_QUICK_RANGE } from '../../actions/chart';
import { setMultipleLatest } from '../../actions/data';
import { insertMany } from '../../actions/entity';
import { CHANGE_PLAYBACK_TIME, INCREMENT_PLAYBACK_TIME, JUMP_TO_LIVE_PLAYBACK } from '../../actions/playback';
import * as fromState from '../../reducers';
import { getUnitId, getUnitSensors, getUnitTypeSensors } from '../../selectors/dashboard';
import * as chartSelectors from '../../selectors/chart';
import { createSelector } from 'reselect';

const getDashboardPageSensors = createSelector(
  [
    state => fromState.getDashboardView(state),
    state => getUnitSensors(state),
    state => getUnitTypeSensors(state)
  ],
  (view, unitSensors, unitTypeSensors) => view === DASHBOARD_VIEW.UNITS
    ? unitSensors
    : unitTypeSensors
);

export default (action$, state$) => state$.pipe(
  distinctUntilChanged((prev, curr) =>
    fromState.getUser(prev) === fromState.getUser(curr)
    && prev.router.location.pathname === curr.router.location.pathname
    && fromState.getPlaybackSpeed(prev) === fromState.getPlaybackSpeed(curr)),
  switchMap(state => {
    if (matchesPath(state.router.location.pathname, PAGE_URL.NOT_HOME, { end: false })
      && !matchesPath(state.router.location.pathname, PAGE_URL.UNIT, { end: false })
      && !matchesPath(state.router.location.pathname, PAGE_URL.UNIT_TYPE, { end: false })) {
      return EMPTY;
    };

    const playbackSpeed = fromState.getPlaybackSpeed(state);
    const UPDATE_PERIOD = _.max([
      MIN_CHART_UDPATE_PERIOD,
      SECONDS_IN_MINUTE / playbackSpeed
    ]);

    const update$ = merge(
      action$.pipe(
        ofType(CHANGE_PLAYBACK_TIME, JUMP_TO_LIVE_PLAYBACK, SET_QUICK_RANGE)
      ),
      action$.pipe(
        ofType(INCREMENT_PLAYBACK_TIME),
        bufferCount(UPDATE_PERIOD),
        map(_.last)
      )
    );

    return update$.pipe(
      withLatestFrom(state$),
      filter(([action, latestState]) => fromState.getUser(state)),
      switchMap(([action, latestState]) => {
        const toDate = action.type === SET_QUICK_RANGE
          ? fromState.getPlaybackTime(latestState)
          : action.type === JUMP_TO_LIVE_PLAYBACK
            ? moment.utc().toISOString()
            : action.payload.time;
        const fromDate = moment(toDate)
          .clone()
          .subtract(UPDATE_PERIOD * playbackSpeed + MIN_CHART_UDPATE_PERIOD * MAX_PLAYBACK_SPEED, 'seconds');

        const fetchTrends$ = api.query({
          controller: ENTITY_TYPE.TREND,
          query: action.type === INCREMENT_PLAYBACK_TIME
            ? {
              sort: {},
              filter: {
                To: {
                  $gte: fromDate.toISOString()
                },
                From: {
                  $lte: toDate
                },
                UnitId: {
                  $eq: getUnitId(latestState)
                }
              }
            }
            : chartSelectors.getTimelineTrendQuery(latestState, CHART_TYPE.MAIN),
          page: 0,
          pageSize: TREND_TIMELINE_QUERY_PAGE_SIZE,
          token: fromState.getUser(state).token
        }).pipe(
          takeUntilAppReset(action$),
          map(response => insertMany(ENTITY_TYPE.TREND, response.items)),
          catchError(api.onError)
        );

        const sensorsForWhichWeShouldFetchValues = getDashboardPageSensors(latestState);

        const fetchSensorValues$ = api.getSensorsLatest({
          sensorIds: _.map(sensorsForWhichWeShouldFetchValues, sensor => sensor.id),
          fromDate: moment.utc(toDate)
            .subtract(moment.duration(fromState.getQuickRange(latestState, CHART_TYPE.MAIN)))
            .toISOString(),
          toDate: toDate,
          token: fromState.getUser(latestState).token
        }).pipe(
          takeUntilAppReset(action$),
          map(response => {
            const payload = {
              sensors: response.sensors,
              outputs: []
            };
            return setMultipleLatest(CHART_TYPE.MAIN, payload);
          }),
          catchError(api.onError)
        );

        const fetchOutputValues$ = api.getLatest({
          predictorIds: fromState.getPredictors(latestState),
          fromDate: moment.utc(toDate)
            .subtract(moment.duration(fromState.getQuickRange(latestState, CHART_TYPE.MAIN)))
            .toISOString(),
          toDate: toDate,
          token: fromState.getUser(latestState).token
        }).pipe(
          takeUntilAppReset(action$),
          map(response => setMultipleLatest(CHART_TYPE.MAIN, response)),
          catchError(api.onError)
        );

        return merge(fetchTrends$, fetchSensorValues$, fetchOutputValues$);
      })
    );
  })
);