import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { injectIntl } from 'react-intl';
import { Line, LineChart, ReferenceArea, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis, CartesianGrid } from 'recharts';
import { Button, Ref, Form } from 'semantic-ui-react';
import { CustomYAxisTickContainer } from '../../../containers/chart/TimeSeriesChartContainer';
import { CHART_TYPE, DATE_FORMAT, REFERENCE_AREA_CLASS_NAME, SENSOR_VALUE_DATE_FORMAT, VALUE_TYPE, ENTITY_TYPE, INVERTED_THEME, PLAYBACK_MODE, LINE_CHART_YAXIS_WIDTH } from '../../../utils/constants';
import { getColourFromSeverity } from '../../../utils/helpers';
import CustomCursor from './CustomCursor';
import CustomTooltip from './CustomTooltip';
import messages from './TimeSeriesChart.messages';
import styles from './TimeSeriesChart.styles';
import AddEntityModalContainer from 'containers/entity/AddEntityModalContainer';
import entityConfig from 'utils/entities';

const CONTAINER_HEIGHT = '100%';
const TICK_FORMATTER = unixTime => moment(unixTime, SENSOR_VALUE_DATE_FORMAT).format(DATE_FORMAT);
const LABEL_FONT_FAMILY = 'Lato, Helvetica Neue, Arial, Helvetica, sans-serif;';
const LABEL_FONT_WEIGHT = 500;
const X_AXIS_TICK = {
  dy: 5,
  fill: 'white',
  fontFamily: LABEL_FONT_FAMILY,
  fontWeight: LABEL_FONT_WEIGHT
};
const X_AXIS_HEIGHT = 50;
const Y_AXIS_DOMAIN = [0, 2];
const Y_AXIS_TICKS = [0, 0.5, 1, 1.5, 2];
const REFERENCE_AREA_OPACITY = 0.5;
const LINE_STROKE_WIDTH = 2;
const DOT_STROKE_WIDTH = 2;
const REFERENCE_LINE_STROKE_DASHARRAY = '4';
const REFERENCE_LINE_STROKE_WIDTH = '1px';
const LINE_CHART_MARGIN = {
  top: 0,
  right: 5,
  left: 0,
  bottom: 0
};
const ZOOM_BUTTON_GROUP_MARGIN = 10;
const REFERENCE_AREA_STROKE_OPACITY = 0.3;
const REFERENCE_AREA_STROKE = 'white';
const REFERENCE_AREA_STROKE_WIDTH = '2px';
const CARTESIAN_GRID_STROKE_DASHARRAY = '2 2';

const getDataKey = line => {
  if (line.valueType === VALUE_TYPE.FLOAT) {
    return line.id;
  };
  if (line.valueType === VALUE_TYPE.ENUMERATION) {
    return payload => _.get(payload[line.id], 'number');
  };
  return payload => !_.isUndefined(payload[line.id]) && !_.isNull(payload[line.id])
    ? 1
    : undefined;
};

const ON_MOUSE_DOWN = 'ON_MOUSE_DOWN';
const onMouseDown = event => ({
  type: ON_MOUSE_DOWN,
  payload: {
    event
  }
});
const ON_MOUSE_MOVE = 'ON_MOUSE_MOVE';
const onMouseMove = event => ({
  type: ON_MOUSE_MOVE,
  payload: {
    event
  }
});
const ON_MOUSE_UP = 'ON_MOUSE_UP';
const onMouseUp = () => ({
  type: ON_MOUSE_UP
});
const ON_CHANGE_NOTES = 'ON_CHANGE_NOTES';
const onChangeNotes = value => ({
  type: ON_CHANGE_NOTES,
  payload: {
    value
  }
});

/**
 * @property {boolean} isDragging True if the user is currently dragging to select a 
 * region, else false
 * @property {number} selectedAreaLeft The timestamp of the start of the selected region,
 * or null if not dragging or if there is no data in the current window
 * @property {number} selectedAreaRight The timestamp of the end of the selected region,
 * or null if not dragging or if there is no data in the current window
 * @property {number} selectedAreaLeftX The x position in Recharts coords of the start of
 * the selected region, or null if not dragging or if there is no data in the current window
 * @property {number} selectedAreaRightX The x position in Recharts coords of the end of
 * the selected region, or null if not dragging or if there is no data in the current window
 */
const initialState = {
  isDragging: false,
  selectedAreaLeft: null,
  selectedAreaRight: null,
  selectedAreaLeftX: null,
  selectedAreaRightX: null,
  notes: ''
};

const reducer = (state, action) => {
  switch (action.type) {
    case ON_MOUSE_DOWN:
      return {
        ...state,
        isDragging: true,
        selectedAreaLeft: action.payload.event.activeLabel ?? action.payload.event.chartX,
        selectedAreaRight: null,
        selectedAreaLeftX: action.payload.event.chartX,
        selectedAreaRightX: null
      };
    case ON_MOUSE_MOVE:
      if (state.isDragging) {
        return {
          ...state,
          selectedAreaRight: action.payload.event.activeLabel ?? action.payload.event.chartX,
          selectedAreaRightX: action.payload.event.chartX
        };
      } else {
        return state;
      };
    case ON_MOUSE_UP:
      if (state.isDragging) {
        if (state.selectedAreaRightX == null) {
          return {
            ...state,
            isDragging: false,
            selectedAreaLeft: null,
            selectedAreaRight: null,
            selectedAreaLeftX: null,
            selectedAreaRightX: null,
            notes: ''
          };
        };
        if (state.selectedAreaRight < state.selectedAreaLeft) {
          return {
            ...state,
            selectedAreaRight: state.selectedAreaLeft,
            selectedAreaLeft: state.selectedAreaRight,
            selectedAreaRightX: state.selectedAreaLeftX,
            selectedAreaLeftX: state.selectedAreaRightX,
            isDragging: false,
            notes: ''
          };
        };
        return {
          ...state,
          isDragging: false,
          notes: ''
        };
      } else {
        return state;
      };
    case ON_CHANGE_NOTES:
    default:
      return state;
  };
};

/**
 * The chart has a "data window" which represents the region for which data is currently
 * loaded. The data window is changed using the date and window picker controls above
 * the chart. There is also a "brush window" which represents the region currently in
 * view. This may be equal to or smaller than the data window. It can be made smaller
 * by zooming in.
 * 
 * @param {number} brushStartTimestamp Start of the brush window
 * @param {number} brushEndTimestamp End of the brush window
 * @param {number} startTimestamp Start of the data window
 * @param {number} endTimestamp End of the data window
 */
const TimeSeriesPlot = injectIntl(({
  data,
  brushStartTimestamp,
  brushEndTimestamp,
  startTimestamp,
  endTimestamp,
  chartType,
  lineGroups,
  referenceLines,
  referenceAreas,
  useDefaultYAxisTicks,
  highlightedWidowSize,
  showHighlightedWindow,
  trendButtons,
  unitId,
  mode,
  onChangeBrush,
  onHoverLine,
  onUnhoverLine,
  onClickTrendButton,
  intl: {
    formatMessage
  }
}) => {
  const state = useRef(initialState);
  const selectedArea = useRef(null);
  const selectionButtons = useRef(null);
  const notesField = useRef(null);
  const tooltipTableBody = useRef(null);
  const chartContainer = useRef(null);

  // Given the x position of a point on the chart in pixel coordinates, returns the
  // corresponding timestamp by interpolating between the known start and end of the window.
  function pixelToTimestampX(xPixel) {
    const chartWidth = chartContainer.current.clientWidth - LINE_CHART_YAXIS_WIDTH;
    const translated = xPixel - LINE_CHART_YAXIS_WIDTH;
    const fraction = translated / chartWidth;

    const displayedDuration = brushEndTimestamp - brushStartTimestamp;
    return brushStartTimestamp + fraction * displayedDuration;
  }

  const renderZoomArea = () => {
    if (state.current.selectedAreaRight === null) {
      selectedArea.current.style.display = 'none';
      if (selectionButtons.current) {
        selectionButtons.current.style.display = 'none';
      };
    } else {
      selectedArea.current.style.display = 'block';
      const left = state.current.selectedAreaLeftX;
      const width = state.current.selectedAreaRightX - state.current.selectedAreaLeftX + _.min([0, left - LINE_CHART_YAXIS_WIDTH]);
      const height = selectedArea.current.getAttribute('height');
      selectedArea.current.setAttribute('d', `M ${_.max([left, LINE_CHART_YAXIS_WIDTH])},0 h ${width} v ${height} h ${-width} Z`);

      if (!state.current.isDragging) {
        selectionButtons.current.style.display = 'block';
        const buttonsLeft = ZOOM_BUTTON_GROUP_MARGIN + state.current.selectedAreaRightX;
        selectionButtons.current.style.left = `min(${buttonsLeft}px, 100% - ${selectionButtons.current.clientWidth}px - 10px)`;
      } else {
        selectionButtons.current.style.display = 'none';
      };
    };
  };
  useEffect(() => {
    requestAnimationFrame(() => {
      selectedArea.current = _.get(document.getElementsByClassName('zoom-area'), '[0].childNodes[0]');
      if (selectedArea.current) {
        renderZoomArea();
      };
    });
  });
  const dispatch = useCallback(action => {
    state.current = reducer(state.current, action);
    if (!selectedArea.current) {
      selectedArea.current = _.get(document.getElementsByClassName('zoom-area'), '[0].childNodes[0]');
    };
    if (selectedArea.current) {
      renderZoomArea();
    };
    if (notesField.current) {
      notesField.current.firstElementChild.value = state.current.notes;
    };
  }, [state, selectedArea]);
  const referenceAreaX1 = _.some(data)
    ? brushStartTimestamp
    : 0;
  const referenceAreaX2 = brushEndTimestamp || endTimestamp;
  const xAxisTickOffset = (brushEndTimestamp - brushStartTimestamp) / 10;
  const xAxis = useMemo(
    () => (
      <XAxis
        dataKey='timestamp'
        domain={[brushStartTimestamp, brushEndTimestamp]}
        tickFormatter={TICK_FORMATTER}
        type='number'
        ticks={_.map(
          _.range(0, 5),
          i => brushStartTimestamp + xAxisTickOffset + (
            brushEndTimestamp - 2 * xAxisTickOffset - brushStartTimestamp
          ) * i / 4
        )}
        height={X_AXIS_HEIGHT}
        tick={X_AXIS_TICK}
        allowDataOverflow />
    ),
    [brushStartTimestamp, brushEndTimestamp, xAxisTickOffset]
  );
  const yAxis = useMemo(
    () => (
      <YAxis
        width={LINE_CHART_YAXIS_WIDTH + 1}
        domain={useDefaultYAxisTicks ? undefined : Y_AXIS_DOMAIN}
        ticks={useDefaultYAxisTicks ? undefined : Y_AXIS_TICKS}
        tick={<CustomYAxisTickContainer chartType={chartType} />} />
    ),
    [useDefaultYAxisTicks, chartType]
  );
  const tooltip = useMemo(
    () => (
      <Tooltip
        content={CustomTooltip}
        tableBodyRef={tooltipTableBody}
        chartType={chartType}
        isAnimationActive={false}
        allowEscapeViewBox
        cursor={_.some(data) && (
          <CustomCursor
            chartType={chartType}
            widthRatio={highlightedWidowSize / ((brushEndTimestamp - brushStartTimestamp))}
            showHighlightedWindow={showHighlightedWindow} />
        )}
        lines={lineGroups} />
    ),
    [chartType, data, highlightedWidowSize, brushEndTimestamp,
      brushStartTimestamp, showHighlightedWindow, lineGroups]
  );
  const referenceLineComponents = useMemo(
    () => _.map(referenceLines, (line, index) => (
      <ReferenceLine
        key={`referenceLine.${index}`}
        x={line.x}
        stroke={line.colour}
        strokeDasharray={REFERENCE_LINE_STROKE_DASHARRAY}
        strokeWidth={REFERENCE_LINE_STROKE_WIDTH} />
    )),
    [referenceLines]
  );
  const referenceAreaComponents = useMemo(
    () => _.map(referenceAreas, (area, index) => (
      <ReferenceArea
        key={`referenceArea.${index}`}
        label={area.label && {
          value: area.label,
          fill: 'white',
          position: 'insideTopLeft',
          fontFamily: LABEL_FONT_FAMILY,
          fontWeight: LABEL_FONT_WEIGHT
        }}
        x1={_.max([area.x1, brushStartTimestamp])}
        x2={_.min([area.x2, brushEndTimestamp])}
        fill={area.colour}
        strokeOpacity={REFERENCE_AREA_STROKE_OPACITY}
        stroke={REFERENCE_AREA_STROKE}
        strokeWidth={REFERENCE_AREA_STROKE_WIDTH} />
    )),
    [referenceAreas, brushStartTimestamp, brushEndTimestamp]
  );
  const lineComponents = useMemo(
    () => _.flatMap(
      _.chain(lineGroups)
        .flatten()
        .reverse()
        .value(),
      line => [
        ..._.map(line.ranges, (range, i) => (
          <ReferenceArea
            className={`${line.className} ${REFERENCE_AREA_CLASS_NAME}`}
            key={`${line.className}.${i}`}
            x1={referenceAreaX1}
            x2={referenceAreaX2}
            y1={(range.min - line.offset) / line.scale}
            y2={(range.max - line.offset) / line.scale}
            fill={getColourFromSeverity(range.severity)}
            opacity={REFERENCE_AREA_OPACITY}
            style={styles.referenceArea}
            strokeOpacity={REFERENCE_AREA_STROKE_OPACITY}
            stroke={REFERENCE_AREA_STROKE}
            strokeWidth={REFERENCE_AREA_STROKE_WIDTH} />
        )),
        (
          <Line
            className={line.className}
            key={line.id}
            type={line.valueType === VALUE_TYPE.ENUMERATION
              ? 'stepAfter'
              : 'linear'
            }
            stroke={line.colour}
            strokeWidth={LINE_STROKE_WIDTH * line.strokeWidthMultiplier}
            activeDot={false}
            name={line.id}
            dataKey={getDataKey(line)}
            dot={_.includes([VALUE_TYPE.FLOAT, VALUE_TYPE.ENUMERATION], line.valueType)
              ? false
              : {
                stroke: line.colour,
                strokeWidth: DOT_STROKE_WIDTH
              }
            }
            connectNulls
            isAnimationActive={false}
            onMouseEnter={() => onHoverLine(line.id)}
            onMouseLeave={() => onUnhoverLine(line.id)} />
        )
      ]
    ),
    [lineGroups, referenceAreaX1, referenceAreaX2, onHoverLine, onUnhoverLine]
  );
  const zoomAreaComponent = useMemo(
    () => (
      <ReferenceArea
        className='zoom-area'
        cursor='all-scroll'
        style={state.current.selectedAreaRight !== null
          ? null
          : styles.referenceArea}
        x1={state.current.selectedAreaLeft || brushStartTimestamp}
        x2={state.current.selectedAreaRight || brushEndTimestamp}
        strokeOpacity={REFERENCE_AREA_STROKE_OPACITY}
        stroke={REFERENCE_AREA_STROKE}
        strokeWidth={REFERENCE_AREA_STROKE_WIDTH} />
    ),
    [state, brushStartTimestamp, brushEndTimestamp]
  );
  return (
    <div
      style={styles.plotWrapperDiv}
      onWheel={event => {
        if (tooltipTableBody.current) {
          tooltipTableBody.current.scrollTop += event.deltaY;
        };
      }}>
      <Button
        content={formatMessage(messages['timeSeriesPlot.resetZoomButton.text'])}
        compact
        primary
        disabled={brushStartTimestamp === startTimestamp && brushEndTimestamp === endTimestamp}
        size='mini'
        onClick={() => {
          onChangeBrush(startTimestamp, endTimestamp);
          state.current = initialState;
          renderZoomArea();
        }}
        style={styles.resetZoomButton} />
      <div
        // This style is needed to get a Recharts responsive container to resize correctly 
        // when inside a flexbox. See (https://github.com/recharts/recharts/issues/172)
        style={{
          position: 'absolute',
          inset: 0
        }}
        ref={chartContainer}>
        <ResponsiveContainer
          width='100%'
          height={CONTAINER_HEIGHT}>
          <LineChart
            data={data}
            margin={LINE_CHART_MARGIN}
            onMouseDown={e => e && dispatch(onMouseDown(e))}
            onMouseMove={e => {
              if (!e || (!state.current.isDragging)) {
                return;
              };
              dispatch(onMouseMove(e));
            }}
            onMouseUp={() => dispatch(onMouseUp())}>
            {xAxis}
            {yAxis}
            <CartesianGrid strokeDasharray={CARTESIAN_GRID_STROKE_DASHARRAY} />
            {referenceLineComponents}
            {referenceAreaComponents}
            {lineComponents}
            {zoomAreaComponent}
            {tooltip}
          </LineChart>
        </ResponsiveContainer>
      </div>

      <Ref innerRef={selectionButtons}>
        <Button.Group
          vertical
          labeled
          icon
          style={styles.zoomButtonGroup}>
          {mode === PLAYBACK_MODE.REVIEW && (
            <Form inverted={INVERTED_THEME}>
              <Ref innerRef={notesField}>
                <Form.TextArea
                  style={styles.notesTextArea}
                  placeholder={formatMessage(messages['timeSeriesPlot.notesTextArea.placeholder'])}
                  onChange={(event, { value }) => dispatch(onChangeNotes(value))} />
              </Ref>
            </Form>
          )}
          {_.map(trendButtons, trendButton => (
            <Button
              key={trendButton.id}
              style={styles.zoomButton}
              icon={trendButton.icon}
              content={trendButton.name}
              color={trendButton.colour}
              onClick={() => onClickTrendButton(
                trendButton.id,
                moment.utc(`${state.current.selectedAreaLeft}`, 'X').toISOString(),
                moment.utc(`${state.current.selectedAreaRight}`, 'X').toISOString(),
                state.current.notes,
                () => {
                  state.current = initialState;
                  renderZoomArea();
                }
              )} />
          ))}
          <Button
            icon='zoom-in'
            content={formatMessage(messages['timeSeriesPlot.zoomButton.text'])}
            onClick={() => {
              const newStartTimestamp = pixelToTimestampX(state.current.selectedAreaLeftX);
              const newEndTimestamp = pixelToTimestampX(state.current.selectedAreaRightX);

              onChangeBrush(newStartTimestamp, newEndTimestamp);
              state.current = initialState;
              renderZoomArea();
            }} />
        </Button.Group>
      </Ref>
      <AddEntityModalContainer
        entityType={ENTITY_TYPE.TREND}
        form={[
          ..._.filter(
            entityConfig.trend.form,
            ({ name }) => !_.includes(['unitId', 'range', undefined], name)
          ),
          {
            type: 'types',
            hideType: true
          }
        ]}
        createEntity={values => ({
          ...entityConfig.trend.createEntity(values),
          from: moment.utc(`${state.current.selectedAreaLeft}`, 'X').toISOString(),
          to: moment.utc(`${state.current.selectedAreaRight}`, 'X').toISOString(),
          unitId
        })} />
    </div>
  );
});

TimeSeriesPlot.propTypes = {
  data: PropTypes.arrayOf((propValue, key, componentName, location, propFullName) => {
    PropTypes.checkPropTypes({
      timestamp: PropTypes.number.isRequired
    }, propValue[key], propFullName, componentName);
    PropTypes.checkPropTypes({
      [propFullName]: PropTypes.objectOf(PropTypes.any.isRequired)
    }, {
      [propFullName]: propValue[key]
    }, propFullName, componentName);
  }).isRequired,
  brushStartTimestamp: PropTypes.number,
  brushEndTimestamp: PropTypes.number,
  startTimestamp: PropTypes.number.isRequired,
  endTimestamp: PropTypes.number.isRequired,
  useDefaultYAxisTicks: PropTypes.bool.isRequired,
  highlightedWidowSize: PropTypes.number,
  showHighlightedWindow: PropTypes.bool.isRequired,
  chartType: PropTypes.oneOf(_.values(CHART_TYPE)).isRequired,
  lineGroups: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.exact({
    id: PropTypes.string.isRequired,
    className: PropTypes.string.isRequired,
    offset: PropTypes.number,
    scale: PropTypes.number,
    ranges: PropTypes.arrayOf(PropTypes.exact({
      min: PropTypes.number.isRequired,
      max: PropTypes.number.isRequired,
      severity: PropTypes.number.isRequired
    })),
    colour: PropTypes.string.isRequired,
    valueScale: PropTypes.number.isRequired,
    strokeWidthMultiplier: PropTypes.number.isRequired,
    description: PropTypes.string.isRequired,
    unitsOfMeasurement: PropTypes.string,
    valueType: PropTypes.oneOf(_.values(VALUE_TYPE)).isRequired
  }))).isRequired,
  onChangeBrush: PropTypes.func.isRequired,
  onHoverLine: PropTypes.func.isRequired,
  onUnhoverLine: PropTypes.func.isRequired
};

TimeSeriesPlot.displayName = 'TimeSeriesPlot';

export default TimeSeriesPlot;