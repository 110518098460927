import _ from 'lodash';
import moment from 'moment';
import createCachedSelector from 're-reselect';
import { Trend } from 'types';
import { CHART_TYPE, DATE_FORMAT, ENTITY_PROPERTY, ENTITY_TYPE } from '../../../../utils/constants';
import * as fromState from '../../../reducers';
import { getUnitId } from '../../dashboard';
import { getPredictor } from '../getPredictor';

export const getTimelineTrends = createCachedSelector(
  [
    state => fromState.getAll<Trend>(state, ENTITY_TYPE.TREND),
    state => fromState.getSelectedTrends(state),
    (state, chartType) => chartType === CHART_TYPE.MAIN
      ? getUnitId(state)
      : _.get(getPredictor(state, chartType), ENTITY_PROPERTY.PREDICTOR.UNIT),
    (state, chartType) => moment.utc(fromState.getToDate(state, chartType), DATE_FORMAT).toISOString(),
    state => fromState.getQuickRange(state, CHART_TYPE.MAIN)
  ],
  (allTrends, selectedTrends, unitId, toDateString, quickRange) => {
    const quickRangeDuration = moment.duration(quickRange);
    const toDate = moment.utc(toDateString);
    const fromDate = toDate
      .clone()
      .subtract(quickRangeDuration);

    const childrenOfSelectedTrends = _.chain(selectedTrends)
      .map(trendId => allTrends[trendId])
      .filter(selectedTrend => selectedTrend.unitId === unitId
        && moment(selectedTrend.from) < toDate && moment(selectedTrend.to) > fromDate)
      .flatMap(selectedTrend => selectedTrend.children || [])
      .map(trendId => allTrends[trendId])
      .value();

    const nonChildTrends = _.chain(allTrends)
      .values()
      .filter(trend => {
        return !trend.isChild
          && trend.unitId === unitId
          && moment(trend.from) < toDate && moment(trend.to) > fromDate;
      })
      .value();

    return [...nonChildTrends, ...childrenOfSelectedTrends];
  }
)(
  (state, chartType) => chartType
);