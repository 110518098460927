import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { createSelector } from 'reselect';
import { addSyncedSearchToLocation } from '../../utils/helpers';

const mapStateToProps = createSelector(
  [
    state => state.router.location.search
  ],
  search => ({
    search
  })
);

const QuerySyncLink = props => (
  <Link
    {...props}
    to={addSyncedSearchToLocation(props.to, props.search)} />
);

QuerySyncLink.displayName = 'QuerySyncLink';

export default connect(mapStateToProps)(QuerySyncLink);