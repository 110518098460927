import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { addCondition, formSearchChanged, formSettingChanged, formValueChanged, removeCondition, setConditionFilter, updateCondition, performSearch } from '../../state/actions/entity';
import EntityForm from '../../components/entity/EntityForm';
import { ENTITY_CONFIG, ENTITY_FORM_TYPE, ENTITY_PROPERTY } from '../../utils/constants';
import * as fromState from '../../state/reducers';
import entityConfig from '../../utils/entities';
import createCachedSelector from 're-reselect';

const getLabels = createCachedSelector(
  [
    (state, { entityType }) => fromState.getLabels(state, entityType)
  ],
  labels => _.map(labels, ENTITY_PROPERTY.LABEL.NAME)
)(
  (state, { entityType }) => entityType
);

const getConditionTypes = createCachedSelector(
  [
    (state, { entityType }) => fromState.getConditionTypes(state, entityType)
  ],
  conditionTypes => _.filter(
    conditionTypes,
    conditionType => conditionType.queryable
  )
)(
  (state, { entityType }) => entityType
);

const mapStateToProps = createCachedSelector(
  [
    (state, { entityType }) => entityConfig[entityType][ENTITY_CONFIG.SEARCH],
    (state, { entityType }) => entityConfig[entityType][ENTITY_CONFIG.MESSAGES],
    (state, { entityType }) => fromState.getTypesById(state, entityType),
    getLabels,
    (state, { entityType }) => fromState.getAllFormSearchResults(state, entityType),
    (state, { entityType }) => fromState.getFormValues(state, entityType, ENTITY_FORM_TYPE.SEARCH),
    (state, { entityType, parentId }) => entityConfig[entityType].selectContextListsSelector
      ? entityConfig[entityType].selectContextListsSelector(state, { parentId })
      : null,
    getConditionTypes
  ],
  (
    form,
    entityMessages,
    typesById,
    labels,
    formSearchResults,
    formValues,
    selectContextLists,
    conditionTypes
  ) => ({
    form,
    entityMessages,
    typesById,
    labels,
    formSearchResults,
    formValues,
    selectContextLists,
    conditionTypes,
    inlineWidth: 2
  })
)(
  (state, { entityType }) => entityType
);

const mapDispatchToProps = (dispatch, { entityType, parentId }) => {
  return bindActionCreators({
    ..._.mapValues({
      onUpdateCondition: updateCondition,
      onRemoveCondition: removeCondition,
      onAddCondition: addCondition,
      onSetConditionFilter: setConditionFilter,
      onFormSettingChanged: formSettingChanged,
      onFormSearchChanged: formSearchChanged,
      onFormValueChanged: formValueChanged
    }, actionCreator => _.partial(actionCreator, entityType, ENTITY_FORM_TYPE.SEARCH)),
    onSubmit: () => performSearch(entityType, parentId)
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(EntityForm);