import ConnectorAccessTokenModal from '../../components/entity/ConnectorAccessTokenModal';
import { connect } from 'react-redux';
import * as fromState from '../../state/reducers';
import { toggleModal } from '../../state/actions/entity';
import { compose, withHandlers } from 'recompose';

export default compose(
  connect(
    (state, { entityType }) => ({
      open: fromState.getModalOpen(state, entityType, 'accessToken'),
      token: fromState.getAccessToken(state, entityType),
      id: fromState.getModalId(state, entityType, 'accessToken')
    }),
    { toggleModal }
  ),
  withHandlers({
    onClose: ({ toggleModal, entityType, id }) => () => toggleModal(entityType, 'accessToken', id)
  })
)(ConnectorAccessTokenModal);