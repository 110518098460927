import _ from 'lodash';
import { of } from 'rxjs';
import { distinctUntilChanged, filter, mergeMap } from 'rxjs/operators';
import { fetchAll } from '../../actions/entity';
import * as fromState from '../../reducers';
import entityConfig from '../../../utils/entities';

export default (action$, state$) => state$.pipe(
  distinctUntilChanged((previous, latest) => fromState.getUser(latest) === fromState.getUser(previous)),
  filter(state => fromState.getUser(state)),
  mergeMap(state => of(
    ..._.chain(entityConfig)
      .keys()
      .filter(entityType => entityConfig[entityType].fetchAllOnLoad)
      .map(entityType => fetchAll(entityType))
      .value()
  ))
);