import { connect } from 'react-redux';
import * as fromState from '../../state/reducers';
import { ENTITY_TYPE } from '../../utils/constants';
import ActionStateCell from '../../components/chart/ActionStateCell';

const mapStateToProps = (state, { action }) => ({
  actionMapping: fromState.getById(state, ENTITY_TYPE.ACTION_MAPPING, action.actionMapping),
  actionConnector: fromState.getById(state, ENTITY_TYPE.ACTION_CONNECTOR, action.connector)
});

export default connect(mapStateToProps)(ActionStateCell);