import { CSSProperties } from 'react';
import { COLOUR } from '../../../utils/constants';

const BORDER_COLOUR = '#555';

export default {
  root: {
    display: 'flex'
  },
  filterButton: {
    borderLeft: 'none',
    padding: '1em'
  },
  expandButton: {
    position: 'absolute',
    left: '26px',
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    height: '50px'
  },
  timeline: (height: number): CSSProperties => ({
    flexGrow: 1,
    height: `${height}px`,
    display: 'inline-flex',
    alignItems: 'center',
    border: `1px solid ${BORDER_COLOUR}`,
    background: COLOUR.MEDIUM_GREY,
    position: 'relative',
    cursor: 'default'
  }),
  trendLabel: {
    position: 'absolute',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    margin: 0,
    cursor: 'pointer',
    boxShadow: '0 0 0 1px rgb(34 36 38 / 15%) inset',
    border: '1px solid rgba(0, 0, 0, 0.1)'
  },
  trendLabelButton: {
    border: 'none'
  },
  trendLabelDividingHeader: {
    fontSize: '1em'
  },
  trendDescription: {
    whiteSpace: 'pre-wrap',
    maxHeight: '10em',
    overflowY: 'scroll'
  },
  trendLabelPopup: {
    background: COLOUR.DARK_GREY,
    border: '1px solid white',
    minWidth: '350px'
  },
  trendLabelUnitSubheader: {
    color: COLOUR.TEXT,
    fontWeight: 'bold'
  },
  trendLabelPopupContent: {
    maxHeight: '30em',
    overflowY: 'scroll'
  }
};