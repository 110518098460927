import AlarmLevelsModal from '../../components/alarm/AlarmLevelsModal';
import { connect } from 'react-redux';
import * as fromState from '../../state/reducers';
import { toggleModal } from '../../state/actions/entity';
import { createSelector } from 'reselect';
import { ENTITY_TYPE, ENTITY_MODAL, ENTITY_PROPERTY } from '../../utils/constants';
import _ from 'lodash';
import { bindActionCreators } from 'redux';

const mapStateToProps = createSelector(
  [
    (state, { id }) => fromState.getModalOpenById(state, ENTITY_TYPE.SENSOR, ENTITY_MODAL.ALARM, id),
    state => fromState.getAlarmLevels(state),
    (state, { id }) => fromState.getById(state, ENTITY_TYPE.SENSOR, id),
    state => fromState.getAll(state, ENTITY_TYPE.ENUMERATION),
    state => fromState.getIsEditing(state, ENTITY_TYPE.SENSOR)   
  ],
  (
    open,
    alarmLevels,
    sensor,
    allEnumerations,
    isSaving
  ) => ({
    open,
    alarmLevels,
    states: _.get(allEnumerations[sensor.enumeration], ENTITY_PROPERTY.ENUMERATION.STATES),
    isSaving
  })
);

const mapDispatchToProps = (dispatch, { id }) => bindActionCreators({
  onClose: () => toggleModal(ENTITY_TYPE.SENSOR, ENTITY_MODAL.ALARM, id)
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AlarmLevelsModal);