import _ from 'lodash';
import { ENTITY_PROPERTY, FEATURE_SETTINGS, VALUE_TYPE } from '../../../../utils/constants';

export default (alarmRanges, values, valueType, enumeration, selectedFeature) => {
  if (!_.includes([VALUE_TYPE.FLOAT, VALUE_TYPE.ENUMERATION], valueType)) {
    return {
      scale: 1,
      offset: 0
    };
  };
  if (selectedFeature && FEATURE_SETTINGS[selectedFeature].NO_OFFSET_SCALE) {
    return {
      scale: 1,
      offset: 0
    };
  };
  if (valueType === VALUE_TYPE.ENUMERATION && enumeration) {
    return {
      scale: (_.size(enumeration.states) - 1) / 2,
      offset: 0
    };
  };
  const maxValue = _.chain(values)
    .maxBy(ENTITY_PROPERTY.SENSOR_VALUE.VALUE)
    .get(ENTITY_PROPERTY.SENSOR_VALUE.VALUE)
    .value();
  const minValue = _.chain(values)
    .minBy(ENTITY_PROPERTY.SENSOR_VALUE.VALUE)
    .get(ENTITY_PROPERTY.SENSOR_VALUE.VALUE)
    .value();
  const max = _.isEmpty(alarmRanges)
    ? maxValue
    : _.max([maxValue, _.last(alarmRanges).max]);
  const min = _.isEmpty(alarmRanges)
    ? minValue
    : _.min([minValue, _.first(alarmRanges).min]);
  if (max - min === 0) {
    return {
      scale: 1,
      offset: 0
    };
  };
  return {
    scale: (max - min) / 2,
    offset: min
  };
};