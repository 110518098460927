import MuteModal from '../../components/dashboard/MuteModal';
import { connect } from 'react-redux';
import * as fromState from '../../state/reducers';
import { setMuteModal, setUntil, saveMutedUntil } from '../../state/actions/dashboard';

const mapStateToProps = state => ({
  open: fromState.getMuteModalOpen(state, 'mute'),
  id: fromState.getMuteModalId(state, 'mute'),
  until: fromState.getMuteModalUntil(state, 'mute'),
  isSaving: fromState.getMuteModalIsSaving(state, 'mute')
});

const mapDispatchToProps = {
  onClose: () => setMuteModal('mute', false),
  onChangeUntil: setUntil,
  onMute: saveMutedUntil
};

export default connect(mapStateToProps, mapDispatchToProps)(MuteModal);