import { combineEpics } from 'redux-observable';
import entityEpics from './entity';
import authEpics from './auth';
import chartEpics from './chart';
import dashboardEpics from './dashboard';
import playbackEpics from './playback';
import alarmEpics from './alarm';
import navigationEpics from './navigation';

export default combineEpics(
  entityEpics,
  authEpics,
  chartEpics,
  dashboardEpics,
  playbackEpics,
  alarmEpics,
  navigationEpics
);