import isPlaying, * as fromIsPlaying from './isPlaying';
import mode, * as fromMode from './mode';
import speed, * as fromSpeed from './speed';
import time, * as fromTime from './time';
import reviewId, * as fromReviewId from './reviewId';
import reviewTrendId, * as fromReviewTrendId from './reviewTrendId';
import { combineReducers } from 'redux';

export default combineReducers({
  isPlaying,
  mode,
  speed,
  time,
  reviewId,
  reviewTrendId
});

export const getIsPlaying = state => fromIsPlaying.getIsPlaying(state.isPlaying);
export const getMode = state => fromMode.getMode(state.mode);
export const getSpeed = state => fromSpeed.getSpeed(state.speed);
export const getTime = state => fromTime.getTime(state.time);
export const getReviewId = state => fromReviewId.getReviewId(state.reviewId);
export const getReviewTrendId = state => fromReviewTrendId.getReviewTrendId(state.reviewTrendId);