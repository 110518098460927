import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { RESET_APP } from 'state/actions/auth';

export default (action$: Observable<any>) => (source: Observable<any>) => source.pipe(
  takeUntil(action$.pipe(
    ofType(RESET_APP),
    take(1)
  ))
);