import { SET_COMPARE_TREND } from '../../actions/chart';

export default (state: string | null = null, action: any): string | null => {
  switch (action.type) {
    case SET_COMPARE_TREND:
      return action.payload.id;
    default:
      return state;
  }
};

export const getCompareTrend = (state: string | null): string | null => state;