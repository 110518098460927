import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import * as entitySelectors from '../../../state/selectors/entity';

const mapStateToProps = createSelector(
  [
    (state, { id }) => id,
    state => entitySelectors.getUnitPaths(state)
  ],
  (
    id,
    unitPaths
  ) => ({
    path: unitPaths[id] || null
  })
);

const UnitText = ({ path }) => path;
UnitText.displayName = 'UnitText';

export default connect(mapStateToProps)(UnitText);