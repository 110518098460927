import _ from 'lodash';
import moment from 'moment';
import { createSelector } from 'reselect';
import * as fromState from '../../../reducers';

export const getTrendQuery = createSelector(
  [fromState.getCompareFromDate, fromState.getCompareToDate, fromState.getCompareLabels],
  (fromDate, toDate, labels) => ({
    sort: {
      To: -1
    },
    filter: {
      ...fromDate
        ? {
          From: {
            $gte: moment(fromDate).utc().toISOString()
          }
        }
        : {},
      ...toDate
        ? {
          To: {
            $lte: moment(toDate).utc().toISOString()
          }
        }
        : {},
      ..._.some(labels)
        ? {
          Labels: {
            $all: labels
          }
        }
        : {}
    }
  })
);