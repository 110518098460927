import { Popup, Button } from 'semantic-ui-react';
import { INVERTED_THEME } from '../../../utils/constants';
import styles from './PopupButton.styles';
import { POPUP_BUTTON, POPUP_BUTTON_POPUP } from './PopupButton.testIds';

interface Props {
  popupText: string;
  popupOpen?: boolean;
  [key: string]: any;
}

const PopupButton = ({
  popupText,
  popupOpen,
  ...props
}: Props) => (
  <Popup
    data-testid={POPUP_BUTTON_POPUP}
    open={popupOpen}
    inverted={INVERTED_THEME}
    style={styles.popup}
    trigger={(
      <Button
        data-testid={POPUP_BUTTON}
        {...props} />
    )}
    on='hover'>
    {popupText}
  </Popup>
);

PopupButton.displayName = 'PopupButton';

export default PopupButton;