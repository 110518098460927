import { Button, Modal } from 'semantic-ui-react';
import AnimatedButton from '../shared/AnimatedButton';
import { defineMessages, injectIntl } from 'react-intl';
import EntityFormContainer from '../../containers/entity/EntityFormContainer';
import { ENTITY_TYPE, ENTITY_FORM_ELEMENT_TYPE, SELECT_CONTEXT_LIST } from '../../utils/constants';

const messages = defineMessages({
  'header.text': {
    id: 'functionSensorsModal.header.text',
    defaultMessage: 'Add'
  },
  'cancelButton.text': {
    id: 'functionSensorsModal.cancelButton.text',
    defaultMessage: 'Cancel'
  },
  'addButton.text': {
    id: 'functionSensorsModal.addButton.text',
    defaultMessage: 'Add'
  }
});

const FunctionSensorsModal = injectIntl(({
  open,
  trigger,
  onClose,
  selectedSensors,
  onAdd,
  isSaving,
  hiddenSensors,
  intl: {
    formatMessage
  }
}) => (
  <Modal
    open={open}
    trigger={trigger}
    centered
    closeOnDimmerClick={false}
    closeIcon
    size='large'
    closeOnEscape
    onClose={onClose}>
    <Modal.Header content={formatMessage(messages['header.text'])} />
    <Modal.Content
      scrolling
      style={{ minHeight: '50vh' }}>
      <EntityFormContainer
        entityType={ENTITY_TYPE.PREDICTOR}
        form={[{
          name: 'additionalFunctionMappings',
          type: ENTITY_FORM_ELEMENT_TYPE.SELECT,
          selectContextListName: SELECT_CONTEXT_LIST.ATTRIBUTES,
          search: true,
          multiple: true,
          hideOptions: hiddenSensors
        }]} />
    </Modal.Content>
    <Modal.Actions>
      <Button
        content={formatMessage(messages['cancelButton.text'])}
        onClick={onClose} />
      <AnimatedButton
        text={formatMessage(messages['addButton.text'])}
        icon='add'
        positive
        loading={isSaving}
        onClick={() => onAdd(selectedSensors)} />
    </Modal.Actions>
  </Modal>
));

FunctionSensorsModal.displayName = 'FunctionSensorsModal';

export default FunctionSensorsModal;