import _ from 'lodash';
import { defineMessages, injectIntl } from 'react-intl';
import { Dropdown, Form, Label, Segment, Table, Header, Popup, Icon, Button } from 'semantic-ui-react';
import DateRangePicker from '../shared/DateRangePicker';
import moment from 'moment';
import { DATE_FORMAT, INVERTED_THEME, COLOUR, ENTITY_TYPE } from '../../utils/constants';
import EntityText from '../entity/EntityText';

const messages = defineMessages({
  'header': {
    id: 'trendSearch.header',
    defaultMessage: 'COMPARE'
  },
  'label.placeholder': {
    id: 'trendSearch.label.placeholder',
    defaultMessage: 'Label'
  },
  'label.label': {
    id: 'trendSearch.label.label',
    defaultMessage: 'Label'
  },
  'loadMoreButton.text': {
    id: 'trendSearch.loadMoreButton.text',
    defaultMessage: 'Load More'
  }
});

const TrendSearch = injectIntl(({
  style,
  trends,
  fromDate,
  toDate,
  labels,
  labelOptions,
  hasMoreTrends,
  loading,
  compareTrend,
  onClickTrend,
  onChangeLabels,
  onChangeFromDate,
  onChangeToDate,
  onClickLoadMore,
  intl: {
    formatMessage
  }
}) => {
  return (
    <div style={{
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden'
    }}>
      <Segment
        inverted={INVERTED_THEME}
        raised
        style={{
          background: COLOUR.BACKGROUND,
          ...style
        }}>
        <Form inverted={INVERTED_THEME}>
          <DateRangePicker
            noQuickRange
            noFormWrapper
            fromDate={fromDate}
            toDate={toDate}
            onChangeFromDate={onChangeFromDate}
            onChangeToDate={onChangeToDate} />
          <Form.Field size='mini'>
            <label>{formatMessage(messages['label.label'])}</label>
            <Dropdown
              search
              multiple
              selection
              clearable
              value={labels}
              onChange={(event, { value }) => onChangeLabels(value)}
              placeholder={formatMessage(messages['label.placeholder'])}
              options={_.map(labelOptions, ({ name }) => ({
                key: name,
                text: name,
                value: name
              }))} />
          </Form.Field>
          {/*
          <Form.Field>
            <label>All</label>
            <Form.Checkbox
              checked={all}
              onChange={(event, { checked }) => onChangeAll(checked)} />
          </Form.Field>
          */}
        </Form>
      </Segment>
      <Segment
        inverted={INVERTED_THEME}
        raised
        style={{
          background: COLOUR.BACKGROUND,
          padding: 0,
          overflowY: 'auto',
          flex: '1 1 auto',
          ...style
        }}>
        <Table
          inverted={INVERTED_THEME}
          selectable>
          <Table.Body>
            {_.map(trends, trend => (
              <Table.Row
                key={trend.id}
                onClick={() => onClickTrend(_.get(compareTrend, 'id') === trend.id ? null : trend.id)}
                active={_.get(compareTrend, 'id') === trend.id}
                style={{ cursor: 'pointer' }}>
                <Table.Cell>
                  <Header
                    inverted={INVERTED_THEME}
                    size='tiny'>
                    {trend.name}
                    <Header.Subheader>
                      {moment(trend.to).format(DATE_FORMAT)}
                    </Header.Subheader>
                  </Header>
                </Table.Cell>
                <Table.Cell>
                  <EntityText
                    id={trend.unitId}
                    entityType={ENTITY_TYPE.UNIT} />
                </Table.Cell>
                <Table.Cell>
                  <Label.Group size='small'>
                    {_.map(trend.labels, label => (
                      <Label key={label}>{label}</Label>
                    ))}
                  </Label.Group>
                </Table.Cell>
                <Table.Cell>
                  <Popup
                    trigger={(
                      <Icon name='info circle' />
                    )}
                    position='left center'
                    content={trend.description}
                    hoverable
                    basic
                    inverted={INVERTED_THEME}
                    style={{
                      background: COLOUR.MEDIUM_GREY,
                      border: '1px solid white',
                      whiteSpace: 'pre-wrap',
                      maxHeight: '20em',
                      overflowY: 'scroll'
                    }} />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        {hasMoreTrends && (
          <Button
            content={formatMessage(messages['loadMoreButton.text'])}
            fluid
            primary
            loading={loading}
            onClick={onClickLoadMore} />
        )}
      </Segment>
    </div>
  );
});

TrendSearch.displayName = 'TrendSearch';

export default TrendSearch;