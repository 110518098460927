import { defineMessages } from 'react-intl';

export default defineMessages({
  'types.label': {
    id: 'entityForm.types.label',
    defaultMessage: 'Type'
  },
  'types.placeholder': {
    id: 'entityForm.types.placeholder',
    defaultMessage: 'Type'
  },
  'types.create': {
    id: 'entityForm.types.create',
    defaultMessage: 'Add New'
  },
  'types.literalOrAttribute.literal.placeholder': {
    id: 'entityForm.types.literalOrAttribute.literal.placeholder',
    defaultMessage: 'Enter Value'
  },
  'types.literalOrAttribute.attribute.placeholder': {
    id: 'entityForm.types.literalOrAttribute.attribute.placeholder',
    defaultMessage: 'Or Select'
  }
});