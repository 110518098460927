import EntityText from 'components/entity/EntityText';
import { ENTITY_TYPE } from 'utils/constants';
import Avatar from './Avatar';

export default function ModelAvatar({
  author
}) {
  if (!author) {
    return null;
  };
  if (author === 'prophes') {
    return (
      <Avatar
        imageSrc='/images/favicon.ico'
        text='PROPHES' />
    );
  };
  return (
    <EntityText
      entityType={ENTITY_TYPE.USER_MAPPING}
      id={author} />
  );
};