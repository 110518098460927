import _ from 'lodash';
import createCachedSelector from 're-reselect';
import { getOutputAlarmRanges } from '../getOutputAlarmRanges';
import { getOutputData } from '../getOutputData';
import { getOutputs } from '../getOutputs';
import { getOutputProperties } from '../getOutputProperties';
import getOffsetScale from './getOffsetScale';
import { ENTITY_PROPERTY } from '../../../../utils/constants';
import { getOutputEnumerations } from '../getEnumerations';

export const getOutputOffsetScale = createCachedSelector(
  [
    getOutputs,
    getOutputAlarmRanges,
    getOutputData,
    getOutputProperties,
    getOutputEnumerations
  ],
  (
    outputs,
    outputAlarmRanges,
    outputData,
    outputProperties,
    outputEnumerations
  ) => _.chain(outputs)
    .map(output => [
      output,
      getOffsetScale(
        outputAlarmRanges[output],
        outputData[output],
        _.get(outputProperties[output], ENTITY_PROPERTY.SENSOR.VALUE_TYPE),
        outputEnumerations[output]
      )
    ])
    .fromPairs()
    .value()
)(
  (state, chartType) => chartType
);