import createCachedSelector from 're-reselect';
import * as fromState from '../../../state/reducers';
import { CHART_TYPE, DATE_FORMAT, DATE_FORMAT_TIME_ONLY, DATE_FORMAT_NO_TIME } from '../../../utils/constants';

const bucketSizes = {
  'PT1H': {
    period: 'minutes',
    step: 5,
    format: DATE_FORMAT_TIME_ONLY
  },
  'PT12H': {
    period: 'minutes',
    step: 30,
    format: DATE_FORMAT_TIME_ONLY
  },
  'P1D': {
    period: 'hours',
    step: 1,
    format: DATE_FORMAT_TIME_ONLY
  },
  'P2D': {
    period: 'hours',
    step: 2,
    format: DATE_FORMAT
  },
  'P3D': {
    period: 'hours',
    step: 3,
    format: DATE_FORMAT
  },
  'P7D': {
    period: 'hours',
    step: 12,
    format: DATE_FORMAT
  },
  'P1M': {
    period: 'days',
    step: 1,
    format: DATE_FORMAT_NO_TIME
  }
};
const DEFAULT_BUCKET_SIZE = bucketSizes['PT12H'];

export default createCachedSelector(
  [state => fromState.getQuickRange(state, CHART_TYPE.MAIN)],
  quickRange => bucketSizes[quickRange] || DEFAULT_BUCKET_SIZE
)(
  (state, chartType) => chartType
);