import _ from 'lodash';
import { quickRanges, messages } from 'utils/dates';
import iconMessages from '../i18n/shared/icons';
import colourMessages from '../i18n/shared/colours';
import { SemanticCOLORS, SemanticICONS } from 'semantic-ui-react';

export const LOCALE = 'en';

export enum CHART_TYPE {
  MAIN = 'CHART_TYPE/MAIN',
  COMPARE = 'CHART_TYPE/COMPARE'
};

export enum ENTITY_TYPE {
  ACTION = 'action',
  ACTION_CONNECTOR = 'actionConnector',
  ACTION_MAPPING = 'actionMapping',
  ALERT_CONNECTOR = 'alertConnector',
  ALERT_MAPPING = 'alertMapping',
  AUTH_GROUP = 'authGroup',
  ENUMERATION = 'enumeration',
  LABEL = 'label',
  MODEL = 'model',
  PREDICTOR = 'predictor',
  REVIEW = 'review',
  SENSOR = 'sensor',
  SENSOR_CONNECTOR = 'sensorConnector',
  SEQUENCE = 'sequence',
  TREND = 'trend',
  TREND_TYPE = 'trendType',
  UNIT = 'unit',
  UNIT_CATEGORY = 'unitCategory',
  UNIT_TYPE = 'unitType',
  UNIT_TYPE_ACTION_MAPPING = 'unitTypeActionMapping',
  UNIT_TYPE_ALERT_MAPPING = 'unitTypeAlertMapping',
  UNIT_TYPE_INSTANCE = 'unitTypeInstance',
  UNIT_TYPE_ATTRIBUTE = 'unitTypeAttribute',
  UNIT_TYPE_PARAMETER = 'unitTypeParameter',
  UNIT_TYPE_PREDICTOR = 'unitTypePredictor',
  USER_MAPPING = 'userMapping'
};

export const DATE_FORMAT_NO_TIME = 'ddd D MMM YY';
export const DATE_FORMAT = `${DATE_FORMAT_NO_TIME}  HH:mm`;
export const DATE_FORMAT_WITH_SECONDS = `${DATE_FORMAT}:ss`;
export const DATE_FORMAT_TIME_ONLY = 'HH:mm:ss';

export enum SELECTION_TYPE {
  INPUT = 'input',
  OUTPUT = 'output',
  FUNCTION = 'function'
};

export enum DASHBOARD_VIEW {
  UNITS = 'units',
  UNIT_TYPES = 'unitTypes'
};

export enum CARD_SIZE {
  SMALL = 0,
  MEDIUM = 1,
  LARGE = 2
};

export enum CHART_VIEW {
  CARDS = 'cards',
  HEAT_MAP = 'heatMap',
  TIME_SERIES = 'timeSeries',
  MAP = 'map'
};

export enum CONTROLLER {
  MODEL = 'model',
  PREDICTOR = 'predictor',
  UNIT_TYPE_PREDICTOR = 'unittypepredictor',
  UNIT_TYPE = 'unittype',
  SENSOR = 'sensor',
  TREND = 'trend',
  ACTION_MAPPING = 'actionmapping',
  ACTION_CONNECTOR = 'actionconnector',
  USER_MAPPING = 'usermapping',
  REVIEW = 'review',
  ENUMERATION = 'enumeration'
};

export const ENTITY_PROPERTY = {
  AUTH_GROUP: {
    ID: 'id',
    NAME: 'name',
    ROLES: 'roles'
  },
  TREND: {
    ID: 'id',
    NAME: 'name',
    TYPE: 'type',
    FROM: 'from',
    TO: 'to',
    DESCRIPTION: 'description',
    LABELS: 'labels'
  },
  TREND_TYPE: {
    ICON: 'icon',
    COLOUR: 'colour'
  },
  MODEL: {
    ID: 'id',
    TYPE: 'type',
    INPUTS: 'inputs',
    INPUT: {
      ENUMERATION: 'enumeration'
    },
    OUTPUT: {
      NAME: 'name',
      VALUE_TYPE: 'valueType',
      UNITS_OF_MEASUREMENT: 'unitsOfMeasurement',
      ENUMERATION: 'enumeration'
    },
    OUTPUTS: 'outputs',
    AUTHOR: 'author'
  },
  PREDICTOR: {
    ID: 'id',
    NAME: 'name',
    TYPE: 'type',
    INPUT_MAPPINGS: 'inputMappings',
    INPUT_MAPPING: {
      SENSOR: 'sensor',
      INPUT: 'input'
    },
    OUTPUT_MAPPINGS: 'outputMappings',
    OUTPUT_MAPPING: {
      OUTPUT: 'output',
      ALARM_RANGES: 'alarmRanges'
    },
    FUNCTION_MAPPINGS: 'functionMappings',
    FUNCTION_MAPPING: {
      SENSOR: 'sensor',
      FUNCTION: 'function'
    },
    UNIT: 'unitId',
    WINDOW: 'window'
  },
  SENSOR: {
    ID: 'id',
    NAME: 'name',
    DESCRIPTION: 'description',
    ALARM_RANGES: 'alarmRanges',
    TYPE: 'type',
    UNITS_OF_MEASUREMENT: 'unitsOfMeasurement',
    VALUE_TYPE: 'valueType'
  },
  UNIT: {
    ID: 'id',
    NAME: 'name',
    DESCRIPTION: 'description',
    TYPE: 'type',
    UNIT: 'unitId'
  },
  SENSOR_VALUE: {
    TIMESTAMP: '[0]',
    VALUE: '[1]'
  },
  LABEL: {
    ID: 'id',
    NAME: 'name'
  },
  USER: {
    ID: 'id',
    NAME: 'name',
    USER_MAPPINGS: 'userMappings'
  },
  USER_MAPPING: {
    TENANT_NAME: 'tenantName',
    TENANT_ID: 'tenantId'
  },
  TENANT: {
    ID: 'id',
    NAME: 'name'
  },
  ENUMERATION: {
    STATES: 'states',
    NAME: 'name'
  },
  UNIT_CATEGORY: {
    NAME: 'name',
    ICON: 'icon'
  },
  UNIT_TYPE: {
    ID: 'id',
    NAME: 'name',
    PARENT_TYPE: 'parentType',
    ATTRIBUTES: 'attributes',
    BASE_TYPE: 'baseType'
  },
  UNIT_TYPE_ATTRIBUTE: {
    NAME: 'name',
    DESCRIPTION: 'description',
    VALUE_TYPE: 'valueType',
    UNIT_TYPE: 'unitType'
  },
  UNIT_TYPE_INSTANCE: {
    ID: 'id',
    UNIT: 'unitId',
    UNIT_TYPE: 'type'
  },
  UNIT_TYPE_PARAMETER: {
    NAME: 'name',
    DESCRIPTION: 'description',
    VALUE_TYPE: 'valueType',
    UNIT_TYPE: 'unitType'
  },
  UNIT_TYPE_PREDICTOR: {
    ID: 'id',
    NAME: 'name',
    UNIT: 'unitId',
    UNIT_TYPE: 'unitType',
    TYPE: 'type'
  },
  ALERT_CONNECTOR: {
    TYPE: 'type'
  },
  ACTION_CONNECTOR: {
    TYPE: 'type'
  },
  ACTION: {
    ID: 'id'
  },
  REVIEW: {
    NAME: 'name',
    TRENDS: 'trends',
    REGIONS: 'regions',
    TREND: {
      REGIONS: 'regions'
    }
  }
};

export const HEAT_MAP_POPUP_GAUGE_MIN_NEEDLE_WIDTH = 0.02;

export const TREND_SEARCH_PAGE_SIZE = 5;

export enum LOADING {
  TRENDS = 'LOADING/TRENDS'
};

export enum ENTITY_CONFIG {
  IMPORT = 'import',
  API_PATH = 'apiPath',
  API_PREFIX = 'apiPrefix',
  LABELS = 'labels',
  NAVIGATE_ON_ADD = 'navigateOnAdd',
  NAVIGATE_ON_CLICK = 'navigateOnClick',
  OPEN_ON_ADD = 'openOnAdd',
  OPEN_ON_CLICK = 'openOnClick',
  PAGE_URL = 'pageUrl',
  IS_CONNECTOR = 'isConnector',
  SEARCH = 'search',
  CREATE_SEARCH_QUERY = 'createSearchQuery',
  PARSE_SEARCH_FILTER = 'parseSearchFilter',
  PARSE_SEARCH_SORT = 'parseSearchSort',
  ITEM_URL = 'itemUrl',
  MESSAGES = 'messages',
  CONDITIONS = 'conditions',
  DISABLE_FETCH_TYPES = 'disableFetchTypes',
  NO_ACTIONS = 'noActions',
  FORM_VALUE_SEARCH_SCHEMA = 'formValueSearchSchema',
  PARENT_ENTITY_TYPE = 'parentEntityType',
  TYPE_ENTITY_TYPE = 'typeEntityType',
  FETCH_BY_ID_SCHEMA = 'fetchByIdSchema',
  TYPE_INHERITED_FIELDS = 'typeInheritedFields',
  ADDITIONAL_IMPORTS = 'additionalImports',
  HIDE_ADD = 'hideAdd',
  SEARCH_ADD_ENTITY_TYPES = 'searchAddEntityTypes',
  DEFAULT_FORM_VALUES = 'defaultFormValues',
  DASHBOARD_DECK_HEADER = 'dashboardDeckHeader'
};

export enum ENTITY_MODAL {
  IMPORT = 'ENTITY_MODAL/IMPORT',
  ADD = 'add',
  DELETE = 'delete',
  EDIT = 'edit',
  ALTERNATIVE_EDIT = 'alternativeEdit',
  ALARM = 'alarm',
  ACCESS_TOKEN = 'accessToken'
};

export const COLOUR_PALETTE = 'rainbow';

export enum AUTH_TYPE {
  REGISTER = 'AUTH_TYPE/REGISTER',
  LOGIN = 'AUTH_TYPE/LOGIN',
  FORGOT = 'AUTH_TYPE/FORGOT',
  CHANGE = 'AUTH_TYPE/CHANGE',
  JOIN = 'AUTH_TYPE/JOIN',
  CONFIRM = 'AUTH_TYPE/CONFIRM',
  RESEND = 'AUTH_TYPE/RESEND'
};

export enum AUTH_MESSAGE {
  PASSWORD_CHANGED = 'passwordChanged',
  EMAIL_CONFIRMED = 'emailConfirmed',
  RESET_LINK_SENT = 'resetLinkSent',
  JOINED_TENANT = 'joinedTenant',
  CONFIRM_LINK_SENT = 'confirmLinkSent',
  CONFIRM_LINK_RESENT = 'confirmLinkResent'
};

export enum PAGE_URL {
  HOME = '/',
  LOGIN = '/',
  NOT_HOME = '/:path',
  LOGOUT = '/logout',
  REGISTER = '/register',
  CHANGE = '/change',
  CONFIRM = '/confirm',
  RESEND = '/resend',
  FORGOT = '/forgot',
  JOIN = '/join',
  EXPLORER = '/explorer',
  ENTITY_PAGE = '/:pageUrl',
  ENTITY_PARENT = '/:parentId',
  ENTITY = '/:pageUrl/:entityId',
  UNIT = '/unit/:unitPath',
  PREDICTOR = '/unit/:unitPath/group/:groupId',
  TREND = '/unit/:unitPath/group/:groupId/trend/:trendId',
  PREDICTOR_CONFIGURATION = '/unit/:unitPath/group/:parentId/configuration',
  PREDICTOR_OPTIONAL_CONFIGURATION = '/unit/:unitPath/group/:groupId/(configuration)?',
  UNIT_TYPE_PREDICTOR_PAGE = '/unittypes/:unitTypeId/:parentId',
  GATEWAY_TIMEOUT = '/504',
  SERVICE_UNAVAILABLE = '/503',
  NOT_FOUND = '/404',
  UNIT_TYPE = '/unittype/:unitTypeId',
  UNIT_TYPE_PREDICTOR = '/unittype/:unitTypeId/predictor/:unitTypePredictorId'
};

export const ROLE = {
  VIEW_ENUMERATION: {
    id: 'viewEnumeration',
    name: 'View Enumeration'
  },
  MODIFY_ENUMERATION: {
    id: 'modifyEnumeration',
    name: 'Modify Enumeration'
  },
  VIEW_UNIT_CATEGORY: {
    id: 'viewUnitCategory',
    name: 'View Unit Category'
  },
  MODIFY_UNIT_CATEGORY: {
    id: 'modifyUnitCategory',
    name: 'Modify Unit Category'
  },
  VIEW_UNIT_TYPE: {
    id: 'viewUnitType',
    name: 'View Unit Type'
  },
  MODIFY_UNIT_TYPE: {
    id: 'modifyUnitType',
    name: 'Modify Unit Type'
  },
  VIEW_SENSOR: {
    id: 'viewSensor',
    name: 'View Sensor'
  },
  MODIFY_SENSOR: {
    id: 'modifySensor',
    name: 'Modify Sensor'
  },
  VIEW_SEQUENCE: {
    id: 'viewSequence',
    name: 'View Sequence'
  },
  MODIFY_SEQUENCE: {
    id: 'modifySequence',
    name: 'Modify Sequence'
  },
  VIEW_TREND_TYPE: {
    id: 'viewTrendType',
    name: 'View Trend Type'
  },
  MODIFY_TREND_TYPE: {
    id: 'modifyTrendType',
    name: 'Modify Trend Type'
  },
  USE_EXPLORER: {
    id: 'useExplorer',
    name: 'Use Explorer'
  },
  ADMIN: {
    id: 'admin',
    name: 'Admin'
  }
};

export enum ENTITY_FORM_MODE {
  EDIT = 'edit',
  ADD = 'add'
};

export enum PLAYBACK_MODE {
  LIVE = 'live',
  HISTORICAL = 'historical',
  REVIEW = 'review'
};

export enum PLAYBACK_SPEED {
  X1 = 1,
  X2 = 5,
  X10 = 10,
  X60 = 60
};

export const MAX_PLAYBACK_SPEED = PLAYBACK_SPEED.X60;

export const MILLISECONDS_IN_MINUTE = 60000;

export const MILLISECONDS_IN_SECOND = 1000;

export const SECONDS_IN_MINUTE = 60;
export const SECONDS_IN_HOUR = 60 * 60;

export const MIN_DASHBOARD_UPDATE_PERIOD_MILLISECONDS = 15000;
export const MIN_CHART_UDPATE_PERIOD = 6;

export const UNIT_PATH_SEPARATOR = '-';

export const EMPTY_STRING = '';

export enum COLOUR {
  RED = '#D32F2F',
  ORANGE = '#F57C00',
  GREEN = '#388E3C',
  GREY = '#455A64',
  DARK_GREY = '#222',
  MEDIUM_GREY = '#333',
  LIGHT_GREY = '#444',
  BACKGROUND = '#282828',
  HEADER = '#1b1c1b',
  TEXT = 'white',
  BLUE = '#2185d0'
};

export enum SEMANTIC_COLOUR {
  red = '#DB2828',
  orange = '#F2711C',
  yellow = '#FBBD08',
  olive = '#B5CC18',
  green = '#21BA45',
  teal = '#00B5AD',
  blue = '#2185D0',
  violet = '#6435C9',
  purple = '#A333C8',
  pink = '#E03997',
  brown = '#A5673F',
  grey = '#767676',
  black = '#1B1C1D'
};

export const INVERTED_THEME = true;

/*
Starting point for light theme:

export const COLOUR = {
  RED: '#D32F2F',
  ORANGE: '#F57C00',
  GREEN: '#388E3C',
  GREY: 'white',
  DARK_GREY: 'white',
  MEDIUM_GREY: 'white',
  LIGHT_GREY: 'white',
  BACKGROUND: 'white',
  HEADER: 'orange',
  TEXT: 'black'
};

export const INVERTED_THEME = false;
*/

export enum ENTITY_FORM_TYPE {
  SEARCH = 'ENTITY_FORM_TYPE/SEARCH',
  ADD_OR_EDIT = 'ENTITY_FORM_TYPE/ADD_OR_EDIT'
};

export const SENSOR_VALUE_DATE_FORMAT = 'X';

export const REFERENCE_AREA_CLASS_NAME = 'reference-area';

export const TREND_TIMELINE_QUERY_PAGE_SIZE = 1000;

export const NEUTRAL_SEVERITY = -1;

export const SEVERITY_COLOURS = {
  [NEUTRAL_SEVERITY]: COLOUR.BLUE,
  0: COLOUR.GREEN,
  1: COLOUR.ORANGE,
  2: COLOUR.RED
};

export const SEVERITY_COLOURS_BRIGHT: { [key: number]: SemanticCOLORS; } = {
  [NEUTRAL_SEVERITY]: 'blue',
  0: 'green',
  1: 'orange',
  2: 'red'
};

export const COLOUR_SEVERITIES = _.chain(SEVERITY_COLOURS)
  .invert()
  .mapValues(value => _.toNumber(value))
  .value();

export const SEVERITY_ICON: { [key: number]: SemanticICONS; } = {
  [NEUTRAL_SEVERITY]: 'check circle outline',
  0: 'check',
  1: 'warning circle',
  2: 'warning sign'
};

export const NO_DATA_ICON = 'ban';

export const DEFAULT_ALARM_RANGES = [
  {
    min: 0,
    max: 1,
    severity: NEUTRAL_SEVERITY
  }
];

export enum VALUE_TYPE {
  FLOAT = 'float',
  DATE = 'date',
  GEO_POINT = 'geoPoint',
  STRING = 'string',
  ENUMERATION = 'enumeration'
};

export enum PHALANX_WORK_TYPE {
  DELIVERY = 'delivery',
  RETURN = 'return',
  SERVICE = 'service'
};

export const UNIT_SHORTHAND = {
  'mbar': ' mbar',
  'DEG C': '°C',
  'bar': ' bar'
};

export enum PROPERTY {
  VALUE = 'value',
  RATE_OF_CHANGE = 'rateOfChange'
};

export const PROPERTY_SETTINGS = {
  [PROPERTY.VALUE]: {},
  [PROPERTY.RATE_OF_CHANGE]: {
    DEFAULT_TICKS: true,
    UNITS_OF_MEASUREMENT_SUFFIX: '/h'
  }
};

export enum FEATURE {
  LATEST_FLOAT = 'latest.float',
  LATEST_DATE = 'latest.date',
  LATEST_GEO_POINT = 'latest.geoPoint',
  LATEST_STRING = 'latest.string',
  LATEST_ENUMERATION = 'latest.enumeration',
  RATE_OF_CHANGE = 'rateOfChange',
  STEP_CHANGE = 'stepChange',
  MEAN = 'mean',
  MEDIAN = 'median',
  STANDARD_DEVIATION = 'standardDeviation',
  MAXIMUM = 'maximum',
  MINIMUM = 'minimum',
  RANGE = 'range',
  LINEAR_FORECAST = 'linearForecast',
  SKEWNESS = 'skewness',
  KURTOSIS = 'kurtosis',
  IMPULSE = 'impulse',
  DIFFERENCE_FROM_MEAN = 'differenceFromMean',
  NUMBER_OF_STATE_CHANGES = 'numberOfStateChanges',
  DEVIATION_FROM = 'deviationFrom',
  RANGE_OF_DEVIATION_FROM = 'rangeOfDeviationFrom',
  DIVIDED_BY = 'dividedBy',
  DISTINCT_STATES = 'distinctStates'
};

interface FeatureSettings {
  VALUE_TYPE: VALUE_TYPE;
  NO_OFFSET_SCALE?: boolean;
  HIDE_ALARM_RANGES?: boolean;
  HIDE_UNITS_OF_MEASUREMENT?: boolean;
  DEFAULT_TICKS?: boolean;
  REQUIRES_INPUT?: boolean;
  REQUIRES_WINDOW?: boolean;
  OUTPUT_VALUE_TYPE?: string;
  UNITS_OF_MEASUREMENT_SUFFIX?: string;
  UNITS_OF_MEASUREMENT_TEMPLATE?: string;
}

export const FEATURE_SETTINGS: { [key: string]: FeatureSettings; } = {
  [FEATURE.LATEST_FLOAT]: {
    NO_OFFSET_SCALE: false,
    HIDE_ALARM_RANGES: false,
    HIDE_UNITS_OF_MEASUREMENT: false,
    VALUE_TYPE: VALUE_TYPE.FLOAT
  },
  [FEATURE.LATEST_DATE]: {
    NO_OFFSET_SCALE: false,
    HIDE_ALARM_RANGES: false,
    HIDE_UNITS_OF_MEASUREMENT: false,
    VALUE_TYPE: VALUE_TYPE.DATE
  },
  [FEATURE.LATEST_GEO_POINT]: {
    NO_OFFSET_SCALE: false,
    HIDE_ALARM_RANGES: false,
    HIDE_UNITS_OF_MEASUREMENT: false,
    VALUE_TYPE: VALUE_TYPE.GEO_POINT
  },
  [FEATURE.LATEST_STRING]: {
    NO_OFFSET_SCALE: false,
    HIDE_ALARM_RANGES: false,
    HIDE_UNITS_OF_MEASUREMENT: false,
    VALUE_TYPE: VALUE_TYPE.STRING
  },
  [FEATURE.LATEST_ENUMERATION]: {
    NO_OFFSET_SCALE: false,
    HIDE_ALARM_RANGES: false,
    HIDE_UNITS_OF_MEASUREMENT: false,
    VALUE_TYPE: VALUE_TYPE.ENUMERATION
  },
  [FEATURE.RATE_OF_CHANGE]: {
    NO_OFFSET_SCALE: false,
    DEFAULT_TICKS: true,
    HIDE_ALARM_RANGES: true,
    HIDE_UNITS_OF_MEASUREMENT: false,
    UNITS_OF_MEASUREMENT_SUFFIX: '/h',
    VALUE_TYPE: VALUE_TYPE.FLOAT
  },
  [FEATURE.STEP_CHANGE]: {
    NO_OFFSET_SCALE: false,
    DEFAULT_TICKS: true,
    HIDE_ALARM_RANGES: true,
    HIDE_UNITS_OF_MEASUREMENT: false,
    VALUE_TYPE: VALUE_TYPE.FLOAT
  },
  [FEATURE.MEAN]: {
    NO_OFFSET_SCALE: false,
    HIDE_ALARM_RANGES: false,
    HIDE_UNITS_OF_MEASUREMENT: false,
    VALUE_TYPE: VALUE_TYPE.FLOAT
  },
  [FEATURE.MEDIAN]: {
    NO_OFFSET_SCALE: false,
    HIDE_ALARM_RANGES: false,
    HIDE_UNITS_OF_MEASUREMENT: false,
    VALUE_TYPE: VALUE_TYPE.FLOAT
  },
  [FEATURE.STANDARD_DEVIATION]: {
    NO_OFFSET_SCALE: false,
    HIDE_ALARM_RANGES: true,
    HIDE_UNITS_OF_MEASUREMENT: false,
    VALUE_TYPE: VALUE_TYPE.FLOAT
  },
  [FEATURE.MAXIMUM]: {
    NO_OFFSET_SCALE: false,
    HIDE_ALARM_RANGES: false,
    HIDE_UNITS_OF_MEASUREMENT: false,
    VALUE_TYPE: VALUE_TYPE.FLOAT
  },
  [FEATURE.MINIMUM]: {
    NO_OFFSET_SCALE: false,
    HIDE_ALARM_RANGES: false,
    HIDE_UNITS_OF_MEASUREMENT: false,
    VALUE_TYPE: VALUE_TYPE.FLOAT
  },
  [FEATURE.RANGE]: {
    NO_OFFSET_SCALE: false,
    HIDE_ALARM_RANGES: true,
    HIDE_UNITS_OF_MEASUREMENT: false,
    VALUE_TYPE: VALUE_TYPE.FLOAT
  },
  [FEATURE.LINEAR_FORECAST]: {
    NO_OFFSET_SCALE: false,
    HIDE_ALARM_RANGES: false,
    HIDE_UNITS_OF_MEASUREMENT: false,
    VALUE_TYPE: VALUE_TYPE.FLOAT,
    REQUIRES_WINDOW: true
  },
  [FEATURE.SKEWNESS]: {
    NO_OFFSET_SCALE: true,
    HIDE_ALARM_RANGES: true,
    HIDE_UNITS_OF_MEASUREMENT: true,
    VALUE_TYPE: VALUE_TYPE.FLOAT
  },
  [FEATURE.KURTOSIS]: {
    NO_OFFSET_SCALE: true,
    HIDE_ALARM_RANGES: true,
    HIDE_UNITS_OF_MEASUREMENT: true,
    VALUE_TYPE: VALUE_TYPE.FLOAT
  },
  [FEATURE.IMPULSE]: {
    NO_OFFSET_SCALE: true,
    HIDE_ALARM_RANGES: true,
    HIDE_UNITS_OF_MEASUREMENT: true,
    VALUE_TYPE: VALUE_TYPE.FLOAT
  },
  [FEATURE.DIFFERENCE_FROM_MEAN]: {
    NO_OFFSET_SCALE: false,
    HIDE_ALARM_RANGES: true,
    HIDE_UNITS_OF_MEASUREMENT: false,
    VALUE_TYPE: VALUE_TYPE.FLOAT
  },
  [FEATURE.NUMBER_OF_STATE_CHANGES]: {
    NO_OFFSET_SCALE: true,
    HIDE_ALARM_RANGES: true,
    HIDE_UNITS_OF_MEASUREMENT: true,
    VALUE_TYPE: VALUE_TYPE.ENUMERATION,
    OUTPUT_VALUE_TYPE: VALUE_TYPE.FLOAT
  },
  [FEATURE.DEVIATION_FROM]: {
    REQUIRES_INPUT: true,
    VALUE_TYPE: VALUE_TYPE.FLOAT
  },
  [FEATURE.RANGE_OF_DEVIATION_FROM]: {
    REQUIRES_INPUT: true,
    VALUE_TYPE: VALUE_TYPE.FLOAT
  },
  [FEATURE.DIVIDED_BY]: {
    REQUIRES_INPUT: true,
    VALUE_TYPE: VALUE_TYPE.FLOAT,
    // eslint-disable-next-line
    UNITS_OF_MEASUREMENT_TEMPLATE: '${inputUnitsOfMeasurement}/${featureInputUnitsOfMeasurement}'
  },
  [FEATURE.DISTINCT_STATES]: {
    NO_OFFSET_SCALE: false,
    HIDE_ALARM_RANGES: false,
    HIDE_UNITS_OF_MEASUREMENT: false,
    VALUE_TYPE: VALUE_TYPE.ENUMERATION,
    OUTPUT_VALUE_TYPE: `list.${VALUE_TYPE.ENUMERATION}`
  }
};

export enum INPUT_TYPE {
  INPUT = 0,
  HTML_TEMPLATE = 1,
  TEXT_AREA = 2,
  XML = 3,
  SELECT = 4,
  CONDITIONS = 5,
  ENUMERATION_AND_STATE = 6,
  UNIT_GEO_SHAPE = 7,
  GEO_SHAPE = 8,
  NUMBER = 9,
  DATE_TIME = 10,
  GEO_POINT = 11,
  ENUMERATION_STATE = 12,
  SELECT_ENTITY = 13,
  LIST = 14
};

const features = _.chain(FEATURE)
  .values()
  .map(id => ({
    id,
    key: id,
    messageId: `feature.${id}`,
    requiresInput: FEATURE_SETTINGS[id].REQUIRES_INPUT,
    requiresWindow: FEATURE_SETTINGS[id].REQUIRES_WINDOW,
    valueType: FEATURE_SETTINGS[id].VALUE_TYPE,
    outputValueType: FEATURE_SETTINGS[id].OUTPUT_VALUE_TYPE || FEATURE_SETTINGS[id].VALUE_TYPE
  }))
  .value();
export const SELECT_LISTS = {
  windowSizes: _.map(quickRanges, id => ({
    id,
    messageId: messages[`quick.${id}`].id
  })),
  features,
  simpleFeatures: _.filter(features, feature => !_.startsWith(feature.outputValueType, 'list.')),
  properties: _.chain(PROPERTY)
    .values()
    .map(id => ({
      id,
      messageId: `property.${id}`
    }))
    .value(),
  severities: [
    {
      id: '0',
      messageId: 'alarmRangeInput.severity.low',
      icon: {
        name: 'circle',
        style: {
          color: COLOUR.GREEN
        }
      }
    },
    {
      id: '1',
      messageId: 'alarmRangeInput.severity.med',
      icon: {
        name: 'circle',
        style: {
          color: COLOUR.ORANGE
        }
      }
    },
    {
      id: '2',
      messageId: 'alarmRangeInput.severity.high',
      icon: {
        name: 'circle',
        color: 'red',
        style: {
          color: COLOUR.RED
        }
      }
    }
  ],
  valueTypes: _.chain(VALUE_TYPE)
    .values()
    .map(id => ({
      id,
      requiresEnumeration: id === VALUE_TYPE.ENUMERATION,
      requiresUnitsOfMeasurement: id === VALUE_TYPE.FLOAT,
      messageId: `valueType.${id}`
    }))
    .value(),
  stringInputTypes: [
    {
      id: INPUT_TYPE.INPUT,
      messageId: 'inputType.input'
    },
    {
      id: INPUT_TYPE.TEXT_AREA,
      messageId: 'inputType.textArea'
    }
  ],
  phalanxWorkTypes: _.chain(PHALANX_WORK_TYPE)
    .values()
    .map(id => ({
      id,
      messageId: `phalanxWorkType.${id}`
    }))
    .value(),
  icons: _.chain(iconMessages)
    .keys()
    .map(icon => ({
      id: icon,
      messageId: `icon.${icon}`,
      icon
    }))
    .value(),
  colours: _.chain(colourMessages)
    .keys()
    .map(colour => ({
      id: colour,
      messageId: `colour.${colour}`,
      icon: {
        name: 'circle',
        color: colour
      }
    }))
    .value()
};

export enum OPTION_TYPE {
  LITERAL = 0,
  ATTRIBUTE = 1,
  LITERAL_OR_ATTRIBUTE = 2
};

export enum VALIDATION_OPTIONS {
  NOT_EMPTY = 0,
  IS_EMAIL = 1,
  IS_URL = 2,
  IS_INTEGER = 4,
  IS_HTML = 8,
  IS_NUMBER = 16,
  IS_DECISION_MODEL = 32,
  IS_WINDOW_DURATION = 64,
  NOT_REQUIRED = 128,
  IS_DATE = 256
};

export const TYPE = 'type';

export const CREATE_TYPE_ID = 'create';

export enum ENTITY_FORM_ELEMENT_TYPE {
  DATE_RANGE = 'dateRange',
  INPUT = 'input',
  SELECT_ENTITY = 'selectEntity',
  TYPES = 'types',
  SELECT = 'select',
  RADIO = 'radio',
  SEARCH = 'search',
  CONDITIONS = 'conditions',
  TEXT_AREA = 'textarea',
  MAPPING = 'mapping',
  LABELS = 'labels',
  LIST = 'list'
};

export const TYPED_LIST_ELEMENT_TYPE = {
  SELECT_ENTITY: ENTITY_FORM_ELEMENT_TYPE.SELECT_ENTITY
};

export const INPUT_TYPE_FORM_ELEMENT = {
  [INPUT_TYPE.SELECT]: ENTITY_FORM_ELEMENT_TYPE.SELECT,
  [INPUT_TYPE.SELECT_ENTITY]: ENTITY_FORM_ELEMENT_TYPE.SELECT_ENTITY
};

export const ADD_NEW_MODEL_VALUE = 'addNew';

export enum FORM_SEARCH_RESULT {
  SETTINGS = 'settings',
  RESULTS = 'results',
  IS_LOADING = 'isLoading'
};

export enum SEARCH_SCHEMA {
  NAME = 'name'
};

export const INPUT_TYPE_VALUE_TYPE = {
  [INPUT_TYPE.INPUT]: VALUE_TYPE.STRING,
  [INPUT_TYPE.NUMBER]: VALUE_TYPE.FLOAT,
  [INPUT_TYPE.DATE_TIME]: VALUE_TYPE.DATE,
  [INPUT_TYPE.GEO_POINT]: VALUE_TYPE.GEO_POINT,
  [INPUT_TYPE.ENUMERATION_STATE]: VALUE_TYPE.ENUMERATION
};

export enum LITERAL_OR_ATTRIBUTE {
  LITERAL = 'literal',
  ATTRIBUTE = 'attribute'
};

export enum ATTRIBUTE_SOURCE {
  UNIT_TYPE_ATTRIBUTE = 1,
  SENSOR = 2,
  MODEL_INPUT = 4,
  MODEL_OUTPUT = 8,
  UNIT_TYPE_PARAMETER = 16,
  TREND_ATTRIBUTE = 32
};

export const ATTRIBUTE_SOURCE_ITEM_PROPS = {
  [ATTRIBUTE_SOURCE.UNIT_TYPE_ATTRIBUTE]: {
    icon: 'cubes'
  },
  [ATTRIBUTE_SOURCE.SENSOR]: {
    icon: 'thermometer half'
  },
  [ATTRIBUTE_SOURCE.MODEL_OUTPUT]: {
    icon: 'lightbulb outline'
  },
  [ATTRIBUTE_SOURCE.TREND_ATTRIBUTE]: {
    icon: 'chart line'
  },
  [ATTRIBUTE_SOURCE.UNIT_TYPE_PARAMETER]: {
    icon: 'plus square'
  }
};

export const NO_ATTRIBUTE_ID = 'unusedAxisLabel';

export const LINE_CHART_YAXIS_WIDTH = 30;

export enum SENSOR_TYPE {
  PREDICTOR_OUTPUT = 'predictorOutput'
};

export enum SENSOR_MAPPING_MODE {
  MANUAL = 'manual',
  TEMPLATE = 'template'
};

export enum SELECT_CONTEXT_LIST {
  ATTRIBUTES = 'attributes',
  INPUTS = 'inputs',
  OUTPUTS = 'outputs'
}