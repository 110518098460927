import _ from 'lodash';
import { Fragment } from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { Icon } from 'semantic-ui-react';
import { UnitCategory } from 'types';
import * as fromState from '../../../state/reducers';
import { ENTITY_PROPERTY, ENTITY_TYPE } from '../../constants';

const mapStateToProps = createSelector(
  [
    (state, { id }) => id,
    state => fromState.getAll<UnitCategory>(state, ENTITY_TYPE.UNIT_CATEGORY)
  ],
  (
    id,
    allUnitCategories
  ) => ({
    id,
    name: _.get(allUnitCategories[id], ENTITY_PROPERTY.UNIT_CATEGORY.NAME),
    icon: _.get(allUnitCategories[id], ENTITY_PROPERTY.UNIT_CATEGORY.ICON)
  })
);

interface Props extends UnitCategory {
  intl: InjectedIntl;
}
const UnitCategoryText = injectIntl(({
  id,
  name,
  icon,
  intl: {
    formatMessage
  }
}: Props) => (
  <Fragment>
    <Icon name={icon} />{formatMessage({
      id: `unit.type.${id}.name`,
      defaultMessage: name
    })}
  </Fragment>
));
UnitCategoryText.displayName = 'UnitCategoryText';

export default connect(mapStateToProps)(UnitCategoryText);