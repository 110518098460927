import { COLOUR } from '../../../utils/constants';

export default {
  wrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  previousWindowButton: {
    border: 'none'
  },
  windowSizePopup: {
    background: COLOUR.MEDIUM_GREY,
    border: '1px solid white'
  },
  windowJumpSizePopup: {
    background: COLOUR.MEDIUM_GREY,
    border: '1px solid white',
    padding: '0px'
  },
  quickRangeButton: {
    width: '140px'
  },
  dateTimeButtonGroup: {
    marginLeft: '20px',
    height: '35.88px'
  },
  dateButton: {
    width: '180px',
    display: 'flex',
    alignItems: 'center',
    paddingRight: 0
  },
  timeButton: {
    paddingRight: 0,
    width: '135px',
    display: 'flex',
    alignItems: 'center'
  },
  dateTimeTextWrapperDiv: {
    display: 'inline-block'
  },
  dateText: {
    marginLeft: '0',
    fontWeight: 400,
    fontSize: '14px'
  },
  dateTimeCaretIcon: {
    marginLeft: '1.5em',
    marginRight: 0,
    display: 'inline'
  },
  datePopup: {
    padding: 0,
    background: COLOUR.MEDIUM_GREY,
    border: 'none'
  },
  timePopup: {
    padding: 0,
    background: COLOUR.MEDIUM_GREY,
    border: '1px solid white'
  },
  timePicker: {
    color: COLOUR.TEXT,
    background: COLOUR.MEDIUM_GREY,
    minWidth: '16em'
  },
  modeButtonGroup: {
    marginLeft: '20px'
  },
  modeLabel: withDetail => ({
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    margin: 0,
    paddingTop: 0,
    paddingBottom: 0,
    fontWeight: 600,
    textAlign: withDetail
      ? 'left'
      : 'center',
    lineHeight: withDetail
      ? '15px'
      : '32px',
    fontSize: '0.8rem'
  }),
  modeLabelDetail: {
    display: 'block',
    marginLeft: 0
  },
  playbackSpeedPopup: {
    background: COLOUR.MEDIUM_GREY,
    border: '1px solid white'
  },
  nextWindowButton: {
    border: 'none',
    borderRadius: 0,
    borderTopRightRadius: '0.25rem',
    borderBottomRightRadius: '0.25rem'
  },
  regionButton: {
    padding: '5px'
  },
  regionPopup: {
    background: COLOUR.DARK_GREY,
    border: '1px solid white'
  },
  trendButton: {
    width: '150px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    cursor: 'default',
    pointerEvents: 'none'
  },
  progressLabel: {
    margin: 0,
    borderRadius: 0,
    paddingTop: 0,
    paddingBottom: 0,
    fontWeight: 600,
    textAlign: 'center',
    lineHeight: '32px',
    width: '60px'
  },
  regionButtonGroup: {
    width: '100%'
  },
  clearRegionButton: {
    border: 0,
    marginTop: '1em',
    textAlign: 'center'
  }
};