import _ from 'lodash';
import { defineMessages } from 'react-intl';
import uuid from 'uuid/v1';
import EntityText from '../../../components/entity/EntityText';
import { ENTITY_FORM_ELEMENT_TYPE, ENTITY_TYPE, TYPE, PAGE_URL, DATE_FORMAT, DASHBOARD_VIEW, SELECT_CONTEXT_LIST } from '../../constants';
import selectContextListsSelector from './selectContextListsSelector';
import optionsSelector from './optionsSelector';
import textComponent from './textComponent';
import { toPath } from '../../helpers';
import itemPageComponent from './itemPageComponent';
import moment from 'moment';

const messages = defineMessages({
  'columns.name.title': {
    id: 'predictor.columns.name.title',
    defaultMessage: 'Name'
  },
  'columns.inputs.title': {
    id: 'predictor.columns.inputs.title',
    defaultMessage: 'Inputs'
  },
  'columns.outputs.title': {
    id: 'predictor.columns.outputs.title',
    defaultMessage: 'Outputs'
  },
  'form.name.label': {
    id: 'predictor.form.name.label',
    defaultMessage: 'Name'
  },
  'form.name.placeholder': {
    id: 'predictor.form.name.placeholder',
    defaultMessage: 'Name'
  },
  'form.category.label': {
    id: 'predictor.form.category.label',
    defaultMessage: 'Type'
  },
  'form.category.option.sensorGroup': {
    id: 'predictor.form.category.option.sensorGroup',
    defaultMessage: 'Sensor Group'
  },
  'form.mode.label': {
    id: 'predictor.form.mode.label',
    defaultMessage: 'Mode'
  },
  'form.mode.option.live': {
    id: 'predictor.form.mode.option.live',
    defaultMessage: 'Live'
  },
  'form.mode.option.historical': {
    id: 'predictor.form.mode.option.historical',
    defaultMessage: 'Historical'
  },
  'types.label': {
    id: 'predictor.form.types.label',
    defaultMessage: 'Model'
  },
  'types.placeholder': {
    id: 'predictor.form.types.placeholder',
    defaultMessage: 'Model'
  },
  'form.category.option.predictor': {
    id: 'predictor.form.category.option.predictor',
    defaultMessage: 'Predictor'
  },
  'form.functionMappings.label': {
    id: 'predictor.form.functionMappings.label',
    defaultMessage: 'Sensors'
  },
  'form.functionMappings.placeholder': {
    id: 'predictor.form.functionMappings.placeholder',
    defaultMessage: 'Sensors'
  },
  'form.additionalFunctionMappings.label': {
    id: 'predictor.form.additionalFunctionMappings.label',
    defaultMessage: 'Sensors'
  },
  'form.additionalFunctionMappings.placeholder': {
    id: 'predictor.form.additionalFunctionMappings.placeholder',
    defaultMessage: 'Sensors'
  },
  'dashboardCard.footerLabel.iconButton.rerun.popupText': {
    id: 'predictor.dashboardCard.footerLabel.iconButton.rerun.popupText',
    defaultMessage: 'Re-run'
  },
  'deckHeader.predictors': {
    id: 'predictor.deckHeader.predictors',
    defaultMessage: 'Predictors'
  },
  'deckHeader.predictorInstances': {
    id: 'predictor.deckHeader.predictorInstances',
    defaultMessage: 'Predictor Instances'
  }
});

const CATEGORY = {
  PREDICTOR: 'predictor',
  SENSOR_GROUP: 'sensorGroup'
};

const MODE = {
  LIVE: 'live',
  HISTORICAL: 'historical'
};

export default {
  apiPath: 'predictor',
  pageUrl: 'models',
  parentEntityType: ENTITY_TYPE.MODEL,
  icon: 'lightbulb outline',
  disableFetchTypes: true,
  fetchAllOnLoad: true,
  navigateOnAdd: true,
  navigateOnClick: true,
  parentProperty: 'unitId',
  messages,
  typeEntityType: ENTITY_TYPE.MODEL,
  optionsSelector,
  textComponent,
  selectContextListsSelector,
  itemPageComponent,
  itemPageUrl: PAGE_URL.PREDICTOR_CONFIGURATION,
  itemUrl: (entity, unitPaths) => toPath(
    PAGE_URL.PREDICTOR,
    {
      unitPath: unitPaths[entity.unitId],
      groupId: entity.id
    }
  ),
  parentSearchFilter: parentId => ({
    Type: {
      $eq: parentId
    }
  }),
  formValueSearchSchema: [{
    name: TYPE,
    entityType: ENTITY_TYPE.MODEL
  }],
  fetchByIdSchema: [{
    name: TYPE,
    entityType: ENTITY_TYPE.MODEL
  }],
  columns: [
    {
      name: 'name',
      width: 3,
      render: ({ name }) => name
    },
    {
      name: 'model',
      width: 3,
      render: ({ type }) => (
        <EntityText
          id={type}
          entityType={ENTITY_TYPE.MODEL} />
      )
    }
  ],
  typeInheritedFields: ['name'],
  transformAfterTypeCreation: (entity, type, selectContextLists) => ({
    ...entity,
    settings: _.mapValues(
      type.options,
      (optionSettings, key) => _.chain(selectContextLists.inputs)
        .find(['value', key])
        .get('id')
        .value()
    )
  }),
  form: [
    {
      name: 'name',
      type: ENTITY_FORM_ELEMENT_TYPE.INPUT
    },
    {
      name: 'category',
      type: 'radio',
      options: [CATEGORY.PREDICTOR, CATEGORY.SENSOR_GROUP],
      disableOnEdit: true
    },
    {
      name: 'mode',
      type: 'radio',
      options: [MODE.LIVE, MODE.HISTORICAL],
      disableOnEdit: true,
      filter: values => values.category !== CATEGORY.SENSOR_GROUP
    },
    {
      name: 'dateRange',
      type: ENTITY_FORM_ELEMENT_TYPE.DATE_RANGE,
      errorPaths: ['from', 'to'],
      fromErrorPath: 'from',
      toErrorPath: 'to',
      filter: values => values.category !== CATEGORY.SENSOR_GROUP
        && values.mode === MODE.HISTORICAL
    },
    {
      type: ENTITY_FORM_ELEMENT_TYPE.TYPES,
      search: true,
      entityType: ENTITY_TYPE.MODEL,
      create: true,
      hideCreateFields: ['name'],
      filter: values => values.category !== CATEGORY.SENSOR_GROUP
    },
    {
      name: 'functionMappings',
      type: ENTITY_FORM_ELEMENT_TYPE.SELECT,
      selectContextListName: SELECT_CONTEXT_LIST.ATTRIBUTES,
      multiple: true,
      search: true,
      includeOptionData: true,
      filter: values => values.category === CATEGORY.SENSOR_GROUP
    }
  ],
  createEntity: values => ({
    id: uuid(),
    name: values.name,
    unitId: values.unitId,
    type: values.category === CATEGORY.SENSOR_GROUP
      ? null
      : values.type || null,
    settings: values.category === CATEGORY.SENSOR_GROUP
      ? {}
      : values.settings || {},
    functionMappings: values.category === CATEGORY.SENSOR_GROUP
      ? _.map(
        values.functionMappings || [],
        optionData => ({
          sensor: optionData.value,
          function: optionData.description
        })
      )
      : null,
    from: moment.utc(_.get(values, 'dateRange.from'), DATE_FORMAT).toISOString(),
    to: moment.utc(_.get(values, 'dateRange.to'), DATE_FORMAT).toISOString()
  }),
  populateForm: entity => ({
    name: entity.name,
    category: entity.type
      ? CATEGORY.PREDICTOR
      : CATEGORY.SENSOR_GROUP,
    type: entity.type,
    settings: entity.settings,
    functionMappings: _.map(
      entity.functionMappings,
      functionMapping => functionMapping.sensor
    ),
    dateRange: {
      from: entity.from && moment.utc(entity.from).format(DATE_FORMAT),
      to: entity.to && moment.utc(entity.to).format(DATE_FORMAT)
    },
    mode: entity.from || entity.to
      ? MODE.HISTORICAL
      : MODE.LIVE
  }),
  deckHeaderMessage: (dashboardView, isTopLevel) => dashboardView === DASHBOARD_VIEW.UNITS
    ? messages['deckHeader.predictors']
    : messages['deckHeader.predictorInstances']
};