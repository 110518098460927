import { SET_SELECTED_FEATURE } from '../../../../actions/chart';
import { FEATURE } from '../../../../../utils/constants';

export default (state = FEATURE.LATEST_FLOAT, action) => {
  switch (action.type) {
    case SET_SELECTED_FEATURE:
      return action.payload.value;
    default:
      return state;
  };
};

export const getSelectedFeature = state => state;