import { SET_CHART_IS_LOADING } from '../../actions/chart';

export default namespace => (state = false, action) => {
  if (action.namespace !== namespace) {
    return state;
  }
  
  switch (action.type) {
    case SET_CHART_IS_LOADING:
      return action.payload.loading;
    default:
      return state;
  };
};

export const getIsLoading = state => state;