import _ from 'lodash';
import { ENTITY_PROPERTY } from '../../../utils/constants';
import { APPEND_MULTIPLE_RANGES, PREPEND_MULTIPLE_RANGES, SET_MULTIPLE_RANGES } from '../../actions/data';

export default (state = {}, action) => {
  switch (action.type) {
    case SET_MULTIPLE_RANGES:
      return {
        ...state,
        ...action.payload.sensors
      };
    case APPEND_MULTIPLE_RANGES:
      return {
        ...state,
        ..._.mapValues(action.payload.sensors, (values, sensor) => _.dropWhile([
          ...state[sensor],
          ..._.differenceBy(values, state[sensor], ENTITY_PROPERTY.SENSOR_VALUE.TIMESTAMP)
        ], ([timestamp]) => timestamp < action.payload.dropBeforeTimestamp))
      };
    case PREPEND_MULTIPLE_RANGES:
      return {
        ...state,
        ..._.mapValues(action.payload.sensors, (values, sensor) => _.dropRightWhile([
          ..._.differenceBy(values, state[sensor], ENTITY_PROPERTY.SENSOR_VALUE.TIMESTAMP),
          ...state[sensor]
        ], ([timestamp]) => timestamp > action.payload.dropAfterTimestamp))
      };
    default:
      return state;
  }
};

export const getAllSensorRanges = state => state;