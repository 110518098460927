import { SetItemSelectedAction, SetSelectedAction, SET_ITEM_SELECTED, SET_SELECTED } from '../../../actions/chart';
import _ from 'lodash';
import { SELECTION_TYPE } from 'utils/constants';

function hasType<T>(action, type): action is T {
  return action.type === type;
}

export default (selectionType: SELECTION_TYPE) => (state: string[] = [], action: SetItemSelectedAction | SetSelectedAction) => {
  if (action.selectionType !== selectionType) {
    return state;
  }

  if (hasType<SetItemSelectedAction>(action, SET_ITEM_SELECTED)) {
    return action.payload.selected ? _.union(state, [action.payload.id]) : _.without(state, action.payload.id);
  }
  else if (hasType<SetSelectedAction>(action, SET_SELECTED)) {
    return action.payload.list;
  }
  else {
    return state;
  }
};

export const getSelected = (state: string[]) => state;