import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { addCondition, formSearchChanged, formValueChanged, removeCondition, setConditionFilter, updateCondition } from '../../state/actions/entity';
import EntityForm from '../../components/entity/EntityForm';
import * as fromState from '../../state/reducers';
import { ENTITY_FORM_TYPE, ENTITY_CONFIG, ENTITY_PROPERTY, ENTITY_FORM_MODE } from '../../utils/constants';
import createCachedSelector from 're-reselect';
import { transformEntityTypeSelectors } from '../../utils/helpers';
import entityConfig from '../../utils/entities';

const getForm = createCachedSelector(
  [
    (state, { form, entityType }) => form || entityConfig[entityType].form,
    (state, { hideFields }) => hideFields
  ],
  (form, hideFields) => _.filter(form, element => !element.name || !_.includes(hideFields, element.name))
)(
  (state, { entityType }) => entityType
);

const getSelectContextLists = createCachedSelector(
  [
    (state, { selectContextLists }) => selectContextLists,
    (state, { parentId, entityType }) => entityConfig[entityType].selectContextListsSelector
      ? entityConfig[entityType].selectContextListsSelector(state, { parentId })
      : null
  ],
  (selectContextLists, selectedSelectContextLists) => ({
    ...selectedSelectContextLists,
    ...selectContextLists
  })
)(
  (state, { entityType }) => entityType
);

const getLabels = createCachedSelector(
  [
    ...transformEntityTypeSelectors([
      fromState.getLabels
    ])
  ],
  labels => _.map(labels, ENTITY_PROPERTY.LABEL.NAME)
)(
  (state, { entityType }) => entityType
);

const getErrorPathPrefixes = createCachedSelector(
  [
    ...transformEntityTypeSelectors([
      (state, entityType) => entityConfig[entityType][ENTITY_CONFIG.TYPE_ENTITY_TYPE]
    ]),
    getForm
  ],
  (
    typeEntityType,
    form
  ) => {
    const errorPathPrefixes = _.flatten([
      ..._.map(
        form,
        element => element.errorPathPrefix || element.errorPaths || element.errorPath || element.name
      ),
      'type',
      'settings'
    ]);
    const typeErrorPathPrefixes = typeEntityType
      ? _.flatten([
        ..._.map(
          entityConfig[typeEntityType].form,
          element => element.errorPathPrefix || element.errorPaths || element.errorPath || element.name
        ),
        'type',
        'settings'
      ])
      : [];
    return {
      errorPathPrefixes,
      typeErrorPathPrefixes
    };
  }
)(
  (state, { entityType }) => entityType
);

const getTypeErrorFields = createCachedSelector(
  [
    ...transformEntityTypeSelectors([
      (state, entityType) => entityConfig[entityType][ENTITY_CONFIG.TYPE_ENTITY_TYPE]
    ]),
    state => state.entities
  ],
  (
    typeEntityType,
    entities
  ) => {
    const state = { entities };
    return typeEntityType
      ? fromState.getErrorFields(state, typeEntityType)
      : null;
  }
)(
  (state, { entityType }) => entityType
);

const getErrorCodes = createCachedSelector(
  [
    (state, { mode }) => mode,
    (state, { entityType }) => entityType,
    state => state.entities,
    getErrorPathPrefixes,
    getTypeErrorFields
  ],
  (
    mode,
    entityType,
    entities,
    {
      errorPathPrefixes
    }
  ) => {
    const state = { entities };
    return mode === ENTITY_FORM_MODE.ADD
      ? fromState.getAddErrorCodes(state, entityType, errorPathPrefixes)
      : fromState.getEditErrorCodes(state, entityType, errorPathPrefixes);
  }
)(
  (state, { entityType }) => entityType
);

const getTypeErrorCodes = createCachedSelector(
  [
    ...transformEntityTypeSelectors([
      (state, entityType) => entityConfig[entityType][ENTITY_CONFIG.TYPE_ENTITY_TYPE]
    ]),
    (state, { mode }) => mode,
    state => state.entities,
    getErrorPathPrefixes
  ],
  (
    typeEntityType,
    mode,
    entities,
    {
      typeErrorPathPrefixes
    }
  ) => {
    const state = { entities };
    return typeEntityType
      ? mode === ENTITY_FORM_MODE.ADD
        ? fromState.getAddErrorCodes(state, typeEntityType, typeErrorPathPrefixes)
        : fromState.getEditErrorCodes(state, typeEntityType, typeErrorPathPrefixes)
      : null;
  }
)(
  (state, { entityType }) => entityType
);

const mapStateToProps = createCachedSelector(
  [
    (state, { entityType }) => entityType,
    (state, { entityType }) => fromState.getFormValues(state, entityType, ENTITY_FORM_TYPE.ADD_OR_EDIT),
    (state, { entityType }) => fromState.getConditionTypes(state, entityType),
    (state, { entityType }) => entityConfig[entityType][ENTITY_CONFIG.MESSAGES],
    (state, { entityType }) => fromState.getAllFormSearchResults(state, entityType),
    (state, { entityType }) => fromState.getAddErrorResponse(state, entityType),
    (state, { entityType }) => fromState.getEditErrorResponse(state, entityType),
    (state, { entityType }) => fromState.getErrorFields(state, entityType),
    getForm,
    (state, { mode }) => mode,
    (state, { parentId }) => parentId,
    getSelectContextLists,
    getLabels,
    getTypeErrorFields,
    getErrorCodes,
    getTypeErrorCodes
  ],
  (
    entityType,
    formValues,
    conditionTypes,
    entityMessages,
    allFormSearchResults,
    addErrorResponse,
    editErrorResponse,
    errorFields,
    form,
    mode,
    parentId,
    selectContextLists,
    labels,
    typeErrorFields,
    errorCodes,
    typeErrorCodes
  ) => ({
    entityType,
    form,
    entityMessages,
    labels,
    formSearchResults: allFormSearchResults,
    errorResponse: mode === ENTITY_FORM_MODE.ADD
      ? addErrorResponse
      : editErrorResponse,
    formValues,
    conditionTypes,
    selectContextLists,
    parentId,
    errorFields: [
      ...errorFields,
      ...typeErrorFields || []
    ],
    errorCodes: [
      ...errorCodes,
      ...typeErrorCodes || []
    ]
  })
)(
  (state, { entityType }) => entityType
);

const mapDispatchToProps = (dispatch, { entityType }) => {
  return bindActionCreators({
    ..._.mapValues({
      onUpdateCondition: updateCondition,
      onRemoveCondition: removeCondition,
      onAddCondition: addCondition,
      onSetConditionFilter: setConditionFilter,
      onFormSearchChanged: formSearchChanged,
      onFormValueChanged: formValueChanged
    }, actionCreator => _.partial(actionCreator, entityType, ENTITY_FORM_TYPE.ADD_OR_EDIT))
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(EntityForm);