import _ from 'lodash';
import createCachedSelector from 're-reselect';
import { COLOUR, ENTITY_PROPERTY, VALUE_TYPE } from '../../../utils/constants';
import { alarmRangesToLevels, getColourFromSeverity, getValueAlarmRangeSeverity } from '../../../utils/helpers';
import getXDomain from './getXDomain';

export default (
  bucketedDataSelector,
  propertiesSelector,
  descriptionsSelector,
  alarmRangesSelector,
  enumerationsSelector,
  getScale
) => createCachedSelector(
  [
    bucketedDataSelector,
    propertiesSelector,
    descriptionsSelector,
    alarmRangesSelector,
    getXDomain,
    enumerationsSelector
  ],
  (
    allBucketedData,
    allProperties,
    allDescriptions,
    allAlarmRanges,
    xDomain,
    allEnumerations
  ) => {
    return _.mapValues(allBucketedData, (bucketedData, key) => {
      const header = allDescriptions[key] || _.get(allProperties[key], ENTITY_PROPERTY.SENSOR.NAME) || key;
      const scale = getScale(allProperties[key]);
      const alarmLevels = alarmRangesToLevels(
        allAlarmRanges[key],
        allEnumerations[key]
      );
      const unitsOfMeasurement = _.get(allProperties[key], ENTITY_PROPERTY.SENSOR.UNITS_OF_MEASUREMENT);
      return _.map(bucketedData, (bucketGroup, index) => {
        const values = _.map(bucketGroup, ENTITY_PROPERTY.SENSOR_VALUE.VALUE);
        const valueType = _.get(allProperties[key], ENTITY_PROPERTY.SENSOR.VALUE_TYPE);
        const min = valueType === VALUE_TYPE.FLOAT
          ? _.min(values)
          : null;
        const max = valueType === VALUE_TYPE.FLOAT
          ? _.max(values)
          : null;
        return {
          header,
          subheader: xDomain[index],
          id: key,
          min,
          max,
          scale,
          alarmRanges: allAlarmRanges[key],
          alarmLevels,
          noData: _.isEmpty(values),
          valueCount: _.size(values),
          singleValue: _.size(values) === 1
            ? allEnumerations[key]
              ? allEnumerations[key].states[_.first(values)]
              : _.first(values)
            : null,
          activeStates: valueType === VALUE_TYPE.ENUMERATION
            ? _.uniq(values)
            : null,
          valueType,
          unitsOfMeasurement,
          colour: getColourFromSeverity(
            _.isEmpty(values)
              ? null
              : _.isEmpty(allAlarmRanges[key])
                ? -1
                : valueType === VALUE_TYPE.FLOAT
                  ? _.chain(allAlarmRanges[key])
                    .filter((range, i) => ((i === _.size(allAlarmRanges[key]) - 1 || min <= range.max) && (i === 0 || max >= range.min)))
                    .map('severity')
                    .max()
                    .value()
                  : _.chain(values)
                    .uniq()
                    .map(_.partial(getValueAlarmRangeSeverity, _, allAlarmRanges[key]))
                    .max()
                    .value(),
            {
              defaultColour: COLOUR.LIGHT_GREY
            }
          )
        };
      });
    });
  }
)(
  (state, chartType) => chartType
);