import _ from 'lodash';
import createCachedSelector from 're-reselect';
import { SENSOR_VALUE_DATE_FORMAT } from '../../../utils/constants';
import { consecutivePairs } from '../../../utils/helpers';
import getBuckets from './getBuckets';

export const findBucketBoundaryIndices = (buckets, data) => {
  return consecutivePairs([
    ..._.map(buckets, bucket => {
      const startTimestamp = parseInt(bucket.start.format(SENSOR_VALUE_DATE_FORMAT));
      return _.sortedIndexBy(
        data,
        [startTimestamp],
        ([timestamp]) => timestamp
      );
    }),
    _.size(data)
  ]);
};

export default dataSelector => createCachedSelector(
  [
    dataSelector,
    getBuckets
  ],
  (data, buckets) => _.mapValues(
    data,
    values => _.map(
      findBucketBoundaryIndices(buckets, values),
      ([startIndex, endIndex]) => (values || []).slice(startIndex, endIndex)
    )
  )
)(
  (state, chartType) => chartType
);