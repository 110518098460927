import { ofType } from 'redux-observable';
import { map, filter, withLatestFrom } from 'rxjs/operators';
import { ADD_FULFILLED, toggleModal } from '../../actions/entity';
import * as fromState from '../../reducers';
import { ENTITY_CONFIG, ENTITY_MODAL } from '../../../utils/constants';
import entityConfig from '../../../utils/entities';

export default (action$, state$) => action$.pipe(
  ofType(ADD_FULFILLED),
  withLatestFrom(state$),
  filter(([action]) =>
    (!entityConfig[action.entityType][ENTITY_CONFIG.NAVIGATE_ON_ADD]
      && !entityConfig[action.entityType][ENTITY_CONFIG.OPEN_ON_ADD])
    || entityConfig[action.entityType][ENTITY_CONFIG.IS_CONNECTOR]),
  filter(([action, state]) => fromState.getModalOpen(state, action.entityType, ENTITY_MODAL.ADD)),
  map(([action]) => toggleModal(action.entityType, ENTITY_MODAL.ADD))
);