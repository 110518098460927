import loadDashboardPageSensors from './loadDashboardPageSensors';
import loadPredictors from './loadPredictors';
import saveMutedUntil from './saveMutedUntil';
import changeUnitPathOnNavigate from './changeUnitPathOnNavigate';
import loadSensorsOnChangeUnit from './loadSensorsOnChangeUnit';
import loadTrendsOnChangeUnit from './loadTrendsOnChangeUnit';
import { combineEpicsWithNames } from '../../../utils/helpers';

export default combineEpicsWithNames({
  loadDashboardPageSensors,
  loadPredictors,
  saveMutedUntil,
  changeUnitPathOnNavigate,
  loadSensorsOnChangeUnit,
  loadTrendsOnChangeUnit
});