import { defineMessages } from 'react-intl';
import { EntityConfig, UnitType } from 'types';
import uuid from 'uuid/v1';
import EntityText from '../../../components/entity/EntityText';
import { ENTITY_FORM_ELEMENT_TYPE, ENTITY_TYPE, DASHBOARD_VIEW, ROLE } from '../../constants';
import textComponent from './textComponent';

const messages = defineMessages({
  'title': {
    id: 'unitType.title',
    defaultMessage: 'Unit Types'
  },
  'columns.name.title': {
    id: 'unitType.columns.name.title',
    defaultMessage: 'Name'
  },
  'columns.description.title': {
    id: 'unitType.columns.description.title',
    defaultMessage: 'Description'
  },
  'columns.baseType.title': {
    id: 'unitType.columns.baseType.title',
    defaultMessage: 'Base Type'
  },
  'columns.category.title': {
    id: 'unitType.columns.category.title',
    defaultMessage: 'Category'
  },
  'form.name.label': {
    id: 'unitType.form.name.label',
    defaultMessage: 'Name'
  },
  'form.name.placeholder': {
    id: 'unitType.form.name.placeholder',
    defaultMessage: 'Name'
  },
  'form.description.label': {
    id: 'unitType.form.description.label',
    defaultMessage: 'Description'
  },
  'form.description.placeholder': {
    id: 'unitType.form.description.placeholder',
    defaultMessage: 'Description'
  },
  'form.baseType.label': {
    id: 'unitType.form.baseType.label',
    defaultMessage: 'Base Type'
  },
  'form.baseType.placeholder': {
    id: 'unitType.form.baseType.placeholder',
    defaultMessage: 'Base Type'
  },
  'form.parentType.label': {
    id: 'unitType.form.parentType.label',
    defaultMessage: 'Category'
  },
  'form.parentType.placeholder': {
    id: 'unitType.form.parentType.placeholder',
    defaultMessage: 'Category'
  },
  'additionalImport.unitTypeInstance.button.text': {
    id: 'unitType.additionalImport.unitTypeInstance.button.text',
    defaultMessage: 'Import Instances'
  },
  'additionalImport.unitTypePredictor.button.text': {
    id: 'unitType.additionalImport.unitTypePredictor.button.text',
    defaultMessage: 'Import Predictors'
  },
  'deckHeader.unitTypesOfUnit': {
    id: 'unitType.deckHeader.unitTypesOfUnit',
    defaultMessage: 'Types'
  },
  'deckHeader.unitTypes': {
    id: 'unitType.deckHeader.unitTypes',
    defaultMessage: 'Unit Types'
  },
  'deckHeader.childUnitTypes': {
    id: 'unitType.deckHeader.childUnitTypes',
    defaultMessage: 'Child Unit Types'
  }
});

const config: EntityConfig<UnitType> = {
  apiPath: 'unittype',
  pageUrl: 'unittypes',
  icon: 'cubes',
  disableFetchTypes: true,
  fetchAllOnLoad: true,
  navigateOnAdd: false,
  navigateOnClick: true,
  parentProperty: 'baseType',
  viewRole: ROLE.VIEW_UNIT_TYPE,
  modifyRole: ROLE.MODIFY_UNIT_TYPE,
  import: true,
  additionalImports: [
    {
      entityType: ENTITY_TYPE.UNIT_TYPE_INSTANCE,
      message: messages['additionalImport.unitTypeInstance.button.text']
    },
    {
      entityType: ENTITY_TYPE.UNIT_TYPE_PREDICTOR,
      message: messages['additionalImport.unitTypePredictor.button.text']
    }
  ],
  messages,
  textComponent,
  columns: [
    {
      name: 'name',
      width: 3,
      render: ({ name }) => name
    },
    {
      name: 'description',
      width: 3,
      render: ({ description }) => description
    },
    {
      name: 'baseType',
      width: 3,
      render: ({ baseType }) => (
        <EntityText
          id={baseType}
          entityType={ENTITY_TYPE.UNIT_TYPE} />
      )
    },
    {
      name: 'category',
      width: 3,
      render: ({ parentType }) => (
        <EntityText
          id={parentType}
          entityType={ENTITY_TYPE.UNIT_CATEGORY} />
      )
    }
  ],
  formValueSearchSchema: [{
    name: 'baseType',
    entityType: ENTITY_TYPE.UNIT_TYPE
  }],
  fetchByIdSchema: [{
    name: 'baseType',
    entityType: ENTITY_TYPE.UNIT_TYPE
  }],
  form: [
    {
      name: 'name',
      type: ENTITY_FORM_ELEMENT_TYPE.INPUT
    },
    {
      name: 'description',
      type: ENTITY_FORM_ELEMENT_TYPE.INPUT
    },
    {
      name: 'parentType',
      type: ENTITY_FORM_ELEMENT_TYPE.SELECT_ENTITY,
      entityType: ENTITY_TYPE.UNIT_CATEGORY
    },
    {
      name: 'baseType',
      type: ENTITY_FORM_ELEMENT_TYPE.SEARCH,
      searchEntityType: 'unitType',
      transformResult: unitType => ({
        key: unitType.id,
        text: unitType.name,
        value: unitType.id
      })
    }
  ],
  createEntity: values => ({
    id: uuid(),
    name: values.name,
    description: values.description,
    parentType: values.parentType,
    baseType: values.baseType || null
  }),
  populateForm: entity => ({
    name: entity.name,
    description: entity.description,
    parentType: entity.parentType,
    baseType: entity.baseType
  }),
  deckHeaderMessage: (dashboardView, isTopLevel) => dashboardView === DASHBOARD_VIEW.UNITS
    ? messages['deckHeader.unitTypesOfUnit']
    : (isTopLevel ? messages['deckHeader.unitTypes'] : messages['deckHeader.childUnitTypes'])
};

export default config;