import { useState, useEffect } from 'react';
import { FEATURE, NO_ATTRIBUTE_ID } from 'utils/constants';
import moment from 'moment';
import FeatureCalculator from 'state/selectors/chart/getChartData/FeatureCalculator';
import * as api from 'utils/api';
import { useSelector } from 'react-redux';
import { getUser } from 'state/reducers';
import _ from 'lodash';

const CACHE_SIZE = 5;

const defaultChartSpec = () => ({
  data: {
    x: []
  },
  layout: {
    scene: {
      xaxis: ''
    }
  }
});

// Returns a human-readable axis title
const generateAxisTitle = (attributeName, featureName, windowSize) => {
  if (featureName === FEATURE.LATEST_FLOAT) {
    return attributeName;
  }

  return `${attributeName} [${featureName}] (${windowSize})`;
};

export default function useExplorerControls() {
  const token = useSelector(getUser).token;

  // Inputs
  const [attrs, setAttrs] = useState(new Array(3).fill(NO_ATTRIBUTE_ID));
  const [features, setFeatures] = useState(new Array(3).fill(FEATURE.LATEST_FLOAT));
  const [windows, setWindows] = useState(new Array(3).fill('PT1H'));
  const [filterState, setFilterState] = useState({
    startDate: moment(),
    endDate: moment(),
    filterAttr: NO_ATTRIBUTE_ID,
    filterValue: ''
  });
  const [colorAttr, setColorAttr] = useState(NO_ATTRIBUTE_ID);
  const [colorMode, setColorMode] = useState('default');
  const [opacity, setOpacity] = useState(1);
  const [markerSize, setMarkerSize] = useState(2);

  // Outputs
  const [sensors, setSensors] = useState([]);
  const [statusMessage, setStatusMessage] = useState('statusBar.ready');
  const [statusColor, setStatusColor] = useState('green');
  const [chartSpec, setChartSpec] = useState(defaultChartSpec());

  // Internals
  const [rawDataCache, setRawDataCache] = useState([]);
  const [subscription, setSubscription] = useState();

  useEffect(() => {
    api.getAll({
      controller: 'sensor',
      token
    }).subscribe({
      next(x) {
        // Get sensor options on first loading menu. 
        setSensors(x);

        // Default axes to first three sensors returned;
        let newAttrs = [...attrs];
        for (let i = 0; i < 3; i++) {
          if (x.length > i) {
            newAttrs[i] = x[i].id;
          }
        }
        setAttrs(newAttrs);
      }
    });
  }, []);

  // Changing the axes or filters should refresh the scatter plot
  useEffect(() => {
    fetchData(rawDataCache);
  }, [attrs, features, windows, colorAttr, colorMode]);

  // If the filter was changed, clear the cache and reload data
  useEffect(() => {
    setRawDataCache([]);
    fetchData([]);
  }, [filterState]);

  // If style is changed, update chart without loads or calculations
  useEffect(() => {
    chartSpec.data.marker.opacity = opacity;
    chartSpec.data.marker.size = markerSize;
  }, [opacity, markerSize]);

  function fetchData(cache) {
    if (filterState.startDate >= filterState.endDate) {
      return;
    }

    // Get the list of attributes whose data is required. This includes data for all active axes,
    // optionally another attribute to be displayed as colour, and optionally a filter attribute.
    let attrsToLoad = [];
    attrs.forEach(attr => {
      if (attr !== NO_ATTRIBUTE_ID) {
        attrsToLoad.push(attr);
      }
    });
    if (colorMode === 'sensor' && colorAttr !== null && colorAttr !== NO_ATTRIBUTE_ID) {
      attrsToLoad.push(colorAttr);
    }
    if (filterState.filterAttr !== NO_ATTRIBUTE_ID) {
      attrsToLoad.push(filterState.filterAttr);
    }
    attrsToLoad = _.uniq(attrsToLoad);

    // For each requested attribute:
    // If the raw data is already cached, use it.
    // Otherwise, add the attribute to the list to fetch from the API.
    let cachedData = {};
    let fetchAttrs = [];
    attrsToLoad.forEach(id => {
      let d = loadFromCache(cache, id);
      if (d === null) {
        fetchAttrs.push(id);
      } else {
        cachedData[id] = d;
      }
    });

    if (fetchAttrs.length == 0) {
      updateChart(cachedData);
      return;
    }

    // Fetch the non-cached data from the API.
    // Once fetched, merge with the cached data and pass to the converter.
    let data = api.getSensorsWindow({
      ids: fetchAttrs,
      fromDate: filterState.startDate,
      toDate: filterState.endDate,
      token
    });

    if (subscription != null) {
      subscription.unsubscribe();
    }

    setStatusColor('blue');
    setStatusMessage('statusBar.fetchingData');

    let sub = data.subscribe({
      next(x) {
        updateChart({
          ...cachedData,
          ...x['sensors']
        });
        cacheRawData(x['sensors']);
        setStatusColor('green');
        setStatusMessage('statusBar.ready');
      },
      error(err) {
        setStatusColor('red');
        setStatusMessage('statusBar.fetchDataError');
      },
      complete() {
        if (subscription != null) {
          subscription.unsubscribe();
        }
      }
    });

    setSubscription(sub);
  }

  function loadFromCache(cache, attr) {
    for (let i = 0; i < cache.length; i++) {
      if (cache[i].attr === attr) {
        return cache[i].data;
      }
    }

    return null;
  }

  // If data is not already cached for the given attribute, add it to the cache and
  // remove the oldest cached data.
  function cacheRawData(rawData) {
    let newCache = [...rawDataCache];

    for (const [attr, data] of Object.entries(rawData)) {
      for (let i = 0; i < newCache.length; i++) {
        if (newCache[i].attr === attr) {
          return;
        }
      }

      newCache.push({
        attr: attr,
        data: data
      });
      if (newCache.length > CACHE_SIZE) {
        newCache.shift();
      }
    }

    setRawDataCache(newCache);
  }

  function updateChart(rawData) {
    let axisTitles = [];
    for (let i = 0; i < 3; i++) {
      axisTitles.push(generateAxisTitle(attrs[i], features[i], windows[i]));
    }

    let rawFeatureData = [];
    for (let i = 0; i < 3; i++) {
      if (attrs[i] === NO_ATTRIBUTE_ID) continue;

      let sensorValues = rawData[attrs[i]];
      let windowSeconds = moment.duration(windows[i]).as('seconds');
      let startTimestamp = filterState.startDate.unix() + windowSeconds;

      let data = new FeatureCalculator('latest', startTimestamp, windowSeconds).calculate(sensorValues);
      const axisData = data.map(d => [d[0], d[1][features[i]]]);

      rawFeatureData[axisTitles[i]] = axisData;
    }

    let [timestamps, attrData] = dropPointsWithMissingDimension(rawFeatureData);

    if (filterState.filterAttr !== NO_ATTRIBUTE_ID) {
      [timestamps, attrData] = applyMask(timestamps, attrData, rawData[filterState.filterAttr], filterState.filterValue);
    }

    _.pull(axisTitles, NO_ATTRIBUTE_ID);

    let data = {};
    let layout = {};
    data.customData = timestamps;

    let scene = {};
    if (axisTitles.length > 0) {
      const xTitle = axisTitles[0];
      data.x = attrData[xTitle];
      scene.xaxis = {
        title: {
          text: xTitle
        }
      };
    }
    if (axisTitles.length > 1) {
      const yTitle = axisTitles[1];
      data.y = attrData[yTitle];
      scene.yaxis = {
        title: {
          text: yTitle
        }
      };
    }
    if (axisTitles.length > 2) {
      const zTitle = axisTitles[2];
      data.z = attrData[zTitle];
      scene.zaxis = {
        title: {
          text: zTitle
        }
      };
    }
    layout.scene = scene;

    data.marker = {
      opacity: opacity,
      size: markerSize
    };

    if (colorMode === 'sensor') {
      data.marker.color = attrData[colorAttr];
    }

    setChartSpec({
      data: data,
      layout: layout
    });
  }

  return [
    attrs, setAttrs,
    features, setFeatures,
    windows, setWindows,
    filterState, setFilterState,
    colorAttr, setColorAttr,
    colorMode, setColorMode,
    opacity, setOpacity,
    markerSize, setMarkerSize,
    sensors,
    statusMessage,
    statusColor,
    chartSpec
  ];
}

// The data is returned from the API as an object containing a list of 
// (time, value) pairs for each requested feature.
// The time values may not match up, so we have to drop any entries whose 
// time is absent from any list. We assume that the pairs in each list are ordered by time.
function dropPointsWithMissingDimension(data) {
  let timestamps = [];
  let attrData = {};

  let attrs = Object.keys(data);
  const nSensors = attrs.length;

  attrs.forEach(attr => { attrData[attr] = []; });

  // Keep track of the current location in each sensor's array
  let positions = new Array(nSensors + 1).fill(0);

  for (let iTime = 0; iTime < data[attrs[0]].length; iTime++) {
    let pointData = [...data[attrs[0]][iTime]];

    // Determine whether there is a value for this time stamp in the other sensor arrays
    let [timestamp] = pointData;
    for (let jSensor = 1; jSensor < nSensors; jSensor++) {
      let sensorData = data[attrs[jSensor]];
      while (positions[jSensor] < sensorData.length) {
        let [t, v] = sensorData[positions[jSensor]];

        if (t < timestamp) {
          positions[jSensor]++;
        } else if (t === timestamp) {
          pointData.push(v);
          positions[jSensor]++;
          break;
        } else if (t > timestamp) {
          break;
        }
      }
    }

    let entryExistsForAllSensors = pointData.length === nSensors + 1;
    if (entryExistsForAllSensors) {
      timestamps.push(pointData[0]);
      for (let i = 0; i < nSensors; i++) {
        attrData[attrs[i]].push(pointData[i + 1]);
      }
    }
  }

  return [timestamps, attrData];
}

function applyMask(timestamps, attrData, maskPairs, maskValue) {
  // First create a flat mask array whose entries correspond to the timestamps.
  // If there is no entry in the mask data for a given timestamp, assume the value is False.
  let j = 0;
  let maskArr = [];
  for (let i = 0; i < timestamps.length; i++) {
    let targetTimestamp = timestamps[i];
    while (j < maskPairs.length) {
      let [t, v] = maskPairs[j];
      if (t < targetTimestamp) {
        j++;
      } else if (t === targetTimestamp) {
        maskArr.push(v === maskValue);
        j++;
        break;
      } else if (t > targetTimestamp) {
        maskArr.push(false);
        break;
      }
    }
  }

  // Now keep only the timestamps and correpsonding attribute values for which the mask is false
  let timestampsOut = _.filter(timestamps, (_, i) => !maskArr[i]);
  let attrDataOut = _.mapValues(attrData, arr => _.filter(arr, (_, i) => !maskArr[i]));

  return [timestampsOut, attrDataOut];
}
