export default {
  wrapperDiv: {
    textAlign: 'left'
  },
  labelButton: {
    border: 'none'
  },
  diagramButton: {
    padding: 0,
    border: 'none'
  }
};