import { CopyToClipboard } from 'react-copy-to-clipboard';
import { defineMessages, injectIntl } from 'react-intl';
import { Popup } from 'semantic-ui-react';
import { COLOUR, INVERTED_THEME } from '../../utils/constants';
import PopupButton from '../shared/PopupButton';

const messages = defineMessages({
  'copied': {
    id: 'copyButton.copied',
    defaultMessage: 'Copied!'
  },
  'copy': {
    id: 'copyButton.copy',
    defaultMessage: 'Copy {text}'
  }
});

const CopyButton = injectIntl(({
  text,
  copiedText,
  onCopy,
  chartType,
  intl: {
    formatMessage
  },
  ...props
}) => (
  <Popup
    inverted={INVERTED_THEME}
    open={copiedText === text}
    content={formatMessage(messages['copied'])}
    style={{
      background: COLOUR.MEDIUM_GREY,
      border: '1px solid white'
    }}
    trigger={(
      <CopyToClipboard text={text}>
        <PopupButton
          popupText={formatMessage(messages['copy'], { text })}
          popupOpen={copiedText !== text ? undefined : false}
          icon='copy'
          size='mini'
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            onCopy(text);
          }}
          {...props} />
      </CopyToClipboard>
    )} />
));

CopyButton.displayName = 'CopyButton';

export default CopyButton;