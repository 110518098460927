import { SET_SHOW_PASSWORD } from '../../../actions/auth';

export default (state = false, action) => {
  switch (action.type) {
    case SET_SHOW_PASSWORD:
      return action.payload.value;
    default:
      return state;
  }
};

export const getShowPassword = state => state;