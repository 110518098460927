import { ofType } from 'redux-observable';
import { filter, map, withLatestFrom } from 'rxjs/operators';
import { TOGGLE_MODAL } from '../../actions/entity';
import * as fromState from '../../reducers';
import { toPath } from '../../../utils/helpers';
import { PAGE_URL, ENTITY_CONFIG, ENTITY_MODAL } from '../../../utils/constants';
import { querySyncPush } from '../../actions/navigation';
import entityConfig from '../../../utils/entities';

export default (action$, state$) => action$.pipe(
  ofType(TOGGLE_MODAL),
  withLatestFrom(state$),
  filter(([action, state]) =>
    entityConfig[action.entityType][ENTITY_CONFIG.IS_CONNECTOR]
    && entityConfig[action.entityType][ENTITY_CONFIG.NAVIGATE_ON_ADD]
    && action.payload.modal === ENTITY_MODAL.ACCESS_TOKEN
    && !fromState.getModalOpen(state, action.entityType, ENTITY_MODAL.ACCESS_TOKEN)),
  map(([action, state]) => querySyncPush(toPath(PAGE_URL.ENTITY, {
    pageUrl: entityConfig[action.entityType][ENTITY_CONFIG.PAGE_URL],
    entityId: action.payload.id
  })))
);