import messages from './ColoursMenu.messages';
import { Form } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import { INVERTED_THEME } from 'utils/constants';

export default injectIntl(function ColoursMenu({
  colourMode,
  onSetColourMode,
  sensors,
  colourAttr,
  onSetColourAttr,
  opacity,
  onSetOpacity,
  markerSize,
  onSetMarkerSize,
  intl: {
    formatMessage
  }
}) {
  const modeOptions = ['default', 'time'].map(id => ({
    key: id,
    text: formatMessage(messages[id]),
    value: id
  }));

  return (
    <div>
      <Form inverted={INVERTED_THEME}>
        <Form.Select
          label={formatMessage(messages['colourMode'])}
          value={colourMode}
          options={modeOptions}
          onChange={(_, data) => onSetColourMode(data.value)} />
        {/* { colourMode === "sensor" &&
          <Form.Select
            label='Sensor'
            value={colourAttr}
            options={sensors}
            onChange={ (_, data) => onSetColourAttr(data.value) }
          />
        } */}
        <Form.Input
          label={formatMessage(messages['opacity'])}
          type='number'
          max='1'
          min='0'
          step='0.1'
          value={opacity}
          onChange={(_, data) => onSetOpacity(data.value)} />
        <Form.Input
          label={formatMessage(messages['markerSize'])}
          type='number'
          max='10'
          min='1'
          step='1'
          value={markerSize}
          onChange={(_, data) => onSetMarkerSize(data.value)} />
      </Form>
    </div>
  );
});