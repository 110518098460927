import settings, * as fromSettings from './settings';
import conditions, * as fromConditions from './conditions';
import values, * as fromValues from './values';
import { combineReducers } from 'redux';

const formValuesReducer = combineReducers({
  settings,
  conditions,
  values
});

export default formType => (state = {}, action) => {
  if (action.formType && action.formType !== formType) {
    return state;
  }

  return formValuesReducer(state, action);
};

export const getFormValue = (state, key) => fromValues.getValue(state.values, key);
export const getFormValues = state => fromValues.getValues(state.values);
export const getSettings = state => fromSettings.getSettings(state.settings);
export const getSetting = (state, key) => fromSettings.getSetting(state.settings, key);
export const getConditions = state => fromConditions.getConditions(state.conditions);
export const getConditionsFilter = state => fromConditions.getFilter(state.conditions);
export const getConditionsList = state => fromConditions.getList(state.conditions);