import { defineMessages } from 'react-intl';
import { createSelector } from 'reselect';
import uuid from 'uuid/v1';
import { getUnitTypeId } from '../../../state/selectors/dashboard';
import { addUnitTypeAttribute } from '../../../state/actions/entity';
import { ENTITY_PROPERTY, ENTITY_TYPE, ENTITY_FORM_TYPE, ENTITY_FORM_ELEMENT_TYPE, DASHBOARD_VIEW, SENSOR_MAPPING_MODE, ROLE } from 'utils/constants';
import _ from 'lodash';
import * as fromState from '../../../state/reducers';
import optionsSelector from './optionsSelector';
import { EntityConfig, Unit, UnitTypeAttribute, UnitTypeInstance } from 'types';

const getSensorMappingElementProps = createSelector(
  [
    state => fromState.getAll<Unit>(state, ENTITY_TYPE.UNIT),
    state => fromState.getAll<UnitTypeInstance>(state, ENTITY_TYPE.UNIT_TYPE_INSTANCE),
    state => getUnitTypeId(state),
    state => fromState.getFormValue(
      state,
      ENTITY_TYPE.UNIT_TYPE_ATTRIBUTE,
      ENTITY_FORM_TYPE.ADD_OR_EDIT,
      ENTITY_PROPERTY.UNIT_TYPE_ATTRIBUTE.VALUE_TYPE
    )
  ],
  (allUnits, allUnitTypeInstances, unitTypeId, valueType) => {
    const unitTypeInstances = _.filter(allUnitTypeInstances, uti => uti.type === unitTypeId);
    const keyNames = _.map(unitTypeInstances, uti => {
      const unit = _.find(allUnits, unit => unit.id === uti.unitId);
      return unit ? unit.name : undefined;
    });

    return {
      keyIds: _.map(unitTypeInstances, 'id'),
      keyNames: keyNames,
      getErrorPath: index => `editedInstances[${index}]`,
      valueElementType: ENTITY_FORM_ELEMENT_TYPE.SELECT_ENTITY,
      valueElementProps: {
        entityType: ENTITY_TYPE.SENSOR,
        valueType: valueType
      }
    };
  }
);

const messages = defineMessages({
  'deckHeader.attributes': {
    id: 'unitTypeAttribute.deckHeader.attributes',
    defaultMessage: 'Attributes'
  },
  'form.name.label': {
    id: 'unitTypeAttribute.form.name.label',
    defaultMessage: 'Name'
  },
  'form.name.placeholder': {
    id: 'unitTypeAttribute.form.name.placeholder',
    defaultMessage: 'Name'
  },
  'form.description.label': {
    id: 'unitTypeAttribute.form.description.label',
    defaultMessage: 'Description'
  },
  'form.description.placeholder': {
    id: 'unitTypeAttribute.form.description.placeholder',
    defaultMessage: 'Description'
  },
  'form.valueType.label': {
    id: 'unitTypeAttribute.form.valueType.label',
    defaultMessage: 'Value Type'
  },
  'form.valueType.placeholder': {
    id: 'unitTypeAttribute.form.valueType.placeholder',
    defaultMessage: 'Value Type'
  },
  'form.unitType.label': {
    id: 'unitTypeAttribute.form.unitType.label',
    defaultMessage: 'Unit Type'
  },
  'form.sensorMappings.label': {
    id: 'unitTypeAttribute.form.sensorMappings.label',
    defaultMessage: 'For each existing instance, select the sensor that measures this attribute:'
  },
  'form.sensorMappingMode.label': {
    id: 'unitTypeAttribute.form.sensorMappingTemplate.label',
    defaultMessage: 'Sensor Mapping Mode'
  },
  'form.sensorMappingMode.option.manual': {
    id: 'unitTypeAttribute.form.sensorMappingMode.option.manual',
    defaultMessage: 'Manual'
  },
  'form.sensorMappingMode.option.template': {
    id: 'unitTypeAttribute.form.sensorMappingMode.option.template',
    defaultMessage: 'Template'
  },
  'form.sensorMappingTemplate.label': {
    id: 'unitTypeAttribute.form.sensorMappingTemplate.label',
    defaultMessage: 'Provide a template for the sensor IDs'
  },
  'form.sensorMappingTemplate.placeholder': {
    id: 'unitTypeAttribute.form.sensorMappingTemplate.placeholder',
    defaultMessage: 'Example: \\{name\\}_AttributeName'
  }
});

const config: EntityConfig<UnitTypeAttribute> = {
  apiPath: 'unitTypeAttribute',
  parentProperty: 'unitType',
  parentEntityType: ENTITY_TYPE.UNIT_TYPE,
  disableFetchTypes: true,
  fetchAllOnLoad: true,
  optionsSelector,
  messages,
  viewRole: ROLE.VIEW_UNIT_TYPE,
  modifyRole: ROLE.MODIFY_UNIT_TYPE,
  form: [
    {
      name: 'name',
      type: ENTITY_FORM_ELEMENT_TYPE.INPUT,
      errorPathPrefix: 'attribute.name'
    },
    {
      name: 'description',
      type: ENTITY_FORM_ELEMENT_TYPE.INPUT,
      errorPathPrefix: 'attribute.description'
    },
    {
      name: 'valueType',
      type: ENTITY_FORM_ELEMENT_TYPE.SELECT,
      selectListName: 'valueTypes',
      errorPathPrefix: 'attribute.valueType'
    },
    {
      name: 'unitType',
      type: ENTITY_FORM_ELEMENT_TYPE.SEARCH,
      searchEntityType: ENTITY_TYPE.UNIT_TYPE,
      errorPathPrefix: 'attribute.unitType',
      transformResults: unitType => ({
        key: unitType.id,
        text: unitType.name,
        description: unitType.description,
        value: unitType.id
      })
    },
    {
      name: 'sensorMappingMode',
      type: ENTITY_FORM_ELEMENT_TYPE.RADIO,
      filter: values => !!values.valueType,
      options: [SENSOR_MAPPING_MODE.MANUAL, SENSOR_MAPPING_MODE.TEMPLATE]
    },
    {
      name: 'sensorMappings',
      type: ENTITY_FORM_ELEMENT_TYPE.MAPPING,
      filter: values => !!values.valueType && values.sensorMappingMode !== SENSOR_MAPPING_MODE.TEMPLATE,
      errorPathPrefix: 'editedInstances',
      getComponentProps: getSensorMappingElementProps
    },
    {
      name: 'sensorMappingTemplate',
      type: ENTITY_FORM_ELEMENT_TYPE.INPUT,
      filter: values => !!values.valueType && values.sensorMappingMode === SENSOR_MAPPING_MODE.TEMPLATE,
      errorPathPrefix: 'template'
    }
  ],
  deckHeaderMessage: (dashboardView, isTopLevel) => dashboardView === DASHBOARD_VIEW.UNIT_TYPES
    ? messages['deckHeader.attributes']
    : null,
  createAction: (values, parent) => addUnitTypeAttribute(
    {
      id: uuid(),
      name: values.name,
      description: values.description,
      valueType: values.valueType,
      unitType: parent.id
    },
    values.sensorMappingMode,
    values.sensorMappings,
    values.sensorMappingTemplate
  )
};

export default config;