import _ from 'lodash';
import { Fragment } from 'react';
import { Map, Marker, Polyline, CircleMarker, TileLayer, Polygon, Tooltip } from 'react-leaflet';
import { Segment } from 'semantic-ui-react';
import { COLOUR, INVERTED_THEME } from '../../../utils/constants';
import styles from './MapChart.styles';

const LINE_COLOUR = COLOUR.BLUE;
const POLYGON_COLOUR = COLOUR.GREEN;
const POINT_FILL_OPACITY = 1;
const POINT_RADIUS = 4;
const TILE_LAYER_URL = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
const TILE_LAYER_ATTRIBUTION = '&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors';
const DEFAULT_CENTER = [0, 0];
const DEFAULT_ZOOM = 1;

const MapChart = ({
  lines,
  polygons,
  bounds,
  isLoading
}) => (
  <Segment
    inverted={INVERTED_THEME}
    loading={isLoading}
    style={styles.segment}>
    {!isLoading && (
      <Map
        bounds={bounds}
        center={bounds
          ? undefined
          : DEFAULT_CENTER}
        zoom={bounds
          ? undefined
          : DEFAULT_ZOOM}
        style={styles.map}>
        <TileLayer
          url={TILE_LAYER_URL}
          attribution={TILE_LAYER_ATTRIBUTION}
          crossOrigin />
        {_.map(lines, line => (
          <Fragment key={line.name}>
            <Polyline
              positions={_.map(line.points, point => point.position)}
              color={LINE_COLOUR} />
            {_.map(_.initial(line.points), point => (
              <CircleMarker
                key={point.timestamp}
                center={point.position}
                radius={POINT_RADIUS}
                color={LINE_COLOUR}
                fillOpacity={POINT_FILL_OPACITY}>
                <Tooltip>
                  <strong>{line.name}</strong>
                  <br />
                  {point.timestamp}
                </Tooltip>
              </CircleMarker>
            ))}
            <Marker position={_.last(line.points).position}>
              <Tooltip>
                <strong>{line.name}</strong>
                <br />
                {_.last(line.points).timestamp}
              </Tooltip>
            </Marker>
          </Fragment>
        ))}
        {_.map(polygons, polygon => (
          <Polygon
            key={polygon.name}
            positions={polygon.positions}
            color={POLYGON_COLOUR}>
            <Tooltip>
              <strong>{polygon.name}</strong>
              <br />
              {polygon.description}
            </Tooltip>
          </Polygon>
        ))}
      </Map>
    )}
  </Segment>
);

MapChart.displayName = 'MapChart';

export default MapChart;