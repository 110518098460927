import { EDIT, EDIT_FULFILLED, EDIT_ERROR } from '../../actions/entity';

export default (state = false, action) => {
  switch (action.type) {
    case EDIT:
      return true;
    case EDIT_FULFILLED:
    case EDIT_ERROR:
      return false;
    default:
      return state;
  }
};

export const getIsEditing = state => state;