import { FORM_VALUE_CHANGED, FORM_SEARCH_CHANGED, FETCH_TYPES_FULFILLED, SET_FORM_VALUES } from '../../../actions/entity';
import _ from 'lodash';

export default (state = {}, action) => {
  switch(action.type) {
    case FORM_VALUE_CHANGED:
    case FORM_SEARCH_CHANGED:
      return {
        ...state,
        [action.payload.key]: action.payload.value
      };
    case FETCH_TYPES_FULFILLED:
      return {
        ...state,
        type: _.size(action.payload.types) === 1 ? action.payload.types[0].id : state.type
      };
    case SET_FORM_VALUES:
      return _.omit(action.payload.formValues, ['settings', 'conditions']);
    default:
      return state;
  }
};

export const getValues = state => state;
export const getValue = (state, key) => state[key];