import { ofType } from 'redux-observable';
import { filter, map, withLatestFrom } from 'rxjs/operators';
import { setQuickRange, SET_QUICK_RANGE } from '../../actions/chart';
import { CHART_TYPE } from '../../../utils/constants';
import * as fromState from '../../reducers';

export default (action$, state$) => action$.pipe(
  ofType(SET_QUICK_RANGE),
  withLatestFrom(state$),
  filter(([action, state]) => action.chartType === CHART_TYPE.MAIN && fromState.getCompareTrend(state)),
  map(([action]) => setQuickRange(CHART_TYPE.COMPARE, action.payload.value))
);