import { PLAYBACK_SPEED, PLAYBACK_MODE } from '../../../../utils/constants';
import { SET_PLAYBACK_SPEED, SET_PLAYBACK_MODE, JUMP_TO_LIVE_PLAYBACK } from '../../../actions/playback';

export default (state: number = PLAYBACK_SPEED.X1, action) => {
  switch (action.type) {
    case SET_PLAYBACK_SPEED:
      return action.payload.speed;
    case SET_PLAYBACK_MODE:
      return action.payload.mode === PLAYBACK_MODE.LIVE ? PLAYBACK_SPEED.X1 : state;
    case JUMP_TO_LIVE_PLAYBACK:
      return PLAYBACK_SPEED.X1;
    default:
      return state;
  }
};

export const getSpeed = (state: number) => state;