import { defineMessages } from 'react-intl';
import ActionStateCellContainer from '../../containers/chart/ActionStateCellContainer';

const messages = defineMessages({
  'columns.connector.title': {
    id: 'action.columns.connector.title',
    defaultMessage: 'Connector'
  },
  'columns.name.title': {
    id: 'action.columns.name.title',
    defaultMessage: 'Name'
  },
  'columns.state.title': {
    id: 'action.columns.state.title',
    defaultMessage: 'State'
  }
});

export default {
  apiPath: 'action',
  messages,
  noActions: true,
  columns: [
    {
      name: 'name',
      width: 3,
      render: ({ actionMapping }) => actionMapping
    },
    {
      name: 'connector',
      width: 3,
      render: ({ connector }) => connector
    },
    {
      name: 'state',
      width: 3,
      render: action => (
        <ActionStateCellContainer action={action} />
      )
    }
  ]
};