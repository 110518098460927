import dateRange, * as fromDateRange from './dateRange';
import trend, * as fromTrend from './trend';
import copiedText, * as fromCopiedText from './copiedText';
import editingFunction, * as fromEditingFunction from './editingFunction';
import functionDescriptionEditText, * as fromFunctionDescriptionEditText from './functionDescriptionEditText';
import selected, * as fromSelected from './selected';
import group, * as fromGroup from './group';
import featuresToggle, * as fromFeaturesToggle from './featuresToggle';
import selectedFeature, * as fromSelectedFeature from './selectedFeature';
import featureWindow, * as fromFeatureWindow from './featureWindow';
import selectedProperty, * as fromSelectedProperty from './selectedProperty';
import { combineReducers } from 'redux';
import { RootState } from 'index';
import { HoverLineState } from './trend/hoverLine';
import { CHART_TYPE, SELECTION_TYPE } from 'utils/constants';

const sharedChartReducer = combineReducers({
  dateRange,
  trend,
  copiedText,
  editingFunction,
  functionDescriptionEditText,
  group,
  selected: combineReducers({
    input: selected(SELECTION_TYPE.INPUT),
    output: selected(SELECTION_TYPE.OUTPUT),
    function: selected(SELECTION_TYPE.FUNCTION)
  }),
  featuresToggle,
  selectedFeature,
  featureWindow,
  selectedProperty
});

export default (chartType: CHART_TYPE) => (state: any = {}, action) => {
  if (action.chartType && action.chartType !== chartType) {
    return state;
  }

  return sharedChartReducer(state, action);
};

export const getSelected = (state, selectionType) => fromSelected.getSelected(state.selected[selectionType]);
export const getQuickRange = (state: RootState): string => fromDateRange.getQuickRange(state.dateRange);
export const getFromDate = (state: RootState): string => fromDateRange.getFromDate(state.dateRange);
export const getToDate = (state: RootState): string => fromDateRange.getToDate(state.dateRange);
export const getGroup = state => fromGroup.getGroup(state.group);
export const getHoverLine = (state: RootState): HoverLineState => fromTrend.getHoverLine(state.trend);
export const getBrushStartTimestamp = state => fromTrend.getBrushStartTimestamp(state.trend);
export const getBrushEndTimestamp = state => fromTrend.getBrushEndTimestamp(state.trend);
export const getCopiedText = state => fromCopiedText.getCopiedText(state.copiedText);
export const getEditingFunction = state => fromEditingFunction.getEditingFunction(state.editingFunction);
export const getFunctionDescriptionEditText = state => fromFunctionDescriptionEditText.getFunctionDescriptionEditText(state.functionDescriptionEditText);
export const getFeaturesToggle = state => fromFeaturesToggle.getFeaturesToggle(state.featuresToggle);
export const getSelectedFeature = state => fromSelectedFeature.getSelectedFeature(state.selectedFeature);
export const getFeatureWindow = state => fromFeatureWindow.getFeatureWindow(state.featureWindow);
export const getSelectedProperty = state => fromSelectedProperty.getSelectedProperty(state.selectedProperty);