import _ from 'lodash';
import createCachedSelector from 're-reselect';
import * as fromState from '../../../reducers';
import { getFunctionSensorIds } from '../getFunctionSensorIds';
import moment from 'moment';
import { DATE_FORMAT } from '../../../../utils/constants';

export const getFunctionData = createCachedSelector(
  [
    getFunctionSensorIds,
    fromState.getAllSensorRanges,
    fromState.getFromDate
  ],
  (ids, allSensorRanges, fromDate) => {
    const fromTimestamp = moment.utc(fromDate, DATE_FORMAT).format('X');
    return _.chain(ids)
      .map(id => [id, _.dropWhile(allSensorRanges[id], ([timestamp]) => timestamp < fromTimestamp)])
      .fromPairs()
      .value();
  }
)(
  (state, chartType) => chartType
);