import _ from 'lodash';
import { createSelector } from 'reselect';
import * as fromState from '../../../reducers';

const getAllTrends = state => fromState.getAll(state, 'trend');

export const getTrends = createSelector(
  [fromState.getTrendIds, getAllTrends],
  (trendIds, allTrends) => {
    return _.map(trendIds, id => allTrends[id]);
  }
);