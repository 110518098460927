import _ from 'lodash';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import PredictorBreadcrumbSection from '../../components/navigation/PredictorBreadcrumbSection';
import { formValueChanged, toggleModal } from '../../state/actions/entity';
import * as fromState from '../../state/reducers';
import * as dashboardSelectors from '../../state/selectors/dashboard';
import {
  CHART_TYPE, ENTITY_MODAL, ENTITY_TYPE, PAGE_URL, SELECTION_TYPE, ENTITY_PROPERTY,
  NO_DATA_ICON, SEVERITY_ICON, ENTITY_FORM_TYPE, PLAYBACK_MODE, SEVERITY_COLOURS_BRIGHT,
  NEUTRAL_SEVERITY
} from '../../utils/constants';
import { toPath, getSemanticColourFromSeverity } from '../../utils/helpers';
import { bindActionCreators } from 'redux';
import { compose, withHandlers } from 'recompose';
import { getUnitId } from '../../state/selectors/dashboard';
import entityConfig from '../../utils/entities';
import { querySyncPush } from '../../state/actions/navigation';
import { getInputSensors } from '../../state/selectors/chart';

const getPredictorOptions = createSelector([
  state => dashboardSelectors.getCards(state),
  state => fromState.getAll(state, ENTITY_TYPE.MODEL),
  state => fromState.getAllLatestSensorValues(state, CHART_TYPE.MAIN),
  state => fromState.getAllLatestOutputValues(state, CHART_TYPE.MAIN),
  state => fromState.getAll(state, ENTITY_TYPE.SENSOR)
], (
  cards,
  allModels,
  allLatestSensorValues,
  allLatestOutputValues,
  allSensors
) => _.chain(_.concat(
  cards.predictors || [],
  cards.sensorGroups || []
))
  .groupBy(predictor => {
    const model = allModels[predictor.type];
    return model
      ? `model.type.${model.type}.name`
      : 'model.type.sensorGroup.name';
  })
  .mapValues(cards => _.chain(cards)
    .map(predictor => {
      const severity = dashboardSelectors.getSeverity(predictor, allLatestSensorValues, allLatestOutputValues, allSensors);
      return {
        key: predictor.id,
        value: predictor.id,
        text: predictor.name,
        icon: {
          name: SEVERITY_ICON[severity] || NO_DATA_ICON,
          color: getSemanticColourFromSeverity(severity, 'grey')
        }
      };
    })
    .sortBy('text')
    .value())
  .value());

const mapStateToProps = createSelector(
  [
    state => fromState.getAll(state, ENTITY_TYPE.MODEL),
    state => fromState.getAll(state, ENTITY_TYPE.PREDICTOR),
    state => fromState.getAllTypes(state, ENTITY_TYPE.MODEL),
    state => fromState.getSelected(state, CHART_TYPE.MAIN, SELECTION_TYPE.INPUT),
    state => getInputSensors(state, CHART_TYPE.MAIN),
    state => fromState.getSelected(state, CHART_TYPE.MAIN, SELECTION_TYPE.FUNCTION),
    entityConfig[ENTITY_TYPE.PREDICTOR].selectContextListsSelector,
    state => fromState.getAll(state, ENTITY_TYPE.UNIT_TYPE_PREDICTOR),
    state => fromState.getAll(state, ENTITY_TYPE.UNIT_TYPE),
    state => fromState.getUnitPath(state),
    state => fromState.getPlaybackMode(state),
    getPredictorOptions,
    getUnitId,
    state => state.router.location.pathname,
    (state, { id }) => id
  ],
  (
    allModels,
    allPredictors,
    modelTypes,
    selectedInputs,
    inputSensors,
    selectedFunctions,
    selectContextLists,
    allUnitTypePredictors,
    allUnitTypes,
    unitPath,
    mode,
    predictorOptions,
    unitId,
    pathname,
    id
  ) => {
    const predictor = allPredictors[id];
    const model = predictor && allModels[predictor.type];
    const unitTypePredictor = (predictor && predictor.unitTypePredictor)
      ? allUnitTypePredictors[predictor.unitTypePredictor]
      : null;

    const unitType = unitTypePredictor
      && allUnitTypes[unitTypePredictor.unitType];

    const editModelButtonVisible = model && _.chain(modelTypes)
      .find(type => type.id === model.type)
      .get('supportsInlineEditing')
      .value();

    return {
      optionGroups: predictorOptions,
      label: {
        messageId: model
          ? `model.type.${model.type}.name`
          : 'model.type.sensorGroup.name',
        detail: unitType && unitType.name,
        model: model
          ? model.id
          : null
      },
      configurationButtonVisible: !unitTypePredictor && mode !== PLAYBACK_MODE.REVIEW,
      editModelButtonVisible,
      predictor,
      model,
      unitPath,
      text: _.get(predictor, ENTITY_PROPERTY.PREDICTOR.NAME),
      unitId,
      createEntity: entityConfig[ENTITY_TYPE.PREDICTOR].createEntity,
      pathname,
      selectedSensorOptions: _.chain(selectedInputs)
        .map(input => _.get(inputSensors[input], ENTITY_PROPERTY.SENSOR.ID))
        .concat(selectedFunctions)
        .uniq()
        .map(id => _.find(selectContextLists.attributes, ['id', id]))
        .filter()
        .value()
    };
  }
);

const mapDispatchToProps = (dispatch, { id }) => ({
  ...bindActionCreators({
    change: (unitPath, groupId) => querySyncPush(toPath(PAGE_URL.PREDICTOR, {
      unitPath,
      groupId
    })),
    clickEditPredictorButton: id => toggleModal(ENTITY_TYPE.PREDICTOR, ENTITY_MODAL.EDIT, id),
    clickEditModelButton: id => toggleModal(ENTITY_TYPE.MODEL, ENTITY_MODAL.EDIT, id),
    clickConfigurationButton: unitPath => querySyncPush(toPath(PAGE_URL.PREDICTOR_CONFIGURATION, {
      unitPath,
      parentId: id
    }))
  }, dispatch),
  clickAdd: selectedSensorOptions => {
    dispatch(toggleModal(ENTITY_TYPE.PREDICTOR, ENTITY_MODAL.ADD));
    dispatch(formValueChanged(
      ENTITY_TYPE.PREDICTOR,
      ENTITY_FORM_TYPE.ADD_OR_EDIT,
      ENTITY_PROPERTY.PREDICTOR.FUNCTION_MAPPINGS,
      selectedSensorOptions
    ));
  }
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    onChange: ({ change, unitPath }) => groupId => change(unitPath, groupId),
    onClickEditPredictorButton: ({ clickEditPredictorButton, predictor }) => () => clickEditPredictorButton(predictor.id),
    onClickEditModelButton: ({ clickEditModelButton, model }) => () => clickEditModelButton(model.id),
    onClickConfigurationButton: ({ clickConfigurationButton, unitPath }) => () => clickConfigurationButton(unitPath),
    createPredictor: ({ createEntity, unitId }) => values => createEntity({
      ...values,
      unitId
    }),
    onClickAdd: ({ clickAdd, selectedSensorOptions }) => () => clickAdd(selectedSensorOptions)
  })
)(PredictorBreadcrumbSection);