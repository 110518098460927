import _ from 'lodash';
import moment from 'moment';
import { Fragment } from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { Button, Divider, Grid, Header, Icon, Label, Popup, Segment, Sidebar, Table } from 'semantic-ui-react';
import CardsChartContainer from '../../containers/chart/CardsChartContainer';
import ChartDropdownContainer from '../../containers/chart/ChartDropdownContainer';
import HeatMapChartContainer from '../../containers/chart/HeatMapChartContainer';
import MapChartContainer from '../../containers/chart/MapChartContainer';
import TimeSeriesChartContainer from '../../containers/chart/TimeSeriesChartContainer';
import TrendSearchContainer from '../../containers/chart/TrendSearchContainer';
import PlaybackButtonsContainer from '../../containers/playback/PlaybackButtonsContainer';
import { CHART_TYPE, CHART_VIEW, COLOUR, DATE_FORMAT, ENTITY_TYPE, INVERTED_THEME, LINE_CHART_YAXIS_WIDTH } from '../../utils/constants';
import EntityText from '../entity/EntityText';
import ChartSidebar from './ChartSidebar';

const messages = defineMessages({
  'compare.text': {
    id: 'chartPage.compare.text',
    defaultMessage: 'COMPARE'
  },
  'actions.header': {
    id: 'chartPage.actions.header',
    defaultMessage: 'ACTIONS'
  }
});

const ChartPage = injectIntl(({
  view,
  compareOpen,
  compareTrend,
  onClickCompareButton,
  onClickCloseCompare,
  intl: {
    formatMessage
  }
}) => {
  return (
    <Fragment>
      <Grid className='button-bar'>
        <Grid.Column
          computer={5}
          largeScreen={4}
          widescreen={3}
          verticalAlign='middle'>
          <ChartDropdownContainer />
        </Grid.Column>
        <Grid.Column
          computer={11}
          largeScreen={12}
          widescreen={13}
          style={{ paddingLeft: `${LINE_CHART_YAXIS_WIDTH}px` }}>
          <PlaybackButtonsContainer />
        </Grid.Column>
      </Grid>
      <Sidebar.Pushable style={{
        overflowX: 'visible',
        minHeight: '500px'
      }}>
        <Sidebar.Pusher
          className='page-content'
          style={{
            height: '100%',
            overflowY: 'scroll'
          }}>
          {(() => {
            switch (view) {
              case CHART_VIEW.TIME_SERIES:
                return (
                  <TimeSeriesChartContainer chartType={CHART_TYPE.MAIN} />
                );
              case CHART_VIEW.HEAT_MAP:
                return (
                  <HeatMapChartContainer chartType={CHART_TYPE.MAIN} />
                );
              case CHART_VIEW.CARDS:
                return (
                  <CardsChartContainer chartType={CHART_TYPE.MAIN} />
                );
              default:
                return (
                  <MapChartContainer chartType={CHART_TYPE.MAIN} />
                );
            }
          })()}
          {compareTrend && (
            <Segment.Group>
              <Divider hidden />
              <Header
                style={{
                  background: '#4582EC',
                  padding: 0
                }}
                inverted={INVERTED_THEME}
                attached='top'
                as={Table}>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell width={3}>
                      {compareTrend.name}
                      <Header.Subheader>
                        {moment(compareTrend.to).format(DATE_FORMAT)}
                      </Header.Subheader>
                    </Table.HeaderCell>
                    <Table.HeaderCell width={3}>
                      <EntityText
                        id={compareTrend.unitId}
                        entityType={ENTITY_TYPE.UNIT} />
                    </Table.HeaderCell>
                    <Table.HeaderCell width={3}>
                      <Label.Group size='small'>
                        {_.map(compareTrend.labels, label => (
                          <Label key={label}>{label}</Label>
                        ))}
                      </Label.Group>
                    </Table.HeaderCell>
                    <Table.HeaderCell width={3}>
                      <Popup
                        trigger={(
                          <Icon name='info circle' />
                        )}
                        content={compareTrend.description}
                        inverted={INVERTED_THEME}
                        hoverable
                        position='top center'
                        basic
                        style={{
                          background: COLOUR.MEDIUM_GREY,
                          border: '1px solid white',
                          whiteSpace: 'pre-wrap',
                          maxHeight: '20em',
                          overflowY: 'scroll'
                        }} />
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      negative
                      width={1}>
                      <Button
                        icon='x'
                        size='huge'
                        floated='right'
                        negative
                        onClick={onClickCloseCompare} />
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
              </Header>
              <Segment
                attached
                inverted={INVERTED_THEME}
                style={{ border: '1px solid #555' }}>
                {(() => {
                  switch (view) {
                    case CHART_VIEW.TIME_SERIES:
                      return (
                        <TimeSeriesChartContainer chartType={CHART_TYPE.COMPARE} />
                      );
                    case CHART_VIEW.HEAT_MAP:
                      return (
                        <HeatMapChartContainer chartType={CHART_TYPE.COMPARE} />
                      );
                    case CHART_VIEW.CARDS:
                      return (
                        <CardsChartContainer chartType={CHART_TYPE.COMPARE} />
                      );
                    default:
                      return (
                        <MapChartContainer chartType={CHART_TYPE.MAIN} />
                      );
                  }
                })()}
              </Segment>
              <Divider hidden />
            </Segment.Group>
          )}
        </Sidebar.Pusher>
        <ChartSidebar
          open={compareOpen}
          onClickToggleButton={onClickCompareButton}
          buttonWidth={30}
          text={formatMessage(messages['compare.text'])}
          position='right'>
          <TrendSearchContainer style={{
            margin: '0',
            marginRight: '30px',
            width: '33vw',
            borderRadius: '0',
            borderTop: '1px solid #767676',
            borderBottom: '1px solid #767676'
          }} />
        </ChartSidebar>
      </Sidebar.Pushable>
    </Fragment>
  );
});

ChartPage.displayName = 'ChartPage';

export default ChartPage;