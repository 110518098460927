import { FETCH_LABELS_FULFILLED } from '../../actions/entity';

export default (state = [], action) => {
  switch (action.type) {
    case FETCH_LABELS_FULFILLED:
      return action.payload.labels;
    default:
      return state;
  }
};

export const getLabels = state => state;