import palette from 'google-palette';
import _ from 'lodash';
import createCachedSelector from 're-reselect';
import { COLOUR_PALETTE } from '../../../../utils/constants';
import { getFunctionSensorIds } from '../getFunctionSensorIds';
import { getInputs } from '../getInputs';
import { getOutputs } from '../getOutputs';

export const getColours = createCachedSelector(
  [
    getInputs,
    getOutputs,
    getFunctionSensorIds    
  ],
  (inputs, outputs, functionSensorIds) => {
    const lines = [...outputs, ...inputs, ...functionSensorIds];
    return _.chain(lines)
      .zip(palette(COLOUR_PALETTE, _.size(lines)))
      .fromPairs()
      .mapValues(colour => `#${colour}`)
      .value();
  }
)(
  (state, chartType) => chartType
);