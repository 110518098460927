import _ from 'lodash';
import { TOGGLE_DECK_COLLAPSED } from '../../actions/dashboard';

export default (state = [], action) => {
  switch (action.type) {
    case TOGGLE_DECK_COLLAPSED:
      if (_.includes(state, action.payload.entityType)) {
        return _.without(state, action.payload.entityType);
      } else {
        return _.concat(state, action.payload.entityType);
      }
    default: 
      return state;
  }
};

export const getCollapsedDecks = state => state;