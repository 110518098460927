import { FETCH_PAGE_FULFILLED, DELETE_BY_ID_FULFILLED, ADD_FULFILLED } from '../../actions/entity';
import _ from 'lodash';

export default (state = [], action) => {
  switch (action.type) {
    case FETCH_PAGE_FULFILLED:
      return _.map(action.payload.entityPage.items, 'id');
    case DELETE_BY_ID_FULFILLED:
      return _.filter(state, id => id !== action.payload.id);
    case ADD_FULFILLED:
      return [...state, action.payload.entity.id];
    default:
      return state;
  }
};

export const getAllIds = state => state;