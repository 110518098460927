import _ from 'lodash';
import moment from 'moment';
import { defineMessages } from 'react-intl';
import uuid from 'uuid/v1';
import EntityText from '../../../components/entity/EntityText';
import { DATE_FORMAT, ENTITY_FORM_ELEMENT_TYPE, ENTITY_TYPE, SELECT_CONTEXT_LIST } from '../../constants';
import parentTypeSelector from './parentTypeSelector';
import selectContextListsSelector from './selectContextListsSelector';
import StatusColumn from './StatusColumn';

const messages = defineMessages({
  'columns.name.title': {
    id: 'alertMapping.columns.name.title',
    defaultMessage: 'Name'
  },
  'columns.predictor.title': {
    id: 'alertMapping.columns.predictor.title',
    defaultMessage: 'Predictor'
  },
  'columns.type.title': {
    id: 'alertMapping.columns.type.title',
    defaultMessage: 'Type'
  },
  'columns.connector.title': {
    id: 'alertMapping.columns.conenctor.title',
    defaultMessage: 'Connector'
  },
  'columns.from.title': {
    id: 'alertMapping.columns.from.title',
    defaultMessage: 'From'
  },
  'columns.to.title': {
    id: 'alertMapping.columns.to.title',
    defaultMessage: 'To'
  },
  'columns.status.title': {
    id: 'alertMapping.columns.status.title',
    defaultMessage: 'Status'
  },
  'form.name.label': {
    id: 'alertMapping.form.name.label',
    defaultMessage: 'Name'
  },
  'form.name.placeholder': {
    id: 'alertMapping.form.name.placeholder',
    defaultMessage: 'Name'
  },
  'form.predictor.label': {
    id: 'alertMapping.form.predictor.label',
    defaultMessage: 'Predictor'
  },
  'form.predictor.placeholder': {
    id: 'alertMapping.form.predictor.placeholder',
    defaultMessage: 'Predictor'
  },
  'form.mode.label': {
    id: 'alertMapping.form.mode.label',
    defaultMessage: 'Mode'
  },
  'form.mode.option.live': {
    id: 'alertMapping.form.mode.option.live',
    defaultMessage: 'Live'
  },
  'form.mode.option.historical': {
    id: 'alertMapping.form.mode.option.historical',
    defaultMessage: 'Historical'
  },
  'form.connector.label': {
    id: 'alertMapping.form.connector.label',
    defaultMessage: 'Connector'
  },
  'form.connector.placeholder': {
    id: 'alertMapping.form.connector.placeholder',
    defaultMessage: 'Connector'
  },
  'type.prophes.label.name': {
    id: 'alertMapping.type.prophes.label.name',
    defaultMessage: 'Label'
  },
  'type.prophes.label.options.name.label': {
    id: 'alertMapping.type.prophes.label.options.name.label',
    defaultMessage: 'Name'
  },
  'type.prophes.label.options.name.placeholder': {
    id: 'alertMapping.type.prophes.label.options.name.placeholder',
    defaultMessage: 'Name'
  },
  'type.prophes.label.options.description.label': {
    id: 'alertMapping.type.prophes.label.options.description.label',
    defaultMessage: 'Description'
  },
  'type.prophes.label.options.description.placeholder': {
    id: 'alertMapping.type.prophes.label.options.description.placeholder',
    defaultMessage: 'Description'
  },
  'type.prophes.label.options.window.label': {
    id: 'alertMapping.type.prophes.label.options.window.label',
    defaultMessage: 'Time Window'
  },
  'type.prophes.label.options.window.placeholder': {
    id: 'alertMapping.type.prophes.label.options.window.placeholder',
    defaultMessage: 'Time Window'
  },
  'type.prophes.label.options.labels.label': {
    id: 'alertMapping.type.prophes.label.options.labels.label',
    defaultMessage: 'Labels'
  },
  'type.prophes.label.options.labels.placeholder': {
    id: 'alertMapping.type.prophes.label.options.labels.placeholder',
    defaultMessage: 'Labels'
  },
  'type.prophes.builtInEmail.name': {
    id: 'alertMapping.type.prophes.builtInEmail.name',
    defaultMessage: 'Email'
  },
  'type.prophes.builtInEmail.options.emailAddress.label': {
    id: 'alertMapping.type.prophes.builtInEmail.options.emailAddress.label',
    defaultMessage: 'Email Address'
  },
  'type.prophes.builtInEmail.options.emailAddress.placeholder': {
    id: 'alertMapping.type.prophes.builtInEmail.options.emailAddress.placeholder',
    defaultMessage: 'Email Address'
  },
  'type.smtp.email.name': {
    id: 'alertMapping.type.smtp.email.name',
    defaultMessage: 'Email'
  },
  'type.smtp.email.options.emailAddress.label': {
    id: 'alertMapping.type.smtp.email.options.emailAddress.label',
    defaultMessage: 'Email Address'
  },
  'type.smtp.email.options.emailAddress.placeholder': {
    id: 'alertMapping.type.smtp.email.options.emailAddress.placeholder',
    defaultMessage: 'Email Address'
  },
  'type.phalanx.phalanxEmailAlert.name': {
    id: 'alertMapping.type.phalanx.phalanxEmailAlert.name',
    defaultMessage: 'Email Alert'
  },
  'type.phalanx.phalanxEmailAlert.options.alertType.label': {
    id: 'alertMapping.type.phalanx.phalanxEmailAlert.options.alertType.label',
    defaultMessage: 'Alert Type'
  },
  'type.phalanx.phalanxEmailAlert.options.alertType.placeholder': {
    id: 'alertMapping.type.phalanx.phalanxEmailAlert.options.alertType.placeholder',
    defaultMessage: 'Alert Type'
  },
  'type.phalanx.phalanxEmailAlert.options.template.label': {
    id: 'alertMapping.type.phalanx.phalanxEmailAlert.options.template.label',
    defaultMessage: 'Template'
  },
  'type.phalanx.phalanxEmailAlert.options.template.placeholder': {
    id: 'alertMapping.type.phalanx.phalanxEmailAlert.options.template.placeholder',
    defaultMessage: 'Template'
  },
  'type.phalanx.phalanxEmailAlert.options.subject.label': {
    id: 'alertMapping.type.phalanx.phalanxEmailAlert.options.subject.label',
    defaultMessage: 'Subject'
  },
  'type.phalanx.phalanxEmailAlert.options.subject.placeholder': {
    id: 'alertMapping.type.phalanx.phalanxEmailAlert.options.subject.placeholder',
    defaultMessage: 'Subject'
  },
  'type.phalanx.phalanxEmailAlert.options.locationId.label': {
    id: 'alertMapping.type.phalanx.phalanxEmailAlert.options.locationId.label',
    defaultMessage: 'Location Id'
  },
  'type.phalanx.phalanxEmailAlert.options.locationId.placeholder': {
    id: 'alertMapping.type.phalanx.phalanxEmailAlert.options.locationId.placeholder',
    defaultMessage: 'Location Id'
  },
  'type.phalanx.phalanxEmailAlert.options.additionalEmailAddress.label': {
    id: 'alertMapping.type.phalanx.phalanxEmailAlert.options.additionalEmailAddress.label',
    defaultMessage: 'Additional Email Address'
  },
  'type.phalanx.phalanxEmailAlert.options.additionalEmailAddress.placeholder': {
    id: 'alertMapping.type.phalanx.phalanxEmailAlert.options.additionalEmailAddress.placeholder',
    defaultMessage: 'Additional Email Address'
  },
  'type.phalanx.phalanxCreateWork.name': {
    id: 'alertMapping.type.phalanx.phalanxCreateWork.name',
    defaultMessage: 'Create Work'
  },
  'type.phalanx.phalanxCreateWork.options.workType.label': {
    id: 'alertMapping.type.phalanx.phalanxCreateWork.options.workType.label',
    defaultMessage: 'Work Type'
  },
  'type.phalanx.phalanxCreateWork.options.workType.placeholder': {
    id: 'alertMapping.type.phalanx.phalanxCreateWork.options.workType.placeholder',
    defaultMessage: 'Work Type'
  },
  'type.phalanx.phalanxCreateWork.options.serialisedEquipmentId.label': {
    id: 'alertMapping.type.phalanx.phalanxCreateWork.options.serialisedEquipmentId.label',
    defaultMessage: 'Serialised Equipment Id'
  },
  'type.phalanx.phalanxCreateWork.options.serialisedEquipmentId.placeholder': {
    id: 'alertMapping.type.phalanx.phalanxCreateWork.options.serialisedEquipmentId.placeholder',
    defaultMessage: 'Serialised Equipment Id'
  },
  'type.phalanx.phalanxCreateWork.options.locationId.label': {
    id: 'alertMapping.type.phalanx.phalanxCreateWork.options.locationId.label',
    defaultMessage: 'Location Id'
  },
  'type.phalanx.phalanxCreateWork.options.locationId.placeholder': {
    id: 'alertMapping.type.phalanx.phalanxCreateWork.options.locationId.placeholder',
    defaultMessage: 'Location Id'
  },
  'type.phalanx.phalanxCreateWork.options.companyId.label': {
    id: 'alertMapping.type.phalanx.phalanxCreateWork.options.companyId.label',
    defaultMessage: 'Company Id'
  },
  'type.phalanx.phalanxCreateWork.options.companyId.placeholder': {
    id: 'alertMapping.type.phalanx.phalanxCreateWork.options.companyId.placeholder',
    defaultMessage: 'Company Id'
  },
  'type.phalanx.phalanxCreateWork.options.customer.label': {
    id: 'alertMapping.type.phalanx.phalanxCreateWork.options.customer.label',
    defaultMessage: 'Customer'
  },
  'type.phalanx.phalanxCreateWork.options.customer.placeholder': {
    id: 'alertMapping.type.phalanx.phalanxCreateWork.options.customer.placeholder',
    defaultMessage: 'Customer'
  },
  'type.phalanx.phalanxCreateWork.options.customerAddress.label': {
    id: 'alertMapping.type.phalanx.phalanxCreateWork.options.customerAddress.label',
    defaultMessage: 'Customer Address'
  },
  'type.phalanx.phalanxCreateWork.options.customerAddress.placeholder': {
    id: 'alertMapping.type.phalanx.phalanxCreateWork.options.customerAddress.placeholder',
    defaultMessage: 'Customer Address'
  },
  'type.phalanx.phalanxCustomerAlert.name': {
    id: 'alertMapping.type.phalanx.phalanxCustomerAlert.name',
    defaultMessage: 'Customer Alert'
  },
  'type.phalanx.phalanxCustomerAlert.options.serialisedEquipmentId.label': {
    id: 'alertMapping.type.phalanx.phalanxCustomerAlert.options.serialisedEquipmentId.label',
    defaultMessage: 'Serialised Equipment Id'
  },
  'type.phalanx.phalanxCustomerAlert.options.serialisedEquipmentId.placeholder': {
    id: 'alertMapping.type.phalanx.phalanxCustomerAlert.options.serialisedEquipmentId.placeholder',
    defaultMessage: 'Serialised Equipment Id'
  },
  'type.phalanx.phalanxCustomerAlert.options.template.label': {
    id: 'alertMapping.type.phalanx.phalanxCustomerAlert.options.template.label',
    defaultMessage: 'Template'
  },
  'type.phalanx.phalanxCustomerAlert.options.template.placeholder': {
    id: 'alertMapping.type.phalanx.phalanxCustomerAlert.options.template.placeholder',
    defaultMessage: 'Template'
  },
  'type.phalanx.phalanxCreateServiceWork.name': {
    id: 'alertMapping.type.phalanx.phalanxCreateServiceWork.name',
    defaultMessage: 'Create Service Work'
  },
  'type.phalanx.phalanxCreateServiceWork.options.serialisedEquipmentId.label': {
    id: 'alertMapping.type.phalanx.phalanxCreateServiceWork.options.serialisedEquipmentId.label',
    defaultMessage: 'Serialised Equipment Id'
  },
  'type.phalanx.phalanxCreateServiceWork.options.serialisedEquipmentId.placeholder': {
    id: 'alertMapping.type.phalanx.phalanxCreateServiceWork.options.serialisedEquipmentId.placeholder',
    defaultMessage: 'Serialised Equipment Id'
  },
  'type.phalanx.phalanxCreateServiceWork.options.location.label': {
    id: 'alertMapping.type.phalanx.phalanxCreateServiceWork.options.location.label',
    defaultMessage: 'Location'
  },
  'type.phalanx.phalanxCreateServiceWork.options.location.placeholder': {
    id: 'alertMapping.type.phalanx.phalanxCreateServiceWork.options.location.placeholder',
    defaultMessage: 'Location'
  },
  'type.phalanx.phalanxReceiptEquipment.name': {
    id: 'alertMapping.type.phalanx.phalanxReceiptEquipment.name',
    defaultMessage: 'Receipt Equipment'
  },
  'type.phalanx.phalanxReceiptEquipment.options.serialisedEquipmentId.label': {
    id: 'alertMapping.type.phalanx.phalanxReceiptEquipment.options.serialisedEquipmentId.label',
    defaultMessage: 'Serialised Equipment Id'
  },
  'type.phalanx.phalanxReceiptEquipment.options.serialisedEquipmentId.placeholder': {
    id: 'alertMapping.type.phalanx.phalanxReceiptEquipment.options.serialisedEquipmentId.placeholder',
    defaultMessage: 'Serialised Equipment Id'
  },
  'type.phalanx.phalanxReceiptEquipment.options.location.label': {
    id: 'alertMapping.type.phalanx.phalanxReceiptEquipment.options.location.label',
    defaultMessage: 'Location'
  },
  'type.phalanx.phalanxReceiptEquipment.options.location.placeholder': {
    id: 'alertMapping.type.phalanx.phalanxReceiptEquipment.options.location.placeholder',
    defaultMessage: 'Location'
  }
});

const MODE = {
  LIVE: 'live',
  HISTORICAL: 'historical'
};

export default {
  apiPath: 'alertmapping',
  pageUrl: 'alerts',
  parentEntityType: ENTITY_TYPE.PREDICTOR,
  conditions: true,
  labels: true,
  messages,
  selectContextListsSelector,
  parentTypeSelector,
  parentSearchFilter: parentId => ({
    Predictor: {
      $eq: parentId
    }
  }),
  formValueSearchSchema: [
    {
      name: 'connector',
      entityType: ENTITY_TYPE.ALERT_CONNECTOR
    }
  ],
  columns: [
    {
      name: 'name',
      width: 3,
      render: ({ name }) => name
    },
    {
      name: 'connector',
      width: 3,
      render: ({ connector }) => (
        <EntityText
          id={connector}
          entityType={ENTITY_TYPE.ALERT_CONNECTOR} />
      )
    },
    {
      name: 'from',
      width: 3,
      render: ({ from }) => from && moment.utc(from).local().format(DATE_FORMAT)
    },
    {
      name: 'to',
      width: 3,
      render: ({ to }) => to && moment.utc(to).local().format(DATE_FORMAT)
    },
    {
      name: 'status',
      width: 3,
      render: ({ state }) => (
        <StatusColumn state={state} />
      )
    }
  ],
  form: [
    {
      name: 'name',
      type: ENTITY_FORM_ELEMENT_TYPE.INPUT
    },
    {
      type: 'conditions',
      conditionTypeFilter: '$.valueType = name.valueType',
      enumerationPath: 'name.enumeration',
      conditionForm: [
        {
          name: 'name',
          type: ENTITY_FORM_ELEMENT_TYPE.SELECT,
          selectContextListName: SELECT_CONTEXT_LIST.OUTPUTS
        }
      ],
      getErrorPath: index => `conditions.list[${index}].condition`,
      errorPathPrefix: 'conditions'
    },
    {
      name: 'mode',
      type: ENTITY_FORM_ELEMENT_TYPE.RADIO,
      options: [MODE.LIVE, MODE.HISTORICAL],
      disableOnEdit: true
    },
    {
      name: 'dateRange',
      type: ENTITY_FORM_ELEMENT_TYPE.DATE_RANGE,
      errorPaths: ['from', 'to'],
      fromErrorPath: 'from',
      toErrorPath: 'to',
      filter: values => values.mode === MODE.HISTORICAL
    },
    {
      name: 'connector',
      type: ENTITY_FORM_ELEMENT_TYPE.SEARCH,
      searchEntityType: ENTITY_TYPE.ALERT_CONNECTOR,
      transformResult: alertConnector => ({
        key: alertConnector.id,
        text: alertConnector.name,
        value: alertConnector.id
      }),
      disableOnEdit: true
    },
    {
      type: ENTITY_FORM_ELEMENT_TYPE.TYPES,
      filter: values => !!values.connector
    }
  ],
  createEntity: (values, predictor) => ({
    id: uuid(),
    name: values.name,
    predictor: predictor.id,
    conditions: {
      filter: values.conditions.filter,
      list: _.map(values.conditions.list, condition => ({
        output: condition.name,
        condition: _.pick(condition, ['settings', 'type'])
      }))
    },
    connector: values.connector,
    unitId: null,
    type: values.connector
      ? values.type
      : null,
    settings: values.settings || {},
    from: moment.utc(_.get(values, 'dateRange.from'), DATE_FORMAT).toISOString(),
    to: moment.utc(_.get(values, 'dateRange.to'), DATE_FORMAT).toISOString()
  }),
  populateForm: entity => ({
    name: entity.name,
    conditions: {
      filter: entity.conditions.filter,
      list: _.map(entity.conditions.list, outputCondition => ({
        name: outputCondition.output,
        settings: outputCondition.condition.settings,
        type: outputCondition.condition.type
      }))
    },
    connector: entity.connector,
    type: entity.type,
    settings: entity.settings,
    dateRange: {
      from: entity.from && moment.utc(entity.from).format(DATE_FORMAT),
      to: entity.to && moment.utc(entity.to).format(DATE_FORMAT)
    },
    mode: entity.from || entity.to
      ? MODE.HISTORICAL
      : MODE.LIVE
  })
};