import { SET_MUTE_MODAL } from '../../../actions/dashboard';

export default (state = false, action) => {
  switch (action.type) {
    case SET_MUTE_MODAL:
      return action.payload.open;
    default:
      return state;
  }
};

export const getOpen = state => state;