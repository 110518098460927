import _ from 'lodash';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Segment, Table } from 'semantic-ui-react';
import { CHART_TYPE, INVERTED_THEME, SELECTION_TYPE, FEATURE, PROPERTY } from '../../../utils/constants';
import styles from './ChartKey.styles';
import ChartKeyHeader from './ChartKeyHeader';
import ChartKeyRow from './ChartKeyRow';

const ChartKey = injectIntl(({
  itemGroups,
  editingItemId,
  editText,
  isSaving,
  chartType,
  showKeyColours,
  showFeaturesToggle,
  featuresToggle,
  selectedFeature,
  featureWindow,
  selectedProperty,
  isLoading,
  onSelect,
  onSetSelected,
  onOpenFunctionSensorsModal,
  onSetEditingId,
  onSetEditText,
  onRemove,
  onEdit,
  onHoverItem,
  onSetFeaturesToggle,
  onSetSelectedFeature,
  onSetFeatureWindow,
  onSetSelectedProperty
}) => (
  <Segment.Group style={styles.segmentGroup}>
    <ChartKeyHeader
      itemGroups={itemGroups}
      chartType={chartType}
      showFeaturesToggle={showFeaturesToggle}
      featuresToggle={featuresToggle}
      selectedFeature={selectedFeature}
      featureWindow={featureWindow}
      selectedProperty={selectedProperty}
      isLoading={isLoading}
      onSetSelected={onSetSelected}
      onOpenFunctionSensorsModal={onOpenFunctionSensorsModal}
      onSetFeaturesToggle={onSetFeaturesToggle}
      onSetSelectedFeature={onSetSelectedFeature}
      onSetFeatureWindow={onSetFeatureWindow}
      onSetSelectedProperty={onSetSelectedProperty} />
    <Segment
      inverted={INVERTED_THEME}
      raised
      style={styles.keySegment}>
      {_.map(itemGroups, (itemGroup, i) => (
        <Table
          key={i}
          inverted={INVERTED_THEME}
          selectable
          size='small'
          compact='very'
          style={styles.itemGroupTable}>
          <Table.Body>
            {_.map(itemGroup, (item, j) => (
              <ChartKeyRow
                key={j}
                item={item}
                index={j}
                editingItemId={editingItemId}
                editText={editText}
                isSaving={isSaving}
                deleteDisabled={_.size(itemGroups) === 1 && _.size(itemGroup) === 1}
                chartType={chartType}
                showKeyColours={showKeyColours}
                onSelect={onSelect}
                onSetEditingId={onSetEditingId}
                onSetEditText={onSetEditText}
                onRemove={onRemove}
                onEdit={onEdit}
                onHoverItem={onHoverItem} />
            ))}
          </Table.Body>
        </Table>
      ))}
    </Segment>
  </Segment.Group>
));

ChartKey.propTypes = {
  itemGroups: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.exact({
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    description: PropTypes.string,
    colour: PropTypes.string,
    selected: PropTypes.bool.isRequired,
    selectionType: PropTypes.oneOf(_.values(SELECTION_TYPE)),
    copyText: PropTypes.string,
    editable: PropTypes.bool.isRequired,
    removable: PropTypes.bool.isRequired
  }))),
  editingItemId: PropTypes.string,
  editText: PropTypes.string,
  isSaving: PropTypes.bool.isRequired,
  chartType: PropTypes.oneOf(_.values(CHART_TYPE)),
  showKeyColours: PropTypes.bool,
  showFeaturesToggle: PropTypes.bool,
  featuresToggle: PropTypes.bool,
  selectedFeature: PropTypes.oneOf(_.values(FEATURE)),
  featureWindow: PropTypes.string,
  selectedProperty: PropTypes.oneOf(_.values(PROPERTY)),
  isLoading: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  onSetSelected: PropTypes.func.isRequired,
  onOpenFunctionSensorsModal: PropTypes.func.isRequired,
  onSetEditingId: PropTypes.func.isRequired,
  onSetEditText: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onHoverItem: PropTypes.func.isRequired,
  onSetFeaturesToggle: PropTypes.func.isRequired,
  onSetSelectedFeature: PropTypes.func.isRequired,
  onSetFeatureWindow: PropTypes.func.isRequired,
  onSetSelectedProperty: PropTypes.func.isRequired
};

ChartKey.displayName = 'ChartKey';

export default ChartKey;