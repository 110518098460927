import { Fragment } from 'react';
import { connect } from 'react-redux';
import * as fromState from '../../state/reducers';

const mapStateToProps = state => {
  const user = fromState.getUser(state);
  return {
    isAuthenticated: !!(user && user.token)
  };
};

export default connect(mapStateToProps)(({
  isAuthenticated,
  children
}) => isAuthenticated && (
  <Fragment>
    {children}
  </Fragment>
));