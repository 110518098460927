import form, * as fromForm from './form';
import user, * as fromUser from './user';
import message, * as fromMessage from './message';
import tenant, * as fromTenant from './tenant';
import { combineReducers } from 'redux';
import { AUTH_TYPE, ENTITY_PROPERTY } from '../../../utils/constants';
import _ from 'lodash';

export default combineReducers({
  form: combineReducers(_.chain(AUTH_TYPE)
    .values()
    .map(value => [value, form(value)])
    .fromPairs()
    .value()),
  user,
  message,
  tenant
});

export const getAuthGroupId = state => {
  const user = fromUser.getUser(state.user);
  const userMapping = _.find(user.userMappings, [ENTITY_PROPERTY.USER_MAPPING.TENANT_ID, user.tenantId]);
  return userMapping.authGroup;
};
export const getValues = (state, authType) => fromForm.getValues(state.form[authType]);
export const getIsLoading = (state, authType) => fromForm.getIsLoading(state.form[authType]);
export const getErrorResponse = (state, authType) => fromForm.getErrorResponse(state.form[authType]);
export const getErrorCodes = (state, authType) => fromForm.getErrorCodes(state.form[authType]);
export const getErrorKeys = (state, authType) => fromForm.getErrorKeys(state.form[authType]);
export const getShowPassword = (state, authType) => fromForm.getShowPassword(state.form[authType]);
export const getUser = state => fromUser.getUser(state.user);
export const getMessage = state => fromMessage.getMessage(state.message);
export const getTenant = state => fromTenant.getTenant(state.tenant);