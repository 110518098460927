import { combineReducers } from 'redux';
import alarmRanges, * as fromAlarmRanges from './alarmRanges';
import alarmLevels, * as fromAlarmLevels from './alarmLevels';
import modalIsOpen, * as fromModalIsOpen from './modalIsOpen';
import isSaving, * as fromIsSaving from './isSaving';

export default combineReducers({
  alarmRanges,
  alarmLevels,
  modalIsOpen,
  isSaving
});

export const getAlarmRanges = state => fromAlarmRanges.getAlarmRanges(state.alarmRanges);
export const getAlarmRangesValid = state => fromAlarmRanges.getAlarmRangesValid(state.alarmRanges);

export const getAlarmLevels = state => fromAlarmLevels.getAlarmLevels(state.alarmLevels);

export const getModalIsOpen = state => fromModalIsOpen.getModalIsOpen(state.modalIsOpen);

export const getIsSaving = state => fromIsSaving.getIsSaving(state.isSaving);