import { connect } from 'react-redux';
import DashboardCard from '../../components/dashboard/DashboardCard';
import { getColourFromSeverity, toPath } from '../../utils/helpers';
import { PAGE_URL, ENTITY_PROPERTY, ENTITY_TYPE, DASHBOARD_VIEW } from 'utils/constants';
import createCachedSelector from 're-reselect';
import { getUnitTypeSeverity } from 'state/selectors/dashboard/getSeverity/getUnitTypeSeverity';
import * as fromState from 'state/reducers';
import _ from 'lodash';
import { UnitType } from 'types';
import { RootState } from 'index';
import { SemanticCOLORS } from 'semantic-ui-react';

interface InputProps {
  unitType: UnitType;
}

const labelColor: SemanticCOLORS = 'violet';

const mapStateToProps = createCachedSelector(
  [
    (state: RootState, { unitType }: InputProps) => getUnitTypeSeverity(state, { id: unitType.id }),
    state => fromState.getAll(state, ENTITY_TYPE.UNIT_CATEGORY),
    (state, { unitType }) => unitType,
    state => fromState.getShowCardDetails(state)
  ],
  (severity, allUnitCategories, unitType, showDetails) => ({
    id: unitType.id,
    linkTo: {
      pathname: toPath(PAGE_URL.UNIT_TYPE, {
        unitTypeId: unitType.id
      }),
      search: `?dashboard=${DASHBOARD_VIEW.UNIT_TYPES}`
    },
    name: unitType.name,
    labels: [
      {
        id: 'unitCategory',
        icon: _.get(allUnitCategories[unitType.parentType], ENTITY_PROPERTY.UNIT_CATEGORY.ICON),
        color: labelColor,
        messageId: `unit.type.${unitType.parentType}.name`,
        messageDefaultValue: _.get(allUnitCategories[unitType.parentType], ENTITY_PROPERTY.UNIT_CATEGORY.NAME),
        type: ENTITY_TYPE.UNIT_CATEGORY
      }
    ],
    fill: getColourFromSeverity(severity),
    severity,
    active: true,
    noModel: true,
    canMute: false,
    canDelete: false,
    canEdit: true,
    canConfigure: false,
    entityType: ENTITY_TYPE.UNIT_TYPE,
    showDetails
  })
)(
  (state, { unitType }) => unitType.id
);

export default connect(mapStateToProps)(DashboardCard);