import { defineMessages } from 'react-intl';
import { QuickRange } from 'types';

export const quickRanges: QuickRange[] = ['PT1H', 'PT12H', 'P1D', 'P3D', 'P7D', 'P1M'];

export const messages = defineMessages({
  'window.label': {
    id: 'dateRangePicker.window.label',
    defaultMessage: 'Window Size'
  },
  'quick.PT1H': {
    id: 'dateRangePicker.quick.lastHour',
    defaultMessage: '1 Hour'
  },
  'quick.PT12H': {
    id: 'dateRangePicker.quick.lastTwelveHours',
    defaultMessage: '12 Hours'
  },
  'quick.P1D': {
    id: 'dateRangePicker.quick.lastDay',
    defaultMessage: '1 Day'
  },
  'quick.P7D': {
    id: 'dateRangePicker.quick.lastSevenDays',
    defaultMessage: '7 Days'
  },
  'quick.P3D': {
    id: 'dateRangePicker.quick.lastThreeDays',
    defaultMessage: '3 Days'
  },
  'quick.P2D': {
    id: 'dateRangePicker.quick.lastTwoDays',
    defaultMessage: '2 Days'
  },
  'quick.P1M': {
    id: 'dateRangePicker.quick.lastMonth',
    defaultMessage: '1 Month'
  },
  'quick.window': {
    id: 'dateRangePicker.quick.window',
    defaultMessage: 'Window'
  },
  'from.label': {
    id: 'dateRangePicker.from.label',
    defaultMessage: 'From'
  },
  'from.placeholder': {
    id: 'dateRangePicker.from.placeholder',
    defaultMessage: 'From'
  },
  'to.label': {
    id: 'dateRangePicker.to.label',
    defaultMessage: 'To'
  },
  'to.placeholder': {
    id: 'dateRangePicker.to.placeholder',
    defaultMessage: 'To'
  }
});