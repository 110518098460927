import _ from 'lodash';
import { merge, of } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, mergeMap } from 'rxjs/operators';
import { insert, insertMany } from 'state/actions/entity';
import * as api from 'utils/api';
import { takeUntilAppReset } from 'utils/helpers';
import { CONTROLLER, ENTITY_TYPE } from '../../../utils/constants';
import * as fromState from '../../reducers';

export default (action$, state$) => state$.pipe(
  distinctUntilChanged((prev, curr) =>
    fromState.getPlaybackReviewId(prev) === fromState.getPlaybackReviewId(curr)),
  filter(state => fromState.getPlaybackReviewId(state) && fromState.getUser(state)),
  mergeMap(state => api.get({
    controller: CONTROLLER.REVIEW,
    id: fromState.getPlaybackReviewId(state),
    token: fromState.getUser(state).token
  }).pipe(
    takeUntilAppReset(action$),
    mergeMap(review => merge(
      of(
        insert(ENTITY_TYPE.REVIEW, review)
      ),
      api.getMultiple({
        controller: CONTROLLER.TREND,
        ids: _.map(review.trends, trend => trend.id),
        token: fromState.getUser(state).token
      }).pipe(
        takeUntilAppReset(action$),
        map(trends => insertMany(ENTITY_TYPE.TREND, trends))
      )
    )),
    catchError(api.onError)
  ))
);