import _ from 'lodash';
import moment from 'moment';
import { catchError, distinctUntilChanged, filter, map, mergeMap } from 'rxjs/operators';
import { CHART_TYPE, DASHBOARD_VIEW, ENTITY_PROPERTY } from '../../../utils/constants';
import { getUnitId, getUnitTypeId, getUnitSensors, getUnitTypeSensors } from '../../selectors/dashboard';
import * as fromState from '../../reducers';
import * as api from '../../../utils/api';
import { takeUntilAppReset } from '../../../utils/helpers';
import { setMultipleLatest } from '../../actions/data';

const getUnitOrUnitTypeId = state => fromState.getDashboardView(state) === DASHBOARD_VIEW.UNITS
  ? getUnitId(state)
  : getUnitTypeId(state);

export default (action$, state$) => state$.pipe(
  distinctUntilChanged((prev, curr) => getUnitOrUnitTypeId(prev) === getUnitOrUnitTypeId(curr)
    && fromState.getUser(prev) === fromState.getUser(curr)),
  filter(state => !_.isNull(fromState.getUser(state))),
  mergeMap(state => {
    const sensors = fromState.getDashboardView(state) === DASHBOARD_VIEW.UNITS
      ? getUnitSensors(state)
      : getUnitTypeSensors(state);

    return api.getSensorsLatest({
      sensorIds: _.map(sensors, ENTITY_PROPERTY.SENSOR.ID),
      fromDate: moment.utc(fromState.getPlaybackTime(state))
        .subtract(moment.duration(fromState.getQuickRange(state, CHART_TYPE.MAIN)))
        .toISOString(),
      toDate: fromState.getPlaybackTime(state),
      token: fromState.getUser(state).token
    }).pipe(
      takeUntilAppReset(action$),
      map(response => setMultipleLatest(CHART_TYPE.MAIN, response)),
      catchError(api.onError)
    );
  })
);