import { ReactElement } from 'react';
import { DropdownItemProps } from 'semantic-ui-react';
import ScrollAwareFormSelect from '../shared/ScrollAwareFormSelect';

interface Props {
  scrollContextRef: React.Ref<HTMLElement>;
  onChange: (value: string) => void;
  options: DropdownItemProps[];
  label: string;
  placeholder: string;
  value: string;
  error?: boolean;
  upward?: boolean;
  disabled?: boolean;
  multiple?: boolean;
}
export default function SelectEntityElement({
  scrollContextRef,
  onChange,
  options,
  label,
  placeholder,
  value,
  error,
  upward,
  disabled,
  multiple
}: Props): ReactElement {
  return (
    <ScrollAwareFormSelect
      scrollContextRef={scrollContextRef}
      search
      clearable
      label={label}
      placeholder={placeholder}
      value={value}
      error={error}
      upward={upward}
      disabled={disabled}
      options={options}
      multiple={multiple}
      onChange={(event, { value }) => onChange(value || null)} />
  );
}