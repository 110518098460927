import { SET_FUNCTION_DESCRIPTION_EDIT_TEXT } from '../../../actions/chart';

export default (state = '', action) => {
  switch (action.type) {
    case SET_FUNCTION_DESCRIPTION_EDIT_TEXT:
      return action.payload.text;
    default:
      return state;
  }
};

export const getFunctionDescriptionEditText = state => state;