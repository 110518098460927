import moment from 'moment';
import createCachedSelector from 're-reselect';
import { DATE_FORMAT } from '../../../../utils/constants';
import * as fromState from '../../../reducers';
import { getFeatureWindowDuration } from '../getFeatureWindowDuration';

export const getWindowFromDate = createCachedSelector(
  [
    fromState.getFromDate,
    getFeatureWindowDuration
  ],
  (fromDate, featureWindowDuration) => moment.utc(fromDate, DATE_FORMAT).subtract(featureWindowDuration)
)(
  (state, chartType) => chartType
);