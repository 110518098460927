import moment from 'moment';
import { Fragment } from 'react';
import { injectIntl } from 'react-intl';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { TimeInput } from 'semantic-ui-calendar-react';
import { Form } from 'semantic-ui-react';
import { DATE_FORMAT, DATE_FORMAT_NO_TIME, INVERTED_THEME, COLOUR, EMPTY_STRING } from '../../utils/constants';
import { messages } from 'utils/dates';

const DateRangePicker = injectIntl(({
  noQuickRange,
  noFormWrapper,
  quickRange,
  fromDate,
  toDate,
  readonly,
  upward,
  includeTime,
  onChangeQuickRange,
  onChangeFromDate,
  onChangeToDate,
  onChangeDates,
  fromError,
  toError,
  style,
  clearable,
  intl: {
    formatMessage,
    locale
  }
}) => {
  const styles = {
    timePicker: {
      color: COLOUR.TEXT,
      background: COLOUR.MEDIUM_GREY,
      minWidth: '16em'
    }
  };
  const Wrapper = noFormWrapper ? Fragment : Form;
  return (
    <Wrapper {...noFormWrapper ? {} : { inverted: INVERTED_THEME }}>
      <Form.Group
        widths='equal'
        style={style}>
        {!noQuickRange && (
          <Form.Field>
            <label>{formatMessage(messages['window.label'])}</label>
            <Form.Select
              style={{ opacity: 1 }}
              disabled={readonly}
              value={quickRange}
              onChange={(event, { value }) => onChangeQuickRange(value)}
              options={[
                {
                  key: 'lastHour',
                  text: formatMessage(messages['quick.PT1H']),
                  value: 'PT1H'
                },
                {
                  key: 'lastTwelveHours',
                  text: formatMessage(messages['quick.PT12H']),
                  value: 'PT12H'
                },
                {
                  key: 'lastDay',
                  text: formatMessage(messages['quick.P1D']),
                  value: 'P1D'
                },
                {
                  key: 'lastThreeDays',
                  text: formatMessage(messages['quick.P3D']),
                  value: 'P3D'
                },
                {
                  key: 'lastSevenDays',
                  text: formatMessage(messages['quick.P7D']),
                  value: 'P7D'
                },
                {
                  key: 'lastMonth',
                  text: formatMessage(messages['quick.P1M']),
                  value: 'P1M'
                }
              ]} />
          </Form.Field>
        )}
        {noQuickRange && (
          <Form.Field
            error={fromError}
            className='force-fluid'>
            <SemanticDatepicker
              disabled={readonly}
              inverted={INVERTED_THEME}
              label={formatMessage(messages['from.label'])}
              clearable={clearable}
              error={fromError}
              autoComplete='off'
              closable
              showToday={false}
              pointing={upward
                ? 'top left'
                : 'left'}
              maxDate={toDate
                ? moment.utc(toDate).local().toDate()
                : moment().utc().local().toDate()}
              format={DATE_FORMAT_NO_TIME}
              date={fromDate
                ? moment.utc(fromDate).local().toDate()
                : toDate
                  ? moment.utc(toDate).local().toDate()
                  : moment.utc().local().toDate()}
              value={fromDate && moment.utc(fromDate).local().toDate()}
              selected={fromDate && moment.utc(fromDate).local().toDate()}
              onChange={(event, { value }) => onChangeFromDate(value && moment(value).utc().format(DATE_FORMAT))}
              name='dateTime'
              placeholder={formatMessage(messages['from.placeholder'])}
              iconPosition='left'
              localization={locale} />
          </Form.Field>
        )}
        {includeTime && (
          <TimeInput
            label='&nbsp;'
            iconPosition='left'
            popupPosition={upward
              ? 'top left'
              : 'bottom left'}
            closable
            duration={0}
            error={fromError}
            pickerStyle={styles.timePicker}
            disabled={readonly || !fromDate}
            value={fromDate
              ? moment.utc(fromDate).local().format('HH:mm')
              : EMPTY_STRING}
            onChange={(event, { value }) => {
              const newFromDate = moment.utc(fromDate).local();
              const time = moment(value, 'HH:mm');
              newFromDate.set({
                hour: time.get('hours'),
                minute: time.get('minute')
              });
              onChangeFromDate(newFromDate.utc().format(DATE_FORMAT));
            }} />
        )}
        <Form.Field
          error={toError}
          className='force-fluid'>
          <SemanticDatepicker
            inverted={INVERTED_THEME}
            disabled={readonly}
            label={formatMessage(messages['to.label'])}
            error={toError}
            clearable={clearable}
            autoComplete='off'
            closable
            showToday={false}
            pointing={upward
              ? 'top left'
              : 'left'}
            minDate={noQuickRange && fromDate
              ? moment.utc(fromDate).local().toDate()
              : null}
            maxDate={moment().utc().local().toDate()}
            format={DATE_FORMAT_NO_TIME}
            date={toDate
              ? moment.utc(toDate).local().toDate()
              : fromDate
                ? moment.utc(fromDate).local().toDate()
                : moment.utc().local().toDate()}
            value={toDate && moment.utc(toDate).local().toDate()}
            onChange={(event, { value }) => {
              if (!value) {
                return onChangeToDate(value);
              }
              if (!noQuickRange) {
                const toMoment = moment(value).utc();
                const fromDate = toMoment.clone().subtract(moment.duration(quickRange)).utc().format(DATE_FORMAT);
                return onChangeDates(fromDate, toMoment.format(DATE_FORMAT));
              }
              return onChangeToDate(moment(value).utc().format(DATE_FORMAT));
            }}
            name='dateTime'
            placeholder={formatMessage(messages['to.placeholder'])}
            iconPosition='left'
            localization={locale} />
        </Form.Field>
        {includeTime && (
          <TimeInput
            label='&nbsp;'
            iconPosition='left'
            popupPosition={upward
              ? 'top left'
              : 'bottom left'}
            closable
            duration={0}
            error={toError}
            disabled={readonly || !toDate}
            pickerStyle={styles.timePicker}
            value={toDate
              ? moment.utc(toDate).local().format('HH:mm')
              : EMPTY_STRING}
            onChange={(event, { value }) => {
              const newToDate = moment.utc(toDate).local();
              const time = moment(value, 'HH:mm');
              newToDate.set({
                hour: time.get('hours'),
                minute: time.get('minute')
              });
              onChangeToDate(newToDate.utc().format(DATE_FORMAT));
            }} />
        )}
      </Form.Group>
    </Wrapper>
  );
});

DateRangePicker.displayName = 'DateRangePicker';

export default DateRangePicker;