import _ from 'lodash';
import { Fragment } from 'react';
import { defineMessages, InjectedIntl, injectIntl } from 'react-intl';
import { Button, Card, Icon, Label, LabelProps, Popup, Statistic } from 'semantic-ui-react';
import { COLOUR, ENTITY_TYPE, INVERTED_THEME, ROLE, VALUE_TYPE } from '../../utils/constants';
import { BarGauge } from '../chart/GaugeCard';
import { getIconFromSeverity } from '../../utils/helpers';
import QuerySyncLink from '../../containers/navigation/QuerySyncLink';
import { formatNumber } from 'components/chart/TimeSeriesChart/TooltipTableRow';
import ProgressLabel from 'components/shared/ProgressLabel';
import ModelPopup from 'components/shared/ModelPopup';
import UnitTypePopup from 'components/shared/UnitTypePopup/UnitTypePopup';
import DeleteEntityModalContainer from 'containers/entity/DeleteEntityModalContainer';
import { AlarmRange, Link } from 'types';
import buttonMessages from 'components/dashboard/ActionButton/ActionButton.messages';
import ActionButton from 'components/dashboard/ActionButton';

const messages = defineMessages({
  'noData': {
    id: 'dashboardCard.noData',
    defaultMessage: 'No data'
  },
  'noModel': {
    id: 'dashboardCard.noModel',
    defaultMessage: 'No model'
  }
});

const ALARM_DIAGRAM_SCALE = 0.8;

interface SeverityLabelProps {
  severity: number;
  fill: string;
}
function SeverityLabel({ severity, fill }: SeverityLabelProps) {
  return (
    <Label
      corner
      size='mini'
      icon={(
        <Icon
          name={getIconFromSeverity(severity)}
          style={{ color: fill }} />
      )} />
  );
}

interface ModelOutputCardRegionProps {
  outputValue: number;
  valueType: string;
  unitsOfMeasurement: string;
  alarmRanges: AlarmRange[];
  outputLabel: string;
  fill: string;
  className: string;
  intl: InjectedIntl;
}
const ModelOutputCardRegion = injectIntl(({
  outputValue,
  valueType,
  unitsOfMeasurement,
  alarmRanges,
  outputLabel,
  fill,
  className,
  intl: {
    formatMessage
  }
}: ModelOutputCardRegionProps) => (
  <Card.Content style={{ maxHeight: '110px' }}>
    <Label
      style={{
        background: fill,
        color: COLOUR.TEXT
      }}
      attached='top'
      size='mini'
      className={className}>
    </Label>
    {(_.isNull(outputValue) || _.isUndefined(outputValue))
      ? (
        <div style={{
          color: COLOUR.TEXT,
          textAlign: 'center',
          height: '120px',
          lineHeight: '70px'
        }}>
          <strong>
            {formatMessage(messages['noData'])}
          </strong>
        </div>
      )
      : (
        <>
          <div
            className='attached'
            style={{
              display: 'flex',
              justifyContent: 'center'
            }}>
            {valueType === VALUE_TYPE.FLOAT
              ? (
                <BarGauge
                  min={_.chain(alarmRanges).first().get('min').value() || 0}
                  max={_.chain(alarmRanges).last().get('max').value() || 0}
                  value={outputValue}
                  minNeedleWidth={0.02}
                  scale={ALARM_DIAGRAM_SCALE}
                  ranges={alarmRanges}
                  cursor='pointer' />
              )
              : (valueType === VALUE_TYPE.ENUMERATION)
                ? (
                  <div style={{
                    color: COLOUR.TEXT,
                    textAlign: 'center',
                    height: '40px',
                    lineHeight: '70px'
                  }} />
                )
                : null}
          </div>
          <Statistic.Group
            widths={1}
            size='small'>
            <Statistic inverted={INVERTED_THEME}>
              <Statistic.Label
                style={{
                  textTransform: 'none',
                  textAlign: 'center',
                  lineHeight: '2em',
                  fontSize: '1.2em'
                }}>
                {outputLabel ||
                  `${unitsOfMeasurement === '%'
                    ? Math.round(100 * outputValue)
                    : valueType === VALUE_TYPE.FLOAT
                      ? formatNumber(outputValue, 1)
                      : outputValue
                  }${unitsOfMeasurement}`}
              </Statistic.Label>
            </Statistic>
          </Statistic.Group>
        </>
      )}
  </Card.Content>
));

interface EntityLabelProps extends LabelProps {
  fontSize: string;
}
const EntityLabel = injectIntl(({
  label,
  fontSize,
  intl: {
    formatMessage
  }
}: EntityLabelProps) => (
  <Label
    style={{
      margin: 3,
      fontSize: fontSize
    }}
    {..._.pick(label, ['icon', 'color', 'detail'])}
    content={label.content || formatMessage({
      id: label.messageId,
      defaultMessage: label.messageDefaultValue
    })} />
));

interface ActionButtonProps {
  fontSize: string;
  onClick: () => void;
}

function MuteButton({
  fontSize,
  onClick
}: ActionButtonProps) {
  return (
    <ActionButton
      hoverMessage={buttonMessages['mute']}
      buttonProps={{
        icon: 'alarm',
        color: 'green'
      }}
      fontSize={fontSize}
      onClick={onClick} />
  );
}

function UnmuteButton({
  fontSize,
  onClick
}: ActionButtonProps) {
  return (
    <ActionButton
      hoverMessage={buttonMessages['unmute']}
      buttonProps={{
        icon: 'alarm mute',
        color: 'grey'
      }}
      fontSize={fontSize}
      onClick={onClick} />
  );
}

function DeleteButton({
  fontSize,
  onClick
}: ActionButtonProps) {
  return (
    <ActionButton
      userRole={ROLE.ADMIN}
      hoverMessage={buttonMessages['delete']}
      buttonProps={{
        negative: true,
        icon: 'trash'
      }}
      fontSize={fontSize}
      onClick={onClick} />
  );
}

function ConfigureButton({
  fontSize,
  onClick
}: ActionButtonProps) {
  return (
    <ActionButton
      userRole={ROLE.ADMIN}
      hoverMessage={buttonMessages['configuration']}
      buttonProps={{
        primary: true,
        icon: 'cog'
      }}
      fontSize={fontSize}
      onClick={onClick} />
  );
}

interface ActionButtonGroupProps {
  canMute: boolean;
  canDelete: boolean;
  canConfigure: boolean;
  entityType: string;
  active: boolean;
  onClickAlarm: () => void;
  onClickDelete: () => void;
  onClickConfiguration: () => void;
  fontSize: string;
}
function ActionButtonGroup({
  canMute,
  canDelete,
  canConfigure,
  active,
  onClickAlarm,
  onClickDelete,
  onClickConfiguration,
  fontSize
}: ActionButtonGroupProps) {
  return (
    <Button.Group
      floated='right'
      size='mini'>
      {canMute && (active
        ? (
          <MuteButton
            key='mute'
            fontSize={fontSize}
            onClick={onClickAlarm} />
        )
        : (
          <UnmuteButton
            key='unmute'
            fontSize={fontSize}
            onClick={onClickAlarm} />
        ))}
      {canDelete && (
        <DeleteButton
          key='delete'
          fontSize={fontSize}
          onClick={onClickDelete} />
      )}
      {canConfigure && (
        <ConfigureButton
          key='configure'
          onClick={onClickConfiguration}
          fontSize={fontSize} />
      )}
    </Button.Group>
  );
};

interface FooterLabelProps {
  footerLabel: LabelProps;
  onClickIconButton: (buttonName: string) => void;
  intl: InjectedIntl;
}
const FooterLabel = injectIntl(({
  footerLabel,
  onClickIconButton,
  intl: {
    formatMessage
  }
}: FooterLabelProps) => {
  if (footerLabel.progress) {
    return (
      <ProgressLabel
        attached='bottom'
        flex
        content={footerLabel.content || formatMessage({
          id: footerLabel.messageId,
          defaultMessage: footerLabel.messageDefaultValue
        })}
        onClickIconButton={onClickIconButton}
        {..._.pick(footerLabel, ['color', 'detail', 'percent', 'iconButtons'])}>
      </ProgressLabel>
    );
  }

  return (
    <Label
      attached='bottom'
      {..._.pick(footerLabel, ['color'])}>
      {
        footerLabel.content || formatMessage({
          id: footerLabel.messageId,
          defaultMessage: footerLabel.messageDefaultValue
        })
      }
      <Label.Detail>
        {footerLabel.detail}
      </Label.Detail>
      {
        _.map(footerLabel.iconButtons, button => (
          <Popup
            key={button.name}
            inverted={INVERTED_THEME}
            trigger={(
              <Icon
                name={button.loading
                  ? 'circle notch'
                  : button.icon}
                loading={button.loading}
                disabled={button.disabled}
                link={!button.disabled}
                onClick={(e: MouseEvent) => {
                  e.preventDefault();
                  e.stopPropagation();
                  if (button.disabled) {
                    return;
                  };
                  onClickIconButton(button.name);
                }}
                style={{
                  margin: '0em 0em 0em 0.75em',
                  float: 'right'
                }} />
            )}
            on='hover'
            position='top center'
            style={{
              background: COLOUR.MEDIUM_GREY,
              border: '1px solid white'
            }}>
            {formatMessage({
              id: button.popupMessageId,
              defaultMessage: button.popupDefaultMessage
            })}
          </Popup>
        ))
      }
    </Label>
  );
});

interface DashboardCardProps {
  id: string;
  linkTo: Link;
  name: string;
  description: string;
  footerLabel: LabelProps;
  labels: LabelProps[];
  valueType: string;
  outputValue: any;
  outputLabel: string;
  fill: string;
  active: boolean;
  alarmRanges: AlarmRange[];
  severity: number;
  noModel: boolean;
  onClickAlarm: () => void;
  onClickDelete: () => void;
  onClickConfiguration: () => void;
  onClickFooterLabelIconButton: (buttonName: string) => void;
  onDelete: () => void;
  loading: boolean;
  canMute: boolean;
  canDelete: boolean;
  canConfigure: boolean;
  entityType: string;
  unitsOfMeasurement: string;
  width: number;
  showDetails: boolean;
}
export default function DashboardCard({
  id,
  linkTo,
  name,
  description,
  footerLabel,
  labels,
  valueType,
  outputValue,
  outputLabel,
  fill,
  active,
  alarmRanges,
  severity,
  noModel,
  onClickAlarm,
  onClickDelete,
  onClickConfiguration,
  onClickFooterLabelIconButton,
  onDelete,
  canMute,
  canDelete,
  canConfigure,
  entityType,
  unitsOfMeasurement,
  width,
  showDetails
}: DashboardCardProps) {
  const className = `severity-${_.isNumber(severity) ? severity : 'noData'}`;
  const scale = (width / 150) ** 0.5;
  const labelFontSize = `${0.6 * scale}rem`;
  const buttonFontSize = `${0.53 * scale}rem`;

  return (
    <>
      <DeleteEntityModalContainer
        id={id}
        entityType={entityType}
        onDelete={onDelete} />
      <Card
        link
        as={QuerySyncLink}
        to={linkTo}
        style={{
          borderTopRightRadius: 0,
          fontSize: `${0.8 * scale}rem`,
          marginBottom: 0
        }}>
        {!noModel && showDetails && (
          <ModelOutputCardRegion
            outputValue={outputValue}
            valueType={valueType}
            unitsOfMeasurement={unitsOfMeasurement}
            alarmRanges={alarmRanges}
            outputLabel={outputLabel}
            fill={fill}
            className={className} />
        )}
        <Card.Content
          style={{
            backgroundColor: fill,
            color: COLOUR.TEXT
          }}
          className={className}>
          <SeverityLabel
            severity={severity}
            fill={fill} />
          <Card.Header style={{
            fontSize: '1.1em',
            wordWrap: 'break-word'
          }}>
            {name}
          </Card.Header>
          {showDetails && (
            <Card.Meta>
              {description}
            </Card.Meta>
          )}
        </Card.Content>
        {showDetails && (
          <Fragment>
            <Card.Content
              extra
              style={{
                backgroundColor: fill,
                color: COLOUR.TEXT,
                padding: '0.3em'
              }}>
              <ActionButtonGroup
                canMute={canMute}
                canDelete={canDelete}
                canConfigure={canConfigure}
                entityType={entityType}
                active={active}
                onClickAlarm={onClickAlarm}
                onClickDelete={onClickDelete}
                onClickConfiguration={onClickConfiguration}
                fontSize={buttonFontSize} />
              {_.some(labels) && (
                <Label.Group>
                  {_.map(labels, label => {
                    switch (label.type) {
                      case ENTITY_TYPE.MODEL:
                        return (
                          <ModelPopup
                            id={label.id}
                            key={label.id}
                            trigger={(
                              <span>
                                <EntityLabel
                                  label={label}
                                  fontSize={labelFontSize} />
                              </span>
                            )} />
                        );
                      case ENTITY_TYPE.UNIT_TYPE:
                        return (
                          <UnitTypePopup
                            id={label.id}
                            key={label.id}
                            trigger={(
                              <span>
                                <EntityLabel
                                  label={label}
                                  fontSize={labelFontSize} />
                              </span>
                            )} />
                        );
                      case ENTITY_TYPE.UNIT_CATEGORY:
                        return (
                          <EntityLabel
                            key={label.id}
                            label={label}
                            fontSize={labelFontSize} />
                        );
                      default:
                        return null;
                    }
                  })}
                </Label.Group>
              )}
              {footerLabel && (
                <FooterLabel
                  footerLabel={footerLabel}
                  onClickIconButton={onClickFooterLabelIconButton} />
              )}
            </Card.Content>
            {footerLabel && (
              <Card.Content
                extra
                style={{
                  backgroundColor: fill,
                  color: COLOUR.TEXT,
                  height: '22.5px',
                  padding: 0
                }} />
            )}
          </Fragment>
        )}
      </Card>
    </>
  );
}