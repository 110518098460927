import _ from 'lodash';
import moment, { Moment } from 'moment';
import Timeline from './Timeline';
import styles from './TrendTimeline.styles';
import { Trend, TrendType } from 'types';

export const timeToPercentage = (
  time: string,
  startMoment: Moment,
  endMoment: Moment
): number => {
  const percentage = 100 * moment(time).diff(startMoment, 'seconds') / endMoment.diff(startMoment, 'seconds');
  return percentage;
};

interface Props {
  startTime: string;
  endTime: string;
  trends: Trend[];
  currentTrendId?: string;
  allTrendTypes: { [id: string]: TrendType; };
  height: number;
}
export default function TrendTimeline({
  startTime,
  endTime,
  trends,
  currentTrendId,
  allTrendTypes,
  height
}: Props) {
  const startMoment = moment(startTime);
  const endMoment = moment(endTime);
  const items = _.chain(trends)
    .map(trend => ({
      start: timeToPercentage(trend.from, startMoment, endMoment),
      end: timeToPercentage(trend.to, startMoment, endMoment),
      trend,
      highlighted: currentTrendId === trend.id,
      isChild: trend.isChild || false
    }))
    .filter(trend => trend.end > 0 && trend.start < 100)
    .value();
  return (
    <div style={styles.root}>
      <Timeline
        items={items}
        allTrendTypes={allTrendTypes}
        height={height} />
    </div>
  );
};