import { Fragment } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Icon } from 'semantic-ui-react';
import uuid from 'uuid/v1';
import { ENTITY_TYPE, TYPED_LIST_ELEMENT_TYPE, ENTITY_CONFIG, ENTITY_FORM_ELEMENT_TYPE, ROLE } from '../../constants';
import textComponent from './textComponent';
import optionsSelector from './optionsSelector';

const messages = defineMessages({
  'title': {
    id: 'trendType.title',
    defaultMessage: 'Trend Types'
  },
  'columns.name.title': {
    id: 'trendType.columns.name.title',
    defaultMessage: 'Name'
  },
  'columns.icon.title': {
    id: 'trendType.columns.icon.title',
    defaultMessage: 'Icon'
  },
  'columns.colour.title': {
    id: 'trendType.columns.colour.title',
    defaultMessage: 'Colour'
  },
  'form.name.label': {
    id: 'trendType.form.name.label',
    defaultMessage: 'Name'
  },
  'form.name.placeholder': {
    id: 'trendType.form.name.placeholder',
    defaultMessage: 'Name'
  },
  'form.icon.label': {
    id: 'trendType.form.icon.label',
    defaultMessage: 'Icon'
  },
  'form.icon.placeholder': {
    id: 'trendType.form.icon.placeholder',
    defaultMessage: 'Icon'
  },
  'form.colour.label': {
    id: 'trendType.form.colour.label',
    defaultMessage: 'Colour'
  },
  'form.colour.placeholder': {
    id: 'trendType.form.colour.placeholder',
    defaultMessage: 'Colour'
  },
  'form.attributes.label': {
    id: 'unitType.form.attributes.label',
    defaultMessage: 'Attributes'
  }
});

export default {
  apiPath: 'trendtype',
  pageUrl: 'trendtypes',
  icon: 'chart bar',
  disableFetchTypes: true,
  fetchAllOnLoad: true,
  messages,
  textComponent,
  optionsSelector,
  viewRole: ROLE.VIEW_TREND_TYPE,
  modifyRole: ROLE.MODIFY_TREND_TYPE,
  [ENTITY_CONFIG.IMPORT]: true,
  columns: [
    {
      name: 'name',
      width: 3,
      render: ({ name }) => name
    },
    {
      name: 'icon',
      width: 3,
      render: ({ icon }) => (
        <Fragment>
          <Icon name={icon} /> <FormattedMessage id={`icon.${icon}`} />
        </Fragment>
      )
    },
    {
      name: 'colour',
      width: 3,
      render: ({ colour }) => (
        <Fragment>
          <Icon
            name='circle'
            color={colour} /> <FormattedMessage id={`colour.${colour}`} />
        </Fragment>
      )
    }
  ],
  form: [
    {
      name: 'name',
      type: ENTITY_FORM_ELEMENT_TYPE.INPUT
    },
    {
      name: 'icon',
      type: ENTITY_FORM_ELEMENT_TYPE.SELECT,
      selectListName: 'icons',
      search: true
    },
    {
      name: 'colour',
      type: ENTITY_FORM_ELEMENT_TYPE.SELECT,
      selectListName: 'colours',
      search: true
    },
    {
      name: 'attributes',
      type: ENTITY_FORM_ELEMENT_TYPE.LIST,
      form: [
        {
          name: 'name'
        },
        {
          name: 'valueType',
          selectListName: 'valueTypes'
        },
        {
          name: 'enumeration',
          type: TYPED_LIST_ELEMENT_TYPE.SELECT_ENTITY,
          entityType: ENTITY_TYPE.ENUMERATION,
          dependency: 'valueType.requiresEnumeration'
        },
        {
          name: 'inputType',
          selectListName: 'stringInputTypes',
          dependency: 'valueType.id = "string"'
        }
      ],
      getErrorPath: (index, name) => `attributes[${index}].${name}`,
      disableAddOnEdit: true
    }
  ],
  createEntity: values => ({
    id: uuid(),
    name: values.name,
    icon: values.icon,
    colour: values.colour,
    attributes: values.attributes || []
  }),
  populateForm: entity => ({
    name: entity.name,
    icon: entity.icon,
    colour: entity.colour,
    attributes: entity.attributes || []
  })
};