import _ from 'lodash';
import createCachedSelector from 're-reselect';
import { getFunctionAlarmRanges } from '../getFunctionAlarmRanges';
import { getFunctionData } from '../getFunctionData';
import { getFunctionSensorIds } from '../getFunctionSensorIds';
import { getFunctionSensors } from '../getFunctionSensors';
import getOffsetScale from './getOffsetScale';
import * as fromState from '../../../reducers';
import { ENTITY_PROPERTY } from '../../../../utils/constants';
import { getFunctionEnumerations } from '../getEnumerations';

export const getFunctionOffsetScale = createCachedSelector(
  [
    getFunctionSensorIds,
    getFunctionAlarmRanges,
    getFunctionData,
    getFunctionSensors,
    getFunctionEnumerations,
    fromState.getFeaturesToggle,
    fromState.getSelectedFeature
  ],
  (
    ids,
    alarmRanges,
    functionData,
    functionSensors,
    functionEnumerations,
    featuresToggle,
    selectedFeature
  ) => _.chain(ids)
    .map(id => [
      id,
      getOffsetScale(
        alarmRanges[id],
        functionData[id],
        _.get(functionSensors[id], ENTITY_PROPERTY.SENSOR.VALUE_TYPE),
        functionEnumerations[id],
        featuresToggle ? selectedFeature : null
      )
    ])
    .fromPairs()
    .value()
)(
  (state, chartType) => chartType
);