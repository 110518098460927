import LoadingModal from 'components/loading/LoadingModal';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import * as fromState from 'state/reducers';

const mapStateToProps = createSelector(
  [
    fromState.getIsLoading
  ],
  isLoading => ({
    isLoading
  })
);

export default connect(mapStateToProps)(LoadingModal);