import { Icon } from 'semantic-ui-react';
import { defineMessages, FormattedMessage } from 'react-intl';
import moment from 'moment';
import { DATE_FORMAT, ENTITY_FORM_ELEMENT_TYPE } from '../constants';

const messages = defineMessages({
  'title': {
    id: 'actionConnector.title',
    defaultMessage: 'Actions'
  },
  'description': {
    id: 'actionConnector.description',
    defaultMessage: 'Actions allow work orders to be created to action repairs'
  },
  'columns.name.title': {
    id: 'actionConnector.columns.name.title',
    defaultMessage: 'Name'
  },
  'columns.status.title': {
    id: 'actionConnector.columns.status.title',
    defaultMessage: 'Status'
  },
  'columns.status.pending': {
    id: 'actionConnector.columns.status.pending',
    defaultMessage: 'Pending'
  },
  'columns.status.validated': {
    id: 'actionConnector.columns.status.validated',
    defaultMessage: 'Validated'
  },
  'columns.status.invalid': {
    id: 'actionConnector.columns.status.invalid',
    defaultMessage: 'Invalid'
  },
  'columns.lastSeen.title': {
    id: 'actionConnector.columns.lastSeen.title',
    defaultMessage: 'Last Seen'
  },
  'columns.lastSeen.never': {
    id: 'actionConnector.columns.lastSeen.never',
    defaultMessage: 'Never'
  },
  'form.name.label': {
    id: 'actionConnector.form.name.label',
    defaultMessage: 'Name'
  },
  'form.name.placeholder': {
    id: 'actionConnector.form.name.placeholder',
    defaultMessage: 'Name'
  },
  'type.phalanx.name': {
    id: 'actionConnector.type.phalanx.name',
    defaultMessage: 'PHALANX Digital Operations Platform'
  },
  'type.phalanx.options.portalUrl.label': {
    id: 'actionConnector.type.phalanx.options.portalUrl.label',
    defaultMessage: 'Portal Url'
  },
  'type.phalanx.options.portalUrl.placeholder': {
    id: 'actionConnector.type.phalanx.options.portalUrl.placeholder',
    defaultMessage: 'Portal Url'
  },
  'type.phalanx.options.appId.label': {
    id: 'actionConnector.type.phalanx.options.appId.label',
    defaultMessage: 'App Id'
  },
  'type.phalanx.options.appId.placeholder': {
    id: 'actionConnector.type.phalanx.options.appId.placeholder',
    defaultMessage: 'App Id'
  },
  'type.phalanx.options.appSecret.label': {
    id: 'actionConnector.type.phalanx.options.appSecret.label',
    defaultMessage: 'App Secret'
  },
  'type.phalanx.options.appSecret.placeholder': {
    id: 'actionConnector.type.phalanx.options.appSecret.placeholder',
    defaultMessage: 'App Secret'
  },
  'type.phalanx.options.cookie.label': {
    id: 'actionConnector.type.phalanx.options.cookie.label',
    defaultMessage: 'Cookie'
  },
  'type.phalanx.options.cookie.placeholder': {
    id: 'actionConnector.type.phalanx.options.cookie.placeholder',
    defaultMessage: 'Cookie'
  }
});

const validationStatusValues = {
  0: {
    cell: 'warning',
    icon: 'attention',
    name: 'pending'
  },
  1: {
    cell: 'positive',
    icon: 'checkmark',
    name: 'validated'
  },
  2: {
    cell: 'error',
    icon: 'attention',
    name: 'invalid'
  }
};

export default {
  apiPath: 'actionconnector',
  pageUrl: 'actions',
  icon: 'wrench',
  messages,
  isConnector: true,
  hideMenu: true,
  columns: [
    {
      name: 'name',
      width: 3,
      render: ({ name }) => name
    },
    {
      name: 'status',
      width: 3,
      props: ({ validationStatus = 0 }) => ({ [validationStatusValues[validationStatus].cell]: true }),
      render: ({ validationStatus = 0 }) => (
        <div>
          <Icon name={validationStatusValues[validationStatus].icon} /><FormattedMessage {...messages[`columns.status.${validationStatusValues[validationStatus].name}`]} />
        </div>
      )
    },
    {
      name: 'lastSeen',
      width: 3,
      render: ({ lastSeen }) => lastSeen ? moment(lastSeen).format(DATE_FORMAT) : <FormattedMessage {...messages['columns.lastSeen.never']} />
    }
  ],
  form: [{
    name: 'name',
    type: ENTITY_FORM_ELEMENT_TYPE.INPUT
  }, {
    type: 'types'
  }],
  createEntity: values => ({
    id: values.name,
    name: values.name,
    type: values.type,
    settings: values.settings || {},
    unitId: null
  }),
  populateForm: entity => ({
    name: entity.name,
    type: entity.type,
    settings: entity.settings
  })
};