import _ from 'lodash';
import { defineMessages } from 'react-intl';
import optionsSelector from './optionsSelector';
import { ENTITY_FORM_ELEMENT_TYPE, ROLE } from '../../../utils/constants';

const messages = defineMessages({
  'title': {
    id: 'enumeration.title',
    defaultMessage: 'Enumerations'
  },
  'columns.name.title': {
    id: 'enumeration.columns.name.title',
    defaultMessage: 'Name'
  },
  'form.name.label': {
    id: 'enumeration.form.name.label',
    defaultMessage: 'Name'
  },
  'form.name.placeholder': {
    id: 'enumeration.form.name.placeholder',
    defaultMessage: 'Name'
  },
  'form.states.label': {
    id: 'enumeration.form.states.label',
    defaultMessage: 'States'
  }
});

export default {
  apiPath: 'enumeration',
  pageUrl: 'enumerations',
  icon: 'list alternate outline',
  messages,
  disableFetchTypes: true,
  fetchAllOnLoad: true,
  optionsSelector,
  viewRole: ROLE.VIEW_ENUMERATION,
  modifyRole: ROLE.MODIFY_ENUMERATION,
  columns: [
    {
      name: 'name',
      width: 3,
      render: ({ name }) => name
    }
  ],
  form: [
    {
      name: 'name',
      type: ENTITY_FORM_ELEMENT_TYPE.INPUT
    },
    {
      name: 'states',
      type: ENTITY_FORM_ELEMENT_TYPE.LIST,
      getErrorPath: index => `states[${index}]`,
      disableAddOnEdit: true,
      form: [
        {
          name: 'name'
        }
      ]
    }
  ],
  createEntity: values => ({
    id: values.name,
    name: values.name,
    states: _.map(values.states, ({ name }) => name),
    unitId: null
  }),
  populateForm: entity => ({
    name: entity.name,
    states: _.map(entity.states, name => ({
      name,
      disableRemove: true
    }))
  })
};