import _ from 'lodash';
import DashboardCard from 'components/dashboard/DashboardCard';
import { Accordion } from 'semantic-ui-react';
import { DASHBOARD_VIEW, ENTITY_PROPERTY, ENTITY_TYPE, INVERTED_THEME } from '../../../utils/constants';
import { useViewport } from '../../../utils/helpers';
import {
  UnitTypeCardContainer,
  UnitTypePredictorCardContainer,
  PredictorCardContainer,
  UnitTypeAttributeCardContainer,
  UnitTypeParameterCardContainer,
  UnitTypeInstanceCardContainer,
  UnitCardContainer,
  MuteModalContainer,
  UnmuteModalContainer,
  DashboardButtonBarContainer,
  DeckPanelContainer
} from 'containers/dashboard';

const DashboardChart = ({
  cards,
  sensorCards,
  view,
  cardSize,
  unitOrUnitTypeId,
  isTopLevel,
  showDetails
}) => {
  const {
    units,
    predictors,
    sensorGroups,
    unitTypes,
    unitTypePredictors,
    unitTypeAttributes,
    unitTypeParameters,
    unitTypeInstances,
    attributeGroups
  } = cards;
  const { width } = useViewport();
  const desiredCardWidth = [150, 200, 250][cardSize];
  const cardsPerRow = Math.min(Math.min(Math.floor(width / desiredCardWidth)), 10);
  const cardWidth = desiredCardWidth;

  return (
    <>
      <DashboardButtonBarContainer />
      <MuteModalContainer />
      <UnmuteModalContainer />
      <div className='page-content'>
        <Accordion
          inverted={INVERTED_THEME}
          exclusive={false}
          fluid>
          <DeckPanelContainer
            groupedItems={[sensorCards]}
            entityType={ENTITY_TYPE.SENSOR}
            isTopLevel={isTopLevel}
            sortBy={sensorCard => sensorCard.name}
            cardsPerRow={cardsPerRow}
            mapItemToCard={card => (
              <DashboardCard
                key={card.id}
                id={card.id}
                name={card.name}
                description={card.description}
                valueType={card.valueType}
                outputValue={card.outputValue}
                fill={card.fill}
                severity={card.severity}
                unitsOfMeasurement={card.unitsOfMeasurement}
                alarmRanges={card.alarmRanges}
                width={cardWidth}
                showDetails={showDetails}
                entityType={ENTITY_TYPE.SENSOR} />
            )} />
          {view === DASHBOARD_VIEW.UNIT_TYPES && (
            <DeckPanelContainer
              groupedItems={[unitTypeAttributes]}
              entityType={ENTITY_TYPE.UNIT_TYPE_ATTRIBUTE}
              canAdd={!_.isNull(unitOrUnitTypeId)}
              parentId={unitOrUnitTypeId}
              isTopLevel={isTopLevel}
              sortBy={attr => attr.name}
              cardsPerRow={cardsPerRow}
              mapItemToCard={unitTypeAttribute => (
                <UnitTypeAttributeCardContainer
                  key={unitTypeAttribute.id}
                  unitTypeAttribute={unitTypeAttribute}
                  width={cardWidth} />
              )} />
          )}
          {view === DASHBOARD_VIEW.UNIT_TYPES && (
            <DeckPanelContainer
              groupedItems={[unitTypeParameters]}
              entityType={ENTITY_TYPE.UNIT_TYPE_PARAMETER}
              canAdd={!_.isNull(unitOrUnitTypeId)}
              parentId={unitOrUnitTypeId}
              isTopLevel={isTopLevel}
              sortBy={attr => attr.name}
              cardsPerRow={cardsPerRow}
              mapItemToCard={unitTypeParameter => (
                <UnitTypeParameterCardContainer
                  key={unitTypeParameter.id}
                  unitTypeParameter={unitTypeParameter}
                  width={cardWidth} />
              )} />
          )}
          <DeckPanelContainer
            groupedItems={[predictors, sensorGroups]}
            entityType={ENTITY_TYPE.PREDICTOR}
            canAdd={view === DASHBOARD_VIEW.UNITS}
            parentId={unitOrUnitTypeId}
            isTopLevel={isTopLevel}
            sortBy={predictor => predictor.name}
            cardsPerRow={cardsPerRow}
            mapItemToCard={predictor => (
              <PredictorCardContainer
                key={predictor.id}
                predictor={predictor}
                view={view}
                width={cardWidth} />
            )} />
          <DeckPanelContainer
            groupedItems={[units]}
            entityType={ENTITY_TYPE.UNIT}
            isTopLevel={isTopLevel}
            canAdd
            parentId={unitOrUnitTypeId}
            sortBy={ENTITY_PROPERTY.UNIT.NAME}
            mapItemToCard={unit => (
              <UnitCardContainer
                key={unit.id}
                unit={unit}
                width={cardWidth} />
            )} />
          <DeckPanelContainer
            groupedItems={[unitTypePredictors, attributeGroups]}
            entityType={ENTITY_TYPE.UNIT_TYPE_PREDICTOR}
            canAdd={!_.isNull(unitOrUnitTypeId)}
            isTopLevel={isTopLevel}
            parentId={unitOrUnitTypeId}
            sortBy={ENTITY_PROPERTY.UNIT_TYPE_PREDICTOR.NAME}
            mapItemToCard={unitTypePredictor => (
              <UnitTypePredictorCardContainer
                key={unitTypePredictor.id}
                unitTypePredictor={unitTypePredictor}
                width={cardWidth} />
            )} />
          {unitTypes && !(isTopLevel && view === DASHBOARD_VIEW.UNITS) && (
            <DeckPanelContainer
              groupedItems={[unitTypes]}
              entityType={ENTITY_TYPE.UNIT_TYPE}
              canAdd={view === DASHBOARD_VIEW.UNIT_TYPES}
              isTopLevel={isTopLevel}
              parentId={unitOrUnitTypeId}
              sortBy={ENTITY_PROPERTY.UNIT_TYPE.NAME}
              mapItemToCard={unitType => (
                <UnitTypeCardContainer
                  key={unitType.id}
                  unitType={unitType}
                  width={cardWidth} />
              )} />
          )}
          {unitTypeInstances && view === DASHBOARD_VIEW.UNIT_TYPES && (
            <DeckPanelContainer
              groupedItems={[unitTypeInstances]}
              entityType={ENTITY_TYPE.UNIT_TYPE_INSTANCE}
              canImport
              importContext={{
                unitTypeId: unitOrUnitTypeId
              }}
              isTopLevel={isTopLevel}
              parentId={unitOrUnitTypeId}
              sortBy={ENTITY_PROPERTY.UNIT_TYPE_INSTANCE.UNIT}
              mapItemToCard={unitTypeInstance => (
                <UnitTypeInstanceCardContainer
                  key={unitTypeInstance.id}
                  unitTypeInstance={unitTypeInstance}
                  width={cardWidth} />
              )} />
          )}
        </Accordion>
      </div>
    </>
  );
};

DashboardChart.displayName = 'DashboardChart';

export default DashboardChart;