import moment from 'moment';
import { SET_BRUSH_TIMESTAMPS, SetBrushTimestampsAction } from '../../../../../actions/chart';

function hasType<T>(action, type): action is T {
  return action.type === type;
}

function initialState() {
  const toDate = moment().utc().local();
  const fromDate = toDate.subtract(1, 'days');
  return fromDate.unix();
}

export default (state: number = initialState(), action): number => {
  if (hasType<SetBrushTimestampsAction>(action, SET_BRUSH_TIMESTAMPS)) {
    return action.payload.startTimestamp;
  } else {
    return state;
  }
};

export const getStartTimestamp = (state: number): number => state;