import { defineMessages } from 'react-intl';

export default defineMessages({
  'timeSeriesPlot.resetZoomButton.text': {
    id: 'timeSeriesChart.timeSeriesPlot.resetZoomButton.text',
    defaultMessage: 'Reset Zoom'
  },
  'timeSeriesPlot.zoomButton.text': {
    id: 'timeSeriesChart.timeSeriesPlot.zoomButton.text',
    defaultMessage: 'Zoom'
  },
  'timeSeriesPlot.notesTextArea.placeholder': {
    id: 'timeSeriesChart.timeSeriesPlot.notesTextArea.placeholder',
    defaultMessage: 'Notes'
  }
});