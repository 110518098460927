import _ from 'lodash';
import moment from 'moment';
import { merge, Observable, of } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, mergeMap } from 'rxjs/operators';
import * as api from '../../../utils/api';
import { CHART_TYPE, ENTITY_PROPERTY, ENTITY_TYPE, PAGE_URL } from '../../../utils/constants';
import { setPredictors } from '../../actions/dashboard';
import { setMultipleLatest } from '../../actions/data';
import { fetchAllFulfilled, fetchConditionTypes, fetchTypes, insertMany } from '../../actions/entity';
import * as fromState from '../../reducers';
import { matchesPath, takeUntilAppReset } from '../../../utils/helpers';
import { RootState } from 'index';
import { Predictor } from 'types';

const shouldLoadPredictors = (state: RootState) => (
  fromState.getUser(state) &&
  (
    _.isEmpty(fromState.getAll<Predictor>(state, ENTITY_TYPE.PREDICTOR))
    || matchesPath(PAGE_URL.HOME, state.router.location.pathname)
    || matchesPath(PAGE_URL.UNIT, state.router.location.pathname)
  )
);

const getPredictorSensors = (predictor: Predictor) => _.map(
  [...predictor.inputMappings, ...predictor.functionMappings || []],
  ENTITY_PROPERTY.PREDICTOR.INPUT_MAPPING.SENSOR
);

export default (action$: Observable<any>, state$: Observable<RootState>) => state$.pipe(
  distinctUntilChanged((prev, curr) => shouldLoadPredictors(prev) === shouldLoadPredictors(curr)
    && fromState.getUser(prev) === fromState.getUser(curr)),
  filter(state => shouldLoadPredictors(state)),
  mergeMap(state => api.getAll({
    controller: _.toLower(ENTITY_TYPE.PREDICTOR),
    token: fromState.getUser(state).token
  }).pipe(
    takeUntilAppReset(action$),
    mergeMap((predictors: Predictor[]) => {
      const fetchPredictorOutputSensors$ = api.getMultiple({
        controller: _.toLower(ENTITY_TYPE.SENSOR),
        ids: _.chain(predictors)
          .flatMap(getPredictorSensors)
          .uniq()
          .value(),
        token: fromState.getUser(state).token
      }).pipe(
        takeUntilAppReset(action$),
        map(sensors => insertMany(ENTITY_TYPE.SENSOR, sensors)),
        catchError(api.onError)
      );

      const fetchPredictorOutputValues$ = api.getLatest({
        predictorIds: _.map(predictors, ENTITY_PROPERTY.PREDICTOR.ID),
        fromDate: moment.utc(fromState.getPlaybackTime(state))
          .subtract(moment.duration(fromState.getQuickRange(state, CHART_TYPE.MAIN)))
          .toISOString(),
        toDate: fromState.getPlaybackTime(state),
        token: fromState.getUser(state).token
      }).pipe(
        takeUntilAppReset(action$),
        mergeMap((response: any) => merge(
          of(
            setMultipleLatest(CHART_TYPE.MAIN, response)
          )
        )),
        catchError(api.onError)
      );

      return merge(
        of(
          fetchTypes(ENTITY_TYPE.TREND),
          fetchTypes(ENTITY_TYPE.MODEL),
          fetchTypes(ENTITY_TYPE.UNIT),
          fetchConditionTypes(ENTITY_TYPE.MODEL),
          fetchAllFulfilled(ENTITY_TYPE.PREDICTOR, predictors),
          setPredictors(_.map(predictors, ENTITY_PROPERTY.PREDICTOR.ID))
        ),
        fetchPredictorOutputSensors$,
        fetchPredictorOutputValues$
      );
    }),
    catchError(api.onError)
  ))
);