import addErrorResponse, * as fromAddErrorResponse from './addErrorResponse';
import allIds, * as fromAllIds from './allIds';
import allTypeIds, * as fromAllTypeIds from './allTypeIds';
import byId, * as fromById from './byId';
import editErrorResponse, * as fromEditErrorResponse from './editErrorResponse';
import formSearchResults, * as fromFormSearchResults from './formSearchResults';
import isAdding, * as fromIsAdding from './isAdding';
import isDeleting, * as fromIsDeleting from './isDeleting';
import isEditing, * as fromIsEditing from './isEditing';
import isLoading, * as fromIsLoading from './isLoading';
import isFetchingAll, * as fromIsFetchingAll from './isFetchingAll';
import page, * as fromPage from './page';
import pageSize, * as fromPageSize from './pageSize';
import total, * as fromTotal from './total';
import typesById, * as fromTypesById from './typesById';
import conditionTypes, * as fromConditionTypes from './conditionTypes';
import formValues, * as fromFormValues from './formValues';
import modal, * as fromModal from './modal';
import errorFields, * as fromErrorFields from './errorFields';
import accessToken, * as fromAccessToken from './accessToken';
import labels, * as fromLabels from './labels';
import isImporting, * as fromIsImporting from './isImporting';
import importError, * as fromImportError from './importError';
import deleteErrorResponse, * as fromDeleteErrorResponse from './deleteErrorResponse';
import sort, * as fromSort from './sort';
import editingId, * as fromEditingId from './editingId';
import { combineReducers } from 'redux';
import { ENTITY_MODAL, ENTITY_FORM_TYPE } from '../../../utils/constants';
import { Entity } from 'types';

const entityReducer = combineReducers({
  addErrorResponse,
  allIds,
  allTypeIds,
  byId,
  editErrorResponse,
  formSearchResults,
  isAdding,
  isDeleting,
  isEditing,
  isLoading,
  isFetchingAll,
  isImporting,
  page,
  pageSize,
  total,
  typesById,
  conditionTypes,
  formValues: combineReducers({
    [ENTITY_FORM_TYPE.ADD_OR_EDIT]: formValues(ENTITY_FORM_TYPE.ADD_OR_EDIT),
    [ENTITY_FORM_TYPE.SEARCH]: formValues(ENTITY_FORM_TYPE.SEARCH)
  }),
  modals: combineReducers({
    add: modal('add'),
    delete: modal('delete'),
    edit: modal('edit'),
    accessToken: modal('accessToken'),
    alarm: modal('alarm'),
    [ENTITY_MODAL.IMPORT]: modal(ENTITY_MODAL.IMPORT),
    [ENTITY_MODAL.ALTERNATIVE_EDIT]: modal(ENTITY_MODAL.ALTERNATIVE_EDIT)
  }),
  errorFields,
  accessToken,
  labels,
  importError,
  deleteErrorResponse,
  sort,
  editingId
});

export default entityType => (state, action) => {
  if (action.entityType && action.entityType !== entityType) {
    return state;
  }

  return entityReducer(state, action);
};

export const getAddErrorResponse = state => fromAddErrorResponse.getAddErrorResponse(state.addErrorResponse);
export const getAddErrorRequest = state => fromAddErrorResponse.getAddErrorRequest(state.addErrorResponse);
export const getAddErrorCodes = (state, errorPathPrefixes) => fromAddErrorResponse.getAddErrorCodes(state.addErrorResponse, errorPathPrefixes);
export const getAddErrorMessages = (state, errorPathPrefixes) => fromAddErrorResponse.getAddErrorMessages(state.addErrorResponse, errorPathPrefixes);
export const getAllIds = state => fromAllIds.getAllIds(state.allIds);
export const getAllTypeIds = state => fromAllTypeIds.getAllTypeIds(state.allTypeIds);
export const getById = <T extends Entity>(state, id) => fromById.getById<T>(state.byId, id);
export const getAll = <T extends Entity>(state) => fromById.getAll<T>(state.byId);
export const getEditErrorResponse = state => fromEditErrorResponse.getEditErrorResponse(state.editErrorResponse);
export const getEditErrorRequest = state => fromEditErrorResponse.getEditErrorRequest(state.editErrorResponse);
export const getEditErrorCodes = (state, errorPathPrefixes) => fromEditErrorResponse.getEditErrorCodes(state.editErrorResponse, errorPathPrefixes);
export const getEditErrorMessages = (state, errorPathPrefixes) => fromEditErrorResponse.getEditErrorMessages(state.editErrorResponse, errorPathPrefixes);
export const getAllFormSearchResults = state => fromFormSearchResults.getAllFormSearchResults(state.formSearchResults);
export const getIsAdding = state => fromIsAdding.getIsAdding(state.isAdding);
export const getIsDeleting = state => fromIsDeleting.getIsDeleting(state.isDeleting);
export const getIsEditing = state => fromIsEditing.getIsEditing(state.isEditing);
export const getIsLoading = state => fromIsLoading.getIsLoading(state.isLoading);
export const getIsFetchingAll = state => fromIsFetchingAll.getIsFetchingAll(state.isFetchingAll);
export const getPage = state => fromPage.getPage(state.page);
export const getPageSize = state => fromPageSize.getPageSize(state.pageSize);
export const getTotal = state => fromTotal.getTotal(state.total);
export const getAllTypes = state => fromTypesById.getAllTypes(state.typesById);
export const getTypesById = state => fromTypesById.getTypesById(state.typesById);
export const getTypeById = (state, id) => fromTypesById.getTypeById(state.typesById, id);
export const getConditionTypes = state => fromConditionTypes.getConditionTypes(state.conditionTypes);
export const getFormValue = (state, formType, key) => fromFormValues.getFormValue(state.formValues[formType], key);
export const getFormValues = (state, formType) => fromFormValues.getFormValues(state.formValues[formType]);
export const getFormSettings = (state, formType) => fromFormValues.getSettings(state.formValues[formType]);
export const getFormSetting = (state, formType, key) => fromFormValues.getSetting(state.formValues[formType], key);
export const getFormConditions = (state, formType) => fromFormValues.getConditions(state.formValues[formType]);
export const getFormConditionsFilter = (state, formType) => fromFormValues.getConditionsFilter(state.formValues[formType]);
export const getFormConditionsList = (state, formType) => fromFormValues.getConditionsList(state.formValues[formType]);
export const getModalId = (state, name) => fromModal.getId(state.modals[name]);
export const getModalOpen = (state, name) => fromModal.getOpen(state.modals[name]);
export const getModalOpenById = (state, name, id) => fromModal.getOpenById(state.modals[name], id);
export const getModals = state => state.modals;
export const getErrorFields = state => fromErrorFields.getErrorFields(state.errorFields);
export const getAccessToken = state => fromAccessToken.getAccessToken(state.accessToken);
export const getLabels = state => fromLabels.getLabels(state.labels);
export const getIsImporting = state => fromIsImporting.getIsImporting(state.isImporting);
export const getImportErrorCodes = state => fromImportError.getCodes(state.importError);
export const getImportErrorRow = state => fromImportError.getRow(state.importError);
export const getImportErrorTotal = state => fromImportError.getTotal(state.importError);
export const getDeleteErrorResponse = state => fromDeleteErrorResponse.getDeleteErrorResponse(state.deleteErrorResponse);
export const getDeleteErrorRequest = state => fromDeleteErrorResponse.getDeleteErrorRequest(state.deleteErrorResponse);
export const getDeleteErrorCodes = state => fromDeleteErrorResponse.getDeleteErrorCodes(state.deleteErrorResponse);
export const getDeleteErrorMessages = state => fromDeleteErrorResponse.getDeleteErrorMessages(state.deleteErrorResponse);
export const getSort = state => fromSort.getSort(state.sort);
export const getEditingId = state => fromEditingId.getEditingId(state.editingId);