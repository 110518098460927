import { SET_COMPARE_FROM_DATE } from '../../../actions/chart';

export default (state = null, action) => {
  switch (action.type) {
    case SET_COMPARE_FROM_DATE:
      return action.payload.value;
    default:
      return state;
  }
};

export const getFromDate = state => state;