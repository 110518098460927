import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toggleModal, importFile, downloadImportTemplate } from '../../state/actions/entity';
import EntityImportModal from '../../components/entity/EntityImportModal';
import { ENTITY_MODAL } from '../../utils/constants';
import * as fromState from '../../state/reducers';

const mapStateToProps = (state, { entityType }) => ({
  open: fromState.getModalOpen(state, entityType, ENTITY_MODAL.IMPORT),
  isImporting: fromState.getIsImporting(state, entityType),
  errorCodes: fromState.getImportErrorCodes(state, entityType),
  errorRow: fromState.getImportErrorRow(state, entityType),
  errorTotal: fromState.getImportErrorTotal(state, entityType)
});

const mapDispatchToProps = (dispatch, { entityType, parentId, context }) => bindActionCreators({
  performImport: file => importFile(entityType, file, parentId, context),
  onClose: () => toggleModal(entityType, ENTITY_MODAL.IMPORT),
  onDownloadTemplate: () => downloadImportTemplate(entityType, context)
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EntityImportModal);