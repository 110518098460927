import _ from 'lodash';
import { injectIntl } from 'react-intl';
import { Icon, Menu, Popup } from 'semantic-ui-react';
import IsAuthenticated from '../../containers/auth/IsAuthenticated';
import { COLOUR, INVERTED_THEME, PAGE_URL } from '../../utils/constants';
import entityConfig from '../../utils/entities';
import { matchesPath, toPath } from '../../utils/helpers';
import { pure } from 'recompose';
import QuerySyncLink from '../../containers/navigation/QuerySyncLink';
import IsInRole from 'containers/auth/IsInRole';

const items = _.chain(entityConfig)
  .toPairs()
  .filter(([entityType, config]) =>
    !config.parentEntityType &&
    config.pageUrl &&
    !config.hideMenu)
  .map(([entityType, config]) => ({
    to: toPath(PAGE_URL.ENTITY_PAGE, {
      pageUrl: config.pageUrl
    }),
    titleMessage: config.messages.title,
    isConnector: config.isConnector,
    icon: config.icon,
    entityType: entityType
  }))
  .value();

const MainMenu = injectIntl(({
  intl: {
    formatMessage
  },
  pathname
}) => {
  return (
    <Menu
      inverted={INVERTED_THEME}
      style={{
        height: '100%',
        borderRight: 'solid 1px #555'
      }}
      compact
      vertical
      color='black'
      fixed='top'
      icon>
      <Menu.Item style={{ height: '50px' }} />
      <IsAuthenticated>
        {/*<Menu.Item>
          <UnitDropdownMenuContainer />
        </Menu.Item>*/}
        {_.chain(items)
          .filter(i => !i.isConnector)
          .map(({ to, titleMessage, icon, entityType }) => (
            <IsInRole
              key={to}
              role={entityConfig[entityType].viewRole}>
              <Popup
                inverted={INVERTED_THEME}
                style={{
                  background: COLOUR.MEDIUM_GREY,
                  border: '1px solid white',
                  left: '56px',
                  width: '50px',
                  whiteSpace: 'nowrap'
                }}
                trigger={(
                  <Menu.Item
                    as={QuerySyncLink}
                    to={to}
                    active={matchesPath(pathname, to, { end: false })}>
                    <Icon name={icon} />
                  </Menu.Item>
                )}
                on='hover'
                position='right center'
                content={formatMessage(titleMessage)} />
            </IsInRole>
          ))
          .value()}
        {_.chain(items)
          .filter('isConnector')
          .map(({ to, titleMessage, icon }) => (
            <Popup
              key={to}
              inverted={INVERTED_THEME}
              style={{
                background: COLOUR.MEDIUM_GREY,
                border: '1px solid white',
                left: '56px',
                width: '50px'
              }}
              trigger={(
                <Menu.Item
                  as={QuerySyncLink}
                  to={to}
                  active={matchesPath(pathname, to, { end: false })}>
                  <Icon name={icon} />
                </Menu.Item>
              )}
              on='hover'
              position='right center'
              content={formatMessage(titleMessage)} />
          )).value()}
      </IsAuthenticated>
    </Menu>
  );
});

MainMenu.displayName = 'MainMenu';

export default pure(MainMenu);