import _ from 'lodash';
import { useState } from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { Form } from 'semantic-ui-react';

const messages = defineMessages({
  'placeholder.state': {
    id: 'enumerationStateElement.placeholder.state',
    defaultMessage: 'Select State...'
  },
  'placeholder.enumeration': {
    id: 'enumerationStateElement.placeholder.enumeration',
    defaultMessage: 'Select Enumeration...'
  },
  'option.change': {
    id: 'enumerationStateElement.option.change',
    defaultMessage: 'Change Enumeration...'
  }
});

const EnumerationStateElement = injectIntl(({
  enumerationId,
  allEnumerations,
  upward,
  value,
  error,
  label,
  placeholder,
  readOnly,
  multiple,
  onChange,
  intl: {
    formatMessage
  }
}) => {
  const [selectedEnumeration, setSelectedEnumeration] = useState();
  const enumeration = allEnumerations[enumerationId]
    || allEnumerations[_.get(value, 'enumeration')]
    || allEnumerations[selectedEnumeration];
  return (
    <Form.Select
      upward={!!upward}
      readOnly={readOnly}
      search
      error={!!error}
      multiple={!!enumeration && multiple}
      onChange={onChange
        ? (event, data) => {
          if (enumeration) {
            onChange(data.value === 'changeEnumeration'
              ? {
                state: null,
                enumeration: null
              }
              : {
                state: data.value,
                enumeration: enumeration.id
              });
            if (data.value === 'changeEnumeration') {
              setSelectedEnumeration(null);
            };
          } else {
            setSelectedEnumeration(data.value);
          }
        }
        : undefined}
      value={value ? value.state : null}
      label={label || null}
      placeholder={placeholder || (enumeration
        ? formatMessage(messages['placeholder.state'])
        : formatMessage(messages['placeholder.enumeration']))}
      options={enumeration
        ? [
          ..._.map(enumeration.states, (state, index) => ({
            key: index,
            text: state,
            value: index
          })),
          ...enumerationId
            ? []
            : [
              {
                key: 'changeEnumeration',
                text: formatMessage(messages['option.change']),
                value: 'changeEnumeration'
              }
            ]
        ]
        : _.map(_.values(allEnumerations), enumeration => ({
          key: enumeration.id,
          text: enumeration.name,
          value: enumeration.id
        }))
      } />
  );
});

EnumerationStateElement.displayName = 'EnumerationStateElement';

export default EnumerationStateElement;