import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import FunctionSensorsModal from '../../components/chart/FunctionSensorsModal';
import { setFunctionSensors } from '../../state/actions/chart';
import { toggleModal } from '../../state/actions/entity';
import * as fromState from '../../state/reducers';
import * as chartSelectors from '../../state/selectors/chart';
import { ENTITY_FORM_TYPE, ENTITY_MODAL, ENTITY_TYPE } from '../../utils/constants';

const mapStateToProps = (state, { chartType }) => ({
  open: fromState.getModalOpen(state, ENTITY_TYPE.PREDICTOR, ENTITY_MODAL.ALTERNATIVE_EDIT),
  selectedSensors: fromState.getFormValue(
    state,
    ENTITY_TYPE.PREDICTOR,
    ENTITY_FORM_TYPE.ADD_OR_EDIT,
    'additionalFunctionMappings'
  ),
  hiddenSensors: chartSelectors.getInputAndFunctionSensorIds(state, chartType),
  isSaving: fromState.getIsEditing(state, ENTITY_TYPE.PREDICTOR)
});

const mapDispatchToProps = (dispatch, { chartType }) => bindActionCreators({
  onClose: () => toggleModal(ENTITY_TYPE.PREDICTOR, ENTITY_MODAL.ALTERNATIVE_EDIT),
  onAdd: _.partial(setFunctionSensors, chartType)
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FunctionSensorsModal);