import { AUTH_TYPE } from '../../utils/constants';
import { defineMessages } from 'react-intl';
import AuthFormContainer from './AuthFormContainer';

const messages = defineMessages({
  'header.text': {
    id: 'resendForm.header.text',
    defaultMessage: 'Resend Confirmation'
  },
  'email.placeholder': {
    id: 'resendForm.email.placeholder',
    defaultMessage: 'Email Address'
  },
  'email.label': {
    id: 'resendForm.email.label',
    defaultMessage: 'Email Address'
  },
  'performButton.text': {
    id: 'resendForm.performButton.text',
    defaultMessage: 'Resend Confirmation Email'
  }
});

export default function ResendFormContainer() {
  return (
    <AuthFormContainer
      authType={AUTH_TYPE.RESEND}
      performIcon='mail'
      fields={[
        {
          icon: 'mail',
          key: 'email'
        }
      ]}
      messages={messages}>
    </AuthFormContainer>
  );
}