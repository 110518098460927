import { COLOUR } from '../../../utils/constants';

export default {
  span: {
    position: 'fixed',
    top: '0',
    right: '0',
    height: '39px'
  },
  nameLabel: {
    borderRadius: 0,
    height: '100%',
    background: COLOUR.HEADER,
    color: COLOUR.TEXT,
    borderLeft: 'solid 1px #555',
    margin: 0,
    padding: '.6em .8em',
    marginTop: '10px'
  },
  tenantLabel: {
    borderRadius: 0,
    background: COLOUR.HEADER,
    color: COLOUR.TEXT,
    height: '100%',
    margin: 0,
    padding: '.6em .8em',
    paddingRight: '20px',
    marginTop: '10px'
  }
};