import moment from 'moment';
import { of, timer } from 'rxjs';
import { filter, mergeMap, withLatestFrom } from 'rxjs/operators';
import { incrementPlaybackTime, setPlaybackMode } from '../../actions/playback';
import { PLAYBACK_MODE } from '../../../utils/constants';
import * as fromState from '../../reducers';

export default (action$, state$) => timer(0, 1000).pipe(
  withLatestFrom(state$),
  filter(([, state]) => fromState.getPlaybackIsPlaying(state)),
  mergeMap(([, state]) => {
    const playbackMode = fromState.getPlaybackMode(state);
    const now = moment.utc();
    const newTime = playbackMode === PLAYBACK_MODE.LIVE
      ? now
      : moment.utc(fromState.getPlaybackTime(state)).add(fromState.getPlaybackSpeed(state), 'seconds');

    if (playbackMode === PLAYBACK_MODE.HISTORICAL && newTime > now) {
      return of(
        incrementPlaybackTime(now.toISOString()),
        setPlaybackMode(PLAYBACK_MODE.LIVE)
      );
    };

    return of(
      incrementPlaybackTime(newTime.toISOString())
    );
  })
);