import { LOCATION_CHANGE, push } from 'connected-react-router';
import { ofType } from 'redux-observable';
import { concat, of } from 'rxjs';
import { delay, filter, mergeMapTo } from 'rxjs/operators';
import { PAGE_URL } from '../../../utils/constants';
import { logout, resetApp } from '../../actions/auth';

export default action$ => action$.pipe(
  ofType(LOCATION_CHANGE),
  filter(action => action.payload.location.pathname === PAGE_URL.LOGOUT),
  mergeMapTo(
    concat(
      of(
        logout(),
        resetApp()
      ),
      of(
        push(PAGE_URL.LOGIN)
      ).pipe(
        delay(100)
      )
    )
  )
);