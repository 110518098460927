import _ from 'lodash';
import { createSelector } from 'reselect';
import { ENTITY_TYPE } from '../../../utils/constants';
import * as fromState from '../../../state/reducers';

const getAttributes = (allUnitTypes, allUnitTypeAttributes, unitTypeId) => {
  const unitType = allUnitTypes[unitTypeId];
  return unitType
    ? [
      ..._.filter(allUnitTypeAttributes, attr => attr.unitType === unitTypeId),
      ...getAttributes(allUnitTypes, allUnitTypeAttributes, unitType.baseType)
    ]
    : [];
};

export default createSelector(
  [
    (state, { unitTypeId }) => unitTypeId,
    state => fromState.getAll(state, ENTITY_TYPE.UNIT_TYPE),
    state => fromState.getAll(state, ENTITY_TYPE.UNIT_TYPE_ATTRIBUTE)
  ],
  (unitTypeId, allUnitTypes, allUnitTypeAttributes) => {
    const attributes = unitTypeId
      ? getAttributes(allUnitTypes, allUnitTypeAttributes, unitTypeId)
      : allUnitTypeAttributes;

    return _.map(attributes, attr => ({
      key: attr.id,
      text: attr.name,
      value: attr.id
    }));
  }
);