import { defineMessages } from 'react-intl';

export default defineMessages({
  'xAxis': {
    id: 'explorerPage.xAxis',
    defaultMessage: 'X Axis'
  },
  'yAxis': {
    id: 'explorerPage.yAxis',
    defaultMessage: 'Y Axis'
  },
  'zAxis': {
    id: 'explorerPage.zAxis',
    defaultMessage: 'Z Axis'
  },
  'feature': {
    id: 'explorerPage.feature',
    defaultMessage: 'Feature'
  },
  'window': {
    id: 'explorerPage.window',
    defaultMessage: 'Window'
  },
  'unusedAxisLabel': {
    id: 'explorerPage.unusedAxisLabel',
    defaultMessage: 'None'
  }
});