import _ from 'lodash';
import moment from 'moment';
import { APPEND_MULTIPLE_RANGES, PREPEND_MULTIPLE_RANGES, SET_MULTIPLE_RANGES } from '../../actions/data';

const initialState = {
  fromDate: null,
  toDate: null,
  predictor: null,
  sensors: [],
  outputs: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_MULTIPLE_RANGES:
    case APPEND_MULTIPLE_RANGES:
    case PREPEND_MULTIPLE_RANGES:
      const [fromDate, toDate] = [
        action.type === APPEND_MULTIPLE_RANGES
          ? moment.utc(action.payload.dropBeforeTimestamp, 'X').toISOString()
          : action.payload.fromDate,
        action.type === PREPEND_MULTIPLE_RANGES
          ? moment.utc(action.payload.dropAfterTimestamp, 'X').toISOString()
          : action.payload.toDate
      ];
      const sensors = _.keys(action.payload.sensors);
      const outputs = _.chain(action.payload.outputs)
        .keys()
        .map(output => `${action.payload.group}/${output}`)
        .value();
      const datesAreUnchanged = fromDate === state.fromDate && toDate === state.toDate;
      return {
        fromDate,
        toDate,
        sensors: datesAreUnchanged
          ? _.union(state.sensors, sensors)
          : sensors,
        outputs: datesAreUnchanged
          ? _.union(state.outputs, outputs)
          : outputs,
        predictor: action.payload.group
      };
    default:
      return state;
  }
};

export const getFromDate = state => state.fromDate;
export const getToDate = state => state.toDate;
export const getSensors = state => state.sensors;
export const getOutputs = state => state.outputs;
export const getPredictor = state => state.predictor;