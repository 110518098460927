import createCachedSelector from 're-reselect';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TimeSeriesChart from '../../../components/chart/TimeSeriesChart';
import * as fromState from '../../../state/reducers';
import { setTrendTimelineHeight } from 'state/actions/chart';

const mapStateToProps = createCachedSelector(
  [
    (state, { chartType }) => fromState.getChartIsLoading(state, chartType),
    state => fromState.getTrendTimelineHeight(state)
  ],
  (isLoading, timelineHeight) => ({
    isLoading,
    timelineHeight
  })
)(
  (state, { chartType }) => chartType
);

const mapDispatchToProps = dispatch => bindActionCreators({
  setTimelineHeight: height => setTrendTimelineHeight(height)
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TimeSeriesChart);