import { VALUE_TYPE } from '../../utils/constants';
import SensorAlarmRangesButtonContainer from '../../containers/alarm/SensorAlarmRangesButtonContainer';
import SensorAlarmLevelsButtonContainer from '../../containers/alarm/SensorAlarmLevelsButtonContainer';

const SensorAlarmButton = ({
  id,
  valueType,
  label
}) => {
  if (valueType === VALUE_TYPE.FLOAT) {
    return (
      <SensorAlarmRangesButtonContainer
        id={id}
        label={label} />
    );
  };
  if (valueType === VALUE_TYPE.ENUMERATION) {
    return (
      <SensorAlarmLevelsButtonContainer
        id={id}
        label={label} />
    );
  };
  return null;
};

SensorAlarmButton.displayName = 'SensorAlarmButton';

export default SensorAlarmButton;