import _ from 'lodash';
import moment from 'moment';
import createCachedSelector from 're-reselect';
import * as chartSelectors from '../../../state/selectors/chart';
import { DATE_FORMAT, ENTITY_PROPERTY, SENSOR_VALUE_DATE_FORMAT, VALUE_TYPE } from '../../../utils/constants';

const sensorValueToPoint = ([timestamp, { latitude, longitude }]) => ({
  timestamp: moment(timestamp, SENSOR_VALUE_DATE_FORMAT).format(DATE_FORMAT),
  position: [latitude, longitude]
});

export default createCachedSelector(
  [
    chartSelectors.getInputs,
    chartSelectors.getInputSensors,
    chartSelectors.getInputData,
    chartSelectors.getOutputs,
    chartSelectors.getOutputProperties,
    chartSelectors.getOutputData,
    chartSelectors.getFunctionSensorIds,
    chartSelectors.getFunctionSensors,
    chartSelectors.getFunctionData
  ],
  (
    inputs,
    inputSensors,
    inputData,
    outputs,
    outputProperties,
    outputData,
    functionSensorIds,
    functionSensors,
    functionData
  ) => {
    return [
      ..._.chain(inputs)
        .filter(input => _.get(inputSensors[input], ENTITY_PROPERTY.SENSOR.VALUE_TYPE) === VALUE_TYPE.GEO_POINT)
        .map(input => ({
          name: input,
          points: _.map(inputData[input], sensorValueToPoint)
        }))
        .filter(({ points }) => _.some(points))
        .value(),
      ..._.chain(outputs)
        .filter(output => _.get(outputProperties[output], ENTITY_PROPERTY.SENSOR.VALUE_TYPE) === VALUE_TYPE.GEO_POINT)
        .map(output => ({
          name: output,
          points: _.map(outputData[output], sensorValueToPoint)
        }))
        .filter(({ points }) => _.some(points))
        .value(),
      ..._.chain(functionSensorIds)
        .filter(id => _.get(functionSensors[id], ENTITY_PROPERTY.SENSOR.VALUE_TYPE) === VALUE_TYPE.GEO_POINT)
        .map(id => ({
          name: _.get(functionSensors[id], ENTITY_PROPERTY.SENSOR.DESCRIPTION)
            || _.get(functionSensors[id], ENTITY_PROPERTY.SENSOR.NAME),
          points: _.map(functionData[id], sensorValueToPoint)
        }))
        .filter(({ points }) => _.some(points))
        .value()
    ];
  }
)(
  (state, chartType) => chartType
);