import { SET_PREDICTORS, PREDICTOR_LOADED } from '../../actions/dashboard';
import { DELETE_BY_ID_FULFILLED } from '../../actions/entity';
import _ from 'lodash';

export default (state = {}, action) => {
  switch (action.type) {
    case SET_PREDICTORS:
      return {
        ...state,
        [action.payload.unitId]: action.payload.predictors
      };
    case DELETE_BY_ID_FULFILLED:
      if (action.entityType !== 'predictor') {
        return state;
      }
      return _.mapValues(state, predictors => _.without(predictors, action.payload.id));
    case PREDICTOR_LOADED:
      return _.mapValues(state, predictors => _.without(predictors, action.payload.id));
    default:
      return state;
  }
};

export const getLoadingPredictors = (state, unitId) => state[unitId];