import { Pagination } from 'semantic-ui-react';
import { INVERTED_THEME } from '../../utils/constants';

const EntityPagination = ({
  total,
  pageSize,
  page,
  pageChanged,
  query,
  parentId
}) => total / pageSize > 1 && (
  <div style={{ textAlign: 'center' }}>
    <Pagination
      inverted={INVERTED_THEME}
      activePage={page + 1}
      totalPages={Math.ceil(total / pageSize)}
      onPageChange={(event, data) => pageChanged(data.activePage - 1, parentId, query)} />
  </div>
);

EntityPagination.displayName = 'EntityPagination';

export default EntityPagination;