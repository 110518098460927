import { Icon } from 'semantic-ui-react';
import moment from 'moment';
import { defineMessages, FormattedMessage } from 'react-intl';
import { DATE_FORMAT, ENTITY_FORM_ELEMENT_TYPE, ROLE } from '../../constants';
import textComponent from './textComponent';

const messages = defineMessages({
  'title': {
    id: 'sensorConnector.title',
    defaultMessage: 'Sensors'
  },
  'description': {
    id: 'sensorConnector.description',
    defaultMessage: 'Sensors provide the IoT data upon which predictions are made'
  },
  'columns.name.title': {
    id: 'sensorConnector.columns.name.title',
    defaultMessage: 'Name'
  },
  'columns.status.title': {
    id: 'sensorConnector.columns.status.title',
    defaultMessage: 'Status'
  },
  'columns.status.pending': {
    id: 'sensorConnector.columns.status.pending',
    defaultMessage: 'Pending'
  },
  'columns.status.validated': {
    id: 'sensorConnector.columns.status.validated',
    defaultMessage: 'Validated'
  },
  'columns.status.invalid': {
    id: 'sensorConnector.columns.status.invalid',
    defaultMessage: 'Invalid'
  },
  'columns.lastSeen.title': {
    id: 'sensorConnector.columns.lastSeen.title',
    defaultMessage: 'Last Seen'
  },
  'columns.lastSeen.never': {
    id: 'sensorConnector.columns.lastSeen.never',
    defaultMessage: 'Never'
  },
  'form.name.label': {
    id: 'sensorConnector.form.name.label',
    defaultMessage: 'Name'
  },
  'form.name.placeholder': {
    id: 'sensorConnector.form.name.placeholder',
    defaultMessage: 'Name'
  },
  'type.api.name': {
    id: 'sensorConnector.type.api.name',
    defaultMessage: 'API'
  },
  'type.pi.name': {
    id: 'sensorConnector.type.pi.name',
    defaultMessage: 'OSISoft PI System'
  },
  'type.phalanx.name': {
    id: 'sensorConnector.type.phalanx.name',
    defaultMessage: 'PHALANX Digital Operations Platform'
  },
  'type.aemp.name': {
    id: 'sensorConnector.type.aemp.name',
    defaultMessage: 'AEMP'
  },
  'type.prophes.name': {
    id: 'sensorConnector.type.prophes.name',
    defaultMessage: 'PROPHES'
  }
});

const validationStatusValues = {
  0: {
    cell: 'warning',
    icon: 'attention',
    name: 'pending'
  },
  1: {
    cell: 'positive',
    icon: 'checkmark',
    name: 'validated'
  },
  2: {
    cell: 'error',
    icon: 'attention',
    name: 'invalid'
  }
};

export default {
  apiPath: 'sensorconnector',
  pageUrl: 'sensors',
  navigateOnAdd: true,
  navigateOnClick: true,
  icon: 'thermometer half',
  textComponent,
  messages,
  isConnector: true,
  viewRole: ROLE.VIEW_SENSOR,
  modifyRole: ROLE.ADMIN,
  columns: [
    {
      name: 'name',
      width: 3,
      render: ({ name }) => name
    },
    {
      name: 'status',
      width: 3,
      props: ({ validationStatus = 0 }) => ({ [validationStatusValues[validationStatus].cell]: true }),
      render: ({ validationStatus = 0 }) => (
        <div>
          <Icon name={validationStatusValues[validationStatus].icon} /><FormattedMessage {...messages[`columns.status.${validationStatusValues[validationStatus].name}`]} />
        </div>
      )
    },
    {
      name: 'lastSeen',
      width: 3,
      render: ({ lastSeen }) => lastSeen ? moment(lastSeen).format(DATE_FORMAT) : <FormattedMessage {...messages['columns.lastSeen.never']} />
    }
  ],
  form: [{
    name: 'name',
    type: ENTITY_FORM_ELEMENT_TYPE.INPUT
  }, {
    type: 'types'
  }],
  createEntity: values => ({
    id: values.name,
    name: values.name,
    type: values.type,
    settings: values.settings || {},
    unitId: null
  }),
  populateForm: entity => ({
    name: entity.name,
    type: entity.type,
    settings: entity.settings
  })
};