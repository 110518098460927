import { Fragment } from 'react';
import { Image } from 'semantic-ui-react';

const styles = {
  image: {
    marginRight: '0.8125rem'
  }
};

export default function Avatar({
  imageSrc,
  text
}) {
  return (
    <Fragment>
      <Image
        avatar
        style={styles.image}
        src={imageSrc} />
      {text}
    </Fragment>
  );
};