import _ from 'lodash';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { Curve, Rectangle } from 'recharts';
import { COLOUR, ENTITY_PROPERTY } from '../../../utils/constants';
import { useSelector } from 'react-redux';
import { getHoverLine } from 'state/reducers';
import createCachedSelector from 're-reselect';
import * as chartSelectors from 'state/selectors/chart';

const selector = createCachedSelector(
  getHoverLine,
  chartSelectors.getFunctionDescriptions,
  chartSelectors.getFunctionSensors,
  chartSelectors.getOutputProperties,
  (hoverLine, functionDescriptions, functionSensors, outputProperties) => functionDescriptions[hoverLine]
    || _.get(functionSensors, `${hoverLine}.${ENTITY_PROPERTY.SENSOR.NAME}`)
    || _.get(outputProperties, `${hoverLine}.${ENTITY_PROPERTY.MODEL.OUTPUT.NAME}`)
    || hoverLine
)(
  (state, chartType) => chartType
);

const Y_AXIS_WIDTH = 65;
const CustomCursor = props => {
  const {
    points,
    height,
    widthRatio,
    showHighlightedWindow,
    width,
    chartType
  } = props;
  const hoverText = useSelector(state => selector(state, chartType));
  const x = _.first(points).x;
  const xLeft = x - widthRatio * width;
  return (
    <g>
      {showHighlightedWindow && (
        <Fragment>
          <Rectangle
            x={_.max([xLeft, Y_AXIS_WIDTH])}
            y={_.first(points).y}
            width={_.min([widthRatio * width, _.first(points).x - Y_AXIS_WIDTH])}
            height={height}
            fill={COLOUR.MEDIUM_GREY}
            opacity={0.2}
            stroke='none'
            pointerEvents='none' />
          {xLeft > Y_AXIS_WIDTH && (
            <Curve
              {...props}
              points={_.map(points, point => ({
                x: xLeft,
                y: point.y
              }))}
              pointerEvents='none' />
          )}
        </Fragment>
      )}
      <Curve
        {...props}
        pointerEvents='none' />
      <text
        x={x}
        y={12}
        fontSize='12'
        fill='white'>
        <tspan
          x={x}
          dx='0.355em'
          dy='0.355em'>
          {hoverText}
        </tspan>
      </text>
    </g>
  );
};

CustomCursor.displayName = 'CustomCursor';

CustomCursor.propTypes = {
  ...Curve.propTypes,
  showHighlightedWindow: PropTypes.bool.isRequired,
  widthRatio: PropTypes.number.isRequired
};

export default CustomCursor;