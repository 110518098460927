import { AUTH_TYPE } from '../../utils/constants';
import { defineMessages } from 'react-intl';
import AuthFormContainer from './AuthFormContainer';

const messages = defineMessages({
  'header.text': {
    id: 'changeForm.header.text',
    defaultMessage: 'Change Password'
  },
  'password.placeholder': {
    id: 'changeForm.password.placeholder',
    defaultMessage: 'New Password'
  },
  'password.label': {
    id: 'changeForm.password.label',
    defaultMessage: 'New Password'
  },
  'performButton.text': {
    id: 'changeForm.performButton.text',
    defaultMessage: 'Save New Password'
  }
});

export default function ChangeFormContainer() {
  return (
    <AuthFormContainer
      authType={AUTH_TYPE.CHANGE}
      performIcon='sign in'
      fields={[
        {
          icon: 'key',
          key: 'password',
          type: 'password'
        }
      ]}
      messages={messages}>
    </AuthFormContainer>
  );
}