import { SET_IS_SAVING } from '../../../actions/dashboard';

export default (state = false, action) => {
  switch (action.type) {
    case SET_IS_SAVING:
      return action.payload.value;
    default:
      return state;
  }
};

export const getIsSaving = state => state;