import { defineMessages } from 'react-intl';

export default defineMessages({
  'header.text': {
    id: 'alarmLevelsModal.header.text',
    defaultMessage: 'Alarm Levels'
  },
  'cancelButton.text': {
    id: 'alarmLevelsModal.cancelButton.text',
    defaultMessage: 'Cancel'
  },
  'saveButton.text': {
    id: 'alarmLevelsModal.saveButton.text',
    defaultMessage: 'Save'
  }
});