import { PROPERTY } from '../../../../../utils/constants';
import { SET_SELECTED_PROPERTY } from '../../../../actions/chart';

export default (state = PROPERTY.VALUE, action) => {
  switch (action.type) {
    case SET_SELECTED_PROPERTY:
      return action.payload.value;
    default:
      return state;
  };
};

export const getSelectedProperty = state => state;