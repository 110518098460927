import _ from 'lodash';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { FETCH_FORM_SEARCH_RESULTS_FULFILLED, insert } from '../../actions/entity';

export default action$ => action$.pipe(
  ofType(FETCH_FORM_SEARCH_RESULTS_FULFILLED),
  mergeMap(action =>
    from(action.payload.results).pipe(
      map(_.partial(insert, action.payload.searchEntityType))
    ))
);