import { AUTH_TYPE } from '../../utils/constants';
import { defineMessages } from 'react-intl';
import AuthFormContainer from './AuthFormContainer';

const messages = defineMessages({
  'header.text': {
    id: 'forgotForm.header.text',
    defaultMessage: 'Forgot Password'
  },
  'email.placeholder': {
    id: 'forgotForm.email.placeholder',
    defaultMessage: 'Email Address'
  },
  'email.label': {
    id: 'forgotForm.email.label',
    defaultMessage: 'Email Address'
  },
  'performButton.text': {
    id: 'forgotForm.performButton.text',
    defaultMessage: 'Send Reset Link'
  }
});

export default function ForgotFormContainer() {
  return (
    <AuthFormContainer
      authType={AUTH_TYPE.FORGOT}
      performIcon='mail'
      fields={[
        {
          icon: 'mail',
          key: 'email'
        }
      ]}
      messages={messages}>
    </AuthFormContainer>
  );
}