import { SET_ALARM_RANGES, EDIT_ALARM_RANGE, REMOVE_ALARM_RANGE, ADD_ALARM_RANGE } from '../../actions/alarm';
import _ from 'lodash';

export default (state = [], action) => {
  switch (action.type) {
    case SET_ALARM_RANGES:
      return action.payload.alarmRanges;
    case EDIT_ALARM_RANGE:
      return [
        ...state.slice(0, action.payload.index),
        {
          ...state[action.payload.index],
          [action.payload.key]: action.payload.value
        },
        ...state.slice(action.payload.index + 1)
      ];
    case REMOVE_ALARM_RANGE:
      return [
        ...state.slice(0, action.payload.index),
        ...state.slice(action.payload.index + 1)
      ];
    case ADD_ALARM_RANGE:
      return [
        ...state,
        action.payload.alarmRange
      ];
    default:
      return state;
  }
};

export const getAlarmRanges = state => state;
export const getAlarmRangesValid = state => _.chain(state)
  .zip(_.tail(state))
  .every(([prev, next]) => {
    return _.isNumber(prev.max) && _.isNumber(prev.min) && prev.max > prev.min && (!next || prev.max === next.min);
  })
  .value();