import { SET_SHOW_CARD_DETAILS } from '../../actions/dashboard';

export default (state = true, action) => {
  switch (action.type) {
    case SET_SHOW_CARD_DETAILS:
      return action.payload.value;
    default: 
      return state;
  }
};

export const getShowCardDetails = state => state;