import { Statistic } from 'semantic-ui-react';
import { INVERTED_THEME } from '../../../utils/constants';
import styles from './GaugeCard.styles';
import { formatNumber } from '../TimeSeriesChart/TooltipTableRow';

const unitShorthand = {
  'mbar': ' mbar',
  'DEG C': '°C',
  'DEG. C': '°C',
  'bar': ' bar'
};

const ValueStatistic = ({
  scale,
  value,
  unitsOfMeasurement
}) => (
  <Statistic.Group
    widths={1}
    size='small'>
    <Statistic inverted={INVERTED_THEME}>
      <Statistic.Label style={styles.valueStatisticLabel}>
        {`${formatNumber(value, scale)}${unitShorthand[unitsOfMeasurement] || unitsOfMeasurement}`}
      </Statistic.Label>
    </Statistic>
  </Statistic.Group>
);

ValueStatistic.displayName = 'ValueStatistic';

export default ValueStatistic;