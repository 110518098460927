import _ from 'lodash';
import createCachedSelector from 're-reselect';
import * as fromState from '../../../state/reducers';
import { getOutputAlarmRanges, getOutputDetails, getOutputs, getOutputProperties, getOutputEnumerations } from '../../../state/selectors/chart';
import { SELECTION_TYPE, ENTITY_PROPERTY } from '../../../utils/constants';

export default createCachedSelector(
  [
    getOutputs,
    _.partialRight(fromState.getSelected, SELECTION_TYPE.OUTPUT),
    getOutputAlarmRanges,
    getOutputDetails,
    getOutputProperties,
    getOutputEnumerations
  ],
  (
    outputs,
    selectedOutputs,
    outputAlarmRanges,
    outputDetails,
    outputProperties,
    outputEnumerations
  ) => _.map(outputs, id => ({
    id,
    name: _.get(outputProperties[id], ENTITY_PROPERTY.MODEL.OUTPUT.NAME) || id,
    description: undefined,
    ranges: outputAlarmRanges[id],
    unitsOfMeasurement: _.get(outputProperties[id], ENTITY_PROPERTY.MODEL.OUTPUT.UNITS_OF_MEASUREMENT),
    valueType: _.get(outputProperties[id], ENTITY_PROPERTY.SENSOR.VALUE_TYPE),
    selected: _.includes(selectedOutputs, id),
    min: outputDetails[id].min,
    max: outputDetails[id].max,
    value: outputEnumerations[id]
      ? outputEnumerations[id].states[outputDetails[id].value]
      : outputDetails[id].value,
    noData: outputDetails[id].noData,
    severity: outputDetails[id].severity,
    scale: _.get(outputProperties[id], ENTITY_PROPERTY.MODEL.OUTPUT.UNITS_OF_MEASUREMENT) === '%' ? 100 : 1
  }))
)(
  (state, chartType) => chartType
);