import moment from 'moment';
import createCachedSelector from 're-reselect';
import { ENTITY_CONFIG, ENTITY_FORM_TYPE } from '../../../../utils/constants';
import entityConfig from '../../../../utils/entities';
import * as fromState from '../../../reducers';

export const getSearchQuery = createCachedSelector(
  [
    (state, entityType) => fromState.getFormValues(state, entityType, ENTITY_FORM_TYPE.SEARCH),
    (state, entityType) => fromState.getSort(state, entityType),
    (state, entityType) => entityType
  ],
  (formValues, sort, entityType) => {
    if (!entityConfig[entityType][ENTITY_CONFIG.CREATE_SEARCH_QUERY]) {
      return null;
    }

    const query = entityConfig[entityType][ENTITY_CONFIG.CREATE_SEARCH_QUERY](formValues, sort);
    return {
      ...query,
      timestamp: moment.utc().format('x')
    };
  }
)(
  (state, entityType) => entityType
);