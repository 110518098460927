import { FETCH_TYPES_FULFILLED } from '../../actions/entity';
import _ from 'lodash';

export default (state = [], action) => {
  switch (action.type) {
    case FETCH_TYPES_FULFILLED:
      return _.map(action.payload.types, 'id');
    default:
      return state;
  }
};

export const getAllTypeIds = state => state;