import _ from 'lodash';
import { createSelector } from 'reselect';
import * as fromState from '../../../state/reducers';
import { ENTITY_TYPE, ENTITY_PROPERTY, ENTITY_FORM_TYPE } from '../../constants';

export default createSelector(
  [
    state => fromState.getFormValue(state, ENTITY_TYPE.UNIT_TYPE_ALERT_MAPPING, ENTITY_FORM_TYPE.ADD_OR_EDIT, 'connector'),
    state => fromState.getAll(state, ENTITY_TYPE.ALERT_CONNECTOR)
  ],
  (connector, allAlertConnectors) => _.get(allAlertConnectors[connector], ENTITY_PROPERTY.ALERT_CONNECTOR.TYPE)
);