import { ofType } from 'redux-observable';
import { concat, of } from 'rxjs';
import { delay, mergeMapTo } from 'rxjs/operators';
import { PAGE_URL } from '../../../utils/constants';
import { resetApp, UNAUTHORISED } from '../../actions/auth';
import { push } from 'connected-react-router';

export default action$ => action$.pipe(
  ofType(UNAUTHORISED),
  mergeMapTo(
    concat(
      of(
        resetApp()
      ),
      of(
        push(PAGE_URL.LOGIN)
      ).pipe(
        delay(100)
      )
    )
  )
);