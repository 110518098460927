import moment from 'moment';
import { defineMessages, injectIntl } from 'react-intl';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { Button, Form, Modal } from 'semantic-ui-react';
import { DATE_FORMAT_NO_TIME, INVERTED_THEME } from '../../utils/constants';
import AnimatedButton from '../shared/AnimatedButton';

const messages = defineMessages({
  'header.text': {
    id: 'muteModal.header.text',
    defaultMessage: 'Mute'
  },
  'cancelButton.text': {
    id: 'muteModal.cancelButton.text',
    defaultMessage: 'Cancel'
  },
  'muteButton.text': {
    id: 'muteModal.muteButton.text',
    defaultMessage: 'Mute'
  },
  'until.label': {
    id: 'muteModal.until.label',
    defaultMessage: 'Until'
  },
  'until.placeholder': {
    id: 'muteModal.until.placeholder',
    defaultMessage: 'Until'
  }
});

const MuteModal = injectIntl(({
  open,
  id,
  until,
  onClose,
  onChangeUntil,
  onMute,
  isSaving,
  intl: {
    formatMessage,
    locale
  }
}) => (
  <Modal
    open={open}
    onClose={onClose}
    centered
    closeOnDimmerClick={false}
    closeIcon
    size='small'
    closeOnEscape>
    <Modal.Header content={formatMessage(messages['header.text'])} />
    <Modal.Content
      scrolling
      style={{ height: '60vh' }}>
      <Form
        inverted={INVERTED_THEME}
        className='force-fluid'>
        <SemanticDatepicker
          closable
          inverted={INVERTED_THEME}
          value={until
            ? moment.utc(until).local().toDate()
            : null}
          label={formatMessage(messages['until.label'])}
          showToday={false}
          autoComplete='off'
          minDate={moment().local().toDate()}
          format={DATE_FORMAT_NO_TIME}
          name='dateTime'
          placeholder={formatMessage(messages['until.placeholder'])}
          iconPosition='left'
          onChange={(event, { value }) => {
            const newValue = moment(value).utc().toISOString();
            if (newValue !== until) {
              onChangeUntil(newValue);
            };
          }}
          localization={locale} />
      </Form>
    </Modal.Content>
    <Modal.Actions>
      <Button
        content={formatMessage(messages['cancelButton.text'])}
        onClick={onClose} />
      <AnimatedButton
        text={formatMessage(messages['muteButton.text'])}
        icon='alarm mute'
        disabled={!until}
        loading={isSaving}
        onClick={() => onMute(id, until)}
        positive />
    </Modal.Actions>
  </Modal>
));

MuteModal.displayName = 'MuteModal';

export default MuteModal;