import _ from 'lodash';
import { COLOUR, SEVERITY_COLOURS, SEVERITY_COLOURS_BRIGHT } from '../../constants';

interface Props {
  bright?: boolean;
  defaultColour?: string;
}
export default function getColourFromSeverity(
  severity: number | null,
  { bright, defaultColour }: Props = {}
): string {
  const colorLookup = bright ? SEVERITY_COLOURS_BRIGHT : SEVERITY_COLOURS;
  const finalFallbackColour = bright ? 'grey' : COLOUR.GREY;

  if (severity === null || !_.has(colorLookup, severity)) {
    return defaultColour || finalFallbackColour;
  } else {
    return colorLookup[severity];
  }
}