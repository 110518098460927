import { PERFORM_AUTH, PERFORM_AUTH_FULFILLED, PERFORM_AUTH_ERROR } from '../../../actions/auth';
import _ from 'lodash';

export default (state = null, action) => {
  switch (action.type) {
    case PERFORM_AUTH:
    case PERFORM_AUTH_FULFILLED:
      return null;
    case PERFORM_AUTH_ERROR:
      return action.payload.err;
    default:
      return state;
  }
};

export const getErrorResponse = state => state;
export const getErrorCodes = state => _.chain(state)
  .get('response.errors')
  .flatMap()
  .map('code')
  .value();