import moment from 'moment';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { filter, mergeMap, withLatestFrom } from 'rxjs/operators';
import { DATE_FORMAT } from '../../../utils/constants';
import { setDates, SET_QUICK_RANGE } from '../../actions/chart';
import * as fromState from '../../reducers';
import { setChartIsLoading } from '../../actions/chart';

export default (action$, state$) => action$.pipe(
  ofType(SET_QUICK_RANGE),
  filter(action => action.payload.value !== null),
  withLatestFrom(state$),
  mergeMap(([action, state]) => {
    const duration = moment.duration(action.payload.value);
    const toDate = fromState.getToDate(state, action.chartType);
    const toMoment = toDate ? moment(toDate, DATE_FORMAT) : moment();
    const fromDate = toMoment.clone().subtract(duration);
    return of(
      setChartIsLoading(action.chartType, true),
      setDates(
        action.chartType,
        fromDate.format(DATE_FORMAT),
        toMoment.format(DATE_FORMAT)
      )
    );
  })
);