import _ from 'lodash';
import { createSelector } from 'reselect';
import * as fromState from '../../../state/reducers';
import * as entitySelectors from '../../../state/selectors/entity';
import { EMPTY_STRING, ENTITY_TYPE, UNIT_PATH_SEPARATOR } from '../../constants';

export default createSelector(
  [
    state => fromState.getAll(state, ENTITY_TYPE.PREDICTOR),
    state => entitySelectors.getUnitPaths(state)
  ],
  (
    allPredictors,
    unitPaths
  ) => _.chain(allPredictors)
    .toPairs()
    .map(([id, predictor]) => ({
      key: id,
      text: `${unitPaths[predictor.unitId] || EMPTY_STRING}${UNIT_PATH_SEPARATOR}${predictor.name}`,
      value: id
    }))
    .sortBy('text')
    .value()
);