import _ from 'lodash';
import { ofType } from 'redux-observable';
import { merge, of } from 'rxjs';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { insert } from '../../actions/entity';
import * as api from '../../../utils/api';
import * as fromState from '../../reducers';
import { ENTITY_CONFIG } from '../../../utils/constants';
import { SAVE_PREDICTOR_ALARM_RANGES, setAlarmModalIsOpen, setAlarmIsSaving } from '../../actions/alarm';
import entityConfig from '../../../utils/entities';
import { takeUntilAppReset } from 'utils/helpers';

export default (action$, state$) => action$.pipe(
  ofType(SAVE_PREDICTOR_ALARM_RANGES),
  withLatestFrom(state$),
  mergeMap(([action, state]) => {
    const predictor = _.cloneDeep(fromState.getById(state, action.payload.entityType, action.payload.id));
    predictor.outputMappings[action.payload.output].alarmRanges = action.payload.alarmRanges;
    return merge(
      of(
        setAlarmIsSaving(true)      
      ),
      api.edit({
        controller: entityConfig[action.payload.entityType][ENTITY_CONFIG.API_PATH],
        entity: predictor,
        token: fromState.getUser(state).token
      }).pipe(
        takeUntilAppReset(action$),
        mergeMap(({ response }) => of(
          setAlarmModalIsOpen(false),
          setAlarmIsSaving(false),
          insert(action.payload.entityType, response)
        )),
        catchError(api.onError)
      )
    );
  })
);