import Avatar from 'components/shared/Avatar';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from 'semantic-ui-react';
import { URL } from 'utils/api';
import { ENTITY_TYPE } from '../../constants';
import * as fromState from 'state/reducers';
import { fetchById } from 'state/actions/entity';

export default function UserMappingText({
  id
}) {
  const userMapping = useSelector(
    state => fromState.getById(state, ENTITY_TYPE.USER_MAPPING, id)
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (id && !userMapping) {
      dispatch(fetchById(ENTITY_TYPE.USER_MAPPING, id));
    };
  }, [id, userMapping, dispatch]);
  if (!id) {
    return null;
  };
  return userMapping
    ? (
      <Avatar
        imageSrc={`${URL}api/user/${id}/image`}
        text={userMapping.name} />
    )
    : (
      <Loader
        inline
        active />
    );
};