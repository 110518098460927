import { ADD_FULFILLED, ADD } from '../../actions/entity';

export default (state = null, action) => {
  switch (action.type) {
    case ADD_FULFILLED:
      return action.payload.entity.token || null;
    case ADD:
      return null;
    default:
      return state;
  }
};

export const getAccessToken = state => state;