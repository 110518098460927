import { CARD_SIZE } from 'utils/constants';
import { SET_CARD_SIZE } from '../../actions/dashboard';

export default (state = CARD_SIZE.LARGE, action) => {
  switch (action.type) {
    case SET_CARD_SIZE:
      return action.payload.value;
    default: 
      return state;
  }
};

export const getCardSize = state => state;