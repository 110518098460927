import { ofType } from 'redux-observable';
import { QUERY_SYNC_PUSH } from '../../actions/navigation';
import { withLatestFrom, map } from 'rxjs/operators';
import { push } from 'connected-react-router';
import { addSyncedSearchToLocation } from '../../../utils/helpers';

export default (action$, state$) => action$.pipe(
  ofType(QUERY_SYNC_PUSH),
  withLatestFrom(state$),
  map(([action, state]) => push(
    addSyncedSearchToLocation(action.payload.value, state.router.location.search)
  ))
);