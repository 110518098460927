import PropTypes from 'prop-types';
import { formatValue } from './TooltipTableRow';
import { VALUE_TYPE } from '../../../utils/constants';
import _ from 'lodash';

const CustomYAxisTick = ({
  valueScale,
  scale,
  offset,
  unitsOfMeasurement,
  valueType,
  hoverLine,
  ...props
}) => {
  if (!hoverLine) {
    return null;
  };

  if (valueType !== VALUE_TYPE.FLOAT) {
    return null;
  };

  return (
    <text
      fill='white'
      height={props.height}
      textAnchor={props.textAnchor}
      x={props.x}
      y={props.y}
      width={props.width}
      fontSize='12'
      transform={`rotate(-45, ${props.x}, ${props.y})`}>
      <tspan
        x={props.x}
        dy='0.355em'>
        {formatValue(props.payload.value, valueScale, scale, offset, unitsOfMeasurement, valueType)}
      </tspan>
    </text>
  );
};

CustomYAxisTick.propTypes = {
  valueScale: PropTypes.number,
  scale: PropTypes.number,
  offset: PropTypes.number,
  unitsOfMeasurement: PropTypes.string,
  valueType: PropTypes.oneOf(_.values(VALUE_TYPE)),
  hoverLine: PropTypes.string,
  fill: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired,
  textAnchor: PropTypes.string.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  payload: PropTypes.shape({
    value: PropTypes.number
  })
};

CustomYAxisTick.displayName = 'CustomYAxisTick';

export default CustomYAxisTick;