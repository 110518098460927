import { injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Item, Popup } from 'semantic-ui-react';
import * as fromState from 'state/reducers';
import { EMPTY_STRING, ENTITY_TYPE, INVERTED_THEME } from 'utils/constants';
import messages from './UnitTypePopup.messages';
import styles from './UnitTypePopup.styles';

export default injectIntl(function UnitTypePopup({
  id,
  key,
  trigger,
  intl: {
    formatMessage
  }
}) {
  const unitType = useSelector(
    state => fromState.getById(state, ENTITY_TYPE.UNIT_TYPE, id)
  );
  const baseType = useSelector(
    state => fromState.getById(state, ENTITY_TYPE.UNIT_TYPE, unitType.baseType)
  );

  if (!unitType) {
    return trigger;
  }
  return (
    <Popup
      inverted={INVERTED_THEME}
      style={styles.popup}
      key={key}
      content={(
        <Item.Group
          className={INVERTED_THEME
            ? 'inverted'
            : EMPTY_STRING}>
          <Item>
            <Item.Content>
              <Item.Header>
                {unitType.name}
              </Item.Header>
              <Item.Meta>
                {formatMessage(messages['description.header'])}
              </Item.Meta>
              {unitType.description && unitType.description !== '' && (
                <Item.Description>
                  {unitType.description}
                </Item.Description>
              )}
              <Item.Meta>
                {formatMessage(messages['baseType.header'])}
              </Item.Meta>
              {unitType.baseType && (
                <Item.Description>
                  {baseType.name}
                </Item.Description>
              )}
            </Item.Content>
          </Item>
        </Item.Group>
      )}
      trigger={trigger} />
  );
});