import { SET_MUTE_MODAL } from '../../../actions/dashboard';

export default (state = null, action) => {
  switch (action.type) {
    case SET_MUTE_MODAL:
      return action.payload.open ? action.payload.id : null;
    default:
      return state;
  }
};

export const getId = state => state;