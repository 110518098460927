import { ofType } from 'redux-observable';
import { from, merge, of } from 'rxjs';
import { map, mergeMap, withLatestFrom, catchError, delay } from 'rxjs/operators';
import * as api from '../../../utils/api';
import { ENTITY_CONFIG, ENTITY_MODAL } from '../../../utils/constants';
import { deleteByIdFulfilled, DELETE_BY_QUERY, setIsDeleting, performSearch, toggleModal, deleteError } from '../../actions/entity';
import * as fromState from '../../reducers';
import * as entitySelectors from '../../selectors/entity';
import entityConfig from '../../../utils/entities';
import { takeUntilAppReset } from 'utils/helpers';

export default (action$, state$) => action$.pipe(
  ofType(DELETE_BY_QUERY),
  withLatestFrom(state$),
  mergeMap(([action, state]) => 
    api.deleteByQuery({
      controller: entityConfig[action.entityType][ENTITY_CONFIG.API_PATH],
      prefix: entityConfig[action.entityType][ENTITY_CONFIG.API_PREFIX],
      parentId: action.payload.parentId,
      query: entitySelectors.getSearchQuery(state, action.entityType),
      token: fromState.getUser(state).token
    }).pipe(
      takeUntilAppReset(action$),
      delay(2000),
      mergeMap(deletedIds => merge(
        of(
          toggleModal(action.entityType, ENTITY_MODAL.DELETE),
          setIsDeleting(action.entityType, false)
        ),
        from(deletedIds).pipe(
          map(id => deleteByIdFulfilled(action.entityType, id))
        ),
        of(
          performSearch(action.entityType, action.payload.parentId)
        )
      )),
      catchError((err, caught) => {
        if (err.status === 400) {
          return of(deleteError(action.entityType, err));
        }
        return api.onError(err, caught);
      })
    ))
);