import { ofType } from 'redux-observable';
import { filter, map, withLatestFrom } from 'rxjs/operators';
import { EDIT_FULFILLED, toggleModal } from '../../actions/entity';
import * as fromState from '../../reducers';

export default (action$, state$) => action$.pipe(
  ofType(EDIT_FULFILLED),
  withLatestFrom(state$),
  filter(([action, state]) => fromState.getModalOpen(state, action.entityType, 'alarm')),
  map(([action, state]) => toggleModal(action.entityType, 'alarm'))
);