import _ from 'lodash';
import { Bar, BarChart } from 'recharts';
import { getColourFromSeverity } from '../../utils/helpers';
import { DEFAULT_BAR_GAUGE_HEIGHT, DEFAULT_BAR_GAUGE_WIDTH } from '../chart/GaugeCard/BarGauge';

const DEFAULT_WIDTH = DEFAULT_BAR_GAUGE_WIDTH;
const DEFAULT_HEIGHT = DEFAULT_BAR_GAUGE_HEIGHT;
const INACTIVE_HEIGHT = 0.25;
const BAR_CATEGORY_GAP = 0;
const DATA_KEY = 'value';

const AlarmLevelsDiagram = ({
  alarmLevels,
  activeStates,
  scale = 1
}) => {
  const width = DEFAULT_WIDTH * scale;
  const height = DEFAULT_HEIGHT * scale;
  return (
    <BarChart
      width={width}
      height={height}
      data={_.map(alarmLevels, (severity, index) => ({
        [DATA_KEY]: _.includes(activeStates, index)
          ? 1
          : INACTIVE_HEIGHT,
        fill: getColourFromSeverity(severity)
      }))}
      barCategoryGap={BAR_CATEGORY_GAP}>
      <Bar
        dataKey={DATA_KEY}
        isAnimationActive={false} />
    </BarChart>
  );
};

AlarmLevelsDiagram.displayName = 'AlarmLevelsDiagram';

export default AlarmLevelsDiagram;