import brush, * as fromBrush from './brush';
import hoverLine, * as fromHoverLine from './hoverLine';
import { combineReducers } from 'redux';
import { RootState } from 'index';
import { HoverLineState } from './hoverLine';

export default combineReducers({
  brush,
  hoverLine
});

export const getBrushStartTimestamp = state => fromBrush.getStartTimestamp(state.brush);
export const getBrushEndTimestamp = state => fromBrush.getEndTimestamp(state.brush);
export const getHoverLine = (state: RootState): HoverLineState => fromHoverLine.getHoverLine(state.hoverLine);