import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import AlarmLevelsModal from '../../components/alarm/AlarmLevelsModal';
import * as fromState from '../../state/reducers';
import { ENTITY_TYPE } from '../../utils/constants';
import { setAlarmModalIsOpen } from '../../state/actions/alarm';

const mapStateToProps = createSelector(
  [
    state => fromState.getAlarmModalIsOpen(state),
    state => fromState.getAlarmLevels(state),
    state => fromState.getAlarmIsSaving(state),
    (state, { id, entityType }) => fromState.getById(state, entityType, id),
    state => fromState.getAll(state, ENTITY_TYPE.MODEL),
    state => fromState.getAll(state, ENTITY_TYPE.ENUMERATION),
    (state, { index }) => index
  ],
  (
    open,
    alarmLevels,
    isSaving,
    predictor,
    allModels,
    allEnumerations,
    index
  ) => ({
    open,
    alarmLevels,
    isSaving,
    states: predictor
      && allModels[predictor.type]
      && allEnumerations[allModels[predictor.type].outputs[index].enumeration]
      && allEnumerations[allModels[predictor.type].outputs[index].enumeration].states
  })
);

const mapDispatchToProps = {
  onClose: () => setAlarmModalIsOpen(false)
};

export default connect(mapStateToProps, mapDispatchToProps)(AlarmLevelsModal);