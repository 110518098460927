import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import SelectEntityElement from '../../components/entity/SelectEntityElement';
import entityConfig from '../../utils/entities';

const mapStateToProps = createSelector(
  [
    (state, props) => entityConfig[props.entityType].optionsSelector(state, props)
  ],
  (
    options
  ) => ({
    options
  })
);

export default connect(mapStateToProps)(SelectEntityElement);