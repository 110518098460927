import { ofType } from 'redux-observable';
import { map, withLatestFrom } from 'rxjs/operators';
import { edit, SAVE_ALARM_RANGES } from '../../actions/entity';
import * as fromState from '../../reducers';

export default (action$, state$) => action$.pipe(
  ofType(SAVE_ALARM_RANGES),
  withLatestFrom(state$),
  map(([action, state]) => edit(action.entityType, {
    ...fromState.getById(state, action.entityType, action.payload.id),
    alarmRanges: action.payload.alarmRanges
  }))
);