import { COLOUR } from '../../../utils/constants';

export default {
  diagramWrapperDiv: {
    display: 'flex',
    justifyContent: 'center'
  },
  label: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    color: COLOUR.TEXT,
    background: COLOUR.LIGHT_GREY,
    width: '50%'
  },
  dropdownIcon: color => ({
    color
  }),
  dropdown: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    background: COLOUR.LIGHT_GREY,
    color: COLOUR.TEXT,
    cursor: 'pointer',
    width: '50%'
  },
  dropdownMenuIcon: color => ({
    color
  })
};