import { createSelector } from 'reselect';
import * as fromState from '../../../reducers';
import { getUnitId } from '../getUnitId';
import _ from 'lodash';
import { ENTITY_TYPE, SENSOR_TYPE } from '../../../../utils/constants';
import { Sensor, Predictor } from 'types';

/** Returns all sensors that need to be loaded to display the current dashboard page,
 * when that page is for a unit.
 */
export const getUnitSensors = createSelector(
  [
    state => getUnitId(state),
    state => fromState.getAll<Sensor>(state, ENTITY_TYPE.SENSOR),
    state => fromState.getAll<Predictor>(state, ENTITY_TYPE.PREDICTOR)
  ],
  (unitId, allSensors, allPredictors) => {
    const sensorsOnThisUnit = _.filter(allSensors, sensor =>
      sensor.unitId === unitId &&
      sensor.type !== SENSOR_TYPE.PREDICTOR_OUTPUT);

    const predictorsOnThisUnit = _.filter(
      _.values(allPredictors),
      predictor => predictor.unitId === unitId
    );

    const inputsOfPredictorsOnThisUnit = _.chain(predictorsOnThisUnit)
      .flatMap(predictor => predictor.inputMappings)
      .map(inputMapping => allSensors[inputMapping.sensor])
      .value();

    const outputsOfPredictorsOnThisUnit = _.flatMap(
      predictorsOnThisUnit,
      predictor => _.map(predictor.outputMappings, outputMapping =>
        allSensors[`${predictor.id}/${outputMapping.output}`])
    );

    return _.compact(_.uniq(_.concat(
      sensorsOnThisUnit,
      inputsOfPredictorsOnThisUnit,
      outputsOfPredictorsOnThisUnit
    )));
  }
);