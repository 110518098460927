import _ from 'lodash';
import uuid from 'uuid/v1';
import { ENTITY_TYPE } from '../../constants';
import actionMapping from '../actionMapping';
import parentTypeSelector from './parentTypeSelector';
import selectContextListsSelector from './selectContextListsSelector';

export default {
  apiPath: 'unittypeactionmapping',
  pageUrl: 'actions',
  parentEntityType: ENTITY_TYPE.UNIT_TYPE_PREDICTOR,
  labels: true,
  messages: actionMapping.messages,
  parentTypeSelector,
  selectContextListsSelector,
  parentSearchFilter: parentId => ({
    UnitTypePredictor: {
      $eq: parentId
    }
  }),
  formValueSearchSchema: actionMapping.formValueSearchSchema,
  columns: actionMapping.columns,
  form: actionMapping.form,
  createEntity: (values, unitTypePredictor) => ({
    id: uuid(),
    name: values.name,
    unitTypePredictor: unitTypePredictor.id,
    trendType: _.get(values, 'trendType.value'),
    connector: values.connector,
    unitId: null,
    type: values.connector
      ? values.type
      : null,
    settings: values.settings || {}
  }),
  populateForm: actionMapping.populateForm
};