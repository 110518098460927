import { AUTH_TYPE } from '../../utils/constants';
import { defineMessages } from 'react-intl';
import AuthFormContainer from './AuthFormContainer';

const messages = defineMessages({
  'header.text': {
    id: 'confirmForm.header.text',
    defaultMessage: 'Confirm Email Address'
  },
  'performButton.text': {
    id: 'confirmForm.performButton.text',
    defaultMessage: 'Confirm'
  }
});

export default function ConfirmFormContainer() {
  return (
    <AuthFormContainer
      authType={AUTH_TYPE.CONFIRM}
      performIcon='sign in'
      fields={[]}
      messages={messages}>
    </AuthFormContainer>
  );
}