import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { ENTITY_CONFIG, EMPTY_STRING } from '../../../utils/constants';
import { fetchFormSearchResults, SET_FORM_VALUES } from '../../actions/entity';
import entityConfig from '../../../utils/entities';

export default action$ => action$.pipe(
  ofType(SET_FORM_VALUES),
  filter(action => entityConfig[action.entityType][ENTITY_CONFIG.FORM_VALUE_SEARCH_SCHEMA]),
  mergeMap(action => from(entityConfig[action.entityType][ENTITY_CONFIG.FORM_VALUE_SEARCH_SCHEMA]).pipe(
    map(searchSchema => fetchFormSearchResults(
      action.entityType,
      false,
      searchSchema.name,
      action.payload.formValues[searchSchema.name] || EMPTY_STRING,
      searchSchema.entityType,
      searchSchema.searchFilter,
      entityConfig[searchSchema.entityType][ENTITY_CONFIG.FETCH_BY_ID_SCHEMA]
    ))
  ))
);