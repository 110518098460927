import _ from 'lodash';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { injectIntl } from 'react-intl';
import { Button, Checkbox, Form, Header, Icon, Popup, Radio, Table } from 'semantic-ui-react';
import FunctionSensorsModalContainer from '../../../../containers/chart/FunctionSensorsModalContainer';
import { CHART_TYPE, FEATURE, INVERTED_THEME, SELECTION_TYPE, PROPERTY, FEATURE_SETTINGS, VALUE_TYPE } from '../../../../utils/constants';
import featuresMessages from '../../../../utils/i18n/shared/features';
import messages from '../ChartKey.messages';
import styles from '../ChartKey.styles';
import { CHART_KEY_HEADER_CHECKBOX, CHART_KEY_HEADER_FEATURES_TOGGLE, CHART_KEY_HEADER_FEATURE_DROPDOWN, CHART_KEY_HEADER_FEATURE_WINDOW_DROPDOWN } from './ChartKeyHeader.testIds';
import { messages as dateRangeMessages, quickRanges } from 'utils/dates';

const ChartKeyHeader = injectIntl(({
  itemGroups,
  chartType,
  showFeaturesToggle,
  featuresToggle,
  selectedFeature,
  featureWindow,
  selectedProperty,
  isLoading,
  onSetSelected,
  onOpenFunctionSensorsModal,
  onSetFeaturesToggle,
  onSetSelectedFeature,
  onSetFeatureWindow,
  onSetSelectedProperty,
  intl: {
    formatMessage
  }
}) => {
  const allSelected = _.every(_.flatten(itemGroups), item => item.selected);
  const noneSelected = !_.some(_.flatten(itemGroups), item => item.selected);
  return (
    <Header
      inverted={INVERTED_THEME}
      attached='top'
      as={Table}
      style={styles.header}
      size='small'
      compact='very'>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width={1}>
            <Checkbox
              data-testid={CHART_KEY_HEADER_CHECKBOX}
              checked={allSelected}
              indeterminate={!allSelected && !noneSelected}
              onChange={() => {
                _.chain(itemGroups)
                  .flatten()
                  .groupBy(item => item.selectionType)
                  .forEach((items, selectionType) => onSetSelected(selectionType, allSelected ? [] : _.map(items, item => item.id)))
                  .value();
              }} />
          </Table.HeaderCell>
          <Table.HeaderCell width={7} />
          <Table.HeaderCell width={1} />
          <Table.HeaderCell width={7}>
            <FunctionSensorsModalContainer
              chartType={chartType}
              trigger={(
                <Button
                  content={formatMessage(messages['header.addButton.text'])}
                  size='mini'
                  positive
                  compact
                  floated='right'
                  onClick={onOpenFunctionSensorsModal} />
              )} />
          </Table.HeaderCell>
        </Table.Row>
        {showFeaturesToggle
          ? (
            <Table.Row>
              <Table.Cell>
                <Icon
                  name='sliders horizontal'
                  fitted />
              </Table.Cell>
              <Table.Cell>
                {formatMessage(messages['header.featuresToggle.text'])}
              </Table.Cell>
              <Table.Cell />
              <Table.Cell>
                <Radio
                  data-testid={CHART_KEY_HEADER_FEATURES_TOGGLE}
                  toggle
                  disabled={isLoading}
                  checked={featuresToggle}
                  onChange={() => onSetFeaturesToggle(!featuresToggle)}
                  style={styles.featuresToggle} />
              </Table.Cell>
            </Table.Row>
          )
          : null}
        {(showFeaturesToggle && featuresToggle)
          ? (
            <Fragment>
              <Table.Row>
                <Table.Cell>
                  <Icon
                    name='calculator'
                    fitted />
                </Table.Cell>
                <Table.Cell>
                  <Form
                    inverted={INVERTED_THEME}
                    size='tiny'>
                    <Popup
                      inverted={INVERTED_THEME}
                      style={styles.featureDropdownPopup}
                      trigger={(
                        <Form.Select
                          data-testid={CHART_KEY_HEADER_FEATURE_DROPDOWN}
                          fluid
                          loading={isLoading}
                          disabled={isLoading}
                          value={selectedFeature}
                          options={_.chain(FEATURE)
                            .values()
                            .filter(name =>
                              !FEATURE_SETTINGS[name].REQUIRES_INPUT
                              && !FEATURE_SETTINGS[name].REQUIRES_WINDOW
                              && FEATURE_SETTINGS[name].VALUE_TYPE === VALUE_TYPE.FLOAT)
                            .map(name => ({
                              text: formatMessage(featuresMessages[name]),
                              value: name,
                              option: name
                            }))
                            .value()}
                          onChange={(event, { value }) => onSetSelectedFeature(value)} />
                      )}
                      content={formatMessage(messages['header.featureDropdown.popupText'])} />
                  </Form>
                </Table.Cell>
                <Table.Cell>
                  <Icon
                    name='calendar'
                    fitted />
                </Table.Cell>
                <Table.Cell>
                  <Form
                    inverted={INVERTED_THEME}
                    size='tiny'>
                    <Popup
                      inverted={INVERTED_THEME}
                      style={styles.featureDropdownPopup}
                      trigger={(
                        <Form.Select
                          data-testid={CHART_KEY_HEADER_FEATURE_WINDOW_DROPDOWN}
                          fluid
                          loading={isLoading}
                          disabled={isLoading}
                          value={featureWindow}
                          options={_.map(quickRanges, text => ({
                            text: formatMessage(dateRangeMessages[`quick.${text}`]),
                            value: text,
                            option: text
                          }))}
                          onChange={(event, { value }) => onSetFeatureWindow(value)} />
                      )}
                      content={formatMessage(messages['header.featureWindowDropwdown.popupText'])} />
                  </Form>
                </Table.Cell>
              </Table.Row>
            </Fragment>
          )
          : null}
      </Table.Header>
    </Header>
  );
});

ChartKeyHeader.propTypes = {
  itemGroups: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.exact({
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    description: PropTypes.string,
    colour: PropTypes.string,
    selected: PropTypes.bool.isRequired,
    selectionType: PropTypes.oneOf(_.values(SELECTION_TYPE)),
    copyText: PropTypes.string,
    editable: PropTypes.bool.isRequired,
    removable: PropTypes.bool.isRequired
  }))),
  chartType: PropTypes.oneOf(_.values(CHART_TYPE)),
  showFeaturesToggle: PropTypes.bool,
  featuresToggle: PropTypes.bool,
  selectedFeature: PropTypes.oneOf(_.values(FEATURE)),
  featureWindow: PropTypes.string,
  selectedProperty: PropTypes.oneOf(_.values(PROPERTY)),
  isLoading: PropTypes.bool.isRequired,
  onSetSelected: PropTypes.func.isRequired,
  onOpenFunctionSensorsModal: PropTypes.func.isRequired,
  onSetFeaturesToggle: PropTypes.func.isRequired,
  onSetSelectedFeature: PropTypes.func.isRequired,
  onSetFeatureWindow: PropTypes.func.isRequired,
  onSetSelectedProperty: PropTypes.func.isRequired
};

ChartKeyHeader.displayName = 'ChartKeyHeader';

export default ChartKeyHeader;