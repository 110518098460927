import moment from 'moment';
import { DATE_FORMAT } from 'utils/constants';
import { SET_TO_DATE, SET_DATES } from '../../../../actions/chart';

function initialState() {
  return moment().utc().local().format(DATE_FORMAT);
}

export default (state: string = initialState(), action: any): string => {
  switch (action.type) {
    case SET_DATES:
      return action.payload.to;
    case SET_TO_DATE:
      return action.payload.value;
    default:
      return state;
  }
};

export const getToDate = (state: string): string => state;