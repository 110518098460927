import { injectIntl } from 'react-intl';
import { Button, Modal } from 'semantic-ui-react';
import AlarmLevelsInputContainer from '../../../containers/alarm/AlarmLevelsInputContainer';
import AnimatedButton from '../../shared/AnimatedButton';
import messages from './AlarmLevelsModal.messages';
import styles from './AlarmLevelsModal.styles';

const AlarmLevelsModal = injectIntl(({
  alarmLevels,
  states,
  open,
  isSaving,
  onSave,
  onClose,
  intl: {
    formatMessage
  }
}) => (
  <Modal
    open={open}
    centered
    closeOnDimmerClick={false}
    closeIcon
    closeOnEscape
    onClose={onClose}>
    <Modal.Header content={formatMessage(messages['header.text'])} />
    <Modal.Content
      scrolling
      style={styles.modalContent}>
      <AlarmLevelsInputContainer states={states} />
    </Modal.Content>
    <Modal.Actions>
      <Button
        content={formatMessage(messages['cancelButton.text'])}
        onClick={onClose} />
      <AnimatedButton
        text={formatMessage(messages['saveButton.text'])}
        icon='save'
        loading={isSaving}
        positive
        onClick={() => onSave(alarmLevels)} />
    </Modal.Actions>
  </Modal>
));

AlarmLevelsModal.displayName = 'AlarmLevelsModal';

export default AlarmLevelsModal;