import { INCREMENT_PLAYBACK_TIME, CHANGE_PLAYBACK_TIME } from '../../actions/playback';
import moment from 'moment';

export default (state = moment.utc().toISOString(), action) => {
  switch (action.type) {
    case CHANGE_PLAYBACK_TIME:
    case INCREMENT_PLAYBACK_TIME:
      return action.payload.time;
    default:
      return state;
  }
};

export const getTime = state => state;