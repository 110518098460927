import { ofType } from 'redux-observable';
import { map } from 'rxjs/operators';
import { PAGE_CHANGED } from '../../actions/entity';
import { toPath } from '../../../utils/helpers';
import { PAGE_URL, ENTITY_CONFIG, EMPTY_STRING } from '../../../utils/constants';
import { querySyncPush } from '../../actions/navigation';
import entityConfig from '../../../utils/entities';

export default action$ => action$.pipe(
  ofType(PAGE_CHANGED),
  map(action => querySyncPush({
    pathname: `${toPath(PAGE_URL.ENTITY_PAGE, {
      pageUrl: entityConfig[action.entityType][ENTITY_CONFIG.PAGE_URL]
    })}${action.payload.parentId
      ? toPath(PAGE_URL.ENTITY_PARENT, {
        parentId: action.payload.parentId
      })
      : EMPTY_STRING}`,
    search: `page=${action.payload.page + 1}${action.payload.query
      ? `&query=${encodeURIComponent(JSON.stringify(action.payload.query))}`
      : EMPTY_STRING}`
  }))
);