import _ from 'lodash';
import { injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Item, Popup } from 'semantic-ui-react';
import * as fromState from 'state/reducers';
import { EMPTY_STRING, ENTITY_PROPERTY, ENTITY_TYPE, INVERTED_THEME } from 'utils/constants';
import ModelAvatar from '../ModelAvatar';
import messages from './ModelPopup.messages';
import styles from './ModelPopup.styles';

export default injectIntl(function ModelPopup({
  id,
  trigger,
  intl: {
    formatMessage
  }
}) {
  const model = useSelector(
    state => fromState.getById(state, ENTITY_TYPE.MODEL, id)
  );
  if (!model) {
    return trigger;
  };
  return (
    <Popup
      inverted={INVERTED_THEME}
      style={styles.popup}
      content={(
        <Item.Group
          className={INVERTED_THEME
            ? 'inverted'
            : EMPTY_STRING}>
          <Item>
            <Item.Content>
              <Item.Header>
                {model.name}
              </Item.Header>
              <Item.Meta>
                {formatMessage(messages['description.header'])}
              </Item.Meta>
              <Item.Description>
                {model.description}
              </Item.Description>
              <Item.Meta>
                {formatMessage(messages['author.header'])}
              </Item.Meta>
              <Item.Description>
                <ModelAvatar
                  author={_.get(model, ENTITY_PROPERTY.MODEL.AUTHOR)} />
              </Item.Description>
            </Item.Content>
          </Item>
        </Item.Group>
      )}
      trigger={trigger} />
  );
});