import { useRef } from 'react';
import { Button, Modal } from 'semantic-ui-react';
import EntityFormContainer from '../../containers/entity/EntityFormContainer';
import AnimatedButton from '../shared/AnimatedButton';
import { injectIntl } from 'react-intl';

const EntityFormModal = injectIntl(({
  entityType,
  createEntity,
  form,
  trigger,
  parent,
  isSaving,
  open,
  formValues,
  confirm,
  name,
  toggleModal,
  icon,
  intl: {
    formatMessage
  },
  messages,
  lockType,
  mode,
  selectContextLists
}) => {
  const ref = useRef();
  return (
    <Modal
      trigger={trigger}
      centered
      closeOnDimmerClick={false}
      closeIcon
      closeOnEscape
      size='large'
      onClose={() => toggleModal(name)}
      onClick={e => e.stopPropagation()} // Required to prevent clicks passing through to components beneath the modal.
      open={open}>
      <Modal.Header>{formatMessage(messages['header.text'])}</Modal.Header>
      <div
        className='scrolling content'
        ref={ref}
        style={{ minHeight: '50vh' }}>
        <Modal.Description>
          <EntityFormContainer
            form={form}
            entityType={entityType}
            lockType={lockType}
            mode={mode}
            selectContextLists={selectContextLists}
            scrollContextRef={ref}
            parentId={parent && parent.id} />
        </Modal.Description>
      </div>
      <Modal.Actions>
        <Button
          content={formatMessage(messages['cancelButton.text'])}
          onClick={() => toggleModal(name)} />
        <AnimatedButton
          text={formatMessage(messages['confirmButton.text'])}
          icon={icon}
          loading={isSaving}
          positive
          onClick={() => confirm(formValues, parent)} />
      </Modal.Actions>
    </Modal>
  );
});

EntityFormModal.displayName = 'EntityFormModal';

export default EntityFormModal;