import _ from 'lodash';
import { List, Dropdown, Input, Label } from 'semantic-ui-react';
import AnimatedButton from '../shared/AnimatedButton';
import { BarGauge } from '../chart/GaugeCard';
import { injectIntl, defineMessages } from 'react-intl';
import { COLOUR, INVERTED_THEME, SEVERITY_COLOURS, COLOUR_SEVERITIES } from '../../utils/constants';
import { getColourFromSeverity } from '../../utils/helpers';

const messages = defineMessages({
  'severity.none': {
    id: 'alarmRangeInput.severity.none',
    defaultMessage: 'Neutral'
  },
  'severity.low': {
    id: 'alarmRangeInput.severity.low',
    defaultMessage: 'Low'
  },
  'severity.med': {
    id: 'alarmRangeInput.severity.med',
    defaultMessage: 'Med'
  },
  'severity.high': {
    id: 'alarmRangeInput.severity.high',
    defaultMessage: 'High'
  },
  'upTo': {
    id: 'alarmRangeInput.upTo',
    defaultMessage: 'up to'
  },
  'editButton.text': {
    id: 'alarmRangeInput.editButton.text',
    defaultMessage: 'Edit'
  },
  'addButton.text': {
    id: 'alarmRangeInput.addButton.text',
    defaultMessage: 'Add'
  },
  'removeButton.text': {
    id: 'alarmRangeInput.removeButton.text',
    defaultMessage: 'Remove'
  },
  'min': {
    id: 'alarmRangeInput.min',
    defaultMessage: 'Min'
  },
  'max': {
    id: 'alarmRangeInput.max',
    defaultMessage: 'Max'
  }
});

export const severityMessages = {
  '-1': messages['severity.none'],
  0: messages['severity.low'],
  1: messages['severity.med'],
  2: messages['severity.high']
};
const parseValue = value => _.isNaN(parseFloat(value)) ? value : parseFloat(value);
const textPercentage = (disabled, full) => {
  const width = disabled ? 25 : 20;
  return `${full ? 100 - width : width}%`;
};

const AlarmRangeInputRow = injectIntl(({
  max,
  min,
  index,
  severity,
  disabled,
  onEditMax,
  onEditSeverity,
  onRemove,
  isOnlyRange,
  intl: {
    formatMessage
  }
}) => (
  <List.Item>
    <List.Header>
      <Input
        value={max}
        fluid
        action
        className='labeled'>
        <Dropdown
          icon={{
            name: 'circle',
            style: { color: getColourFromSeverity(severity) }
          }}
          text={formatMessage(severityMessages[severity])}
          labeled
          button
          className='icon label'
          style={{
            background: COLOUR.LIGHT_GREY,
            color: COLOUR.TEXT,
            minWidth: textPercentage(disabled),
            cursor: disabled ? 'default' : 'pointer'
          }}>
          {disabled
            ? <div></div>
            : (
              <Dropdown.Menu>
                {_.map(SEVERITY_COLOURS, (colour, i) => (
                  <Dropdown.Item
                    key={colour}
                    onClick={() => onEditSeverity(COLOUR_SEVERITIES[colour])}
                    icon={{
                      name: 'circle',
                      style: { color: colour }
                    }}
                    text={formatMessage(severityMessages[i])} />
                ))}
              </Dropdown.Menu>
            )}
        </Dropdown>
        <Label
          basic
          style={{
            borderRadius: 0,
            color: COLOUR.TEXT,
            background: COLOUR.LIGHT_GREY,
            width: textPercentage(disabled)
          }}>
          {min}
        </Label>
        <Label
          basic
          style={{
            borderRadius: 0,
            color: COLOUR.TEXT,
            background: COLOUR.LIGHT_GREY,
            width: textPercentage(disabled)
          }}>
          {formatMessage(messages['upTo'])}
        </Label>
        <input
          tabIndex={index + 1}
          type='number'
          style={{ borderRadius: 0 }}
          disabled={disabled}
          onChange={event => onEditMax(parseValue(event.target.value))} />
        {!disabled && !isOnlyRange && (
          <AnimatedButton
            negative
            fluid
            style={{
              width: textPercentage(disabled),
              textAlign: 'center',
              display: 'block'
            }}
            text={formatMessage(messages['removeButton.text'])}
            icon='minus'
            onClick={onRemove} />
        )}
      </Input>
    </List.Header>
  </List.Item>
));

AlarmRangeInputRow.displayName = 'AlarmRangeInputRow';

const INVALID_ALARM_RANGES = [
  {
    min: 0,
    max: 1,
    severity: null
  }
];

const AlarmRangeInput = injectIntl(({
  alarmRanges,
  disabled,
  onEdit,
  onEditAlarmRange,
  onRemoveAlarmRange,
  onAddAlarmRange,
  isValid = true,
  intl: {
    formatMessage
  }
}) => {
  if (_.isEmpty(alarmRanges)) {
    return null;
  }

  const barGaugeRanges = isValid ? alarmRanges : INVALID_ALARM_RANGES;
  return (
    <div>
      <div style={{
        display: 'flex',
        justifyContent: 'center'
      }}>
        <BarGauge
          min={_.first(barGaugeRanges).min}
          max={_.last(barGaugeRanges).max}
          ranges={barGaugeRanges}
          full />
      </div>
      <List
        inverted={INVERTED_THEME}
        verticalAlign='middle'>
        <List.Item>
          <List.Header>
            <Input
              value={_.first(alarmRanges).min}
              fluid
              className='labeled'>
              <Dropdown
                text={formatMessage(messages['min'])}
                icon={{ name: 'circle' }}
                labeled
                button
                className='icon label'
                style={{
                  background: COLOUR.LIGHT_GREY,
                  color: COLOUR.TEXT,
                  minWidth: textPercentage(disabled),
                  cursor: 'default'
                }}>
                <div></div>
              </Dropdown>
              <input
                tabIndex={0}
                type='number'
                disabled={disabled}
                onChange={event => onEditAlarmRange(0, 'min', parseValue(event.target.value))} />
            </Input>
          </List.Header>
        </List.Item>
        {_.map(alarmRanges, ({ min, max, severity }, i) => (
          <AlarmRangeInputRow
            key={i}
            index={i}
            min={min}
            max={max}
            onEditMax={value => {
              if (_.size(alarmRanges) > i + 1) {
                onEditAlarmRange(i + 1, 'min', value);
              }

              return onEditAlarmRange(i, 'max', value);
            }}
            onEditSeverity={value => onEditAlarmRange(i, 'severity', value)}
            onRemove={() => {
              if (i > 0 && _.size(alarmRanges) > i + 1) {
                onEditAlarmRange(i + 1, 'min', alarmRanges[i - 1].max);
              }

              return onRemoveAlarmRange(i);
            }}
            isOnlyRange={_.size(alarmRanges) === 1}
            severity={severity}
            disabled={disabled} />
        ))}
        <List.Item>
          <List.Header>
            <Input
              fluid
              className='labeled'>
              <Dropdown
                text={formatMessage(messages['max'])}
                icon={{ name: 'circle' }}
                labeled
                button
                className='icon label'
                style={{
                  background: COLOUR.LIGHT_GREY,
                  color: COLOUR.TEXT,
                  minWidth: textPercentage(disabled),
                  cursor: 'default'
                }}>
                <div></div>
              </Dropdown>
              <Label style={{
                borderRadius: 0,
                color: COLOUR.TEXT,
                background: COLOUR.LIGHT_GREY,
                width: textPercentage(disabled, true)
              }}>
                {_.last(alarmRanges).max}
              </Label>
            </Input>
          </List.Header>
        </List.Item>
      </List>
      {disabled
        ? (
          <AnimatedButton
            primary
            icon='edit'
            text={formatMessage(messages['editButton.text'])}
            onClick={() => onEdit(alarmRanges)} />
        )
        : (
          <AnimatedButton
            primary
            icon='add'
            text={formatMessage(messages['addButton.text'])}
            onClick={() => {
              const lastMaximum = _.chain(alarmRanges).last().get('max').value();
              const max = _.isUndefined(lastMaximum) ? 0 : parseFloat(lastMaximum);
              return onAddAlarmRange({
                min: max,
                max: max + 1,
                severity: 0
              });
            }} />
        )}
    </div>
  );
});

AlarmRangeInput.displayName = 'AlarmRangeInput';

export default AlarmRangeInput;