import _ from 'lodash';
import { ATTRIBUTE_SOURCE_ITEM_PROPS } from '../../constants';

export default (selectList, formatMessage) => _.map(selectList, item => ({
  key: item.id,
  text: item.messageId ? formatMessage({ id: item.messageId }) : item.text || item.name || item.id,
  value: item.value || item.id,
  icon: item.icon,
  ...item.source
    ? ATTRIBUTE_SOURCE_ITEM_PROPS[item.source] || {}
    : {},
  ...item
}));