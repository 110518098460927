import { useSelector } from 'react-redux';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Label } from 'semantic-ui-react';
import { CONTROLLER, ENTITY_FORM_ELEMENT_TYPE, ENTITY_TYPE, ROLE } from '../../../utils/constants';
import textComponent from './textComponent';
import * as fromState from '../../../state/reducers';

const AuthGroupNameElement = ({ authGroupId }) => useSelector(state => {
  const authGroup = fromState.getById(state, ENTITY_TYPE.AUTH_GROUP, authGroupId);
  return authGroup.name;
});

const messages = defineMessages({
  'title': {
    id: 'userMapping.title',
    defaultMessage: 'Users'
  },
  'columns.email.title': {
    id: 'userMapping.columns.email.title',
    defaultMessage: 'Email'
  },
  'columns.state.title': {
    id: 'userMapping.columns.state.title',
    defaultMessage: 'State'
  },
  'columns.authGroup.title': {
    id: 'userMapping.columns.authGroup.title',
    defaultMessage: 'Auth Group'
  },
  'form.email.label': {
    id: 'userMapping.form.email.label',
    defaultMessage: 'Email'
  },
  'form.email.placeholder': {
    id: 'userMapping.form.email.placeholder',
    defaultMessage: 'Email'
  },
  'form.authGroup.label': {
    id: 'userMapping.form.authGroup.label',
    defaultMessage: 'Auth Group'
  },
  'form.authGroup.placeholder': {
    id: 'userMapping.form.authGroup.placeholder',
    defaultMessage: 'Auth Group'
  },
  'form.authGroup.option.developer': {
    id: 'userMapping.form.authGroup.option.developer',
    defaultMessage: 'Developer'
  },
  'state.created': {
    id: 'userMapping.state.created',
    defaultMessage: 'Created'
  },
  'state.sendingInvite': {
    id: 'userMapping.state.sendingInvite',
    defaultMessage: 'Sending Invite'
  },
  'state.invited': {
    id: 'userMapping.state.invited',
    defaultMessage: 'Invited'
  },
  'state.active': {
    id: 'userMapping.state.active',
    defaultMessage: 'Active'
  }
});

const colours = {
  created: 'yellow',
  sendingInvite: 'yellow',
  invited: 'yellow',
  active: 'green'
};

export default {
  apiPath: CONTROLLER.USER_MAPPING,
  pageUrl: 'users',
  icon: 'users',
  messages,
  disableFetchTypes: true,
  viewRole: ROLE.ADMIN,
  modifyRole: ROLE.ADMIN,
  textComponent,
  columns: [
    {
      name: 'email',
      width: 3,
      render: ({ email }) => email
    },
    {
      name: 'state',
      width: 3,
      render: ({ state }) => (
        <Label color={colours[state.state]}>
          <FormattedMessage {...messages[`state.${state.state}`]} />
        </Label>
      )
    },
    {
      name: 'authGroup',
      width: 3,
      render: ({ authGroup }) => <AuthGroupNameElement authGroupId={authGroup} />
    }
  ],
  form: [
    {
      name: 'email',
      type: 'input',
      disableOnEdit: true
    },
    {
      name: 'authGroup',
      type: ENTITY_FORM_ELEMENT_TYPE.SELECT_ENTITY,
      entityType: ENTITY_TYPE.AUTH_GROUP
    }
  ],
  createEntity: values => ({
    email: values.email,
    authGroup: values.authGroup,
    unitId: null
  }),
  populateForm: entity => ({
    email: entity.email,
    authGroup: entity.authGroup,
    unitId: entity.unitId
  })
};;