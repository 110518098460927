import _ from 'lodash';
import { Accordion, Icon, Card } from 'semantic-ui-react';
import IsInRole from '../../../containers/auth/IsInRole';
import { defineMessages, injectIntl } from 'react-intl';
import styles from './DeckPanel.styles';
import AddEntityModalContainer from '../../../containers/entity/AddEntityModalContainer';
import EntityImportModalContainer from '../../../containers/entity/EntityImportModalContainer';
import AnimatedButton from '../../../components/shared/AnimatedButton';
import entityConfig from '../../../utils/entities';
import { ROLE } from '../../../utils/constants';

const messages = defineMessages({
  'addButton.text': {
    id: 'deckPanel.addButton.text',
    defaultMessage: 'Add'
  },
  'importButton.text': {
    id: 'deckPanel.importButton.text',
    defaultMessage: 'Import'
  }
});

/**
 * @param parentId The ID of the unit or unit type asssociated with the dashboard page
 * in which this deck is placed. If there is no parent (the deck is on the home page),
 * set to null.
 */
const DeckPanel = injectIntl(({
  groupedItems,
  entityType,
  canAdd,
  canImport,
  importContext,
  isTopLevel,
  open,
  view,
  parentId,
  sortBy,
  mapItemToCard,
  cardsPerRow,
  onClickAdd,
  onClickImport,
  onToggleDeckCollapsed,
  intl: {
    formatMessage
  }
}) => {
  if (_.compact(groupedItems).length === 0) {
    return <></>;
  }

  const count = _.chain(groupedItems)
    .compact()
    .sumBy(group => group.length)
    .value();

  const config = entityConfig[entityType];
  const iconName = open ? 'caret down' : 'caret right';
  const headerText = formatMessage(config.deckHeaderMessage(view, isTopLevel));

  return (
    <IsInRole role={entityConfig[entityType].viewRole}>
      <Accordion.Title
        onClick={onToggleDeckCollapsed}>
        <div style={styles.deckHeader}>
          <div style={styles.deckHeaderLeft}>
            <Icon
              name={iconName}
              style={{
                ...styles.deckCollapseIcon,
                visibility: count > 0 ? 'visible' : 'hidden'
              }} />
            {`${headerText} (${count})`}
          </div>
          <div style={styles.deckHeaderRight}>
            {canImport && (
              <IsInRole role={ROLE.ADMIN}>
                <EntityImportModalContainer
                  entityType={entityType}
                  context={importContext}
                  trigger={(
                    <AnimatedButton
                      style={styles.deckHeaderButton}
                      text={formatMessage(messages['importButton.text'])}
                      icon='upload'
                      positive
                      onClick={e => {
                        onClickImport();
                        e.stopPropagation();
                      }}>
                    </AnimatedButton>
                  )} />
              </IsInRole>
            )}
            {canAdd && (
              <IsInRole role={entityConfig[entityType].modifyRole}>
                <AddEntityModalContainer
                  trigger={(
                    <AnimatedButton
                      style={styles.deckHeaderButton}
                      text={formatMessage(messages['addButton.text'])}
                      icon='add'
                      positive
                      onClick={e => {
                        onClickAdd();
                        e.stopPropagation();
                      }} />
                  )}
                  form={_.filter(
                    config.form,
                    ({ name }) => !name || name !== config.parentProperty
                  )}
                  createEntity={(values, parent) =>
                    config.createEntity({
                      ...values,
                      [config.parentProperty]: parentId
                    }, parent)}
                  entityType={entityType}
                  parentId={parentId} />
              </IsInRole>
            )}
          </div>
        </div>
      </Accordion.Title>
      <Accordion.Content
        active={open && count > 0}
        style={{ marginBottom: '20px' }}>
        {
          _.chain(groupedItems)
            .zipWith(_.range(groupedItems.length))
            .compact()
            .map(([group, index]) => (
              <Card.Group
                key={index}
                style={{ clear: 'both' }}
                itemsPerRow={cardsPerRow}>
                {
                  _.chain(group)
                    .sortBy(sortBy)
                    .map(mapItemToCard)
                    .value()
                }
              </Card.Group>
            ))
            .value()
        }
      </Accordion.Content>
    </IsInRole>
  );
});

DeckPanel.displayName = 'DeckPanel';

export default DeckPanel;