import { injectIntl } from 'react-intl';
import { Segment, Tab, Menu, MenuItem, Icon, Popup } from 'semantic-ui-react';
import { INVERTED_THEME } from 'utils/constants';
import AxesMenu from './AxesMenu';
import FiltersMenu from './FiltersMenu';
import ColoursMenu from './ColoursMenu';
import messages from './ExplorerPage.messages';
import PlotlyChart from './PlotlyChart';
import styles from './ExplorerPage.styles';
import useExplorerControls from './useExplorerControls';

const ExplorerPage = injectIntl(({
  intl: {
    formatMessage
  }
}) => {
  const [
    attrs, setAttrs,
    features, setFeatures,
    windows, setWindows,
    filterState, setFilterState,
    colorAttr, setColorAttr,
    colorMode, setColorMode,
    opacity, setOpacity,
    markerSize, setMarkerSize,
    sensors,
    statusMessage,
    statusColor,
    chartSpec
  ] = useExplorerControls();

  const tabPopup = (icon, titleMessage) => (
    <MenuItem>
      <Popup
        inverted={INVERTED_THEME}
        style={styles.tabPopup}
        trigger={(
          <Menu.Item>
            <Icon name={icon} />
          </Menu.Item>
        )}
        on='hover'
        position='right center'
        content={formatMessage(titleMessage)} />
    </MenuItem>
  );

  const panes = [
    {
      menuItem: tabPopup('filter', messages['menu.filters']),
      render: () => (
        <FiltersMenu
          filterState={filterState}
          onSetFilterState={setFilterState}
          sensors={sensors} />
      )
    },
    {
      menuItem: tabPopup('expand arrows alternate', messages['menu.axes']),
      render: () => (
        <AxesMenu
          sensors={sensors}
          attrs={attrs}
          onSetAttrs={setAttrs}
          features={features}
          onSetFeatures={setFeatures}
          windows={windows}
          onSetWindows={setWindows} />
      )
    },
    {
      menuItem: tabPopup('paint brush', messages['menu.colours']),
      render: () => (
        <ColoursMenu
          colourMode={colorMode}
          onSetColourMode={setColorMode}
          sensors={sensors}
          colourAttr={colorAttr}
          onSetColourAttr={setColorAttr}
          opacity={opacity}
          onSetOpacity={setOpacity}
          markerSize={markerSize}
          onSetMarkerSize={setMarkerSize} />
      )
    }
  ];

  return (
    <>
      <Segment
        raised
        color={statusColor}
        size='mini'
        inverted={INVERTED_THEME}>
        {formatMessage(messages[statusMessage])}
      </Segment>
      <Segment
        inverted={INVERTED_THEME}
        style={styles.mainLayout}>
        <Tab
          style={styles.tab}
          inverted={INVERTED_THEME}
          color='black'
          menu={{
            inverted: INVERTED_THEME,
            compact: true,
            vertical: true,
            color: 'black',
            icon: true,
            style: styles.tabMenu
          }}
          panes={panes} />

        <div style={styles.chartContainer}>
          <PlotlyChart
            spec={chartSpec}
            colourMode={colorMode} />
        </div>
      </Segment>
    </>
  );
});

ExplorerPage.displayName = 'ExplorerPage';

export default ExplorerPage;
