import PredictorOutputAlarmLevelsButtonContainer from '../../containers/alarm/PredictorOutputAlarmLevelsButtonContainer';
import PredictorOutputAlarmRangesButtonContainer from '../../containers/alarm/PredictorOutputAlarmRangesButtonContainer';
import { ENTITY_TYPE, VALUE_TYPE } from '../../utils/constants';

const PredictorOutputAlarmButton = ({
  index,
  id,
  valueType,
  label,
  entityType = ENTITY_TYPE.PREDICTOR
}) => {
  if (valueType === VALUE_TYPE.FLOAT) {
    return (
      <PredictorOutputAlarmRangesButtonContainer
        index={index}
        id={id}
        label={label}
        entityType={entityType} />
    );
  };
  if (valueType === VALUE_TYPE.ENUMERATION) {
    return (
      <PredictorOutputAlarmLevelsButtonContainer
        index={index}
        id={id}
        label={label}
        entityType={entityType} />
    );
  };
  return null;
};

PredictorOutputAlarmButton.displayName = 'PredictorOutputAlarmButton';

export default PredictorOutputAlarmButton;