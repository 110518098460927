import _ from 'lodash';
import { createSelector } from 'reselect';
import { ENTITY_TYPE } from '../../../utils/constants';
import * as fromState from '../../../state/reducers';

export default createSelector(
  [
    state => fromState.getAll(state, ENTITY_TYPE.SENSOR),
    (state, { valueType }) => valueType
  ],
  (allSensors, valueType) => {
    const sensors = valueType
      ? _.filter(allSensors, s => s.valueType === valueType)
      : allSensors;

    return _.map(sensors, s => ({
      key: s.id,
      text: s.name,
      value: s.id
    }));
  }
);