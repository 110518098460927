import { defineMessages } from 'react-intl';

export default defineMessages({
  'red': {
    id: 'colour.red',
    defaultMessage: 'Red'
  },
  'orange': {
    id: 'colour.orange',
    defaultMessage: 'Orange'
  },
  'yellow': {
    id: 'colour.yellow',
    defaultMessage: 'Yellow'
  },
  'olive': {
    id: 'colour.olive',
    defaultMessage: 'Olive'
  },
  'green': {
    id: 'colour.green',
    defaultMessage: 'Green'
  },
  'teal': {
    id: 'colour.teal',
    defaultMessage: 'Teal'
  },
  'blue': {
    id: 'colour.blue',
    defaultMessage: 'Blue'
  },
  'violet': {
    id: 'colour.violet',
    defaultMessage: 'Violet'
  },
  'purple': {
    id: 'colour.purple',
    defaultMessage: 'Purple'
  },
  'pink': {
    id: 'colour.pink',
    defaultMessage: 'Pink'
  },
  'brown': {
    id: 'colour.brown',
    defaultMessage: 'Brown'
  },
  'grey': {
    id: 'colour.grey',
    defaultMessage: 'Grey'
  },
  'black': {
    id: 'colour.black',
    defaultMessage: 'Black'
  }
});