import AlarmRangeInput from '../../components/alarm/AlarmRangeInput';
import { connect } from 'react-redux';
import * as fromState from '../../state/reducers';
import { editAlarmRange, removeAlarmRange, addAlarmRange } from '../../state/actions/alarm';

const mapStateToProps = state => ({
  alarmRanges: fromState.getAlarmRanges(state),
  isValid: fromState.getAlarmRangesValid(state)
});

const mapDispatchToProps = {
  onEditAlarmRange: editAlarmRange,
  onRemoveAlarmRange: removeAlarmRange,
  onAddAlarmRange: addAlarmRange
};

export default connect(mapStateToProps, mapDispatchToProps)(AlarmRangeInput);