import _ from 'lodash';
import { defineMessages, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { Tab, Table } from 'semantic-ui-react';
import PredictorOutputAlarmButton from '../../../components/alarm/PredictorOutputAlarmButton';
import SensorAlarmButton from '../../../components/alarm/SensorAlarmButton';
import EntityPageContainer from '../../../containers/entity/EntityPageContainer';
import * as fromState from '../../../state/reducers';
import { getFunctionSensors, getInputSensors } from '../../../state/selectors/chart';
import { CHART_TYPE, ENTITY_PROPERTY, ENTITY_TYPE, INVERTED_THEME, VALUE_TYPE } from '../../constants';

const messages = defineMessages({
  'tab.alerts': {
    id: 'predictorPage.tab.alerts',
    defaultMessage: 'Alerts'
  },
  'tab.actions': {
    id: 'predictorPage.tab.actions',
    defaultMessage: 'Actions'
  },
  'tab.alarms': {
    id: 'predictorPage.tab.alarms',
    defaultMessage: 'Alarms'
  },
  'tab.alarms.table.column.inputs': {
    id: 'predictorPage.tab.alarms.table.column.inputs',
    defaultMessage: 'Inputs'
  },
  'tab.alarms.table.column.outputs': {
    id: 'predictorPage.tab.alarms.table.column.outputs',
    defaultMessage: 'Outputs'
  },
  'tab.alarms.table.column.sensors': {
    id: 'predictorPage.tab.alarms.table.column.sensors',
    defaultMessage: 'Sensors'
  }
});

const mapStateToProps = createSelector(
  [
    (state, { parentId }) => fromState.getById(state, ENTITY_TYPE.PREDICTOR, parentId),
    state => fromState.getAll(state, ENTITY_TYPE.MODEL),
    state => getInputSensors(state, CHART_TYPE.MAIN),
    state => getFunctionSensors(state, CHART_TYPE.MAIN)
  ],
  (predictor, allModels, inputSensors, functionSensors) => ({
    predictor,
    model: _.get(allModels, predictor && predictor.type),
    inputSensors,
    functionSensors
  })
);

const PredictorPage = ({
  predictor,
  model,
  inputSensors,
  functionSensors,
  intl: {
    formatMessage
  }
}) => (
  <Tab
    menu={{
      inverted: INVERTED_THEME,
      className: 'tab-menu'
    }}
    panes={[
      {
        menuItem: formatMessage(messages['tab.alerts']),
        render: () => (
          <EntityPageContainer
            entityType={ENTITY_TYPE.ALERT_MAPPING}
            parentId={predictor && predictor.id} />
        )
      },
      {
        menuItem: formatMessage(messages['tab.actions']),
        render: () => (
          <EntityPageContainer
            entityType={ENTITY_TYPE.ACTION_MAPPING}
            parentId={predictor && predictor.id} />
        )
      },
      {
        menuItem: formatMessage(messages['tab.alarms']),
        render: () => (
          <div className='page-content'>
            <Table
              celled
              inverted={INVERTED_THEME}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>
                    {formatMessage(messages['tab.alarms.table.column.inputs'])}
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    {formatMessage(messages['tab.alarms.table.column.outputs'])}
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    {formatMessage(messages['tab.alarms.table.column.sensors'])}
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {_.chain(predictor)
                  .get(ENTITY_PROPERTY.PREDICTOR.INPUT_MAPPINGS)
                  .filter(inputMapping => inputSensors[inputMapping.input]
                    && _.includes(
                      [VALUE_TYPE.FLOAT, VALUE_TYPE.ENUMERATION],
                      inputSensors[inputMapping.input].valueType
                    ))
                  .zip(
                    _.chain(predictor)
                      .get(ENTITY_PROPERTY.PREDICTOR.OUTPUT_MAPPINGS)
                      .filter((outputMapping, index) => model
                        && _.includes(
                          [VALUE_TYPE.FLOAT, VALUE_TYPE.ENUMERATION],
                          model.outputs[index].valueType
                        ))
                      .value(),
                    _.chain(predictor)
                      .get(ENTITY_PROPERTY.PREDICTOR.FUNCTION_MAPPINGS)
                      .filter(functionMapping => functionSensors[functionMapping.sensor]
                        && _.includes(
                          [VALUE_TYPE.FLOAT, VALUE_TYPE.ENUMERATION],
                          functionSensors[functionMapping.sensor].valueType
                        ))
                      .value()
                  )
                  .map(([inputMapping, outputMapping, functionMapping], index) => (
                    <Table.Row key={index}>
                      <Table.Cell width={1}>
                        {inputMapping && (
                          <SensorAlarmButton
                            id={inputMapping.sensor}
                            valueType={inputSensors[inputMapping.input].valueType}
                            label={inputSensors[inputMapping.input].name} />
                        )}
                      </Table.Cell>
                      <Table.Cell width={1}>
                        {outputMapping && (
                          <PredictorOutputAlarmButton
                            index={index}
                            id={predictor && predictor.id}
                            valueType={model && model.outputs[index].valueType}
                            label={outputMapping.output} />
                        )}
                      </Table.Cell>
                      <Table.Cell width={1}>
                        {functionMapping && (
                          <SensorAlarmButton
                            id={functionMapping.sensor}
                            valueType={functionSensors[functionMapping.sensor].valueType}
                            label={functionSensors[functionMapping.sensor].name} />
                        )}
                      </Table.Cell>
                    </Table.Row>
                  ))
                  .value()}
              </Table.Body>
            </Table>
          </div>
        )
      }
    ]} />
);

PredictorPage.displayName = 'PredictorPage';

export default connect(mapStateToProps)(injectIntl(PredictorPage));