import { AUTH_TYPE, AUTH_MESSAGE, PAGE_URL } from '../../../utils/constants';
import { PERFORM_AUTH_FULFILLED, setAuthMessage } from '../../actions/auth';
import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';
import { merge } from 'rxjs';
import queryString from 'query-string';
import { querySyncPush } from 'state/actions/navigation';

const authFlows = {
  [AUTH_TYPE.CHANGE]: {
    navigateTo: PAGE_URL.LOGIN,
    showMessage: AUTH_MESSAGE.PASSWORD_CHANGED
  },
  [AUTH_TYPE.CONFIRM]: {
    navigateTo: PAGE_URL.LOGIN,
    showMessage: AUTH_MESSAGE.EMAIL_CONFIRMED
  },
  [AUTH_TYPE.FORGOT]: {
    showMessage: AUTH_MESSAGE.RESET_LINK_SENT
  },
  [AUTH_TYPE.JOIN]: {
    navigateTo: PAGE_URL.LOGIN,
    showMessage: AUTH_MESSAGE.JOINED_TENANT
  },
  [AUTH_TYPE.LOGIN]: {
    navigateTo: PAGE_URL.HOME,
    redirect: true
  },
  [AUTH_TYPE.REGISTER]: {
    showMessage: AUTH_MESSAGE.CONFIRM_LINK_SENT
  },
  [AUTH_TYPE.RESEND]: {
    showMessage: AUTH_MESSAGE.CONFIRM_LINK_RESENT
  }
};

export default action$ => action$.pipe(
  ofType(PERFORM_AUTH_FULFILLED),
  mergeMap(action => {
    const navigateActions = [];
    const authFlow = authFlows[action.authType];
    const queryParameters = queryString.parse(window.location.search);
    if (authFlow.redirect && queryParameters.redirect) {
      navigateActions.push(querySyncPush(queryParameters.redirect));
    } else if (authFlow.navigateTo) {
      navigateActions.push(querySyncPush(authFlows[action.authType].navigateTo));
    };
    return merge([
      ...navigateActions,
      setAuthMessage(authFlows[action.authType].showMessage || null)
    ]);
  })
);