import ProgressLabel from 'components/shared/ProgressLabel';
import _ from 'lodash';
import { InjectedIntl, injectIntl } from 'react-intl';
import { Label } from 'semantic-ui-react';
import { Review } from 'types';
import messages from 'utils/i18n/shared/status';

interface Props {
  review: Review,
  intl: InjectedIntl;
}
const StatusColumn = ({
  review,
  intl: {
    formatMessage
  }
}: Props) => {
  const completed = _.chain(review.trends)
    .filter(reviewTrend =>
      reviewTrend.skipped || !_.find(
        review.regions,
        reviewRegion => !reviewTrend.regions[reviewRegion.name]
      ))
    .size()
    .value();
  if (completed === 0) {
    return (
      <Label content={formatMessage(messages['new'])} />
    );
  };

  if (completed === _.size(review.trends)) {
    return (
      <Label
        color='green'
        content={formatMessage(messages['complete'])} />
    );
  };

  return (
    <ProgressLabel
      content={formatMessage(messages['inProgress'])}
      color='yellow'
      percent={100 * completed / _.size(review.trends)} />
  );
};

StatusColumn.displayName = 'StatusColumn';

export default injectIntl(StatusColumn);