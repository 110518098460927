import id, * as fromId from './id';
import open, * as fromOpen from './open';
import { combineReducers } from 'redux';

const modalReducer = combineReducers({
  id,
  open
});

export default modal => (state, action) => {
  if (action.payload && action.payload.modal && action.payload.modal !== modal) {
    return state;
  }

  return modalReducer(state, action);
};

export const getId = state => fromId.getId(state.id);
export const getOpen = state => fromOpen.getOpen(state.open);
export const getOpenById = (state, id) => getOpen(state) && getId(state) === id;