import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { resetApp, SWITCH_TENANT } from '../../../actions/auth';
import { push } from 'connected-react-router';

export default action$ => action$.pipe(
  ofType(SWITCH_TENANT),
  mergeMap(() =>
    of(
      push('/reset'),
      resetApp(),
      push('/')
    ))
);