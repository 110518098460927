import _ from 'lodash';
import { VALUE_TYPE } from 'utils/constants';

const triangleArea = (left, bucketPoint, right) => Math.abs(
  (left[0] - right[0]) * (bucketPoint[1] - left[1])
  - (left[0] - bucketPoint[0]) * (right[1] - left[1])
) * 0.5;

export default function largestTriangleThreeBuckets(
  data,
  interval,
  brushStartTimestamp,
  brushEndTimestamp,
  valueType
) {
  data = _.reject(
    data,
    ([timestamp]) => (timestamp < brushStartTimestamp)
      || (timestamp > brushEndTimestamp)
  );
  if (_.isNull(interval)) {
    return data;
  };
  const buckets = _.size(data) <= 2
    ? []
    : _.chain(data)
      .slice(1, _.size(data) - 2)
      .groupBy(([timestamp]) => Math.floor(timestamp / interval))
      .values()
      .filter(_.some)
      .value();
  if (valueType !== VALUE_TYPE.FLOAT && valueType !== VALUE_TYPE.ENUMERATION) {
    return _.map(buckets, bucket => {
      const [timestamp, value] = _.first(bucket);
      return [interval * Math.floor(timestamp / interval), value];
    });
  };
  const bucketMeans = _.map(
    buckets,
    bucket => [
      _.meanBy(bucket, _.first),
      _.meanBy(bucket, _.last)
    ]
  );
  const bucketPoints = [_.first(data)];
  _.each(buckets, (bucket, index) => {
    bucketPoints.push([
      interval * Math.floor(bucket[0][0] / interval),
      _.last(_.maxBy(
        bucket,
        bucketPoint => triangleArea(
          bucketPoints[index],
          bucketPoint,
          bucketMeans[index + 1] || _.last(data)
        ) || 0
      ))
    ]);
  });
  bucketPoints.push(_.last(data));
  return _.filter(bucketPoints);
};