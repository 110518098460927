import { PERFORM_AUTH, PERFORM_AUTH_ERROR, PERFORM_AUTH_FULFILLED } from '../../../actions/auth';

export default (state = false, action) => {
  switch (action.type) {
    case PERFORM_AUTH:
      return true;
    case PERFORM_AUTH_ERROR:
    case PERFORM_AUTH_FULFILLED:
      return false;
    default:
      return state;
  }
};

export const getIsLoading = state => state;