import _ from 'lodash';
import { ofType } from 'redux-observable';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { fetchLabelsFulfilled, FETCH_LABELS } from '../../actions/entity';
import * as api from '../../../utils/api';
import * as fromState from '../../reducers';
import entityConfig from '../../../utils/entities';
import { ENTITY_CONFIG } from 'utils/constants';
import { takeUntilAppReset } from 'utils/helpers';

export default (action$, state$) => action$.pipe(
  ofType(FETCH_LABELS),
  withLatestFrom(state$),
  mergeMap(([action, state]) =>
    api.getLabels({
      controller: entityConfig[action.entityType][ENTITY_CONFIG.API_PATH],
      token: fromState.getUser(state).token
    }).pipe(
      takeUntilAppReset(action$),
      map(_.partial(fetchLabelsFulfilled, action.entityType)),
      catchError(api.onError)
    ))
);