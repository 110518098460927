import _ from 'lodash';
import { SET_MULTIPLE_LATEST } from '../../actions/data';

export default (state = {}, action) => {
  switch (action.type) {
    case SET_MULTIPLE_LATEST:
      return {
        ...state,
        ..._.chain(action.payload.outputs)
          .toPairs()
          .map(([id, values]) => _.mapKeys(values, (value, output) => `${id}/${output}`))
          .reduce(_.extend)
          .value()
      };
    default:
      return state;
  }
};

export const getLatestOutputValue = (state, group, output) => state[`${group}/${output}`];
export const getAllLatestOutputValues = state => state;