import { defineMessages } from 'react-intl';

export default defineMessages({
  'range.title': {
    id: 'gaugeCard.range.title',
    defaultMessage: 'RANGE'
  },
  'noData': {
    id: 'gaugeCard.noData',
    defaultMessage: 'No data'
  },
  'min.label': {
    id: 'gaugeCard.min.label',
    defaultMessage: 'MIN'
  },
  'max.label': {
    id: 'gaugeCard.max.label',
    defaultMessage: 'MAX'
  }
});