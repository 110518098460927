import _ from 'lodash';
import { injectIntl } from 'react-intl';
import { Dropdown, Input, Label, List } from 'semantic-ui-react';
import { COLOUR_SEVERITIES, INVERTED_THEME, SEVERITY_COLOURS } from '../../../utils/constants';
import { getColourFromSeverity } from '../../../utils/helpers';
import AlarmLevelsDiagram from '../AlarmLevelsDiagram';
import { severityMessages } from '../AlarmRangeInput';
import styles from './AlarmLevelsInput.styles';

const AlarmLevelsInput = injectIntl(({
  alarmLevels,
  states,
  onEdit,
  intl: {
    formatMessage
  }
}) => (
  <div>
    <div style={styles.diagramWrapperDiv}>
      <AlarmLevelsDiagram
        alarmLevels={alarmLevels}
        activeStates={_.range(0, _.size(alarmLevels))} />
    </div>
    <List
      inverted={INVERTED_THEME}
      verticalAlign='middle'>
      {_.map(states, (state, index) => (
        <List.Item key={state}>
          <List.Header>
            <Input
              fluid
              className='labeled'>
              <Label
                basic
                style={styles.label}>
                {state}
              </Label>
              <Dropdown
                icon={{
                  name: 'circle',
                  style: styles.dropdownIcon(getColourFromSeverity(alarmLevels[index]))
                }}
                text={formatMessage(severityMessages[alarmLevels[index]])}
                labeled
                button
                className='icon label'
                style={styles.dropdown}>
                <Dropdown.Menu>
                  {_.map(SEVERITY_COLOURS, (colour, i) => (
                    <Dropdown.Item
                      key={colour}
                      onClick={() => onEdit(index, COLOUR_SEVERITIES[colour])}
                      icon={{
                        name: 'circle',
                        style: styles.dropdownMenuIcon(colour)
                      }}
                      text={formatMessage(severityMessages[i])} />
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </Input>
          </List.Header>
        </List.Item>
      ))}
    </List>
  </div>
));

AlarmLevelsInput.displayName = 'AlarmLevelsInput';

export default AlarmLevelsInput;