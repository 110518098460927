import _ from 'lodash';
import { createSelector } from 'reselect';
import * as fromState from '../../../state/reducers';
import { ENTITY_PROPERTY, ENTITY_TYPE, ENTITY_MODAL, SELECT_CONTEXT_LIST } from '../../constants';

export default createSelector(
  [
    state => fromState.getModalId(state, ENTITY_TYPE.MODEL, ENTITY_MODAL.EDIT),
    state => fromState.getAll(state, ENTITY_TYPE.MODEL)
  ],
  (
    id,
    allModels
  ) => {
    const inputs = id
      ? _.chain(allModels[id])
        .get(ENTITY_PROPERTY.MODEL.INPUTS)
        .map(input => ({
          id: input.name,
          attributeType: input.attributeType,
          source: input.attributeType,
          valueType: input.valueType,
          enumeration: input.enumeration,
          unitsOfMeasurement: input.unitsOfMeasurement
        }))
        .value()
      : null;

    return {
      [SELECT_CONTEXT_LIST.INPUTS]: inputs,
      [SELECT_CONTEXT_LIST.ATTRIBUTES]: inputs
    };
  }
);