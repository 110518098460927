import _ from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { bindActionCreators } from 'redux';
import { createSelector } from 'reselect';
import DeckPanel from '../../components/dashboard/DeckPanel';
import * as fromState from '../../state/reducers';
import { toggleDeckCollapsed } from '../../state/actions/dashboard';
import { toggleModal } from 'state/actions/entity';
import { ENTITY_MODAL } from '../../utils/constants';

const mapStateToProps = createSelector(
  [
    (state, { entityType }) => !_.includes(fromState.getCollapsedDecks(state), entityType),
    state => fromState.getDashboardView(state)
  ],
  (
    open,
    view
  ) => ({
    open,
    view
  })
);

const mapDispatchToProps = (dispatch, { entityType }) => ({
  ...bindActionCreators({
    onClickAdd: () => toggleModal(entityType, ENTITY_MODAL.ADD),
    onClickImport: () => toggleModal(entityType, ENTITY_MODAL.IMPORT),
    onToggleDeckCollapsed: () => toggleDeckCollapsed(entityType)
  }, dispatch)
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(DeckPanel);