import { TOGGLE_MODAL } from '../../../actions/entity';

export default (state = null, action) => {
  switch (action.type) {
    case TOGGLE_MODAL:
      return action.payload.id || null;
    default:
      return state;
  }
};

export const getId = state => state;