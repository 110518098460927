import _ from 'lodash';
import { createSelector } from 'reselect';
import { ENTITY_PROPERTY, ENTITY_TYPE } from '../../../../utils/constants';
import * as fromState from '../../../reducers';
import { getUnitPaths } from '../../entity';

export const getUnits = createSelector(
  [
    getUnitPaths,
    state => fromState.getAll(state, ENTITY_TYPE.UNIT),
    state => fromState.getAll(state, ENTITY_TYPE.UNIT_CATEGORY)
  ],
  (unitPaths, allUnits, allUnitCategories) => _.chain(allUnits)
    .map(unit => ({
      id: unit.id,
      path: unitPaths[unit.id],
      name: unit.name,
      description: unit.description,
      icon: _.get(allUnitCategories[unit.type], ENTITY_PROPERTY.UNIT_CATEGORY.ICON),
      parentPath: unitPaths[unit.unitId]
    }))
    .filter('path')
    .uniqBy('path')
    .value()
);