import _ from 'lodash';
import { Card, Grid, Segment } from 'semantic-ui-react';
import { INVERTED_THEME } from '../../../utils/constants';
import GaugeCard from '../GaugeCard';
import styles from './CardsChart.styles';

const CardsColumn = ({
  widths,
  cards,
  isLoading,
  chartType,
  selectionType,
  onClickCard
}) => _.some(cards) && (
  <Grid.Column {...widths}>
    <Segment
      className='transparent-background'
      inverted={INVERTED_THEME}
      loading={isLoading}
      style={styles.cardsSegment}>
      <Card.Group
        stackable
        centered>
        {_.map(cards, card => (
          <GaugeCard
            chartType={chartType}
            key={card.id}
            {...card}
            onClick={() => onClickCard(chartType, card.id, selectionType, !card.selected)} />
        ))}
      </Card.Group>
    </Segment>
  </Grid.Column>
);

CardsColumn.displayName = 'CardsColumn';

export default CardsColumn;