import _ from 'lodash';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { distinctUntilChanged, filter, groupBy, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { ENTITY_FORM_TYPE, TYPE } from '../../../../utils/constants';
import { formSettingChanged, FORM_VALUE_CHANGED } from '../../../actions/entity';
import * as fromState from '../../../reducers';

export default (action$, state$) => action$.pipe(
  ofType(FORM_VALUE_CHANGED),
  filter(action => action.payload.key === TYPE && action.formType === ENTITY_FORM_TYPE.ADD_OR_EDIT),
  groupBy(action => action.entityType),
  mergeMap(group => group.pipe(
    distinctUntilChanged((previousAction, latestAction) => previousAction.payload.value === latestAction.payload.value),
    filter(action => action.payload.value),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const type = fromState.getTypeById(state, action.entityType, action.payload.value);
      return from(_.toPairs(type ? type.options : {})).pipe(
        map(([name, optionSettings]) => formSettingChanged(
          action.entityType,
          action.formType,
          name,
          optionSettings.defaultValue || null
        ))
      );
    })
  ))
);