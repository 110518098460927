import _ from 'lodash';
import createCachedSelector from 're-reselect';
import * as fromState from '../../../state/reducers';
import * as chartSelectors from '../../../state/selectors/chart';
import { ENTITY_PROPERTY, SELECTION_TYPE, FEATURE_SETTINGS, VALUE_TYPE } from '../../../utils/constants';

export default createCachedSelector(
  [
    chartSelectors.getInputs,
    _.partialRight(fromState.getSelected, SELECTION_TYPE.INPUT),
    chartSelectors.getInputSensors,
    chartSelectors.getInputOffsetScale,
    chartSelectors.getInputAlarmRanges,
    chartSelectors.getColours,
    fromState.getFeaturesToggle,
    fromState.getSelectedFeature,
    fromState.getSelectedProperty
  ],
  (inputs, selectedInputs, inputSensors, inputOffsetScale, inputAlarmRanges, colours, featuresToggle, selectedFeature, selectedProperty) => {
    return _.chain(inputs)
      .filter(input => _.includes(selectedInputs, input))
      .map(input => {
        const valueType = _.get(inputSensors[input], ENTITY_PROPERTY.SENSOR.VALUE_TYPE);
        return {
          id: input,
          className: _.replace(input, / /g, '-'),
          offset: _.get(inputOffsetScale[input], 'offset'),
          scale: _.get(inputOffsetScale[input], 'scale'),
          ranges: (featuresToggle && FEATURE_SETTINGS[selectedFeature].HIDE_ALARM_RANGES)
            ? []
            : _.filter(inputAlarmRanges[input], alarmRange => alarmRange.min !== alarmRange.max),
          colour: colours[input],
          description: input,
          strokeWidthMultiplier: _.includes([VALUE_TYPE.FLOAT, VALUE_TYPE.ENUMERATION], valueType)
            ? 1
            : 0,
          valueScale: 1,
          unitsOfMeasurement: (featuresToggle && FEATURE_SETTINGS[selectedFeature].HIDE_UNITS_OF_MEASUREMENT)
            ? ''
            : `${_.get(inputSensors[input], ENTITY_PROPERTY.SENSOR.UNITS_OF_MEASUREMENT)}${featuresToggle ? FEATURE_SETTINGS[selectedFeature].UNITS_OF_MEASUREMENT_SUFFIX || '' : ''}`,
          valueType
        };
      })
      .value();
  }
)(
  (state, chartType) => chartType
);