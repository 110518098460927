import _ from 'lodash';
import { defineMessages, injectIntl } from 'react-intl';
import EnumerationStateElementContainer from '../../containers/entity/EnumerationStateElementContainer';
import SelectEntityElementContainer from '../../containers/entity/SelectEntityElementContainer';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import moment from 'moment';
import { selectListToOptions } from 'utils/helpers';
import { DATE_FORMAT, ENTITY_TYPE, INPUT_TYPE, INPUT_TYPE_VALUE_TYPE, INVERTED_THEME, OPTION_TYPE, SELECT_CONTEXT_LIST, VALIDATION_OPTIONS } from '../../utils/constants';
import { Input, Form } from 'semantic-ui-react';

const hasFlag = (settings, flag) => (settings & flag) === flag;

const messages = defineMessages({
  'typedList.multiple.noResultsMessage': {
    id: 'entityForm.typedList.multiple.noResultsMessage',
    defaultMessage: 'Type To Add Value...'
  }
});

export default injectIntl(function OptionElement({
  option,
  optionSettings,
  getSettingErrorPath,
  enumerationId,
  itemIndex,
  onChangeItem,
  errorPathPrefix,
  item,
  selectContextLists,
  errorFields,
  getTypeOptionPlaceholder,
  unitsOfMeasurement,
  intl: {
    formatMessage,
    locale
  }
}) {
  if (optionSettings.inputType === INPUT_TYPE.ENUMERATION_AND_STATE) {
    return (
      <EnumerationStateElementContainer
        key={option}
        error={_.includes(errorFields, getSettingErrorPath(itemIndex, option))}
        enumerationId={enumerationId}
        upward
        multiple={optionSettings.multiple}
        value={(item.settings || {})[option]}
        onChange={value => onChangeItem(itemIndex, option, value, true, errorPathPrefix)} />
    );
  } else if (optionSettings.inputType === INPUT_TYPE.UNIT_GEO_SHAPE) {
    return (
      <SelectEntityElementContainer
        key={option}
        error={_.includes(errorFields, getSettingErrorPath(itemIndex, option))}
        entityType={ENTITY_TYPE.UNIT}
        geoShape
        value={(item.settings || {})[option]}
        upward
        onChange={value => onChangeItem(itemIndex, option, value, true, errorPathPrefix)} />
    );
  } else if (optionSettings.optionType === OPTION_TYPE.ATTRIBUTE) {
    const attributeOptions = selectListToOptions(_.filter(
      selectContextLists[SELECT_CONTEXT_LIST.ATTRIBUTES],
      attribute => (
        attribute.valueType === INPUT_TYPE_VALUE_TYPE[optionSettings.inputType]
        && attribute.enumeration === optionSettings.enumeration
        && (!attribute.source || hasFlag(optionSettings.attributeType, attribute.source))
      )
    ));
    return (
      <Form.Select
        key={option}
        error={_.includes(errorFields, getSettingErrorPath(itemIndex, option))}
        upward
        value={(item.settings || {})[option]}
        options={attributeOptions}
        onChange={(e, { value }) => onChangeItem(itemIndex, option, value, true, errorPathPrefix)} />
    );
  } else if (optionSettings.inputType === INPUT_TYPE.DATE || hasFlag(optionSettings.validationSettings, VALIDATION_OPTIONS.IS_DATE)) {
    return (
      <Form.Field
        error={_.includes(errorFields, getSettingErrorPath(itemIndex, option))}
        className='force-fluid'>
        <SemanticDatepicker
          inverted={INVERTED_THEME}
          placeholder={getTypeOptionPlaceholder(item.type, option)}
          clearable
          error={_.includes(errorFields, getSettingErrorPath(itemIndex, option))}
          autoComplete='off'
          closable
          showToday={false}
          pointing='top left'
          format={DATE_FORMAT}
          value={(item.settings || {})[option]
            ? moment.utc((item.settings || {})[option]).local().toDate()
            : undefined}
          onChange={(event, { value }) => onChangeItem(
            itemIndex,
            option,
            value && moment(value).utc().toISOString(),
            true,
            errorPathPrefix
          )}
          name='dateTime'
          iconPosition='left'
          localization={locale} />
      </Form.Field>
    );
  } else if (optionSettings.multiple) {
    return (
      <Form.Select
        key={option}
        error={_.includes(errorFields, getSettingErrorPath(itemIndex, option))}
        fluid
        value={(item.settings || {})[option] || []}
        options={_.map((item.settings || {})[option] || [], text => ({
          key: text,
          text,
          value: text
        }))}
        multiple
        clearable
        selectOnBlur={false}
        search
        noResultsMessage={formatMessage(messages['typedList.multiple.noResultsMessage'])}
        allowAdditions
        placeholder={getTypeOptionPlaceholder(item.type, option)}
        onAddItem={(event, { value }) => onChangeItem(
          itemIndex,
          option,
          _.uniq([...(item.settings || {})[option] || [], value]),
          true,
          errorPathPrefix
        )}
        onChange={(event, { value }) => {
          event.stopPropagation();
          event.preventDefault();
          onChangeItem(itemIndex, option, value, true, errorPathPrefix);
        }} />
    );
  } else {
    return (
      <Form.Field
        key={option}
        error={_.includes(errorFields, getSettingErrorPath(itemIndex, option))}>
        <Input
          fluid
          type={hasFlag(optionSettings.validationSettings, VALIDATION_OPTIONS.IS_NUMBER)
            ? 'number'
            : 'text'}
          label={unitsOfMeasurement
            ? {
              content: unitsOfMeasurement,
              color: 'blue',
              style: {
                width: '50%'
              }
            }
            : undefined}
          labelPosition={unitsOfMeasurement ? 'right' : undefined}
          value={(item.settings || {})[option]}
          placeholder={getTypeOptionPlaceholder(item.type, option)}
          onChange={event => onChangeItem(itemIndex, option, event.target.value, true, errorPathPrefix)} />
      </Form.Field>
    );
  }
});