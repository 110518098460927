export const SET_PREDICTORS = 'dashboard/SET_PREDICTORS';
export const setPredictors = predictors => ({
  type: SET_PREDICTORS,
  payload: {
    predictors
  }
});

export const PREDICTOR_LOADED = 'dashboard/PREDICTOR_LOADED';
export const predictorLoaded = (unitId, id) => ({
  type: PREDICTOR_LOADED,
  payload: {
    unitId,
    id
  }
});

export const SET_MUTE_MODAL = 'dashboard/SET_MUTE_MODAL';
export const setMuteModal = (modal, open, id) => ({
  type: SET_MUTE_MODAL,
  payload: {
    id,
    modal,
    open
  }
});

export const SET_UNTIL = 'dashboard/SET_UNTIL';
export const setUntil = value => ({
  type: SET_UNTIL,
  payload: {
    value,
    modal: 'mute'
  }
});

export const SAVE_MUTED_UNTIL = 'dashboard/SAVE_MUTED_UNTIL';
export const saveMutedUntil = (id, until) => ({
  type: SAVE_MUTED_UNTIL,
  payload: {
    id,
    until
  }
});

export const SET_IS_SAVING = 'dashboard/SET_IS_SAVING';
export const setIsSaving = (modal, value) => ({
  type: SET_IS_SAVING,
  payload: {
    modal,
    value
  }
});

export const SET_DASHBOARD_VIEW = 'dashboard/SET_DASHBOARD_VIEW';
export const setDashboardView = view => ({
  type: SET_DASHBOARD_VIEW,
  payload: {
    view
  }
});

export const CHANGE_UNIT_PATH = 'dashboard/CHANGE_UNIT_PATH';
export const changeUnitPath = path => ({
  type: CHANGE_UNIT_PATH,
  payload: {
    path
  }
});

export const SET_CARD_SIZE = 'dashboard/SET_CARD_SIZE';
export const setCardSize = value => ({
  type: SET_CARD_SIZE,
  payload: {
    value
  }
});

export const SET_SHOW_CARD_DETAILS = 'dashboard/SET_SHOW_CARD_DETAILS';
export const setShowCardDetails = value => ({
  type: SET_SHOW_CARD_DETAILS,
  payload: {
    value
  }
});

export const TOGGLE_DECK_COLLAPSED = 'dashboard/TOGGLE_DECK_COLLAPSED';
export const toggleDeckCollapsed = entityType => ({
  type: TOGGLE_DECK_COLLAPSED,
  payload: {
    entityType
  }
});