import _ from 'lodash';
import ModelAvatar from 'components/shared/ModelAvatar';
import { defineMessages, FormattedMessage } from 'react-intl';
import { ENTITY_CONFIG, ENTITY_FORM_ELEMENT_TYPE } from 'utils/constants';
import { unescapeRegex } from 'utils/helpers';
import uuid from 'uuid/v1';
import selectContextListsSelector from './selectContextListsSelector';
import textComponent from './textComponent';

const messages = defineMessages({
  'title': {
    id: 'model.title',
    defaultMessage: 'Models'
  },
  'description': {
    id: 'model.description',
    defaultMessage: 'Models are the machine learning algorithms powering PROPHES'
  },
  'columns.name.title': {
    id: 'model.columns.name.title',
    defaultMessage: 'Name'
  },
  'columns.type.title': {
    id: 'model.columns.type.title',
    defaultMessage: 'Type'
  },
  'columns.description.title': {
    id: 'model.columns.description.title',
    defaultMessage: 'Description'
  },
  'columns.author.title': {
    id: 'model.columns.author.title',
    defaultMessage: 'Author'
  },
  'form.name.label': {
    id: 'model.form.name.label',
    defaultMessage: 'Name'
  },
  'form.name.placeholder': {
    id: 'model.form.name.placeholder',
    defaultMessage: 'Name'
  },
  'form.description.label': {
    id: 'model.form.description.label',
    defaultMessage: 'Description'
  },
  'form.description.placeholder': {
    id: 'model.form.description.placeholder',
    defaultMessage: 'Description'
  },
  'type.docker.name': {
    id: 'model.type.docker.name',
    defaultMessage: 'Docker Service'
  },
  'type.docker.options.serviceName.label': {
    id: 'model.type.docker.options.serviceName.label',
    defaultMessage: 'Service Name'
  },
  'type.docker.options.serviceName.placeholder': {
    id: 'model.type.docker.options.serviceName.placeholder',
    defaultMessage: 'Service Name'
  },
  'type.decision.name': {
    id: 'model.type.decision.name',
    defaultMessage: 'Decision Model'
  },
  'type.decision.options.decisionModel.label': {
    id: 'model.type.decision.options.decisionModel.label',
    defaultMessage: 'Decision Model'
  },
  'type.decision.options.decisionModel.placeholder': {
    id: 'model.type.decision.options.decisionModel.placeholder',
    defaultMessage: 'Decision Model'
  },
  'type.decision.options.window.label': {
    id: 'model.type.decision.options.window.label',
    defaultMessage: 'Decision Window'
  },
  'type.decision.options.window.placeholder': {
    id: 'model.type.decision.options.window.placeholder',
    defaultMessage: 'Decision Window'
  },
  'type.decision.options.outputUnitsOfMeasurement.label': {
    id: 'model.type.decision.options.outputUnitsOfMeasurement.label',
    defaultMessage: 'Output Units of Measurement'
  },
  'type.decision.options.outputUnitsOfMeasurement.placeholder': {
    id: 'model.type.decision.options.outputUnitsOfMeasurement.placeholder',
    defaultMessage: 'Output Units of Measurement'
  },
  'type.rule.name': {
    id: 'model.type.rule.name',
    defaultMessage: 'Rule'
  },
  'type.rule.options.window.label': {
    id: 'model.type.rule.options.window.label',
    defaultMessage: 'Time Window'
  },
  'type.rule.options.window.placeholder': {
    id: 'model.type.rule.options.window.placeholder',
    defaultMessage: 'Time Window'
  },
  'type.rule.options.conditions.label': {
    id: 'model.type.rule.options.conditions.label',
    defaultMessage: 'Conditions'
  },
  'type.rule.options.conditions.placeholder': {
    id: 'model.type.rule.options.conditions.placeholder',
    defaultMessage: 'Conditions'
  },
  'type.featureGroup.name': {
    id: 'model.type.featureGroup.name',
    defaultMessage: 'Feature Group'
  },
  'type.featureGroup.options.window.label': {
    id: 'model.type.featureGroup.options.window.label',
    defaultMessage: 'Time Window'
  },
  'type.featureGroup.options.window.placeholder': {
    id: 'model.type.featureGroup.options.window.placeholder',
    defaultMessage: 'Time Window'
  },
  'type.featureGroup.options.features.label': {
    id: 'model.type.featureGroup.options.features.label',
    defaultMessage: 'Features'
  },
  'type.featureGroup.options.features.placeholder': {
    id: 'model.type.featureGroup.options.features.placeholder',
    defaultMessage: 'Features'
  },
  'type.sensorGroup.name': {
    id: 'model.type.sensorGroup.name',
    defaultMessage: 'Sensor Group'
  },
  'type.attributeGroup.name': {
    id: 'model.type.attributeGroup.name',
    defaultMessage: 'Attribute Group'
  },
  'type.perceptron.name': {
    id: 'model.type.perceptron.name',
    defaultMessage: 'Perceptron'
  },
  'type.perceptron.options.window.label': {
    id: 'model.type.perceptron.options.window.label',
    defaultMessage: 'Time Window'
  },
  'type.perceptron.options.window.placeholder': {
    id: 'model.type.perceptron.options.window.placeholder',
    defaultMessage: 'Time Window'
  },
  'type.perceptron.options.features.label': {
    id: 'model.type.perceptron.options.features.label',
    defaultMessage: 'Features & Weights'
  },
  'type.perceptron.options.features.placeholder': {
    id: 'model.type.perceptron.options.features.placeholder',
    defaultMessage: 'Features & Weights'
  },
  'type.perceptron.options.bias.label': {
    id: 'model.type.perceptron.options.bias.label',
    defaultMessage: 'Bias'
  },
  'type.perceptron.options.bias.placeholder': {
    id: 'model.type.perceptron.options.bias.placeholder',
    defaultMessage: 'Bias'
  }
});

export default {
  apiPath: 'model',
  pageUrl: 'models',
  icon: 'lightbulb outline',
  messages,
  conditions: true,
  textComponent,
  selectContextListsSelector,
  fetchAllOnLoad: true,
  columns: [
    {
      name: 'name',
      width: 3,
      render: ({ name }) => name
    },
    {
      name: 'type',
      width: 3,
      render: ({ type }) => (
        <FormattedMessage
          {...messages[`type.${type}.name`]} />
      )
    },
    {
      name: 'description',
      width: 3,
      render: ({ description }) => description
    },
    {
      name: 'author',
      width: 3,
      render: ({ author }) => (
        <ModelAvatar author={author} />
      )
    }
  ],
  [ENTITY_CONFIG.SEARCH]: [
    {
      name: 'name',
      type: ENTITY_FORM_ELEMENT_TYPE.INPUT
    },
    {
      name: 'description',
      type: ENTITY_FORM_ELEMENT_TYPE.INPUT
    }
  ],
  [ENTITY_CONFIG.CREATE_SEARCH_QUERY]: ({
    name,
    description
  }, {
    by,
    direction
  }) => {
    const filter = {};
    if (name) {
      filter.Name = {
        $regex: _.escapeRegExp(name),
        $options: 'i'
      };
    };
    if (description) {
      filter.Description = {
        $regex: _.escapeRegExp(description),
        $options: 'i'
      };
    };
    return {
      filter
    };
  },
  [ENTITY_CONFIG.PARSE_SEARCH_FILTER]: filter => ({
    name: unescapeRegex(_.get(filter, 'Name.$regex') || ''),
    description: unescapeRegex(_.get(filter, 'Description.$regex') || '')
  }),
  form: [{
    name: 'name',
    type: ENTITY_FORM_ELEMENT_TYPE.INPUT
  }, {
    name: 'description',
    type: ENTITY_FORM_ELEMENT_TYPE.TEXT_AREA
  }, {
    type: 'types'
  }],
  createEntity: values => ({
    id: uuid(),
    name: values.name,
    description: values.description,
    type: values.type,
    settings: values.settings,
    unitId: null
  }),
  populateForm: entity => ({
    name: entity.name,
    description: entity.description,
    type: entity.type,
    settings: entity.settings
  })
};