import { LOCATION_CHANGE } from 'connected-react-router';
import { ofType } from 'redux-observable';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { changeUnitPath } from '../../actions/dashboard';
import { matchesPath, getPathParams } from '../../../utils/helpers';
import { PAGE_URL } from '../../../utils/constants';

export default action$ => action$.pipe(
  ofType(LOCATION_CHANGE),
  map(action => matchesPath(action.payload.location.pathname, PAGE_URL.UNIT, { end: false })
    ? getPathParams(PAGE_URL.UNIT, action.payload.location.pathname, { end: false }).unitPath
    : null),
  distinctUntilChanged(),
  map(changeUnitPath)
);