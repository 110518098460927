import { SET_REVIEW_ID } from '../../actions/playback';

export default (state = null, action) => {
  switch (action.type) {
    case SET_REVIEW_ID:
      return action.payload.id;
    default:
      return state;
  }
};

export const getReviewId = state => state;