import { FORM_SETTING_CHANGED, FETCH_TYPES_FULFILLED, SET_FORM_VALUES, FORM_COMPLEX_SETTING_CHANGED, FORM_SETTING_SEARCH_CHANGED, FORM_VALUE_CHANGED } from '../../../actions/entity';
import _ from 'lodash';
import conditionsReducer from './conditions';
import { INPUT_TYPE, TYPE } from '../../../../utils/constants';

export default (state = {}, action) => {
  switch (action.type) {
    case FORM_VALUE_CHANGED:
      return action.payload.key === TYPE
        ? {}
        : state;
    case FORM_SETTING_CHANGED:
    case FORM_SETTING_SEARCH_CHANGED:
      return {
        ...state,
        [action.payload.key]: action.payload.value
      };
    case FORM_COMPLEX_SETTING_CHANGED:
      switch (action.inputType) {
        case INPUT_TYPE.CONDITIONS:
          return {
            ...state,
            [action.payload.key]: conditionsReducer(state[action.payload.key], action.payload.action)
          };
        default:
          return state;
      };
    case FETCH_TYPES_FULFILLED:
      return _.size(action.payload.types) === 1 ? _.mapValues(action.payload.types[0].options, val => val.defaultValue || null) : state;
    case SET_FORM_VALUES:
      return action.payload.formValues.settings || {};
    default:
      return state;
  }
};

export const getSetting = (state, key) => state[key];
export const getSettings = state => state;