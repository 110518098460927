import _ from 'lodash';
import * as chartSelectors from '../../../state/selectors/chart';
import createBucketedDataSelector from './createBucketedDataSelector';
import createDataSelector from './createDataSelector';

export default createDataSelector(
  createBucketedDataSelector(chartSelectors.getFunctionData),
  chartSelectors.getFunctionSensors,
  chartSelectors.getFunctionDescriptions,
  chartSelectors.getFunctionAlarmRanges,
  chartSelectors.getFunctionEnumerations,
  _.constant(1)
);