import { PLAYBACK_MODE } from '../../../utils/constants';
import { SET_PLAYBACK_MODE, SET_IS_PLAYING, JUMP_TO_LIVE_PLAYBACK } from '../../actions/playback';

export default (state = PLAYBACK_MODE.LIVE, action) => {
  switch (action.type) {
    case SET_PLAYBACK_MODE:
      return action.payload.mode;
    case JUMP_TO_LIVE_PLAYBACK:
      return PLAYBACK_MODE.LIVE;    
    case SET_IS_PLAYING:
      return (!action.payload.value && state === PLAYBACK_MODE.LIVE)        
        ? PLAYBACK_MODE.HISTORICAL
        : state;
    default:
      return state;
  }
};

export const getMode = state => state;