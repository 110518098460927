import { Loader, Modal } from 'semantic-ui-react';
import { defineMessages, injectIntl } from 'react-intl';

const messages = defineMessages({
  'header': {
    id: 'loadingModal.header',
    defaultMessage: 'Loading'
  }
});

export default injectIntl(function LoadingModal({
  isLoading,
  intl: {
    formatMessage
  }
}) {
  return (
    <Modal
      open={isLoading}
      size='mini'>
      <Modal.Header>
        {formatMessage(messages['header'])}
      </Modal.Header>
      <Modal.Content>
        <Loader
          active
          inline='centered' />
      </Modal.Content>
    </Modal>
  );
});