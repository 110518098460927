import { defineMessages } from 'react-intl';

export default defineMessages({
  'apply': {
    id: 'explorerPage.filtersMenu.apply',
    defaultMessage: 'Apply'
  },
  'cancel': {
    id: 'explorerPage.filtersMenu.cancel',
    defaultMessage: 'Cancel'
  },
  'startDate': {
    id: 'explorerPage.filtersMenu.startDate',
    defaultMessage: 'Start Date'
  },
  'endDate': {
    id: 'explorerPage.filtersMenu.endDate',
    defaultMessage: 'End Date'
  },
  'chooseFilterAttribute': {
    id: 'explorerPage.filtersMenu.chooseFilterAttribute',
    defaultMessage: 'Filter points based on the following attribute:'
  },
  'noFilter': {
    id: 'explorerPage.filtersMenu.noFilter',
    defaultMessage: 'None'
  },
  'chooseFilterValue': {
    id: 'explorerPage.filtersMenu.chooseFilterValue',
    defaultMessage: 'Remove points where the attribute\'s value is:'
  }
});