import _ from 'lodash';
import { createSelector } from 'reselect';
import * as fromState from '../../../reducers';
import { getUnitPaths } from '../../entity';
import { matchesPath, getPathParams } from '../../../../utils/helpers';
import { PAGE_URL, ENTITY_TYPE } from '../../../../utils/constants';
import entityConfig from '../../../../utils/entities';
import { RootState } from 'index';

export const getUnitId = createSelector(
  [
    (state: RootState) => fromState.getUnitPath(state),
    (state: RootState) => getUnitPaths(state),
    (state: RootState) => state.router.location.pathname
  ],
  (unitPath, unitPaths, pathname: string) => {
    if (matchesPath(pathname, PAGE_URL.ENTITY)) {
      const params = getPathParams(PAGE_URL.ENTITY, pathname);
      if (params.pageUrl === entityConfig[ENTITY_TYPE.UNIT].pageUrl) {
        return params.entityId as (string | null);
      };
    };
    return _.invert(unitPaths)[unitPath] || null as (string | null);
  }
);