import { defineMessages } from 'react-intl';

export default defineMessages({
  'float': {
    id: 'valueType.float',
    defaultMessage: 'Float'
  },
  'date': {
    id: 'valueType.date',
    defaultMessage: 'Date'
  },
  'string': {
    id: 'valueType.string',
    defaultMessage: 'String'
  },
  'enumeration': {
    id: 'valueType.enumeration',
    defaultMessage: 'Enumeration'
  },
  'geoPoint': {
    id: 'valueType.geoPoint',
    defaultMessage: 'Geo Point'
  }
});