import { connect } from 'react-redux';
import * as chartSelectors from '../../state/selectors/chart';
import TagSearch from '../../components/chart/TrendSearch';
import * as fromState from '../../state/reducers';
import { setCompareTrend, setCompareOpen, setCompareAll, setCompareLabels, setCompareFromDate, setCompareToDate, loadMoreTrends } from '../../state/actions/chart';
import { ENTITY_TYPE, LOADING } from '../../utils/constants';
import { bindActionCreators } from 'redux';
import _ from 'lodash';

const mapStateToProps = state => ({
  all: fromState.getCompareAll(state),
  fromDate: fromState.getCompareFromDate(state),
  toDate: fromState.getCompareToDate(state),
  labels: fromState.getCompareLabels(state),
  trends: chartSelectors.getTrends(state),
  labelOptions: fromState.getLabels(state, ENTITY_TYPE.TREND),
  compareTrend: fromState.getById(state, ENTITY_TYPE.TREND, fromState.getCompareTrend(state)),
  hasMoreTrends: fromState.getTrendCount(state) > _.size(fromState.getTrendIds(state)),
  loading: fromState.getChartIsLoading(state, LOADING.TRENDS)
});

const mapDispatchToProps = dispatch => ({
  onClickTrend: trendId => {
    dispatch(setCompareTrend(trendId));
    dispatch(setCompareOpen(false));
  },
  ...bindActionCreators({
    onChangeAll: setCompareAll,
    onChangeLabels: setCompareLabels,
    onChangeFromDate: setCompareFromDate,
    onChangeToDate: setCompareToDate,
    onClickLoadMore: loadMoreTrends
  }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(TagSearch);