import { defineMessages } from 'react-intl';

export default defineMessages({
  'latest.float': {
    id: 'feature.latest.float',
    defaultMessage: 'Latest Value (Float)'
  },
  'latest.date': {
    id: 'feature.latest.date',
    defaultMessage: 'Latest Value (Date)'
  },
  'latest.string': {
    id: 'feature.latest.string',
    defaultMessage: 'Latest Value (String)'
  },
  'latest.enumeration': {
    id: 'feature.latest.enumeration',
    defaultMessage: 'Latest Value (Enumeration)'
  },
  'latest.geoPoint': {
    id: 'feature.latest.geoPoint',
    defaultMessage: 'Latest Value (Geo Point)'
  },
  'rateOfChange': {
    id: 'feature.rateOfChange',
    defaultMessage: 'Rate Of Change'
  },
  'stepChange': {
    id: 'feature.stepChange',
    defaultMessage: 'Step Change'
  },
  'mean': {
    id: 'feature.mean',
    defaultMessage: 'Mean'
  },
  'median': {
    id: 'feature.median',
    defaultMessage: 'Median'
  },
  'standardDeviation': {
    id: 'feature.standardDeviation',
    defaultMessage: 'Standard Deviation'
  },
  'maximum': {
    id: 'feature.maximum',
    defaultMessage: 'Maximum'
  },
  'minimum': {
    id: 'feature.minimum',
    defaultMessage: 'Minimum'
  },
  'range': {
    id: 'feature.range',
    defaultMessage: 'Range'
  },
  'linearForecast': {
    id: 'feature.linearForecast',
    defaultMessage: 'Linear Forecast'
  },
  'skewness': {
    id: 'feature.skewness',
    defaultMessage: 'Skewness'
  },
  'kurtosis': {
    id: 'feature.kurtosis',
    defaultMessage: 'Kurtosis'
  },
  'impulse': {
    id: 'feature.impulse',
    defaultMessage: 'Impulse'
  },
  'differenceFromMean': {
    id: 'feature.differenceFromMean',
    defaultMessage: 'Difference From Mean'
  },
  'numberOfStateChanges': {
    id: 'feature.numberOfStateChanges',
    defaultMessage: 'Number Of State Changes'
  },
  'deviationFrom': {
    id: 'feature.deviationFrom',
    defaultMessage: 'Deviation From'
  },
  'rangeOfDeviationFrom': {
    id: 'feature.rangeOfDeviationFrom',
    defaultMessage: 'Range Of Deviation From'
  },
  'dividedBy': {
    id: 'feature.dividedBy',
    defaultMessage: 'Divided By'
  },
  'distinctStates': {
    id: 'feature.distinctStates',
    defaultMessage: 'Distinct States'
  }
});