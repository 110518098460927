import _ from 'lodash';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import AuthMenu from '../../../components/auth/AuthMenu';
import * as fromState from '../../../state/reducers';
import { switchTenant } from '../../../state/actions/auth';
import { ENTITY_PROPERTY } from '../../../utils/constants';

export const mapStateToProps = createSelector(
  [fromState.getUser],
  user => ({
    id: _.get(user, ENTITY_PROPERTY.USER.ID),
    name: _.get(user, ENTITY_PROPERTY.USER.NAME),
    tenantId: _.get(user, ENTITY_PROPERTY.USER_MAPPING.TENANT_ID),
    tenants: _.chain(user)
      .get(ENTITY_PROPERTY.USER.USER_MAPPINGS)
      .map(({ tenantId: id, tenantName: name }) => ({
        id,
        name 
      }))
      .value()
  })
);

const mapDispatchToProps = {
  onClickTenant: switchTenant
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthMenu);