import { ofType } from 'redux-observable';
import { EDIT_FULFILLED } from '../../../actions/entity';
import { filter, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { setFunctionDescriptionEditText, setEditingFunction } from '../../../actions/chart';
import { CHART_TYPE } from '../../../../utils/constants';

export default action$ => action$.pipe(
  ofType(EDIT_FULFILLED),
  filter(action => action.entityType === 'predictor'),
  mergeMap(() => of(
    setFunctionDescriptionEditText(CHART_TYPE.MAIN, ''),
    setEditingFunction(CHART_TYPE.MAIN, null),
    setFunctionDescriptionEditText(CHART_TYPE.COMPARE, ''),
    setEditingFunction(CHART_TYPE.COMPARE, null)
  ))
);