import { Grid, Button, Popup } from 'semantic-ui-react';
import { injectIntl, defineMessages } from 'react-intl';
import { INVERTED_THEME, COLOUR, DASHBOARD_VIEW, CARD_SIZE, ROLE, LINE_CHART_YAXIS_WIDTH } from '../../utils/constants';
import PlaybackButtonsContainer from '../../containers/playback/PlaybackButtonsContainer';
import PopupButton from '../../components/shared/PopupButton';
import IsInRole from 'containers/auth/IsInRole';

const messages = defineMessages({
  'unitsButton.popupText': {
    id: 'dashboardButtonBar.unitsButton.popupText',
    defaultMessage: 'Units'
  },
  'unitTypesButton.popupText': {
    id: 'dashboardButtonBar.unitTypesButton.popupText',
    defaultMessage: 'Unit Types'
  },
  'cardSizeButton.small.popupText': {
    id: 'dashboardButtonBar.cardSizeButton.small.popupText',
    defaultMessage: 'Small Cards'
  },
  'cardSizeButton.medium.popupText': {
    id: 'dashboardButtonBar.cardSizeButton.medium.popupText',
    defaultMessage: 'Medium Cards'
  },
  'cardSizeButton.large.popupText': {
    id: 'dashboardButtonBar.cardSizeButton.large.popupText',
    defaultMessage: 'Large Cards'
  },
  'hideCardDetailsButton.popupText': {
    id: 'dashboardButtonBar.hideCardDetailsButton.popupText',
    defaultMessage: 'Hide Card Details'
  },
  'showCardDetailsButton.popupText': {
    id: 'dashboardButtonBar.showCardDetailsButton.popupText',
    defaultMessage: 'Show Card Details'
  }
});

export default injectIntl(function DashboardButtonBar({
  view,
  onChangeView,
  cardSize,
  onChangeCardSize,
  showCardDetails,
  onChangeShowCardDetails,
  intl: {
    formatMessage
  }
}) {
  return (
    <Grid className='button-bar'>
      <Grid.Column
        computer={5}
        largeScreen={4}
        widescreen={3}>
        <IsInRole role={ROLE.VIEW_UNIT_TYPE}>
          <Button.Group>
            <Popup
              inverted={INVERTED_THEME}
              content={formatMessage(messages['unitsButton.popupText'])}
              style={{
                border: '1px solid white',
                background: COLOUR.MEDIUM_GREY
              }}
              trigger={(
                <Button
                  icon='cube'
                  primary={view === DASHBOARD_VIEW.UNITS}
                  onClick={view === DASHBOARD_VIEW.UNITS
                    ? null
                    : (() => onChangeView(DASHBOARD_VIEW.UNITS))}
                  style={{ border: 0 }} />
              )} />
            <Popup
              inverted={INVERTED_THEME}
              content={formatMessage(messages['unitTypesButton.popupText'])}
              style={{
                border: '1px solid white',
                background: COLOUR.MEDIUM_GREY
              }}
              trigger={(
                <Button
                  icon='cubes'
                  primary={view === DASHBOARD_VIEW.UNIT_TYPES}
                  onClick={view === DASHBOARD_VIEW.UNIT_TYPES
                    ? null
                    : (() => onChangeView(DASHBOARD_VIEW.UNIT_TYPES))}
                  style={{ border: 0 }} />
              )} />
          </Button.Group>
        </IsInRole>

        <Button.Group>
          <PopupButton
            popupText={formatMessage(messages['cardSizeButton.small.popupText'])}
            icon='th'
            primary={cardSize === CARD_SIZE.SMALL}
            onClick={() => onChangeCardSize(CARD_SIZE.SMALL)}
            style={{
              border: 0,
              marginLeft: '1em'
            }} />
          <PopupButton
            popupText={formatMessage(messages['cardSizeButton.medium.popupText'])}
            icon={{
              name: 'th large',
              style: { fontSize: '1em' }
            }}
            primary={cardSize === CARD_SIZE.MEDIUM}
            onClick={() => onChangeCardSize(CARD_SIZE.MEDIUM)} />
          <PopupButton
            popupText={formatMessage(messages['cardSizeButton.large.popupText'])}
            icon='square'
            primary={cardSize === CARD_SIZE.LARGE}
            onClick={() => onChangeCardSize(CARD_SIZE.LARGE)} />
        </Button.Group>
        <Button.Group>
          <PopupButton
            popupText={formatMessage(messages['hideCardDetailsButton.popupText'])}
            icon='window minimize outline'
            primary={!showCardDetails}
            onClick={() => onChangeShowCardDetails(false)}
            style={{
              border: 0,
              marginLeft: '1em'
            }} />
          <PopupButton
            popupText={formatMessage(messages['showCardDetailsButton.popupText'])}
            icon='align justify'
            primary={showCardDetails}
            onClick={() => onChangeShowCardDetails(true)} />
        </Button.Group>
      </Grid.Column>
      <Grid.Column
        computer={11}
        largeScreen={10}
        widescreen={11}
        style={{ paddingLeft: `${LINE_CHART_YAXIS_WIDTH}px` }}>
        <PlaybackButtonsContainer />
      </Grid.Column>
    </Grid>
  );
});