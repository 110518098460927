import _ from 'lodash';
import { ofType } from 'redux-observable';
import { of, merge } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom, first, delay } from 'rxjs/operators';
import { ADD, addError, addFulfilled, add, ADD_ERROR, ADD_FULFILLED, clearAddError } from '../../actions/entity';
import * as api from '../../../utils/api';
import * as fromState from '../../reducers';
import { ENTITY_CONFIG, CREATE_TYPE_ID, ENTITY_FORM_TYPE } from '../../../utils/constants';
import entityConfig from '../../../utils/entities';
import { takeUntilAppReset } from 'utils/helpers';

export default (action$, state$) => action$.pipe(
  ofType(ADD),
  withLatestFrom(state$),
  mergeMap(([action, state]) => {
    const typeEntityType = entityConfig[action.entityType][ENTITY_CONFIG.TYPE_ENTITY_TYPE];
    const transformAfterTypeCreation = entityConfig[action.entityType].transformAfterTypeCreation;
    const selectContextListsSelector = entityConfig[action.entityType].selectContextListsSelector;

    if (!typeEntityType || action.payload.entity.type !== CREATE_TYPE_ID) {
      return of(
        [action, state],
        ...typeEntityType
          ? [[clearAddError(typeEntityType), state]]
          : []
      );
    }

    const addTypeAction = add(
      typeEntityType,
      entityConfig[typeEntityType].createEntity({
        ...fromState.getFormValues(
          state,
          typeEntityType,
          ENTITY_FORM_TYPE.ADD_OR_EDIT
        ),
        ..._.chain(entityConfig[action.entityType][ENTITY_CONFIG.TYPE_INHERITED_FIELDS])
          .map(field => [field, action.payload.entity[field]])
          .fromPairs()
          .value()
      })
    );

    return merge(
      of([addTypeAction, state]),
      action$.pipe(
        ofType(ADD_FULFILLED, ADD_ERROR),
        first(resultAction => resultAction.entityType === typeEntityType),
        mergeMap(resultAction => {
          // If the addition of the type fails, also report failure to add the original entity
          if (resultAction.type === ADD_ERROR) {
            return of([addError(action.entityType, resultAction.payload.err), state]);
          }

          // If addition of type succeeds, perform any necessary transformations on the
          // original entity and then add it.
          const transformedEntity = transformAfterTypeCreation
            ? transformAfterTypeCreation(
              action.payload.entity,
              resultAction.payload.entity,
              selectContextListsSelector
                ? selectContextListsSelector(state, {})
                : {}
            )
            : action.payload.entity;

          const addTransformedEntityAction = add(action.entityType, {
            ...transformedEntity,
            type: resultAction.payload.entity.id
          });

          return of([addTransformedEntityAction, state]).pipe(delay(2500));
        })
      )
    );
  }),
  mergeMap(([action, state]) => {
    if (action.type !== ADD) {
      return of(action);
    }

    return api.create({
      controller: entityConfig[action.entityType][ENTITY_CONFIG.API_PATH],
      entity: action.payload.entity,
      token: fromState.getUser(state).token
    }).pipe(
      takeUntilAppReset(action$),
      map(({ response }) => addFulfilled(action.entityType, response)),
      catchError((err, caught) => {
        if (err.status === 400) {
          return of(addError(action.entityType, err));
        }
        return api.onError(err, caught);
      })
    );
  })
);