import _ from 'lodash';
import baseMoment from 'moment';
import { extendMoment } from 'moment-range';
import createCachedSelector from 're-reselect';
import * as fromState from '../../../state/reducers';
import { DATE_FORMAT } from '../../../utils/constants';
import { consecutivePairs } from '../../../utils/helpers';
import getBucketSize from './getBucketSize';
const moment = extendMoment(baseMoment);

const getRangesFromBoundaries = boundaries => _.map(consecutivePairs(boundaries), _.spread(moment.range));
const getBoundaries = (range, period, step) => Array.from(range.by(period, { step }));

export default createCachedSelector(
  [
    fromState.getToDate,
    fromState.getFromDate,
    getBucketSize
  ],
  (toDate, fromDate, bucketSize) => {
    const range = moment.range(moment.utc(fromDate, DATE_FORMAT), moment.utc(toDate, DATE_FORMAT));
    const { period, step } = bucketSize;
    return getRangesFromBoundaries(getBoundaries(range, period, step));
  }
)(
  (state, chartType) => chartType
);