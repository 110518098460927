import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DashboardCard from '../../components/dashboard/DashboardCard';
import * as fromState from '../../state/reducers';
import { ENTITY_TYPE, SEVERITY_COLOURS, ENTITY_MODAL } from '../../utils/constants';
import { deleteUnitTypeParameter, toggleModal } from '../../state/actions/entity';

const mapStateToProps = createSelector(
  [
    (state, { unitTypeParameter }) => unitTypeParameter,
    state => fromState.getShowCardDetails(state)
  ],
  (unitTypeParameter, showDetails) => ({
    id: unitTypeParameter.id,
    name: unitTypeParameter.name,
    description: unitTypeParameter.description,
    valueType: unitTypeParameter.valueType,
    fill: SEVERITY_COLOURS[0],
    active: true,
    noModel: true,
    canMute: false,
    canDelete: true,
    canConfigure: false,
    entityType: ENTITY_TYPE.UNIT_TYPE_PARAMETER,
    showDetails
  })
);

const mapDispatchToProps = (dispatch, { unitTypeParameter }) => bindActionCreators({
  onClickDelete: () => toggleModal(ENTITY_TYPE.UNIT_TYPE_PARAMETER, ENTITY_MODAL.DELETE, unitTypeParameter.id),
  onDelete: () => deleteUnitTypeParameter(unitTypeParameter)
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DashboardCard);