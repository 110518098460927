import _ from 'lodash';
import { ofType } from 'redux-observable';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { fetchTypesFulfilled, FETCH_TYPES, fetchTypes } from '../../actions/entity';
import * as api from '../../../utils/api';
import * as fromState from '../../reducers';
import { ENTITY_CONFIG } from '../../../utils/constants';
import { merge, of } from 'rxjs';
import entityConfig from '../../../utils/entities';
import { takeUntilAppReset } from 'utils/helpers';

export default (action$, state$) => action$.pipe(
  ofType(FETCH_TYPES),
  withLatestFrom(state$),
  mergeMap(([action, state]) => {
    const typeEntityType = entityConfig[action.entityType][ENTITY_CONFIG.TYPE_ENTITY_TYPE];
    return merge(
      ...entityConfig[action.entityType][ENTITY_CONFIG.DISABLE_FETCH_TYPES]
        ? []
        : [
          api.types({
            controller: entityConfig[action.entityType][ENTITY_CONFIG.API_PATH],
            prefix: entityConfig[action.entityType][ENTITY_CONFIG.API_PREFIX],
            parentId: action.payload.parentId,
            token: fromState.getUser(state).token
          }).pipe(
            takeUntilAppReset(action$),
            map(_.partial(fetchTypesFulfilled, action.entityType)),
            catchError(api.onError)
          )
        ],
      ...typeEntityType
        ? [of(fetchTypes(typeEntityType))]
        : []
    );
  })
);