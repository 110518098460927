import { Button, Modal, Message } from 'semantic-ui-react';
import AnimatedButton from '../shared/AnimatedButton';
import { defineMessages, injectIntl } from 'react-intl';
import { ENTITY_MODAL } from '../../utils/constants';
import apiMessages from '../../utils/i18n/shared/api';
import _ from 'lodash';

const messages = defineMessages({
  'header.text': {
    id: 'deleteEntityModal.header.text',
    defaultMessage: 'Delete'
  },
  'cancelButton.text': {
    id: 'deleteEntityModal.cancelButton.text',
    defaultMessage: 'Cancel'
  },
  'deleteButton.text': {
    id: 'deleteEntityModal.deleteButton.text',
    defaultMessage: 'Delete'
  },
  'error.validationFailed.header': {
    id: 'deleteEntityModal.error.validationFailed.header',
    defaultMessage: 'Validation Failed'
  }
});

const DeleteEntityModal = injectIntl(({
  open,
  id,
  trigger,
  toggleModal,
  isDeleting,
  errorCodes,
  onDelete,
  intl: {
    formatMessage
  }
}) => (
  <Modal
    open={open}
    trigger={trigger}
    centered
    closeOnDimmerClick={false}
    closeIcon
    size='small'
    closeOnEscape
    onClose={() => toggleModal(ENTITY_MODAL.DELETE, id)}>
    <Modal.Header content={formatMessage(messages['header.text'])} />
    {_.some(errorCodes) && (
      <Modal.Content>
        <Message
          negative
          list={_.map(errorCodes, code => apiMessages[code] ? formatMessage(apiMessages[code]) : code)}
          header={formatMessage(messages['error.validationFailed.header'])} />
      </Modal.Content>
    )}
    <Modal.Actions>
      <Button
        content={formatMessage(messages['cancelButton.text'])}
        onClick={(e, data) => {
          e.preventDefault();
          e.stopPropagation();
          toggleModal(ENTITY_MODAL.DELETE, id);
        }} />
      <AnimatedButton
        text={formatMessage(messages['deleteButton.text'])}
        icon='trash'
        loading={isDeleting}
        negative
        onClick={(e, data) => {
          e.preventDefault();
          e.stopPropagation();
          onDelete(id);
        }} />
    </Modal.Actions>
  </Modal>
));

DeleteEntityModal.displayName = 'DeleteEntityModal';

export default DeleteEntityModal;