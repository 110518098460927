import { COLOUR } from '../../utils/constants';

export default {
  mainLayout: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%'
  },
  tab: {
    flex: '0 0 50%', 
    paddingRight: '50px'
  },
  tabMenu: {
    height: '100%',
    borderRight: 'solid 1px #555'
  },
  chartContainer: {
    flex: '0 0 50%'
  },
  tabPopup: {
    background: COLOUR.MEDIUM_GREY,
    border: '1px solid white',
    position: 'fixed',
    left: '56px',
    width: '50px',
    whiteSpace: 'nowrap'
  }
};