import { Fragment } from 'react';
import { Form, Grid, Segment, Header, Message, Input, Icon } from 'semantic-ui-react';
import AnimatedButton from '../shared/AnimatedButton';
import { injectIntl, defineMessages } from 'react-intl';
import _ from 'lodash';
import apiMessages from '../../utils/i18n/shared/api';
import IsNotAuthenticated from '../../containers/auth/IsNotAuthenticated';
import IsAuthenticated from '../../containers/auth/IsAuthenticated';
import { INVERTED_THEME, COLOUR } from '../../utils/constants';

const rootMessages = defineMessages({
  'alreadySignedIn': {
    id: 'authForm.alreadySignedIn',
    defaultMessage: 'You are already logged in!'
  },
  'error.validationFailed.header': {
    id: 'authForm.error.validationFailed.header',
    defaultMessage: 'Validation Failed'
  },
  'message.header': {
    id: 'authForm.message.header',
    defaultMessage: 'Success'
  },
  'message.passwordChanged': {
    id: 'authForm.message.passwordChanged',
    defaultMessage: 'Password changed successfully'
  },
  'message.emailConfirmed': {
    id: 'authForm.message.emailConfirmed',
    defaultMessage: 'Email address confirmed'
  },
  'message.resetLinkSent': {
    id: 'authForm.message.resetLinkSent',
    defaultMessage: 'Password reset link sent'
  },
  'message.joinedTenant': {
    id: 'authForm.message.joinedTenant',
    defaultMessage: 'Successfully joined organisation'
  },
  'message.confirmLinkSent': {
    id: 'authForm.message.confirmLinkSent',
    defaultMessage: 'Email confirmation link sent'
  },
  'message.confirmLinkResent': {
    id: 'authForm.message.confirmLinkResent',
    defaultMessage: 'Email confirmation link resent'
  }
});

const AuthForm = injectIntl(({
  fields,
  messages,
  values,
  onChange,
  perform,
  performIcon,
  isLoading,
  errorCodes,
  errorKeys,
  children,
  showPassword,
  onChangeShowPassword,
  message,
  intl: {
    formatMessage
  }
}) => (
  <Fragment>
    <Grid columns='equal'>
      <Grid.Column />
      <Grid.Column width={6}>
        <Header
          inverted={INVERTED_THEME}
          as='h2'
          textAlign='center'>
        </Header>
        <Header
          inverted={INVERTED_THEME}
          textAlign='center'>
          {formatMessage(messages['header.text'])}
        </Header>
        <Segment inverted={INVERTED_THEME}>
          <IsAuthenticated>
            <Header
              inverted={INVERTED_THEME}
              textAlign='center'>
              {formatMessage(rootMessages['alreadySignedIn'])}
            </Header>
          </IsAuthenticated>
          <IsNotAuthenticated>
            <Form inverted={INVERTED_THEME}>
              {message && (
                <Message
                  inverted={INVERTED_THEME}
                  positive
                  content={formatMessage(rootMessages[`message.${message}`])}
                  header={formatMessage(rootMessages['message.header'])} />
              )}
              {_.some(errorCodes) && (
                <Message
                  negative
                  list={_.map(errorCodes, code => apiMessages[code] ? formatMessage(apiMessages[code]) : code)}
                  header={formatMessage(rootMessages['error.validationFailed.header'])} />
              )}
              {_.map(fields, ({ icon, key }, i) => (
                <Form.Field
                  key={key}
                  error={_.includes(errorKeys, key)}>
                  <label>{formatMessage(messages[`${key}.label`])}</label>
                  <Input
                    icon={icon}
                    autoFocus={i === 0}
                    iconPosition='left'
                    labelPosition={key === 'password' ? 'right' : undefined}
                    label={key === 'password'
                      ? {
                        style: {
                          borderTopLeftRadius: '0',
                          borderBottomLeftRadius: '0',
                          cursor: 'pointer',
                          background: '#4582ec',
                          color: COLOUR.TEXT
                        },
                        icon: (
                          <Icon
                            name={showPassword ? 'eye' : 'eye slash'}
                            style={{ margin: '0' }} />
                        ),
                        onClick: e => {
                          e.preventDefault();
                          e.stopPropagation();
                          onChangeShowPassword(!showPassword);
                        }
                      }
                      : undefined}
                    type={key === 'password' && !showPassword ? 'password' : undefined}
                    placeholder={formatMessage(messages[`${key}.placeholder`])}
                    value={values[key] || ''}
                    onChange={(e, { value }) => onChange(key, value)} />
                </Form.Field>
              ))}

              <AnimatedButton
                loading={isLoading}
                text={formatMessage(messages['performButton.text'])}
                fluid
                icon={performIcon}
                positive
                size='large'
                onClick={perform} />

              {children}
            </Form>
          </IsNotAuthenticated>
        </Segment>
      </Grid.Column>
      <Grid.Column />
    </Grid>
  </Fragment>
));

AuthForm.displayName = 'AuthForm';

export default AuthForm;