import { ofType } from 'redux-observable';
import { filter, map, withLatestFrom } from 'rxjs/operators';
import { CHART_TYPE, ENTITY_TYPE } from '../../../../utils/constants';
import { setDates } from '../../../actions/chart';
import { EDIT_FULFILLED } from '../../../actions/entity';
import * as fromState from '../../../reducers';

export default (action$, state$) => action$.pipe(
  ofType(EDIT_FULFILLED),
  filter(action => action.entityType === ENTITY_TYPE.MODEL),
  withLatestFrom(state$),
  map(([action, state]) => setDates(
    CHART_TYPE.MAIN,
    fromState.getFromDate(state, CHART_TYPE.MAIN),
    fromState.getToDate(state, CHART_TYPE.MAIN)
  ))
);