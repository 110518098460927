import { ofType } from 'redux-observable';
import { SAVE_MUTED_UNTIL, setIsSaving, setMuteModal } from '../../actions/dashboard';
import { withLatestFrom, mergeMap, catchError } from 'rxjs/operators';
import { merge, of } from 'rxjs';
import * as api from '../../../utils/api';
import moment from 'moment';
import * as fromState from '../../reducers';
import { insert } from '../../actions/entity';
import { takeUntilAppReset } from 'utils/helpers';

export default (action$, state$) => action$.pipe(
  ofType(SAVE_MUTED_UNTIL),
  withLatestFrom(state$),
  mergeMap(([action, state]) => merge(
    of(setIsSaving(action.payload.until ? 'mute' : 'unmute', true)),
    api.edit({
      controller: 'predictor',
      entity: {
        ...fromState.getById(state, 'predictor', action.payload.id),
        mutedUntil: action.payload.until ? moment.utc(action.payload.until).toISOString() : null
      },
      token: fromState.getUser(state).token
    }).pipe(
      takeUntilAppReset(action$),
      mergeMap(({ response }) => of(
        insert('predictor', response),
        setIsSaving(action.payload.until ? 'mute' : 'unmute', false),
        setMuteModal(action.payload.until ? 'mute' : 'unmute', false)
      )),
      catchError(api.onError)
    )
  ))
);