import _ from 'lodash';
import { ofType } from 'redux-observable';
import { EMPTY, from } from 'rxjs';
import { catchError, expand, filter, first, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { takeUntilAppReset } from 'utils/helpers';
import * as api from '../../../utils/api';
import { ENTITY_CONFIG } from '../../../utils/constants';
import entityConfig from '../../../utils/entities';
import { fetchById, FETCH_BY_ID, insert, INSERT } from '../../actions/entity';
import * as fromState from '../../reducers';

export default (action$, state$) => action$.pipe(
  ofType(FETCH_BY_ID),
  expand(fetchByIdAction => action$.pipe(
    ofType(INSERT),
    first(insertAction => insertAction.payload.entity.id === fetchByIdAction.payload.id
      && insertAction.entityType === fetchByIdAction.entityType),
    mergeMap(insertAction => {
      const fetchByIdSchema = entityConfig[insertAction.entityType].fetchByIdSchema;
      if (!fetchByIdSchema) {
        return EMPTY;
      };
      return from(fetchByIdSchema).pipe(
        filter(schema => !!insertAction.payload.entity[schema.name]),
        map(schema => fetchById(schema.entityType, insertAction.payload.entity[schema.name]))
      );
    })
  )),
  withLatestFrom(state$),
  mergeMap(([action, state]) =>
    api.get({
      controller: entityConfig[action.entityType][ENTITY_CONFIG.API_PATH],
      id: action.payload.id,
      token: fromState.getUser(state).token
    }).pipe(
      takeUntilAppReset(action$),
      map(_.partial(insert, action.entityType)),
      catchError(api.onError)
    ))
);