import { createSelector } from 'reselect';
import { PAGE_URL } from '../../../../utils/constants';
import { getPathParams, matchesPath } from '../../../../utils/helpers';

export const getUnitTypePredictorId = createSelector(
  [
    state => state.router.location.pathname
  ],
  pathname => {
    if (matchesPath(pathname, PAGE_URL.UNIT_TYPE_PREDICTOR)) {
      const params = getPathParams(PAGE_URL.UNIT_TYPE_PREDICTOR, pathname);
      return params.unitTypePredictorId;
    };
    return null;
  }
);