import { defineMessages } from 'react-intl';

export default defineMessages({
  'condition.greaterThan.name': {
    id: 'condition.greaterThan.name',
    defaultMessage: 'Greater Than'
  },
  'condition.greaterThan.options.value.placeholder': {
    id: 'condition.greaterThan.options.value.placeholder',
    defaultMessage: 'Value'
  },
  'condition.lessThan.name': {
    id: 'condition.lessThan.name',
    defaultMessage: 'Less Than'
  },
  'condition.lessThan.options.value.placeholder': {
    id: 'condition.lessThan.options.value.placeholder',
    defaultMessage: 'Value'
  },
  'condition.equalsFloat.name': {
    id: 'condition.equalsFloat.name',
    defaultMessage: 'Equals (Float)'
  },
  'condition.equalsFloat.options.value.placeholder': {
    id: 'condition.equalsFloat.options.value.placeholder',
    defaultMessage: 'Value'
  },
  'condition.lessThanParameter.name': {
    id: 'condition.lessThanParameter.name',
    defaultMessage: 'Less Than Parameter'
  },
  'condition.lessThanParameter.options.value.placeholder': {
    id: 'condition.lessThanParameter.options.value.placeholder',
    defaultMessage: 'Value'
  },
  'condition.greaterThanParameter.name': {
    id: 'condition.greaterThanParameter.name',
    defaultMessage: 'Greater Than Parameter'
  },
  'condition.greaterThanParameter.options.value.placeholder': {
    id: 'condition.greaterThanParameter.options.value.placeholder',
    defaultMessage: 'Value'
  },
  'condition.equalsParameter.name': {
    id: 'condition.equalsParameter.name',
    defaultMessage: 'Equals Parameter'
  },
  'condition.equalsParameter.options.value.placeholder': {
    id: 'condition.equalsParameter.options.value.placeholder',
    defaultMessage: 'Value'
  },
  'condition.inThePast.name': {
    id: 'condition.inThePast.name',
    defaultMessage: 'In The Past'
  },
  'condition.inTheFuture.name': {
    id: 'condition.inTheFuture.name',
    defaultMessage: 'In The Future'
  },
  'condition.lessThanDaysAway.name': {
    id: 'condition.lessThanDaysAway.name',
    defaultMessage: 'Less Than X Days Away'
  },
  'condition.lessThanDaysAway.options.days.placeholder': {
    id: 'condition.lessThanDaysAway.options.days.placeholder',
    defaultMessage: 'Days'
  },
  'condition.moreThanDaysAway.name': {
    id: 'condition.moreThanDaysAway.name',
    defaultMessage: 'More Than X Days Away'
  },
  'condition.moreThanDaysAway.options.days.placeholder': {
    id: 'condition.moreThanDaysAway.options.days.placeholder',
    defaultMessage: 'Days'
  },
  'condition.lessThanDaysAgo.name': {
    id: 'condition.lessThanDaysAgo.name',
    defaultMessage: 'Less Than X Days Ago'
  },
  'condition.lessThanDaysAgo.options.days.placeholder': {
    id: 'condition.lessThanDaysAgo.options.days.placeholder',
    defaultMessage: 'Days'
  },
  'condition.moreThanDaysAgo.name': {
    id: 'condition.moreThanDaysAgo.name',
    defaultMessage: 'More Than X Days Ago'
  },
  'condition.moreThanDaysAgo.options.days.placeholder': {
    id: 'condition.moreThanDaysAgo.options.days.placeholder',
    defaultMessage: 'Days'
  },
  'condition.greaterThanDate.name': {
    id: 'condition.greaterThanDate.name',
    defaultMessage: 'After'
  },
  'condition.greaterThanDate.options.value.placeholder': {
    id: 'condition.greaterThanDate.options.value.placeholder',
    defaultMessage: 'Value'
  },
  'condition.lessThanDate.name': {
    id: 'condition.lessThanDate.name',
    defaultMessage: 'Before'
  },
  'condition.lessThanDate.options.value.placeholder': {
    id: 'condition.lessThanDate.options.value.placeholder',
    defaultMessage: 'Value'
  },
  'condition.lessThanAttributeDate.name': {
    id: 'condition.lessThanAttributeDate.name',
    defaultMessage: 'Before (By Name)'
  },
  'condition.inUnit.name': {
    id: 'condition.inUnit.name',
    defaultMessage: 'In Location'
  },
  'condition.inAttributeUnit.name': {
    id: 'condition.inAttributeUnit.name',
    defaultMessage: 'In Location (By Id)'
  },
  'condition.equals.name': {
    id: 'condition.equals.name',
    defaultMessage: 'Equals (String)'
  },
  'condition.equals.options.value.placeholder': {
    id: 'condition.equals.options.value.placeholder',
    defaultMessage: 'Value'
  },
  'condition.containsCaseInsensitive.name': {
    id: 'condition.containsCaseInsensitive.name',
    defaultMessage: 'Contains (Case Insensitive)'
  },
  'condition.containsCaseInsensitive.options.value.placeholder': {
    id: 'condition.containsCaseInsensitive.options.value.placeholder',
    defaultMessage: 'Value'
  },
  'condition.containsCaseSensitive.name': {
    id: 'condition.containsCaseSensitive.name',
    defaultMessage: 'Contains (Case Sensitive)'
  },
  'condition.containsCaseSensitive.options.value.placeholder': {
    id: 'condition.containsCaseSensitive.options.value.placeholder',
    defaultMessage: 'Value'
  },
  'condition.inString.name': {
    id: 'condition.inString.name',
    defaultMessage: 'In List (String)'
  },
  'condition.inString.options.value.placeholder': {
    id: 'condition.inString.options.value.placeholder',
    defaultMessage: 'Value'
  },
  'condition.inState.name': {
    id: 'condition.inState.name',
    defaultMessage: 'Equals (Enumeration)'
  },
  'condition.inEnumeration.name': {
    id: 'condition.inEnumeration.name',
    defaultMessage: 'In List (Enumeration)'
  },
  'condition.containsState.name': {
    id: 'condition.containsState.name',
    defaultMessage: 'Contains'
  },
  'condition.doesNotContainState.name': {
    id: 'condition.doesNotContainState.name',
    defaultMessage: 'Does Not Contain'
  }
});