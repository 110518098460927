import { ofType } from 'redux-observable';
import { DOWNLOAD_IMPORT_TEMPLATE } from '../../actions/entity';
import { withLatestFrom, mergeMap, map, filter } from 'rxjs/operators';
import * as api from '../../../utils/api';
import * as fromState from '../../reducers';
import { ENTITY_CONFIG, ENTITY_TYPE } from '../../../utils/constants';
import _ from 'lodash';
import entityConfig from '../../../utils/entities';
import { takeUntilAppReset } from 'utils/helpers';

const getApiCall = (action, state) => {
  if (action.entityType === ENTITY_TYPE.UNIT_TYPE_INSTANCE && action.payload.context.unitTypeId) {
    return api.getUnitTypeInstanceImportHeadersWithContext({
      unitTypeId: action.payload.context.unitTypeId,
      token: fromState.getUser(state).token
    });
  } else {
    return api.getImportHeaders({
      controller: entityConfig[action.entityType][ENTITY_CONFIG.API_PATH],
      apiPrefix: entityConfig[action.entityType][ENTITY_CONFIG.API_PREFIX],
      parentId: _.last(state.router.location.pathname.split('/')),
      token: fromState.getUser(state).token
    });
  }
};

export default (action$, state$) => action$.pipe(
  ofType(DOWNLOAD_IMPORT_TEMPLATE),
  withLatestFrom(state$),
  mergeMap(([action, state]) =>
    getApiCall(action, state).pipe(
      takeUntilAppReset(action$),
      map(headers => {
        const element = document.createElement('a');
        element.setAttribute('href', `data:text/plain;charset=utf-8,${encodeURIComponent(headers.join(','))}`);
        element.setAttribute('download', `${action.entityType}.csv`);
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
        return null;
      })
    )),
  filter(action => action)
);