import _ from 'lodash';
import moment from 'moment';
import { EMPTY, merge, of } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, mergeMap } from 'rxjs/operators';
import * as api from '../../../utils/api';
import { CHART_TYPE, CONTROLLER, DATE_FORMAT, ENTITY_PROPERTY, ENTITY_TYPE, LOADING, PAGE_URL, SELECTION_TYPE, TREND_SEARCH_PAGE_SIZE, DASHBOARD_VIEW } from '../../../utils/constants';
import { getPathParams, matchesPath, takeUntilAppReset } from '../../../utils/helpers';
import { setChartIsLoading, setCompareOpen, setCompareTrend, setGroup, setQuickRange, setSelected, setToDate, setTrendCount, setTrendIds } from '../../actions/chart';
import { insert, insertMany } from '../../actions/entity';
import * as fromState from '../../reducers';
import * as chartSelectors from '../../selectors/chart';
import { setDashboardView } from 'state/actions/dashboard';

export default (action$, state$) => state$.pipe(
  distinctUntilChanged((prev, curr) =>
    prev.router.location.pathname === curr.router.location.pathname
    && fromState.getUnitPath(prev) === fromState.getUnitPath(curr)),
  filter(state => matchesPath(state.router.location.pathname, PAGE_URL.PREDICTOR_OPTIONAL_CONFIGURATION)
    && fromState.getUnitPath(state)),
  map(state => {
    const pathParams = getPathParams(PAGE_URL.PREDICTOR_OPTIONAL_CONFIGURATION, state.router.location.pathname);
    return [
      state,
      pathParams.groupId
    ];
  }),
  filter(([state]) => fromState.getUser(state)),
  mergeMap(([state, id]) => merge(
    of(
      setDashboardView(DASHBOARD_VIEW.UNITS),
      setToDate(CHART_TYPE.MAIN, moment.utc(fromState.getPlaybackTime(state)).format(DATE_FORMAT)),
      setGroup(CHART_TYPE.MAIN, id),
      setSelected(CHART_TYPE.MAIN, SELECTION_TYPE.INPUT, []),
      setSelected(CHART_TYPE.MAIN, SELECTION_TYPE.OUTPUT, []),
      setSelected(CHART_TYPE.MAIN, SELECTION_TYPE.FUNCTION, []),
      setCompareTrend(null),
      setCompareOpen(false),
      setChartIsLoading(CHART_TYPE.MAIN, true),
      setChartIsLoading(LOADING.TRENDS, true)
    ),
    api.getAll({
      controller: CONTROLLER.ACTION_CONNECTOR,
      token: fromState.getUser(state).token
    }).pipe(
      takeUntilAppReset(action$),
      map(results => insertMany(ENTITY_TYPE.ACTION_CONNECTOR, results)),
      catchError(api.onError)
    ),
    (fromState.getById(state, ENTITY_TYPE.PREDICTOR, id)
      ? of(
        fromState.getById(state, ENTITY_TYPE.PREDICTOR, id)
      )
      : api.get({
        id,
        controller: CONTROLLER.PREDICTOR,
        token: fromState.getUser(state).token
      })
    ).pipe(
      takeUntilAppReset(action$),
      mergeMap(group => merge(
        group.type
          ? api.get({
            controller: CONTROLLER.MODEL,
            id: group.type,
            token: fromState.getUser(state).token
          }).pipe(
            takeUntilAppReset(action$),
            map(model => insert(ENTITY_TYPE.MODEL, model))
          )
          : EMPTY,
        api.getMultiple({
          controller: CONTROLLER.SENSOR,
          ids: _.chain([...group.inputMappings, ...group.functionMappings || [], ...group.sensorMappings || []]).map(ENTITY_PROPERTY.PREDICTOR.INPUT_MAPPING.SENSOR).filter().uniq().value(),
          token: fromState.getUser(state).token
        }).pipe(
          takeUntilAppReset(action$),
          map(results => insertMany(ENTITY_TYPE.SENSOR, results))
        ),
        api.query({
          controller: CONTROLLER.TREND,
          query: chartSelectors.getTrendQuery(state),
          page: 0,
          pageSize: TREND_SEARCH_PAGE_SIZE,
          token: fromState.getUser(state).token
        }).pipe(
          takeUntilAppReset(action$),
          mergeMap(({ items: trends, total }) => of(
            insertMany(ENTITY_TYPE.TREND, trends),
            setTrendIds(_.map(trends, ENTITY_PROPERTY.TREND.ID)),
            setTrendCount(total),
            setChartIsLoading(LOADING.TRENDS, false)
          ))
        ),
        of(
          insert(ENTITY_TYPE.PREDICTOR, group),
          fromState.getGroup(state, CHART_TYPE.MAIN) === id && fromState.getToDate(state, CHART_TYPE.MAIN) === moment.utc(fromState.getPlaybackTime(state)).format(DATE_FORMAT)
            ? setChartIsLoading(CHART_TYPE.MAIN, false)
            : setQuickRange(CHART_TYPE.MAIN, fromState.getQuickRange(state, CHART_TYPE.MAIN)),
          setSelected(CHART_TYPE.MAIN, SELECTION_TYPE.INPUT, _.map(group.inputMappings, ENTITY_PROPERTY.PREDICTOR.INPUT_MAPPING.INPUT)),
          setSelected(CHART_TYPE.MAIN, SELECTION_TYPE.OUTPUT, _.map(group.outputMappings, ENTITY_PROPERTY.PREDICTOR.OUTPUT_MAPPING.OUTPUT)),
          setSelected(CHART_TYPE.MAIN, SELECTION_TYPE.FUNCTION, _.map(group.functionMappings, ENTITY_PROPERTY.PREDICTOR.FUNCTION_MAPPING.SENSOR))
        )
      )),
      catchError(api.onError)
    )
  ))
);