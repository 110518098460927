import _ from 'lodash';
import { defineMessages, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { Tab, Table } from 'semantic-ui-react';
import PredictorOutputAlarmButton from '../../../components/alarm/PredictorOutputAlarmButton';
import EntityPageContainer from '../../../containers/entity/EntityPageContainer';
import * as fromState from '../../../state/reducers';
import { ENTITY_PROPERTY, ENTITY_TYPE, INVERTED_THEME, VALUE_TYPE } from '../../constants';

const messages = defineMessages({
  'tab.alerts': {
    id: 'unitTypePredictorPage.tab.alerts',
    defaultMessage: 'Alerts'
  },
  'tab.actions': {
    id: 'unitTypePredictorPage.tab.actions',
    defaultMessage: 'Actions'
  },
  'tab.alarms': {
    id: 'unitTypePredictorPage.tab.alarms',
    defaultMessage: 'Alarms'
  },
  'tab.alarms.table.column.outputs': {
    id: 'unitTypePredictorPage.tab.alarms.table.column.outputs',
    defaultMessage: 'Outputs'
  }
});

const mapStateToProps = createSelector(
  [
    (state, { parentId }) => fromState.getById(state, ENTITY_TYPE.UNIT_TYPE_PREDICTOR, parentId),
    state => fromState.getAll(state, ENTITY_TYPE.MODEL)
  ],
  (unitTypePredictor, allModels) => ({
    unitTypePredictor: unitTypePredictor,
    model: _.get(allModels, unitTypePredictor && unitTypePredictor.type)
  })
);

const UnitTypePredictorPage = ({
  unitTypePredictor,
  model,
  intl: {
    formatMessage
  }
}) => (
  <Tab
    menu={{
      inverted: INVERTED_THEME,
      size: 'large',
      className: 'tab-menu'
    }}
    panes={[
      {
        menuItem: formatMessage(messages['tab.alerts']),
        render: () => (
          <EntityPageContainer
            entityType={ENTITY_TYPE.UNIT_TYPE_ALERT_MAPPING}
            parentId={unitTypePredictor && unitTypePredictor.id} />
        )
      },
      {
        menuItem: formatMessage(messages['tab.actions']),
        render: () => (
          <EntityPageContainer
            entityType={ENTITY_TYPE.UNIT_TYPE_ACTION_MAPPING}
            parentId={unitTypePredictor && unitTypePredictor.id} />
        )
      },
      {
        menuItem: formatMessage(messages['tab.alarms']),
        render: () => (
          <div className='page-content'>
            <Table
              celled
              inverted={INVERTED_THEME}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>
                    {formatMessage(messages['tab.alarms.table.column.outputs'])}
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {_.chain(unitTypePredictor)
                  .get(ENTITY_PROPERTY.PREDICTOR.OUTPUT_MAPPINGS)
                  .filter((outputMapping, index) => model
                    && _.includes(
                      [VALUE_TYPE.FLOAT, VALUE_TYPE.ENUMERATION],
                      model.outputs[index].valueType
                    ))
                  .map((outputMapping, index) => (
                    <Table.Row key={index}>
                      <Table.Cell width={1}>
                        {outputMapping && (
                          <PredictorOutputAlarmButton
                            index={index}
                            id={unitTypePredictor && unitTypePredictor.id}
                            valueType={model && model.outputs[index].valueType}
                            label={outputMapping.output}
                            entityType={ENTITY_TYPE.UNIT_TYPE_PREDICTOR} />
                        )}
                      </Table.Cell>
                    </Table.Row>
                  ))
                  .value()}
              </Table.Body>
            </Table>
          </div>
        )
      }
    ]} />
);

UnitTypePredictorPage.displayName = 'UnitTypePredictorPage';

export default connect(mapStateToProps)(injectIntl(UnitTypePredictorPage));