import { RootState } from 'index';
import _ from 'lodash';
import createCachedSelector from 're-reselect';
import { Predictor, Sensor, UnitType, UnitTypePredictor } from 'types';
import { CHART_TYPE, ENTITY_TYPE, ENTITY_PROPERTY } from '../../../../utils/constants';
import * as fromState from '../../../reducers';
import { getSeverity } from './getSeverity';

const isChildOf = (
  childId: string,
  parentId: string,
  allUnitTypes: { [key: string]: UnitType; }
): boolean => {
  if (!childId) {
    return false;
  };
  return childId === parentId
    || isChildOf(_.get(allUnitTypes[childId], ENTITY_PROPERTY.UNIT_TYPE.BASE_TYPE), parentId, allUnitTypes);
};

interface Props {
  id: string;
}

export const getUnitTypeSeverity = createCachedSelector(
  [
    (state: RootState, { id }: Props) => id,
    state => fromState.getAll<Predictor>(state, ENTITY_TYPE.PREDICTOR),
    state => fromState.getAll<UnitType>(state, ENTITY_TYPE.UNIT_TYPE),
    state => fromState.getAll<UnitTypePredictor>(state, ENTITY_TYPE.UNIT_TYPE_PREDICTOR),
    state => fromState.getAllLatestSensorValues(state, CHART_TYPE.MAIN),
    state => fromState.getAllLatestOutputValues(state, CHART_TYPE.MAIN),
    state => fromState.getAll<Sensor>(state, ENTITY_TYPE.SENSOR)
  ],
  (
    parentId,
    allPredictors,
    allUnitTypes,
    allUnitTypePredictors,
    allLatestSensorValues,
    allLatestOutputValues,
    allSensors
  ) => {
    return _.chain(allPredictors)
      .values()
      .filter(predictor => {
        if (_.isNil(predictor.unitTypePredictor)) {
          return false;
        }

        const unitTypePredictor = allUnitTypePredictors[predictor.unitTypePredictor];
        if (_.isNil(unitTypePredictor)) {
          return false;
        }

        return isChildOf(unitTypePredictor.unitType, parentId, allUnitTypes);
      })
      .map(predictor => getSeverity(predictor, allLatestSensorValues, allLatestOutputValues, allSensors))
      .max()
      .value();
  }
)(
  (state, { id }) => id
);