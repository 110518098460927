import _ from 'lodash';
import moment from 'moment';
import createCachedSelector from 're-reselect';
import { connect } from 'react-redux';
import { compose, withHandlers } from 'recompose';
import { bindActionCreators } from 'redux';
import { edit, formValueChanged, insert, toggleModal } from 'state/actions/entity';
import { getUnitId } from 'state/selectors/dashboard';
import { TimeSeriesPlot } from '../../../components/chart/TimeSeriesChart';
import { setBrushTimestamps, setHoverLine } from '../../../state/actions/chart';
import * as fromState from '../../../state/reducers';
import * as chartSelectors from '../../../state/selectors/chart';
import { DATE_FORMAT, ENTITY_FORM_TYPE, ENTITY_MODAL, ENTITY_PROPERTY, ENTITY_TYPE, FEATURE_SETTINGS, PLAYBACK_MODE, PROPERTY_SETTINGS, SEMANTIC_COLOUR, SENSOR_VALUE_DATE_FORMAT } from '../../../utils/constants';
import { transformChartTypeSelectors } from '../../../utils/helpers';
import getFunctionLines from './getFunctionLines';
import getInputLines from './getInputLines';
import getOutputLines from './getOutputLines';

const mapStateToProps = createCachedSelector(
  [
    ...transformChartTypeSelectors([
      chartSelectors.getChartData,
      fromState.getBrushStartTimestamp,
      fromState.getBrushEndTimestamp,
      fromState.getFromDate,
      fromState.getToDate,
      chartSelectors.getPredictor,
      chartSelectors.getModel,
      getOutputLines,
      getInputLines,
      getFunctionLines,
      fromState.getFeaturesToggle,
      fromState.getSelectedFeature,
      chartSelectors.getFeatureWindowDuration,
      fromState.getSelectedProperty,
      chartSelectors.getTimelineTrends
    ]),
    state => fromState.getAll(state, ENTITY_TYPE.TREND_TYPE),
    fromState.getPlaybackMode,
    fromState.getPlaybackReviewId,
    fromState.getPlaybackReviewTrendId,
    state => fromState.getAll(state, ENTITY_TYPE.REVIEW),
    getUnitId
  ],
  (
    data,
    brushStartTimestamp,
    brushEndTimestamp,
    fromDate,
    toDate,
    predictor,
    model,
    outputLines,
    inputLines,
    functionLines,
    featuresToggle,
    selectedFeature,
    featureWindowDuration,
    selectedProperty,
    trends,
    allTrendTypes,
    mode,
    reviewId,
    reviewTrendId,
    allReviews,
    unitId
  ) => ({
    data,
    brushStartTimestamp,
    brushEndTimestamp,
    startTimestamp: parseInt(moment.utc(fromDate, DATE_FORMAT).format(SENSOR_VALUE_DATE_FORMAT)),
    endTimestamp: parseInt(moment.utc(toDate, DATE_FORMAT).format(SENSOR_VALUE_DATE_FORMAT)),
    useDefaultYAxisTicks: !!featuresToggle && !!(FEATURE_SETTINGS[selectedFeature].NO_OFFSET_SCALE || FEATURE_SETTINGS[selectedFeature].DEFAULT_TICKS || PROPERTY_SETTINGS[selectedProperty].DEFAULT_TICKS),
    highlightedWidowSize: featuresToggle
      ? featureWindowDuration.as('seconds')
      : predictor
        ? moment.duration((model || predictor).window).as('seconds')
        : 0,
    showHighlightedWindow: featuresToggle || (!!predictor && !!predictor.type),
    lineGroups: _.reject([
      outputLines,
      inputLines,
      functionLines
    ], _.isEmpty),
    referenceLines: _.chain(trends)
      .flatMap(trend => [
        {
          x: moment(trend.from).format('X'),
          colour: SEMANTIC_COLOUR[_.get(allTrendTypes[trend.type], ENTITY_PROPERTY.TREND_TYPE.COLOUR)]
        },
        {
          x: moment(trend.to).format('X'),
          colour: SEMANTIC_COLOUR[_.get(allTrendTypes[trend.type], ENTITY_PROPERTY.TREND_TYPE.COLOUR)]
        }
      ])
      .filter(line => line.x)
      .value(),
    referenceAreas: mode === PLAYBACK_MODE.REVIEW
      ? [
        ..._.chain(trends)
          .filter(trend => trend.id === reviewTrendId)
          .map(trend => ({
            x1: moment(trend.from).format('X'),
            x2: moment(trend.to).format('X'),
            label: trend.name,
            colour: SEMANTIC_COLOUR[_.get(allTrendTypes[trend.type], ENTITY_PROPERTY.TREND_TYPE.COLOUR)]
          }))
          .value(),
        ..._.chain(allReviews[reviewId])
          .get(ENTITY_PROPERTY.REVIEW.TRENDS)
          .find(trend => trend.id === reviewTrendId)
          .get(ENTITY_PROPERTY.REVIEW.TREND.REGIONS)
          .toPairs()
          .map(([name, details]) => {
            const reviewRegion = _.find(
              allReviews[reviewId].regions,
              region => region.name === name
            );
            return {
              x1: moment(details.from).format('X'),
              x2: moment(details.to).format('X'),
              label: reviewRegion.name,
              colour: SEMANTIC_COLOUR[_.get(allTrendTypes[details.type], ENTITY_PROPERTY.TREND_TYPE.COLOUR)]
            };
          })
          .value()
      ]
      : [],
    trendButtons: mode === PLAYBACK_MODE.REVIEW
      ? _.chain(allReviews[reviewId])
        .get(ENTITY_PROPERTY.REVIEW.REGIONS)
        .map(region => ({
          id: region.name,
          name: region.name,
          icon: _.get(allTrendTypes, `${region.trendType}.${ENTITY_PROPERTY.TREND_TYPE.ICON}`),
          colour: _.get(allTrendTypes, `${region.trendType}.${ENTITY_PROPERTY.TREND_TYPE.COLOUR}`)
        }))
        .value()
      : _.values(allTrendTypes),
    mode,
    review: allReviews[reviewId],
    reviewTrendId,
    unitId
  })
)(
  (state, { chartType }) => chartType
);

const mapDispatchToProps = (dispatch, { chartType }) => ({
  clickTrendButton: type => {
    dispatch(formValueChanged(ENTITY_TYPE.TREND, ENTITY_FORM_TYPE.ADD_OR_EDIT, ENTITY_PROPERTY.TREND.TYPE, type));
    dispatch(toggleModal(ENTITY_TYPE.TREND, ENTITY_MODAL.ADD, type));
  },
  clickTrendButtonReviewMode: (type, from, to, notes, resetZoomArea, review, reviewTrendId) => {
    const index = _.findIndex(review.trends, trend => trend.id === reviewTrendId);
    const editedReview = {
      ...review,
      trends: [
        ...review.trends.slice(0, index),
        {
          ...review.trends[index],
          regions: {
            ...review.trends[index].regions,
            [type]: {
              from,
              to,
              type: _.find(
                review.regions,
                region => region.name === type
              ).trendType,
              settings: {},
              notes
            }
          }
        },
        ...review.trends.slice(index + 1)
      ]
    };
    dispatch(insert(ENTITY_TYPE.REVIEW, editedReview));
    dispatch(edit(ENTITY_TYPE.REVIEW, editedReview));
    resetZoomArea();
  },
  ...bindActionCreators({
    onChangeBrush: _.partial(setBrushTimestamps, chartType),
    onUnhoverLine: line => {
      // this is a hack to improve the performance of the time series chart
      // normally should never access the dom directly when using react
      // recharts library does not allow modifying subcomponents without re-rendering entire chart
      const className = _.replace(line, / /g, '-');
      _.forEach(document.getElementsByClassName(className), element => {
        _.forEach(element.childNodes, childNode => {
          childNode.style.strokeWidth = childNode.getAttribute('stroke-width');
        });
      });
      return setHoverLine(chartType, null);
    },
    onHoverLine: line => {
      // this is a hack to improve the performance of the time series chart
      // normally should never access the dom directly when using react
      // recharts library does not allow modifying subcomponents without re-rendering entire chart
      const className = _.replace(line, / /g, '-');
      _.forEach(document.getElementsByClassName(className), element => {
        _.forEach(element.childNodes, childNode => {
          childNode.style.strokeWidth = (childNode.getAttribute('stroke-width') || 0) * 1.4;
        });
      });
      return setHoverLine(chartType, line);
    }
  }, dispatch)
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    onClickTrendButton: ({
      mode,
      review,
      reviewTrendId,
      clickTrendButton,
      clickTrendButtonReviewMode
    }) => (type, from, to, notes, resetZoomArea) => mode === PLAYBACK_MODE.REVIEW
      ? clickTrendButtonReviewMode(
        type,
        from,
        to,
        notes,
        resetZoomArea,
        review,
        reviewTrendId
      )
      : clickTrendButton(type)
  })
)(TimeSeriesPlot);