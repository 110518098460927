import moment from 'moment';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { switchMap, withLatestFrom } from 'rxjs/operators';
import { CHART_TYPE, DATE_FORMAT, PLAYBACK_MODE } from '../../../utils/constants';
import { setDates } from '../../actions/chart';
import { CHANGE_PLAYBACK_TIME, setPlaybackMode } from '../../actions/playback';
import * as fromState from '../../reducers';
import { setChartIsLoading } from '../../actions/chart';

export default (action$, state$) => action$.pipe(
  ofType(CHANGE_PLAYBACK_TIME),
  withLatestFrom(state$),
  switchMap(([action, state]) => {
    const toDate = moment.utc(action.payload.time);
    const duration = moment.duration(fromState.getQuickRange(state, CHART_TYPE.MAIN));
    const toDateString = toDate.format(DATE_FORMAT);
    const noDataFetch = toDateString === fromState.getToDate(state, CHART_TYPE.MAIN);
    const isNow = toDateString === moment.utc().format(DATE_FORMAT);
    return of(
      ...noDataFetch
        ? []
        : [setChartIsLoading(CHART_TYPE.MAIN, true)],
      setDates(
        CHART_TYPE.MAIN,
        toDate.clone().subtract(duration).format(DATE_FORMAT),
        toDate.format(DATE_FORMAT),
        false,
        noDataFetch
      ),
      ...isNow && fromState.getPlaybackIsPlaying(state)
        ? [setPlaybackMode(PLAYBACK_MODE.LIVE)]
        : fromState.getPlaybackMode(state) === PLAYBACK_MODE.LIVE
          ? [setPlaybackMode(PLAYBACK_MODE.HISTORICAL)]
          : []
    );
  })
);