import _ from 'lodash';
import createCachedSelector from 're-reselect';
import { ENTITY_PROPERTY, SELECTION_TYPE } from '../../../utils/constants';
import * as fromState from '../../../state/reducers';
import * as chartSelectors from '../../../state/selectors/chart';

export default createCachedSelector(
  [
    _.partialRight(fromState.getSelected, SELECTION_TYPE.OUTPUT),
    chartSelectors.getOutputs,
    chartSelectors.getColours,
    chartSelectors.getOutputProperties
  ],
  (
    selectedOutputs,
    outputs,
    colours,
    outputProperties
  ) => _.map(outputs, output => ({
    id: output,
    title: _.get(outputProperties[output], ENTITY_PROPERTY.MODEL.OUTPUT.NAME),
    description: null,
    colour: colours[output],
    selected: _.includes(selectedOutputs, output),
    selectionType: SELECTION_TYPE.OUTPUT,
    editable: false,
    removable: false
  }))
)(
  (state, chartType) => chartType
);