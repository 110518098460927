import _ from 'lodash';
import { createSelector, createSelectorCreator, defaultMemoize } from 'reselect';
import * as fromState from '../../../state/reducers';
import { ATTRIBUTE_SOURCE, ENTITY_PROPERTY, ENTITY_TYPE } from '../../constants';
import { getModelOutputName, getModelOutputUnitsOfMeasurement } from 'utils/helpers';

const createDeepEqualPathsSelectorCreator = paths => createSelectorCreator(
  defaultMemoize,
  (a, b) => _.every(paths, path => _.isEqual(_.get(a, path), _.get(b, path)))
);

const createPredictorDeepEqualPathsSelector = createDeepEqualPathsSelectorCreator([
  ENTITY_PROPERTY.PREDICTOR.TYPE,
  ENTITY_PROPERTY.PREDICTOR.INPUT_MAPPINGS,
  ENTITY_PROPERTY.PREDICTOR.UNIT
]);

const getPredictor = createPredictorDeepEqualPathsSelector(
  [
    (state, { parentId }) => fromState.getById(state, ENTITY_TYPE.PREDICTOR, parentId)
  ],
  predictor => predictor
);

export default createSelector(
  [
    getPredictor,
    state => fromState.getAll(state, ENTITY_TYPE.MODEL),
    state => fromState.getAll(state, ENTITY_TYPE.SENSOR),
    state => fromState.getLabels(state, ENTITY_TYPE.ALERT_MAPPING)
  ],
  (predictor, allModels, allSensors, labels) => {
    const model = _.get(allModels, predictor && predictor.type);
    const inputSensors = _.chain(predictor)
      .get(ENTITY_PROPERTY.PREDICTOR.INPUT_MAPPINGS)
      .map(inputMapping => [inputMapping.input, allSensors[inputMapping.sensor]])
      .fromPairs()
      .value();
    const outputs = _.chain(model)
      .get(ENTITY_PROPERTY.MODEL.OUTPUTS)
      .map(output => ({
        id: output.name,
        ...output,
        text: getModelOutputName(model, output.name),
        unitsOfMeasurement: getModelOutputUnitsOfMeasurement(
          model,
          output.name,
          inputSensors
        )
      }))
      .value();
    return {
      outputs,
      attributes: [
        ..._.chain(allSensors)
          .values()
          .filter(sensor => predictor && sensor.unitId === predictor.unitId)
          .map(sensor => ({
            id: `${ATTRIBUTE_SOURCE.SENSOR}_${sensor.id}`,
            text: sensor.name,
            description: sensor.description,        
            valueType: sensor.valueType,
            enumeration: sensor.enumeration,
            unitsOfMeasurement: sensor.unitsOfMeasurement,
            source: ATTRIBUTE_SOURCE.SENSOR
          }))
          .value(),
        ..._.map(outputs, output => ({
          source: ATTRIBUTE_SOURCE.MODEL_OUTPUT,
          ...output,
          id: `${ATTRIBUTE_SOURCE.MODEL_OUTPUT}_${output.id}`
        }))
      ],
      labels: _.map(labels, label => ({
        id: label.name
      }))
    };
  }
);