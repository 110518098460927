import { Button, Modal } from 'semantic-ui-react';
import AnimatedButton from '../shared/AnimatedButton';
import { defineMessages, injectIntl } from 'react-intl';

const messages = defineMessages({
  'header.text': {
    id: 'unmuteModal.header.text',
    defaultMessage: 'Unmute'
  },
  'cancelButton.text': {
    id: 'unmuteModal.cancelButton.text',
    defaultMessage: 'Cancel'
  },
  'unmuteButton.text': {
    id: 'unmuteModal.unmuteButton.text',
    defaultMessage: 'Unmute'
  }
});

const UnmuteModal = injectIntl(({
  open,
  id,
  onClose,
  onUnmute,
  isSaving,
  intl: {
    formatMessage
  }
}) => (
  <Modal
    open={open}
    onClose={onClose}
    centered
    closeOnDimmerClick={false}
    closeIcon
    size='mini'
    closeOnEscape>
    <Modal.Header content={formatMessage(messages['header.text'])} />
    <Modal.Actions>
      <Button
        content={formatMessage(messages['cancelButton.text'])}
        onClick={onClose} />
      <AnimatedButton
        text={formatMessage(messages['unmuteButton.text'])}
        icon='alarm'
        loading={isSaving}
        onClick={() => onUnmute(id)}
        positive />
    </Modal.Actions>
  </Modal>
));

UnmuteModal.displayName = 'UnmuteModal';

export default UnmuteModal;