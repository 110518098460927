import _ from 'lodash';
import { Fragment } from 'react';
import { Form } from 'semantic-ui-react';
import { EMPTY_STRING, LITERAL_OR_ATTRIBUTE } from '../../../utils/constants';

const makeOnChangeLiteralOrAttributeSetting = (type, onChangeSetting) => (key, value, errorPath) => onChangeSetting(
  key,
  value
    ? {
      type,
      value
    }
    : null,
  errorPath
);

const isLiteralOrAttributeDisabled = (type, key, settingsValues) => {
  const valueType = _.get(settingsValues, `${key}.type`);
  return valueType && valueType !== type;
};

const getLiteralOrAttributeValue = (type, key, settingsValues) =>
  _.get(settingsValues, `${key}.type`) !== type
    ? null
    : _.get(settingsValues, `${key}.value`);

export const getDefaultOptionValues = (key, settingsValues, onChangeSetting) => ({
  OptionWrapper: Fragment,
  optionWrapperProps: {},
  onChangeLiteralSetting: onChangeSetting,
  onChangeAttributeSetting: null,
  literalInputDisabled: false,
  attributeInputDisabled: true,
  literalValue: _.get(settingsValues, key) || EMPTY_STRING,
  attributeValue: null
});

export const getLiteralOrAttributeOptionValues = (key, settingsValues, onChangeSetting) => ({
  OptionWrapper: Form.Group,
  optionWrapperProps: {
    widths: 'equal'
  },
  onChangeLiteralSetting: makeOnChangeLiteralOrAttributeSetting(LITERAL_OR_ATTRIBUTE.LITERAL, onChangeSetting),
  onChangeAttributeSetting: makeOnChangeLiteralOrAttributeSetting(LITERAL_OR_ATTRIBUTE.ATTRIBUTE, onChangeSetting),
  literalInputDisabled: isLiteralOrAttributeDisabled(LITERAL_OR_ATTRIBUTE.LITERAL, key, settingsValues),
  attributeInputDisabled: isLiteralOrAttributeDisabled(LITERAL_OR_ATTRIBUTE.ATTRIBUTE, key, settingsValues),
  literalValue: getLiteralOrAttributeValue(LITERAL_OR_ATTRIBUTE.LITERAL, key, settingsValues) || EMPTY_STRING,
  attributeValue: getLiteralOrAttributeValue(LITERAL_OR_ATTRIBUTE.ATTRIBUTE, key, settingsValues)
});