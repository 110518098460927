import { COLOUR } from '../../../utils/constants';

export default {
  card: {
    width: '160px',
    borderTopRightRadius: 0
  },
  severityLabel: fill => ({
    color: fill
  }),
  cardGaugeContent: {
    maxHeight: '130px'
  },
  cardHeaderLabel: fill => ({
    background: fill,
    color: COLOUR.TEXT,
    fontSize: '0.75rem'
  }),
  noDataWrapperDiv: {
    color: COLOUR.TEXT,
    textAlign: 'center',
    height: '112px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  stringValueDiv: {
    color: COLOUR.TEXT,
    textAlign: 'center',
    height: '112px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    wordBreak: 'break-word'
  },
  barGaugeWrapperDiv: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10px'
  },
  cardContent: fill => ({
    backgroundColor: fill
  }),
  cardHeader: {
    fontSize: '1rem',
    overflowWrap: 'break-word'
  },
  cardMeta: {
    fontSize: '0.75rem',
    overflowWrap: 'break-word'
  },
  cardExtraContent: fill => ({
    backgroundColor: fill
  }),
  cardSelectButton: {
    border: 'none'
  },
  statisticValue: {
    textTransform: 'none'
  },
  valueStatisticLabel: {
    textTransform: 'none',
    textAlign: 'center',
    lineHeight: '2em'
  }
};