import { defineMessages } from 'react-intl';
import uuid from 'uuid/v1';
import { ENTITY_FORM_ELEMENT_TYPE } from 'utils/constants';

const messages = defineMessages({
  'title': {
    id: 'label.title',
    defaultMessage: 'Labels'
  },
  'columns.name.title': {
    id: 'label.columns.name.title',
    defaultMessage: 'Name'
  },
  'form.name.label': {
    id: 'label.form.name.label',
    defaultMessage: 'Name'
  },
  'form.name.placeholder': {
    id: 'label.form.name.placeholder',
    defaultMessage: 'Name'
  }
});

export default {
  apiPath: 'label',
  pageUrl: 'labels',
  icon: 'tags',
  messages,
  disableFetchTypes: true,
  columns: [
    {
      name: 'name',
      width: 3,
      render: ({ name }) => name
    }
  ],
  form: [
    {
      name: 'name',
      type: ENTITY_FORM_ELEMENT_TYPE.INPUT,
      disableOnEdit: true
    }
  ],
  createEntity: values => ({
    id: uuid(),
    name: values.name,
    unitId: null
  }),
  populateForm: entity => ({
    name: entity.name
  })
};