import { createSelector } from 'reselect';
import * as fromState from '../../../reducers';
import { ENTITY_TYPE } from '../../../../utils/constants';
import { Trend } from 'types';

export const getCompareTrend = createSelector(
  [
    state => fromState.getAll<Trend>(state, ENTITY_TYPE.TREND),
    fromState.getCompareTrend
  ],
  (allTrends, id) => id ? allTrends[id] : null
);