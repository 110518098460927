import _ from 'lodash';
import { Fragment } from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { Button, Dropdown, Icon, Label, Popup, Form } from 'semantic-ui-react';
import { ENTITY_PROPERTY, INVERTED_THEME, PLAYBACK_MODE } from '../../../utils/constants';
import PopupButton from '../../shared/PopupButton';
import styles from './PlaybackButtons.styles';

const messages = defineMessages({
  'mode.historical': {
    id: 'playbackButtons.mode.historical',
    defaultMessage: 'Historical'
  },
  'mode.live': {
    id: 'playbackButtons.mode.live',
    defaultMessage: 'Live'
  },
  'mode.review': {
    id: 'playbackButtons.mode.review',
    defaultMessage: 'Review'
  },
  'playbackSpeedButton.popupText': {
    id: 'playbackButtons.playbackSpeedButton.popupText',
    defaultMessage: 'Playback Speed'
  },
  'jumpToLiveButton.popupText': {
    id: 'playbackButtons.jumpToLiveButton.popupText',
    defaultMessage: 'Jump To Live'
  },
  'previousTrendButton.popupText': {
    id: 'playbackButtons.previousTrendButton.popupText',
    defaultMessage: 'Previous Trend'
  },
  'nextTrendButton.popupText': {
    id: 'playbackButtons.nextTrendButton.popupText',
    defaultMessage: 'Next Trend'
  },
  'saveAndExitButton.popupText': {
    id: 'playbackButtons.saveAndExitButton.popupText',
    defaultMessage: 'Save & Exit'
  },
  'regionIcon.popupText': {
    id: 'playbackButtons.regionIcon.popupText',
    defaultMessage: 'Click and drag on the time-series to capture a region'
  },
  'clearRegionButton.text': {
    id: 'playbackButtons.clearRegionButton.text',
    defaultMessage: 'Clear'
  },
  'notesTextArea.placeholder': {
    id: 'playbackButtons.notesTextArea.placeholder',
    defaultMessage: 'Notes'
  }
});

const options = [
  {
    key: 1,
    text: '1x',
    value: 1
  },
  {
    key: 2,
    text: '2x',
    value: 2
  },
  {
    key: 10,
    text: '10x',
    value: 10
  },
  {
    key: 60,
    text: '60x',
    value: 60
  }
];

const MODE_COLOUR = {
  [PLAYBACK_MODE.LIVE]: 'green',
  [PLAYBACK_MODE.HISTORICAL]: 'yellow',
  [PLAYBACK_MODE.REVIEW]: 'purple'
};

const ModeButtonGroup = injectIntl(({
  mode,
  isPlaying,
  speed,
  review,
  reviewTrends,
  reviewTrendId,
  allTrendTypes,
  onChangeSpeed,
  onJumpToLive,
  onChangeReviewTrend,
  onExitReview,
  onClearRegion,
  intl: {
    formatMessage
  }
}) => {
  const reviewTrendIndex = _.chain(review)
    .get(ENTITY_PROPERTY.REVIEW.TRENDS)
    .findIndex(trend => trend.id === reviewTrendId)
    .value();
  const reviewTrend = _.chain(review)
    .get(ENTITY_PROPERTY.REVIEW.TRENDS)
    .get(reviewTrendIndex)
    .value();
  const trend = _.get(reviewTrends, reviewTrendIndex);
  return (
    <Button.Group
      size='small'
      style={styles.modeButtonGroup}>
      <Label
        size='large'
        style={styles.modeLabel(mode === PLAYBACK_MODE.REVIEW)}
        detail={mode === PLAYBACK_MODE.REVIEW
          ? {
            style: styles.modeLabelDetail,
            content: _.get(review, ENTITY_PROPERTY.REVIEW.NAME)
          }
          : null}
        content={formatMessage(messages[`mode.${mode}`])}
        color={MODE_COLOUR[mode]} />
      {mode === PLAYBACK_MODE.REVIEW
        ? (
          <Fragment>
            <PopupButton
              popupText={formatMessage(messages['saveAndExitButton.popupText'])}
              icon='save'
              color='purple'
              loading={!review}
              onClick={() => onExitReview()} />
            <PopupButton
              popupText={formatMessage(messages['previousTrendButton.popupText'])}
              disabled={reviewTrendIndex === 0}
              primary
              icon='step backward'
              onClick={() => onChangeReviewTrend(_.get(reviewTrends, `${reviewTrendIndex - 1}.id`))} />
            <Label
              content={`${reviewTrendIndex + 1}/${_.size(reviewTrends)}`}
              color='blue'
              style={styles.progressLabel}
              pointing='right' />
            <Button
              compact
              content={_.get(trend, ENTITY_PROPERTY.TREND.NAME)}
              style={styles.trendButton} />
            {_.chain(review)
              .get(ENTITY_PROPERTY.REVIEW.REGIONS)
              .map(region => {
                const complete = _.get(reviewTrend, `regions.${region.name}`);
                return (
                  <Popup
                    key={`${region.name}${complete}`}
                    inverted={INVERTED_THEME}
                    basic
                    verticalOffset={-10}
                    position='bottom right'
                    style={styles.regionPopup}
                    trigger={(
                      <PopupButton
                        style={styles.regionButton}
                        popupText={region.name}>
                        <Icon.Group>
                          <Icon
                            name={_.get(allTrendTypes, `${region.trendType}.${ENTITY_PROPERTY.TREND_TYPE.ICON}`)}
                            className='review-region'
                            circular
                            color={complete
                              ? _.get(allTrendTypes, `${region.trendType}.${ENTITY_PROPERTY.TREND_TYPE.COLOUR}`)
                              : 'grey'}
                            inverted
                            size='small' />
                          <Icon
                            color={complete
                              ? 'green'
                              : 'red'}
                            name={complete
                              ? 'check'
                              : 'dont'}
                            corner />
                        </Icon.Group>
                      </PopupButton>
                    )}
                    on='hover'
                    hoverable>
                    {complete
                      ? (
                        <Fragment>
                          <Form inverted={INVERTED_THEME}>
                            <Form.TextArea
                              placeholder={formatMessage(messages['notesTextArea.placeholder'])}
                              value={_.get(reviewTrend, `regions.${region.name}.notes`)}
                              readOnly />
                          </Form>
                          <Button.Group
                            vertical
                            labeled
                            icon
                            style={styles.regionButtonGroup}>
                            <Button
                              style={styles.clearRegionButton}
                              icon='dont'
                              fluid
                              negative
                              content={formatMessage(messages['clearRegionButton.text'])}
                              onClick={() => onClearRegion(review, reviewTrendIndex, region.name)} />
                          </Button.Group>
                        </Fragment>
                      )
                      : formatMessage(messages['regionIcon.popupText'])}
                  </Popup>
                );
              })
              .value()}
            <PopupButton
              popupText={formatMessage(messages['nextTrendButton.popupText'])}
              disabled={reviewTrendIndex === _.size(reviewTrends) - 1}
              primary
              icon='step forward'
              onClick={() => onChangeReviewTrend(_.get(reviewTrends, `${reviewTrendIndex + 1}.id`))} />
          </Fragment>
        )
        : (
          <Fragment>
            <Popup
              trigger={(
                <Dropdown
                  className='button'
                  value={speed}
                  floating
                  disabled={mode !== PLAYBACK_MODE.HISTORICAL || !isPlaying}
                  options={options}
                  onChange={(event, { value }) => onChangeSpeed(value)} />
              )}
              style={styles.playbackSpeedPopup}
              content={formatMessage(messages['playbackSpeedButton.popupText'])}
              inverted={INVERTED_THEME} />
            <PopupButton
              popupText={formatMessage(messages['jumpToLiveButton.popupText'])}
              color='green'
              disabled={mode !== PLAYBACK_MODE.HISTORICAL || !isPlaying}
              icon='fast forward'
              onClick={onJumpToLive} />
          </Fragment>
        )}
    </Button.Group>
  );
});

ModeButtonGroup.displayName = 'ModeButtonGroup';

export default ModeButtonGroup;