import _ from 'lodash';
import { Pie, PieChart } from 'recharts';
import { COLOUR, DEFAULT_ALARM_RANGES } from '../../../utils/constants';
import { getColourFromSeverity, getValueAlarmRangeSeverity } from '../../../utils/helpers';

export const DEFAULT_BAR_GAUGE_WIDTH = 140;
export const DEFAULT_BAR_GAUGE_HEIGHT = 70;

const BarGauge = ({
  min,
  max,
  value,
  ranges,
  scale = 1,
  full = false,
  minNeedleWidth = 0,
  cursor = 'auto'
}) => {
  ranges = _.map(
    !_.isEmpty(ranges) ? ranges : DEFAULT_ALARM_RANGES,
    range => ({ ...range })
  );
  const rangeSize = _.last(ranges).max - _.first(ranges).min;
  if (max - min < minNeedleWidth * rangeSize) {
    min = 0.5 * (max + min) - 0.5 * minNeedleWidth * rangeSize;
    max = 0.5 * (max + min) + 0.5 * minNeedleWidth * rangeSize;
  }

  const valueMin = value - 0.5 * minNeedleWidth * rangeSize;
  const valueMax = value + 0.5 * minNeedleWidth * rangeSize;
  const rangesMin = _.first(ranges).min;
  const rangesMax = _.last(ranges).max;
  if (_.isNumber(value)) {
    if (_.isNumber(max) && _.isNumber(min)) {
      min = _.min([min, valueMin]);
      max = _.max([max, valueMax]);
    }

    _.first(ranges).min = _.min([rangesMin, valueMin]);
    _.last(ranges).max = _.max([rangesMax, valueMax]);
  } else {
    _.first(ranges).min = _.min([rangesMin, min]);
    _.last(ranges).max = _.max([rangesMax, max]);
  }

  const activeRanges = _.chain(ranges)
    .filter(range => range.max > min && range.min < max)
    .map(range => ({
      ...range,
      fill: getColourFromSeverity(range.severity)
    }))
    .value();
  if (!_.isEmpty(activeRanges)) {
    _.first(activeRanges).min = min;
    _.last(activeRanges).max = max;
  }
  const valueSeverity = getValueAlarmRangeSeverity(value, ranges);
  const innerRadius = _.isNumber(value) ? 45 : !_.isNumber(min) && !_.isNumber(max) ? 45 : 10;
  return (
    <PieChart
      width={140 * scale}
      height={70 * scale}
      style={{ cursor }}>
      {!full && (
        <Pie
          data={_.map([
            {
              min: _.first(ranges).min,
              max: valueMin,
              fill: COLOUR.MEDIUM_GREY
            },
            {
              min: valueMin,
              max: valueMax,
              fill: getColourFromSeverity(valueSeverity)
            },
            {
              min: valueMax,
              max: _.last(ranges).max,
              fill: COLOUR.MEDIUM_GREY
            }
          ], range => ({
            ...range,
            value: range.max - range.min
          }))}
          dataKey='value'
          isAnimationActive={false}
          stroke='none'
          cx={60 * scale}
          cy={60 * scale}
          innerRadius={0}
          outerRadius={(full ? 50 : innerRadius) * scale}
          startAngle={180}
          endAngle={0} />
      )}
      {!full && (
        <Pie
          isAnimationActive={false}
          data={_.map(ranges, range => ({
            ...range,
            value: range.max - range.min,
            fill: getColourFromSeverity(range.severity)
          }))}
          dataKey='value'
          stroke='none'
          cx={60 * scale}
          cy={60 * scale}
          innerRadius={innerRadius * scale}
          outerRadius={(innerRadius + 3) * scale}
          startAngle={180}
          endAngle={0} />
      )}
      <Pie
        isAnimationActive={false}
        data={_.map([
          {
            min: _.first(ranges).min,
            max: min,
            fill: COLOUR.MEDIUM_GREY
          },
          ...activeRanges,
          {
            min: max,
            max: _.last(ranges).max,
            fill: COLOUR.MEDIUM_GREY
          }
        ], range => ({
          ...range,
          value: range.max - range.min
        }))}
        dataKey='value'
        stroke='none'
        cx={60 * scale}
        cy={60 * scale}
        innerRadius={full ? 0 : (innerRadius + 3) * scale}
        outerRadius={60 * scale}
        startAngle={180}
        endAngle={0} />
    </PieChart>
  );
};

BarGauge.displayName = 'BarGauge';

export default BarGauge;