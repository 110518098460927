export const TOGGLE_MODAL = 'entities/TOGGLE_MODAL';
export const toggleModal = (entityType: string, modal: string, id?: string) => ({
  type: TOGGLE_MODAL,
  entityType,
  payload: {
    modal,
    id
  }
});

export const FETCH_PAGE = 'entities/FETCH_PAGE';
export const fetchPage = (entityType, page, parentId, query) => ({
  type: FETCH_PAGE,
  entityType,
  payload: {
    page,
    parentId,
    query
  }
});

export const FETCH_PAGE_FULFILLED = 'entities/FETCH_PAGE_FULFILLED';
export const fetchPageFulfilled = (entityType, entityPage, parentId) => ({
  type: FETCH_PAGE_FULFILLED,
  entityType,
  payload: {
    entityPage,
    parentId
  }
});

export const FORM_VALUE_CHANGED = 'entities/FORM_VALUE_CHANGED';
export const formValueChanged = (entityType, formType, key, value, errorPath?) => ({
  type: FORM_VALUE_CHANGED,
  entityType,
  formType,
  payload: {
    key,
    value,
    errorPath
  }
});

export const FORM_SETTING_CHANGED = 'entities/FORM_SETTING_CHANGED';
export const formSettingChanged = (entityType, formType, key, value, errorPath) => ({
  type: FORM_SETTING_CHANGED,
  entityType,
  formType,
  payload: {
    key,
    value,
    errorPath
  }
});

export const FORM_COMPLEX_SETTING_CHANGED = 'entities/FORM_COMPLEX_SETTING_CHANGED';
export const formComplexSettingChanged = (entityType, formType, inputType, key, action) => ({
  type: FORM_COMPLEX_SETTING_CHANGED,
  entityType,
  formType,
  inputType,
  payload: {
    key,
    action
  }
});

export const FORM_SEARCH_CHANGED = 'entities/FORM_SEARCH_CHANGED';
export const formSearchChanged = (entityType, formType, key, value, searchEntityType, searchFilter, errorPath) => ({
  type: FORM_SEARCH_CHANGED,
  entityType,
  formType,
  payload: {
    key,
    value,
    searchEntityType,
    searchFilter,
    errorPath
  }
});

export const FORM_SETTING_SEARCH_CHANGED = 'entities/FORM_SETTING_SEARCH_CHANGED';
export const formSettingSearchChanged = (entityType, formType, key, value, searchEntityType, searchFilter, errorPath) => ({
  type: FORM_SETTING_SEARCH_CHANGED,
  entityType,
  formType,
  payload: {
    key,
    value,
    searchEntityType,
    searchFilter,
    errorPath
  }
});

export const FETCH_FORM_SEARCH_RESULTS = 'entities/FETCH_FORM_SEARCH_RESULTS';
export const fetchFormSearchResults = (entityType, isSetting, key, value, searchEntityType, searchFilter) => ({
  type: FETCH_FORM_SEARCH_RESULTS,
  entityType,
  payload: {
    isSetting,
    key,
    value,
    searchEntityType,
    searchFilter
  }
});

export const FETCH_FORM_SEARCH_RESULTS_FULFILLED = 'entities/FETCH_FORM_SEARCH_RESULTS_FULFILLED';
export const fetchFormSearchResultsFulfilled = (entityType, isSetting, key, results, searchEntityType) => ({
  type: FETCH_FORM_SEARCH_RESULTS_FULFILLED,
  entityType,
  payload: {
    isSetting,
    key,
    results,
    searchEntityType
  }
});

export const DELETE_BY_ID = 'entities/DELETE_BY_ID';
export const deleteById = (entityType, id) => ({
  type: DELETE_BY_ID,
  entityType,
  payload: {
    id
  }
});

export interface DeleteByIdFulfilledPayload {
  id: string;
}
export const DELETE_BY_ID_FULFILLED = 'entities/DELETE_BY_ID_FULFILLED';
export const deleteByIdFulfilled = (entityType, id) => ({
  type: DELETE_BY_ID_FULFILLED,
  entityType,
  payload: {
    id
  }
});

export const DELETE_ERROR = 'entities/DELETE_ERROR';
export const deleteError = (entityType, err) => ({
  type: DELETE_ERROR,
  entityType,
  payload: {
    err
  }
});

export const SET_IS_DELETING = 'entities/SET_IS_DELETING';
export const setIsDeleting = (entityType, value) => ({
  type: SET_IS_DELETING,
  entityType,
  payload: {
    value
  }
});

export const FETCH_TYPES = 'entities/FETCH_TYPES';
export const fetchTypes = (entityType, parentId?) => ({
  type: FETCH_TYPES,
  entityType,
  payload: {
    parentId
  }
});

export const FETCH_TYPES_FULFILLED = 'entities/FETCH_TYPES_FULFILLED';
export const fetchTypesFulfilled = (entityType, types) => ({
  type: FETCH_TYPES_FULFILLED,
  entityType,
  payload: {
    types
  }
});

export const FETCH_LABELS = 'entities/FETCH_LABELS';
export const fetchLabels = entityType => ({
  type: FETCH_LABELS,
  entityType,
  payload: {
  }
});

export const FETCH_LABELS_FULFILLED = 'entities/FETCH_LABELS_FULFILLED';
export const fetchLabelsFulfilled = (entityType, labels) => ({
  type: FETCH_LABELS_FULFILLED,
  entityType,
  payload: {
    labels
  }
});

export const ADD = 'entities/ADD';
export const add = (entityType, entity) => ({
  type: ADD,
  entityType,
  payload: {
    entity
  }
});

export const ADD_FULFILLED = 'entities/ADD_FULFILLED';
export const addFulfilled = (entityType, entity) => ({
  type: ADD_FULFILLED,
  entityType,
  payload: {
    entity
  }
});

export const ADD_ERROR = 'entities/ADD_ERROR';
export const addError = (entityType, err) => ({
  type: ADD_ERROR,
  entityType,
  payload: {
    err
  }
});

export const CLEAR_ADD_ERROR = 'entities/CLEAR_ADD_ERROR';
export const clearAddError = entityType => ({
  type: CLEAR_ADD_ERROR,
  entityType
});

export const SET_IS_ADDING = 'entities/SET_IS_ADDING';
export const setIsAdding = (entityType, value) => ({
  type: SET_IS_ADDING,
  entityType,
  payload: {
    value
  }
});

export const PAGE_CHANGED = 'entities/PAGE_CHANGED';
export const pageChanged = (entityType, page, parentId, query) => ({
  type: PAGE_CHANGED,
  entityType,
  payload: {
    page,
    parentId,
    query
  }
});

export const FETCH_BY_ID = 'entities/FETCH_BY_ID';
export const fetchById = (entityType, id) => ({
  type: FETCH_BY_ID,
  entityType,
  payload: {
    id
  }
});

export const INSERT = 'entities/INSERT';
export const insert = (entityType, entity) => ({
  type: INSERT,
  entityType,
  payload: {
    entity
  }
});

export const INSERT_MANY = 'entities/INSERT_MANY';
export const insertMany = (entityType, entities) => ({
  type: INSERT_MANY,
  entityType,
  payload: {
    entities
  }
});

export const FETCH_ALL = 'entities/FETCH_ALL';
export const fetchAll = (entityType, parentId) => ({
  type: FETCH_ALL,
  entityType,
  payload: {
    parentId
  }
});

export const FETCH_ALL_FULFILLED = 'entities/FETCH_ALL_FULFILLED';
export const fetchAllFulfilled = (entityType, entities) => ({
  type: FETCH_ALL_FULFILLED,
  entityType,
  payload: {
    entities
  }
});

export const FETCH_ALL_ERROR = 'entities/FETCH_ALL_ERROR';
export const fetchAllError = (entityType, err) => ({
  type: FETCH_ALL_ERROR,
  entityType,
  payload: {
    err
  }
});

export const SET_CONDITION_FILTER = 'entities/SET_CONDITION_FILTER';
export const setConditionFilter = (entityType, formType, filter) => ({
  type: SET_CONDITION_FILTER,
  entityType,
  formType,
  payload: {
    filter
  }
});

export const ADD_CONDITION = 'entities/ADD_CONDITION';
export const addCondition = (entityType, formType, condition) => ({
  type: ADD_CONDITION,
  entityType,
  formType,
  payload: {
    condition
  }
});

export const REMOVE_CONDITION = 'entities/REMOVE_CONDITION';
export const removeCondition = (entityType, formType, index, errorPathPrefix) => ({
  type: REMOVE_CONDITION,
  entityType,
  formType,
  payload: {
    index,
    errorPathPrefix
  }
});

export const UPDATE_CONDITION = 'entities/UPDATE_CONDITION';
export const updateCondition = (entityType, formType, index, key, value, isSetting, errorPathPrefix) => ({
  type: UPDATE_CONDITION,
  entityType,
  formType,
  payload: {
    index,
    key,
    value,
    isSetting,
    errorPathPrefix
  }
});

export const FETCH_CONDITION_TYPES = 'entities/FETCH_CONDITION_TYPES';
export const fetchConditionTypes = entityType => ({
  type: FETCH_CONDITION_TYPES,
  entityType,
  payload: {}
});

export const FETCH_CONDITION_TYPES_FULFILLED = 'entities/FETCH_CONDITION_TYPES_FULFILLED';
export const fetchConditionTypesFulfilled = (entityType, conditionTypes) => ({
  type: FETCH_CONDITION_TYPES_FULFILLED,
  entityType,
  payload: {
    conditionTypes
  }
});

export const SET_FORM_VALUES = 'entities/SET_FORM_VALUES';
export const setFormValues = (entityType, formType, formValues) => ({
  type: SET_FORM_VALUES,
  entityType,
  formType,
  payload: {
    formValues
  }
});

export const EDIT = 'entities/EDIT';
export const edit = (entityType, entity) => ({
  type: EDIT,
  entityType,
  payload: {
    entity
  }
});

export const EDIT_FULFILLED = 'entities/EDIT_FULFILLED';
export const editFulfilled = (entityType, entity) => ({
  type: EDIT_FULFILLED,
  entityType,
  payload: {
    entity
  }
});

export const EDIT_ERROR = 'entities/EDIT_ERROR';
export const editError = (entityType, err) => ({
  type: EDIT_ERROR,
  entityType,
  payload: {
    err
  }
});

export const SAVE_ALARM_RANGES = 'entities/SAVE_ALARM_RANGES';
export const saveAlarmRanges = (entityType, alarmRanges, id) => ({
  type: SAVE_ALARM_RANGES,
  entityType,
  payload: {
    alarmRanges,
    id
  }
});

export const SET_ENTITY_IS_LOADING = 'entities/SET_ENTITY_IS_LOADING';
export const setEntityIsLoading = (entityType, loading) => ({
  type: SET_ENTITY_IS_LOADING,
  entityType,
  payload: {
    loading
  }
});

export const SET_IS_IMPORTING = 'entities/SET_IS_IMPORTING';
export const setIsImporting = (entityType, importing) => ({
  type: SET_IS_IMPORTING,
  entityType,
  payload: {
    importing
  }
});

export const IMPORT_FILE = 'entities/IMPORT_FILE';
export const importFile = (entityType: string, file, parentId: string, context) => ({
  type: IMPORT_FILE,
  entityType,
  payload: {
    file,
    parentId,
    context
  }
});

export const SET_IMPORT_ERROR = 'entities/SET_IMPORT_ERROR';
export const setImportError = (entityType, codes, row, total: number) => ({
  type: SET_IMPORT_ERROR,
  entityType,
  payload: {
    codes,
    row,
    total
  }
});

export const DOWNLOAD_IMPORT_TEMPLATE = 'entities/DOWNLOAD_IMPORT_TEMPLATE';
export const downloadImportTemplate = (entityType, context) => ({
  type: DOWNLOAD_IMPORT_TEMPLATE,
  entityType,
  payload: {
    context
  }
});

export const PERFORM_SEARCH = 'entities/PERFORM_SEARCH';
export const performSearch = (entityType, parentId) => ({
  type: PERFORM_SEARCH,
  entityType,
  payload: {
    parentId
  }
});

export const DELETE_BY_QUERY = 'entities/DELETE_BY_QUERY';
export const deleteByQuery = (entityType, parentId) => ({
  type: DELETE_BY_QUERY,
  entityType,
  payload: {
    parentId
  }
});

export const SET_SORT = 'entities/SET_SORT';
export const setSort = (entityType, { by, direction }) => ({
  type: SET_SORT,
  entityType,
  payload: {
    by,
    direction
  }
});

export const OPEN_ENTITY = 'entities/OPEN_ENTITY';
export const openEntity = (entityType, entity) => ({
  type: OPEN_ENTITY,
  entityType,
  payload: {
    entity
  }
});

export const ADD_UNIT_TYPE_ATTRIBUTE = 'entities/ADD_UNIT_TYPE_ATTRIBUTE';
export const addUnitTypeAttribute = (unitTypeAttribute, sensorMappingMode, sensorMappings, sensorMappingTemplate) => ({
  type: ADD_UNIT_TYPE_ATTRIBUTE,
  payload: {
    unitTypeAttribute,
    sensorMappingMode,
    sensorMappings,
    sensorMappingTemplate
  }
});

export const DELETE_UNIT_TYPE_ATTRIBUTE = 'entities/DELETE_UNIT_TYPE_ATTRIBUTE';
export const deleteUnitTypeAttribute = id => ({
  type: DELETE_UNIT_TYPE_ATTRIBUTE,
  payload: id
});

export const ADD_UNIT_TYPE_PARAMETER = 'entities/ADD_UNIT_TYPE_PARAMETER';
export const addUnitTypeParameter = (unitTypeParameter, valueMappings) => ({
  type: ADD_UNIT_TYPE_PARAMETER,
  payload: {
    unitTypeParameter,
    valueMappings
  }
});

export const DELETE_UNIT_TYPE_PARAMETER = 'entities/DELETE_UNIT_TYPE_PARAMETER';
export const deleteUnitTypeParameter = id => ({
  type: DELETE_UNIT_TYPE_PARAMETER,
  payload: id
});