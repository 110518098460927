import { ofType } from 'redux-observable';
import { TOGGLE_MODAL, setImportError } from '../../actions/entity';
import { withLatestFrom, filter, map } from 'rxjs/operators';
import { ENTITY_MODAL } from '../../../utils/constants';
import * as fromState from '../../reducers';

export default (action$, state$) => action$.pipe(
  ofType(TOGGLE_MODAL),
  withLatestFrom(state$),
  filter(([action, state]) => action.payload.modal === ENTITY_MODAL.IMPORT && !fromState.getModalOpen(state, action.entityType, ENTITY_MODAL.IMPORT)),
  map(([action, state]) => setImportError(action.entityType, [], null, 0))
);