import _ from 'lodash';
import createCachedSelector from 're-reselect';
import * as fromState from '../../../reducers';
import { getInputs } from '../getInputs';
import { getSensors } from '../getSensors';

export const getFullInputData = createCachedSelector(
  [
    getInputs,
    getSensors,
    fromState.getAllSensorRanges
  ],
  (inputs, sensors, allSensorRanges) => {
    return _.chain(inputs)
      .zip(sensors)
      .map(([input, sensor]) => [input, allSensorRanges[sensor] || []])
      .fromPairs()
      .value();
  }
)(
  (state, chartType) => chartType
);