import { FETCH_ALL, FETCH_ALL_ERROR, FETCH_ALL_FULFILLED } from '../../actions/entity';

export default (state = false, action) => {
  switch (action.type) {
    case FETCH_ALL:
      return true;
    case FETCH_ALL_FULFILLED:
    case FETCH_ALL_ERROR:
      return false;
    default:
      return state;
  }
};

export const getIsFetchingAll = state => state;