import IsInRole from 'containers/auth/IsInRole';
import { injectIntl, InjectedIntl, FormattedMessage } from 'react-intl';
import { Button, ButtonProps, Popup } from 'semantic-ui-react';
import { Role } from 'types';
import { COLOUR, INVERTED_THEME } from 'utils/constants';

interface Props {
  userRole?: Role;
  hoverMessage: FormattedMessage.MessageDescriptor;
  fontSize: string;
  buttonProps: ButtonProps;
  onClick: () => void;
  intl: InjectedIntl;
}

export default injectIntl(function ActionButton({
  userRole,
  hoverMessage,
  fontSize,
  buttonProps,
  onClick,
  intl: {
    formatMessage
  }
}: Props) {
  return (
    <IsInRole role={userRole}>
      <Popup
        inverted={INVERTED_THEME}
        style={{
          background: COLOUR.MEDIUM_GREY,
          border: '1px solid white'
        }}
        content={formatMessage(hoverMessage)}
        trigger={(
          <Button
            {...buttonProps}
            style={{
              border: 'none',
              fontSize
            }}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              onClick();
            }} />
        )} />
    </IsInRole>
  );
});