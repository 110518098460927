import _ from 'lodash';
import { Fragment } from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { Form, Message, Radio } from 'semantic-ui-react';
import { ENTITY_FORM_ELEMENT_TYPE, ENTITY_FORM_MODE, INVERTED_THEME, TYPE, FORM_SEARCH_RESULT, SELECT_LISTS } from '../../utils/constants';
import apiMessages from '../../utils/i18n/shared/api';
import DateRangePicker from '../shared/DateRangePicker';
import ConditionsElement from './ConditionsElement';
import LabelsElement from './LabelsElement';
import ListElement from './ListElement';
import TypesElementContainer from '../../containers/entity/TypesElementContainer';
import MappingElementContainer from '../../containers/entity/MappingElementContainer';
import SelectEntityElementContainer from '../../containers/entity/SelectEntityElementContainer';
import { selectListToOptions } from '../../utils/helpers';
import ScrollAwareFormSelect from '../shared/ScrollAwareFormSelect';

const messages = defineMessages({
  'error.validationFailed.header': {
    id: 'entityForm.error.validationFailed.header',
    defaultMessage: 'Validation Failed'
  }
});

const EntityForm = injectIntl(({
  entityType,
  form,
  labels,
  formSearchResults,
  formValues,
  selectContextLists,
  entityMessages,
  intl: { formatMessage },
  lockType,
  errorResponse,
  errorCodes,
  errorFields,
  mode,
  noFormWrapper,
  inlineWidth,
  conditionTypes,
  scrollContextRef,
  parentId,
  inlineTypeCreation,
  onFormValueChanged,
  onFormSearchChanged,
  onSetConditionFilter,
  onAddCondition,
  onRemoveCondition,
  onUpdateCondition,
  onSubmit
}) => {
  const FormWrapper = noFormWrapper ? Fragment : Form;
  const FormGroupWrapper = inlineWidth ? Form.Group : Fragment;

  const formatKeyedMessage = (messageKeys, messageKey) => {
    const message = messageKeys[messageKey];
    return message ? formatMessage(message) : messageKey;
  };

  const formatEntityMessage = (messageKey) => {
    return formatKeyedMessage(entityMessages, messageKey);
  };

  const InputElmnt = (element, i) => (
    <Form.Input
      error={_.includes(errorFields, element.errorPath || element.name)}
      key={element.name}
      autoFocus={i === 0}
      fluid
      disabled={mode === ENTITY_FORM_MODE.EDIT && element.disableOnEdit}
      label={element.label || formatEntityMessage(`form.${element.name}.label`)}
      placeholder={element.placeholder || formatEntityMessage(`form.${element.name}.placeholder`)}
      value={formValues[element.name] || ''}
      onChange={event => onFormValueChanged(element.name, event.target.value, element.errorPath)} />
  );

  const TextAreaElmnt = (element, i) => (
    <Form.TextArea
      error={_.includes(errorFields, element.errorPath || element.name)}
      key={element.name}
      autoHeight
      disabled={mode === ENTITY_FORM_MODE.EDIT && element.disableOnEdit}
      rows={2}
      autoFocus={i === 0}
      label={element.label || formatEntityMessage(`form.${element.name}.label`)}
      placeholder={element.placeholder || formatEntityMessage(`form.${element.name}.placeholder`)}
      value={formValues[element.name] || ''}
      onChange={event => onFormValueChanged(element.name, event.target.value, element.errorPath)} />
  );

  const SelectElmnt = (element, i) => {
    const options = element.options
      ? _.map(element.options, option => ({
        key: option,
        value: option,
        text: formatEntityMessage(`form.${element.name}.option.${option}`)
      }))
      : selectListToOptions(
        SELECT_LISTS[element.selectListName] || selectContextLists[element.selectContextListName],
        formatMessage
      );
    const formValue = formValues[element.name];
    const value = element.includeOptionData
      ? element.multiple
        ? _.map(formValue, selectedValue => _.get(selectedValue, 'value'))
        : _.get(formValue, 'value')
      : formValue;
    return (
      <ScrollAwareFormSelect
        key={element.name}
        scrollContextRef={scrollContextRef}
        error={_.includes(errorFields, element.errorPath || element.name)}
        rows={2}
        multiple={!!element.multiple}
        clearable={!!element.multiple}
        disabled={mode === ENTITY_FORM_MODE.EDIT && element.disableOnEdit}
        options={_.filter(options, option => !element.hideOptions || !_.includes(element.hideOptions, option.value))}
        autoFocus={i === 0}
        search={!!element.search && ((options, query) => {
          const re = new RegExp(_.escapeRegExp(query), 'i');
          return _.filter(options, opt => re.test(opt.text) || re.test(opt.description));
        })}
        label={element.label || formatEntityMessage(`form.${element.name}.label`)}
        placeholder={element.placeholder || formatEntityMessage(`form.${element.name}.placeholder`)}
        value={value || (element.multiple ? [] : null)}
        onChange={(event, { value }) => onFormValueChanged(
          element.name,
          element.includeOptionData
            ? element.multiple
              ? _.map(value, selectedValue => _.find(options, option => option.value === selectedValue))
              : _.find(options, option => option.value === value)
            : value,
          element.errorPath
        )} />
    );
  };

  const TypesElmnt = (element, i) => (
    <TypesElementContainer
      key={ENTITY_FORM_ELEMENT_TYPE.TYPES}
      element={element}
      entityType={entityType}
      errorFields={errorFields}
      lockType={lockType}
      autoFocus={i === 0}
      onChangeType={val => onFormValueChanged(TYPE, val)}
      onSearchChange={searchQuery => onFormSearchChanged(
        TYPE,
        searchQuery,
        element.entityType
      )}
      settingsValues={formValues.settings}
      selectContextLists={selectContextLists}
      parentId={parentId}
      inlineCreation={inlineTypeCreation}
      entityMessages={entityMessages}
      conditionTypes={conditionTypes}
      scrollContextRef={scrollContextRef} />
  );

  const SearchElmnt = (element, i) => {
    const results = _.get(formSearchResults[element.name], FORM_SEARCH_RESULT.RESULTS) || [];
    return (
      <ScrollAwareFormSelect
        key={element.name}
        scrollContextRef={scrollContextRef}
        error={_.includes(errorFields, element.errorPath || element.name)}
        label={element.label || formatEntityMessage(`form.${element.name}.label`)}
        autoFocus={i === 0}
        fluid
        search
        clearable
        disabled={mode === ENTITY_FORM_MODE.EDIT && element.disableOnEdit}
        loading={_.get(formSearchResults[element.name], FORM_SEARCH_RESULT.IS_LOADING)}
        placeholder={element.placeholder || formatEntityMessage(`form.${element.name}.placeholder`)}
        onChange={(event, { value }) => onFormValueChanged(element.name, value, element.errorPath)}
        onSearchChange={(event, { searchQuery }) => onFormSearchChanged(
          element.name,
          searchQuery,
          element.searchEntityType,
          element.searchFilter,
          element.errorPath
        )}
        options={_.map(results, element.transformResult)}
        value={formValues[element.name] || ''} />
    );
  };

  const ConditionsElmnt = (element, i) => (
    <ConditionsElement
      key='conditions'
      conditionForm={_.map(element.conditionForm, conditionFormElement => {
        const selectList = SELECT_LISTS[conditionFormElement.selectListName]
          || selectContextLists[conditionFormElement.selectContextListName];
        return {
          ...conditionFormElement,
          options: conditionFormElement.options || selectListToOptions(selectList)
        };
      })}
      disabled={element.disabled}
      errorFields={errorFields}
      filter={formValues.conditions.filter}
      list={formValues.conditions.list}
      conditionTypes={conditionTypes}
      loading={element.loading}
      getErrorPath={element.getErrorPath}
      errorPathPrefix={element.errorPathPrefix}
      conditionTypeFilter={element.conditionTypeFilter}
      enumerationPath={element.enumerationPath}
      selectContextLists={selectContextLists}
      onAddCondition={onAddCondition}
      onChangeCondition={onUpdateCondition}
      onRemoveCondition={onRemoveCondition}
      onChangeFilter={onSetConditionFilter} />
  );

  const DateRangeElmnt = (element, i) => (
    <DateRangePicker
      style={{ margin: inlineWidth ? '0 0 1em' : null }}
      key={element.name}
      fromError={_.includes(errorFields, element.fromErrorPath)}
      toError={_.includes(errorFields, element.toErrorPath)}
      noQuickRange
      clearable
      readonly={mode === ENTITY_FORM_MODE.EDIT && element.disableOnEdit}
      noFormWrapper
      upward={!!scrollContextRef}
      includeTime
      fromDate={_.get(formValues, `${element.name}.from`)}
      toDate={_.get(formValues, `${element.name}.to`)}
      onChangeFromDate={fromDate => onFormValueChanged(element.name, {
        ...formValues[element.name],
        from: fromDate
      }, element.fromErrorPath)}
      onChangeToDate={toDate => onFormValueChanged(element.name, {
        ...formValues[element.name],
        to: toDate
      }, element.toErrorPath)} />
  );

  const LabelsElmnt = (element, i) => (
    <LabelsElement
      key={element.name}
      scrollContextRef={scrollContextRef}
      state={formValues[element.name]}
      labels={labels}
      error={_.includes(errorFields, element.errorPath || element.name)}
      label={element.label || formatEntityMessage(`form.${element.name}.label`)}
      placeholder={element.placeholder || formatEntityMessage(`form.${element.name}.placeholder`)}
      allowAdditions={element.allowAdditions}
      multiple={element.multiple}
      errorPath={element.errorPath}
      messages={element.messages}
      onChange={(value, errorPath) => onFormValueChanged(element.name, value, errorPath)} />
  );

  const ListElmnt = (element, i) => (
    <ListElement
      key={element.name}
      scrollContextRef={scrollContextRef}
      label={element.label || formatEntityMessage(`form.${element.name}.label`)}
      state={formValues[element.name]}
      form={element.form}
      errorFields={errorFields}
      disableAdd={mode === ENTITY_FORM_MODE.EDIT && element.disableAddOnEdit}
      getErrorPath={element.getErrorPath}
      errorPath={element.name}
      selectContextLists={selectContextLists}
      onChange={(value, errorPath) => onFormValueChanged(element.name, value, errorPath)} />
  );

  const SelectEntityElmnt = (element, i) => (
    <SelectEntityElementContainer
      key={element.name}
      scrollContextRef={scrollContextRef}
      disabled={mode === ENTITY_FORM_MODE.EDIT && element.disableOnEdit}
      entityType={element.entityType}
      label={element.label || formatEntityMessage(`form.${element.name}.label`)}
      placeholder={element.placeholder || formatEntityMessage(`form.${element.name}.placeholder`)}
      multiple={element.multiple}
      value={formValues[element.name]}
      error={_.includes(errorFields, element.errorPath || element.name)}
      onChange={value => onFormValueChanged(element.name, value, element.errorPath)} />
  );

  const RadioElmnt = (element, i) => (
    <Form.Field
      key={element.name}
      disabled={mode === ENTITY_FORM_MODE.EDIT && element.disableOnEdit}>
      <label>
        {element.label || formatEntityMessage(`form.${element.name}.label`)}
      </label>
      {_.map(element.options, (option, optionIndex) => (
        <Form.Field key={option}>
          <Radio
            label={formatEntityMessage(`form.${element.name}.option.${option}`)}
            name={element.name}
            value={option}
            disabled={mode === ENTITY_FORM_MODE.EDIT && element.disableOnEdit}
            checked={option === formValues[element.name] || (!formValues[element.name] && optionIndex === 0)}
            onChange={(e, { value }) => onFormValueChanged(element.name, value, element.errorPath)} />
        </Form.Field>
      ))}
    </Form.Field>
  );

  const MappingElmnt = (element, i) => (
    <MappingElementContainer
      key={element.name}
      errorFields={errorFields}
      element={element}
      entityType={entityType}
      label={element.label || formatEntityMessage(`form.${element.name}.label`)}
      mapping={formValues[element.name]}
      scrollContextRef={scrollContextRef}
      onChange={value => onFormValueChanged(element.name, value, element.errorPath)} />
  );

  const elementDirectory = {
    [ENTITY_FORM_ELEMENT_TYPE.INPUT]: InputElmnt,
    [ENTITY_FORM_ELEMENT_TYPE.TEXT_AREA]: TextAreaElmnt,
    [ENTITY_FORM_ELEMENT_TYPE.SELECT]: SelectElmnt,
    [ENTITY_FORM_ELEMENT_TYPE.TYPES]: TypesElmnt,
    [ENTITY_FORM_ELEMENT_TYPE.SEARCH]: SearchElmnt,
    [ENTITY_FORM_ELEMENT_TYPE.CONDITIONS]: ConditionsElmnt,
    [ENTITY_FORM_ELEMENT_TYPE.DATE_RANGE]: DateRangeElmnt,
    [ENTITY_FORM_ELEMENT_TYPE.LABELS]: LabelsElmnt,
    [ENTITY_FORM_ELEMENT_TYPE.LIST]: ListElmnt,
    [ENTITY_FORM_ELEMENT_TYPE.SELECT_ENTITY]: SelectEntityElmnt,
    [ENTITY_FORM_ELEMENT_TYPE.RADIO]: RadioElmnt,
    [ENTITY_FORM_ELEMENT_TYPE.MAPPING]: MappingElmnt
  };

  const elements = _.chain(form)
    .filter(element => !element.filter || element.filter(formValues, conditionTypes, selectContextLists))
    .map((element, i) => elementDirectory[element.type](element, i) || null)
    .filter()
    .value();

  return (
    <Fragment>
      {errorResponse != null && (
        <Message
          negative
          list={_.map(errorCodes, code => apiMessages[code] ? formatMessage(apiMessages[code]) : code)}
          header={formatMessage(messages['error.validationFailed.header'])} />
      )}
      <FormWrapper
        {...noFormWrapper
          ? {}
          : {
            inverted: INVERTED_THEME,
            onSubmit: onSubmit
          }}>
        {_.chain(elements)
          .chunk(inlineWidth)
          .map((groupElements, i) => _.size(groupElements) === 1
            ? (
              <Fragment key={i}>
                {groupElements}
              </Fragment>
            )
            : (
              <FormGroupWrapper
                key={i}
                {...inlineWidth ?
                  {
                    widths: _.size(groupElements)
                  }
                  : {}}>
                {groupElements}
              </FormGroupWrapper>
            ))
          .value()}
        <button
          type='submit'
          hidden />
      </FormWrapper>
    </Fragment>
  );
});

export default EntityForm;
