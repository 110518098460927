import _ from 'lodash';
import moment from 'moment';
import createCachedSelector from 're-reselect';
import { DATE_FORMAT } from '../../../../utils/constants';
import * as fromState from '../../../reducers';
import { getInputs } from '../getInputs';
import { getSensors } from '../getSensors';

export const getInputData = createCachedSelector(
  [
    getInputs,
    getSensors,
    fromState.getAllSensorRanges,
    fromState.getFromDate
  ],
  (inputs, sensors, allSensorRanges, fromDate) => {
    const fromTimestamp = parseInt(moment.utc(fromDate, DATE_FORMAT).format('X'));
    return _.chain(inputs)
      .zip(sensors)
      .map(([input, sensor]) => [input, _.dropWhile(allSensorRanges[sensor] || [], ([timestamp]) => timestamp < fromTimestamp)])
      .fromPairs()
      .value();
  }
)(
  (state, chartType) => chartType
);