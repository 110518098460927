import { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createSelector } from 'reselect';
import { Loader } from 'semantic-ui-react';
import { fetchById } from '../../../state/actions/entity';
import * as fromState from '../../../state/reducers';
import { ENTITY_TYPE } from '../../constants';

const mapStateToProps = createSelector(
  [
    (state, { id }) => id,
    state => fromState.getAll(state, ENTITY_TYPE.MODEL)
  ],
  (
    id,
    allModels
  ) => ({
    model: allModels[id]
  })
);

const mapDispatchToProps = (dispatch, { id }) => bindActionCreators({
  fetchModel: () => fetchById(ENTITY_TYPE.MODEL, id)
}, dispatch);

const ModelText = ({
  id,
  model,
  fetchModel
}) => {
  useEffect(() => {
    if (id && !model) {
      fetchModel();
    };
  });
  if (!id) {
    return null;
  };
  return model
    ? model.name
    : (
      <Loader
        inline
        active />
    );
};
ModelText.displayName = 'ModelText';

export default connect(mapStateToProps, mapDispatchToProps)(ModelText);