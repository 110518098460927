import { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createSelector } from 'reselect';
import { Loader } from 'semantic-ui-react';
import { UnitType } from 'types';
import { fetchById } from '../../../state/actions/entity';
import * as fromState from '../../../state/reducers';
import { ENTITY_TYPE } from '../../constants';

const mapStateToProps = createSelector(
  [
    (state, { id }) => id,
    state => fromState.getAll<UnitType>(state, ENTITY_TYPE.UNIT_TYPE)
  ],
  (
    id,
    allUnitTypes
  ) => ({
    unitType: allUnitTypes[id]
  })
);

const mapDispatchToProps = (dispatch, { id }) => bindActionCreators({
  fetchUnitType: () => fetchById(ENTITY_TYPE.UNIT_TYPE, id)
}, dispatch);

const UnitTypeText = ({
  id,
  unitType,
  fetchUnitType
}) => {
  useEffect(() => {
    if (id && !unitType) {
      fetchUnitType();
    };
  });
  if (!id) {
    return null;
  };
  return unitType
    ? unitType.name
    : (
      <Loader
        inline
        active />
    );
};
UnitTypeText.displayName = 'UnitTypeText';

export default connect(mapStateToProps, mapDispatchToProps)(UnitTypeText);