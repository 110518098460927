import _ from 'lodash';
import features from 'utils/i18n/shared/features';
import { messages } from 'utils/dates';
import intl from '../intl';

export default (model, output) => {
  if (model.type !== 'featureGroup') {
    return output;
  };

  const [input, feature, featureInput, featureWindow] = _.split(output, '|');
  let name = `${input} ${intl.formatMessage(features[feature])}`;
  if (featureInput) {
    name = `${name} ${featureInput}`;
  };
  if (featureWindow) {
    name = `${name} ${intl.formatMessage(messages[`quick.${featureWindow}`])}`;
  };
  return name;
};