import _ from 'lodash';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { mergeMap, withLatestFrom } from 'rxjs/operators';
import { DELETE_UNIT_TYPE_ATTRIBUTE } from 'state/actions/entity';
import { deleteById, edit } from '../../actions/entity';
import { ENTITY_TYPE } from '../../../utils/constants';
import * as fromState from '../../reducers';

export default (action$, state$) => action$.pipe(
  ofType(DELETE_UNIT_TYPE_ATTRIBUTE),
  withLatestFrom(state$),
  mergeMap(([action, state]) => {
    const unitTypeAttribute = action.payload;
    const allUnitTypeInstances = fromState.getAll(state, ENTITY_TYPE.UNIT_TYPE_INSTANCE);
    return of(
      deleteById(ENTITY_TYPE.UNIT_TYPE_ATTRIBUTE, unitTypeAttribute.id),
      ..._.chain(allUnitTypeInstances)
        .filter(instance => instance.type === unitTypeAttribute.unitType)
        .map(instance => {
          const editedSettings = _.omit(instance.settings, unitTypeAttribute.id);
          const editedInstance = {
            ...instance,
            settings: editedSettings
          };
          return edit(ENTITY_TYPE.UNIT_TYPE_INSTANCE, editedInstance);
        })
        .value()
    );
  })
);