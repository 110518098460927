import _ from 'lodash';
import createCachedSelector from 're-reselect';
import { getInputAlarmRanges } from '../getInputAlarmRanges';
import { getInputData } from '../getInputData';
import { getInputs } from '../getInputs';
import { getInputSensors } from '../getInputSensors';
import getOffsetScale from './getOffsetScale';
import * as fromState from '../../../reducers';
import { ENTITY_PROPERTY } from '../../../../utils/constants';
import { getInputEnumerations } from '../getEnumerations';

export const getInputOffsetScale = createCachedSelector(
  [
    getInputs,
    getInputAlarmRanges,
    getInputData,
    getInputSensors,
    getInputEnumerations,
    fromState.getFeaturesToggle,
    fromState.getSelectedFeature
  ],
  (
    inputs,
    inputAlarmRanges,
    inputData,
    inputSensors,
    inputEnumerations,
    featuresToggle,
    selectedFeature
  ) => _.chain(inputs)
    .map(input => [
      input,
      getOffsetScale(
        inputAlarmRanges[input],
        inputData[input],
        _.get(inputSensors[input], ENTITY_PROPERTY.SENSOR.VALUE_TYPE),
        inputEnumerations[input],
        featuresToggle ? selectedFeature : null
      )
    ])
    .fromPairs()
    .value()
)(
  (state, chartType) => chartType
);