import { Modal, Message } from 'semantic-ui-react';
import { injectIntl, defineMessages } from 'react-intl';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import AnimatedButton from '../shared/AnimatedButton';

const messages = defineMessages({
  'header.text': {
    id: 'connectorAccessTokenModal.header.text',
    defaultMessage: 'Access Token'
  },
  'copyReminder': {
    id: 'connectorAccessTokenModal.copyReminder',
    defaultMessage: 'Make sure to save the access token after closing this dialog. You won\'t be able to see it again!'
  },
  'copyButton.text': {
    id: 'connectorAccessTokenModal.copyButton.text',
    defaultMessage: 'Copy To Clipboard & Close'
  }
});

const ConnectorAccessTokenModal = injectIntl(({
  open,
  token,
  onClose,
  intl: {
    formatMessage
  }
}) => (
  <Modal
    open={open}
    centered
    closeOnDimmerClick={false}
    closeOnEscape={false}
    size='small'
    onClose={onClose}>
    <Modal.Header>
      {formatMessage(messages['header.text'])}
    </Modal.Header>
    <Modal.Content>
      <Message
        info
        content={formatMessage(messages['copyReminder'])} />
    </Modal.Content>
    <Modal.Actions>
      <CopyToClipboard text={token}>
        <AnimatedButton
          text={formatMessage(messages['copyButton.text'])}
          icon='copy'
          positive
          onClick={onClose} />
      </CopyToClipboard>
    </Modal.Actions>
  </Modal>
));

ConnectorAccessTokenModal.displayName = 'ConnectorAccessTokenModal';

export default ConnectorAccessTokenModal;