import _ from 'lodash';
import { injectIntl } from 'react-intl';
import { Button } from 'semantic-ui-react';
import PredictorAlarmLevelsModalContainer from '../../../containers/alarm/PredictorAlarmLevelsModalContainer';
import SensorAlarmLevelsModalContainer from '../../../containers/alarm/SensorAlarmLevelsModalContainer';
import { ENTITY_TYPE, ROLE } from '../../../utils/constants';
import AlarmLevelsDiagram from '../AlarmLevelsDiagram';
import { ALARM_BUTTON_DIAGRAM_BACKGROUND_COLOUR, ALARM_BUTTON_DIAGRAM_SCALE, ALARM_BUTTON_LABEL_COLOUR } from '../AlarmRangesButton';
import messages from './AlarmLevelsButton.messages';
import styles from './AlarmLevelsButton.styles';
import IsInRole from 'containers/auth/IsInRole';

const AlarmLevelsButton = injectIntl(({
  id,
  index,
  alarmLevels,
  type,
  label,
  entityType,
  onClick,
  onSave,
  intl: {
    formatMessage
  }
}) => {
  return (
    <div style={styles.wrapperDiv}>
      <Button.Group>
        {label && _.map(
          _.isArray(label) ? label : [label],
          l => (
            <Button
              key={l}
              style={styles.labelButton}
              color={ALARM_BUTTON_LABEL_COLOUR}
              content={l} />
          )
        )}
        <Button
          style={styles.diagramButton}
          color={ALARM_BUTTON_DIAGRAM_BACKGROUND_COLOUR}>
          <AlarmLevelsDiagram
            alarmLevels={alarmLevels}
            activeStates={_.range(0, _.size(alarmLevels))}
            scale={ALARM_BUTTON_DIAGRAM_SCALE} />
        </Button>
        <IsInRole role={type === ENTITY_TYPE.SENSOR ? ROLE.MODIFY_SENSOR : null}>
          <Button onClick={onClick}>
            {formatMessage(messages['editButton.text'])}
          </Button>
        </IsInRole>
      </Button.Group>

      {(() => {
        switch (type) {
          case ENTITY_TYPE.SENSOR:
            return (
              <SensorAlarmLevelsModalContainer
                id={id}
                onSave={onSave} />
            );
          default:
            return (
              <PredictorAlarmLevelsModalContainer
                id={id}
                index={index}
                entityType={entityType}
                onSave={onSave} />
            );
        }
      })()}
    </div>
  );
});

AlarmLevelsButton.displayName = 'AlarmLevelsButton';

export default AlarmLevelsButton;