import _ from 'lodash';
import moment from 'moment';
import uuid from 'uuid/v1';
import { ENTITY_TYPE, DATE_FORMAT } from '../../constants';
import alertMapping from '../alertMapping';
import parentTypeSelector from './parentTypeSelector';
import selectContextListsSelector from './selectContextListsSelector';

export default {
  apiPath: 'unittypealertmapping',
  pageUrl: 'alerts',
  parentEntityType: ENTITY_TYPE.UNIT_TYPE_PREDICTOR,
  conditions: true,
  labels: true,
  messages: alertMapping.messages,
  selectContextListsSelector,
  parentTypeSelector,
  parentSearchFilter: parentId => ({
    UnitTypePredictor: {
      $eq: parentId
    }
  }),
  formValueSearchSchema: alertMapping.formValueSearchSchema,
  columns: _.reject(alertMapping.columns, ['name', 'status']),
  form: alertMapping.form,
  createEntity: (values, unitTypePredictor) => ({
    id: uuid(),
    name: values.name,
    unitTypePredictor: unitTypePredictor.id,
    conditions: {
      filter: values.conditions.filter,
      list: _.map(values.conditions.list, condition => ({
        output: condition.name,
        condition: _.pick(condition, ['settings', 'type'])
      }))
    },
    connector: values.connector,
    unitId: null,
    type: values.connector
      ? values.type
      : null,
    settings: values.settings || {},
    from: moment.utc(_.get(values, 'dateRange.from'), DATE_FORMAT).toISOString(),
    to: moment.utc(_.get(values, 'dateRange.to'), DATE_FORMAT).toISOString()
  }),
  populateForm: alertMapping.populateForm
};