import { SET_IS_LOADING } from 'state/actions/loading';

export default (state = false, action) => {
  switch (action.type) {
    case SET_IS_LOADING:
      return action.payload.value;
    default:
      return state;
  };
};

export const getIsLoading = state => state;