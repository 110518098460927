import _ from 'lodash';
import { useState } from 'react';
import { injectIntl } from 'react-intl';
import { Breadcrumb, Button, Dropdown, Label, Popup } from 'semantic-ui-react';
import IsInRole from '../../../containers/auth/IsInRole';
import EditEntityModalContainer from '../../../containers/entity/EditEntityModalContainer';
import { EMPTY_STRING, ENTITY_FORM_ELEMENT_TYPE, ENTITY_TYPE, INVERTED_THEME, ROLE, PAGE_URL } from '../../../utils/constants';
import messages from './PredictorBreadcrumbSection.messages';
import styles from './PredictorBreadcrumbSection.styles';
import { matchesPath } from '../../../utils/helpers';
import AddEntityModalContainer from '../../../containers/entity/AddEntityModalContainer';
import ModelPopup from 'components/shared/ModelPopup';

const ADD_NEW_VALUE = 'addNew';

const PredictorBreadcrumbSection = injectIntl(({
  text,
  id,
  optionGroups,
  pathname,
  predictor,
  model,
  label,
  editModelButtonVisible,
  configurationButtonVisible,
  createPredictor,
  onChange,
  onClickEditPredictorButton,
  onClickEditModelButton,
  onClickConfigurationButton,
  onClickAdd,
  intl: {
    formatMessage
  }
}) => {
  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const options = _.chain(optionGroups)
    .toPairs()
    .map(([key, options]) => [key ? formatMessage({ id: key }) : EMPTY_STRING, options])
    .sortBy('[0]')
    .flatMap(([key, options]) => [
      ..._.map(options, option => ({
        ...option,
        active: false,
        description: key
      }))
    ])
    .value();
  return (
    <Breadcrumb.Section active>
      <AddEntityModalContainer
        createEntity={createPredictor}
        entityType={ENTITY_TYPE.PREDICTOR} />
      <Button.Group size='small'>
        <Dropdown
          trigger={<span style={{ color: open ? 'transparent' : 'unset' }}>{text}</span>}
          scrolling
          onOpen={() => setOpen(true)}
          onClose={() => {
            setOpen(false);
            setSearchText('');
          }}
          onBlur={() => {
            setOpen(false);
            setSearchText('');
          }}
          search
          searchQuery={searchText}
          onSearchChange={(e, { searchQuery }) => setSearchText(searchQuery)}
          onChange={(e, { value }) => {
            if (value === ADD_NEW_VALUE) {
              onClickAdd();
            } else {
              onChange(value);
            };
          }}
          selectOnNavigation={false}
          selectOnBlur={false}
          className='button icon labeled with-description'
          icon={_.chain(optionGroups).values().flatten().find(['value', id]).get('icon').value()}
          style={styles.predictorDropdown}
          options={[
            {
              icon: 'add',
              text: formatMessage(messages['addNew.text']),
              value: ADD_NEW_VALUE,
              key: ADD_NEW_VALUE,
              active: false
            },
            ...options
          ]} />
        <EditEntityModalContainer
          trigger={(
            <Popup
              on='hover'
              inverted={INVERTED_THEME}
              style={styles.buttonPopup}
              trigger={(
                <Button
                  icon='edit'
                  style={styles.editModelButton}
                  onClick={onClickEditPredictorButton} />
              )}>
              {formatMessage(messages['editPredictorButton.popupText'])}
            </Popup>
          )}
          entity={predictor}
          form={[{
            name: 'name',
            type: ENTITY_FORM_ELEMENT_TYPE.INPUT
          }]}
          entityType={ENTITY_TYPE.PREDICTOR}
          createEntity={values => ({
            ...predictor,
            name: values.name
          })} />
        {label && (
          <ModelPopup
            id={label.model}
            trigger={(
              <Label
                size='large'
                style={styles.modelTypeLabel(
                  !editModelButtonVisible && !configurationButtonVisible,
                  !!label.detail,
                  !!label.model
                )}
                color='violet'
                detail={{
                  style: styles.modelTypeLabelDetail,
                  content: label.detail
                }}
                content={formatMessage({ id: label.messageId })} />
            )} />
        )}
        <IsInRole role={ROLE.ADMIN}>
          {editModelButtonVisible && (
            <EditEntityModalContainer
              trigger={(
                <Popup
                  on='hover'
                  inverted={INVERTED_THEME}
                  style={styles.buttonPopup}
                  trigger={(
                    <Button
                      icon='edit'
                      style={styles.editModelButton}
                      primary
                      onClick={onClickEditModelButton} />
                  )}>
                  {formatMessage(messages['editModelButton.popupText'])}
                </Popup>
              )}
              entity={model}
              form={[{ type: 'types' }]}
              entityType={ENTITY_TYPE.MODEL} />
          )}
          {configurationButtonVisible && (
            matchesPath(pathname, PAGE_URL.PREDICTOR_CONFIGURATION)
              ? (
                <Button
                  icon='x'
                  style={styles.configurationButton}
                  primary
                  onClick={() => onChange(id)} />
              )
              : (
                <Popup
                  on='hover'
                  inverted={INVERTED_THEME}
                  style={styles.buttonPopup}
                  trigger={(
                    <Button
                      icon='cog'
                      style={styles.configurationButton}
                      primary
                      onClick={onClickConfigurationButton} />
                  )}>
                  {formatMessage(messages['configurationButton.popupText'])}
                </Popup>
              )
          )}
        </IsInRole>
      </Button.Group>
    </Breadcrumb.Section>
  );
});

PredictorBreadcrumbSection.displayName = 'PredictorBreadcrumbSection';

export default PredictorBreadcrumbSection;