import _ from 'lodash';
import { createSelector } from 'reselect';
import * as fromState from 'state/reducers';
import { ATTRIBUTE_SOURCE, ENTITY_TYPE, ENTITY_FORM_TYPE } from '../../constants';

export default createSelector(
  [
    state => fromState.getFormValues(state, ENTITY_TYPE.TREND, ENTITY_FORM_TYPE.SEARCH),
    state => fromState.getAll(state, ENTITY_TYPE.TREND_TYPE)
  ],
  (formValues, allTrendTypes) => {
    const trendType = allTrendTypes[formValues.type];
    return {
      attributes: trendType
        ? _.map(trendType.attributes, attribute => ({
          ...attribute,
          source: ATTRIBUTE_SOURCE.TREND_ATTRIBUTE,
          text: attribute.name,
          value: attribute.name
        }))
        : []
    };
  }
);