import { connect } from 'react-redux';
import * as fromState from '../../state/reducers';
import CopyButton from '../../components/chart/CopyButton';
import { setCopiedText } from '../../state/actions/chart';
import _ from 'lodash';
import { bindActionCreators } from 'redux';

const mapStateToProps = (state, { chartType }) => ({
  copiedText: fromState.getCopiedText(state, chartType)
});

const mapDispatchToProps = (dispatch, { chartType }) => bindActionCreators({
  onCopy: _.partial(setCopiedText, chartType)
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CopyButton);