import _ from 'lodash';
import moment from 'moment';
import createCachedSelector from 're-reselect';
import { DATE_FORMAT, ENTITY_PROPERTY, VALUE_TYPE } from '../../../../utils/constants';
import * as fromState from '../../../reducers';
import { getFeatureWindowDuration } from '../getFeatureWindowDuration';
import FeatureCalculator from './FeatureCalculator';

const EMPTY = {};

export default (fullDataSelector, sensorsSelector) => createCachedSelector(
  [
    fullDataSelector,
    fromState.getFromDate,
    getFeatureWindowDuration,
    fromState.getFeaturesToggle,
    fromState.getChartIsLoading,
    fromState.getSelectedProperty,
    sensorsSelector
  ],
  (
    fullData,
    fromDate,
    featureWindowDuration,
    featuresToggle,
    isLoading,
    selectedProperty,
    sensors
  ) => {
    if (isLoading || !featuresToggle) {
      return EMPTY;
    };
    const startTimestamp = parseInt(moment.utc(fromDate, DATE_FORMAT).format('X'));
    const windowSeconds = featureWindowDuration.as('seconds');
    return _.mapValues(fullData, (sensorValues, key) => {
      if (_.get(sensors[key], ENTITY_PROPERTY.SENSOR.VALUE_TYPE) !== VALUE_TYPE.FLOAT) {
        return [];
      };
      return new FeatureCalculator(selectedProperty, startTimestamp, windowSeconds).calculate(sensorValues);
    });
  }
)(
  (state, chartType) => chartType
);