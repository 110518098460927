import { AUTH_TYPE, PAGE_URL } from '../../utils/constants';
import { defineMessages, injectIntl } from 'react-intl';
import AuthFormContainer from './AuthFormContainer';
import { Divider, Button } from 'semantic-ui-react';
import QuerySyncLink from '../../containers/navigation/QuerySyncLink';

const messages = defineMessages({
  'header.text': {
    id: 'loginForm.header.text',
    defaultMessage: 'Login'
  },
  'email.placeholder': {
    id: 'loginForm.email.placeholder',
    defaultMessage: 'Email Address'
  },
  'email.label': {
    id: 'loginForm.email.label',
    defaultMessage: 'Email Address'
  },
  'password.placeholder': {
    id: 'loginForm.password.placeholder',
    defaultMessage: 'Password'
  },
  'password.label': {
    id: 'loginForm.password.label',
    defaultMessage: 'Password'
  },
  'performButton.text': {
    id: 'loginForm.performButton.text',
    defaultMessage: 'Login'
  },
  'forgotButton.text': {
    id: 'loginForm.forgotButton.text',
    defaultMessage: 'Forgot password?'
  },
  'resendButton.text': {
    id: 'loginForm.resendButton.text',
    defaultMessage: 'Resend confirmation email'
  }
});

export default injectIntl(({
  intl: {
    formatMessage
  }
}) => (
  <AuthFormContainer
    authType={AUTH_TYPE.LOGIN}
    performIcon='sign in'
    fields={[
      {
        icon: 'mail',
        key: 'email'
      },
      {
        icon: 'key',
        key: 'password',
        type: 'password'
      }
    ]}
    messages={messages}>
    <Divider />
    <Button
      as={QuerySyncLink}
      to={PAGE_URL.FORGOT}
      content={formatMessage(messages['forgotButton.text'])}
      fluid
      color='grey'
      size='tiny' />
    <Divider hidden />
    <Button
      as={QuerySyncLink}
      to={PAGE_URL.RESEND}
      content={formatMessage(messages['resendButton.text'])}
      fluid
      color='grey'
      size='tiny' />
  </AuthFormContainer>
));