import { defineMessages } from 'react-intl';
import { EntityConfig, UnitTypeInstance } from 'types';
import uuid from 'uuid/v1';
import EntityText from '../../../components/entity/EntityText';
import { ENTITY_TYPE, ENTITY_FORM_ELEMENT_TYPE, TYPE, DASHBOARD_VIEW } from '../../constants';
import selectContextListsSelector from './selectContextListsSelector';

const messages = defineMessages({
  'title': {
    id: 'unitTypeInstance.title',
    defaultMessage: 'Instances'
  },
  'columns.type.title': {
    id: 'unitTypeInstance.columns.type.title',
    defaultMessage: 'Unit Type'
  },
  'deckHeader.instances': {
    id: 'unitTypeInstance.deckHeader.instances',
    defaultMessage: 'Instances'
  },
  'deckHeader.types': {
    id: 'unitTypeInstance.deckHeader.types',
    defaultMessage: 'Types'
  }
});

const config: EntityConfig<UnitTypeInstance> = {
  apiPath: 'unittypeinstance',
  pageUrl: 'units',
  parentEntityType: ENTITY_TYPE.UNIT,
  icon: 'cubes',
  disableFetchTypes: true,
  fetchAllOnLoad: true,
  messages,
  typeEntityType: ENTITY_TYPE.UNIT_TYPE,
  selectContextListsSelector,
  parentSearchFilter: parentId => ({
    UnitId: {
      $eq: parentId
    }
  }),
  formValueSearchSchema: [{
    name: TYPE,
    entityType: ENTITY_TYPE.UNIT_TYPE
  }],
  columns: [
    {
      name: 'type',
      width: 3,
      render: ({ type }) => (
        <EntityText
          id={type}
          entityType={ENTITY_TYPE.UNIT_TYPE} />
      )
    }
  ],
  form: [
    {
      type: ENTITY_FORM_ELEMENT_TYPE.TYPES,
      search: true,
      entityType: ENTITY_TYPE.UNIT_TYPE
    }
  ],
  createEntity: (values, unit) => ({
    id: uuid(),
    unitId: unit.id,
    type: values.type,
    settings: values.settings || {}
  }),
  populateForm: entity => ({
    type: entity.type,
    settings: entity.settings
  }),
  deckHeaderMessage: (dashboardView, isTopLevel) => dashboardView === DASHBOARD_VIEW.UNITS
    ? messages['deckHeader.types']
    : messages['deckHeader.instances']
};

export default config;