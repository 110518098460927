import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { performAuthFulfilled, performAuthError, PERFORM_AUTH } from '../../actions/auth';
import * as fromState from '../../reducers';
import * as api from '../../../utils/api';
import { AUTH_TYPE } from '../../../utils/constants';
import queryString from 'query-string';

const AUTH_ENDPOINT = {
  [AUTH_TYPE.REGISTER]: 'register',
  [AUTH_TYPE.LOGIN]: 'login',
  [AUTH_TYPE.FORGOT]: 'forgot',
  [AUTH_TYPE.CHANGE]: 'change',
  [AUTH_TYPE.JOIN]: 'join',
  [AUTH_TYPE.CONFIRM]: 'confirm',
  [AUTH_TYPE.RESEND]: 'resend'
};

const AUTH_HAS_TOKEN = {
  [AUTH_TYPE.REGISTER]: false,
  [AUTH_TYPE.LOGIN]: false,
  [AUTH_TYPE.FORGOT]: false,
  [AUTH_TYPE.CHANGE]: true,
  [AUTH_TYPE.JOIN]: true,
  [AUTH_TYPE.CONFIRM]: true,
  [AUTH_TYPE.RESEND]: false
};

export default (action$, state$) => action$.pipe(
  ofType(PERFORM_AUTH),
  withLatestFrom(state$),
  mergeMap(([action, state]) =>
    api[AUTH_ENDPOINT[action.authType]](...[
      fromState.getAuthFormValues(state, action.authType),
      ...AUTH_HAS_TOKEN[action.authType] ? [queryString.parse(state.router.location.search).token] : []
    ]).pipe(
      map(({ response }) => performAuthFulfilled(action.authType, response)),
      catchError(err => of(performAuthError(action.authType, err)))
    ))
);