import _ from 'lodash';
import { FEATURE_SETTINGS, EMPTY_STRING } from 'utils/constants';

export default (feature, inputUnitsOfMeasurement, featureInputUnitsOfMeasurement) => {
  if (!feature) {
    return inputUnitsOfMeasurement || '';
  };
  if (FEATURE_SETTINGS[feature].HIDE_UNITS_OF_MEASUREMENT) {
    return EMPTY_STRING;
  };
  if (FEATURE_SETTINGS[feature].UNITS_OF_MEASUREMENT_SUFFIX) {
    return `${inputUnitsOfMeasurement || ''}${FEATURE_SETTINGS[feature].UNITS_OF_MEASUREMENT_SUFFIX}`;
  };
  if (!FEATURE_SETTINGS[feature].REQUIRES_INPUT) {
    return inputUnitsOfMeasurement || '';
  };
  if (FEATURE_SETTINGS[feature].UNITS_OF_MEASUREMENT_TEMPLATE) {
    return _.template(FEATURE_SETTINGS[feature].UNITS_OF_MEASUREMENT_TEMPLATE)({
      inputUnitsOfMeasurement,
      featureInputUnitsOfMeasurement
    });
  };
  if (inputUnitsOfMeasurement !== featureInputUnitsOfMeasurement) {
    return EMPTY_STRING;
  };
  return inputUnitsOfMeasurement || '';
};