import { evaluate } from 'feelin';
import _ from 'lodash';
import { Fragment, useEffect } from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { Form } from 'semantic-ui-react';
import SelectEntityElementContainer from '../../../containers/entity/SelectEntityElementContainer';
import { TYPED_LIST_ELEMENT_TYPE } from '../../../utils/constants';
import AnimatedButton from '../../shared/AnimatedButton';
import OptionElement from '../OptionElement';

const messages = defineMessages({
  'typedList.removeButton.text': {
    id: 'entityForm.typedList.removeButton.text',
    defaultMessage: 'Remove'
  },
  'typedList.addButton.text': {
    id: 'entityForm.typedList.addButton.text',
    defaultMessage: 'Add'
  }
});

/**
 * @param {object[]} list - A list of row definitions. These are objects whose
 * properties will be mapped to form elements on a row. Each item must have a `type`
 * property that is equal to the `id` property of one of the items in `types`.
 * @param {object[]} form - This defines the form element that should be used to display
 * and modify the properties of items in `list`.
 * @param {object[]} types - Defines the different types of condition that can be applied
 * to features. Each type must have an `options` property, which is an object that
 * specifies which values the user must provide to fully define a condition of this type.
 */
const TypedList = injectIntl(({
  intl: {
    formatMessage,
    locale
  },
  context = {},
  list,
  form,
  types,
  getTypeText,
  getTypeOptionPlaceholder,
  typeFilter,
  enumerationPath,
  disabled,
  disableAdd,
  loading,
  errorFields,
  getErrorPath,
  getSettingErrorPath,
  errorPathPrefix,
  getUnitsOfMeasurement,
  scrollContextRef,
  selectContextLists,
  onAddItem,
  onChangeItem,
  onRemoveItem
}) => {
  const formatKeyedMessage = (messageKeys, messageKey) => {
    const message = messageKeys[messageKey];
    return message ? formatMessage(message) : messageKey;
  };

  const formatMessageMessage = (messageKey) => {
    return formatKeyedMessage(messages, messageKey);
  };

  const addNewItemWithDefaultValues = properties => onAddItem({
    ..._.chain(form)
      .map(element => [element.name, _.isEmpty(element.options) ? null : _.first(element.options).value])
      .fromPairs()
      .value(),
    ...properties,
    ..._.some(types)
      ? {
        type: _.first(types).id
      }
      : {}
  });

  const dependencyAndFilterContexts = _.map(list, item => _.extend(
    {},
    context,
    _.chain(form)
      .map(element => [
        element.name,
        _.find(
          element.options,
          option => option.value === item[element.name]
        ) || {}
      ])
      .fromPairs()
      .value()
  ));
  const disabledItemElements = _.map(list, (item, itemIndex) => {
    return _.filter(form, element => {
      if (!element.dependency) {
        return false;
      };
      return !evaluate(element.dependency, dependencyAndFilterContexts[itemIndex]);
    });
  });
  const universallyDisabledElements = _.intersection(..._.values(disabledItemElements));

  const filteredItemElementOptions = _.map(list, (item, itemIndex) => {
    return _.map(form, element => {
      if (!element.options) {
        return null;
      };
      if (_.includes(disabledItemElements[itemIndex], element)) {
        return null;
      };
      if (!element.filter) {
        return element.options;
      };
      return _.filter(
        element.options,
        option => evaluate(element.filter, _.extend(
          {},
          dependencyAndFilterContexts[itemIndex],
          {
            $: option
          }
        ))
      );
    });
  });

  const filteredTypes = _.map(list, (item, itemIndex) => {
    return _.filter(
      types,
      type => evaluate(typeFilter || 'true', _.extend(
        {},
        dependencyAndFilterContexts[itemIndex],
        {
          $: type
        }
      ))
    );
  });

  useEffect(() => {
    if (!list) {
      addNewItemWithDefaultValues();
    };
    _.each(list, (item, itemIndex) => {
      _.each(disabledItemElements[itemIndex], element => {
        if (item[element.name]) {
          onChangeItem(itemIndex, element.name, null, false, errorPathPrefix);
        };
      });
      _.each(form, (element, elementIndex) => {
        const filteredOptions = filteredItemElementOptions[itemIndex][elementIndex];
        if (!filteredOptions) {
          return;
        };
        const value = item[element.name];
        if (value && !_.find(filteredOptions, ['value', value])) {
          onChangeItem(itemIndex, element.name, null, false, errorPathPrefix);
        };
        if (!value && !_.find(filteredOptions, ['value', value]) && !_.isEmpty(filteredOptions)) {
          onChangeItem(itemIndex, element.name, _.first(filteredOptions).value, false, errorPathPrefix);
        };
      });
      const type = item.type;
      if (type && !_.find(filteredTypes[itemIndex], ['id', type])) {
        onChangeItem(itemIndex, 'type', null, false, errorPathPrefix);
      };
      if (!type && !_.isEmpty(filteredTypes[itemIndex])) {
        onChangeItem(itemIndex, 'type', _.first(filteredTypes[itemIndex]).id, false, errorPathPrefix);
      };
    });
  });

  return (
    <Fragment>
      {_.map(list, (item, itemIndex) => {
        const unitsOfMeasurement = getUnitsOfMeasurement
          ? getUnitsOfMeasurement(item, form)
          : null;
        const options = _.chain(types)
          .find(['id', item.type])
          .get('options')
          .value();
        const enumerationId = enumerationPath
          ? evaluate(enumerationPath, dependencyAndFilterContexts[itemIndex])
          : null;
        return (
          <Form.Group
            key={itemIndex}
            widths='equal'>
            {_.map(form, (element, elementIndex) => {
              if (_.includes(universallyDisabledElements, element)) {
                return null;
              };
              if (_.includes(disabledItemElements[itemIndex], element)) {
                return <Form.Field key={element.name} />;
              };
              if (element.type === TYPED_LIST_ELEMENT_TYPE.SELECT_ENTITY) {
                return (
                  <SelectEntityElementContainer
                    key={element.name}
                    entityType={element.entityType}
                    upward
                    error={_.includes(errorFields, getErrorPath(itemIndex, element.name))}
                    value={item[element.name] || null}
                    scrollContextRef={scrollContextRef}
                    onChange={value => onChangeItem(itemIndex, element.name, value, false, errorPathPrefix)} />
                );
              } else if (!element.options) {
                return (
                  <Form.Input
                    key={element.name}
                    type={element.inputType || undefined}
                    fluid
                    placeholder={element.placeholderMessage
                      ? formatMessage(element.placeholderMessage)
                      : null}
                    error={_.includes(errorFields, getErrorPath(itemIndex, element.name))}
                    value={item[element.name] || ''}
                    onChange={event => onChangeItem(itemIndex, element.name, event.target.value, false, errorPathPrefix)} />
                );
              };
              return (
                <Form.Select
                  key={element.name}
                  fluid
                  search
                  upward
                  scrolling
                  error={_.includes(errorFields, getErrorPath(itemIndex, element.name))}
                  value={item[element.name]}
                  options={_.map(
                    filteredItemElementOptions[itemIndex][elementIndex],
                    option => _.pick(option, ['key', 'value', 'text', 'icon', 'description'])
                  )}
                  onChange={(event, { value }) => onChangeItem(itemIndex, element.name, value, false, errorPathPrefix)} />
              );
            })}
            {_.some(types) && (
              <Fragment>
                <Form.Select
                  key='type'
                  fluid
                  upward
                  value={item.type}
                  error={_.includes(errorFields, getErrorPath(itemIndex, 'type'))}
                  options={_.map(
                    filteredTypes[itemIndex],
                    type => ({
                      key: type.id,
                      text: getTypeText(type.id),
                      value: type.id
                    })
                  )}
                  onChange={(event, { value }) => onChangeItem(itemIndex, 'type', value, false, errorPathPrefix)} />
                {_.some(options)
                  ? _.chain(options)
                    .toPairs()
                    .map(([option, optionSettings], index) => (
                      <OptionElement
                        key={index}
                        option={option}
                        optionSettings={optionSettings}
                        getSettingErrorPath={getSettingErrorPath}
                        enumerationId={enumerationId}
                        itemIndex={itemIndex}
                        onChangeItem={onChangeItem}
                        errorPathPrefix={errorPathPrefix}
                        item={item}
                        selectContextLists={selectContextLists}
                        errorFields={errorFields}
                        getTypeOptionPlaceholder={getTypeOptionPlaceholder}
                        unitsOfMeasurement={unitsOfMeasurement} />
                    ))
                    .value()
                  : <Form.Field key='emptyOption' />}
              </Fragment>
            )}
            <Form.Field>
              <AnimatedButton
                fluid
                type='button'
                tabIndex={-1}
                disabled={item.disableRemove}
                text={formatMessageMessage('typedList.removeButton.text')}
                icon='minus'
                negative
                floated='right'
                onClick={event => {
                  event.preventDefault();
                  onRemoveItem(itemIndex, errorPathPrefix);
                }} />
            </Form.Field>
          </Form.Group>
        );
      })}
      <Form.Group widths='equal'>
        <Form.Field>
          <AnimatedButton
            disabled={disabled || disableAdd}
            loading={loading}
            text={formatMessageMessage('typedList.addButton.text')}
            icon='add'
            primary
            onClick={event => {
              event.preventDefault();
              addNewItemWithDefaultValues();
            }} />
        </Form.Field>
      </Form.Group>
    </Fragment>
  );
});

TypedList.displayName = 'TypedList';

export default TypedList;