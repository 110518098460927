import { ofType } from 'redux-observable';
import { debounceTime, filter, map } from 'rxjs/operators';
import { fetchFormSearchResults, FORM_SEARCH_CHANGED, FORM_SETTING_SEARCH_CHANGED } from '../../../actions/entity';

export default action$ => action$.pipe(
  ofType(FORM_SEARCH_CHANGED, FORM_SETTING_SEARCH_CHANGED),
  debounceTime(300),
  filter(action => action.payload.value),
  map(action => fetchFormSearchResults(
    action.entityType,
    action.type === FORM_SETTING_SEARCH_CHANGED,
    action.payload.key,
    action.payload.value,
    action.payload.searchEntityType,
    action.payload.searchFilter
  ))
);