import _ from 'lodash';
import { createSelector } from 'reselect';
import * as fromState from 'state/reducers';
import { ENTITY_TYPE } from '../../constants';

export default createSelector(
  [
    state => fromState.getAll(state, ENTITY_TYPE.TREND_TYPE)
  ],
  (
    allTrendTypes
  ) => _.chain(allTrendTypes)
    .toPairs()
    .map(([id, trendType]) => ({
      key: trendType.id,
      text: trendType.name,
      value: trendType.id,
      icon: {
        name: trendType.icon,
        color: trendType.colour,
        inverted: true,
        circular: true,
        size: 'small'
      }
    }))
    .sortBy('text')
    .value()
);