import _ from 'lodash';
import SensorAlarmRangesModalContainer from '../../containers/alarm/SensorAlarmRangesModalContainer';
import PredictorAlarmRangesModalContainer from '../../containers/alarm/PredictorAlarmRangesModalContainer';
import { Button } from 'semantic-ui-react';
import { injectIntl, defineMessages } from 'react-intl';
import { BarGauge } from '../chart/GaugeCard';
import IsInRole from 'containers/auth/IsInRole';
import { ENTITY_TYPE, ROLE } from 'utils/constants';

const messages = defineMessages({
  'editButton.text': {
    id: 'sensorAlarmRangesButton.editButton.text',
    defaultMessage: 'Edit'
  }
});

export const ALARM_BUTTON_DIAGRAM_SCALE = 0.4;
export const ALARM_BUTTON_LABEL_COLOUR = 'black';
export const ALARM_BUTTON_DIAGRAM_BACKGROUND_COLOUR = 'black';

const AlarmRangesButton = injectIntl(({
  id,
  alarmRanges,
  type,
  label,
  entityType,
  onClick,
  onSave,
  intl: {
    formatMessage
  }
}) => {
  return (
    <div style={{ textAlign: 'left' }}>
      <Button.Group>
        {label && _.map(_.isArray(label) ? label : [label], l => (
          <Button
            key={l}
            style={{ border: 'none' }}
            color={ALARM_BUTTON_LABEL_COLOUR}
            content={l} />
        ))}
        <Button
          style={{
            padding: 0,
            border: 'none'
          }}
          color={ALARM_BUTTON_DIAGRAM_BACKGROUND_COLOUR}>
          <BarGauge
            style={{ cursor: 'pointer' }}
            scale={ALARM_BUTTON_DIAGRAM_SCALE}
            full
            min={_.chain(alarmRanges).first().get('min').value() || 0}
            max={_.chain(alarmRanges).last().get('max').value() || 0}
            ranges={alarmRanges} />
        </Button>
        <IsInRole role={type === ENTITY_TYPE.SENSOR ? ROLE.MODIFY_SENSOR : null}>
          <Button onClick={onClick}>
            {formatMessage(messages['editButton.text'])}
          </Button>
        </IsInRole>
      </Button.Group>

      {(() => {
        switch (type) {
          case ENTITY_TYPE.SENSOR:
            return (
              <SensorAlarmRangesModalContainer
                id={id}
                onSave={onSave} />
            );
          default:
            return (
              <PredictorAlarmRangesModalContainer
                entityType={entityType}
                onSave={onSave} />
            );
        }
      })()}
    </div>
  );
});

AlarmRangesButton.displayName = 'AlarmRangesButton';

export default AlarmRangesButton;