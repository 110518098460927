import _ from 'lodash';
import createCachedSelector from 're-reselect';
import { connect } from 'react-redux';
import CardsChart from '../../../components/chart/CardsChart';
import { setItemSelected } from '../../../state/actions/chart';
import * as fromState from '../../../state/reducers';
import { transformChartTypeSelectors } from '../../../utils/helpers';
import getFunctionCards from './getFunctionCards';
import getInputCards from './getInputCards';
import getOutputCards from './getOutputCards';

const mapStateToProps = createCachedSelector(
  transformChartTypeSelectors([
    getFunctionCards,
    getInputCards,
    getOutputCards,
    fromState.getChartIsLoading
  ]),
  (
    functionCards,
    inputCards,
    outputCards,
    isLoading
  ) => ({
    cardsByType: {
      function: _.some(inputCards) || _.some(outputCards) ? [] : functionCards,
      input: inputCards,
      output: outputCards
    },
    isLoading
  })
)(
  (state, { chartType }) => chartType
);

const mapDispatchToProps = {
  onClickCard: setItemSelected
};

export default connect(mapStateToProps, mapDispatchToProps)(CardsChart);