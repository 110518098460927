import _ from 'lodash';
import { createSelector } from 'reselect';
import { Unit, UnitType, UnitTypePredictor, UnitTypeAttribute, UnitTypeParameter, UnitTypeInstance, Predictor } from 'types';
import { DASHBOARD_VIEW, ENTITY_PROPERTY, ENTITY_TYPE } from '../../../../utils/constants';
import * as fromState from '../../../reducers';
import { getUnitId } from '../getUnitId';
import { getUnitTypeId } from '../getUnitTypeId';
import { getUnitTypePredictorId } from '../getUnitTypePredictorId';

interface DashboardCards {
  units?: Unit[];
  unitTypes?: UnitType[];
  predictors?: Predictor[];
  sensorGroups?: Predictor[];
  unitTypePredictors?: UnitTypePredictor[];
  attributeGroups?: UnitTypePredictor[];
  unitTypeAttributes?: UnitTypeAttribute[];
  unitTypeParameters?: UnitTypeParameter[];
  unitTypeInstances?: UnitTypeInstance[];
}

const getUnitDashboardCards = createSelector(
  [
    state => getUnitId(state),
    state => fromState.getAll<Predictor>(state, ENTITY_TYPE.PREDICTOR),
    state => fromState.getAll<Unit>(state, ENTITY_TYPE.UNIT),
    state => fromState.getAll<UnitType>(state, ENTITY_TYPE.UNIT_TYPE),
    state => fromState.getAll<UnitTypeInstance>(state, ENTITY_TYPE.UNIT_TYPE_INSTANCE)
  ],
  (unitId, allPredictors, allUnits, allUnitTypes, allUnitTypeInstances) => {
    const predictorsInUnit = _.filter(allPredictors, [ENTITY_PROPERTY.PREDICTOR.UNIT, unitId]);
    return {
      units: _.filter(allUnits, [ENTITY_PROPERTY.UNIT.UNIT, unitId]),
      unitTypes: _.chain(allUnitTypeInstances)
        .filter(uti => uti.unitId === unitId && uti.type in allUnitTypes)
        .map(uti => _.find(allUnitTypes, t => t.id === uti.type))
        .value(),
      predictors: unitId ? _.filter(predictorsInUnit, predictor => predictor.type) : null,
      sensorGroups: unitId ? _.filter(predictorsInUnit, predictor => !predictor.type) : null
    } as DashboardCards;
  }
);

const getUnitTypeDashboardCards = createSelector(
  [
    state => getUnitTypeId(state),
    state => getUnitTypePredictorId(state),
    state => fromState.getAll<UnitType>(state, ENTITY_TYPE.UNIT_TYPE),
    state => fromState.getAll<UnitTypePredictor>(state, ENTITY_TYPE.UNIT_TYPE_PREDICTOR),
    state => fromState.getAll<UnitTypeAttribute>(state, ENTITY_TYPE.UNIT_TYPE_ATTRIBUTE),
    state => fromState.getAll<UnitTypeParameter>(state, ENTITY_TYPE.UNIT_TYPE_PARAMETER),
    state => fromState.getAll<UnitTypeInstance>(state, ENTITY_TYPE.UNIT_TYPE_INSTANCE),
    state => fromState.getAll<Predictor>(state, ENTITY_TYPE.PREDICTOR)
  ],
  (
    unitTypeId,
    unitTypePredictorId,
    allUnitTypes,
    allUnitTypePredictors,
    allUnitTypeAttributes,
    allUnitTypeParameters,
    allUnitTypeInstances,
    allPredictors
  ) => {
    if (unitTypePredictorId) {
      return {
        predictors: _.filter(
          allPredictors,
          predictor => predictor.unitTypePredictor === unitTypePredictorId && predictor.type
        ),
        sensorGroups: _.filter(
          allPredictors,
          predictor => predictor.unitTypePredictor === unitTypePredictorId && !predictor.type
        )
      } as DashboardCards;
    } else if (unitTypeId) {
      return {
        unitTypes: _.filter(allUnitTypes, ['baseType', unitTypeId]),
        unitTypePredictors: _.filter(
          _.values(allUnitTypePredictors),
          unitTypePredictor => unitTypePredictor.unitType === unitTypeId && unitTypePredictor.type
        ),
        unitTypeAttributes: _.filter(
          _.values(allUnitTypeAttributes),
          unitTypeAttribute => unitTypeAttribute.unitType === unitTypeId
        ),
        unitTypeParameters: _.filter(
          _.values(allUnitTypeParameters),
          unitTypeParameter => unitTypeParameter.unitType === unitTypeId
        ),
        unitTypeInstances: _.filter(
          _.values(allUnitTypeInstances),
          unitTypeInstance => unitTypeInstance.type === unitTypeId
        ),
        attributeGroups: _.filter(
          _.values(allUnitTypePredictors),
          unitTypePredictor => unitTypePredictor.unitType === unitTypeId && !unitTypePredictor.type
        )
      } as DashboardCards;
    } else {
      return {
        unitTypes: _.filter(allUnitTypes, ['baseType', unitTypeId])
      } as DashboardCards;
    }
  }
);

export const getCards = createSelector(
  [
    state => getUnitTypeDashboardCards(state),
    state => getUnitDashboardCards(state),
    state => fromState.getDashboardView(state)
  ],
  (unitTypeDashboardCards, unitDashboardCards, view) => view === DASHBOARD_VIEW.UNIT_TYPES
    ? unitTypeDashboardCards
    : unitDashboardCards
);