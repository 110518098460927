import _ from 'lodash';
import { TooltipTableRow } from '../../../components/chart/TimeSeriesChart';
import { transformChartTypeSelectors } from '../../../utils/helpers';
import * as fromState from '../../../state/reducers';
import { connect } from 'react-redux';
import { getChartData } from '../../../state/selectors/chart';
import createCachedSelector from 're-reselect';

const mapStateToProps = createCachedSelector(
  [
    ...transformChartTypeSelectors([
      fromState.getHoverLine,
      getChartData
    ]),
    (state, { timestamp }) => timestamp,
    (state, { line }) => line.id,
    (state, { payload, line }) => _.get(payload, line.id)
  ],
  (
    hoverLine,
    chartData,
    timestamp,
    lineId,
    value
  ) => ({
    hoverLine,
    mostRecentValue: _.isUndefined(value)
      ? _.chain(chartData)
        .findLast(entry => entry.timestamp <= timestamp && lineId in entry)
        .get(lineId)
        .value()
      : null
  })
)(
  (state, props) => props.chartType
);

export default connect(mapStateToProps)(TooltipTableRow);