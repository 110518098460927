import _ from 'lodash';
import { useEffect } from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { Button, Popup } from 'semantic-ui-react';
import { INVERTED_THEME, COLOUR, CHART_VIEW } from '../../utils/constants';

const messages = defineMessages({
  'view.label': {
    id: 'chartDropdown.view.label',
    defaultMessage: 'View'
  },
  'views.cards': {
    id: 'chartDropdown.views.cards',
    defaultMessage: 'Cards'
  },
  'views.heatMap': {
    id: 'chartDropdown.views.heatMap',
    defaultMessage: 'Heat Map'
  },
  'views.timeSeries': {
    id: 'chartDropdown.views.timeSeries',
    defaultMessage: 'Time Series'
  },
  'views.map': {
    id: 'chartDropdown.views.map',
    defaultMessage: 'Map'
  }
});

const CHART_VIEW_ICON = {
  [CHART_VIEW.CARDS]: 'tachometer alternate',
  [CHART_VIEW.HEAT_MAP]: 'table',
  [CHART_VIEW.TIME_SERIES]: 'chart line',
  [CHART_VIEW.MAP]: 'crosshairs'
};

const ChartDropdown = injectIntl(({
  onChange,
  value,
  mapViewEnabled,
  intl: {
    formatMessage
  }
}) => {
  useEffect(() => {
    if (value === CHART_VIEW.MAP && !mapViewEnabled) {
      onChange(CHART_VIEW.CARDS);
    };
  });
  return (
    <Button.Group size='small'>
      {_.map(_.values(CHART_VIEW), view => {
        if (view === CHART_VIEW.MAP && !mapViewEnabled) {
          return null;
        };
        return (
          <Popup
            inverted={INVERTED_THEME}
            key={view}
            content={formatMessage(messages[`views.${view}`])}
            style={{
              border: '1px solid white',
              background: COLOUR.MEDIUM_GREY
            }}
            trigger={(
              <Button
                primary={view === value}
                icon={CHART_VIEW_ICON[view]}
                onClick={() => onChange(view)}
                style={{ border: 0 }} />
            )} />
        );
      })}
    </Button.Group>
  );
});

ChartDropdown.displayName = 'ChartDropdown';

export default ChartDropdown;