import _ from 'lodash';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { ADD_UNIT_TYPE_PARAMETER, editFulfilled } from '../../actions/entity';
import * as fromState from '../../reducers';
import * as api from '../../../utils/api';
import { ENTITY_TYPE } from '../../../utils/constants';
import { takeUntilAppReset } from '../../../utils/helpers';
import { addError, addFulfilled } from '../../actions/entity';

const getApiCall = (action, state) => {
  const editedInstances = _.chain(action.payload.valueMappings)
    .keys()
    .map(unitTypeInstanceId => {
      const unitTypeInstance = fromState.getById(
        state,
        ENTITY_TYPE.UNIT_TYPE_INSTANCE,
        unitTypeInstanceId
      );
      const settings = unitTypeInstance.settings;
      const editedSettings = {
        ...settings,
        [action.payload.unitTypeParameter.id]: action.payload.valueMappings[unitTypeInstanceId]
      };

      return {
        ...unitTypeInstance,
        settings: editedSettings
      };
    })
    .value();

  return api.createUnitTypeParameterWithInstances({
    parameter: action.payload.unitTypeParameter,
    editedInstances: editedInstances,
    token: fromState.getUser(state).token
  });
};

export default (action$, state$) => action$.pipe(
  ofType(ADD_UNIT_TYPE_PARAMETER),
  withLatestFrom(state$),
  mergeMap(([action, state]) => getApiCall(action, state).pipe(
    takeUntilAppReset(action$),
    mergeMap(({ response }) => of(
      addFulfilled(ENTITY_TYPE.UNIT_TYPE_PARAMETER, response.parameter),
      ..._.map(
        response.editedInstances,
        instance => editFulfilled(ENTITY_TYPE.UNIT_TYPE_INSTANCE, instance)
      )
    )),
    catchError((err, caught) => {
      if (err.status === 400) {
        return of(addError(action.entityType, err));
      }
      return api.onError(err, caught);
    })
  ))
);