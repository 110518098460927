import _ from 'lodash';
import createCachedSelector from 're-reselect';
import { connect } from 'react-redux';
import { compose, withHandlers } from 'recompose';
import { bindActionCreators } from 'redux';
import ChartKey from '../../../components/chart/ChartKey';
import { editFunction, featureWindowChanged, removeFunction, setEditingFunction, setFeaturesToggle, setFunctionDescriptionEditText, setHoverLine, setItemSelected, setSelected, setSelectedFeature, setSelectedProperty } from '../../../state/actions/chart';
import * as fromState from '../../../state/reducers';
import { ENTITY_TYPE, REFERENCE_AREA_CLASS_NAME, ENTITY_MODAL } from '../../../utils/constants';
import { transformChartTypeSelectors } from '../../../utils/helpers';
import getFunctions from './getFunctions';
import getInputs from './getInputs';
import getOutputs from './getOutputs';
import { toggleModal } from '../../../state/actions/entity';
import { getPredictor } from '../../../state/selectors/chart';

const mapStateToProps = createCachedSelector(
  [
    ...transformChartTypeSelectors([
      getFunctions,
      getInputs,
      getOutputs,
      fromState.getEditingFunction,
      fromState.getFunctionDescriptionEditText,
      state => fromState.getIsEditing(state, ENTITY_TYPE.PREDICTOR),
      fromState.getFeaturesToggle,
      fromState.getSelectedFeature,
      fromState.getFeatureWindow,
      fromState.getSelectedProperty,
      fromState.getChartIsLoading,
      getPredictor
    ])
  ],
  (
    functions,
    inputs,
    outputs,
    editingItemId,
    editText,
    isSaving,
    featuresToggle,
    selectedFeature,
    featureWindow,
    selectedProperty,
    isLoading,
    predictor
  ) => ({
    itemGroups: _.reject([
      outputs,
      inputs,
      functions
    ], _.isEmpty),
    editingItemId,
    editText,
    isSaving,
    featuresToggle,
    selectedFeature,
    featureWindow,
    selectedProperty,
    isLoading,
    predictorId: predictor && predictor.id
  })
)(
  (state, { chartType }) => chartType
);

const mapDispatchToProps = (dispatch, { chartType }) => bindActionCreators({
  onSelect: _.partial(setItemSelected, chartType),
  onSetSelected: (selectionType, ids) => setSelected(chartType, selectionType, ids),
  openFunctionSensorsModal: id => toggleModal(ENTITY_TYPE.PREDICTOR, ENTITY_MODAL.ALTERNATIVE_EDIT, id),
  onSetEditingId: _.partial(setEditingFunction, chartType),
  onSetEditText: _.partial(setFunctionDescriptionEditText, chartType),
  onRemove: _.partial(removeFunction, chartType),
  onEdit: _.partial(editFunction, chartType),
  onSetHoverItem: _.partial(setHoverLine, chartType),
  onSetFeaturesToggle: _.partial(setFeaturesToggle, chartType),
  onSetSelectedFeature: _.partial(setSelectedFeature, chartType),
  onSetFeatureWindow: _.partial(featureWindowChanged, chartType),
  onSetSelectedProperty: _.partial(setSelectedProperty, chartType)
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    onOpenFunctionSensorsModal: ({ openFunctionSensorsModal, predictorId }) =>
      () => openFunctionSensorsModal(predictorId),
    onHoverItem: ({ itemGroups, onSetHoverItem }) => hoverLine => {
      // this is a hack to improve the performance of the time series chart
      // normally should never access the dom directly when using react
      // recharts library does not allow showing/hiding subcomponents without re-rendering entire chart
      _.forEach(_.flatten(itemGroups), ({ id: key }) => {
        const className = _.replace(key, / /g, '-');
        _.forEach(document.getElementsByClassName(className), element => {
          element.style.display = 'block';
        });

        _.forEach(document.getElementsByClassName(`${className} ${REFERENCE_AREA_CLASS_NAME}`), element => {
          if (!_.some(element.childNodes)) {
            return;
          };

          element.childNodes[0].style.display = 'none';
        });

        if (!hoverLine) {
          return;
        }

        if (hoverLine === key) {
          _.forEach(document.getElementsByClassName(`${className} ${REFERENCE_AREA_CLASS_NAME}`), element => {
            if (!_.some(element.childNodes)) {
              return;
            };

            element.childNodes[0].style.display = 'block';
          });
          return;
        }

        _.forEach(document.getElementsByClassName(className), element => {
          element.style.display = 'none';
        });
      });

      return onSetHoverItem(hoverLine);
    }
  })
)(ChartKey);