import { SET_REVIEW_TREND_ID, CHANGE_REVIEW_TREND } from '../../actions/playback';

export default (state = true, action) => {
  switch (action.type) {
    case SET_REVIEW_TREND_ID:
    case CHANGE_REVIEW_TREND:
      return action.payload.id;
    default:
      return state;
  }
};

export const getReviewTrendId = state => state;