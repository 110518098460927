import { defineMessages, FormattedMessage } from 'react-intl';
import uuid from 'uuid/v1';
import EntityText from '../../../components/entity/EntityText';
import { ENTITY_TYPE, ENTITY_FORM_ELEMENT_TYPE, TYPE, PAGE_URL, DATE_FORMAT, DASHBOARD_VIEW, ROLE } from '../../constants';
import selectContextListsSelector from './selectContextListsSelector';
import _ from 'lodash';
import itemPageComponent from './itemPageComponent';
import { toPath } from '../../helpers';
import textComponent from './textComponent';
import moment from 'moment';

const messages = defineMessages({
  'title': {
    id: 'unitTypeInstance.title',
    defaultMessage: 'Instances'
  },
  'columns.name.title': {
    id: 'unitTypePredictor.columns.name.title',
    defaultMessage: 'Name'
  },
  'columns.category.title': {
    id: 'unitTypePredictor.columns.category.title',
    defaultMessage: 'Type'
  },
  'columns.model.title': {
    id: 'unitTypePredictor.columns.model.title',
    defaultMessage: 'Model'
  },
  'columns.from.title': {
    id: 'unitTypePredictor.columns.from.title',
    defaultMessage: 'From'
  },
  'columns.to.title': {
    id: 'unitTypePredictor.columns.to.title',
    defaultMessage: 'To'
  },
  'types.label': {
    id: 'unitTypePredictor.form.types.label',
    defaultMessage: 'Model'
  },
  'types.placeholder': {
    id: 'unitTypePredictor.form.types.placeholder',
    defaultMessage: 'Model'
  },
  'form.name.label': {
    id: 'unitTypePredictor.form.name.label',
    defaultMessage: 'Name'
  },
  'form.name.placeholder': {
    id: 'unitTypePredictor.form.name.placeholder',
    defaultMessage: 'Name'
  },
  'form.category.label': {
    id: 'unitTypePredictor.form.category.label',
    defaultMessage: 'Type'
  },
  'form.category.option.attributeGroup': {
    id: 'unitTypePredictor.form.category.option.attributeGroup',
    defaultMessage: 'Attribute Group'
  },
  'form.category.option.predictor': {
    id: 'unitTypePredictor.form.category.option.predictor',
    defaultMessage: 'Predictor'
  },
  'form.mode.label': {
    id: 'unitTypePredictor.form.mode.label',
    defaultMessage: 'Mode'
  },
  'form.mode.option.live': {
    id: 'unitTypePredictor.form.mode.option.live',
    defaultMessage: 'Live'
  },
  'form.mode.option.historical': {
    id: 'unitTypePredictor.form.mode.option.historical',
    defaultMessage: 'Historical'
  },
  'form.attributes.label': {
    id: 'unitTypePredictor.form.attributes.label',
    defaultMessage: 'Attributes'
  },
  'form.attributes.placeholder': {
    id: 'unitTypePredictor.form.attributes.placeholder',
    defaultMessage: 'Attributes'
  },
  'deckHeader.predictors': {
    id: 'unitTypePredictor.deckHeader.predictors',
    defaultMessage: 'Predictors'
  },
  'deckHeader.inheritedPredictors': {
    id: 'unitTypePredictor.deckHeader.inheritedPredictors',
    defaultMessage: 'Inherited Predictors'
  }
});

const CATEGORY = {
  PREDICTOR: 'predictor',
  ATTRIBUTE_GROUP: 'attributeGroup'
};

const MODE = {
  LIVE: 'live',
  HISTORICAL: 'historical'
};

export default {
  apiPath: 'unittypepredictor',
  pageUrl: 'unittypes',
  parentEntityType: ENTITY_TYPE.UNIT_TYPE,
  icon: 'cubes',
  disableFetchTypes: true,
  messages,
  typeEntityType: ENTITY_TYPE.MODEL,
  selectContextListsSelector,
  fetchAllOnLoad: true,
  navigateOnAdd: false,
  navigateOnClick: true,
  itemPageUrl: PAGE_URL.UNIT_TYPE_PREDICTOR_PAGE,
  itemPageComponent,
  textComponent,
  viewRole: ROLE.VIEW_UNIT_TYPE,
  modifyRole: ROLE.MODIFY_UNIT_TYPE,
  itemUrl: entity => toPath(PAGE_URL.UNIT_TYPE_PREDICTOR_PAGE, {
    unitTypeId: entity.unitType,
    parentId: entity.id
  }),
  parentSearchFilter: parentId => ({
    UnitType: {
      $eq: parentId
    }
  }),
  formValueSearchSchema: [{
    name: TYPE,
    entityType: ENTITY_TYPE.MODEL
  }],
  columns: [
    {
      name: 'name',
      width: 3,
      render: ({ name }) => name
    },
    {
      name: 'category',
      width: 3,
      render: ({ type }) => (
        <FormattedMessage
          {...messages[type
            ? 'form.category.option.predictor'
            : 'form.category.option.attributeGroup'
          ]} />
      )
    },
    {
      name: 'model',
      width: 3,
      render: ({ type }) => (
        <EntityText
          id={type}
          entityType={ENTITY_TYPE.MODEL} />
      )
    },
    {
      name: 'from',
      width: 3,
      render: ({ from }) => from && moment.utc(from).local().format(DATE_FORMAT)
    },
    {
      name: 'to',
      width: 3,
      render: ({ to }) => to && moment.utc(to).local().format(DATE_FORMAT)
    }
  ],
  typeInheritedFields: ['name'],
  transformAfterTypeCreation: (entity, type, selectContextLists) => ({
    ...entity,
    settings: _.mapValues(
      type.options,
      (optionSettings, key) => {
        const attribute = _.chain(selectContextLists.inputs)
          .find(['value', key])
          .get('id')
          .value();
        if (attribute) {
          return attribute;
        }
        return _.chain(selectContextLists.parameters)
          .find(['value', key])
          .get('id')
          .value();
      }
    )
  }),
  form: [
    {
      name: 'name',
      type: ENTITY_FORM_ELEMENT_TYPE.INPUT
    },
    {
      name: 'category',
      type: ENTITY_FORM_ELEMENT_TYPE.RADIO,
      options: [CATEGORY.PREDICTOR, CATEGORY.ATTRIBUTE_GROUP],
      disableOnEdit: true
    },
    {
      name: 'mode',
      type: ENTITY_FORM_ELEMENT_TYPE.RADIO,
      options: [MODE.LIVE, MODE.HISTORICAL],
      disableOnEdit: true,
      filter: values => values.category !== CATEGORY.ATTRIBUTE_GROUP
    },
    {
      name: 'dateRange',
      type: ENTITY_FORM_ELEMENT_TYPE.DATE_RANGE,
      errorPaths: ['from', 'to'],
      fromErrorPath: 'from',
      toErrorPath: 'to',
      filter: values => values.category !== CATEGORY.ATTRIBUTE_GROUP
        && values.mode === MODE.HISTORICAL
    },
    {
      type: ENTITY_FORM_ELEMENT_TYPE.TYPES,
      search: true,
      entityType: ENTITY_TYPE.MODEL,
      create: true,
      hideCreateFields: ['name'],
      filter: values => values.category !== CATEGORY.ATTRIBUTE_GROUP
    },
    {
      name: 'attributes',
      type: ENTITY_FORM_ELEMENT_TYPE.SELECT_ENTITY,
      entityType: ENTITY_TYPE.UNIT_TYPE_ATTRIBUTE,
      multiple: true,
      search: true,
      filter: values => values.category === CATEGORY.ATTRIBUTE_GROUP
    }
  ],
  createEntity: (values, unitType) => ({
    id: uuid(),
    name: values.name,
    unitId: null,
    unitType: unitType.id,
    type: values.category === CATEGORY.ATTRIBUTE_GROUP
      ? null
      : values.type || null,
    settings: values.category === CATEGORY.ATTRIBUTE_GROUP
      ? {}
      : values.settings || {},
    attributes: values.category === CATEGORY.ATTRIBUTE_GROUP
      ? values.attributes || []
      : null,
    from: moment.utc(_.get(values, 'dateRange.from'), DATE_FORMAT).toISOString(),
    to: moment.utc(_.get(values, 'dateRange.to'), DATE_FORMAT).toISOString()
  }),
  populateForm: entity => ({
    name: entity.name,
    category: entity.type
      ? CATEGORY.PREDICTOR
      : CATEGORY.ATTRIBUTE_GROUP,
    type: entity.type,
    settings: entity.settings,
    attributes: entity.attributes,
    dateRange: {
      from: entity.from && moment.utc(entity.from).format(DATE_FORMAT),
      to: entity.to && moment.utc(entity.to).format(DATE_FORMAT)
    },
    mode: entity.from || entity.to
      ? MODE.HISTORICAL
      : MODE.LIVE
  }),
  deckHeaderMessage: (dashboardView, isTopLevel) => dashboardView === DASHBOARD_VIEW.UNITS
    ? messages['deckHeader.inheritedPredictors']
    : messages['deckHeader.predictors']
};