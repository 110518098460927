import { defineMessages } from 'react-intl';

export default defineMessages({
  'menu.axes': {
    id: 'explorerPage.menu.axes',
    defaultMessage: 'Axes'
  },
  'menu.filters': {
    id: 'explorerPage.menu.filters',
    defaultMessage: 'Filters'
  },
  'menu.colours': {
    id: 'explorerPage.menu.colours',
    defaultMessage: 'Colours'
  },
  'statusBar.ready': {
    id: 'explorerPage.statusBar.ready',
    defaultMessage: 'Ready'
  },
  'statusBar.fetchingData': {
    id: 'explorerPage.statusBar.fetchingData',
    defaultMessage: 'Fetching Data...'
  },
  'statusBar.fetchDataError': {
    id: 'explorerPage.statusBar.fetchDataError',
    defaultMessage: 'An error occurred while fetching data.'
  }
});