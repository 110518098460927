import { defineMessages } from 'react-intl';

export default defineMessages({
  'ambulance': {
    id: 'icon.ambulance',
    defaultMessage: 'Ambulance'
  },
  'anchor': {
    id: 'icon.anchor',
    defaultMessage: 'Anchor'
  },
  'archive': {
    id: 'icon.archive',
    defaultMessage: 'Archive'
  },
  'balance scale': {
    id: 'icon.balance scale',
    defaultMessage: 'Balance Scale'
  },
  'bath': {
    id: 'icon.bath',
    defaultMessage: 'Bath'
  },
  'bed': {
    id: 'icon.bed',
    defaultMessage: 'Bed'
  },
  'beer': {
    id: 'icon.beer',
    defaultMessage: 'Beer'
  },
  'bell': {
    id: 'icon.bell',
    defaultMessage: 'Bell'
  },
  'bell outline': {
    id: 'icon.bell outline',
    defaultMessage: 'Bell Outline'
  },
  'bicycle': {
    id: 'icon.bicycle',
    defaultMessage: 'Bicycle'
  },
  'binoculars': {
    id: 'icon.binoculars',
    defaultMessage: 'Binoculars'
  },
  'birthday cake': {
    id: 'icon.birthday cake',
    defaultMessage: 'Birthday Cake'
  },
  'book': {
    id: 'icon.book',
    defaultMessage: 'Book'
  },
  'bookmark': {
    id: 'icon.bookmark',
    defaultMessage: 'Bookmark'
  },
  'bookmark outline': {
    id: 'icon.bookmark outline',
    defaultMessage: 'Bookmark Outline'
  },
  'box': {
    id: 'icon.box',
    defaultMessage: 'Box'
  },
  'boxes': {
    id: 'icon.boxes',
    defaultMessage: 'Boxes'
  },
  'briefcase': {
    id: 'icon.briefcase',
    defaultMessage: 'Briefcase'
  },
  'bug': {
    id: 'icon.bug',
    defaultMessage: 'Bug'
  },
  'building': {
    id: 'icon.building',
    defaultMessage: 'Building'
  },
  'building outline': {
    id: 'icon.building outline',
    defaultMessage: 'Building Outline'
  },
  'bullhorn': {
    id: 'icon.bullhorn',
    defaultMessage: 'Bullhorn'
  },
  'bullseye': {
    id: 'icon.bullseye',
    defaultMessage: 'Bullseye'
  },
  'bus': {
    id: 'icon.bus',
    defaultMessage: 'Bus'
  },
  'calculator': {
    id: 'icon.calculator',
    defaultMessage: 'Calculator'
  },
  'calendar': {
    id: 'icon.calendar',
    defaultMessage: 'Calendar'
  },
  'calendar outline': {
    id: 'icon.calendar outline',
    defaultMessage: 'Calendar Outline'
  },
  'calendar alternate': {
    id: 'icon.calendar alternate',
    defaultMessage: 'Calendar Alternate'
  },
  'calendar alternate outline': {
    id: 'icon.calendar alternate outline',
    defaultMessage: 'Calendar Alternate Outline'
  },
  'camera': {
    id: 'icon.camera',
    defaultMessage: 'Camera'
  },
  'camera retro': {
    id: 'icon.camera retro',
    defaultMessage: 'Camera Retro'
  },
  'car': {
    id: 'icon.car',
    defaultMessage: 'Car'
  },
  'check': {
    id: 'icon.check',
    defaultMessage: 'Check'
  },
  'clipboard': {
    id: 'icon.clipboard',
    defaultMessage: 'Clipboard'
  },
  'clipboard outline': {
    id: 'icon.clipboard outline',
    defaultMessage: 'Clipboard Outline'
  },
  'cloud': {
    id: 'icon.cloud',
    defaultMessage: 'Cloud'
  },
  'coffee': {
    id: 'icon.coffee',
    defaultMessage: 'Coffee'
  },
  'cog': {
    id: 'icon.cog',
    defaultMessage: 'Cog'
  },
  'cogs': {
    id: 'icon.cogs',
    defaultMessage: 'Cogs'
  },
  'compass': {
    id: 'icon.compass',
    defaultMessage: 'Compass'
  },
  'compass outline': {
    id: 'icon.compass outline',
    defaultMessage: 'Compass Outline'
  },
  'copy': {
    id: 'icon.copy',
    defaultMessage: 'Copy'
  },
  'copy outline': {
    id: 'icon.copy outline',
    defaultMessage: 'Copy Outline'
  },
  'x': {
    id: 'icon.x',
    defaultMessage: 'Cross'
  },
  'cube': {
    id: 'icon.cube',
    defaultMessage: 'Cube'
  },
  'cubes': {
    id: 'icon.cubes',
    defaultMessage: 'Cubes'
  },
  'cut': {
    id: 'icon.cut',
    defaultMessage: 'Cut'
  },
  'dolly': {
    id: 'icon.dolly',
    defaultMessage: 'Dolly'
  },
  'dolly flatbed': {
    id: 'icon.dolly flatbed',
    defaultMessage: 'Dolly Flatbed'
  },
  'envelope': {
    id: 'icon.envelope',
    defaultMessage: 'Envelope'
  },
  'envelope outline': {
    id: 'icon.envelope outline',
    defaultMessage: 'Envelope Outline'
  },
  'envelope open': {
    id: 'icon.envelope open',
    defaultMessage: 'Envelope Open'
  },
  'envelope open outline': {
    id: 'icon.envelope open outline',
    defaultMessage: 'Envelope Open Outline'
  },
  'eraser': {
    id: 'icon.eraser',
    defaultMessage: 'Eraser'
  },
  'eye': {
    id: 'icon.eye',
    defaultMessage: 'Eye'
  },
  'eye dropper': {
    id: 'icon.eye dropper',
    defaultMessage: 'Eye Dropper'
  },
  'fax': {
    id: 'icon.fax',
    defaultMessage: 'Fax'
  },
  'fighter jet': {
    id: 'icon.fighter jet',
    defaultMessage: 'Fighter Jet'
  },
  'file': {
    id: 'icon.file',
    defaultMessage: 'File'
  },
  'file outline': {
    id: 'icon.file outline',
    defaultMessage: 'File Outline'
  },
  'file alternate': {
    id: 'icon.file alternate',
    defaultMessage: 'File Alternate'
  },
  'file alternate outline': {
    id: 'icon.file alternate outline',
    defaultMessage: 'File Alternate Outline'
  },
  'film': {
    id: 'icon.film',
    defaultMessage: 'Film'
  },
  'fire': {
    id: 'icon.fire',
    defaultMessage: 'Fire'
  },
  'fire extinguisher': {
    id: 'icon.fire extinguisher',
    defaultMessage: 'Fire Extinguisher'
  },
  'flag': {
    id: 'icon.flag',
    defaultMessage: 'Flag'
  },
  'flag outline': {
    id: 'icon.flag outline',
    defaultMessage: 'Flag Outline'
  },
  'flag checkered': {
    id: 'icon.flag checkered',
    defaultMessage: 'Flag Checkered'
  },
  'flask': {
    id: 'icon.flask',
    defaultMessage: 'Flask'
  },
  'futbol': {
    id: 'icon.futbol',
    defaultMessage: 'Futbol'
  },
  'futbol outline': {
    id: 'icon.futbol outline',
    defaultMessage: 'Futbol Outline'
  },
  'gamepad': {
    id: 'icon.gamepad',
    defaultMessage: 'Gamepad'
  },
  'gavel': {
    id: 'icon.gavel',
    defaultMessage: 'Gavel'
  },
  'gem': {
    id: 'icon.gem',
    defaultMessage: 'Gem'
  },
  'gem outline': {
    id: 'icon.gem outline',
    defaultMessage: 'Gem Outline'
  },
  'gift': {
    id: 'icon.gift',
    defaultMessage: 'Gift'
  },
  'glass martini': {
    id: 'icon.glass martini',
    defaultMessage: 'Glass Martini'
  },
  'globe': {
    id: 'icon.globe',
    defaultMessage: 'Globe'
  },
  'graduation cap': {
    id: 'icon.graduation cap',
    defaultMessage: 'Graduation Cap'
  },
  'hdd': {
    id: 'icon.hdd',
    defaultMessage: 'Hdd'
  },
  'hdd outline': {
    id: 'icon.hdd outline',
    defaultMessage: 'Hdd Outline'
  },
  'headphones': {
    id: 'icon.headphones',
    defaultMessage: 'Headphones'
  },
  'heart': {
    id: 'icon.heart',
    defaultMessage: 'Heart'
  },
  'heart outline': {
    id: 'icon.heart outline',
    defaultMessage: 'Heart Outline'
  },
  'home': {
    id: 'icon.home',
    defaultMessage: 'Home'
  },
  'hospital': {
    id: 'icon.hospital',
    defaultMessage: 'Hospital'
  },
  'hospital outline': {
    id: 'icon.hospital outline',
    defaultMessage: 'Hospital Outline'
  },
  'hourglass': {
    id: 'icon.hourglass',
    defaultMessage: 'Hourglass'
  },
  'hourglass outline': {
    id: 'icon.hourglass outline',
    defaultMessage: 'Hourglass Outline'
  },
  'image': {
    id: 'icon.image',
    defaultMessage: 'Image'
  },
  'image outline': {
    id: 'icon.image outline',
    defaultMessage: 'Image Outline'
  },
  'images': {
    id: 'icon.images',
    defaultMessage: 'Images'
  },
  'images outline': {
    id: 'icon.images outline',
    defaultMessage: 'Images Outline'
  },
  'industry': {
    id: 'icon.industry',
    defaultMessage: 'Industry'
  },
  'key': {
    id: 'icon.key',
    defaultMessage: 'Key'
  },
  'keyboard': {
    id: 'icon.keyboard',
    defaultMessage: 'Keyboard'
  },
  'keyboard outline': {
    id: 'icon.keyboard outline',
    defaultMessage: 'Keyboard Outline'
  },
  'laptop': {
    id: 'icon.laptop',
    defaultMessage: 'Laptop'
  },
  'leaf': {
    id: 'icon.leaf',
    defaultMessage: 'Leaf'
  },
  'lemon': {
    id: 'icon.lemon',
    defaultMessage: 'Lemon'
  },
  'lemon outline': {
    id: 'icon.lemon outline',
    defaultMessage: 'Lemon Outline'
  },
  'life ring': {
    id: 'icon.life ring',
    defaultMessage: 'Life Ring'
  },
  'life ring outline': {
    id: 'icon.life ring outline',
    defaultMessage: 'Life Ring Outline'
  },
  'lightbulb': {
    id: 'icon.lightbulb',
    defaultMessage: 'Lightbulb'
  },
  'lightbulb outline': {
    id: 'icon.lightbulb outline',
    defaultMessage: 'Lightbulb Outline'
  },
  'lock': {
    id: 'icon.lock',
    defaultMessage: 'Lock'
  },
  'lock open': {
    id: 'icon.lock open',
    defaultMessage: 'Lock Open'
  },
  'magic': {
    id: 'icon.magic',
    defaultMessage: 'Magic'
  },
  'magnet': {
    id: 'icon.magnet',
    defaultMessage: 'Magnet'
  },
  'map': {
    id: 'icon.map',
    defaultMessage: 'Map'
  },
  'map outline': {
    id: 'icon.map outline',
    defaultMessage: 'Map Outline'
  },
  'map marker': {
    id: 'icon.map marker',
    defaultMessage: 'Map Marker'
  },
  'map marker alternate': {
    id: 'icon.map marker alternate',
    defaultMessage: 'Map Marker Alternate'
  },
  'map pin': {
    id: 'icon.map pin',
    defaultMessage: 'Map Pin'
  },
  'map signs': {
    id: 'icon.map signs',
    defaultMessage: 'Map Signs'
  },
  'medkit': {
    id: 'icon.medkit',
    defaultMessage: 'Medkit'
  },
  'microchip': {
    id: 'icon.microchip',
    defaultMessage: 'Microchip'
  },
  'microphone': {
    id: 'icon.microphone',
    defaultMessage: 'Microphone'
  },
  'mobile': {
    id: 'icon.mobile',
    defaultMessage: 'Mobile'
  },
  'mobile alternate': {
    id: 'icon.mobile alternate',
    defaultMessage: 'Mobile Alternate'
  },
  'money bill alternate': {
    id: 'icon.money bill alternate',
    defaultMessage: 'Money Bill Alternate'
  },
  'money bill alternate outline': {
    id: 'icon.money bill alternate outline',
    defaultMessage: 'Money Bill Alternate Outline'
  },
  'moon': {
    id: 'icon.moon',
    defaultMessage: 'Moon'
  },
  'moon outline': {
    id: 'icon.moon outline',
    defaultMessage: 'Moon Outline'
  },
  'motorcycle': {
    id: 'icon.motorcycle',
    defaultMessage: 'Motorcycle'
  },
  'newspaper': {
    id: 'icon.newspaper',
    defaultMessage: 'Newspaper'
  },
  'newspaper outline': {
    id: 'icon.newspaper outline',
    defaultMessage: 'Newspaper Outline'
  },
  'paint brush': {
    id: 'icon.paint brush',
    defaultMessage: 'Paint Brush'
  },
  'pallet': {
    id: 'icon.pallet',
    defaultMessage: 'Pallet'
  },
  'paper plane': {
    id: 'icon.paper plane',
    defaultMessage: 'Paper Plane'
  },
  'paper plane outline': {
    id: 'icon.paper plane outline',
    defaultMessage: 'Paper Plane Outline'
  },
  'paperclip': {
    id: 'icon.paperclip',
    defaultMessage: 'Paperclip'
  },
  'paste': {
    id: 'icon.paste',
    defaultMessage: 'Paste'
  },
  'paw': {
    id: 'icon.paw',
    defaultMessage: 'Paw'
  },
  'pencil alternate': {
    id: 'icon.pencil alternate',
    defaultMessage: 'Pencil Alternate'
  },
  'phone': {
    id: 'icon.phone',
    defaultMessage: 'Phone'
  },
  'plane': {
    id: 'icon.plane',
    defaultMessage: 'Plane'
  },
  'plug': {
    id: 'icon.plug',
    defaultMessage: 'Plug'
  },
  'print': {
    id: 'icon.print',
    defaultMessage: 'Print'
  },
  'puzzle piece': {
    id: 'icon.puzzle piece',
    defaultMessage: 'Puzzle Piece'
  },
  'road': {
    id: 'icon.road',
    defaultMessage: 'Road'
  },
  'rocket': {
    id: 'icon.rocket',
    defaultMessage: 'Rocket'
  },
  'save': {
    id: 'icon.save',
    defaultMessage: 'Save'
  },
  'save outline': {
    id: 'icon.save outline',
    defaultMessage: 'Save Outline'
  },
  'search': {
    id: 'icon.search',
    defaultMessage: 'Search'
  },
  'shield alternate': {
    id: 'icon.shield alternate',
    defaultMessage: 'Shield Alternate'
  },
  'shopping bag': {
    id: 'icon.shopping bag',
    defaultMessage: 'Shopping Bag'
  },
  'shopping basket': {
    id: 'icon.shopping basket',
    defaultMessage: 'Shopping Basket'
  },
  'shopping cart': {
    id: 'icon.shopping cart',
    defaultMessage: 'Shopping Cart'
  },
  'shower': {
    id: 'icon.shower',
    defaultMessage: 'Shower'
  },
  'snowflake': {
    id: 'icon.snowflake',
    defaultMessage: 'Snowflake'
  },
  'snowflake outline': {
    id: 'icon.snowflake outline',
    defaultMessage: 'Snowflake Outline'
  },
  'space shuttle': {
    id: 'icon.space shuttle',
    defaultMessage: 'Space Shuttle'
  },
  'star': {
    id: 'icon.star',
    defaultMessage: 'Star'
  },
  'star outline': {
    id: 'icon.star outline',
    defaultMessage: 'Star Outline'
  },
  'sticky note': {
    id: 'icon.sticky note',
    defaultMessage: 'Sticky Note'
  },
  'sticky note outline': {
    id: 'icon.sticky note outline',
    defaultMessage: 'Sticky Note Outline'
  },
  'stopwatch': {
    id: 'icon.stopwatch',
    defaultMessage: 'Stopwatch'
  },
  'subway': {
    id: 'icon.subway',
    defaultMessage: 'Subway'
  },
  'suitcase': {
    id: 'icon.suitcase',
    defaultMessage: 'Suitcase'
  },
  'sun': {
    id: 'icon.sun',
    defaultMessage: 'Sun'
  },
  'sun outline': {
    id: 'icon.sun outline',
    defaultMessage: 'Sun Outline'
  },
  'tablet': {
    id: 'icon.tablet',
    defaultMessage: 'Tablet'
  },
  'tablet alternate': {
    id: 'icon.tablet alternate',
    defaultMessage: 'Tablet Alternate'
  },
  'tachometer alternate': {
    id: 'icon.tachometer alternate',
    defaultMessage: 'Tachometer Alternate'
  },
  'tag': {
    id: 'icon.tag',
    defaultMessage: 'Tag'
  },
  'tags': {
    id: 'icon.tags',
    defaultMessage: 'Tags'
  },
  'taxi': {
    id: 'icon.taxi',
    defaultMessage: 'Taxi'
  },
  'thumbtack': {
    id: 'icon.thumbtack',
    defaultMessage: 'Thumbtack'
  },
  'ticket alternate': {
    id: 'icon.ticket alternate',
    defaultMessage: 'Ticket Alternate'
  },
  'train': {
    id: 'icon.train',
    defaultMessage: 'Train'
  },
  'trash': {
    id: 'icon.trash',
    defaultMessage: 'Trash'
  },
  'trash alternate': {
    id: 'icon.trash alternate',
    defaultMessage: 'Trash Alternate'
  },
  'trash alternate outline': {
    id: 'icon.trash alternate outline',
    defaultMessage: 'Trash Alternate Outline'
  },
  'tree': {
    id: 'icon.tree',
    defaultMessage: 'Tree'
  },
  'trophy': {
    id: 'icon.trophy',
    defaultMessage: 'Trophy'
  },
  'truck': {
    id: 'icon.truck',
    defaultMessage: 'Truck'
  },
  'tv': {
    id: 'icon.tv',
    defaultMessage: 'Tv'
  },
  'umbrella': {
    id: 'icon.umbrella',
    defaultMessage: 'Umbrella'
  },
  'university': {
    id: 'icon.university',
    defaultMessage: 'University'
  },
  'unlock': {
    id: 'icon.unlock',
    defaultMessage: 'Unlock'
  },
  'unlock alternate': {
    id: 'icon.unlock alternate',
    defaultMessage: 'Unlock Alternate'
  },
  'utensil spoon': {
    id: 'icon.utensil spoon',
    defaultMessage: 'Utensil Spoon'
  },
  'utensils': {
    id: 'icon.utensils',
    defaultMessage: 'Utensils'
  },
  'warehouse': {
    id: 'icon.warehouse',
    defaultMessage: 'Warehouse'
  },
  'wheelchair': {
    id: 'icon.wheelchair',
    defaultMessage: 'Wheelchair'
  },
  'wrench': {
    id: 'icon.wrench',
    defaultMessage: 'Wrench'
  }
});