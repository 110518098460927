import { defineMessages } from 'react-intl';

export default defineMessages({
  'mute': {
    id: 'dashboardCard.mute',
    defaultMessage: 'Mute'
  },
  'unmute': {
    id: 'dashboardCard.unmute',
    defaultMessage: 'Unmute'
  },
  'configuration': {
    id: 'dashboardCard.configuration',
    defaultMessage: 'Configure Alerts And Actions'
  },
  'delete': {
    id: 'dashboardCard.delete',
    defaultMessage: 'Delete'
  }
});