import { Segment } from 'semantic-ui-react';
import { ENTITY_PROPERTY, EMPTY_STRING, INVERTED_THEME } from '../../../utils/constants';
import styles from './TrendTimeline.styles';
import _ from 'lodash';
import { Trend, TrendType } from 'types';
import { CSSProperties } from 'react';

const MAX_LABEL_HEIGHT = 30;
const MIN_LABEL_WIDTH = 10;

interface Props {
  trend: Trend;
  trendType?: TrendType;
  /** Position of the start of the label as a percentage of the total timeline width */
  start: number;
  /** Position of the end of the label as a percentage of the total timeline width */
  end: number;
  /** Number of parallel labels to allow space for */
  overlap?: number;
  /** Vertical position of this label */
  overlapIndex?: number;
  highlighted: boolean;
  isChild: boolean;
  timelineHeight: number;
  timelineWidth: number;
  onClick: () => void;
}
export default function TrendLabel({
  trend,
  trendType,
  start,
  end,
  overlap = 1,
  overlapIndex = 0,
  highlighted,
  isChild,
  timelineHeight,
  timelineWidth,
  onClick,
  ...props
}: Props) {
  const minLabelWidthPercentage = 100 * (MIN_LABEL_WIDTH / timelineWidth);

  const scaleFactor = Math.max(2, overlap);

  const height = Math.min(timelineHeight / scaleFactor, MAX_LABEL_HEIGHT);
  const fontSize = 2 * height / 3;

  const trueWidthPercentage = Math.min(100, end) - Math.max(0, start);

  const isInflated = minLabelWidthPercentage > trueWidthPercentage;
  const displayWidthPercentage = Math.max(minLabelWidthPercentage, trueWidthPercentage);

  // If inflated, centre the inflated label on the middle of the true range
  const inflationPadding = isInflated
    ? (minLabelWidthPercentage - trueWidthPercentage) / 2
    : 0;

  const left = Math.max(0, start - inflationPadding);

  return (
    <Segment
      {...props}
      inverted={INVERTED_THEME}
      color={highlighted
        ? 'purple'
        : _.get(trendType, ENTITY_PROPERTY.TREND_TYPE.COLOUR)}
      circular={start === end}
      onClick={e => {
        onClick();
        e.stopPropagation();
      }}
      onMouseMove={e => e.stopPropagation()}
      style={{
        left: `${left}%`,
        width: `${displayWidthPercentage}%`,
        top: overlap > 1 ? `${100 * overlapIndex / overlap}%` : undefined,
        height: `${height}px`,
        maxHeight: `${height}px`,
        minHeight: '0px',
        opacity: isChild ? 0.6 : 1,
        borderRadius: isInflated ? MIN_LABEL_WIDTH / 2 : 0,
        padding: 0,
        ...(styles.trendLabel as CSSProperties)
      }}>
      <div style={{
        position: 'relative',
        left: '10px',
        fontSize: `${fontSize}px`,
        lineHeight: `${height}px`
      }}>
        {start === end
          ? EMPTY_STRING
          : ` ${trend.name}`}
      </div>

    </Segment>
  );
}