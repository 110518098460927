import _ from 'lodash';
import { ofType } from 'redux-observable';
import { filter, map, withLatestFrom, expand } from 'rxjs/operators';
import { setFormValues, TOGGLE_MODAL } from '../../../actions/entity';
import * as fromState from '../../../reducers';
import { ENTITY_FORM_TYPE, ENTITY_CONFIG } from '../../../../utils/constants';
import { of, EMPTY } from 'rxjs';
import entityConfig from '../../../../utils/entities';

export default (action$, state$) => action$.pipe(
  ofType(TOGGLE_MODAL),
  withLatestFrom(state$),
  filter(([action, state]) => action.payload.modal === 'add' && fromState.getModalOpen(state, action.entityType, 'add')),
  map(([action, state]) => [action.entityType, state]),
  expand(([entityType, state]) => {
    const typeEntityType = entityConfig[entityType][ENTITY_CONFIG.TYPE_ENTITY_TYPE];
    return typeEntityType && typeEntityType !== entityType
      ? of([typeEntityType, state])
      : EMPTY;
  }),
  map(([entityType, state]) => {
    const typeId = fromState.getFormValue(state, entityType, ENTITY_FORM_TYPE.ADD_OR_EDIT, 'type') || null;
    const type = fromState.getTypeById(state, entityType, typeId);
    return setFormValues(entityType, ENTITY_FORM_TYPE.ADD_OR_EDIT, {
      ...entityConfig[entityType][ENTITY_CONFIG.DEFAULT_FORM_VALUES] || {},
      type: typeId,
      settings: type ? _.mapValues(type.options, optionSettings => optionSettings.defaultValue || null) : {},
      conditions: {
        filter: 'All',
        list: []
      }
    });
  })
);