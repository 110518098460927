import { ofType } from 'redux-observable';
import { LOCATION_CHANGE } from 'connected-react-router';
import { filter, withLatestFrom, map, distinctUntilChanged } from 'rxjs/operators';
import * as fromState from '../../reducers';
import { setAuthMessage } from '../../actions/auth';

export default (action$, state$) => action$.pipe(
  ofType(LOCATION_CHANGE),
  distinctUntilChanged((prev, curr) => prev.payload.location.pathname === curr.payload.location.pathname),
  withLatestFrom(state$),
  filter(([action, state]) => fromState.getAuthMessage(state)),
  map(([action]) => setAuthMessage(null))
);