import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createSelector } from 'reselect';
import * as fromState from 'state/reducers';
import { Trend, TrendType } from 'types';
import { ENTITY_TYPE } from 'utils/constants';
import { toggleTrendSelected } from 'state/actions/chart';
import TrendLabel from 'components/chart/TrendTimeline/TrendLabel';

interface Props {
  trend: Trend;
}
const mapStateToProps = createSelector(
  [
    (state, { trend }: Props) => fromState.getById<TrendType>(state, ENTITY_TYPE.TREND_TYPE, trend.type)
  ],
  (trendType) => ({
    trendType
  })
);

const mapDispatchToProps = (dispatch, { trend }: Props) => bindActionCreators({
  onClick: () => toggleTrendSelected(trend.id)
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TrendLabel);