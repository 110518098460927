import { ofType } from 'redux-observable';
import { of, merge } from 'rxjs';
import { mergeMap, withLatestFrom, catchError, mapTo, delay, filter } from 'rxjs/operators';
import { IMPORT_FILE, toggleModal, setIsImporting, fetchLabels, setImportError, performSearch } from '../../actions/entity';
import { ENTITY_MODAL, ENTITY_CONFIG, ENTITY_TYPE } from '../../../utils/constants';
import * as api from '../../../utils/api';
import * as fromState from '../../reducers';
import _ from 'lodash';
import entityConfig from '../../../utils/entities';
import { takeUntilAppReset } from 'utils/helpers';

const getApiCall = (action, state) => {
  if (action.entityType === ENTITY_TYPE.UNIT_TYPE_INSTANCE && action.payload.context.unitTypeId) {
    return api.importUnitTypeInstancesWithContext({
      file: action.payload.file,
      unitTypeId: action.payload.context.unitTypeId,
      token: fromState.getUser(state).token
    });
  } else {
    return api.importFile({
      controller: entityConfig[action.entityType][ENTITY_CONFIG.API_PATH],
      apiPrefix: entityConfig[action.entityType][ENTITY_CONFIG.API_PREFIX],
      parentId: _.last(state.router.location.pathname.split('/')),
      file: action.payload.file,
      context: action.payload.context,
      token: fromState.getUser(state).token
    });
  }
};

export default (action$, state$) => action$.pipe(
  ofType(IMPORT_FILE),
  withLatestFrom(state$),
  mergeMap(([action, state]) => merge(
    of(setIsImporting(action.entityType, true)),
    getApiCall(action, state).pipe(
      takeUntilAppReset(action$),
      mergeMap(() => merge(
        of(null).pipe(
          filter(() => entityConfig[action.entityType][ENTITY_CONFIG.LABELS]),
          delay(2000),
          mapTo(fetchLabels(action.entityType))
        ),
        of(
          toggleModal(action.entityType, ENTITY_MODAL.IMPORT),
          setIsImporting(action.entityType, false)
        ),
        of(null).pipe(
          delay(2000),
          mapTo(performSearch(action.entityType, action.payload.parentId))
        )
      )),
      catchError((err, caught) => {
        if (err.status === 400) {
          if (err.response) {
            const errorCodes = _.chain(err.response.errors)
              .flatMap()
              .map('code')
              .value();
            return of(
              setImportError(action.entityType, errorCodes, err.response.row, err.response.total),
              setIsImporting(action.entityType, false)
            );
          };

          return of(
            setImportError(action.entityType, ['api.errorCode.import.invalidCsv'], null, 1),
            setIsImporting(action.entityType, false)
          );
        }

        return api.onError(err, caught);
      })
    )
  ))
);