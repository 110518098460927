import _ from 'lodash';
import createCachedSelector from 're-reselect';
import * as fromState from '../../../reducers';
import { getInputs } from '../getInputs';
import { getSensors } from '../getSensors';

const getAllSensors = state => fromState.getAll(state, 'sensor');

export const getInputAlarmRanges = createCachedSelector(
  [getInputs, getSensors, getAllSensors],
  (inputs, sensors, allSensors) => _.chain(inputs)
    .zip(sensors)
    .map(([input, sensor]) => [
      input,
      _.get(allSensors[sensor], 'alarmRanges')
    ])
    .fromPairs()
    .value()
)(
  (state, chartType) => chartType
);