import { connect } from 'react-redux';
import DashboardCard from '../../components/dashboard/DashboardCard';
import * as dashboardSelectors from '../../state/selectors/dashboard';
import { getColourFromSeverity, toPath } from '../../utils/helpers';
import * as fromState from '../../state/reducers';
import { UNIT_PATH_SEPARATOR, PAGE_URL, ENTITY_TYPE, ENTITY_PROPERTY, ENTITY_MODAL } from '../../utils/constants';
import _ from 'lodash';
import createCachedSelector from 're-reselect';
import unitTypeEntity from '../../utils/entities/unitType';
import { bindActionCreators } from 'redux';
import { toggleModal, deleteById } from '../../state/actions/entity';

const mapStateToProps = createCachedSelector(
  [
    fromState.getUnitPath,
    (state, { unit }) => dashboardSelectors.getUnitSeverity(state, { id: unit.id }),
    (state, { unit }) => unit,
    state => fromState.getAll(state, ENTITY_TYPE.UNIT_CATEGORY),
    state => fromState.getAll(state, ENTITY_TYPE.UNIT_TYPE_INSTANCE),
    state => fromState.getAll(state, ENTITY_TYPE.UNIT_TYPE),
    state => fromState.getShowCardDetails(state)
  ],
  (
    unitPath,
    severity,
    unit,
    allUnitCategories,
    allUnitTypeInstances,
    allUnitTypes,
    showDetails
  ) => {
    const unitCategoryLabel = (categoryId) => ({
      type: ENTITY_TYPE.UNIT_CATEGORY,
      id: categoryId,
      icon: _.get(allUnitCategories[categoryId], ENTITY_PROPERTY.UNIT_CATEGORY.ICON),
      color: 'violet',
      messageId: `unit.type.${categoryId}.name`,
      messageDefaultValue: _.get(allUnitCategories[categoryId], ENTITY_PROPERTY.UNIT_CATEGORY.NAME)
    });

    const unitIsNotAnInstanceOfAnyChildType = unitTypeInstance => !_.some(allUnitTypeInstances, instance =>
      _.has(allUnitTypes, instance.type) &&
      allUnitTypes[instance.type].baseType === unitTypeInstance.type &&
      instance.unitId === unit.id);

    const unitTypeInstanceLabels = () => _
      .chain(allUnitTypeInstances)
      .filter(uti => uti.unitId === unit.id && uti.type in allUnitTypes)
      .filter(unitIsNotAnInstanceOfAnyChildType)
      .map(uti => ({
        type: ENTITY_TYPE.UNIT_TYPE,
        id: uti.type,
        icon: unitTypeEntity.icon,
        color: 'violet',
        content: allUnitTypes[uti.type].name
      }))
      .value();

    return {
      id: unit.id,
      linkTo: toPath(PAGE_URL.UNIT, {
        unitPath: unitPath
          ? `${unitPath}${UNIT_PATH_SEPARATOR}${unit.name}`
          : unit.name
      }),
      name: unit.name,
      description: unit && unit.description,
      labels: [
        unitCategoryLabel(unit.type),
        ...unitTypeInstanceLabels()
      ],
      fill: getColourFromSeverity(severity),
      severity,
      active: true,
      noModel: true,
      canMute: false,
      canDelete: true,
      canConfigure: false,
      entityType: ENTITY_TYPE.UNIT,
      showDetails
    };
  }
)(
  (state, { unit }) => unit.id
);

const mapDispatchToProps = (dispatch, { unit }) => bindActionCreators({
  onClickDelete: () => toggleModal(ENTITY_TYPE.UNIT, ENTITY_MODAL.DELETE, unit.id),
  onDelete: () => deleteById(ENTITY_TYPE.UNIT, unit.id)
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DashboardCard);