import { FETCH_TYPES_FULFILLED } from '../../actions/entity';
import { Type } from 'types';
import _ from 'lodash';

type TypesByIdState = { [id: string]: Type; };

export default (state: TypesByIdState = {}, action) => {
  switch (action.type) {
    case FETCH_TYPES_FULFILLED:
      return _.reduce(action.payload.types, (memo, type) => ({
        ...memo,
        [type.id]: type
      }), {});
    default:
      return state;
  }
};

export function getAllTypes(state: TypesByIdState) {
  return _.values(state);
}

export function getTypesById(state: TypesByIdState) {
  return state;
}

export function getTypeById(state: TypesByIdState, id: string): Type | undefined {
  return state[id];
}