import { Fragment } from 'react';
import { injectIntl } from 'react-intl';
import { Header, Popup } from 'semantic-ui-react';
import { INVERTED_THEME, HEAT_MAP_POPUP_GAUGE_MIN_NEEDLE_WIDTH, VALUE_TYPE } from '../../../utils/constants';
import { BarGauge, MinMaxStatistic } from '../GaugeCard';
import messages from './HeatMapChart.messages';
import styles from './HeatMapChart.styles';
import PropTypes from 'prop-types';
import { formatValueType } from '../../../utils/helpers';
import AlarmLevelsDiagram from '../../alarm/AlarmLevelsDiagram';

const HeatMapPopup = injectIntl(({
  trigger,
  header,
  subheader,
  min,
  max,
  alarmRanges,
  alarmLevels,
  noData,
  valueCount,
  singleValue,
  activeStates,
  valueType,
  scale,
  unitsOfMeasurement,
  intl: {
    formatMessage
  }
}) => (
  <Popup
    inverted={INVERTED_THEME}
    style={styles.popup}
    trigger={trigger}
    on='hover'>
    <Header
      inverted={INVERTED_THEME}
      textAlign='center'>
      {header}
      <Header.Subheader>
        {subheader}
      </Header.Subheader>
    </Header>
    {noData
      ? (
        <Header
          inverted={INVERTED_THEME}
          textAlign='center'>
          {formatMessage(messages['noData'])}
        </Header>
      )
      : (
        valueType === VALUE_TYPE.FLOAT
          ? (
            <Fragment>
              <div style={styles.gaugeWrapper}>
                <BarGauge
                  min={min}
                  max={max}
                  ranges={alarmRanges}
                  minNeedleWidth={HEAT_MAP_POPUP_GAUGE_MIN_NEEDLE_WIDTH} />
              </div>
              <MinMaxStatistic
                min={min}
                max={max}
                scale={scale}
                unitsOfMeasurement={unitsOfMeasurement} />
            </Fragment>
          )
          : valueType === VALUE_TYPE.ENUMERATION
            ? (
              <Fragment>
                <div style={styles.alarmLevelsDiagramWrapper}>
                  <AlarmLevelsDiagram
                    alarmLevels={alarmLevels}
                    activeStates={activeStates} />
                </div>
                <Header
                  inverted={INVERTED_THEME}
                  textAlign='center'>
                  {valueCount === 1
                    ? formatValueType(singleValue, valueType, unitsOfMeasurement)
                    : formatMessage(messages['nValues'], { valueCount })}
                </Header>
              </Fragment>
            )
            : (
              <Header
                inverted={INVERTED_THEME}
                textAlign='center'>
                {valueCount === 1
                  ? formatValueType(singleValue, valueType, unitsOfMeasurement)
                  : formatMessage(messages['nValues'], { valueCount })}
              </Header>
            )
      )}
  </Popup>
));

HeatMapPopup.propTypes = {
  trigger: PropTypes.element.isRequired,
  header: PropTypes.string.isRequired,
  subheader: PropTypes.string.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
  alarmRanges: PropTypes.arrayOf(PropTypes.exact({
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    severity: PropTypes.number.isRequired
  })),
  alarmLevels: PropTypes.arrayOf(PropTypes.number),
  scale: PropTypes.number.isRequired,
  unitsOfMeasurement: PropTypes.string,
  activeStates: PropTypes.arrayOf(PropTypes.number)
};

HeatMapPopup.displayName = 'HeatMapPopup';

export default HeatMapPopup;