import _ from 'lodash';
import moment from 'moment';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getUser } from 'state/reducers';
import { injectIntl } from 'react-intl';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { Button, Form } from 'semantic-ui-react';
import {
  DATE_FORMAT_NO_TIME, INVERTED_THEME, NO_ATTRIBUTE_ID,
  VALUE_TYPE, CONTROLLER
} from 'utils/constants';
import messages from './FiltersMenu.messages';
import * as api from 'utils/api';

export default injectIntl(function FiltersMenu({
  filterState,
  onSetFilterState,
  sensors,
  intl: {
    formatMessage,
    locale
  }
}) {
  const token = useSelector(getUser).token;

  const [draftFilterState, setDraftFilterState] = useState({
    startDate: filterState.startDate,
    endDate: filterState.endDate,
    filterAttr: filterState.filterAttr,
    filterValue: filterState.filterValue
  });
  const [filterValueOptions, setFilterValueOptions] = useState([]);
  const [subscription, setSubscription] = useState();

  const PropertyLabel = ({ propName, messageId }) => (
    <label style={{
      fontWeight: draftFilterState[propName] == filterState[propName] ? 'normal' : 'bold'
    }}>
      {formatMessage(messages[messageId])}
    </label>
  );

  const ChangesMade = () => {
    if (draftFilterState.startDate != filterState.startDate) return true;
    if (draftFilterState.endDate != filterState.endDate) return true;
    if (draftFilterState.filterAttr != filterState.filterAttr) return true;
    if (draftFilterState.filterValue != filterState.filterValue) return true;

    return false;
  };

  useEffect(() => {
    if (draftFilterState.filterAttr === NO_ATTRIBUTE_ID || sensors.length == 0) {
      if (subscription != null) {
        subscription.unsubscribe();
      }
      setFilterValueOptions([]);
      return;
    }

    let enumId = _.find(sensors, s => s.id === draftFilterState.filterAttr).enumeration;
    let response = api.get({
      controller: CONTROLLER.ENUMERATION,
      id: enumId,
      token: token
    });

    if (subscription != null) {
      subscription.unsubscribe();
    }

    let sub = response.subscribe({
      next(x) {
        setFilterValueOptions(_.map(x.states, (s, i) => ({
          key: i,
          text: s,
          value: i
        })));
        setDraftFilterState({
          ...draftFilterState,
          filterValue: 0
        });
      },
      error(err) {
        console.log(err);
      },
      complete() {
        if (subscription != null) {
          subscription.unsubscribe();
        }
      }
    });

    setSubscription(sub);
  }, [draftFilterState.filterAttr, sensors]);

  const DatePicker = ({ date, setDate }) => (
    <SemanticDatepicker
      inverted={INVERTED_THEME}
      inline
      fluid
      showToday={false}
      clearOnSameDateClick={false}
      maxDate={moment().local().toDate()}
      localization={locale}
      format={DATE_FORMAT_NO_TIME}
      date={date.clone().local().toDate()}
      value={date.clone().local().toDate()}
      onChange={(event, { value }) => {
        const newTime = moment(value, DATE_FORMAT_NO_TIME);
        setDate(newTime);
      }} />
  );

  const noFilterOption = {
    id: NO_ATTRIBUTE_ID,
    value: NO_ATTRIBUTE_ID,
    text: formatMessage(messages['noFilter'])
  };

  function sensorCanBeUsedAsFilter(s) {
    return s.valueType === VALUE_TYPE.ENUMERATION && s.enumeration === 'BOOLEAN';
  }

  return (
    <div>
      <Form inverted={INVERTED_THEME}>
        <PropertyLabel
          propName='startDate'
          messageId='startDate' />
        <DatePicker
          date={draftFilterState.startDate}
          setDate={d => setDraftFilterState({
            ...draftFilterState,
            startDate: d
          })} />
        <PropertyLabel
          propName='endDate'
          messageId='endDate' />
        <DatePicker
          date={draftFilterState.endDate}
          setDate={d => setDraftFilterState({
            ...draftFilterState,
            endDate: d
          })} />
        <PropertyLabel
          propName='filterAttr'
          messageId='chooseFilterAttribute' />
        <Form.Select
          options={_.concat([noFilterOption], _.map(
            _.filter(sensors, sensorCanBeUsedAsFilter),
            s => ({
              key: s.id,
              text: s.name,
              value: s.id
            })
          ))}
          value={draftFilterState.filterAttr}
          onChange={(_, data) => {
            setDraftFilterState({
              ...draftFilterState,
              filterAttr: data.value
            });
          }}>
        </Form.Select>
        {draftFilterState.filterAttr !== NO_ATTRIBUTE_ID && (
          <>
            <PropertyLabel
              propName='filterValue'
              messageId='chooseFilterValue' />
            <Form.Select
              options={filterValueOptions}
              value={draftFilterState.filterValue}
              onChange={(_, data) => {
                setDraftFilterState({
                  ...draftFilterState,
                  filterValue: data.value
                });
              }} />
          </>
        )}
      </Form>
      <Button
        onClick={() => {
          onSetFilterState(draftFilterState);
        }}
        style={{
          margin: '10px'
        }}>
        {formatMessage(messages['apply'])}
      </Button>
      {ChangesMade() && (
        <Button
          onClick={() => {
            setDraftFilterState(filterState);
          }}
          style={{
            margin: '10px'
          }}>
          {formatMessage(messages['cancel'])}
        </Button>
      )}
    </div>
  );
});
