import _ from 'lodash';
import moment from 'moment';
import queryString from 'query-string';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { mergeMap, withLatestFrom } from 'rxjs/operators';
import { setQuickRange, setToDate } from 'state/actions/chart';
import { querySyncPush } from 'state/actions/navigation';
import { CHANGE_REVIEW_TREND } from 'state/actions/playback';
import * as fromState from 'state/reducers';
import { getUnitId } from 'state/selectors/dashboard';
import { getUnitPaths } from 'state/selectors/entity';
import { CHART_TYPE, CHART_VIEW, DATE_FORMAT, ENTITY_TYPE, PAGE_URL, PLAYBACK_MODE } from 'utils/constants';
import { getTrendQuickRange, getTrendToDate, toPath } from 'utils/helpers';

export default (action$, state$) => action$.pipe(
  ofType(CHANGE_REVIEW_TREND),
  withLatestFrom(state$),
  mergeMap(([action, state]) => {
    const unitPaths = getUnitPaths(state);
    const currentPredictorId = fromState.getGroup(state, CHART_TYPE.MAIN);
    const trend = fromState.getById(state, ENTITY_TYPE.TREND, action.payload.id);
    const quickRange = getTrendQuickRange(trend);
    const unitId = getUnitId(state);
    const allPredictors = fromState.getAll(state, ENTITY_TYPE.PREDICTOR);
    const currentPredictor = allPredictors[currentPredictorId];
    const matchingUnitTypePredictor = currentPredictor.unitTypePredictor
      ? _.chain(allPredictors)
        .values()
        .filter(predictor => predictor.unitId === trend.unitId)
        .find(
          predictor => predictor.unitTypePredictor === currentPredictor.unitTypePredictor
        )
        .value()
      : null;
    const [unitPath, predictorId] = unitId === trend.unitId
      ? [unitPaths[trend.unitId], currentPredictorId]
      : [
        unitPaths[trend.unitId],
        matchingUnitTypePredictor
          ? matchingUnitTypePredictor.id
          : trend.predictor
      ];
    const trendToDate = getTrendToDate(trend);
    return of(
      querySyncPush({
        pathname: toPath(PAGE_URL.PREDICTOR, {
          unitPath,
          groupId: predictorId
        }),
        search: queryString.stringify({
          time: trendToDate,
          mode: PLAYBACK_MODE.REVIEW,
          isPlaying: false,
          window: quickRange,
          view: CHART_VIEW.TIME_SERIES,
          review: fromState.getPlaybackReviewId(state),
          reviewTrend: trend.id
        })
      }),
      setToDate(CHART_TYPE.MAIN, moment.utc(trendToDate).format(DATE_FORMAT)),
      setQuickRange(CHART_TYPE.MAIN, quickRange)
    );
  })
);