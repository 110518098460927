import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import AlarmLevelsInput from '../../components/alarm/AlarmLevelsInput';
import { editAlarmLevel } from '../../state/actions/alarm';
import * as fromState from '../../state/reducers';

const mapStateToProps = createSelector(
  [
    state => fromState.getAlarmLevels(state)
  ],
  (
    alarmLevels
  ) => ({
    alarmLevels
  })
);

const mapDispatchToProps = {
  onEdit: editAlarmLevel
};

export default connect(mapStateToProps, mapDispatchToProps)(AlarmLevelsInput);