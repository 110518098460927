import _ from 'lodash';
import { ofType } from 'redux-observable';
import { merge, of } from 'rxjs';
import { mergeMap, withLatestFrom } from 'rxjs/operators';
import { setTrendCount, setTrendIds, SET_COMPARE_ALL, SET_COMPARE_FROM_DATE, SET_COMPARE_LABELS, SET_COMPARE_TO_DATE } from '../../../actions/chart';
import { insertMany } from '../../../actions/entity';
import { setChartIsLoading } from '../../../actions/chart';
import { CONTROLLER, ENTITY_PROPERTY, ENTITY_TYPE, LOADING, TREND_SEARCH_PAGE_SIZE } from '../../../../utils/constants';
import * as fromState from '../../../reducers';
import * as chartSelectors from '../../../selectors/chart';
import { takeUntilAppReset } from 'utils/helpers';

export default (action$, state$, { api }) => action$.pipe(
  ofType(SET_COMPARE_ALL, SET_COMPARE_FROM_DATE, SET_COMPARE_TO_DATE, SET_COMPARE_LABELS),
  withLatestFrom(state$),
  mergeMap(([action, state]) => merge(
    of(
      setTrendIds([]),
      setChartIsLoading(LOADING.TRENDS, true)
    ),
    api.query({
      controller: CONTROLLER.TREND,
      page: 0,
      pageSize: TREND_SEARCH_PAGE_SIZE,
      query: chartSelectors.getTrendQuery(state),
      token: fromState.getUser(state).token
    }).pipe(
      takeUntilAppReset(action$),
      mergeMap(({ items: trends, total }) => of(
        insertMany(ENTITY_TYPE.TREND, trends),
        setTrendIds(_.map(trends, ENTITY_PROPERTY.TREND.ID)),
        setTrendCount(total),
        setChartIsLoading(LOADING.TRENDS, false)
      ))
    )
  ))
);