import _ from 'lodash';
import moment from 'moment';
import { ofType } from 'redux-observable';
import { concat, EMPTY, of } from 'rxjs';
import { bufferCount, distinctUntilChanged, map, mergeMap, switchAll, switchMap, withLatestFrom } from 'rxjs/operators';
import * as api from '../../../utils/api';
import { CHART_TYPE, DATE_FORMAT, MAX_PLAYBACK_SPEED, MIN_CHART_UDPATE_PERIOD, PAGE_URL, SECONDS_IN_MINUTE } from '../../../utils/constants';
import { matchesPath, takeUntilAppReset } from '../../../utils/helpers';
import { setDates } from '../../actions/chart';
import { appendMultipleRanges } from '../../actions/data';
import { CHANGE_PLAYBACK_TIME, INCREMENT_PLAYBACK_TIME, JUMP_TO_LIVE_PLAYBACK } from '../../actions/playback';
import * as fromState from '../../reducers';
import * as chartSelectors from '../../selectors/chart';

export default (action$, state$) => state$.pipe(
  distinctUntilChanged((prev, curr) =>
    fromState.getGroup(prev, CHART_TYPE.MAIN) === fromState.getGroup(curr, CHART_TYPE.MAIN)
    && curr.router.location.pathname === prev.router.location.pathname
    && fromState.getPlaybackSpeed(prev) === fromState.getPlaybackSpeed(curr)),
  switchMap(state => {
    const group = fromState.getGroup(state, CHART_TYPE.MAIN);
    if (!group || !matchesPath(state.router.location.pathname, PAGE_URL.PREDICTOR)) {
      return EMPTY;
    };

    const playbackSpeed = fromState.getPlaybackSpeed(state);
    const UPDATE_PERIOD = _.max([
      MIN_CHART_UDPATE_PERIOD,
      SECONDS_IN_MINUTE / playbackSpeed
    ]);

    return concat(
      of(
        action$.pipe(
          ofType(INCREMENT_PLAYBACK_TIME),
          bufferCount(UPDATE_PERIOD),
          map(_.last)
        )
      ),
      action$.pipe(
        ofType(CHANGE_PLAYBACK_TIME, JUMP_TO_LIVE_PLAYBACK),
        map(() => action$.pipe(
          ofType(INCREMENT_PLAYBACK_TIME),
          bufferCount(UPDATE_PERIOD),
          map(_.last)
        ))
      )
    ).pipe(
      switchAll(),
      switchMap(action => {
        const toDate = moment.utc(action.payload.time);
        const fromDate = toDate.clone().subtract(UPDATE_PERIOD * playbackSpeed + MIN_CHART_UDPATE_PERIOD * MAX_PLAYBACK_SPEED, 'seconds');
        return api.getWindow({
          predictor: group,
          fromDate: fromDate.format(DATE_FORMAT),
          toDate: toDate.format(DATE_FORMAT),
          token: fromState.getUser(state).token
        }).pipe(
          takeUntilAppReset(action$),
          withLatestFrom(state$),
          mergeMap(([{ sensors, outputs }, latestState]) => {
            const duration = moment.duration(fromState.getQuickRange(latestState, CHART_TYPE.MAIN));
            const dropBeforeTimestamp = parseInt(chartSelectors.getWindowFromDate(latestState, CHART_TYPE.MAIN).format('X'));
            return of(
              appendMultipleRanges(
                CHART_TYPE.MAIN,
                sensors,
                fromState.getGroup(state, CHART_TYPE.MAIN),
                outputs,
                dropBeforeTimestamp,
                toDate.toISOString()
              ),
              setDates(
                CHART_TYPE.MAIN,
                toDate.clone().subtract(duration).format(DATE_FORMAT),
                toDate.format(DATE_FORMAT),
                false,
                true
              )
            );
          })
        );
      })
    );
  })
);