import { SET_AUTH_MESSAGE } from '../../actions/auth';

export default (state = null, action) => {
  switch (action.type) {
    case SET_AUTH_MESSAGE:
      return action.payload.message;
    default:
      return state;
  }
};

export const getMessage = state => state;