import moment from 'moment';
import DateTimeButtonGroup from './DateTimeButtonGroup';
import ModeButtonGroup from './ModeButtonGroup';
import styles from './PlaybackButtons.styles';
import WindowButtonGroup from './WindowButtonGroup';

const PlaybackButtons = ({
  mode,
  time,
  isPlaying,
  quickRange,
  speed,
  review,
  reviewTrends,
  reviewTrendId,
  allTrendTypes,
  onChangeSpeed,
  onChangeTime,
  onJumpToLive,
  onChangeQuickRange,
  onClickPause,
  onClickPlay,
  onChangeReviewTrend,
  onExitReview,
  onClearRegion
}) => {
  const momentTime = moment.utc(time);
  return (
    <div style={styles.wrapper}>
      <WindowButtonGroup
        momentTime={momentTime}
        quickRange={quickRange}
        isPlaying={isPlaying}
        mode={mode}
        onChangeTime={onChangeTime}
        onChangeQuickRange={onChangeQuickRange}
        onJumpToLive={onJumpToLive} />
      <DateTimeButtonGroup
        momentTime={momentTime}
        isPlaying={isPlaying}
        mode={mode}
        onChangeTime={onChangeTime}
        onClickPause={onClickPause}
        onClickPlay={onClickPlay} />
      <ModeButtonGroup
        mode={mode}
        isPlaying={isPlaying}
        speed={speed}
        review={review}
        reviewTrends={reviewTrends}
        reviewTrendId={reviewTrendId}
        allTrendTypes={allTrendTypes}
        onChangeSpeed={onChangeSpeed}
        onJumpToLive={onJumpToLive}
        onChangeReviewTrend={onChangeReviewTrend}
        onExitReview={onExitReview}
        onClearRegion={onClearRegion} />
    </div>
  );
};

PlaybackButtons.displayName = 'PlaybackButtons';

export default PlaybackButtons;