import * as baseMoment from 'moment';
import { extendMoment } from 'moment-range';
import createCachedSelector from 're-reselect';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { TrendType } from 'types';
import TrendTimeline from 'components/chart/TrendTimeline/TrendTimeline';
import { setQuickRange } from 'state/actions/chart';
import * as fromState from 'state/reducers';
import * as chartSelectors from 'state/selectors/chart';
import { CHART_TYPE, ENTITY_TYPE, CHART_VIEW, DATE_FORMAT } from 'utils/constants';
const moment = extendMoment(baseMoment);

export const getBrushTimes = (
  startTimestamp: number,
  endTimestamp: number
): [string, string] => {
  return [
    moment.utc(startTimestamp, 'X').toISOString(),
    moment.utc(endTimestamp, 'X').toISOString()
  ];
};

interface Props {
  chartType: CHART_TYPE;
}
const mapStateToProps = createCachedSelector(
  [
    (state, { chartType }: Props) => fromState.getToDate(state, chartType),
    chartSelectors.getCompareTrend,
    state => fromState.getQuickRange(state, CHART_TYPE.MAIN),
    (state, { chartType }: Props) => chartSelectors.getTimelineTrends(state, chartType),
    fromState.getUnitPath,
    fromState.getView,
    (state, { chartType }: Props) => fromState.getBrushStartTimestamp(state, chartType),
    (state, { chartType }: Props) => fromState.getBrushEndTimestamp(state, chartType),
    (state, { chartType }: Props) => fromState.getTrendTimelineHeight(state),
    state => fromState.getAll<TrendType>(state, ENTITY_TYPE.TREND_TYPE),
    (state, { chartType }: Props) => chartType
  ],
  (
    toDate,
    compareTrend,
    quickRange,
    trends,
    unitPath,
    view,
    brushStartTimestamp,
    brushEndTimestamp,
    height,
    allTrendTypes,
    chartType
  ) => {
    const [brushStartTime, brushEndTime] = view === CHART_VIEW.TIME_SERIES
      ? getBrushTimes(brushStartTimestamp, brushEndTimestamp)
      : [null, null];
    const quickRangeDuration = moment.duration(quickRange);
    if (chartType === CHART_TYPE.COMPARE && compareTrend !== null) {
      return {
        endTime: brushEndTime || compareTrend.to,
        startTime: brushStartTime
          || moment.utc(compareTrend.to).subtract(quickRangeDuration).toISOString(),
        trends,
        unitPath,
        currentTrendId: compareTrend.id,
        allTrendTypes,
        height
      };
    };

    return {
      endTime: brushEndTime || moment.utc(toDate, DATE_FORMAT).toISOString(),
      startTime: brushStartTime
        || moment.utc(toDate, DATE_FORMAT).subtract(quickRangeDuration).toISOString(),
      trends,
      unitPath,
      allTrendTypes,
      height
    };
  }
)(
  (state, { chartType }) => chartType
);

const mapDispatchToProps = (dispatch, { chartType }) => bindActionCreators({
  onSetQuickRange: quickRange => setQuickRange(CHART_TYPE.MAIN, quickRange)
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TrendTimeline);