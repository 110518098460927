import _ from 'lodash';
import createCachedSelector from 're-reselect';
import { connect } from 'react-redux';
import { CustomYAxisTick } from '../../../components/chart/TimeSeriesChart';
import * as fromState from '../../../state/reducers';
import getFunctionLines from './getFunctionLines';
import getInputLines from './getInputLines';
import getOutputLines from './getOutputLines';
import { transformChartTypeSelectors } from '../../../utils/helpers';

const mapStateToProps = createCachedSelector(
  transformChartTypeSelectors([
    getInputLines,
    getOutputLines,
    getFunctionLines,
    fromState.getHoverLine
  ]),
  (inputLines, outputLines, functionLines, hoverLine) => ({
    ..._.chain([...inputLines, ...outputLines, ...functionLines])
      .find(['id', hoverLine])
      .pick(['valueScale', 'scale', 'offset', 'unitsOfMeasurement', 'valueType'])
      .value(),
    hoverLine
  })
)(
  (state, { chartType }) => chartType
);

export default connect(mapStateToProps)(CustomYAxisTick);