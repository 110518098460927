import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import * as fromState from '../../../state/reducers';
import * as entitySelectors from '../../../state/selectors/entity';
import { EMPTY_STRING, ENTITY_TYPE, UNIT_PATH_SEPARATOR } from '../../constants';

const mapStateToProps = createSelector(
  [
    (state, { id }) => fromState.getById(state, ENTITY_TYPE.PREDICTOR, id),
    state => entitySelectors.getUnitPaths(state),
    (state, { id }) => id
  ],
  (
    predictor,
    unitPaths,
    id
  ) => ({
    path: predictor
      ? `${unitPaths[predictor.unitId] || EMPTY_STRING}${UNIT_PATH_SEPARATOR}${predictor.name}`
      : id
  })
);

const PredictorText = ({ path }) => path;
PredictorText.displayName = 'PredictorText';

export default connect(mapStateToProps)(PredictorText);