import _ from 'lodash';
import { injectIntl } from 'react-intl';
import { Dropdown, Form } from 'semantic-ui-react';

const ON_DROPDOWN_VALUE_CHANGE = 'LabelsElement/ON_DROPDOWN_VALUE_CHANGE';
const onDropdownValueChange = value => ({
  type: ON_DROPDOWN_VALUE_CHANGE,
  payload: {
    value
  }
});

const ON_DROPDOWN_SEARCH_CHANGE = 'LabelsElement/ON_DROPDOWN_SEARCH_CHANGE';
const onDropdownSearchChange = searchQuery => ({
  type: ON_DROPDOWN_SEARCH_CHANGE,
  payload: {
    searchQuery
  }
});

const ON_DROPDOWN_ADD_ITEM = 'LabelsElement/ON_DROPDOWN_ADD_ITEM';
const onDropdownAddItem = newValue => ({
  type: ON_DROPDOWN_ADD_ITEM,
  payload: {
    newValue
  }
});

const labelsElementReducer = (state, action) => {
  switch (action.type) {
    case ON_DROPDOWN_VALUE_CHANGE:
      return {
        ...state,
        value: action.payload.value
      };
    case ON_DROPDOWN_SEARCH_CHANGE:
      return {
        ...state,
        searchQuery: action.payload.searchQuery
      };
    case ON_DROPDOWN_ADD_ITEM:
      return {
        ...state,
        searchQuery: '',
        value: [
          ...state.value,
          action.payload.newValue
        ],
        labels: [
          ...state.labels,
          ..._.includes(state.labels, action.payload.newValue)
            ? []
            : [action.payload.newValue]
        ]
      };
    default:
      return state;
  };
};

const LabelsElement = injectIntl(({
  label,
  placeholder,
  multiple,
  state = {
    labels: [],
    searchQuery: '',
    value: multiple ? [] : null
  },
  labels,
  error,
  allowAdditions = false,
  errorPath,
  messages,
  onChange,
  intl: {
    formatMessage
  }
}) => {
  const {
    labels: additionalLabels = [],
    searchQuery = '',
    value: dropdownValue = multiple ? [] : null
  } = state;
  return (
    <Form.Field
      error={error}>
      <label>
        {label}
      </label>
      <Dropdown
        clearable
        fluid
        selection
        multiple={multiple}
        search
        additionPosition='bottom'
        value={dropdownValue}
        searchQuery={searchQuery}
        onChange={(e, { value }) => {
          return onChange(
            labelsElementReducer(state, onDropdownValueChange(value)),
            errorPath
          );
        }}
        onSearchChange={(e, { searchQuery }) => {
          return onChange(
            labelsElementReducer(state, onDropdownSearchChange(searchQuery))
          );
        }}
        onAddItem={(e, { value: newValue }) => {
          if (_.includes(dropdownValue, newValue)) {
            return;
          }
          return onChange(
            labelsElementReducer(state, onDropdownAddItem(newValue)),
            errorPath
          );
        }}
        options={_.map(
          [...labels, ...additionalLabels],
          label => ({
            name: label,
            text: messages
              ? formatMessage(messages[label])
              : label,
            value: label
          })
        )}
        allowAdditions={allowAdditions}
        placeholder={placeholder} />
    </Form.Field>
  );
});

LabelsElement.displayName = 'LabelsElement';

export default LabelsElement;