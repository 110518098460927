import { COLOUR } from '../../../utils/constants';

export default {
  buttonPopup: {
    background: COLOUR.MEDIUM_GREY,
    border: '1px solid white'
  },
  editModelButton: {
    margin: 0
  },
  predictorDropdown: {
    minWidth: '10rem',
    border: 'none'
  },
  predictorDropdownMenu: {
    maxHeight: '50vh'
  },
  configurationButton: {
    margin: 0
  },
  modelTypeLabel: (noButtons, withDetail, withModel) => ({
    ...noButtons
      ? {}
      : {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0
      },
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    margin: 0,
    paddingTop: 0,
    paddingBottom: 0,
    lineHeight: withDetail
      ? '15px'
      : '32px',
    fontWeight: 600,
    fontSize: '0.8rem',
    ...withModel
      ? {
        cursor: 'pointer'
      }
      : {}
  }),
  modelTypeLabelDetail: {
    display: 'block',
    marginLeft: 0
  }
};