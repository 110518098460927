import _ from 'lodash';
import { defineMessages, injectIntl } from 'react-intl';
import { Button, Divider, Header, Loader, Popup, Table } from 'semantic-ui-react';
import { COLOUR, EMPTY_STRING, INVERTED_THEME, ENTITY_MODAL } from '../../utils/constants';
import IsInRole from '../../containers/auth/IsInRole';
import EditEntityModalContainer from '../../containers/entity/EditEntityModalContainer';
import DeleteEntityModalContainer from '../../containers/entity/DeleteEntityModalContainer';
import entityConfig from '../../utils/entities';

const messages = defineMessages({
  'deleteButton.text': {
    id: 'entityTable.deleteButton.text',
    defaultMessage: 'Delete'
  },
  'editButton.text': {
    id: 'entityTable.editButton.text',
    defaultMessage: 'Edit'
  },
  'emptyMessage': {
    id: 'entityTable.emptyMessage',
    defaultMessage: 'There are no items'
  }
});

const EntityTable = injectIntl(({
  isLoading,
  columns,
  toggleModal,
  rows,
  entityMessages,
  entityType,
  deleteById,
  getItemUrl,
  navigateOnClick,
  openOnClick,
  parentId,
  form,
  noActions,
  actionsDisabledForIds,
  sort,
  onRowClick,
  onClickSortableHeader,
  onOpenEntity,
  intl: {
    formatMessage
  }
}) => {
  return (
    <Table
      celled
      inverted={INVERTED_THEME}
      selectable
      sortable>
      <Table.Header>
        <Table.Row>
          {_.map(columns, (({ width, name, sortable }) => (
            <Table.HeaderCell
              key={name}
              width={width}
              sorted={sort.by === name
                ? sort.direction
                : null}
              className={sortable
                ? EMPTY_STRING
                : 'not-sortable'}
              onClick={sortable
                ? () => onClickSortableHeader(name)
                : null}>
              {formatMessage(entityMessages[`columns.${name}.title`])}
            </Table.HeaderCell>
          )))}
          <IsInRole role={entityConfig[entityType].modifyRole}>
            {!noActions && (
              <Table.HeaderCell
                width={1}
                className='not-sortable' />
            )}
          </IsInRole>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {_.map(rows, entity => (
          <Table.Row key={entity.id}>
            {_.map(columns, column => (
              <Table.Cell
                onClick={() => {
                  if (navigateOnClick) {
                    const targetUrl = getItemUrl(entity);
                    if (targetUrl !== null) {
                      onRowClick(getItemUrl(entity));
                    }
                  };
                  if (openOnClick) {
                    onOpenEntity(entity);
                  };
                }}
                style={(navigateOnClick || openOnClick) && { cursor: 'pointer' }}
                key={column.name}
                {...column.props ? column.props(entity) : {}}>
                {column.render(entity)}
              </Table.Cell>
            ))}
            <IsInRole
              role={entityConfig[entityType].modifyRole}>
              {!noActions && (
                <Table.Cell textAlign='center'>
                  <Button.Group>
                    <EditEntityModalContainer
                      trigger={(
                        <Popup
                          on='hover'
                          inverted={INVERTED_THEME}
                          style={{
                            background: COLOUR.MEDIUM_GREY,
                            border: '1px solid white'
                          }}
                          trigger={(
                            <Button
                              primary
                              disabled={isLoading || _.includes(actionsDisabledForIds, entity.id)}
                              icon='edit outline'
                              style={{ border: 'none' }}
                              onClick={event => {
                                event.stopPropagation();
                                return toggleModal('edit', entity.id);
                              }} />
                          )}>
                          {formatMessage(messages['editButton.text'])}
                        </Popup>
                      )}
                      parentId={parentId}
                      entity={entity}
                      form={form}
                      entityType={entityType} />
                    <DeleteEntityModalContainer
                      id={entity.id}
                      entityType={entityType}
                      onDelete={() => deleteById(entity.id)} />
                    <Popup
                      on='hover'
                      inverted={INVERTED_THEME}
                      style={{
                        background: COLOUR.MEDIUM_GREY,
                        border: '1px solid white'
                      }}
                      trigger={(
                        <Button
                          icon='trash'
                          disabled={isLoading || _.includes(actionsDisabledForIds, entity.id)}
                          negative
                          onClick={event => {
                            event.stopPropagation();
                            return toggleModal(ENTITY_MODAL.DELETE, entity.id);
                          }} />
                      )}>
                      {formatMessage(messages['deleteButton.text'])}
                    </Popup>
                  </Button.Group>
                </Table.Cell>
              )}
            </IsInRole>
          </Table.Row>
        ))}
      </Table.Body>
      <Table.Footer>
        <Table.Row>
          {isLoading && !_.some(rows) && (
            <Table.Cell colSpan={columns.length + 1}>
              <Divider hidden />
              <Loader
                inline='centered'
                active={isLoading} />
              <Divider hidden />
            </Table.Cell>
          )}
          {!isLoading && !_.some(rows) && (
            <Table.Cell
              colSpan={columns.length + 2}
              textAlign='center'>
              <Divider hidden />
              <Header
                inverted={INVERTED_THEME}
                content={formatMessage(messages['emptyMessage'])}
                disabled />
              <Divider hidden />
            </Table.Cell>
          )}
        </Table.Row>
      </Table.Footer>
    </Table>
  );
});

EntityTable.displayName = 'EntityTable';

export default EntityTable;