import { Button, Modal } from 'semantic-ui-react';
import AnimatedButton from '../shared/AnimatedButton';
import AlarmRangeInputContainer from '../../containers/alarm/AlarmRangeInputContainer';
import { defineMessages, injectIntl } from 'react-intl';

const messages = defineMessages({
  'header.text': {
    id: 'alarmRangesModal.header.text',
    defaultMessage: 'Alarm Ranges'
  },
  'cancelButton.text': {
    id: 'alarmRangesModal.cancelButton.text',
    defaultMessage: 'Cancel'
  },
  'saveButton.text': {
    id: 'alarmRangesModal.saveButton.text',
    defaultMessage: 'Save'
  }
});

const AlarmRangesModal = injectIntl(({
  alarmRanges,
  isSaving,
  onSave,
  onClose,
  open,
  isValid,
  intl: {
    formatMessage
  }
}) => (
  <Modal
    open={open}
    centered
    closeOnDimmerClick={false}
    closeIcon
    closeOnEscape
    onClose={onClose}>
    <Modal.Header content={formatMessage(messages['header.text'])} />
    <Modal.Content
      scrolling
      style={{
        minHeight: '50vh',
        overflow: 'show'
      }}>
      <AlarmRangeInputContainer />
    </Modal.Content>
    <Modal.Actions>
      <Button
        content={formatMessage(messages['cancelButton.text'])}
        onClick={onClose} />
      <AnimatedButton
        text={formatMessage(messages['saveButton.text'])}
        icon='save'
        loading={isSaving}
        positive
        disabled={!isValid}
        onClick={() => onSave(alarmRanges)} />
    </Modal.Actions>
  </Modal>
));

AlarmRangesModal.displayName = 'AlarmRangesModal';

export default AlarmRangesModal;