import createCachedSelector from 're-reselect';
import * as fromState from '../../../reducers';
import { ENTITY_TYPE } from '../../../../utils/constants';
import _ from 'lodash';

export default propertiesSelector => createCachedSelector(
  [
    propertiesSelector,
    state => fromState.getAll(state, ENTITY_TYPE.ENUMERATION)
  ],
  (
    properties,
    allEnumerations
  ) => _.mapValues(
    properties,
    values => values
      ? allEnumerations[values.enumeration]
      : null
  )
)(
  (state, chartType) => chartType
);