import _ from 'lodash';
import { defineMessages } from 'react-intl';
import { EntityConfig, Review } from 'types';
import { ENTITY_TYPE, ENTITY_FORM_ELEMENT_TYPE, TYPED_LIST_ELEMENT_TYPE } from 'utils/constants';
import uuid from 'uuid/v1';
import StatusColumn from './StatusColumn';

const messages = defineMessages({
  'title': {
    id: 'review.title',
    defaultMessage: 'Reviews'
  },
  'columns.name.title': {
    id: 'review.columns.name.title',
    defaultMessage: 'Name'
  },
  'columns.status.title': {
    id: 'review.columns.status.title',
    defaultMessage: 'Status'
  },
  'columns.progress.title': {
    id: 'review.columns.progress.title',
    defaultMessage: 'Progress'
  },
  'form.name.label': {
    id: 'review.form.name.label',
    defaultMessage: 'Name'
  },
  'form.name.placeholder': {
    id: 'review.form.name.placeholder',
    defaultMessage: 'Name'
  },
  'form.regions.label': {
    id: 'review.form.regions.label',
    defaultMessage: 'Regions'
  },
  'form.regions.name.placeholder': {
    id: 'review.form.regions.name.placeholder',
    defaultMessage: 'Leave blank to use trend type name'
  }
});

const config: EntityConfig<Review> = {
  apiPath: 'review',
  pageUrl: 'reviews',
  icon: 'tasks',
  disableFetchTypes: true,
  hideAdd: true,
  openOnAdd: true,
  openOnClick: true,
  messages,
  columns: [
    {
      name: 'name',
      width: 3,
      render: ({ name }) => name
    },
    {
      name: 'status',
      width: 3,
      render: review => (
        <StatusColumn review={review} />
      )
    },
    {
      name: 'progress',
      width: 3,
      render: (review: Review) => {
        const numTrendsCompleted = _.chain(review.trends)
          .filter(reviewTrend =>
            reviewTrend.skipped ||
            _.every(review.regions, reviewRegion => reviewTrend.regions[reviewRegion.name]))
          .size()
          .value();
        return `${numTrendsCompleted}/${_.size(review.trends)}`;
      }
    }
  ],
  form: [
    {
      name: 'name',
      type: ENTITY_FORM_ELEMENT_TYPE.INPUT
    },
    {
      name: 'regions',
      type: ENTITY_FORM_ELEMENT_TYPE.LIST,
      form: [
        {
          name: 'name',
          placeholderMessage: messages['form.regions.name.placeholder']
        },
        {
          name: 'trendType',
          type: TYPED_LIST_ELEMENT_TYPE.SELECT_ENTITY,
          entityType: ENTITY_TYPE.TREND_TYPE
        }
      ],
      getErrorPath: (index, name) => `regions[${index}].${name}`,
      disableAddOnEdit: true
    }
  ],
  createEntity: values => ({
    id: uuid(),
    name: values.name,
    query: {
      filter: JSON.stringify(values.query.filter),
      sort: JSON.stringify(values.query.sort)
    },
    regions: values.regions
  }),
  populateForm: entity => ({
    name: entity.name,
    regions: entity.regions
  })
};

export default config;