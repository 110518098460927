import _ from 'lodash';
import { ofType } from 'redux-observable';
import { merge, of } from 'rxjs';
import { mergeMap, withLatestFrom } from 'rxjs/operators';
import { LOAD_MORE_TRENDS, setTrendCount, setTrendIds } from '../../../actions/chart';
import { insertMany } from '../../../actions/entity';
import { setChartIsLoading } from '../../../actions/chart';
import { CONTROLLER, ENTITY_PROPERTY, ENTITY_TYPE, LOADING, TREND_SEARCH_PAGE_SIZE } from '../../../../utils/constants';
import * as fromState from '../../../reducers';
import * as chartSelectors from '../../../selectors/chart';
import { takeUntilAppReset } from 'utils/helpers';

export default (action$, state$, { api }) => action$.pipe(
  ofType(LOAD_MORE_TRENDS),
  withLatestFrom(state$),
  mergeMap(([action, state]) => merge(
    of(
      setChartIsLoading(LOADING.TRENDS, true)
    ),
    api.query({
      controller: CONTROLLER.TREND,
      page: chartSelectors.getTrendPage(state) + 1,
      pageSize: TREND_SEARCH_PAGE_SIZE,
      query: chartSelectors.getTrendQuery(state),
      token: fromState.getUser(state).token
    }).pipe(
      takeUntilAppReset(action$),
      mergeMap(({ items: trends, total }) => of(
        insertMany(ENTITY_TYPE.TREND, trends),
        setTrendIds([...fromState.getTrendIds(state), ..._.map(trends, ENTITY_PROPERTY.TREND.ID)]),
        setTrendCount(fromState.getTrendCount(state) + total),
        setChartIsLoading(LOADING.TRENDS, false)
      ))
    )
  ))
);