import _ from 'lodash';
import moment from 'moment';
import { ofType } from 'redux-observable';
import { concat, merge, of } from 'rxjs';
import { filter, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { takeUntilAppReset } from 'utils/helpers';
import * as api from '../../../utils/api';
import { CHART_TYPE, CONTROLLER, DATE_FORMAT, ENTITY_PROPERTY, ENTITY_TYPE, SELECTION_TYPE } from '../../../utils/constants';
import { setChartIsLoading, setDates, setGroup, setSelected, SET_COMPARE_TREND } from '../../actions/chart';
import { insert, insertMany } from '../../actions/entity';
import * as fromState from '../../reducers';

export default (action$, state$) => action$.pipe(
  ofType(SET_COMPARE_TREND),
  filter(action => action.payload.id),
  withLatestFrom(state$),
  mergeMap(([action, state]) => merge(
    of(
      setChartIsLoading(CHART_TYPE.COMPARE, true)
    ),
    api.getByPredictor({
      controller: CONTROLLER.ACTION_MAPPING,
      predictor: fromState.getById(state, ENTITY_TYPE.TREND, action.payload.id).predictor,
      token: fromState.getUser(state).token
    }).pipe(
      takeUntilAppReset(action$),
      map(results => insertMany(ENTITY_TYPE.ACTION_MAPPING, results))
    ),
    api.get({
      controller: CONTROLLER.PREDICTOR,
      id: fromState.getById(state, ENTITY_TYPE.TREND, action.payload.id).predictor,
      token: fromState.getUser(state).token
    }).pipe(
      takeUntilAppReset(action$),
      mergeMap(predictor => concat(
        api.getMultiple({
          controller: CONTROLLER.SENSOR,
          ids: _.chain([...predictor.inputMappings, ...predictor.functionMappings || []]).map(ENTITY_PROPERTY.PREDICTOR.INPUT_MAPPING.SENSOR).filter().value(),
          token: fromState.getUser(state).token
        }).pipe(
          takeUntilAppReset(action$),
          map(sensors => insertMany(ENTITY_TYPE.SENSOR, sensors))
        ),
        of(
          insert(ENTITY_TYPE.PREDICTOR, predictor),
          setSelected(CHART_TYPE.COMPARE, SELECTION_TYPE.INPUT, predictor.id === fromState.getGroup(state, CHART_TYPE.MAIN) ? fromState.getSelected(state, CHART_TYPE.MAIN, SELECTION_TYPE.INPUT) : []),
          setSelected(CHART_TYPE.COMPARE, SELECTION_TYPE.OUTPUT, predictor.id === fromState.getGroup(state, CHART_TYPE.MAIN) ? fromState.getSelected(state, CHART_TYPE.MAIN, SELECTION_TYPE.OUTPUT) : []),
          setSelected(CHART_TYPE.COMPARE, SELECTION_TYPE.FUNCTION, []),
          setGroup(CHART_TYPE.COMPARE, predictor.id),
          setDates(
            CHART_TYPE.COMPARE,
            moment.utc(fromState.getById(state, ENTITY_TYPE.TREND, action.payload.id).to)
              .subtract(moment.duration(fromState.getQuickRange(state, CHART_TYPE.MAIN)))
              .format(DATE_FORMAT),
            moment.utc(fromState.getById(state, ENTITY_TYPE.TREND, action.payload.id).to).format(DATE_FORMAT)
          )
        )
      ))
    )
  ))
);