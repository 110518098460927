import { SET_PREDICTORS } from '../../actions/dashboard';
import { DELETE_BY_ID_FULFILLED, ADD_FULFILLED } from '../../actions/entity';
import _ from 'lodash';
import { ENTITY_TYPE } from '../../../utils/constants';

export default (state = {}, action) => {
  switch (action.type) {
    case SET_PREDICTORS:
      return action.payload.predictors;
    case DELETE_BY_ID_FULFILLED:
      if (action.entityType !== ENTITY_TYPE.PREDICTOR) {
        return state;
      }
      return _.without(state, action.payload.id);
    case ADD_FULFILLED:
      if (action.entityType !== ENTITY_TYPE.PREDICTOR) {
        return state;
      };
      return _.union(state, [action.payload.entity.id]);
    default:
      return state;
  }
};

export const getPredictors = state => state;