import _ from 'lodash';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import * as fromState from '../../state/reducers';
import { ENTITY_PROPERTY, ENTITY_TYPE } from '../../utils/constants';
import EntityTableContainer from '../entity/EntityTableContainer';

const mapStateToProps = createSelector(
  [
    (state, { trend }) => trend,
    state => fromState.getAll(state, ENTITY_TYPE.ACTION)
  ],
  (trend, allActions) => ({
    ids: _.chain(allActions)
      .values()
      .filter(action => action.trend === trend.id)
      .map(ENTITY_PROPERTY.ACTION.ID)
      .value(),
    entityType: ENTITY_TYPE.ACTION
  })
);

export default connect(mapStateToProps)(EntityTableContainer);