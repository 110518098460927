export default {
  searchSegment: {
    border: '1px solid #555'
  },
  searchLayoutSpan: {
    clear: 'both',
    display: 'block'
  },
  searchDivider: {
    clear: 'both'
  }
};