import _ from 'lodash';
import { defineMessages } from 'react-intl';
import { EntityConfig, ActionMapping } from 'types';
import uuid from 'uuid/v1';
import EntityText from '../../../components/entity/EntityText';
import { ENTITY_FORM_ELEMENT_TYPE, ENTITY_TYPE } from '../../constants';
import parentTypeSelector from './parentTypeSelector';
import selectContextListsSelector from './selectContextListsSelector';

const messages = defineMessages({
  'columns.name.title': {
    id: 'actionMapping.columns.name.title',
    defaultMessage: 'Name'
  },
  'columns.connector.title': {
    id: 'actionMapping.columns.connector.title',
    defaultMessage: 'Connector'
  },
  'form.name.label': {
    id: 'actionMapping.form.name.label',
    defaultMessage: 'Name'
  },
  'form.name.placeholder': {
    id: 'actionMapping.form.name.placeholder',
    defaultMessage: 'Name'
  },
  'form.trendType.label': {
    id: 'actionMapping.form.trendType.label',
    defaultMessage: 'On'
  },
  'form.trendType.placeholder': {
    id: 'actionMapping.form.trendType.placeholder',
    defaultMessage: 'On'
  },
  'form.connector.label': {
    id: 'actionMapping.form.connector.label',
    defaultMessage: 'Connector'
  },
  'form.connector.placeholder': {
    id: 'actionMapping.form.connector.placeholder',
    defaultMessage: 'Connector'
  },
  'type.phalanx.createWork.name': {
    id: 'actionMapping.type.phalanx.createWork.name',
    defaultMessage: 'Create Work'
  },
  'type.phalanx.createWork.options.location.label': {
    id: 'actionMapping.type.phalanx.createWork.options.location.label',
    defaultMessage: 'Location'
  },
  'type.phalanx.createWork.options.location.placeholder': {
    id: 'actionMapping.type.phalanx.createWork.options.location.placeholder',
    defaultMessage: 'Location'
  },
  'type.phalanx.createWork.options.serialisedEquipmentId.label': {
    id: 'actionMapping.type.phalanx.createWork.options.serialisedEquipmentId.label',
    defaultMessage: 'Serialised Equipment Id'
  },
  'type.phalanx.createWork.options.serialisedEquipmentId.placeholder': {
    id: 'actionMapping.type.phalanx.createWork.options.serialisedEquipmentId.placeholder',
    defaultMessage: 'Serialised Equipment Id'
  }
});

const config: EntityConfig<ActionMapping> = {
  apiPath: 'actionmapping',
  pageUrl: 'actions',
  parentEntityType: ENTITY_TYPE.PREDICTOR,
  labels: true,
  messages,
  parentTypeSelector,
  selectContextListsSelector,
  parentSearchFilter: parentId => ({
    Predictor: {
      $eq: parentId
    }
  }),
  formValueSearchSchema: [
    {
      name: 'connector',
      entityType: ENTITY_TYPE.ACTION_CONNECTOR
    }
  ],
  columns: [
    {
      name: 'name',
      width: 3,
      render: ({ name }) => name
    },
    {
      name: 'connector',
      width: 3,
      render: ({ connector }) => (
        <EntityText
          id={connector}
          entityType={ENTITY_TYPE.ACTION_CONNECTOR} />
      )
    }
  ],
  form: [
    {
      name: 'name',
      type: ENTITY_FORM_ELEMENT_TYPE.INPUT
    },
    {
      name: 'trendType',
      type: ENTITY_FORM_ELEMENT_TYPE.SELECT_ENTITY,
      entityType: ENTITY_TYPE.TREND_TYPE
    },
    {
      name: 'connector',
      type: ENTITY_FORM_ELEMENT_TYPE.SEARCH,
      searchEntityType: ENTITY_TYPE.ACTION_CONNECTOR,
      transformResult: unitTypeActionMapping => ({
        key: unitTypeActionMapping.id,
        text: unitTypeActionMapping.name,
        value: unitTypeActionMapping.id
      }),
      disableOnEdit: true
    },
    {
      type: ENTITY_FORM_ELEMENT_TYPE.TYPES,
      filter: values => !!values.connector
    }
  ],
  createEntity: (values, predictor) => ({
    id: uuid(),
    name: values.name,
    predictor: predictor.id,
    trendType: _.get(values, 'trendType.value'),
    connector: values.connector,
    unitId: null,
    type: values.connector
      ? values.type
      : null,
    settings: values.settings || {}
  }),
  populateForm: entity => ({
    name: entity.name,
    trendType: {
      value: entity.trendType
    },
    connector: entity.connector,
    type: entity.type,
    settings: entity.settings
  })
};

export default config;