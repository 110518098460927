import Pane from './Pane';
import React, { CSSProperties, useEffect, useState, useRef } from 'react';
import {
  ReactNode
} from 'react';
import Resizer from './Resizer';

interface State {
  active: boolean;
  position: number;
  pane1Size?: number;
  pane2Size?: number;
  pane1?: HTMLElement;
  pane2?: HTMLElement;
}

interface Props {
  children: ReactNode;
  size?: number;
  minSize?: number;
  maxSize?: number;
  onChange?: (size: number) => void;
  className?: string;
  style?: CSSProperties;
  resizerStyle?: CSSProperties;
  pane1Style?: CSSProperties;
  pane2Style?: CSSProperties;
  [key: string]: unknown;
}
export default function SplitPane({
  children,
  size,
  minSize = 50,
  maxSize,
  onChange,
  style: styleProps,
  resizerStyle,
  pane1Style,
  pane2Style
}: Props) {
  function initialState(): State {
    return {
      active: false,
      position: size || minSize || 0,
      pane1Size: size || minSize,
      pane2Size: undefined
    };
  }

  const [state, setState] = useState<State>(initialState());
  const pane1Ref = useRef<HTMLElement | null>(null);
  const pane2Ref = useRef<HTMLElement | null>(null);

  useEffect(() => {
    document.addEventListener('mouseup', onMouseUp);
    document.addEventListener('mousemove', onMouseMove);

    return () => {
      document.removeEventListener('mouseup', onMouseUp);
      document.removeEventListener('mousemove', onMouseMove);
    };
  });

  const commonStyle: CSSProperties = {
    display: 'flex',
    flex: 1,
    height: '100%',
    position: 'absolute',
    outline: 'none',
    overflow: 'hidden',
    ...styleProps
  };

  const directionalStyle: CSSProperties = {
    bottom: 0,
    flexDirection: 'column',
    minHeight: '100%',
    top: 0,
    width: '100%'
  };

  const style: CSSProperties = {
    ...commonStyle,
    ...directionalStyle
  };

  const notNullChildren = React.Children.toArray(children).filter(c => c);

  function onMouseDown(event: React.MouseEvent) {
    setState({
      ...state,
      active: true,
      position: event.clientY
    });
  }

  function onMouseMove(event: MouseEvent) {
    if (state.active) {
      if (pane1Ref.current === null) {
        return;
      }

      const size = pane1Ref.current.getBoundingClientRect().height;
      const positionDelta = state.position - event.clientY;

      const newSize = Math.min(
        Math.max(size - positionDelta, minSize || 0),
        maxSize || Infinity
      );

      if (onChange) {
        onChange(newSize);
      }

      setState({
        ...state,
        position: event.clientY,
        pane1Size: newSize
      });
    }
  }

  function onMouseUp(event: MouseEvent) {
    setState({
      ...state,
      active: false
    });
  }

  return (
    <div style={style}>
      <Pane
        size={state.pane1Size}
        callbackRef={element => pane1Ref.current = element}
        style={pane1Style}>
        {notNullChildren[0]}
      </Pane>
      <Resizer
        style={resizerStyle}
        onMouseDown={onMouseDown} />
      <Pane
        size={state.pane2Size}
        callbackRef={element => pane2Ref.current = element}
        style={pane2Style}>
        {notNullChildren[1]}
      </Pane>
    </div>
  );
};