import { ofType } from 'redux-observable';
import { SET_ITEM_SELECTED, setItemSelected, SET_SELECTED, setSelected } from '../../actions/chart';
import { CHART_TYPE } from '../../../utils/constants';
import { map, filter, withLatestFrom } from 'rxjs/operators';
import * as fromState from '../../reducers';

export default (action$, state$) => action$.pipe(
  ofType(SET_ITEM_SELECTED, SET_SELECTED),
  withLatestFrom(state$),
  filter(([action, state]) => !action.payload.recursive && action.selectionType !== 'function' && fromState.getGroup(state, CHART_TYPE.MAIN) === fromState.getGroup(state, CHART_TYPE.COMPARE)),
  map(([action]) =>
    action.type === SET_ITEM_SELECTED
      ? setItemSelected(
        action.chartType === CHART_TYPE.MAIN ? CHART_TYPE.COMPARE : CHART_TYPE.MAIN,
        action.payload.id,
        action.selectionType,
        action.payload.selected,
        true
      )
      : setSelected(
        action.chartType === CHART_TYPE.MAIN ? CHART_TYPE.COMPARE : CHART_TYPE.MAIN,
        action.selectionType,
        action.payload.list,
        true
      ))
);