import { CHART_TYPE, SELECTION_TYPE } from 'utils/constants';

export const CHANGE_VIEW = 'chart/CHANGE_VIEW';
export const changeView = view => ({
  type: CHANGE_VIEW,
  payload: {
    view
  }
});

export const TOGGLE_TREND_SELECTED = 'chart/TOGGLE_TREND_SELECTED';
export const toggleTrendSelected = (
  id: string
) => ({
  type: TOGGLE_TREND_SELECTED,
  payload: {
    id
  }
});
export type ToggleTrendSelectedAction = ReturnType<typeof toggleTrendSelected>;

export const SET_ITEM_SELECTED = 'chart/SET_ITEM_SELECTED';
export const setItemSelected = (
  chartType: CHART_TYPE,
  id: string,
  selectionType: SELECTION_TYPE,
  selected: boolean,
  recursive: boolean
) => ({
  type: SET_ITEM_SELECTED,
  chartType,
  selectionType,
  payload: {
    id,
    selected,
    recursive
  }
});
export type SetItemSelectedAction = ReturnType<typeof setItemSelected>;

export const SET_SELECTED = 'chart/SET_SELECTED';
export const setSelected = (
  chartType: CHART_TYPE,
  selectionType: SELECTION_TYPE,
  list: string[],
  recursive: boolean
) => ({
  type: SET_SELECTED,
  chartType,
  selectionType,
  payload: {
    list,
    recursive
  }
});
export type SetSelectedAction = ReturnType<typeof setSelected>;

export const SET_QUICK_RANGE = 'chart/SET_QUICK_RANGE';
export const setQuickRange = (chartType, value) => ({
  type: SET_QUICK_RANGE,
  chartType,
  payload: {
    value
  }
});

export const SET_DATES = 'chart/SET_DATES';
export const setDates = (chartType, from, to, recursive, noDataFetch) => ({
  type: SET_DATES,
  chartType,
  payload: {
    from,
    to,
    recursive,
    noDataFetch
  }
});

export const SET_TO_DATE = 'chart/SET_TO_DATE';
export const setToDate = (chartType, value) => ({
  type: SET_TO_DATE,
  chartType,
  payload: {
    value
  }
});

export const SET_GROUP = 'chart/SET_GROUP';
export const setGroup = (chartType, id) => ({
  type: SET_GROUP,
  chartType,
  payload: {
    id
  }
});

export const SET_HOVER_LINE = 'chart/SET_HOVER_LINE';
export const setHoverLine = (chartType, line) => ({
  type: SET_HOVER_LINE,
  chartType,
  payload: {
    line
  }
});

export const SET_BRUSH_TIMESTAMPS = 'chart/SET_BRUSH_TIMESTAMPS';
export const setBrushTimestamps = (
  chartType: CHART_TYPE,
  startTimestamp: number,
  endTimestamp: number
) => ({
  type: SET_BRUSH_TIMESTAMPS,
  chartType,
  payload: {
    startTimestamp,
    endTimestamp
  }
});
export type SetBrushTimestampsAction = ReturnType<typeof setBrushTimestamps>;

export const SET_COPIED_TEXT = 'chart/SET_COPIED_TEXT';
export const setCopiedText = (chartType, text) => ({
  type: SET_COPIED_TEXT,
  chartType,
  payload: {
    text
  }
});

export const SET_FUNCTION_SENSORS = 'chart/SET_FUNCTION_SENSORS';
export const setFunctionSensors = (chartType, ids) => ({
  type: SET_FUNCTION_SENSORS,
  chartType,
  payload: {
    ids
  }
});

export const SET_EDITING_FUNCTION = 'chart/EDITING_FUNCTION';
export const setEditingFunction = (chartType, id) => ({
  type: SET_EDITING_FUNCTION,
  chartType,
  payload: {
    id
  }
});

export const SET_FUNCTION_DESCRIPTION_EDIT_TEXT = 'chart/SET_FUNCTION_DESCRIPTION_EDIT_TEXT';
export const setFunctionDescriptionEditText = (chartType, text) => ({
  type: SET_FUNCTION_DESCRIPTION_EDIT_TEXT,
  chartType,
  payload: {
    text
  }
});

export const REMOVE_FUNCTION = 'chart/REMOVE_FUNCTION';
export const removeFunction = (chartType, index) => ({
  type: REMOVE_FUNCTION,
  chartType,
  payload: {
    index
  }
});

export const EDIT_FUNCTION = 'chart/EDIT_FUNCTION';
export const editFunction = (chartType, index, description) => ({
  type: EDIT_FUNCTION,
  chartType,
  payload: {
    index,
    description
  }
});

export const SET_COMPARE_OPEN = 'chart/SET_COMPARE_OPEN';
export const setCompareOpen = open => ({
  type: SET_COMPARE_OPEN,
  payload: {
    open
  }
});

export const SET_TREND_IDS = 'chart/SET_TREND_IDS';
export const setTrendIds = ids => ({
  type: SET_TREND_IDS,
  payload: {
    ids
  }
});

export const APPEND_TREND_IDS = 'chart/APPEND_TREND_IDS';
export const appendTrendIds = ids => ({
  type: APPEND_TREND_IDS,
  payload: {
    ids
  }
});

export const SET_COMPARE_TREND = 'chart/SET_COMPARE_TREND';
export const setCompareTrend = id => ({
  type: SET_COMPARE_TREND,
  payload: {
    id
  }
});

export const SET_COMPARE_ALL = 'chart/SET_COMPARE_ALL';
export const setCompareAll = value => ({
  type: SET_COMPARE_ALL,
  payload: {
    value
  }
});

export const SET_COMPARE_FROM_DATE = 'chart/SET_COMPARE_FROM_DATE';
export const setCompareFromDate = value => ({
  type: SET_COMPARE_FROM_DATE,
  payload: {
    value
  }
});

export const SET_COMPARE_TO_DATE = 'chart/SET_COMPARE_TO_DATE';
export const setCompareToDate = value => ({
  type: SET_COMPARE_TO_DATE,
  payload: {
    value
  }
});

export const SET_COMPARE_LABELS = 'chart/SET_COMPARE_LABELS';
export const setCompareLabels = value => ({
  type: SET_COMPARE_LABELS,
  payload: {
    value
  }
});

export const SET_TREND_COUNT = 'chart/SET_TREND_COUNT';
export const setTrendCount = count => ({
  type: SET_TREND_COUNT,
  payload: {
    count
  }
});

export const LOAD_MORE_TRENDS = 'chart/LOAD_MORE_TRENDS';
export const loadMoreTrends = () => ({
  type: LOAD_MORE_TRENDS,
  payload: {
  }
});

export const SET_FEATURES_TOGGLE = 'chart/SET_FEATURES_TOGGLE';
export const setFeaturesToggle = (chartType, value) => ({
  type: SET_FEATURES_TOGGLE,
  chartType,
  payload: {
    value
  }
});

export const SET_SELECTED_PROPERTY = 'chart/SET_SELECTED_PROPERTY';
export const setSelectedProperty = (chartType, value) => ({
  type: SET_SELECTED_PROPERTY,
  chartType,
  payload: {
    value
  }
});

export const SET_SELECTED_FEATURE = 'chart/SET_SELECTED_FEATURE';
export const setSelectedFeature = (chartType, value) => ({
  type: SET_SELECTED_FEATURE,
  chartType,
  payload: {
    value
  }
});

export const FEATURE_WINDOW_CHANGED = 'chart/FEATURE_WINDOW_CHANGED';
export const featureWindowChanged = (chartType, value) => ({
  type: FEATURE_WINDOW_CHANGED,
  chartType,
  payload: {
    value
  }
});

export const SET_FEATURE_WINDOW = 'chart/SET_FEATURE_WINDOW';
export const setFeatureWindow = (chartType, value) => ({
  type: SET_FEATURE_WINDOW,
  chartType,
  payload: {
    value
  }
});

export const SET_CHART_IS_LOADING = 'chart/SET_CHART_IS_LOADING';
export const setChartIsLoading = (namespace, loading) => ({
  type: SET_CHART_IS_LOADING,
  namespace,
  payload: {
    loading
  }
});

export const SET_TREND_TIMELINE_HEIGHT = 'chart/SET_TREND_TIMELINE_HEIGHT';
export const setTrendTimelineHeight = value => ({
  type: SET_TREND_TIMELINE_HEIGHT,
  payload: {
    value
  }
});