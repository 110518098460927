import _ from 'lodash';
import { createSelector } from 'reselect';
import { UnitCategory } from 'types';
import * as fromState from '../../../state/reducers';
import { ENTITY_TYPE } from '../../constants';

export default createSelector(
  [
    state => fromState.getAll<UnitCategory>(state, ENTITY_TYPE.UNIT_CATEGORY)
  ],
  (
    allUnitCategories
  ) => _.chain(allUnitCategories)
    .toPairs()
    .map(([id, unitCategory]) => ({
      key: unitCategory.id,
      text: unitCategory.name,
      value: unitCategory.id,
      icon: unitCategory.icon
    }))
    .sortBy('text')
    .value()
);