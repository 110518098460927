import _ from 'lodash';
import queryString from 'query-string';
import { ofType } from 'redux-observable';
import { merge, of } from 'rxjs';
import { catchError, filter, mergeMap, withLatestFrom } from 'rxjs/operators';
import { OPEN_ENTITY } from 'state/actions/entity';
import { setIsLoading } from 'state/actions/loading';
import { querySyncPush } from 'state/actions/navigation';
import * as fromState from 'state/reducers';
import { getUnitPaths } from 'state/selectors/entity';
import * as api from 'utils/api';
import { CHART_VIEW, CONTROLLER, ENTITY_TYPE, PAGE_URL, PLAYBACK_MODE } from 'utils/constants';
import { getTrendQuickRange, getTrendToDate, takeUntilAppReset, toPath } from 'utils/helpers';

export default (action$, state$) => action$.pipe(
  ofType(OPEN_ENTITY),
  filter(action => action.entityType === ENTITY_TYPE.REVIEW),
  withLatestFrom(state$),
  mergeMap(([action, state]) => merge(
    of(
      setIsLoading(true)
    ),
    api.getMultiple({
      controller: CONTROLLER.TREND,
      ids: _.map(action.payload.entity.trends, trend => trend.id),
      token: fromState.getUser(state).token
    }).pipe(
      takeUntilAppReset(action$),
      mergeMap(trends => {
        const regions = action.payload.entity.regions;
        const trendsById = _.chain(trends)
          .map(trend => [trend.id, trend])
          .fromPairs()
          .value();
        const trend = _.chain(action.payload.entity.trends)
          .find(reviewTrend =>
            !reviewTrend.skipped && !!_.find(
              regions,
              reviewRegion => !reviewTrend.regions[reviewRegion.name]
            ))
          .thru(reviewTrend => reviewTrend && _.get(trendsById, reviewTrend.id))
          .value() || trendsById[_.first(action.payload.entity.trends).id];
        return of(
          querySyncPush({
            pathname: toPath(PAGE_URL.PREDICTOR, {
              unitPath: getUnitPaths(state)[trend.unitId],
              groupId: trend.predictor
            }),
            search: queryString.stringify({
              time: getTrendToDate(trend),
              mode: PLAYBACK_MODE.REVIEW,
              isPlaying: false,
              window: getTrendQuickRange(trend),
              view: CHART_VIEW.TIME_SERIES,
              review: action.payload.entity.id,
              reviewTrend: trend.id
            })
          }),
          setIsLoading(false)
        );
      }),
      catchError(api.onError)
    )
  ))
);