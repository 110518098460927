import moment from 'moment';
import { DATE_FORMAT } from 'utils/constants';
import { SET_DATES } from '../../../../actions/chart';

function initialState() {
  const toDate = moment().utc().local();
  const fromDate = toDate.subtract(1, 'days');
  return fromDate.format(DATE_FORMAT);
}

export default (state: string = initialState(), action: any): string => {
  switch (action.type) {
    case SET_DATES:
      return action.payload.from;
    default:
      return state;
  }
};

export const getFromDate = (state: string): string => state;