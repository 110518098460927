import EntityEvents from '../../components/entity/EntityEvents';
import { connect } from 'react-redux';
import { unauthorised } from '../../state/actions/auth';
import {
  insert,
  deleteByIdFulfilled
} from '../../state/actions/entity';
import * as fromState from '../../state/reducers';

const mapStateToProps = state => {
  const user = fromState.getUser(state);
  return {
    token: user && user.token
  };
};

const mapDispatchToProps = {
  unauthorised,
  create: insert,
  update: insert,
  delete: deleteByIdFulfilled
};

export default connect(mapStateToProps, mapDispatchToProps)(EntityEvents);