import _ from 'lodash';
import { injectIntl } from 'react-intl';
import { Form } from 'semantic-ui-react';
import { ENTITY_FORM_ELEMENT_TYPE, INPUT_TYPE } from 'utils/constants';
import SelectEntityElementContainer from '../../containers/entity/SelectEntityElementContainer';

const ValueElement = ({
  id,
  error,
  scrollContextRef,
  elementType,
  elementProps,
  mapping,
  onChange
}) => {
  switch (elementType) {
    case ENTITY_FORM_ELEMENT_TYPE.SELECT_ENTITY: {
      return (
        <SelectEntityElementContainer
          error={error}
          value={mapping && mapping[id]}
          scrollContextRef={scrollContextRef}
          entityType={elementProps.entityType}
          onChange={value => onChange({
            ...mapping,
            [id]: value
          })}
          {...elementProps.valueFilterProps} />
      );
    }
    case ENTITY_FORM_ELEMENT_TYPE.INPUT: {
      return (
        <Form.Input
          error={error}
          value={mapping && mapping[id]}
          onChange={event => onChange({
            ...mapping,
            [id]: event.target.value
          })}
          type={elementProps.inputType === INPUT_TYPE.NUMBER
            ? 'number'
            : 'text'} />
      );
    }
    default: {
      return null;
    }
  }
};

/**
   * Use this element when input is required in a dictionary structure. That is,
   * a mapping from given IDs to a particular value type. For example, when adding
   * a unit type attribute, a sensor corresponding to that attribute must be provided
   * for each instance of the unit type. We are mapping the IDs of the unit type instances
   * to the IDs of the sensors.
   * @param {string[]} keyIds - The IDs of the key entities
   * @param {string[]} keyNames - Display names for the key entities
   * @param {object} valueFilterProps - Pass properties needed by the optionsSelector for
   * the `valueEntityType` in this object
   */
const MappingElement = injectIntl(({
  label,
  keyIds,
  keyNames,
  valueElementType,
  valueElementProps,
  mapping,
  scrollContextRef,
  errorFields,
  getErrorPath,
  onChange
}) => (
  <>
    <strong>{label}</strong>
    {_.chain(keyIds)
      .zip(keyNames)
      .map(([id, name], index) => (
        <div key={id}>
          <em>{name}</em>
          <ValueElement
            id={id}
            error={_.includes(errorFields, getErrorPath(index))}
            index={index}
            scrollContextRef={scrollContextRef}
            elementType={valueElementType}
            elementProps={valueElementProps}
            mapping={mapping}
            onChange={onChange} />
        </div>
      ))
      .value()
    }
  </>
));

MappingElement.displayName = 'MappingElement';

export default MappingElement;