import { SET_TREND_IDS, APPEND_TREND_IDS } from '../../actions/chart';
import { DELETE_BY_ID_FULFILLED } from '../../actions/entity';
import _ from 'lodash';

export default (state = [], action) => {
  switch (action.type) {
    case DELETE_BY_ID_FULFILLED:
      return _.without(state, action.payload.id);
    case SET_TREND_IDS:
      return action.payload.ids;
    case APPEND_TREND_IDS:
      return [...state, ...action.payload.ids];
    default:
      return state;
  }
};

export const getTrendIds = state => state;