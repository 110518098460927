import _ from 'lodash';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Button, Checkbox, Icon, Input, Table } from 'semantic-ui-react';
import CopyButtonContainer from '../../../containers/chart/CopyButtonContainer';
import { CHART_TYPE, SELECTION_TYPE } from '../../../utils/constants';
import messages from './ChartKey.messages';
import styles from './ChartKey.styles';
import ChartKeyEditButtonGroupPopup from './ChartKeyEditButtonGroupPopup';

const ChartKeyRow = injectIntl(({
  item,
  editingItemId,
  editText,
  isSaving,
  index,
  deleteDisabled,
  chartType,
  showKeyColours,
  onSelect,
  onSetEditingId,
  onSetEditText,
  onRemove,
  onEdit,
  onHoverItem,
  intl: {
    formatMessage
  }
}) => (
  <Table.Row
    key={item.id}
    onClick={() => onSelect(item.id, item.selectionType, !item.selected)}
    style={styles.itemGroupTableRow}>
    <Table.Cell width={2}>
      <Checkbox checked={item.selected} />
      {showKeyColours && (
        <Icon
          name='circle'
          style={styles.keyColourIcon(item.colour)} />
      )}
    </Table.Cell>
    <Table.Cell width={10}>
      {item.id === editingItemId && (
        <Input
          size='mini'
          autoFocus
          value={editText}
          placeholder={formatMessage(messages['functionSensor.description.placeholder'])}
          onClick={e => e.stopPropagation()}
          onBlur={() => {
            onEdit(index, editText);
          }}
          onKeyDown={e => e.keyCode === 13 && e.target.blur()}
          onChange={(e, { value }) => {
            e.stopPropagation();
            onSetEditText(value);
          }} />
      )}
      {item.id !== editingItemId && item.title && (
        <strong style={styles.rowTitle}>{item.title}</strong>
      )}
      {item.description && (
        <p style={styles.rowDescription}>{item.description}</p>
      )}
    </Table.Cell>
    <Table.Cell width={4}>
      <Button.Group
        size='mini'
        floated='right'
        vertical>
        <Button
          icon='eye'
          size='mini'
          floated='right'
          primary
          disabled={!item.selected}
          onClick={e => e.stopPropagation()}
          onMouseOver={() => onHoverItem(item.id)}
          onMouseOut={() => onHoverItem(null)}
          style={styles.hoverButton} />
        {item.editable && (
          <ChartKeyEditButtonGroupPopup
            item={item}
            editingItemId={editingItemId}
            isSaving={isSaving}
            deleteDisabled={deleteDisabled}
            index={index}
            chartType={chartType}
            onSelect={onSelect}
            onSetEditingId={onSetEditingId}
            onSetEditText={onSetEditText}
            onRemove={onRemove} />
        )}
        {!item.editable && item.copyText && (
          <CopyButtonContainer
            text={item.copyText}
            chartType={chartType} />
        )}
      </Button.Group>
    </Table.Cell>
  </Table.Row>
));

ChartKeyRow.propTypes = {
  item: PropTypes.exact({
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    description: PropTypes.string,
    colour: PropTypes.string,
    selected: PropTypes.bool.isRequired,
    selectionType: PropTypes.oneOf(_.values(SELECTION_TYPE)),
    copyText: PropTypes.string,
    editable: PropTypes.bool.isRequired,
    removable: PropTypes.bool.isRequired
  }),
  editingItemId: PropTypes.string,
  editText: PropTypes.string,
  isSaving: PropTypes.bool.isRequired,
  chartType: PropTypes.oneOf(_.values(CHART_TYPE)),
  showKeyColours: PropTypes.bool,
  index: PropTypes.number.isRequired,
  deleteDisabled: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  onSetEditingId: PropTypes.func.isRequired,
  onSetEditText: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onHoverItem: PropTypes.func.isRequired
};

ChartKeyRow.displayName = 'ChartKeyRow';

export default ChartKeyRow;