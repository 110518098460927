import { COLOUR } from '../../../utils/constants';

export default {
  deckHeader: {
    background: COLOUR.DARK_GREY,
    display: 'flex',
    justifyContent: 'space-between',
    height: '50px',
    lineHeight: '50px',
    textAlign: 'center',
    border: '1px solid rgb(85, 85, 85)'
  },
  deckHeaderLeft: {
    display: 'flex',
    justifyContent: 'flex-start'
  },
  deckHeaderRight: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  deckHeaderButton: {
    margin: '5px',
    marginLeft: 'auto'
  },
  deckCollapseIcon: {
    marginLeft: '10px'
  }
};