import _ from 'lodash';
import createCachedSelector from 're-reselect';
import { getModelOutputName, getModelOutputUnitsOfMeasurement } from 'utils/helpers';
import { ENTITY_PROPERTY } from 'utils/constants';
import { getInputSensors } from '../getInputSensors';
import { getModel } from '../getModel';
import { getOutputs } from '../getOutputs';

export const getOutputProperties = createCachedSelector(
  [
    getOutputs,
    getModel,
    getInputSensors
  ],
  (outputs, model, inputSensors) => {
    if (!model) {
      return _.chain(outputs)
        .map(output => [
          output,
          {
            unitsOfMeasurement: '',
            valueType: null
          }
        ])
        .fromPairs()
        .value();
    };

    const outputProperties = _.chain(outputs)
      .map(output => [
        output,
        {
          ..._.chain(model.outputs)
            .find([ENTITY_PROPERTY.MODEL.OUTPUT.NAME, output])
            .pick([
              ENTITY_PROPERTY.MODEL.OUTPUT.VALUE_TYPE,
              ENTITY_PROPERTY.MODEL.OUTPUT.ENUMERATION
            ])
            .value(),
          name: getModelOutputName(model, output),
          unitsOfMeasurement: getModelOutputUnitsOfMeasurement(
            model,
            output,
            inputSensors
          )
        }
      ])
      .fromPairs()
      .value();

    return outputProperties;
  }
)(
  (state, chartType) => chartType
);