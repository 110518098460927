import { AUTH_TYPE, PAGE_URL } from '../../utils/constants';
import { defineMessages, injectIntl } from 'react-intl';
import AuthFormContainer from './AuthFormContainer';
import { Button, Divider } from 'semantic-ui-react';
import QuerySyncLink from '../../containers/navigation/QuerySyncLink';

const messages = defineMessages({
  'header.text': {
    id: 'registerForm.header.text',
    defaultMessage: 'Register'
  },
  'organisation.placeholder': {
    id: 'registerForm.organisation.placeholder',
    defaultMessage: 'Organisation'
  },
  'organisation.label': {
    id: 'registerForm.organisation.label',
    defaultMessage: 'Organisation'
  },
  'name.placeholder': {
    id: 'registerForm.name.placeholder',
    defaultMessage: 'Name'
  },
  'name.label': {
    id: 'registerForm.name.label',
    defaultMessage: 'Name'
  },
  'email.placeholder': {
    id: 'registerForm.email.placeholder',
    defaultMessage: 'Email Address'
  },
  'email.label': {
    id: 'registerForm.email.label',
    defaultMessage: 'Email Address'
  },
  'password.placeholder': {
    id: 'registerForm.password.placeholder',
    defaultMessage: 'Password'
  },
  'password.label': {
    id: 'registerForm.password.label',
    defaultMessage: 'Password'
  },
  'performButton.text': {
    id: 'registerForm.performButton.text',
    defaultMessage: 'Register'
  },
  'resendButton.text': {
    id: 'registerForm.resendButton.text',
    defaultMessage: 'Resend confirmation email'
  }
});

export default injectIntl(({
  intl: {
    formatMessage
  }
}) => (
  <AuthFormContainer
    authType={AUTH_TYPE.REGISTER}
    performIcon='signup'
    fields={[
      {
        icon: 'building',
        key: 'organisation'
      },
      {
        icon: 'user',
        key: 'name'
      },
      {
        icon: 'mail',
        key: 'email'
      },
      {
        icon: 'key',
        key: 'password',
        type: 'password'
      }
    ]}
    messages={messages}>
    <Divider />
    <Button
      as={QuerySyncLink}
      to={PAGE_URL.RESEND}
      content={formatMessage(messages['resendButton.text'])}
      fluid
      color='grey'
      size='tiny' />
  </AuthFormContainer>
));