import quickRange, * as fromQuickRange from './quickRange';
import fromDate, * as fromFromDate from './fromDate';
import toDate, * as fromToDate from './toDate';
import { combineReducers } from 'redux';
import { RootState } from 'index';

export default combineReducers({
  quickRange,
  fromDate,
  toDate
});

export const getQuickRange = (state: RootState): string => fromQuickRange.getQuickRange(state.quickRange);
export const getFromDate = (state: RootState): string => fromFromDate.getFromDate(state.fromDate);
export const getToDate = (state: RootState): string => fromToDate.getToDate(state.toDate);