import _ from 'lodash';
import createCachedSelector from 're-reselect';
import * as fromState from '../../../state/reducers';
import { getFunctionAlarmRanges, getFunctionDescriptions, getFunctionDetails, getFunctionSensorIds, getFunctionSensors, getFunctionEnumerations } from '../../../state/selectors/chart';
import { SELECTION_TYPE } from '../../../utils/constants';

export default createCachedSelector(
  [
    getFunctionSensorIds,
    _.partialRight(fromState.getSelected, SELECTION_TYPE.FUNCTION),
    getFunctionSensors,
    getFunctionDescriptions,
    getFunctionAlarmRanges,
    getFunctionDetails,
    getFunctionEnumerations
  ],
  (
    functions,
    selectedFunctions,
    functionSensors,
    functionDescriptions,
    functionAlarmRanges,
    functionDetails,
    functionEnumerations
  ) => _.chain(functions)
    .filter(id => functionSensors[id])
    .map(id => ({
      id,
      name: functionDescriptions[id],
      description: functionSensors[id].name,
      ranges: functionAlarmRanges[id],
      unitsOfMeasurement: functionSensors[id].unitsOfMeasurement,
      valueType: functionSensors[id].valueType,
      selected: _.includes(selectedFunctions, id),
      min: functionDetails[id].min,
      max: functionDetails[id].max,
      value: functionEnumerations[id]
        ? functionEnumerations[id].states[functionDetails[id].value]
        : functionDetails[id].value,
      noData: functionDetails[id].noData,
      severity: functionDetails[id].severity,
      scale: 1
    }))
    .value()
)(
  (state, chartType) => chartType
);