import { connect } from 'react-redux';
import { authFormValueChanged, performAuth, setShowPassword } from '../../state/actions/auth';
import * as fromState from '../../state/reducers';
import AuthForm from '../../components/auth/AuthForm';
import { bindActionCreators } from 'redux';

const mapStateToProps = (state, { authType }) => ({
  values: fromState.getAuthFormValues(state, authType),
  isLoading: fromState.getAuthIsLoading(state, authType),
  errorCodes: fromState.getAuthErrorCodes(state, authType),
  errorKeys: fromState.getAuthErrorKeys(state, authType),
  showPassword: fromState.getShowPassword(state, authType),
  message: fromState.getAuthMessage(state)
});

const mapDispatchToProps = (dispatch, { authType }) => bindActionCreators({
  onChange: (key, value) => authFormValueChanged(authType, key, value),
  perform: () => performAuth(authType),
  onChangeShowPassword: value => setShowPassword(authType, value)
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AuthForm);