import _ from 'lodash';
import { Fragment } from 'react';
import { injectIntl } from 'react-intl';
import { Button, Card, Label, Icon } from 'semantic-ui-react';
import CopyButtonContainer from '../../../containers/chart/CopyButtonContainer';
import { VALUE_TYPE, NEUTRAL_SEVERITY } from '../../../utils/constants';
import BarGauge from './BarGauge';
import messages from './GaugeCard.messages';
import ValueStatistic from './ValueStatistic';
import styles from './GaugeCard.styles';
import { getColourFromSeverity, getIconFromSeverity, formatValueType } from '../../../utils/helpers';

const GaugeCard = injectIntl(({
  name,
  min,
  max,
  value,
  noData,
  description,
  ranges,
  selected,
  severity,
  onClick,
  unitsOfMeasurement,
  valueType,
  scale = 1,
  chartType,
  intl: {
    formatMessage
  }
}) => {
  let [gaugeMin, gaugeMax] = [min, max];
  if (_.isEmpty(ranges)) {
    ranges = [{
      min,
      max,
      severity: NEUTRAL_SEVERITY
    }];
  }

  const fill = getColourFromSeverity(severity);
  const className = `severity-${noData ? 'noData' : severity}`;
  return (
    <Card
      link
      onClick={onClick}
      style={styles.card}>
      <Card.Content style={styles.cardGaugeContent}>
        <Label
          style={styles.cardHeaderLabel(fill)}
          attached='top'
          size='tiny'
          className={className}>
        </Label>
        {noData
          ? (
            <div
              style={styles.noDataWrapperDiv}
              className='attached'>
              <strong>
                {formatMessage(messages['noData'])}
              </strong>
            </div>
          )
          : (
            valueType === VALUE_TYPE.FLOAT
              ? (
                <Fragment>
                  <div
                    className='attached'
                    style={styles.barGaugeWrapperDiv}>
                    <BarGauge
                      scale={1}
                      min={gaugeMin}
                      max={gaugeMax}
                      value={value}
                      ranges={ranges}
                      minNeedleWidth={0.02}
                      cursor='pointer' />
                  </div>
                  <ValueStatistic
                    scale={scale}
                    value={value}
                    unitsOfMeasurement={unitsOfMeasurement} />
                </Fragment>
              )
              : (
                <div
                  style={styles.stringValueDiv}
                  className='attached'>
                  <strong>
                    {formatValueType(value, valueType, unitsOfMeasurement)}
                  </strong>
                </div>
              )
          )}
      </Card.Content>
      <Card.Content
        style={styles.cardContent(fill)}
        className={className}>
        <Label
          corner
          icon={(
            <Icon
              name={getIconFromSeverity(severity)}
              style={styles.severityLabel(fill)} />
          )} />
        <Card.Header style={styles.cardHeader}>
          {name}
        </Card.Header>
        <Card.Meta style={styles.cardMeta}>
          {description}
        </Card.Meta>
      </Card.Content>
      <Card.Content
        extra
        style={styles.cardExtraContent(fill)}>
        <Button.Group
          floated='right'
          size='mini'>
          <Button
            positive={selected}
            icon={selected ? 'check square' : 'square outline'}
            style={styles.cardSelectButton}
            floated='right' />
          {description && (
            <CopyButtonContainer
              text={description}
              floated='right'
              chartType={chartType}
              size='mini' />
          )}
        </Button.Group>
      </Card.Content>
    </Card>
  );
});

GaugeCard.displayName = 'GaugeCard';

export default GaugeCard;