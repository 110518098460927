import { defineMessages } from 'react-intl';

export default defineMessages({
  'colourMode': {
    id: 'explorerPage.colourMode',
    defaultMessage: 'Colour Mode'
  },
  'default': {
    id: 'explorerPage.default',
    defaultMessage: 'Default'
  },
  'time': {
    id: 'explorerPage.time',
    defaultMessage: 'Time'
  },
  'sensor': {
    id: 'explorerPage.sensor',
    defaultMessage: 'Sensor'
  },
  'opacity': {
    id: 'explorerPage.opacity',
    defaultMessage: 'Opacity'
  },
  'markerSize': {
    id: 'explorerPage.markerSize',
    defaultMessage: 'Marker Size'
  }
});