import _ from 'lodash';
import createCachedSelector from 're-reselect';
import * as fromState from '../../../reducers';
import { getSensors } from '../getSensors';
import { getInputs } from '../getInputs';
import { Sensor } from 'types';
import { CHART_TYPE, ENTITY_TYPE } from 'utils/constants';

const getAllSensors = state => fromState.getAll<Sensor>(state, ENTITY_TYPE.SENSOR);

export const getInputSensors = createCachedSelector(
  [
    getSensors,
    getInputs,
    getAllSensors
  ],
  (sensorIds, inputs, allSensors) => _.chain(sensorIds)
    .zip(inputs)
    .map(([sensorId, input]) => sensorId ? [input, allSensors[sensorId]] : null)
    .fromPairs()
    .value()
)(
  (state, chartType: CHART_TYPE) => chartType
);