import { combineReducers } from 'redux';
import errorResponse, * as fromErrorResponse from './errorResponse';
import formValues, * as fromFormValues from './formValues';
import isLoading, * as fromIsLoading from './isLoading';
import showPassword, * as fromShowPassword from './showPassword';
import errorKeys, * as fromErrorKeys from './errorKeys';

const authFormReducer = combineReducers({
  errorResponse,
  formValues,
  isLoading,
  showPassword,
  errorKeys
});

export default authType => (state, action) => {
  if (action.authType && action.authType !== authType) {
    return state;
  }

  return authFormReducer(state, action);
};

export const getErrorResponse = state => fromErrorResponse.getErrorResponse(state.errorResponse);
export const getErrorCodes = state => fromErrorResponse.getErrorCodes(state.errorResponse);
export const getValues = state => fromFormValues.getValues(state.formValues);
export const getIsLoading = state => fromIsLoading.getIsLoading(state.isLoading);
export const getShowPassword = state => fromShowPassword.getShowPassword(state.showPassword);
export const getErrorKeys = state => fromErrorKeys.getErrorKeys(state.errorKeys);