import { AUTH_FORM_VALUE_CHANGED, PERFORM_AUTH_FULFILLED, PERFORM_AUTH_ERROR } from '../../../actions/auth';
import _ from 'lodash';

export default (state = {}, action) => {
  switch (action.type) {
    case AUTH_FORM_VALUE_CHANGED:
      return {
        ...state,
        [action.payload.key]: action.payload.value
      };
    case PERFORM_AUTH_FULFILLED:
      return {};
    case PERFORM_AUTH_ERROR:
      return _.omitBy(state, (val, key) => key === 'password');
    default:
      return state;
  }
};

export const getValues = state => state;