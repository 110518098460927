import AddEntityModalContainer from 'containers/entity/AddEntityModalContainer';
import _ from 'lodash';
import { defineMessages, injectIntl } from 'react-intl';
import { Header, Segment } from 'semantic-ui-react';
import IsInRole from '../../../containers/auth/IsInRole';
import DeleteEntityModalContainer from '../../../containers/entity/DeleteEntityModalContainer';
import EntitySearchFormContainer from '../../../containers/entity/EntitySearchFormContainer';
import { ENTITY_MODAL, INVERTED_THEME, ROLE } from '../../../utils/constants';
import AnimatedButton from '../../shared/AnimatedButton';
import styles from './EntityPage.styles';
import entityConfig from 'utils/entities';

const messages = defineMessages({
  'search.header': {
    id: 'entityPage.search.header',
    defaultMessage: 'SEARCH'
  },
  'searchButton.text': {
    id: 'entityPage.searchButton.text',
    defaultMessage: 'Search'
  },
  'clearButton.text': {
    id: 'entityPage.clearButton.text',
    defaultMessage: 'Clear'
  },
  'searchAndDeleteButton.text': {
    id: 'entityPage.searchAndDeleteButton.text',
    defaultMessage: 'Search And Delete'
  }
});

const SearchSegment = injectIntl(({
  entityType,
  parentId,
  isLoading,
  clearButtonDisabled,
  searchAddEntityTypes,
  formQuery,
  onToggleModal,
  onSearch,
  onClearSearch,
  onSearchAndDelete,
  onToggleOtherEntityModal,
  intl: {
    formatMessage
  }
}) => (
  <Segment.Group>
    <Header
      attached='top'
      inverted={INVERTED_THEME}>
      {formatMessage(messages['search.header'])}
    </Header>
    <Segment
      inverted
      style={styles.searchSegment}>
      <EntitySearchFormContainer
        entityType={entityType}
        parentId={parentId} />
      <AnimatedButton
        text={formatMessage(messages['searchButton.text'])}
        icon='search'
        positive
        loading={isLoading}
        floated='right'
        onClick={onSearch} />
      <AnimatedButton
        text={formatMessage(messages['clearButton.text'])}
        icon='dont'
        loading={isLoading}
        negative
        floated='right'
        disabled={clearButtonDisabled}
        onClick={onClearSearch} />
      {_.map(searchAddEntityTypes, searchAddEntityType => (
        <AddEntityModalContainer
          key={searchAddEntityType.entityType}
          trigger={(
            <AnimatedButton
              floated='right'
              text={formatMessage(searchAddEntityType.message)}
              icon='add'
              primary
              onClick={() => onToggleOtherEntityModal(
                searchAddEntityType.entityType,
                ENTITY_MODAL.ADD
              )}
              loading={isLoading} />
          )}
          entityType={searchAddEntityType.entityType}
          createEntity={(values, parent) =>
            entityConfig[searchAddEntityType.entityType].createEntity(
              {
                ...values,
                query: formQuery
              },
              parent
            )
          } />
      ))}
      <IsInRole role={ROLE.ADMIN}>
        <DeleteEntityModalContainer
          entityType={entityType}
          onDelete={onSearchAndDelete} />
        <AnimatedButton
          text={formatMessage(messages['searchAndDeleteButton.text'])}
          icon='trash'
          loading={isLoading}
          negative
          floated='left'
          onClick={() => onToggleModal(ENTITY_MODAL.DELETE)} />
      </IsInRole>
      <span style={styles.searchLayoutSpan} />
    </Segment>
  </Segment.Group>
));

SearchSegment.displayName = 'SearchSegment';

export default SearchSegment;