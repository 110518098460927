import { SET_TREND_TIMELINE_HEIGHT } from 'state/actions/chart';

export default (state = 50, action): number => {
  switch (action.type) {
    case SET_TREND_TIMELINE_HEIGHT:
      return action.payload.value;
    default:
      return state;
  }
};

export const getTrendTimelineHeight = (state: number): number => state;