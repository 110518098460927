import { AUTH_TYPE } from '../../utils/constants';
import { defineMessages } from 'react-intl';
import AuthFormContainer from './AuthFormContainer';

const messages = defineMessages({
  'header.text': {
    id: 'joinForm.header.text',
    defaultMessage: 'Join Organisation'
  },
  'name.placeholder': {
    id: 'joinForm.name.placeholder',
    defaultMessage: 'Name'
  },
  'name.label': {
    id: 'joinForm.name.label',
    defaultMessage: 'Name'
  },
  'password.placeholder': {
    id: 'joinForm.password.placeholder',
    defaultMessage: 'Password'
  },
  'password.label': {
    id: 'joinForm.password.label',
    defaultMessage: 'Password'
  },
  'performButton.text': {
    id: 'joinForm.performButton.text',
    defaultMessage: 'Confirm'
  }
});

export default function JoinFormContainer() {
  return (
    <AuthFormContainer
      authType={AUTH_TYPE.JOIN}
      performIcon='signup'
      fields={[
        {
          icon: 'user',
          key: 'name'
        },
        {
          icon: 'key',
          key: 'password',
          type: 'password'
        }
      ]}
      messages={messages}>
    </AuthFormContainer>
  );
}