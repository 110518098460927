import { ADD_CONDITION, REMOVE_CONDITION, UPDATE_CONDITION, SET_FORM_VALUES } from '../../../../actions/entity';
import _ from 'lodash';
import { TYPE } from 'utils/constants';

const compatibleConditionTypeGroups = [
  ['greaterThan', 'lessThan', 'equalsFloat', 'greaterThanParameter', 'lessThanParameter', 'equalsParameter'],
  ['lessThanDaysAway', 'moreThanDaysAway', 'lessThanDaysAgo', 'moreThanDaysAgo'],
  ['greaterThanDate', 'lessThanDate'],
  ['equals', 'containsCaseInsensitive', 'containsCaseSensitive']
];
const clearSettingsOnTypeChange = (fromType, toType) => {
  return !_.find(
    compatibleConditionTypeGroups,
    group => _.includes(group, fromType) && _.includes(group, toType)
  );
};

export default (state = [], action) => {
  switch (action.type) {
    case ADD_CONDITION:
      return [...state || [], action.payload.condition];
    case REMOVE_CONDITION:
      return [...(state || []).slice(0, action.payload.index), ...(state || []).slice(action.payload.index + 1)];
    case UPDATE_CONDITION:
      return [
        ...(state || []).slice(0, action.payload.index),
        {
          ...(state || [])[action.payload.index],
          ...action.payload.isSetting
            ? {
              settings: {
                ...(state || [])[action.payload.index].settings,
                [action.payload.key]: action.payload.value
              }
            }
            : {
              [action.payload.key]: action.payload.value,
              settings: (action.payload.key === TYPE
                && clearSettingsOnTypeChange((state || [])[action.payload.index].type, action.payload.value))
                ? {}
                : (state || [])[action.payload.index].settings
            }
        },
        ...(state || []).slice(action.payload.index + 1)
      ];
    case SET_FORM_VALUES:
      return _.get(action, 'payload.formValues.conditions.list') || null;
    default:
      return state;
  }
};

export const getList = state => state;