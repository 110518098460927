import _ from 'lodash';
import { Fragment } from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { Label } from 'semantic-ui-react';
import AnimatedButton from '../shared/AnimatedButton';

const messages = defineMessages({
  'actionStateCell.phalanx.createWork.viewButton.text': {
    id: 'actionStateCell.phalanx.createWork.viewButton.text',
    defaultMessage: 'View'
  },
  'actionState.phalanx.createWork.new': {
    id: 'actionState.phalanx.createWork.new',
    defaultMessage: 'New'
  },
  'actionState.phalanx.createWork.created': {
    id: 'actionState.phalanx.createWork.created',
    defaultMessage: 'Work Created'
  },
  'actionState.phalanx.createWork.assigned': {
    id: 'actionState.phalanx.createWork.assigned',
    defaultMessage: 'Work Assigned'
  },
  'actionState.phalanx.createWork.completed': {
    id: 'actionState.phalanx.createWork.completed',
    defaultMessage: 'Work Completed'
  },
  'actionState.phalanx.createWork.failed': {
    id: 'actionState.phalanx.createWork.failed',
    defaultMessage: 'Failed To Create Work'
  }
});

const colours = {
  0: 'yellow',
  1: 'green',
  2: 'red'
};

const ActionStateCell = injectIntl(({
  actionConnector,
  actionMapping,
  action,
  intl: {
    formatMessage
  }
}) => actionConnector && actionMapping && action && (
  <Fragment>
    <Label color={colours[action.validationStatus]}>
      {formatMessage(messages[`actionState.${actionConnector.type}.${actionMapping.type}.${action.state.state}`])}
    </Label>
    {actionConnector.type === 'phalanx' && actionMapping.type === 'createWork' && !_.includes(['new', 'failed'], action.state.state) && (
      <AnimatedButton
        as='a'
        icon='external'
        text={formatMessage(messages['actionStateCell.phalanx.createWork.viewButton.text'])}
        size='mini'
        positive
        floated='right'
        href={`${actionConnector.settings.portalUrl}#/work/${_.first(action.state.history).settings.workId}`}
        target='_blank' />
    )}
  </Fragment>
));

ActionStateCell.displayName = 'ActionStateCell';

export default ActionStateCell;