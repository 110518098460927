import { SET_ALARM_MODAL_IS_OPEN } from '../../actions/alarm';

export default (state = false, action) => {
  switch (action.type) {
    case SET_ALARM_MODAL_IS_OPEN:
      return action.payload.open;
    default:
      return state;
  };
};

export const getModalIsOpen = state => state;