import UnmuteModal from '../../components/dashboard/UnmuteModal';
import { connect } from 'react-redux';
import * as fromState from '../../state/reducers';
import { setMuteModal, saveMutedUntil } from '../../state/actions/dashboard';

const mapStateToProps = state => ({
  open: fromState.getMuteModalOpen(state, 'unmute'),
  id: fromState.getMuteModalId(state, 'unmute'),
  isSaving: fromState.getMuteModalIsSaving(state, 'unmute')
});

const mapDispatchToProps = {
  onClose: () => setMuteModal('unmute', false),
  onUnmute: id => saveMutedUntil(id, null)
};

export default connect(mapStateToProps, mapDispatchToProps)(UnmuteModal);