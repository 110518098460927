import { SET_FUNCTION_SENSORS } from '../../actions/chart';
import { withLatestFrom, map } from 'rxjs/operators';
import { edit } from '../../actions/entity';
import * as chartSelectors from '../../selectors/chart';
import _ from 'lodash';
import { ofType } from 'redux-observable';
import { ENTITY_TYPE } from '../../../utils/constants';
import * as fromState from '../../reducers';

export default (action$, state$) => action$.pipe(
  ofType(SET_FUNCTION_SENSORS),
  withLatestFrom(state$),
  map(([action, state]) => {
    const predictor = chartSelectors.getPredictor(state, action.chartType);
    const functionMappings = predictor.functionMappings || [];
    const editedFunctionMappings = [
      ...functionMappings, 
      ..._.map(action.payload.ids, id => ({
        sensor: id,
        function: fromState.getById(state, ENTITY_TYPE.SENSOR, id).description || null
      }))
    ];
    return edit('predictor', {
      ...predictor,
      functionMappings: editedFunctionMappings 
    });
  })
);