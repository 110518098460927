import _ from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { pageChanged } from '../../state/actions/entity';
import EntityPagination from '../../components/entity/EntityPagination';
import * as fromState from '../../state/reducers';
import * as entitySelectors from '../../state/selectors/entity';

const mapStateToProps = (state, { entityType, page }) => ({
  total: fromState.getTotal(state, entityType),
  pageSize: fromState.getPageSize(state, entityType),
  query: entitySelectors.getSearchQuery(state, entityType),
  page
});

const mapDispatchToProps = (dispatch, { entityType }) => {
  return bindActionCreators(_.mapValues({
    pageChanged
  }, actionCreator => _.partial(actionCreator, entityType)), dispatch);
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EntityPagination));