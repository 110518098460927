import { FETCH_PAGE, FETCH_PAGE_FULFILLED, SET_ENTITY_IS_LOADING } from '../../actions/entity';

export default (state = false, action) => {
  switch (action.type) {
    case FETCH_PAGE:
      return true;
    case FETCH_PAGE_FULFILLED:
      return false;
    case SET_ENTITY_IS_LOADING:
      return action.payload.loading;
    default:
      return state;
  }
};

export const getIsLoading = state => state;