import { CHANGE_VIEW } from '../../actions/chart';
import { CHART_VIEW } from '../../../utils/constants';

export default (state: CHART_VIEW = CHART_VIEW.CARDS, action: any): CHART_VIEW => {
  switch (action.type) {
    case CHANGE_VIEW:
      return action.payload.view;
    default:
      return state;
  }
};

export const getView = (state: CHART_VIEW): CHART_VIEW => state;