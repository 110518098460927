import _ from 'lodash';
import createCachedSelector from 're-reselect';
import { ENTITY_PROPERTY, SELECTION_TYPE } from '../../../utils/constants';
import * as fromState from '../../../state/reducers';
import * as chartSelectors from '../../../state/selectors/chart';

export default createCachedSelector(
  [
    _.partialRight(fromState.getSelected, SELECTION_TYPE.INPUT),
    chartSelectors.getInputs,
    chartSelectors.getInputSensors,
    chartSelectors.getColours
  ],
  (
    selectedInputs,
    inputs,
    inputSensors,
    colours
  ) => _.map(inputs, input => ({
    id: input,
    title: _.get(inputSensors[input], ENTITY_PROPERTY.SENSOR.NAME),
    description: _.get(inputSensors[input], ENTITY_PROPERTY.SENSOR.DESCRIPTION),
    colour: colours[input],
    selected: _.includes(selectedInputs, input),
    selectionType: SELECTION_TYPE.INPUT,
    copyText: _.get(inputSensors[input], ENTITY_PROPERTY.SENSOR.NAME),
    editable: false,
    removable: false
  }))
)(
  (state, chartType) => chartType
);