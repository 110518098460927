export const SET_MULTIPLE_LATEST = 'data/SET_MULTIPLE_LATEST';
export const setMultipleLatest = (chartType, { sensors, outputs }) => ({
  type: SET_MULTIPLE_LATEST,
  chartType,
  payload: {
    sensors,
    outputs
  }
});

export const APPEND_MULTIPLE_RANGES = 'data/APPEND_MULTIPLE_RANGES';
export const appendMultipleRanges = (chartType, sensors, group, outputs, dropBeforeTimestamp, toDate) => ({
  type: APPEND_MULTIPLE_RANGES,
  chartType,
  payload: {
    sensors,
    group,
    outputs,
    dropBeforeTimestamp,
    toDate
  }
});

export const PREPEND_MULTIPLE_RANGES = 'data/PREPEND_MULTIPLE_RANGES';
export const prependMultipleRanges = (chartType, sensors, group, outputs, fromDate, dropAfterTimestamp) => ({
  type: PREPEND_MULTIPLE_RANGES,
  chartType,
  payload: {
    sensors,
    group,
    outputs,
    fromDate,
    dropAfterTimestamp
  }
});

export const SET_MULTIPLE_RANGES = 'data/SET_MULTIPLE_RANGES';
export const setMultipleRanges = (chartType, sensors, group, outputs, fromDate, toDate) => ({
  type: SET_MULTIPLE_RANGES,
  chartType,
  payload: {
    sensors,
    group,
    outputs,
    fromDate,
    toDate
  }
});