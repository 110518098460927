export const SET_PLAYBACK_MODE = 'playback/SET_PLAYBACK_MODE';
export const setPlaybackMode = mode => ({
  type: SET_PLAYBACK_MODE,
  payload: {
    mode
  }
});

export const SET_IS_PLAYING = 'playback/SET_IS_PLAYING';
export const setIsPlaying = value => ({
  type: SET_IS_PLAYING,
  payload: {
    value
  }
});

export const INCREMENT_PLAYBACK_TIME = 'playback/INCREMENT_PLAYBACK_TIME';
export const incrementPlaybackTime = time => ({
  type: INCREMENT_PLAYBACK_TIME,
  payload: {
    time
  }
});

export const CHANGE_PLAYBACK_TIME = 'playback/CHANGE_PLAYBACK_TIME';
export const changePlaybackTime = time => ({
  type: CHANGE_PLAYBACK_TIME,
  payload: {
    time
  }
});

export const JUMP_TO_LIVE_PLAYBACK = 'playback/JUMP_TO_LIVE_PLAYBACK';
export const jumpToLivePlayback = () => ({
  type: JUMP_TO_LIVE_PLAYBACK,
  payload: {}
});

export const SET_PLAYBACK_SPEED = 'playback/SET_PLAYBACK_SPEED';
export const setPlaybackSpeed = speed => ({
  type: SET_PLAYBACK_SPEED,
  payload: {
    speed
  }
});

export const SET_REVIEW_ID = 'playback/SET_REVIEW_ID';
export const setReviewId = id => ({
  type: SET_REVIEW_ID,
  payload: {
    id
  }
});

export const SET_REVIEW_TREND_ID = 'playback/SET_REVIEW_TREND_ID';
export const setReviewTrendId = id => ({
  type: SET_REVIEW_TREND_ID,
  payload: {
    id
  }
});

export const CHANGE_REVIEW_TREND = 'playback/CHANGE_REVIEW_TREND';
export const changeReviewTrend = id => ({
  type: CHANGE_REVIEW_TREND,
  payload: {
    id
  }
});