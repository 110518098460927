import { injectIntl } from 'react-intl';
import { Statistic } from 'semantic-ui-react';
import { INVERTED_THEME } from '../../../utils/constants';
import messages from './GaugeCard.messages';
import styles from './GaugeCard.styles';
import { formatNumber } from '../TimeSeriesChart/TooltipTableRow';

const unitShorthand = {
  'mbar': ' mbar',
  'DEG C': '°C',
  'DEG. C': '°C',
  'bar': ' bar'
};

const MinMaxStatistic = injectIntl(({
  scale,
  min,
  max,
  unitsOfMeasurement,
  intl: {
    formatMessage
  }
}) => (
  <Statistic.Group
    size='mini'
    widths={2}>
    <Statistic
      inverted={INVERTED_THEME}
      size='mini'>
      <Statistic.Label>
        {formatMessage(messages['min.label'])}
      </Statistic.Label>
      <Statistic.Value style={styles.statisticValue}>
        {`${formatNumber(min, scale)}${unitShorthand[unitsOfMeasurement] || unitsOfMeasurement}`}
      </Statistic.Value>
    </Statistic>
    <Statistic
      inverted={INVERTED_THEME}
      size='mini'>
      <Statistic.Label>
        {formatMessage(messages['max.label'])}
      </Statistic.Label>
      <Statistic.Value style={styles.statisticValue}>
        {`${formatNumber(max, scale)}${unitShorthand[unitsOfMeasurement] || unitsOfMeasurement}`}
      </Statistic.Value>
    </Statistic>
  </Statistic.Group>
));

MinMaxStatistic.displayName = 'MinMaxStatistic';

export default MinMaxStatistic;