import _ from 'lodash';
import { createSelector } from 'reselect';
import { Sensor } from 'types';
import * as fromState from '../../../state/reducers';
import { ATTRIBUTE_SOURCE, ENTITY_TYPE } from '../../constants';

export default createSelector(
  [
    state => fromState.getAll<Sensor>(state, ENTITY_TYPE.SENSOR),
    (state, { parentId }) => parentId
  ],
  (allSensors, unitId) => ({
    attributes: _.chain(allSensors)
      .values()
      .filter(sensor => sensor.unitId === unitId)
      .map(sensor => ({
        id: sensor.id,
        text: sensor.name,
        description: sensor.description,
        valueType: sensor.valueType,
        enumeration: sensor.enumeration,
        unitsOfMeasurement: sensor.unitsOfMeasurement,
        source: ATTRIBUTE_SOURCE.SENSOR
      }))
      .value()
  })
);