import ChartPage from '../../components/chart/ChartPage';
import { connect } from 'react-redux';
import * as fromState from '../../state/reducers';
import { setCompareOpen, setCompareTrend } from '../../state/actions/chart';
import { ENTITY_TYPE } from '../../utils/constants';

const mapStateToProps = state => ({
  view: fromState.getView(state),
  compareOpen: fromState.getCompareOpen(state),
  compareTrend: fromState.getById(state, ENTITY_TYPE.TREND, fromState.getCompareTrend(state))
});

const mapDispatchToProps = {
  onClickCompareButton: setCompareOpen,
  onClickCloseCompare: () => setCompareTrend(null)
};

export default connect(mapStateToProps, mapDispatchToProps)(ChartPage);