import { defineMessages } from 'react-intl';

export default defineMessages({
  'addNew.text': {
    id: 'predictorBreadcrumbSection.addNew.text',
    defaultMessage: 'Add New'
  },
  'configurationButton.popupText': {
    id: 'predictorBreadcrumbSection.configurationButton.popupText',
    defaultMessage: 'Configure Alerts And Actions'
  },
  'editPredictorButton.popupText': {
    id: 'predictorBreadcrumbSection.editPredictorButton.popupText',
    defaultMessage: 'Edit Name'
  },
  'editModelButton.popupText': {
    id: 'predictorBreadcrumbSection.editModelButton.popupText',
    defaultMessage: 'View/Edit Model'
  }
});