import _ from 'lodash';
import { defineMessages } from 'react-intl';
import { createSelector } from 'reselect';
import uuid from 'uuid/v1';
import { DASHBOARD_VIEW, ENTITY_FORM_ELEMENT_TYPE, ENTITY_TYPE, ROLE, INPUT_TYPE, VALUE_TYPE } from '../../../utils/constants';
import { addUnitTypeParameter } from '../../../state/actions/entity';
import * as fromState from '../../../state/reducers';
import { getUnitTypeId } from '../../../state/selectors/dashboard';

const getValueMappingElementProps = createSelector(
  [
    state => fromState.getAll(state, ENTITY_TYPE.UNIT),
    state => fromState.getAll(state, ENTITY_TYPE.UNIT_TYPE_INSTANCE),
    state => getUnitTypeId(state)
  ],
  (allUnits, allUnitTypeInstances, unitTypeId) => {
    const unitTypeInstances = _.filter(allUnitTypeInstances, uti => uti.type === unitTypeId);
    const keyNames = _.map(unitTypeInstances, uti => _.find(allUnits, unit => unit.id === uti.unitId).name);

    return {
      keyIds: _.map(unitTypeInstances, 'id'),
      keyNames: keyNames,
      getErrorPath: index => `editedInstances[${index}]`,
      valueElementType: ENTITY_FORM_ELEMENT_TYPE.INPUT,
      valueElementProps: {
        inputType: INPUT_TYPE.NUMBER
      }
    };
  }
);

const messages = defineMessages({
  'deckHeader.parameters': {
    id: 'unitTypeParameter.deckHeader.parameters',
    defaultMessage: 'Parameters'
  },
  'form.name.label': {
    id: 'unitTypeParameter.form.name.label',
    defaultMessage: 'Name'
  },
  'form.name.placeholder': {
    id: 'unitTypeParameter.form.name.placeholder',
    defaultMessage: 'Name'
  },
  'form.description.label': {
    id: 'unitTypeParameter.form.description.label',
    defaultMessage: 'Description'
  },
  'form.description.placeholder': {
    id: 'unitTypeParameter.form.description.placeholder',
    defaultMessage: 'Description'
  },
  'form.unitType.label': {
    id: 'unitTypeParameter.form.unitType.label',
    defaultMessage: 'Unit Type'
  },
  'form.valueMappings.label': {
    id: 'unitTypeParameter.form.valueMappings.label',
    defaultMessage: 'For each existing instance, provide the value of this parameter:'
  }
});

export default {
  apiPath: 'unitTypeParameter',
  parentProperty: 'unitType',
  parentEntityType: ENTITY_TYPE.UNIT_TYPE,
  disableFetchTypes: true,
  fetchAllOnLoad: true,
  messages,
  viewRole: ROLE.VIEW_UNIT_TYPE,
  modifyRole: ROLE.MODIFY_UNIT_TYPE,
  form: [
    {
      name: 'name',
      type: 'input',
      errorPathPrefix: 'parameter.name'
    },
    {
      name: 'description',
      type: 'input',
      errorPathPrefix: 'parameter.description'
    },
    {
      name: 'unitType',
      type: ENTITY_FORM_ELEMENT_TYPE.SEARCH,
      searchEntityType: ENTITY_TYPE.UNIT_TYPE,
      errorPathPrefix: 'parameter.unitType',
      transformResults: unitType => ({
        key: unitType.id,
        text: unitType.name,
        description: unitType.description,
        value: unitType.id
      })
    },
    {
      name: 'valueMappings',
      type: ENTITY_FORM_ELEMENT_TYPE.MAPPING,
      errorPathPrefix: 'editedInstances',
      getComponentProps: getValueMappingElementProps
    }
  ],
  deckHeaderMessage: dashboardView => dashboardView === DASHBOARD_VIEW.UNIT_TYPES
    ? messages['deckHeader.parameters']
    : null,
  createAction: (values, parent) => addUnitTypeParameter(
    {
      id: uuid(),
      name: values.name,
      description: values.description,
      valueType: VALUE_TYPE.FLOAT,
      unitType: parent.id
    },
    values.valueMappings
  )
};