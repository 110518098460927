import { SET_ALARM_IS_SAVING } from '../../actions/alarm';

export default (state = false, action) => {
  switch (action.type) {
    case SET_ALARM_IS_SAVING:
      return action.payload.saving;
    default:
      return state;
  };
};

export const getIsSaving = state => state;