import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { DASHBOARD_VIEW, ENTITY_TYPE, ENTITY_MODAL, SEVERITY_COLOURS, PAGE_URL } from 'utils/constants';
import createCachedSelector from 're-reselect';
import * as fromState from 'state/reducers';
import DashboardCard from 'components/dashboard/DashboardCard';
import { toggleModal, deleteById } from 'state/actions/entity';
import { getUnitPaths } from 'state/selectors/entity';
import { toPath } from 'utils/helpers';

const mapStateToProps = createCachedSelector(
  [
    (state, { unitTypeInstance }) => unitTypeInstance,
    (state, { unitTypeInstance }) =>
      fromState.getById(state, ENTITY_TYPE.UNIT, unitTypeInstance.unitId),
    (state, { unitTypeInstance }) =>
      fromState.getById(state, ENTITY_TYPE.UNIT_TYPE, unitTypeInstance.type),
    state => fromState.getDashboardView(state),
    getUnitPaths
  ],
  (unitTypeInstance, unit, unitType, view, unitPaths) => {
    const linkTo = view === DASHBOARD_VIEW.UNIT_TYPES
      ? ({
        pathname: toPath(PAGE_URL.UNIT, {
          unitPath: unitPaths[unit.id]
        }),
        search: `?dashboard=${DASHBOARD_VIEW.UNITS}`
      })
      : ({
        pathname: toPath(PAGE_URL.UNIT_TYPE, {
          unitTypeId: unitType.id
        }),
        search: `?dashboard=${DASHBOARD_VIEW.UNIT_TYPES}`
      });

    return {
      id: unitTypeInstance.id,
      linkTo,
      name: view === DASHBOARD_VIEW.UNIT_TYPES ? unitPaths[unit.id] : unitType.name,
      canMute: false,
      canDelete: true,
      canConfigure: false,
      entityType: ENTITY_TYPE.UNIT_TYPE_INSTANCE,
      fill: SEVERITY_COLOURS[0]
    };
  }
)(
  (state, { unitTypeInstance }) => unitTypeInstance.id
);

const mapDispatchToProps = (dispatch, { unitTypeInstance }) => bindActionCreators({
  onClickDelete: () => toggleModal(ENTITY_TYPE.UNIT_TYPE_INSTANCE, ENTITY_MODAL.DELETE, unitTypeInstance.id),
  onDelete: () => deleteById(ENTITY_TYPE.UNIT_TYPE_INSTANCE, unitTypeInstance.id)
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DashboardCard);