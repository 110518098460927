import { SET_IS_PLAYING } from '../../actions/playback';

export default (state = true, action) => {
  switch (action.type) {
    case SET_IS_PLAYING:
      return action.payload.value;
    default:
      return state;
  }
};

export const getIsPlaying = state => state;