import _ from 'lodash';
import { defineMessages } from 'react-intl';
import uuid from 'uuid/v1';
import { ENTITY_FORM_ELEMENT_TYPE, ENTITY_PROPERTY, ROLE } from '../../../utils/constants';
import optionsSelector from './optionsSelector';

const roleMessages = _.chain(ROLE)
  .values()
  .keyBy(role => `form.roles.option.${role.id}`)
  .mapValues(role => ({
    id: `authGroup.form.roles.option.${role.id}`,
    defaultMessage: role.name
  }))
  .value();

const messages = defineMessages({
  'title': {
    id: 'authGroup.title',
    defaultMessage: 'Auth Groups'
  },
  'columns.name.title': {
    id: 'authGroup.columns.name.title',
    defaultMessage: 'Name'
  },
  'form.name.label': {
    id: 'authGroup.form.name.label',
    defaultMessage: 'Name'
  },
  'form.name.placeholder': {
    id: 'authGroup.form.name.placeholder',
    defaultMessage: 'Name'
  },
  'form.roles.label': {
    id: 'authGroup.form.roles.label',
    defaultMessage: 'Roles'
  },
  'form.roles.placeholder': {
    id: 'authGroup.form.roles.placeholder',
    defaultMessage: 'Select the roles a member of this auth group may perform'
  },
  ...roleMessages
});

export default {
  apiPath: 'authgroup',
  pageUrl: 'authgroup',
  icon: 'unlock',
  messages,
  disableFetchTypes: true,
  fetchAllOnLoad: true,
  optionsSelector,
  viewRole: ROLE.ADMIN,
  modifyRole: ROLE.ADMIN,
  columns: [
    {
      name: 'name',
      width: 3,
      render: ({ name }) => name
    }
  ],
  form: [
    {
      name: ENTITY_PROPERTY.AUTH_GROUP.NAME,
      type: ENTITY_FORM_ELEMENT_TYPE.INPUT
    },
    {
      name: ENTITY_PROPERTY.AUTH_GROUP.ROLES,
      type: ENTITY_FORM_ELEMENT_TYPE.SELECT,
      multiple: true,
      search: true,
      options: _.map(_.values(ROLE), 'id')
    }
  ],
  createEntity: values => ({
    id: uuid(),
    name: values.name,
    roles: values.roles || [],
    unitId: null
  }),
  populateForm: entity => ({
    name: entity.name,
    roles: entity.roles,
    unitId: entity.unitId
  })
};