import unit from './unit';
import sensorConnector from './sensorConnector';
import sensor from './sensor';
import model from './model';
import predictor from './predictor';
import alertConnector from './alertConnector';
import alertMapping from './alertMapping';
import actionConnector from './actionConnector';
import actionMapping from './actionMapping';
import authGroup from './authGroup';
import trend from './trend';
import action from './action';
import userMapping from './userMapping';
import enumeration from './enumeration';
import unitType from './unitType';
import unitCategory from './unitCategory';
import unitTypeInstance from './unitTypeInstance';
import unitTypePredictor from './unitTypePredictor';
import unitTypeAlertMapping from './unitTypeAlertMapping';
import unitTypeActionMapping from './unitTypeActionMapping';
import unitTypeAttribute from './unitTypeAttribute';
import unitTypeParameter from './unitTypeParameter';
import label from './label';
import sequence from './sequence';
import trendType from './trendType';
import review from './review';

export default {
  unit,
  unitCategory,
  unitType,
  sensorConnector,
  sensor,
  model,
  predictor,
  alertConnector,
  alertMapping,
  actionConnector,
  actionMapping,
  authGroup,
  sequence,
  trend,
  trendType,
  review,
  action,
  userMapping,
  enumeration,
  unitTypeInstance,
  unitTypePredictor,
  unitTypeAlertMapping,
  unitTypeActionMapping,
  unitTypeAttribute,
  unitTypeParameter,
  label
};