import _ from 'lodash';
import { DELETE_BY_ID, DELETE_ERROR, TOGGLE_MODAL } from '../../actions/entity';

export default (state = null, action) => {
  switch (action.type) {
    case TOGGLE_MODAL:
      return null;
    case DELETE_BY_ID:
      return null;
    case DELETE_ERROR:
      return action.payload.err;
    default:
      return state;
  }
};

export const getDeleteErrorResponse = state => state;
export const getDeleteErrorRequest = state => JSON.parse(_.get(state, 'request.body') || null);
export const getDeleteErrorMessages = state => _.chain(state)
  .get('response.errors')
  .flatMap()
  .map('message')
  .value();
export const getDeleteErrorCodes = state => _.chain(state)
  .get('response.errors')
  .flatMap()
  .map('code')
  .value();