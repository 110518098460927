import { FETCH_PAGE_FULFILLED, DELETE_BY_ID_FULFILLED, ADD_FULFILLED, FETCH_ALL_FULFILLED, EDIT_FULFILLED, INSERT, INSERT_MANY, DeleteByIdFulfilledPayload } from '../../actions/entity';
import _ from 'lodash';
import { Entity } from 'types';

type ByIdState<T extends Entity> = { [id: string]: T; };

export default (state: ByIdState<Entity> = {}, action: any) => {
  switch (action.type) {
    case FETCH_PAGE_FULFILLED:
      return {
        ...state,
        ..._.reduce(action.payload.entityPage.items, (memo, entity) => ({
          ...memo,
          [entity.id]: entity
        }), {})
      };
    case DELETE_BY_ID_FULFILLED: {
      const payload: DeleteByIdFulfilledPayload = action.payload;
      const { [payload.id]: deletedEntity, ...rest } = state;
      return rest;
    }
    case ADD_FULFILLED:
    case INSERT:
    case EDIT_FULFILLED:
      return {
        ...state,
        [action.payload.entity.id]: action.payload.entity
      };
    case FETCH_ALL_FULFILLED:
    case INSERT_MANY:
      return {
        ...state,
        ..._.reduce(action.payload.entities, (memo, entity) => {
          memo[entity.id] = entity;
          return memo;
        }, {})
      };
    default:
      return state;
  }
};

export function getById<T extends Entity>(state: ByIdState<T>, id: string): T | undefined {
  return state[id] as T;
}

export function getAll<T extends Entity>(state: ByIdState<T>) {
  return state;
}