import createCachedSelector from 're-reselect';
import { ENTITY_TYPE } from '../../../../utils/constants';
import * as fromState from '../../../reducers';
import { getPredictor } from '../getPredictor';

export const getModel = createCachedSelector(
  [
    getPredictor,
    state => fromState.getAll(state, ENTITY_TYPE.MODEL)
  ],
  (predictor, allModels) => (predictor && allModels[predictor.type]) || null
)(
  (state, chartType) => chartType
);