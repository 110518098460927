import { Fragment, ReactNode } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import uuid from 'uuid/v1';
import { Icon } from 'semantic-ui-react';
import optionsSelector from './optionsSelector';
import textComponent from './textComponent';
import { ENTITY_FORM_ELEMENT_TYPE, ROLE } from '../../constants';
import { EntityConfig, UnitCategory } from 'types';

const messages = defineMessages({
  'title': {
    id: 'unitCategory.title',
    defaultMessage: 'Unit Categories'
  },
  'columns.name.title': {
    id: 'unitCategory.columns.name.title',
    defaultMessage: 'Name'
  },
  'columns.icon.title': {
    id: 'unitCategory.columns.icon.title',
    defaultMessage: 'Icon'
  },
  'form.name.label': {
    id: 'unitCategory.form.name.label',
    defaultMessage: 'Name'
  },
  'form.name.placeholder': {
    id: 'unitCategory.form.name.placeholder',
    defaultMessage: 'Name'
  },
  'form.icon.label': {
    id: 'unitCategory.form.icon.label',
    defaultMessage: 'Icon'
  },
  'form.icon.placeholder': {
    id: 'unitCategory.form.icon.placeholder',
    defaultMessage: 'Icon'
  }
});

const config: EntityConfig<UnitCategory> = {
  apiPath: 'unitcategory',
  pageUrl: 'unitcategories',
  icon: 'sitemap',
  disableFetchTypes: true,
  fetchAllOnLoad: true,
  messages,
  optionsSelector,
  textComponent,
  viewRole: ROLE.VIEW_UNIT_CATEGORY,
  modifyRole: ROLE.MODIFY_UNIT_CATEGORY,
  columns: [
    {
      name: 'name',
      width: 3,
      render: (entity: UnitCategory): ReactNode => {
        const message = {
          id: `unit.type.${entity.id}.name`,
          defaultMessage: entity.name
        };
        return <FormattedMessage {...message} />;
      }
    },
    {
      name: 'icon',
      width: 3,
      render: ({ icon }) => (
        <Fragment>
          <Icon name={icon} /> <FormattedMessage id={`icon.${icon}`} />
        </Fragment>
      )
    }
  ],
  form: [
    {
      name: 'name',
      type: ENTITY_FORM_ELEMENT_TYPE.INPUT
    },
    {
      name: 'icon',
      type: ENTITY_FORM_ELEMENT_TYPE.SELECT,
      selectListName: 'icons',
      search: true
    }
  ],
  createEntity: values => ({
    id: uuid(),
    name: values.name,
    icon: values.icon,
    options: values.options || {}
  }),
  populateForm: entity => ({
    name: entity.name,
    icon: entity.icon,
    options: entity.options
  })
};

export default config;