import createCachedSelector from 're-reselect';
import { connect } from 'react-redux';
import MapChart from '../../../components/chart/MapChart/MapChart';
import * as fromState from '../../../state/reducers';
import { transformChartTypeSelectors } from '../../../utils/helpers';
import getBounds from './getBounds';
import getLines from './getLines';
import getPolygons from './getPolygons';

const mapStateToProps = createCachedSelector(
  [
    ...transformChartTypeSelectors([
      getLines,
      getPolygons,
      getBounds,
      fromState.getChartIsLoading
    ])
  ],
  (lines, polygons, bounds, isLoading) => ({
    lines,
    polygons,
    bounds,
    isLoading
  })
)(
  (state, { chartType }) => chartType
);

export default connect(mapStateToProps)(MapChart);