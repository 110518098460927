import _ from 'lodash';
import moment from 'moment';
import createCachedSelector from 're-reselect';
import { DATE_FORMAT } from '../../../../utils/constants';
import * as fromState from '../../../reducers';
import { getOutputRanges } from '../getOutputRanges';
import { getOutputs } from '../getOutputs';

export const getOutputData = createCachedSelector(
  [
    getOutputs,
    getOutputRanges,
    fromState.getFromDate
  ],
  (outputs, allOutputRanges, fromDate) => {
    const fromTimestamp = parseInt(moment.utc(fromDate, DATE_FORMAT).format('X'));
    return _.chain(outputs)
      .map(output => [output, _.dropWhile(allOutputRanges[output] || [], ([timestamp]) => timestamp < fromTimestamp)])
      .fromPairs()
      .value();
  }
)(
  (state, chartType) => chartType
);