import { SET_EDITING_FUNCTION } from '../../../actions/chart';

export default (state = null, action) => {
  switch (action.type) {
    case SET_EDITING_FUNCTION:
      return action.payload.id;
    default: 
      return state;
  }
};

export const getEditingFunction = state => state;