import _ from 'lodash';
import { createSelector } from 'reselect';
import * as fromState from '../../../reducers';
import { getUnitTypeId } from '../getUnitTypeId';
import { ENTITY_TYPE, SENSOR_TYPE } from '../../../../utils/constants';

const getUnitTypeSensorIds = createSelector(
  [
    state => getUnitTypeId(state),
    state => fromState.getAll(state, ENTITY_TYPE.UNIT_TYPE_ATTRIBUTE),
    state => fromState.getAll(state, ENTITY_TYPE.UNIT_TYPE_INSTANCE)
  ],
  (
    unitTypeId,
    allUnitTypeInstances,
    allUnitTypeAttributes
  ) => _.chain(allUnitTypeAttributes)
    .filter(attr => attr.type === unitTypeId)
    .flatMap(attr => _.chain(allUnitTypeInstances)
      .filter(instance => instance.type === unitTypeId)
      .map(instance => instance.settings[attr.id])
      .value()).value()
);

export const getUnitTypeSensors = state => _.chain(getUnitTypeSensorIds(state))
  .map(sensorId => fromState.getById(state, ENTITY_TYPE.SENSOR, sensorId))
  .filter(sensor => sensor.type !== SENSOR_TYPE.PREDICTOR_OUTPUT)
  .value();