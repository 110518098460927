import { ofType } from 'redux-observable';
import { filter, mergeMap } from 'rxjs/operators';
import { ENTITY_CONFIG } from '../../../utils/constants';
import { fetchById, FETCH_PAGE_FULFILLED, insert } from '../../actions/entity';
import { of, EMPTY } from 'rxjs';
import entityConfig from '../../../utils/entities';

export default action$ => action$.pipe(
  ofType(FETCH_PAGE_FULFILLED),
  filter(action => entityConfig[action.entityType][ENTITY_CONFIG.PARENT_ENTITY_TYPE]),
  mergeMap(action => action.payload.entityPage.parent
    ? of(
      insert(
        entityConfig[action.entityType][ENTITY_CONFIG.PARENT_ENTITY_TYPE],
        action.payload.entityPage.parent
      )
    )
    : action.payload.parentId
      ? of(
        fetchById(
          entityConfig[action.entityType][ENTITY_CONFIG.PARENT_ENTITY_TYPE],
          action.payload.parentId
        )
      )
      : EMPTY)
);