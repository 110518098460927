import { connect } from 'react-redux';
import DashboardCard from '../../components/dashboard/DashboardCard';
import { getColourFromSeverity, toPath } from '../../utils/helpers';
import { PAGE_URL, ENTITY_MODAL, ENTITY_TYPE } from 'utils/constants';
import { getUnitTypePredictorSeverity } from 'state/selectors/dashboard/getSeverity/getUnitTypePredictorSeverity';
import createCachedSelector from 're-reselect';
import * as fromState from 'state/reducers';
import { deleteById, toggleModal } from 'state/actions/entity';
import { bindActionCreators } from 'redux';

const mapStateToProps = createCachedSelector(
  [
    (state, { unitTypePredictor }) => getUnitTypePredictorSeverity(state, { id: unitTypePredictor.id }),
    (state, { unitTypePredictor }) => fromState.getById(state, ENTITY_TYPE.MODEL, unitTypePredictor.type),
    (state, { unitTypePredictor }) => unitTypePredictor,
    state => fromState.getShowCardDetails(state)
  ],
  (severity, model, unitTypePredictor, showDetails) => ({
    id: unitTypePredictor.id,
    linkTo: toPath(PAGE_URL.UNIT_TYPE_PREDICTOR, {
      unitTypeId: unitTypePredictor.unitType,
      unitTypePredictorId: unitTypePredictor.id
    }),
    labels: [
      {
        type: ENTITY_TYPE.MODEL,
        id: model
          ? model.id
          : null,
        icon: unitTypePredictor.type
          ? 'lightbulb outline'
          : 'thermometer half',
        color: 'violet',
        messageId: model
          ? `model.type.${model.type}.name`
          : 'model.type.attributeGroup.name'
      }
    ],
    name: unitTypePredictor.name,
    fill: getColourFromSeverity(severity),
    severity,
    active: true,
    noModel: true,
    canMute: false,
    canDelete: true,
    canConfigure: false,
    entityType: ENTITY_TYPE.UNIT_TYPE_PREDICTOR,
    showDetails
  })
)(
  (state, { unitTypePredictor }) => unitTypePredictor.id
);

const mapDispatchToProps = (dispatch, { unitTypePredictor }) => bindActionCreators({
  onClickDelete: () => toggleModal(ENTITY_TYPE.UNIT_TYPE_PREDICTOR, ENTITY_MODAL.DELETE, unitTypePredictor.id),
  onDelete: () => deleteById(ENTITY_TYPE.UNIT_TYPE_PREDICTOR, unitTypePredictor.id)
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DashboardCard);