import { ofType } from 'redux-observable';
import { map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { SET_FUNCTION_SENSORS, setItemSelected } from '../../actions/chart';
import * as api from '../../../utils/api';
import * as fromState from '../../reducers';
import { setMultipleRanges } from '../../actions/data';
import * as chartSelectors from '../../selectors/chart';
import { DATE_FORMAT, SELECTION_TYPE } from 'utils/constants';
import moment from 'moment';
import { takeUntilAppReset } from 'utils/helpers';
import { from, merge } from 'rxjs';

export default (action$, state$) => action$.pipe(
  ofType(SET_FUNCTION_SENSORS),
  withLatestFrom(state$),
  mergeMap(([action, state]) => {
    const group = fromState.getGroup(state, action.chartType);
    const fromDate = chartSelectors.getWindowFromDate(state, action.chartType);
    const toDate = fromState.getToDate(state, action.chartType);
    return merge(
      from(action.payload.ids).pipe(
        map(id => setItemSelected(action.chartType, id, SELECTION_TYPE.FUNCTION, true))
      ),
      api.getSensorsWindow({
        ids: action.payload.ids,
        fromDate,
        toDate,
        token: fromState.getUser(state).token
      }).pipe(
        takeUntilAppReset(action$),
        map(({ sensors }) => setMultipleRanges(
          action.chartType,
          sensors,
          group,
          [],
          fromDate.toISOString(),
          moment.utc(toDate, DATE_FORMAT).toISOString()
        ))
      )
    );
  })
);