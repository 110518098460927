import { Button, Icon } from 'semantic-ui-react';
import { INVERTED_THEME } from '../../utils/constants';

const AnimatedButton = ({
  text,
  icon,
  ...rest
}) => (
  <Button
    inverted={INVERTED_THEME}
    animated
    {...rest}>
    <Button.Content visible>{text}</Button.Content>
    <Button.Content hidden>
      <Icon name={icon} />
    </Button.Content>
  </Button>
);

AnimatedButton.displayName = 'AnimatedButton';

export default AnimatedButton;