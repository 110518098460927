export const PERFORM_AUTH = 'auth/PERFORM_AUTH';
export const performAuth = authType => ({
  type: PERFORM_AUTH,
  authType,
  payload: {}
});

export const PERFORM_AUTH_FULFILLED = 'auth/PERFORM_AUTH_FULFILLED';
export const performAuthFulfilled = (authType, response) => ({
  type: PERFORM_AUTH_FULFILLED,
  authType,
  payload: {
    response
  }
});

export const PERFORM_AUTH_ERROR = 'auth/PERFORM_AUTH_ERROR';
export const performAuthError = (authType, err) => ({
  type: PERFORM_AUTH_ERROR,
  authType,
  payload: {
    err
  }
});

export const AUTH_FORM_VALUE_CHANGED = 'auth/AUTH_FORM_VALUE_CHANGED';
export const authFormValueChanged = (authType, key, value) => ({
  type: AUTH_FORM_VALUE_CHANGED,
  authType,
  payload: {
    key,
    value
  }
});

export const SET_SHOW_PASSWORD = 'auth/SET_SHOW_PASSWORD';
export const setShowPassword = (authType, value) => ({
  type: SET_SHOW_PASSWORD,
  authType,
  payload: {
    value
  }
});

export const LOGOUT = 'LOGOUT';
export const logout = () => ({
  type: LOGOUT,
  payload: {}
});

export const UNAUTHORISED = 'UNAUTHORISED';
export const unauthorised = () => ({
  type: UNAUTHORISED,
  payload: {}
});

export const GATEWAY_TIMEOUT = 'GATEWAY_TIMEOUT';
export const gatewayTimeout = () => ({
  type: GATEWAY_TIMEOUT,
  payload: {}
});

export const SERVICE_UNAVAILABLE = 'SERVICE_UNAVAILABLE';
export const serviceUnavailable = () => ({
  type: SERVICE_UNAVAILABLE,
  payload: {}
});

export const NOT_FOUND = 'NOT_FOUND';
export const notFound = () => ({
  type: NOT_FOUND,
  payload: {}
});

export const SET_AUTH_MESSAGE = 'auth/SET_AUTH_MESSAGE';
export const setAuthMessage = message => ({
  type: SET_AUTH_MESSAGE,
  payload: {
    message
  }
});

export const RESET_APP = 'auth/RESET_APP';
export const resetApp = () => ({
  type: RESET_APP,
  payload: {}
});

export const SWITCH_TENANT = 'auth/SWITCH_TENANT';
export const switchTenant = tenantId => ({
  type: SWITCH_TENANT,
  payload: {
    tenantId
  }
});

export const SET_TENANT = 'auth/SET_TENANT';
export const setTenant = tenantId => ({
  type: SET_TENANT,
  payload: {
    tenantId
  }
});