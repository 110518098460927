import { Fragment } from 'react';
import { connect } from 'react-redux';
import * as fromState from '../../state/reducers';

const mapStateToProps = state => {
  const user = fromState.getUser(state);
  return {
    isNotAuthenticated: !(user && user.token)
  };
};

export default connect(mapStateToProps)(({
  isNotAuthenticated,
  children
}) => isNotAuthenticated && (
  <Fragment>
    {children}
  </Fragment>
));