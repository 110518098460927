import { SET_CONDITION_FILTER, SET_FORM_VALUES } from '../../../../actions/entity';
import _ from 'lodash';

export default (state = 'All', action) => {
  switch (action.type) {
    case SET_CONDITION_FILTER:
      return action.payload.filter;
    case SET_FORM_VALUES:
      return _.get(action, 'payload.formValues.conditions.filter') || null;
    default:
      return state;
  }
};

export const getFilter = state => state;