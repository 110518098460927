import { SET_UNTIL, SET_MUTE_MODAL } from '../../../actions/dashboard';

export default (state = null, action) => {
  switch (action.type) {
    case SET_UNTIL:
      return action.payload.value;
    case SET_MUTE_MODAL:
      return action.payload.open ? state : null;
    default:
      return state;
  }
};

export const getUntil = state => state;