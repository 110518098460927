import _ from 'lodash';
import { connect } from 'react-redux';

const connectWithName = (mapStateToProps, mapDispatchToProps) => component => {
  const mapDispatchToPropsWithName = _.isFunction(mapDispatchToProps)
    ? (dispatch, props) => mapDispatchToProps(action => dispatch({
      ...action,
      DISPATCHED_FROM_COMPONENT: component.displayName || component.name
    }), props)
    : _.mapValues(mapDispatchToProps, actionCreator => (...args) => ({
      ...actionCreator(...args),
      DISPATCHED_FROM_COMPONENT: component.displayName || component.name
    }));
  return connect(mapStateToProps, mapDispatchToPropsWithName)(component);
};

export default process.env.NODE_ENV !== 'production' ? connectWithName : connect;