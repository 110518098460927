import _ from 'lodash';
import { TOGGLE_TREND_SELECTED } from 'state/actions/chart';

export default (state: string[] = [], action) => {
  switch (action.type) {
    case TOGGLE_TREND_SELECTED:
      if (_.includes(state, action.payload.id)) {
        return _.without(state, action.payload.id);
      } else {
        return [...state, action.payload.id];
      }
    default:
      return state;
  }
};

export const getSelectedTrends = (state: string[]) => state;