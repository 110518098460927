import LoadingModalContainer from 'containers/loading/LoadingModalContainer';
import { Fragment } from 'react';
import { Divider } from 'semantic-ui-react';
import AuthMenuContainer from '../../containers/auth/AuthMenuContainer';
import QuerySyncLink from '../../containers/navigation/QuerySyncLink';
import SpartanLogo from '../../spartan.svg';
import { PAGE_URL } from '../../utils/constants';
import ExplorerPage from '../explorer/ExplorerPage';

export default function ExplorerRoutes() {
  return (
    <Fragment>
      <div className='page-header'>
        <span className='logo-wrapper'>
          <QuerySyncLink to={PAGE_URL.EXPLORER}>
            <img
              src={SpartanLogo}
              alt='Spartan Solutions Ltd.'
              height='50px' />
          </QuerySyncLink>
        </span>
        <AuthMenuContainer />
      </div>
      <LoadingModalContainer />
      <div style={{
        paddingTop: '60px',
        height: 'calc(100% - 60px)'
      }}>
        <ExplorerPage />
      </div>
      <Divider
        section
        hidden />
    </Fragment>
  );
};