import createCachedSelector from 're-reselect';
import { connect } from 'react-redux';
import DeleteEntityModal from '../../components/entity/DeleteEntityModal';
import * as fromState from '../../state/reducers';
import { ENTITY_MODAL } from '../../utils/constants';
import { toggleModal } from '../../state/actions/entity';
import { bindActionCreators } from 'redux';

const mapStateToProps = createCachedSelector(
  [
    (state, { entityType, id }) => id ?
      fromState.getModalOpenById(state, entityType, ENTITY_MODAL.DELETE, id) :
      fromState.getModalOpen(state, entityType, ENTITY_MODAL.DELETE) &&
      fromState.getModalId(state, entityType, ENTITY_MODAL.DELETE) === null,
    (state, { entityType }) => fromState.getIsDeleting(state, entityType),
    (state, { entityType }) => fromState.getDeleteErrorCodes(state, entityType)
  ],
  (
    open,
    isDeleting,
    errorCodes
  ) => ({
    open,
    isDeleting,
    errorCodes
  })
)(
  (state, { entityType }) => entityType
);

const mapDispatchToProps = (dispatch, { entityType, id }) => bindActionCreators({
  toggleModal: () => toggleModal(entityType, ENTITY_MODAL.DELETE, id)
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DeleteEntityModal);