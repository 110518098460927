import _ from 'lodash';
import { AUTH_TYPE, ENTITY_PROPERTY } from '../../../../utils/constants';
import { LOGOUT, PERFORM_AUTH_FULFILLED, UNAUTHORISED, SWITCH_TENANT } from '../../../actions/auth';
import queryString from 'query-string';

const getUserMappingByTenant = (user, tenantId) => {
  return _.find(
    user.userMappings,
    [ENTITY_PROPERTY.USER_MAPPING.TENANT_ID, tenantId]
  );
};

const getDefaultState = () => {
  if (!window.localStorage.user) {
    return null;
  };
  let user = JSON.parse(window.localStorage.user);
  if (!user.tenantId) {
    return null;
  };
  const queryParameters = queryString.parse(window.location.search);
  if (queryParameters.tenant) {
    const userMapping = getUserMappingByTenant(user, queryParameters.tenant);
    if (!userMapping) {
      return null;
    };
    user = {
      ...user,
      token: userMapping.token,
      tenantId: userMapping.tenantId
    };
  };
  window.localStorage.user = JSON.stringify(user);
  return user;
};

export default (state = getDefaultState(), action) => {
  switch (action.type) {
    case PERFORM_AUTH_FULFILLED: {
      if (action.authType !== AUTH_TYPE.LOGIN) {
        return state;
      };
      const queryParameters = queryString.parse(window.location.search);
      const userMapping = queryParameters.tenant
        ? getUserMappingByTenant(action.payload.response, queryParameters.tenant)
        : _.first(action.payload.response.userMappings);
      if (!userMapping) {
        return null;
      };
      const user = {
        ...action.payload.response,
        token: userMapping.token,
        tenantId: userMapping.tenantId
      };
      window.localStorage.user = JSON.stringify(user);
      return user;
    }

    case SWITCH_TENANT: {
      if (!state) {
        return state;
      };
      const { token, tenantId } = _.find(state.userMappings, [ENTITY_PROPERTY.USER_MAPPING.TENANT_ID, action.payload.tenantId]);
      const newUser = {
        ...state,
        token,
        tenantId
      };
      window.localStorage.user = JSON.stringify(newUser);
      return newUser;
    }

    case LOGOUT:
    case UNAUTHORISED: {
      delete window.localStorage.user;
      return null;
    }

    default: {
      return state;
    }
  }
};

export const getUser = state => state;