import _ from 'lodash';
import { AlarmRange } from 'types';
import { NEUTRAL_SEVERITY } from '../../constants';

export default (value: number, alarmRanges: AlarmRange[]) => {
  if (_.isNil(value)) {
    return null;
  }

  if (!alarmRanges) {
    return NEUTRAL_SEVERITY;
  }

  return _.chain(alarmRanges)
    .filter((range, i) => {
      const isBelowMax = value <= range.max || i === _.size(alarmRanges) - 1;
      const isAboveMin = value >= range.min || i === 0;
      return isBelowMax && isAboveMin;
    })
    .map('severity')
    .max()
    .value();
};