import { EDIT, EDIT_ERROR, TOGGLE_MODAL } from '../../actions/entity';
import _ from 'lodash';
import { ErrorResponse, ResponseWrapper } from 'types';

export default (state: ResponseWrapper<ErrorResponse> | null = null, action) => {
  switch (action.type) {
    case TOGGLE_MODAL:
      return null;
    case EDIT:
      return null;
    case EDIT_ERROR:
      return action.payload.err;
    default:
      return state;
  }
};

export const getEditErrorResponse = (state: ResponseWrapper<ErrorResponse> | null) => state;
export const getEditErrorRequest = (state: ResponseWrapper<ErrorResponse> | null) =>
  JSON.parse(_.get(state, 'request.body') || 'null');

export const getEditErrorMessages = (
  state: ResponseWrapper<ErrorResponse> | null,
  errorPathPrefixes: string[]
) => {
  if (state === null) {
    return [];
  }

  return _.chain(state.response.errors)
    .filter((val, errorPath) => _.some(errorPathPrefixes, prefix => _.startsWith(errorPath, prefix)))
    .flatMap()
    .map(err => err.message)
    .value();
};

export const getEditErrorCodes = (
  state: ResponseWrapper<ErrorResponse> | null,
  errorPathPrefixes: string[]
) => {
  if (state === null) {
    return [];
  }

  return _.chain(state.response.errors)
    .filter((val, errorPath) => _.some(errorPathPrefixes, prefix => _.startsWith(errorPath, prefix)))
    .flatMap()
    .map(err => err.code)
    .value();
};
