import { SET_MULTIPLE_LATEST } from '../../actions/data';

export default (state = {}, action) => {
  switch (action.type) {
    case SET_MULTIPLE_LATEST:
      return {
        ...state,
        ...action.payload.sensors
      };
    default:
      return state;
  }
};

export const getLatestSensorValue = (state, id) => state[id];
export const getAllLatestSensorValues = state => state;