import startTimestamp, * as fromStartTimestamp from './startTimestamp';
import endTimestamp, * as fromEndTimestamp from './endTimestamp';
import { combineReducers } from 'redux';

export default combineReducers({
  startTimestamp,
  endTimestamp
});

export const getStartTimestamp = state => fromStartTimestamp.getStartTimestamp(state.startTimestamp);
export const getEndTimestamp = state => fromEndTimestamp.getEndTimestamp(state.endTimestamp);