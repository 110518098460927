import _ from 'lodash';
import moment from 'moment';
import { defineMessages, injectIntl } from 'react-intl';
import { Button, Dropdown, Popup } from 'semantic-ui-react';
import { INVERTED_THEME, PLAYBACK_MODE } from '../../../utils/constants';
import { messages as dateRangeMessages } from 'utils/dates';
import PopupButton from '../../shared/PopupButton';
import styles from './PlaybackButtons.styles';

const messages = defineMessages({
  'previousWindowButton.popupText': {
    id: 'playbackButtons.previousWindowButton.popupText',
    defaultMessage: 'Jump Backwards'
  },
  'windowSizeButton.popupText': {
    id: 'playbackButtons.windowSizeButton.popupText',
    defaultMessage: 'Window Size'
  },
  'nextWindowButton.popupText': {
    id: 'playbackButtons.nextWindowButton.popupText',
    defaultMessage: 'Jump Forwards'
  }
});

const WindowButtonGroup = injectIntl(({
  momentTime,
  quickRange,
  isPlaying,
  mode,
  onChangeTime,
  onChangeQuickRange,
  onJumpToLive,
  intl: {
    formatMessage
  }
}) => {
  const duration = moment.duration(quickRange);
  const nowTime = moment.utc();
  const lessThanDurationAwayFromNow = nowTime.diff(momentTime) <= duration;
  return (
    <Button.Group size='small'>
      <Dropdown
        simple
        value={quickRange}
        onChange={(e, { value }) => onChangeTime(momentTime.clone().subtract(moment.duration(value)).toISOString()) && e.target.blur()}
        options={[
          {
            key: 'lastHour',
            text: formatMessage(dateRangeMessages['quick.PT1H']),
            value: 'PT1H'
          },
          {
            key: 'lastTwelveHours',
            text: formatMessage(dateRangeMessages['quick.PT12H']),
            value: 'PT12H'
          },
          {
            key: 'lastDay',
            text: formatMessage(dateRangeMessages['quick.P1D']),
            value: 'P1D'
          },
          {
            key: 'lastThreeDays',
            text: formatMessage(dateRangeMessages['quick.P3D']),
            value: 'P3D'
          },
          {
            key: 'lastSevenDays',
            text: formatMessage(dateRangeMessages['quick.P7D']),
            value: 'P7D'
          },
          {
            key: 'lastMonth',
            text: formatMessage(dateRangeMessages['quick.P1M']),
            value: 'P1M'
          }
        ]}
        trigger={(
          <PopupButton
            popupText={formatMessage(messages['previousWindowButton.popupText'])}
            color='blue'
            icon='step backward'
            onClick={e => {
              onChangeTime(momentTime.clone().subtract(duration).toISOString());
              e.target.blur();
              e.target.parentNode.blur();
            }}
            style={styles.previousWindowButton} />
        )} />
      <Popup
        inverted={INVERTED_THEME}
        trigger={(
          <Dropdown
            className='button'
            disabled={!onChangeQuickRange}
            style={styles.quickRangeButton}
            value={quickRange}
            onChange={(e, { value }) => onChangeQuickRange(value)}
            options={[
              {
                key: 'lastHour',
                text: formatMessage(dateRangeMessages['quick.PT1H']),
                value: 'PT1H'
              },
              {
                key: 'lastTwelveHours',
                text: formatMessage(dateRangeMessages['quick.PT12H']),
                value: 'PT12H'
              },
              {
                key: 'lastDay',
                text: formatMessage(dateRangeMessages['quick.P1D']),
                value: 'P1D'
              },
              {
                key: 'lastThreeDays',
                text: formatMessage(dateRangeMessages['quick.P3D']),
                value: 'P3D'
              },
              {
                key: 'lastSevenDays',
                text: formatMessage(dateRangeMessages['quick.P7D']),
                value: 'P7D'
              },
              {
                key: 'lastMonth',
                text: formatMessage(dateRangeMessages['quick.P1M']),
                value: 'P1M'
              }
            ]} />
        )}
        style={styles.windowSizePopup}
        content={formatMessage(messages['windowSizeButton.popupText'])} />
      <Dropdown
        simple
        disabled={mode === PLAYBACK_MODE.LIVE}
        value={quickRange}
        onChange={(e, { value }) => onChangeTime(momentTime.clone().add(moment.duration(value)).toISOString()) && e.target.blur()}
        options={[
          {
            key: 'lastHour',
            text: formatMessage(dateRangeMessages['quick.PT1H']),
            value: 'PT1H'
          },
          {
            key: 'lastTwelveHours',
            text: formatMessage(dateRangeMessages['quick.PT12H']),
            value: 'PT12H'
          },
          {
            key: 'lastDay',
            text: formatMessage(dateRangeMessages['quick.P1D']),
            value: 'P1D'
          },
          {
            key: 'lastThreeDays',
            text: formatMessage(dateRangeMessages['quick.P3D']),
            value: 'P3D'
          },
          {
            key: 'lastSevenDays',
            text: formatMessage(dateRangeMessages['quick.P7D']),
            value: 'P7D'
          },
          {
            key: 'lastMonth',
            text: formatMessage(dateRangeMessages['quick.P1M']),
            value: 'P1M'
          }
        ]}
        trigger={(
          <PopupButton
            popupText={formatMessage(messages['nextWindowButton.popupText'])}
            color='blue'
            style={styles.nextWindowButton}
            disabled={mode === PLAYBACK_MODE.LIVE}
            icon='step forward'
            onClick={e => {
              lessThanDurationAwayFromNow && isPlaying
                ? onJumpToLive()
                : onChangeTime(_.min([momentTime.clone().add(duration), nowTime]).toISOString());
              e.target.blur();
              e.target.parentNode.blur();
            }} />
        )} />
    </Button.Group>
  );
});

WindowButtonGroup.displayName = 'WindowButtonGroup';

export default WindowButtonGroup;