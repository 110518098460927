import _ from 'lodash';
import createCachedSelector from 're-reselect';
import { CHART_TYPE, ENTITY_TYPE } from '../../../../utils/constants';
import * as fromState from '../../../reducers';
import { getSeverity } from './getSeverity';

export const getUnitTypePredictorSeverity = createCachedSelector(
  [
    (state, { id }) => id,
    state => fromState.getAll(state, ENTITY_TYPE.PREDICTOR),
    state => fromState.getAllLatestSensorValues(state, CHART_TYPE.MAIN),
    state => fromState.getAllLatestOutputValues(state, CHART_TYPE.MAIN),
    state => fromState.getAll(state, ENTITY_TYPE.SENSOR)
  ],
  (
    id,
    allPredictors,
    allLatestSensorValues,
    allLatestOutputValues,
    allSensors
  ) => {
    return _.chain(allPredictors)
      .values()
      .filter(predictor => predictor.unitTypePredictor === id)
      .map(predictor => getSeverity(predictor, allLatestSensorValues, allLatestOutputValues, allSensors))
      .max()
      .value();
  }
)(
  (state, { id }) => id
);