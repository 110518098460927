import { FETCH_CONDITION_TYPES_FULFILLED } from '../../actions/entity';

export default (state = [], action) => {
  switch (action.type) {
    case FETCH_CONDITION_TYPES_FULFILLED:
      return action.payload.conditionTypes;
    default:
      return state;
  }
};

export const getConditionTypes = state => state;