import _ from 'lodash';
import { FEATURE, SECONDS_IN_HOUR, PROPERTY } from '../../../../../utils/constants';
import { median } from 'simple-statistics';
import { consecutivePairs } from '../../../../../utils/helpers';

// because the features are calculated using an online algorithm, there is an error that builds up with each new value
// use this term to determine after how many values everything should be recalculated from scratch
const WINDOW_RESET_COUNT = 240;

// because there is a small error in the calculation, we treat values below a certain threshold as if they were zero
const M2_ZERO_THRESHOLD = 0.000000001;
const M3_ZERO_THRESHOLD = 0.0000000001;
const M4_ZERO_THRESHOLD = 0.0000000001;

export default class FeatureCalculator {
  constructor(property, startTimestamp, windowSeconds) {
    // a property is a value calculated for a single point in a time-series
    // for example rate of change, which does not depend on a window and so is not a feature
    // separating properties from features allows for things like mean rate of change
    this.property = property;

    // the timestamp after which to start calculating the output features
    // it is expected that some data from before this timestamp is passed in to enable
    // points immediately after it to have correct features calculated
    this.startTimestamp = startTimestamp;

    // the size of the rolling feature window in seconds
    this.windowSeconds = windowSeconds;

    // list of the sensor values in the rolling feature window
    this.buffer = [];

    // list of calculated features that will be returned
    this.featureValues = [];

    // passedFromTimestamp controls whether the scan has passed the fromTimestamp
    this.passedFromTimestamp = false;
    
    // number of values in current feature window
    this.count = 0;
    
    // number of values since the calculations were last reset
    this.resetCount = 0;
    
    // number of consecutive same values
    this.unchangedCount = 0;

    // latest value
    this.latestValue = 0;
    
    // the mean x value
    this.M1X = 0;

    // second central moment of x 
    this.M2X = 0;

    // the covariance
    this.cov = 0;

    // the mean of y
    this.M1Y = 0;

    // second central moment of y
    this.M2Y = 0;

    // third central moment of y
    this.M3Y = 0;

    // fourth central moment of y
    this.M4Y = 0;
    
    // double ended queue used to calculate min
    this.minDeque = [];

    // doubled ended queue used to calculate max
    this.maxDeque = [];
  }

  reset() {
    this.resetCount = 0;
    this.count = 0;
    this.unchangedCount = 0;
    this.latestValue = 0;
    this.M1X = 0;
    this.M2X = 0;
    this.cov = 0;
    this.M1Y = 0;
    this.M2Y = 0;
    this.M3Y = 0;
    this.M4Y = 0;
    this.minDeque = [];
    this.maxDeque = [];
    _.each(this.buffer, bufferValue => this.addValue(...bufferValue));
  }

  // these formulas are taken from https://en.wikipedia.org/wiki/Algorithms_for_calculating_variance
  // we calculate the central moments of the data using an online algorithm
  // this lets us calculate mean, standard deviation, skewness and kurtosis for a rolling window using a single pass through the data
  // without this method the performance is inadequate
  secondCentralMomentDelta(delta, delta_n, n1) {
    return delta * delta_n * n1;
  }

  thirdCentralMomentDelta(term1, n, delta_n) {
    return term1 * delta_n * (n - 2) - 3 * delta_n * this.M2Y;
  }

  fourthCentralMomentDelta(term1, n, delta_n, delta_n2) {
    return term1 * delta_n2 * (n ** 2 - 3 * n + 3) + 6 * delta_n2 * this.M2Y - 4 * delta_n * this.M3Y;
  }
  
  scanValue([timestamp, value]) {
    const dropBeforeTimestamp = timestamp - this.windowSeconds;
    const newBufferStartIndex = _.findIndex(this.buffer, sensorValue => sensorValue[0] >= dropBeforeTimestamp);
    let removedValues = [];
    if (newBufferStartIndex > 0) {
      removedValues = this.buffer.splice(0, newBufferStartIndex);
    };
    this.buffer.push([timestamp, value]);

    // we keep track of unchanged values to prevent division by zero (since zero might actually be a non-zero small floating point number)
    if (value === this.latestValue) {
      this.unchangedCount++;
    } else {
      this.latestValue = value;
      this.unchangedCount = 1;
    };

    if (timestamp < this.startTimestamp) {
      return;
    };
  
    // at the start of the visible window, and every WINDOW_RESET_COUNT values afterwards
    // we populate a fresh set of intermediate calculations
    if (!this.passedFromTimestamp || this.resetCount > WINDOW_RESET_COUNT) {
      this.reset();
    } else {
      _.each(removedValues, removedValue => this.removeValue(...removedValue));
      this.addValue(timestamp, value);
      this.resetCount++;
    };

    this.featureValues.push([timestamp, this.getFeatures()]);
  }

  addValue(timestamp, value) {
    this.passedFromTimestamp = true;
    const n1 = this.count;
    this.count++;
    const n = this.count;
    const deltaX = timestamp - this.M1X;
    const delta_nX = deltaX / n;
    this.M1X += delta_nX;
    const deltaY = value - this.M1Y;
    this.cov += delta_nX * deltaY * n1;
    this.M2X += this.secondCentralMomentDelta(deltaX, delta_nX, n1);
    const delta_nY = deltaY / n;
    this.M1Y += delta_nY;
    const delta_n2Y = delta_nY ** 2;
    const term1 = this.secondCentralMomentDelta(deltaY, delta_nY, n1);
    this.M4Y += this.fourthCentralMomentDelta(term1, n, delta_nY, delta_n2Y);
    this.M3Y += this.thirdCentralMomentDelta(term1, n, delta_nY);
    this.M2Y += term1;
  
    while (this.minDeque.length > 0 && value < _.last(this.minDeque)) {
      this.minDeque.pop();
    };
    this.minDeque.push(value);
  
    while (this.maxDeque.length > 0 && value > _.last(this.maxDeque)) {
      this.maxDeque.pop();
    };
    this.maxDeque.push(value);
  }

  removeValue(timestamp, value) {
    const n = this.count;
    this.count--;
    const n1 = this.count;
    this.M1X -= (timestamp - this.M1X) / n1;
    const deltaX = timestamp - this.M1X;
    const delta_nX = deltaX / n;
    this.M2X -= this.secondCentralMomentDelta(deltaX, delta_nX, n1);
    this.M1Y -= (value - this.M1Y) / n1;
    const deltaY = value - this.M1Y;
    this.cov -= delta_nX * deltaY * n1;
    const delta_nY = deltaY / n;
    const delta_n2Y = delta_nY ** 2;
    const term1 = this.secondCentralMomentDelta(deltaY, delta_nY, n1);
    this.M2Y -= term1;
    this.M3Y -= this.thirdCentralMomentDelta(term1, n, delta_nY);
    this.M4Y -= this.fourthCentralMomentDelta(term1, n, delta_nY, delta_n2Y);
  
    if (value === this.minDeque[0]) {
      this.minDeque.shift();
    }
  
    if (value === this.maxDeque[0]) {
      this.maxDeque.shift();
    }
  }

  latest() {
    return _.last(this.buffer)[1];
  }

  rateOfChange() {
    return SECONDS_IN_HOUR * this.cov / Math.abs(this.M2X);
  }

  stepChange() {
    return _.last(this.buffer)[1] - this.buffer[Math.max(0, _.size(this.buffer) - 2)][1];
  }

  mean() {
    return this.M1Y;
  }

  median() {
    return median(_.map(this.buffer, _.last));
  }

  standardDeviation() {
    return Math.sqrt(Math.abs(this.M2Y) / this.count);
  }

  maximum() {
    return this.maxDeque[0];
  }

  minimum() {
    return this.minDeque[0];
  }

  range() {
    return this.maxDeque[0] - this.minDeque[0];
  }

  skewness() {
    if (this.unchangedCount >= this.count) {
      return NaN;
    };
    if (Math.abs(this.M2Y) < M2_ZERO_THRESHOLD || Math.abs(this.M3Y) < M3_ZERO_THRESHOLD) {
      return NaN;
    };
    return Math.sqrt(this.count) * this.M3Y / (this.M2Y ** 1.5);
  }

  kurtosis() {
    if (this.unchangedCount >= this.count) {
      return NaN;
    };
    if (Math.abs(this.M2Y) < M2_ZERO_THRESHOLD || Math.abs(this.M4Y) < M4_ZERO_THRESHOLD) {
      return NaN;
    };
    return ((this.count * this.M4Y) / (this.M2Y ** 2)) - 3;
  }
 
  impulse() {
    return this.M1Y === 0
      ? 1
      : Math.abs(this.maxDeque[0] / this.M1Y);
  }

  differenceFromMean() {
    return _.last(this.buffer)[1] - this.M1Y;
  }

  getFeatures() {
    return {
      [FEATURE.LATEST_FLOAT]: this.latest(),
      [FEATURE.RATE_OF_CHANGE]: this.rateOfChange(),
      [FEATURE.STEP_CHANGE]: this.stepChange(),
      [FEATURE.MEAN]: this.mean(),
      [FEATURE.MEDIAN]: this.median(),
      [FEATURE.STANDARD_DEVIATION]: this.standardDeviation(),
      [FEATURE.MAXIMUM]: this.maximum(),
      [FEATURE.MINIMUM]: this.minimum(),
      [FEATURE.RANGE]: this.range(),
      [FEATURE.SKEWNESS]: this.skewness(),
      [FEATURE.KURTOSIS]: this.kurtosis(),
      [FEATURE.IMPULSE]: this.impulse(),
      [FEATURE.DIFFERENCE_FROM_MEAN]: this.differenceFromMean()
    };
  }

  getProperty(sensorValues) {
    switch (this.property) {
      case PROPERTY.RATE_OF_CHANGE:
        if (_.size(sensorValues) < 2) {
          return [];
        };
        return _.map(consecutivePairs(sensorValues), ([[previousTimestamp, previousValue], [latestTimestamp, latestValue]]) => {
          return [latestTimestamp, SECONDS_IN_HOUR * (latestValue - previousValue) / (latestTimestamp - previousTimestamp)];
        });
      default:
        return sensorValues;
    };
  }

  calculate(sensorValues) {
    _.each(this.getProperty(sensorValues), this.scanValue.bind(this));
    return this.featureValues;
  }
};