import { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createSelector } from 'reselect';
import { Loader } from 'semantic-ui-react';
import { fetchById } from 'state/actions/entity';
import * as fromState from 'state/reducers';
import { ENTITY_TYPE } from '../../constants';

const mapStateToProps = createSelector(
  [
    (state, { id }) => id,
    state => fromState.getAll(state, ENTITY_TYPE.TREND_TYPE)
  ],
  (
    id,
    allTrendTypes
  ) => ({
    trendType: allTrendTypes[id]
  })
);

const mapDispatchToProps = (dispatch, { id }) => bindActionCreators({
  fetchTrendType: () => fetchById(ENTITY_TYPE.TREND_TYPE, id)
}, dispatch);

const TrendTypeText = ({
  id,
  trendType,
  fetchTrendType
}) => {
  useEffect(() => {
    if (id && !trendType) {
      fetchTrendType();
    };
  });
  if (!id) {
    return null;
  };
  return trendType
    ? trendType.name
    : (
      <Loader
        inline
        active />
    );
};

TrendTypeText.displayName = 'TrendTypeText';

export default connect(mapStateToProps, mapDispatchToProps)(TrendTypeText);