import _ from 'lodash';
import createCachedSelector from 're-reselect';
import * as fromState from '../../../reducers';

export const getOutputRanges = createCachedSelector(
  [
    fromState.getAllOutputRanges,
    fromState.getGroup
  ],
  (outputRanges, group) => _.chain(outputRanges)
    .pickBy((val, key) => _.startsWith(key, `${group}/`))
    .mapKeys((val, key) => key.split('/').slice(1).join('/'))
    .value()
)(
  (state, chartType) => chartType
);