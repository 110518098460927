import { SET_GROUP } from '../../../actions/chart';

export default (state = null, action) => {
  switch (action.type) {
    case SET_GROUP:
      return action.payload.id;
    default:
      return state;
  }
};

export const getGroup = state => state;