import _ from 'lodash';
import { SemanticCOLORS } from 'semantic-ui-react';
import { SEVERITY_COLOURS_BRIGHT } from 'utils/constants';

export default function getSemanticColourFromSeverity(
  severity: number | null,
  defaultColour: SemanticCOLORS
): SemanticCOLORS {
  const colorLookup = SEVERITY_COLOURS_BRIGHT;
  const finalFallbackColour = 'grey';

  if (severity === null || !_.has(colorLookup, severity)) {
    return defaultColour || finalFallbackColour;
  } else {
    return colorLookup[severity];
  }
}