import { EDIT, EDIT_FULFILLED, EDIT_ERROR } from '../../actions/entity';

export default (state = false, action) => {
  switch (action.type) {
    case EDIT:
      return action.payload.entity.id;
    case EDIT_FULFILLED:
    case EDIT_ERROR:
      return null;
    default:
      return state;
  }
};

export const getEditingId = state => state;