import all, * as fromAll from './all';
import fromDate, * as fromFromDate from './fromDate';
import toDate, * as fromToDate from './toDate';
import labels, * as fromLabels from './labels';
import { combineReducers } from 'redux';

export default combineReducers({
  all,
  fromDate,
  toDate,
  labels
});

export const getAll = state => fromAll.getAll(state.all);
export const getFromDate = state => fromFromDate.getFromDate(state.fromDate);
export const getToDate = state => fromToDate.getToDate(state.toDate);
export const getLabels = state => fromLabels.getLabels(state.labels);