import _ from 'lodash';
import { createSelector } from 'reselect';
import { ENTITY_TYPE, UNIT_PATH_SEPARATOR } from '../../../../utils/constants';
import * as fromState from '../../../reducers';
import { Unit } from 'types';

function getPath(unit: Unit, allUnits: { [id: string]: Unit; }): string {
  if (!unit.unitId) {
    return unit.name;
  };
  return `${getPath(allUnits[unit.unitId], allUnits)}${UNIT_PATH_SEPARATOR}${unit.name}`;
}

export const getUnitPaths = createSelector(
  [
    state => fromState.getAll<Unit>(state, ENTITY_TYPE.UNIT)
  ],
  allUnits => _.mapValues(allUnits, unit => getPath(unit, allUnits))
);