import TrendTimelineContainer from 'containers/chart/Timeline/TrendTimelineContainer';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { Grid, Segment, Table } from 'semantic-ui-react';
import ChartKeyContainer from '../../../containers/chart/ChartKeyContainer';
import { HeatMapCellContainer } from '../../../containers/chart/HeatMapChartContainer';
import { CHART_TYPE, INVERTED_THEME, VALUE_TYPE } from '../../../utils/constants';
import styles from './HeatMapChart.styles';
import HeatMapPopup from './HeatMapPopup';

const HeatMapChart = ({
  yDomain,
  data,
  isLoading,
  chartType
}) => (
  <Grid
    stackable
    className='no-padding full-height'>
    <Grid.Row>
      <Grid.Column
        mobile={16}
        tablet={5}
        computer={5}
        largeScreen={4}
        widescreen={3}>
        <ChartKeyContainer chartType={chartType} />
      </Grid.Column>
      <Grid.Column
        mobile={16}
        tablet={11}
        computer={11}
        largeScreen={12}
        widescreen={13}>
        <div style={styles.timelineWrapper}>
          <TrendTimelineContainer chartType={chartType} />
        </div>
        <Segment
          className='transparent-background'
          inverted={INVERTED_THEME}
          loading={isLoading}
          style={styles.chartSegment}>
          {_.some(yDomain) && (
            <Table
              inverted={INVERTED_THEME}
              celled
              style={styles.chartTable}>
              <Table.Body>
                {_.map(yDomain, (subDomain, i) => (
                  <Fragment key={i}>
                    {_.map(subDomain, y => (
                      <Table.Row key={y}>
                        {_.map(data[y], dataEntry => (
                          <HeatMapPopup
                            key={dataEntry.subheader}
                            chartType={chartType}
                            {...dataEntry}
                            trigger={(
                              <HeatMapCellContainer
                                chartType={chartType}
                                id={dataEntry.id}
                                colour={dataEntry.colour} />
                            )} />
                        ))}
                      </Table.Row>
                    ))}
                    <Table.Row>
                      <Table.Cell colSpan={_.size(data[_.first(subDomain)])} />
                    </Table.Row>
                  </Fragment>
                ))}
              </Table.Body>
            </Table>
          )}
        </Segment>
      </Grid.Column>
    </Grid.Row>
  </Grid>
);

HeatMapChart.propTypes = {
  yDomain: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string.isRequired)).isRequired,
  data: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.exact({
    header: PropTypes.string.isRequired,
    subheader: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    min: PropTypes.number,
    max: PropTypes.number,
    scale: PropTypes.number,
    alarmRanges: PropTypes.arrayOf(PropTypes.exact({
      min: PropTypes.number.isRequired,
      max: PropTypes.number.isRequired,
      severity: PropTypes.number.isRequired
    })),
    alarmLevels: PropTypes.arrayOf(PropTypes.number),
    noData: PropTypes.bool.isRequired,
    valueCount: PropTypes.number.isRequired,
    singleValue: PropTypes.any,
    activeStates: PropTypes.arrayOf(PropTypes.number),
    valueType: PropTypes.oneOf(_.values(VALUE_TYPE)),
    unitsOfMeasurement: PropTypes.string,
    colour: PropTypes.string.isRequired
  }))),
  isLoading: PropTypes.bool.isRequired,
  chartType: PropTypes.oneOf(_.values(CHART_TYPE))
};

HeatMapChart.displayName = 'HeatMapChart';

export default HeatMapChart;