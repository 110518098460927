import { defineMessages } from 'react-intl';

export default defineMessages({
  'noData': {
    id: 'heatMapChart.noData',
    defaultMessage: 'No data'
  },
  'nValues': {
    id: 'heatMapChart.nValues',
    defaultMessage: '{valueCount, number} values'
  }
});