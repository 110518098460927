import { defineMessages } from 'react-intl';

export default defineMessages({
  'functionSensor.description.placeholder': {
    id: 'chartKey.functionSensor.description.placeholder',
    defaultMessage: 'Description'
  },
  'header.addButton.text': {
    id: 'chartKey.addButton.text',
    defaultMessage: 'Add'
  },
  'header.featuresToggle.text': {
    id: 'chartKey.header.featuresToggle.text',
    defaultMessage: 'Apply Feature'
  },
  'header.featureDropdown.popupText': {
    id: 'chartKey.header.featureDropdown.popupText',
    defaultMessage: 'Feature'
  },
  'header.featureWindowDropwdown.popupText': {
    id: 'chartKey.header.featureWindowDropwdown.popupText',
    defaultMessage: 'Feature Window'
  },
  'header.propertyDropdown.popupText': {
    id: 'chartKey.header.propertyDropdown.popupText',
    defaultMessage: 'Property'
  },
  'functionSensor.removeButton.popupText': {
    id: 'chartKey.functionSensor.removeButton.placeholder',
    defaultMessage: 'Remove'
  },
  'functionSensor.editDescriptionButton.popupText': {
    id: 'chartKey.functionSensor.editDescriptionButton.popupText',
    defaultMessage: 'Edit Description'
  }
});