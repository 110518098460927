import { SET_FEATURE_WINDOW } from '../../../../actions/chart';
import { quickRanges } from 'utils/dates';

export default (state = quickRanges[0], action) => {
  switch (action.type) {
    case SET_FEATURE_WINDOW:
      return action.payload.value;
    default:
      return state;
  };
};

export const getFeatureWindow = state => state;