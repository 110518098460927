import performAuth from './performAuth';
import logoutOnNavigate from './logoutOnNavigate';
import navigateHomeOnLogout from './navigateHomeOnLogout';
import navigateToLoginOnUnauthorised from './navigateToLoginOnUnauthorised';
import navigateToErrorPageOnGatewayTimeout from './navigateToErrorPageOnGatewayTimeout';
import navigateToErrorPageOnServiceUnavailable from './navigateToErrorPageOnServiceUnavailable';
import navigateToErrorPageOnNotFound from './navigateToErrorPageOnNotFound';
import authFlow from './authFlow';
import clearAuthMessageOnNavigate from './clearAuthMessageOnNavigate';
import resetAppOnSwitchTenant from './resetAppOnSwitchTenant';
import { combineEpicsWithNames } from '../../../utils/helpers';

export default combineEpicsWithNames({
  performAuth,
  logoutOnNavigate,
  navigateHomeOnLogout,
  navigateToLoginOnUnauthorised,
  navigateToErrorPageOnGatewayTimeout,
  navigateToErrorPageOnServiceUnavailable,
  navigateToErrorPageOnNotFound,
  authFlow,
  clearAuthMessageOnNavigate,
  resetAppOnSwitchTenant
});