import { CHART_TYPE, ENTITY_TYPE } from '../../../../utils/constants';
import * as fromState from '../../../reducers';
import { getSeverity } from './getSeverity';
import createCachedSelector from 're-reselect';

export const getPredictorSeverity = createCachedSelector(
  [
    (state, { id }) => fromState.getById(state, ENTITY_TYPE.PREDICTOR, id),
    state => fromState.getAllLatestSensorValues(state, CHART_TYPE.MAIN),
    state => fromState.getAllLatestOutputValues(state, CHART_TYPE.MAIN),
    state => fromState.getAll(state, ENTITY_TYPE.SENSOR)
  ],
  getSeverity
)(
  (state, { id }) => id
);