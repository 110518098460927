import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import EnumerationStateElement from '../../components/entity/EnumerationStateElement';
import * as fromState from '../../state/reducers';
import { ENTITY_TYPE } from '../../utils/constants';

const mapStateToProps = createSelector(
  [
    state => fromState.getAll(state, ENTITY_TYPE.ENUMERATION)
  ],
  (
    allEnumerations
  ) => ({
    allEnumerations
  })
);

export default connect(mapStateToProps)(EnumerationStateElement);