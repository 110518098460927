import _ from 'lodash';
import { combineEpics } from 'redux-observable';
import { map } from 'rxjs/operators';

const addNameToOutputActions = name => action$ => action$.pipe(
  map(action => ({
    ...action,
    DISPATCHED_FROM_EPIC: name 
  }))
);
const combineEpicsWithNames = epics => combineEpics(..._.chain(epics)
  .toPairs()
  .map(([name, epic]) => (...args) => epic(...args).pipe(addNameToOutputActions(name)))
  .value());

export default process.env.NODE_ENV !== 'production'
  ? combineEpicsWithNames
  : epics => combineEpics(..._.values(epics));