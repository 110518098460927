import _ from 'lodash';
import createCachedSelector from 're-reselect';
import { getInputs } from '../getInputs';
import { getInputOffsetScale, getOutputOffsetScale, getFunctionOffsetScale } from '../getOffsetScale';
import { getOutputs } from '../getOutputs';
import { getOutputData } from '../getOutputData';
import { getFunctionSensorIds } from '../getFunctionSensorIds';
import createDataFeatureSelector from './createDataFeatureSelector';
import { getFullInputData } from './getFullInputData';
import { getFullFunctionData } from './getFullFunctionData';
import { getInputData } from '../getInputData';
import { getFunctionData } from '../getFunctionData';
import { getInputSensors } from '../getInputSensors';
import { getFunctionSensors } from '../getFunctionSensors';
import { getOutputProperties } from '../getOutputProperties';
import { getInputEnumerations, getOutputEnumerations, getFunctionEnumerations } from '../getEnumerations';
import * as fromState from '../../../reducers';
import { VALUE_TYPE, ENTITY_PROPERTY } from '../../../../utils/constants';
import largestTriangleThreeBuckets from './largestTriangleThreeBuckets';
import moment from 'moment';

const DURATION_INTERVALS = [
  [28 * 24 * 60 * 60, 3600],
  [14 * 24 * 60 * 60, 1800],
  [3 * 24 * 60 * 60, 600],
  [0, null]
];

const getValue = (value, valueType, enumeration, { offset, scale }) => {
  if (valueType === VALUE_TYPE.FLOAT) {
    return (value - offset) / scale;
  };
  if (valueType === VALUE_TYPE.ENUMERATION) {
    return {
      text: enumeration ? enumeration.states[value] : value,
      number: (value - offset) / scale
    };
  };
  return value;
};

export const getChartData = createCachedSelector(
  [
    getInputs,
    getInputData,
    createDataFeatureSelector(getFullInputData, getInputSensors),
    getInputOffsetScale,
    getInputSensors,
    getInputEnumerations,
    getOutputs,
    getOutputData,
    getOutputOffsetScale,
    getOutputProperties,
    getOutputEnumerations,
    getFunctionSensorIds,
    getFunctionData,
    createDataFeatureSelector(getFullFunctionData, getFunctionSensors),
    getFunctionOffsetScale,
    getFunctionSensors,
    getFunctionEnumerations,
    fromState.getFeaturesToggle,
    fromState.getSelectedFeature,
    fromState.getBrushStartTimestamp,
    fromState.getBrushEndTimestamp,
    fromState.getQuickRange
  ],
  (
    inputs,
    inputData,
    inputFeatureData,
    inputOffsetScale,
    inputSensors,
    inputEnumerations,
    outputs,
    outputData,
    outputOffsetScale,
    outputProperties,
    outputEnumerations,
    functionSensorIds,
    functionData,
    functionFeatureData,
    functionOffsetScale,
    functionSensors,
    functionEnumerations,
    featuresToggle,
    selectedFeature,
    brushStartTimestamp,
    brushEndTimestamp,
    quickRange
  ) => {
    const duration = brushEndTimestamp
      ? (brushEndTimestamp - brushStartTimestamp)
      : moment.duration(quickRange).as('seconds');
    const interval = _.find(
      DURATION_INTERVALS,
      durationInterval => duration >= durationInterval[0]
    )[1];
    const chartInputData = featuresToggle ? inputFeatureData : inputData;
    const chartFunctionData = featuresToggle ? functionFeatureData : functionData;
    return _.chain([
      ..._.map(
        inputs,
        input => _.map(
          largestTriangleThreeBuckets(
            chartInputData[input],
            interval,
            brushStartTimestamp,
            brushEndTimestamp,
            _.get(inputSensors[input], ENTITY_PROPERTY.SENSOR.VALUE_TYPE)
          ),
          ([timestamp, value]) => ({
            [input]: getValue(
              featuresToggle ? value[selectedFeature] : value,
              _.get(inputSensors[input], ENTITY_PROPERTY.SENSOR.VALUE_TYPE),
              inputEnumerations[input],
              inputOffsetScale[input]
            ),
            timestamp
          })
        )
      ),
      ..._.map(
        functionSensorIds,
        id => _.map(
          largestTriangleThreeBuckets(
            chartFunctionData[id],
            interval,
            brushStartTimestamp,
            brushEndTimestamp,
            _.get(functionSensors[id], ENTITY_PROPERTY.SENSOR.VALUE_TYPE)
          ),
          ([timestamp, value]) => ({
            [id]: getValue(
              featuresToggle ? value[selectedFeature] : value,
              _.get(functionSensors[id], ENTITY_PROPERTY.SENSOR.VALUE_TYPE),
              functionEnumerations[id],
              functionOffsetScale[id]
            ),
            timestamp
          })
        )
      ),
      ..._.map(
        outputs,
        output => _.map(
          largestTriangleThreeBuckets(
            outputData[output],
            interval,
            brushStartTimestamp,
            brushEndTimestamp,
            _.get(outputProperties[output], ENTITY_PROPERTY.SENSOR.VALUE_TYPE)
          ),
          ([timestamp, value]) => ({
            [output]: getValue(
              value,
              _.get(outputProperties[output], ENTITY_PROPERTY.SENSOR.VALUE_TYPE),
              outputEnumerations[output],
              outputOffsetScale[output]
            ),
            timestamp
          })
        )
      )
    ])
      .flatten()
      .groupBy('timestamp')
      .values()
      .map(_.spread(_.extend))
      .orderBy('timestamp')
      .value();
  }
)(
  (state, chartType) => chartType
);