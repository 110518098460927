import _ from 'lodash';
import { Fragment } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Divider, Sidebar } from 'semantic-ui-react';
import ChangeFormContainer from '../../containers/auth/ChangeFormContainer';
import ConfirmFormContainer from '../../containers/auth/ConfirmFormContainer';
import ForgotFormContainer from '../../containers/auth/ForgotFormContainer';
import IsAuthenticated from '../../containers/auth/IsAuthenticated';
import IsNotAuthenticated from '../../containers/auth/IsNotAuthenticated';
import JoinFormContainer from '../../containers/auth/JoinFormContainer';
import LoginFormContainer from '../../containers/auth/LoginFormContainer';
import RegisterFormContainer from '../../containers/auth/RegisterFormContainer';
import ResendFormContainer from '../../containers/auth/ResendFormContainer';
import ChartPageContainer from '../../containers/chart/ChartPageContainer';
import DashboardChartContainer from '../../containers/dashboard/DashboardChartContainer';
import EntityEventsContainer from '../../containers/entity/EntityEventsContainer';
import EntityPageContainer from '../../containers/entity/EntityPageContainer';
import BreadcrumbsContainer from '../../containers/navigation/BreadcrumbsContainer';
import { PAGE_URL } from '../../utils/constants';
import entityConfig from '../../utils/entities';
import { toPath } from '../../utils/helpers';
import GatewayTimeoutPage from '../auth/GatewayTimeoutPage';
import NotFoundPage from '../auth/NotFoundPage';
import ServiceUnavailablePage from '../auth/ServiceUnavailablePage';
import MainMenu from './MainMenu';
import LoadingModalContainer from 'containers/loading/LoadingModalContainer';
import queryString from 'query-string';
import AuthMenuContainer from '../../containers/auth/AuthMenuContainer';
import QuerySyncLink from '../../containers/navigation/QuerySyncLink';
import SpartanLogo from '../../spartan.svg';

const SIDEBAR_WIDTH = '44px';
export default function Routes() {
  return (
    <Fragment>
      <Sidebar
        visible
        width='thin'
        style={{ width: SIDEBAR_WIDTH }}>
        <Route
          path='/'
          render={({ location }) => (
            <MainMenu pathname={location.pathname} />
          )} />
      </Sidebar>
      <div className='page-header'>
        <span className='logo-wrapper'>
          <QuerySyncLink to={PAGE_URL.HOME}>
            <img
              src={SpartanLogo}
              alt='Spartan Solutions Ltd.'
              height='50px' />
          </QuerySyncLink>
        </span>
        <BreadcrumbsContainer />
        <AuthMenuContainer />
      </div>
      <LoadingModalContainer />
      <div style={{
        paddingTop: '60px',
        paddingLeft: SIDEBAR_WIDTH,
        height: 'calc(100% - 60px)'
      }}>
        <IsAuthenticated>
          <EntityEventsContainer />
          <Route
            path={PAGE_URL.HOME}
            exact>
            <DashboardChartContainer />
          </Route>
          <Route
            path={PAGE_URL.UNIT}
            exact>
            <DashboardChartContainer />
          </Route>
          <Route
            path={PAGE_URL.UNIT_TYPE}
            exact>
            <DashboardChartContainer />
          </Route>
          <Route
            path={PAGE_URL.UNIT_TYPE_PREDICTOR}
            exact>
            <DashboardChartContainer />
          </Route>
          {_.flatMap(entityConfig, (config, entityType) => {
            const ItemPageComponent = config.itemPageComponent;
            return [
              ...config.pageUrl
                ? [
                  (
                    <Route
                      key={entityType}
                      path={`${toPath(PAGE_URL.ENTITY_PAGE, {
                        pageUrl: config.pageUrl
                      })}${config.parentEntityType ? PAGE_URL.ENTITY_PARENT : ''}`}
                      exact
                      render={({ match }) => (
                        <EntityPageContainer
                          entityType={entityType}
                          path={match.path}
                          parentId={match.params.parentId} />
                      )} />
                  )
                ]
                : [],
              ...config.itemPageUrl
                ? [
                  (
                    <Route
                      key={`${entityType}.item`}
                      path={config.itemPageUrl}
                      exact
                      render={({ match }) => <ItemPageComponent {...match.params} />}>
                    </Route>
                  )
                ]
                : []
            ];
          })}
          <Route
            path={PAGE_URL.PREDICTOR}
            exact>
            <ChartPageContainer />
          </Route>
        </IsAuthenticated>
        <IsNotAuthenticated>
          <Switch>
            <Route
              path={PAGE_URL.LOGIN}
              exact
              component={LoginFormContainer} />
            <Route
              path={PAGE_URL.REGISTER}
              component={RegisterFormContainer} />
            <Route
              path={PAGE_URL.FORGOT}
              component={ForgotFormContainer} />
            <Route
              path={PAGE_URL.CHANGE}
              component={ChangeFormContainer} />
            <Route
              path={PAGE_URL.JOIN}
              component={JoinFormContainer} />
            <Route
              path={PAGE_URL.CONFIRM}
              component={ConfirmFormContainer} />
            <Route
              path={PAGE_URL.RESEND}
              component={ResendFormContainer} />
            <Redirect
              to={{
                pathname: PAGE_URL.LOGIN,
                search: queryString.stringify({
                  redirect: window.location.pathname
                })
              }} />
          </Switch>
        </IsNotAuthenticated>
        <Route
          path={PAGE_URL.GATEWAY_TIMEOUT}
          exact
          component={GatewayTimeoutPage} />
        <Route
          path={PAGE_URL.SERVICE_UNAVAILABLE}
          exact
          component={ServiceUnavailablePage} />
        <Route
          path={PAGE_URL.NOT_FOUND}
          exact
          component={NotFoundPage} />
      </div>
      <Divider
        section
        hidden />
    </Fragment>
  );
}