import _ from 'lodash';
import { ENTITY_PROPERTY } from '../../../utils/constants';
import { APPEND_MULTIPLE_RANGES, PREPEND_MULTIPLE_RANGES, SET_MULTIPLE_RANGES } from '../../actions/data';

export default (state = {}, action) => {
  switch (action.type) {
    case SET_MULTIPLE_RANGES:
      return {
        ...state,
        ..._.mapKeys(action.payload.outputs, (val, key) => `${action.payload.group}/${key}`)
      };
    case APPEND_MULTIPLE_RANGES:
      return {
        ...state,
        ..._.chain(action.payload.outputs)
          .toPairs()
          .map(([output, values]) => [
            `${action.payload.group}/${output}`,
            _.dropWhile([
              ...state[`${action.payload.group}/${output}`],
              ..._.differenceBy(values, state[`${action.payload.group}/${output}`], ENTITY_PROPERTY.SENSOR_VALUE.TIMESTAMP)
            ], ([timestamp]) => timestamp < action.payload.dropBeforeTimestamp)
          ])
          .fromPairs()
          .value()
      };
    case PREPEND_MULTIPLE_RANGES:
      return {
        ...state,
        ..._.chain(action.payload.outputs)
          .toPairs()
          .map(([output, values]) => [
            `${action.payload.group}/${output}`,
            _.dropRightWhile([
              ..._.differenceBy(values, state[`${action.payload.group}/${output}`], ENTITY_PROPERTY.SENSOR_VALUE.TIMESTAMP),
              ...state[`${action.payload.group}/${output}`]
            ], ([timestamp]) => timestamp > action.payload.dropAfterTimestamp)
          ])
          .fromPairs()
          .value()
      };
    default:
      return state;
  }
};

export const getAllOutputRanges = state => state;