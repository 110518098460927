import { COLOUR } from '../../../utils/constants';

export default {
  segmentGroup: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  keySegment: {
    background: COLOUR.DARK_GREY,
    overflowY: 'scroll',
    padding: 0
  },
  itemGroupTable: {
    borderRadius: 0,
    tableLayout: 'fixed'
  },
  itemGroupTableRow: {
    cursor: 'pointer'
  },
  rowTitle: {
    wordBreak: 'break-word'
  },
  rowDescription: {
    wordBreak: 'break-all'
  },
  keyColourIcon: colour => ({
    color: colour
  }),
  hoverButton: {
    border: 'none'
  },
  editablePopup: {
    background: COLOUR.MEDIUM_GREY,
    border: '1px solid white'
  },
  header: {
    padding: 0
  },
  featureDropdownPopup: {
    background: COLOUR.MEDIUM_GREY,
    border: '1px solid white'
  },
  featuresToggle: {
    float: 'right'
  }
};