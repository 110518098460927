import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import DashboardChart from '../../components/dashboard/DashboardChart/DashboardChart';
import * as fromState from '../../state/reducers';
import * as dashboardSelectors from '../../state/selectors/dashboard';
import { getUnitId, getUnitTypeId, getSensorCards } from '../../state/selectors/dashboard';
import { DASHBOARD_VIEW, PAGE_URL, CHART_TYPE } from '../../utils/constants';

const mapStateToProps = createSelector(
  [
    dashboardSelectors.getCards,
    state => getSensorCards(state, CHART_TYPE.MAIN),
    getUnitId,
    getUnitTypeId,
    state => fromState.getDashboardView(state),
    state => fromState.getCardSize(state),
    state => state.router.location.pathname,
    state => fromState.getShowCardDetails(state)
  ],
  (
    cards,
    sensorCards,
    unitId,
    unitTypeId,
    view,
    cardSize,
    pathname,
    showDetails
  ) => ({
    cards,
    sensorCards: unitId ? sensorCards : null,
    view,
    cardSize,
    unitOrUnitTypeId: view === DASHBOARD_VIEW.UNITS ? unitId : unitTypeId,
    isTopLevel: pathname === PAGE_URL.HOME,
    showDetails
  })
);

export default connect(mapStateToProps)(DashboardChart);