import { SET_QUICK_RANGE } from '../../../../actions/chart';

export default (state = 'P1D', action: any): string => {
  switch (action.type) {
    case SET_QUICK_RANGE:
      return action.payload.value;
    default:
      return state;
  }
};

export const getQuickRange = (state: string): string => state;