import _ from 'lodash';
import { createSelector } from 'reselect';
import * as fromState from '../../../state/reducers';
import { ENTITY_TYPE, ENTITY_PROPERTY, ATTRIBUTE_SOURCE } from '../../constants';
import { getModelOutputName } from 'utils/helpers';

const getAttributes = (allUnitTypes, allUnitTypeAttributes, unitTypeId) => {
  const unitType = allUnitTypes[unitTypeId];
  return unitType
    ? [
      ..._.filter(allUnitTypeAttributes, attr => attr.unitType === unitTypeId),
      ...getAttributes(allUnitTypes, unitType.baseType)
    ]
    : [];
};

export default createSelector(
  [
    (state, { parentId }) => fromState.getById(state, ENTITY_TYPE.UNIT_TYPE_PREDICTOR, parentId),
    state => fromState.getAll(state, ENTITY_TYPE.MODEL),
    state => fromState.getAll(state, ENTITY_TYPE.UNIT_TYPE),
    state => fromState.getAll(state, ENTITY_TYPE.UNIT_TYPE_ATTRIBUTE),
    state => fromState.getLabels(state, ENTITY_TYPE.UNIT_TYPE_ALERT_MAPPING)
  ],
  (unitTypePredictor, allModels, allUnitTypes, allUnitTypeAttributes, labels) => {
    const model = _.get(allModels, unitTypePredictor && unitTypePredictor.type);
    const outputs = _.chain(model)
      .get(ENTITY_PROPERTY.MODEL.OUTPUTS)
      .map(output => ({
        id: output.name,
        ...output,
        text: getModelOutputName(model, output.name)
      }))
      .value();
    return {
      outputs,
      attributes: [
        ..._.map(
          getAttributes(allUnitTypes, allUnitTypeAttributes, unitTypePredictor && unitTypePredictor.unitType),
          attribute => ({
            id: `${ATTRIBUTE_SOURCE.UNIT_TYPE_ATTRIBUTE}_${attribute.name}`,
            source: ATTRIBUTE_SOURCE.UNIT_TYPE_ATTRIBUTE,
            text: attribute.name,
            ...attribute
          })
        ),
        ..._.map(outputs, output => ({
          source: ATTRIBUTE_SOURCE.MODEL_OUTPUT,
          ...output,
          id: `${ATTRIBUTE_SOURCE.MODEL_OUTPUT}_${output.id}`,
          text: output.id
        }))
      ],
      labels: _.map(labels, label => ({
        id: label.name
      }))
    };
  }
);