import { distinctUntilChanged, map, mergeMap, filter, catchError } from 'rxjs/operators';
import * as api from '../../../utils/api';
import { ENTITY_CONFIG, ENTITY_TYPE } from '../../../utils/constants';
import { insertMany } from '../../actions/entity';
import * as fromState from '../../reducers';
import { getUnitId } from '../../selectors/dashboard';
import entityConfig from '../../../utils/entities';
import { takeUntilAppReset } from 'utils/helpers';
import { Observable } from 'rxjs';
import { RootState } from 'index';

export default (action$: any, state$: Observable<RootState>) => state$.pipe(
  filter(state => fromState.getUser(state)),
  map(state => [getUnitId(state), state]),
  distinctUntilChanged(([previousId], [currentId]) => previousId === currentId),
  mergeMap(([id, state]) => api.query({
    controller: entityConfig[ENTITY_TYPE.SENSOR][ENTITY_CONFIG.API_PATH],
    page: 0,
    pageSize: 1000,
    query: {
      sort: {},
      filter: {
        UnitId: {
          $eq: id
        }
      }
    },
    token: fromState.getUser(state).token
  }).pipe(
    takeUntilAppReset(action$),
    map(({ items }) => insertMany(ENTITY_TYPE.SENSOR, items)),
    catchError(api.onError)
  ))
);