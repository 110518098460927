import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { Segment, Table, Ref } from 'semantic-ui-react';
import { TooltipTableRowContainer } from '../../../containers/chart/TimeSeriesChartContainer';
import { DATE_FORMAT, INVERTED_THEME, SENSOR_VALUE_DATE_FORMAT, CHART_TYPE, VALUE_TYPE } from '../../../utils/constants';
import styles from './TimeSeriesChart.styles';

const CustomTooltip = ({
  chartType,
  tableBodyRef,
  active,
  payload,
  label,
  lines
}) => {
  if (!active || _.isUndefined(label)) {
    return null;
  }

  return (
    <Segment
      inverted={INVERTED_THEME}
      style={styles.tooltipSegment}
      as={Table}
      size='mini'
      compact>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell colSpan={3}>
            {moment(label, SENSOR_VALUE_DATE_FORMAT).format(DATE_FORMAT)}
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Ref innerRef={tableBodyRef}>
        <Table.Body style={styles.tooltipTableBody}>
          {_.map(lines, (linesGroup, i) => (
            <Fragment key={i}>
              {_.map(linesGroup, line => (
                <TooltipTableRowContainer
                  {..._.find(payload, ['name', line.id])}
                  chartType={chartType}
                  key={line.id}
                  timestamp={label}
                  line={line} />
              ))}
              <Table.Row>
                <Table.HeaderCell
                  colSpan={3}
                  style={styles.tooltipEmptyRow}>
                </Table.HeaderCell>
              </Table.Row>
            </Fragment>
          ))}
        </Table.Body>
      </Ref>
    </Segment>
  );
};

CustomTooltip.propTypes = {
  active: PropTypes.bool.isRequired,
  chartType: PropTypes.oneOf(_.values(CHART_TYPE)),
  payload: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.number
  })),
  label: PropTypes.number,
  lines: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.exact({
    id: PropTypes.string.isRequired,
    className: PropTypes.string.isRequired,
    offset: PropTypes.number,
    scale: PropTypes.number,
    ranges: PropTypes.arrayOf(PropTypes.exact({
      min: PropTypes.number.isRequired,
      max: PropTypes.number.isRequired,
      severity: PropTypes.number.isRequired
    })),
    colour: PropTypes.string.isRequired,
    valueScale: PropTypes.number.isRequired,
    description: PropTypes.string.isRequired,
    strokeWidthMultiplier: PropTypes.number.isRequired,
    unitsOfMeasurement: PropTypes.string,
    valueType: PropTypes.oneOf(_.values(VALUE_TYPE))
  }))).isRequired
};

CustomTooltip.displayName = 'CustomTooltip';

export default CustomTooltip;