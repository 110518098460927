import _ from 'lodash';
import PropTypes from 'prop-types';
import { Icon, Table } from 'semantic-ui-react';
import styles from './TimeSeriesChart.styles';
import { VALUE_TYPE } from '../../../utils/constants';
import { formatValueType } from '../../../utils/helpers';

export const formatNumber = (value, scale) => {
  const abs = Math.abs(value);
  if (abs < 1 && abs > 0.01) {
    return (scale * value).toPrecision(2);
  } else if (abs <= 0.01 && abs > 0) {
    return (scale * value).toExponential(2);
  } else if (abs > 1000000) {
    return (scale * value).toExponential(2);
  } else if (!Number.isInteger(value)) {
    return (scale * value).toFixed(2);
  }
  return scale * value;
};

export const formatValue = (value, valueScale, scale, offset, unitsOfMeasurement, valueType) => {
  if (_.isUndefined(value)) {
    return '-';
  };
  if (valueType === VALUE_TYPE.FLOAT) {
    const formattedValue = formatNumber(value * scale + offset, valueScale);
    return formatValueType(formattedValue, valueType, unitsOfMeasurement);
  };
  if (valueType === VALUE_TYPE.ENUMERATION) {
    return formatValueType(value.text, valueType, unitsOfMeasurement);
  };
  return formatValueType(value, valueType, unitsOfMeasurement);
};

const TooltipTableRow = ({
  payload,
  hoverLine,
  line,
  mostRecentValue
}) => (
  <Table.Row active={line.id === hoverLine}>
    <Table.Cell>
      <Icon
        name='circle'
        style={styles.tooltipRowIcon(line.colour)} />
    </Table.Cell>
    <Table.Cell>
      {line.description}
    </Table.Cell>
    <Table.Cell>
      {(_.isNull(mostRecentValue) || _.isUndefined(mostRecentValue))
        ? formatValue(
          _.get(payload, line.id),
          line.valueScale,
          line.scale,
          line.offset,
          line.unitsOfMeasurement,
          line.valueType
        )
        : (
          <em style={styles.mostRecentValue}>
            {formatValue(
              mostRecentValue,
              line.valueScale,
              line.scale,
              line.offset,
              line.unitsOfMeasurement,
              line.valueType
            )}
          </em>
        )}
    </Table.Cell>
  </Table.Row>
);

TooltipTableRow.propTypes = {
  payload: PropTypes.object,
  hoverLine: PropTypes.string,
  line: PropTypes.exact({
    id: PropTypes.string.isRequired,
    className: PropTypes.string.isRequired,
    offset: PropTypes.number,
    scale: PropTypes.number,
    ranges: PropTypes.arrayOf(PropTypes.exact({
      min: PropTypes.number.isRequired,
      max: PropTypes.number.isRequired,
      severity: PropTypes.number.isRequired
    })),
    colour: PropTypes.string.isRequired,
    valueScale: PropTypes.number.isRequired,
    strokeWidthMultiplier: PropTypes.number.isRequired,
    description: PropTypes.string.isRequired,
    unitsOfMeasurement: PropTypes.string,
    valueType: PropTypes.oneOf(_.values(VALUE_TYPE))
  }),
  mostRecentValue: PropTypes.any
};

TooltipTableRow.displayName = 'TooltipTableRow';

export default TooltipTableRow;