import { ofType } from 'redux-observable';
import { merge } from 'rxjs';
import { filter, map, withLatestFrom } from 'rxjs/operators';
import { ENTITY_MODAL } from 'utils/constants';
import { EDIT_FULFILLED, toggleModal } from '../../actions/entity';
import * as fromState from '../../reducers';

export default (action$, state$) => {
  const editFulfilledAction$ = action$.pipe(
    ofType(EDIT_FULFILLED),
    withLatestFrom(state$)
  );
  return merge(
    editFulfilledAction$.pipe(
      filter(([action, state]) => fromState.getModalOpen(state, action.entityType, ENTITY_MODAL.EDIT)),
      map(([action, state]) => toggleModal(action.entityType, ENTITY_MODAL.EDIT))
    ),
    editFulfilledAction$.pipe(
      filter(([action, state]) => fromState.getModalOpen(state, action.entityType, ENTITY_MODAL.ALTERNATIVE_EDIT)),
      map(([action, state]) => toggleModal(action.entityType, ENTITY_MODAL.ALTERNATIVE_EDIT))
    )
  );
};