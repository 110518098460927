import incrementPlaybackTime from './incrementPlaybackTime';
import liveUpdateDashboard from './liveUpdateDashboard';
import liveUpdateChart from './liveUpdateChart';
import changePlaybackTime from './changePlaybackTime';
import jumpToLive from './jumpToLive';
import loadReview from './loadReview';
import navigateOnChangeReviewTrend from './navigateOnChangeReviewTrend';
import { combineEpicsWithNames } from '../../../utils/helpers';

export default combineEpicsWithNames({
  incrementPlaybackTime,
  liveUpdateDashboard,
  liveUpdateChart,
  changePlaybackTime,
  jumpToLive,
  loadReview,
  navigateOnChangeReviewTrend
});