import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  add,
  toggleModal
} from '../../state/actions/entity';
import EntityFormModal from '../../components/entity/EntityFormModal';
import { defineMessages } from 'react-intl';
import _ from 'lodash';
import * as fromState from '../../state/reducers';
import { ENTITY_FORM_TYPE, ENTITY_CONFIG, ENTITY_MODAL } from '../../utils/constants';
import entityConfig from '../../utils/entities';
import createCachedSelector from 're-reselect';

const messages = defineMessages({
  'header.text': {
    id: 'addEntityModal.header.text',
    defaultMessage: 'Add'
  },
  'cancelButton.text': {
    id: 'addEntityModal.cancelButton.text',
    defaultMessage: 'Cancel'
  },
  'confirmButton.text': {
    id: 'addEntityModal.addButton.text',
    defaultMessage: 'Add'
  }
});

const mapStateToProps = createCachedSelector(
  [
    (state, { entityType, parentId }) =>
      entityConfig[entityType][ENTITY_CONFIG.PARENT_ENTITY_TYPE]
      && fromState.getById(state, entityConfig[entityType][ENTITY_CONFIG.PARENT_ENTITY_TYPE], parentId),
    (state, { entityType }) => fromState.getIsAdding(state, entityType),
    (state, { entityType }) => fromState.getModals(state, entityType),
    (state, { entityType }) => fromState.getFormValues(state, entityType, ENTITY_FORM_TYPE.ADD_OR_EDIT),
    (state, { entityType }) => fromState.getModalOpen(state, entityType, ENTITY_MODAL.ADD),
    (state, { createEntity, entityType }) => createEntity || entityConfig[entityType].createEntity
  ],
  (
    parent,
    isSaving,
    modals,
    formValues,
    open,
    createEntity
  ) => ({
    parent,
    isSaving,
    modals,
    formValues,
    messages,
    open,
    name: 'add',
    icon: 'add',
    mode: 'add',
    lockType: false,
    createEntity
  })
)(
  (state, { entityType }) => entityType
);

const mapDispatchToProps = (dispatch, { createEntity, entityType }) => {
  const formValuesToEntity = (formValues, parent) => {
    if (createEntity) {
      return createEntity(formValues, parent);
    } else {
      return entityConfig[entityType].createEntity(formValues, parent);
    }
  };

  const confirmAction = (formValues, parent) => {
    if (entityConfig[entityType].createAction) {
      return entityConfig[entityType].createAction(formValues, parent);
    } else {
      return add(entityType, formValuesToEntity(formValues, parent));
    }
  };

  return bindActionCreators({
    confirm: confirmAction,
    toggleModal: _.partial(toggleModal, entityType)
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(EntityFormModal);