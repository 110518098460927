import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  edit,
  toggleModal
} from '../../state/actions/entity';
import EntityFormModal from '../../components/entity/EntityFormModal';
import { defineMessages } from 'react-intl';
import _ from 'lodash';
import * as fromState from '../../state/reducers';
import entityConfig from '../../utils/entities';
import { ENTITY_FORM_TYPE, ENTITY_CONFIG } from '../../utils/constants';

const messages = defineMessages({
  'header.text': {
    id: 'editEntityModal.header.text',
    defaultMessage: 'Edit'
  },
  'cancelButton.text': {
    id: 'editEntityModal.cancelButton.text',
    defaultMessage: 'Cancel'
  },
  'confirmButton.text': {
    id: 'editEntityModal.saveButton.text',
    defaultMessage: 'Save'
  }
});

const mapStateToProps = (state, { entityType, entity, parentId, createEntity }) => ({
  parent: entityConfig[entityType][ENTITY_CONFIG.PARENT_ENTITY_TYPE] && fromState.getById(state, entityConfig[entityType][ENTITY_CONFIG.PARENT_ENTITY_TYPE], parentId),
  isSaving: fromState.getIsEditing(state, entityType),
  modals: fromState.getModals(state, entityType),
  formValues: fromState.getFormValues(state, entityType, ENTITY_FORM_TYPE.ADD_OR_EDIT),
  messages,
  open: fromState.getModalOpen(state, entityType, 'edit') && fromState.getModalId(state, entityType, 'edit') === entity.id,
  name: 'edit',
  icon: 'save',
  mode: 'edit',
  lockType: true,
  createEntity: createEntity || entityConfig[entityType].createEntity
});

const mapDispatchToProps = (dispatch, { entityType, entity }) => {
  return bindActionCreators({
    confirm: editedEntity => edit(entityType, {
      ...entity,
      ...editedEntity,
      id: entity.id 
    }),
    toggleModal: _.partial(toggleModal, entityType)
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(EntityFormModal);