import { Header, Segment, Icon } from 'semantic-ui-react';
import { defineMessages, injectIntl } from 'react-intl';
import { INVERTED_THEME } from '../../utils/constants';

const messages = defineMessages({
  'gatewayTimeout.message': {
    id: 'gatewayTimeout.message',
    defaultMessage: 'Gateway Timeout'
  }
});

const GatewayTimeoutPage = injectIntl(({
  intl: {
    formatMessage
  }
}) => (
  <Segment
    basic
    textAlign='center'>
    <Header inverted={INVERTED_THEME}>
      <Icon name='cogs' />
      {formatMessage(messages['gatewayTimeout.message'])}
    </Header>
  </Segment>
));

GatewayTimeoutPage.displayName = 'GatewayTimeoutPage';

export default GatewayTimeoutPage;