import _ from 'lodash';
import createCachedSelector from 're-reselect';
import * as fromState from '../../../reducers';
import { getFunctionSensorIds } from '../getFunctionSensorIds';

export const getFullFunctionData = createCachedSelector(
  [
    getFunctionSensorIds,
    fromState.getAllSensorRanges
  ],
  (ids, allSensorRanges) => {
    return _.chain(ids)
      .map(id => [id, allSensorRanges[id]])
      .fromPairs()
      .value();
  }
)(
  (state, chartType) => chartType
);