import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createSelector } from 'reselect';
import AlarmLevelsButton from '../../components/alarm/AlarmLevelsButton';
import { saveAlarmRanges, toggleModal } from '../../state/actions/entity';
import * as fromState from '../../state/reducers';
import { ENTITY_MODAL, ENTITY_PROPERTY, ENTITY_TYPE } from '../../utils/constants';
import { alarmLevelsToRanges, alarmRangesToLevels } from '../../utils/helpers';

const mapStateToProps = createSelector(
  [
    (state, { id }) => fromState.getById(state, ENTITY_TYPE.SENSOR, id),
    state => fromState.getAll(state, ENTITY_TYPE.ENUMERATION)
  ],
  (
    sensor,
    allEnumerations
  ) => ({
    alarmLevels: alarmRangesToLevels(
      _.get(sensor, ENTITY_PROPERTY.SENSOR.ALARM_RANGES),
      allEnumerations[sensor.enumeration]
    ),
    type: ENTITY_TYPE.SENSOR
  })
);

const mapDispatchToProps = (dispatch, { id }) => bindActionCreators({
  onClick: () => toggleModal(ENTITY_TYPE.SENSOR, ENTITY_MODAL.ALARM, id),
  onSave: alarmLevels => saveAlarmRanges(
    ENTITY_TYPE.SENSOR,
    alarmLevelsToRanges(alarmLevels),
    id
  )
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AlarmLevelsButton);