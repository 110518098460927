import _ from 'lodash';
import createCachedSelector from 're-reselect';
import { getPredictor } from '../getPredictor';

export const getSensors = createCachedSelector(
  [getPredictor],
  predictor => _.isNil(predictor)
    ? []
    : _.map(predictor.inputMappings, inputMapping => inputMapping.sensor)
)(
  (state, chartType) => chartType
);