import TrendTimelineContainer from 'containers/chart/Timeline/TrendTimelineContainer';
import { Grid, Segment } from 'semantic-ui-react';
import ChartKeyContainer from '../../../containers/chart/ChartKeyContainer';
import { TimeSeriesPlotContainer } from '../../../containers/chart/TimeSeriesChartContainer';
import { CHART_TYPE, INVERTED_THEME } from '../../../utils/constants';
import styles from './TimeSeriesChart.styles';
import SplitPane from 'components/shared/SplitPane';

interface Props {
  isLoading: boolean;
  chartType: CHART_TYPE;
  timelineHeight: number;
  setTimelineHeight: (height: number) => void;
}
export default function TimeSeriesChart({
  isLoading,
  timelineHeight,
  chartType,
  setTimelineHeight
}: Props) {
  return (
    <Grid
      stackable
      className='no-padding full-height'>
      <Grid.Row>
        <Grid.Column
          mobile={16}
          tablet={5}
          computer={5}
          largeScreen={4}
          widescreen={3}>
          <ChartKeyContainer
            chartType={chartType}
            showKeyColours
            showFeaturesToggle />
        </Grid.Column>
        <Grid.Column
          mobile={16}
          tablet={11}
          computer={11}
          largeScreen={12}
          widescreen={13}>
          <SplitPane
            size={timelineHeight}
            minSize={20}
            maxSize={500}
            onChange={height => setTimelineHeight(height)}
            resizerStyle={styles.resizer}
            pane2Style={{ height: '100%' }}>
            <div style={styles.timelineWrapper}>
              <TrendTimelineContainer chartType={chartType} />
            </div>
            <Segment
              className='transparent-background'
              inverted={INVERTED_THEME}
              loading={isLoading}
              style={isLoading
                ? styles.plotSegmentLoading
                : styles.plotSegment}>
              <TimeSeriesPlotContainer chartType={chartType} />
            </Segment>
          </SplitPane>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

