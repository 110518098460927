import { ofType } from 'redux-observable';
import { delay, filter, map } from 'rxjs/operators';
import { ADD_FULFILLED, EDIT_FULFILLED, fetchLabels } from '../../actions/entity';
import entityConfig from '../../../utils/entities';
import { ENTITY_CONFIG } from '../../../utils/constants';

export default action$ => action$.pipe(
  ofType(EDIT_FULFILLED, ADD_FULFILLED),
  filter(action => entityConfig[action.entityType][ENTITY_CONFIG.LABELS]),
  delay(2000),
  map(action => fetchLabels(action.entityType))
);