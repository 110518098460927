import { ofType } from 'redux-observable';
import { catchError, filter, map, mergeMap, withLatestFrom, delay } from 'rxjs/operators';
import { ADD_FULFILLED, insertMany } from 'state/actions/entity';
import * as fromState from 'state/reducers';
import * as api from 'utils/api';
import { ENTITY_CONFIG, ENTITY_TYPE } from 'utils/constants';
import entityConfig from 'utils/entities';
import { takeUntilAppReset } from 'utils/helpers';

export default (action$, state$) => action$.pipe(
  ofType(ADD_FULFILLED),
  filter(action => action.entityType === ENTITY_TYPE.PREDICTOR),
  filter(action => !!action.payload.entity.type),
  delay(2500),
  withLatestFrom(state$),
  mergeMap(([action, state]) => api.query({
    controller: entityConfig[ENTITY_TYPE.SENSOR][ENTITY_CONFIG.API_PATH],
    prefix: entityConfig[ENTITY_TYPE.SENSOR][ENTITY_CONFIG.API_PREFIX],
    parentId: 'prophes',
    page: 0,
    pageSize: 1000,
    query: {
      sort: {},
      filter: {
        Type: 'predictorOutput',
        UnitId: action.payload.entity.unitId
      }
    },
    token: fromState.getUser(state).token
  }).pipe(
    takeUntilAppReset(action$),
    map(response => insertMany(ENTITY_TYPE.SENSOR, response.items)),
    catchError(api.onError)
  ))
);