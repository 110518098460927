import { replace } from 'connected-react-router';
import { ofType } from 'redux-observable';
import { map, withLatestFrom } from 'rxjs/operators';
import { addSyncedSearchToLocation } from '../../../utils/helpers';
import { QUERY_SYNC_REPLACE } from '../../actions/navigation';

export default (action$, state$) => action$.pipe(
  ofType(QUERY_SYNC_REPLACE),
  withLatestFrom(state$),
  map(([action, state]) => replace(
    addSyncedSearchToLocation(action.payload.value, state.router.location.search)
  ))
);