import _ from 'lodash';
import { createSelector } from 'reselect';
import { DropdownItemProps } from 'semantic-ui-react';
import { Unit } from 'types';
import * as fromState from '../../../state/reducers';
import * as entitySelectors from '../../../state/selectors/entity';
import { ENTITY_TYPE } from '../../constants';

export default createSelector(
  [
    state => fromState.getAll<Unit>(state, ENTITY_TYPE.UNIT),
    state => entitySelectors.getUnitPaths(state),
    (state, { geoShape }) => geoShape
  ],
  (
    allUnits,
    unitPaths,
    geoShape
  ) => _.chain(allUnits)
    .toPairs()
    .filter(([id, unit]) => !geoShape || unit.settings.geoShape)
    .map(([id, unit]) => ({
      key: id,
      text: geoShape
        ? unit.description || unit.name
        : unitPaths[id],
      value: id
    }) as DropdownItemProps)
    .sortBy('text')
    .value()
);