import { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createSelector } from 'reselect';
import { Loader } from 'semantic-ui-react';
import { fetchById } from '../../../state/actions/entity';
import * as fromState from '../../../state/reducers';
import { ENTITY_TYPE } from '../../constants';

const mapStateToProps = createSelector(
  [
    (state, { id }) => id,
    state => fromState.getAll(state, ENTITY_TYPE.ALERT_CONNECTOR)
  ],
  (
    id,
    allAlertConnectors
  ) => ({
    alertConnector: allAlertConnectors[id]
  })
);

const mapDispatchToProps = (dispatch, { id }) => bindActionCreators({
  fetchAlertConnector: () => fetchById(ENTITY_TYPE.ALERT_CONNECTOR, id)
}, dispatch);

const AlertConnectorText = ({
  id,
  alertConnector,
  fetchAlertConnector
}) => {
  useEffect(() => {
    if (id && !alertConnector) {
      fetchAlertConnector();
    };
  });
  if (!id) {
    return null;
  };
  return alertConnector
    ? alertConnector.name
    : (
      <Loader
        inline
        active />
    );
};
AlertConnectorText.displayName = 'AlertConnectorText';

export default connect(mapStateToProps, mapDispatchToProps)(AlertConnectorText);