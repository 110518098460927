export const QUERY_SYNC_PUSH = 'QUERY_SYNC_PUSH';
export const querySyncPush = value => ({
  type: QUERY_SYNC_PUSH,
  payload: {
    value
  }
});

export const QUERY_SYNC_REPLACE = 'QUERY_SYNC_REPLACE';
export const querySyncReplace = value => ({
  type: QUERY_SYNC_REPLACE,
  payload: {
    value
  }
});