import { createSelector } from 'reselect';
import { PAGE_URL } from '../../../../utils/constants';
import { getPathParams, matchesPath } from '../../../../utils/helpers';

export const getUnitTypeId = createSelector(
  [
    state => state.router.location.pathname
  ],
  pathname => {
    if (matchesPath(pathname, PAGE_URL.UNIT_TYPE)) {
      const params = getPathParams(PAGE_URL.UNIT_TYPE, pathname);
      return params.unitTypeId;
    };
    if (matchesPath(pathname, PAGE_URL.UNIT_TYPE_PREDICTOR)) {
      const params = getPathParams(PAGE_URL.UNIT_TYPE_PREDICTOR, pathname);
      return params.unitTypeId;
    };
    return null;
  }
);