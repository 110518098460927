import { SET_SORT } from '../../actions/entity';

export default (
  state = {
    by: null,
    direction: null
  },
  action
) => {
  switch (action.type) {
    case SET_SORT:
      return action.payload;
    default:
      return state;
  };
};

export const getSort = state => state;