import _ from 'lodash';
import { createSelector } from 'reselect';
import * as fromState from '../../../state/reducers';
import { getUnitPaths } from '../../../state/selectors/entity';
import { ENTITY_TYPE } from '../../../utils/constants';

export default createSelector(
  [
    state => fromState.getAll(state, ENTITY_TYPE.UNIT),
    getUnitPaths
  ],
  (allUnits, unitPaths) => _.chain(allUnits)
    .values()
    .filter(unit => !!unit.settings.geoShape)
    .map(unit => ({
      name: unitPaths[unit.id],
      description: unit.description,
      positions: unit.settings.geoShape.coordinates
    }))
    .value()
);