import { SET_COMPARE_OPEN } from '../../actions/chart';

export default (state = false, action) => {
  switch (action.type) {
    case SET_COMPARE_OPEN:
      return action.payload.open;
    default:
      return state;
  }
};

export const getCompareOpen = state => state;