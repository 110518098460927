export const SET_ALARM_RANGES = 'alarm/SET_ALARM_RANGES';
export const setAlarmRanges = alarmRanges => ({
  type: SET_ALARM_RANGES,
  payload: {
    alarmRanges
  }
});

export const EDIT_ALARM_RANGE = 'alarm/EDIT_ALARM_RANGE';
export const editAlarmRange = (index, key, value) => ({
  type: EDIT_ALARM_RANGE,
  payload: {
    index,
    key,
    value
  }
});

export const REMOVE_ALARM_RANGE = 'alarm/REMOVE_ALARM_RANGE';
export const removeAlarmRange = index => ({
  type: REMOVE_ALARM_RANGE,
  payload: {
    index
  }
});

export const ADD_ALARM_RANGE = 'alarm/ADD_ALARM_RANGE';
export const addAlarmRange = alarmRange => ({
  type: ADD_ALARM_RANGE,
  payload: {
    alarmRange
  }
});

export const SET_ALARM_LEVELS = 'alarm/SET_ALARM_LEVELS';
export const setAlarmLevels = alarmLevels => ({
  type: SET_ALARM_LEVELS,
  payload: {
    alarmLevels
  }
});

export const EDIT_ALARM_LEVEL = 'alarm/EDIT_ALARM_LEVEL';
export const editAlarmLevel = (index, severity) => ({
  type: EDIT_ALARM_LEVEL,
  payload: {
    index,
    severity
  }
});

export const SAVE_PREDICTOR_ALARM_RANGES = 'alarm/SAVE_PREDICTOR_ALARM_RANGES';
export const savePredictorAlarmRanges = (entityType, alarmRanges, output, id) => ({
  type: SAVE_PREDICTOR_ALARM_RANGES,
  payload: {
    entityType,
    alarmRanges,
    output,
    id
  }
});

export const SET_ALARM_MODAL_IS_OPEN = 'alarm/SET_ALARM_MODAL_IS_OPEN';
export const setAlarmModalIsOpen = open => ({
  type: SET_ALARM_MODAL_IS_OPEN,
  payload: {
    open
  }
});

export const SET_ALARM_IS_SAVING = 'alarm/SET_ALARM_IS_SAVING';
export const setAlarmIsSaving = saving => ({
  type: SET_ALARM_IS_SAVING,
  payload: {
    saving
  }
});