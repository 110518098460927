import { connect } from 'react-redux';
import { changePlaybackTime, jumpToLivePlayback, setIsPlaying, setPlaybackSpeed, changeReviewTrend, setPlaybackMode } from '../../state/actions/playback';
import PlaybackButtons from '../../components/playback/PlaybackButtons';
import * as fromState from '../../state/reducers';
import _ from 'lodash';
import { setQuickRange } from '../../state/actions/chart';
import { CHART_TYPE, ENTITY_TYPE, ENTITY_PROPERTY, PLAYBACK_MODE } from '../../utils/constants';
import { createSelector } from 'reselect';
import { edit } from 'state/actions/entity';

const mapStateToProps = createSelector(
  [
    fromState.getPlaybackMode,
    fromState.getPlaybackTime,
    fromState.getPlaybackIsPlaying,
    fromState.getPlaybackSpeed,
    state => fromState.getQuickRange(state, CHART_TYPE.MAIN),
    fromState.getPlaybackReviewId,
    fromState.getPlaybackReviewTrendId,
    state => fromState.getAll(state, ENTITY_TYPE.REVIEW),
    state => fromState.getAll(state, ENTITY_TYPE.TREND),
    state => fromState.getAll(state, ENTITY_TYPE.TREND_TYPE)
  ],
  (
    mode,
    time,
    isPlaying,
    speed,
    quickRange,
    reviewId,
    reviewTrendId,
    allReviews,
    allTrends,
    allTrendTypes
  ) => ({
    mode,
    time,
    isPlaying,
    speed,
    quickRange,
    review: allReviews[reviewId],
    reviewTrends: _.chain(allReviews[reviewId])
      .get(ENTITY_PROPERTY.REVIEW.TRENDS)
      .map(trend => allTrends[trend.id])
      .filter()
      .value(),
    reviewTrendId,
    allTrendTypes
  })
);

const mapDispatchToProps = {
  onClickPlay: () => setIsPlaying(true),
  onClickPause: () => setIsPlaying(false),
  onChangeSpeed: setPlaybackSpeed,
  onChangeTime: changePlaybackTime,
  onJumpToLive: jumpToLivePlayback,
  onChangeQuickRange: _.partial(setQuickRange, CHART_TYPE.MAIN),
  onChangeReviewTrend: changeReviewTrend,
  onExitReview: () => setPlaybackMode(PLAYBACK_MODE.HISTORICAL),
  onClearRegion: (review, index, name) => edit(
    ENTITY_TYPE.REVIEW,
    {
      ...review,
      trends: [
        ...review.trends.slice(0, index),
        {
          ...review.trends[index],
          regions: _.omit(review.trends[index].regions, name)
        },
        ...review.trends.slice(index + 1)
      ]
    }
  )
};

export default connect(mapStateToProps, mapDispatchToProps)(PlaybackButtons);