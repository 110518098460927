import _ from 'lodash';
import { ofType } from 'redux-observable';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { fetchConditionTypesFulfilled, FETCH_CONDITION_TYPES, fetchConditionTypes } from '../../actions/entity';
import * as api from '../../../utils/api';
import * as fromState from '../../reducers';
import { ENTITY_CONFIG } from '../../../utils/constants';
import { merge, of } from 'rxjs';
import entityConfig from '../../../utils/entities';
import { takeUntilAppReset } from 'utils/helpers';

export default (action$, state$) => action$.pipe(
  ofType(FETCH_CONDITION_TYPES),
  withLatestFrom(state$),
  mergeMap(([action, state]) => {
    const typeEntityType = entityConfig[action.entityType][ENTITY_CONFIG.TYPE_ENTITY_TYPE];
    return merge(
      ...entityConfig[action.entityType][ENTITY_CONFIG.CONDITIONS]
        ? [
          api.getConditionTypes({
            controller: entityConfig[action.entityType][ENTITY_CONFIG.API_PATH],
            token: fromState.getUser(state).token
          }).pipe(
            takeUntilAppReset(action$),
            map(_.partial(fetchConditionTypesFulfilled, action.entityType)),
            catchError(api.onError)
          )
        ]
        : [],
      ...typeEntityType
        ? [of(fetchConditionTypes(typeEntityType))]
        : []
    );
  })
);