import filter, * as fromFilter from './filter';
import list, * as fromList from './list';
import { combineReducers } from 'redux';

export default combineReducers({
  filter,
  list
});

export const getConditions = state => state;
export const getFilter = state => fromFilter.getFilter(state.filter);
export const getList = state => fromList.getList(state.list);