import _ from 'lodash';
import { createSelector } from 'reselect';
import { getUnitSensors } from '../getUnitSensors';
import * as fromState from '../../../reducers';
import { CHART_TYPE, ENTITY_TYPE, NEUTRAL_SEVERITY } from '../../../../utils/constants';
import { getColourFromSeverity, getSensorSeverity } from '../../../../utils/helpers';
import { RootState } from 'index';
import { Enumeration } from 'types';

export const getSensorCards = createSelector(
  [
    (state: RootState) => getUnitSensors(state),
    (state: RootState) => fromState.getAllLatestSensorValues(state, CHART_TYPE.MAIN),
    (state: RootState) => fromState.getAll<Enumeration>(state, ENTITY_TYPE.ENUMERATION)
  ],
  (
    sensors,
    allLatestSensorValues,
    allEnumerations
  ) => {
    return _.map(sensors, (sensor, i) => {
      const alarmRanges = sensor.alarmRanges;
      const enumeration = sensor.enumeration
        ? allEnumerations[sensor.enumeration]
        : null;

      const latestValue = allLatestSensorValues[sensor.id];
      const severity = getSensorSeverity(latestValue, alarmRanges);

      return ({
        id: sensor.id,
        name: sensor.name,
        description: sensor.description,
        alarmRanges: alarmRanges,
        unitsOfMeasurement: sensor.unitsOfMeasurement || '',
        valueType: sensor.valueType,
        outputValue: enumeration
          ? enumeration.states[latestValue]
          : latestValue,
        severity: severity,
        fill: getColourFromSeverity(severity)
      });
    });
  }
);