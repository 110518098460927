import _ from 'lodash';
import moment from 'moment';
import { quickRanges } from 'utils/dates';

export default trend => {
  return _.minBy(
    quickRanges,
    quickRange => Math.abs(
      moment.duration(quickRange) - (
        7 * _.max([moment(trend.to).diff(moment(trend.from)), moment.duration(1, 'hour')])
      )
    )
  );
};