import _ from 'lodash';
import { defineMessages } from 'react-intl';
import { EntityConfig, Unit } from 'types';
import uuid from 'uuid/v1';
import EntityText from '../../../components/entity/EntityText';
import { DASHBOARD_VIEW, ENTITY_FORM_ELEMENT_TYPE, ENTITY_TYPE } from '../../constants';
import { unescapeRegex } from '../../helpers';
import optionsSelector from './optionsSelector';
import textComponent from './textComponent';

const messages = defineMessages({
  'title': {
    id: 'unit.title',
    defaultMessage: 'Units'
  },
  'columns.name.title': {
    id: 'unit.columns.name.title',
    defaultMessage: 'Name'
  },
  'columns.category.title': {
    id: 'unit.columns.category.title',
    defaultMessage: 'Category'
  },
  'columns.description.title': {
    id: 'unit.columns.description.title',
    defaultMessage: 'Description'
  },
  'columns.parent.title': {
    id: 'unit.columns.parent.title',
    defaultMessage: 'Parent'
  },
  'form.name.label': {
    id: 'unit.form.name.label',
    defaultMessage: 'Name'
  },
  'form.name.placeholder': {
    id: 'unit.form.name.placeholder',
    defaultMessage: 'Name'
  },
  'form.description.label': {
    id: 'unit.form.description.label',
    defaultMessage: 'Description'
  },
  'form.description.placeholder': {
    id: 'unit.form.description.placeholder',
    defaultMessage: 'Description'
  },
  'form.unitId.label': {
    id: 'unit.form.unitId.label',
    defaultMessage: 'Parent'
  },
  'form.unitId.placeholder': {
    id: 'unit.form.unitId.placeholder',
    defaultMessage: 'Parent'
  },
  'types.label': {
    id: 'unit.form.types.label',
    defaultMessage: 'Category'
  },
  'type.location.name': {
    id: 'unit.type.location.name',
    defaultMessage: 'Location'
  },
  'type.equipment.name': {
    id: 'unit.type.equipment.name',
    defaultMessage: 'Equipment'
  },
  'types.placeholder': {
    id: 'unit.form.types.placeholder',
    defaultMessage: 'Category'
  },
  'type.location.options.geoShape.label': {
    id: 'unit.type.location.options.geoShape.label',
    defaultMessage: 'Geo Shape'
  },
  'deckHeader.units': {
    id: 'unit.deckHeader.units',
    defaultMessage: 'Units'
  },
  'deckHeader.childUnits': {
    id: 'unit.deckHeader.childUnits',
    defaultMessage: 'Child Units'
  },
  'deckHeader.instancesOfUnitType': {
    id: 'unit.deckHeader.instancesOfUnitType',
    defaultMessage: 'Instances'
  }
});

const config: EntityConfig<Unit> = {
  apiPath: 'unit',
  pageUrl: 'units',
  icon: 'cube',
  messages,
  navigateOnAdd: false,
  navigateOnClick: true,
  parentProperty: 'unitId',
  fetchAllOnLoad: true,
  import: true,
  search: [
    {
      name: 'name',
      type: ENTITY_FORM_ELEMENT_TYPE.INPUT
    },
    {
      name: 'description',
      type: ENTITY_FORM_ELEMENT_TYPE.INPUT
    }
  ],
  createSearchQuery: ({
    name,
    description
  }) => {
    const filterName = name
      ? ({
        $regex: _.escapeRegExp(name),
        $options: 'i'
      })
      : undefined;

    const filterDescription = description
      ? ({
        $regex: _.escapeRegExp(description),
        $options: 'i'
      })
      : undefined;

    const filter = {
      Name: filterName,
      Description: filterDescription
    };
    return {
      filter
    };
  },
  parseSearchFilter: filter => ({
    name: unescapeRegex(_.get(filter, 'Name.$regex') || ''),
    description: unescapeRegex(_.get(filter, 'Description.$regex') || '')
  }),
  optionsSelector,
  textComponent,
  typeEntityType: ENTITY_TYPE.UNIT_CATEGORY,
  columns: [
    {
      name: 'name',
      width: 3,
      render: ({ name }) => name
    },
    {
      name: 'description',
      width: 3,
      render: ({ description }) => description
    },
    {
      name: 'parent',
      width: 3,
      render: ({ unitId }) => (
        <EntityText
          id={unitId}
          entityType={ENTITY_TYPE.UNIT} />
      )
    },
    {
      name: 'category',
      width: 3,
      render: ({ type }) => (
        <EntityText
          id={type}
          entityType={ENTITY_TYPE.UNIT_CATEGORY} />
      )
    }
  ],
  form: [
    {
      name: 'name',
      type: ENTITY_FORM_ELEMENT_TYPE.INPUT
    },
    {
      name: 'description',
      type: ENTITY_FORM_ELEMENT_TYPE.INPUT
    },
    {
      name: 'unitId',
      type: ENTITY_FORM_ELEMENT_TYPE.SEARCH,
      searchEntityType: ENTITY_TYPE.UNIT,
      transformResult: unit => ({
        key: unit.id,
        text: unit.name,
        description: unit.description,
        value: unit.id
      })
    },
    {
      type: ENTITY_FORM_ELEMENT_TYPE.TYPES
    }
  ],
  createEntity: values => ({
    id: uuid(),
    name: values.name,
    description: values.description,
    unitId: values.unitId || null,
    type: values.type,
    settings: values.settings
  }),
  populateForm: entity => ({
    name: entity.name,
    description: entity.description,
    unitId: entity.unitId,
    type: entity.type,
    settings: entity.settings
  }),
  deckHeaderMessage: (dashboardView, isTopLevel) => dashboardView === DASHBOARD_VIEW.UNITS
    ? (isTopLevel ? messages['deckHeader.units'] : messages['deckHeader.childUnits'])
    : messages['deckHeader.instancesOfUnitType']
};

export default config;