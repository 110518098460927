import Plot from 'react-plotly.js';
import moment from 'moment';
import { DATE_FORMAT } from 'utils/constants';
import { injectIntl } from 'react-intl';
import messages from './PlotlyChart.messages';

const MARGIN_3D = 10;

const PLOT_CONFIG = {
  displaylogo: false,
  responsive: true,
  scrollZoom: true
};

function Scatter3d({
  data,
  layout
}) {
  data.type = 'scatter3d';
  data.mode = 'markers';
  data.text = data.customData.map(t => moment.unix(t).format(DATE_FORMAT));
  data.hovertemplate = '%{text}<br>' +
    `<b>${layout.scene.xaxis.title.text}</b>: %{x}<br>` +
    `<b>${layout.scene.yaxis.title.text}</b>: %{y}<br>` +
    `<b>${layout.scene.zaxis.title.text}</b>: %{z}<extra></extra>`;

  layout.margin = {
    l: MARGIN_3D,
    r: MARGIN_3D,
    t: MARGIN_3D,
    b: MARGIN_3D
  };
  layout.autosize = true;

  return (
    <Plot
      style={{
        width: '100%',
        height: '100%'
      }}
      data={[data]}
      layout={layout}
      config={PLOT_CONFIG} />
  );
}

function Scatter2d({
  data,
  layout
}) {
  data.type = 'scattergl';
  data.mode = 'markers';
  data.text = data.customData.map(t => moment.unix(t).format(DATE_FORMAT));
  data.hovertemplate = '%{text}<br>' +
    `<b>${layout.scene.xaxis.title.text}</b>: %{x}<br>` +
    `<b>${layout.scene.yaxis.title.text}</b>: %{y}<br><extra></extra>`;

  layout.autosize = true;
  layout.scene.hovermode = 'closest';

  return (
    <Plot
      style={{
        width: '100%',
        height: '100%'
      }}
      data={[data]}
      layout={layout.scene}
      config={PLOT_CONFIG} />
  );
}

function Histogram({
  data,
  layout,
  attrs
}) {
  data.type = 'histogram';
  data.marker = { color: 'red' };

  layout.autosize = true;

  return (
    <Plot
      style={{
        width: '100%',
        height: '100%'
      }}
      data={[data]}
      layout={layout}
      config={PLOT_CONFIG} />
  );
}

function EmptyChart() {
  return (
    <Plot
      style={{
        width: '100%',
        height: '100%'
      }}
      config={PLOT_CONFIG} />
  );
}

export default injectIntl(function PlotlyChart({
  spec,
  colourMode,
  intl: {
    formatMessage
  }
}) {
  let data = spec.data;
  let layout = spec.layout;
  layout.uirevision = 'true';

  if (colourMode === 'time') {
    data.marker.color = data.customData;
    data.marker.colorbar = {
      title: formatMessage(messages['timeAxisLabel']),
      tickvals: []
    };
    data.marker.colorscale = 'Portland';
  }

  let nAxes = 0;
  if (data.hasOwnProperty('x')) {
    nAxes++;
  }
  if (data.hasOwnProperty('y')) {
    nAxes++;
  }
  if (data.hasOwnProperty('z')) {
    nAxes++;
  }

  if (nAxes >= 3) {
    return Scatter3d({
      data,
      layout
    });
  } else if (nAxes >= 2) {
    return Scatter2d({
      data,
      layout
    });
  } else if (nAxes >= 1) {
    return Histogram({
      data,
      layout
    });
  } else {
    return EmptyChart();
  }
});