import { Button, Icon, Sidebar } from 'semantic-ui-react';

const oppositePosition = {
  left: 'right',
  right: 'left'
};

const ChartSidebar = ({
  open,
  onClickToggleButton,
  text,
  children,
  position,
  buttonWidth
}) => (
  <Sidebar
    visible={open}
    direction={position}
    animation='overlay'
    style={{
      visibility: 'visible',
      position: 'absolute',
      height: '50vh'
    }}>
    <Button
      attached={oppositePosition[position]}
      primary
      style={{
        position: 'absolute',
        [oppositePosition[position]]: `-${buttonWidth}px`,
        height: '100%',
        width: `${buttonWidth}px`
      }}
      onClick={() => onClickToggleButton(!open)}>
      <Icon
        name={open ? `angle ${position}` : `angle ${oppositePosition[position]}`}
        style={{
          position: 'absolute',
          [oppositePosition[position]]: '0px',
          width: `${buttonWidth}px`,
          margin: '0',
          top: `${buttonWidth / 2}px`
        }} />
      <p style={{
        transform: 'rotate(90deg)',
        position: 'absolute',
        width: '25vh',
        textAlign: 'center',
        top: '50%',
        pointerEvents: 'none',
        [oppositePosition[position]]: `calc(-25vh/2 + ${buttonWidth / 2}px)`,
        lineHeight: `${buttonWidth}px`
      }}>
        {text}
      </p>
      <Icon
        name={open ? `angle ${position}` : `angle ${oppositePosition[position]}`}
        style={{
          position: 'absolute',
          [oppositePosition[position]]: '0px',
          width: `${buttonWidth}px`,
          margin: '0',
          bottom: `${buttonWidth / 2}px`
        }} />
    </Button>
    <Button
      color='grey'
      attached={position}
      style={{
        position: 'absolute',
        [position]: '0px',
        height: '100%',
        width: `${buttonWidth}px`,
        borderRadius: '0'
      }}
      onClick={() => onClickToggleButton(!open)}>
      <Icon
        name={open ? `angle ${position}` : `angle ${oppositePosition[position]}`}
        style={{
          position: 'absolute',
          width: `${buttonWidth}px`,
          [position]: '0',
          margin: '0',
          top: `${buttonWidth / 2}px`
        }} />
      <Icon
        name={open ? `angle ${position}` : `angle ${oppositePosition[position]}`}
        style={{
          position: 'absolute',
          width: `${buttonWidth}px`,
          [position]: '0',
          margin: '0',
          bottom: `${buttonWidth / 2}px`
        }} />
    </Button>
    {children}
  </Sidebar>
);

ChartSidebar.displayName = 'ChartSidebar';

export default ChartSidebar;