import { CSSProperties, ReactNode } from 'react';

interface Props {
  children: ReactNode;
  size?: number;
  style?: CSSProperties;
  /* The SplitPane uses this callback ref to access the DOM nodes for its children and determine their display sizes */
  callbackRef: (element: HTMLDivElement) => void;
}
export default function Pane({
  children,
  size,
  style: styleProps,
  callbackRef
}: Props) {
  const baseStyle: CSSProperties = {
    position: 'relative',
    outline: 'none'
  };

  const takeUpSpecifiedHeight = {
    height: size,
    display: 'flex',
    flex: 'none'
  };

  const heightStyle = size === undefined
    ? {}
    : takeUpSpecifiedHeight;

  const style = {
    ...baseStyle,
    ...heightStyle,
    ...(styleProps || {})
  };

  if (size !== undefined) {
    baseStyle.height = size;
    baseStyle.display = 'flex';
    baseStyle.flex = 'none';
  }

  return (
    <div
      style={style}
      ref={callbackRef}>
      {children}
    </div>
  );
}