import { connect } from 'react-redux';
import { compose, withHandlers } from 'recompose';
import { createSelector } from 'reselect';
import AlarmLevelsButton from '../../components/alarm/AlarmLevelsButton';
import { setAlarmLevels, setAlarmModalIsOpen, savePredictorAlarmRanges } from '../../state/actions/alarm';
import * as fromState from '../../state/reducers';
import { ENTITY_TYPE } from '../../utils/constants';
import { alarmLevelsToRanges, alarmRangesToLevels } from '../../utils/helpers';

const mapStateToProps = createSelector(
  [
    (state, { id, entityType }) => fromState.getById(state, entityType, id),
    (state, { index }) => index,
    state => fromState.getAll(state, ENTITY_TYPE.ENUMERATION)
  ],
  (
    predictor,
    index,
    allEnumerations
  ) => {
    const alarmRanges = predictor.outputMappings[index].alarmRanges;
    const enumerationId = predictor.outputMappings[index].enumeration;
    return {
      alarmLevels: alarmRangesToLevels(alarmRanges, allEnumerations[enumerationId]),
      type: 'output'
    };
  }
);

const mapDispatchToProps = (dispatch, { id, index, entityType }) => ({
  openModal: alarmLevels =>
    dispatch(setAlarmModalIsOpen(true)) && dispatch(setAlarmLevels(alarmLevels)),
  onSave: alarmLevels => dispatch(savePredictorAlarmRanges(entityType, alarmLevelsToRanges(alarmLevels), index, id))
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    onClick: ({ alarmLevels, openModal }) => () => openModal(alarmLevels)
  })
)(AlarmLevelsButton);