import _ from 'lodash';
import PropTypes from 'prop-types';
import { Button, Popup } from 'semantic-ui-react';
import CopyButtonContainer from '../../../containers/chart/CopyButtonContainer';
import { CHART_TYPE, INVERTED_THEME, SELECTION_TYPE } from '../../../utils/constants';
import styles from './ChartKey.styles';
import PopupButton from '../../shared/PopupButton';
import { injectIntl } from 'react-intl';
import messages from './ChartKey.messages';

const ChartKeyEditButtonGroupPopup = injectIntl(({
  item,
  editingItemId,
  isSaving,
  deleteDisabled,
  index,
  chartType,
  onSelect,
  onSetEditingId,
  onSetEditText,
  onRemove,
  intl: {
    formatMessage
  }
}) => (
  <Popup
    inverted={INVERTED_THEME}
    on='focus'
    open={item.id === editingItemId ? false : undefined}
    style={styles.editablePopup}
    trigger={(
      <Button
        icon='ellipsis horizontal'
        size='mini'
        loading={item.id === editingItemId && isSaving}
        onClick={e => e.stopPropagation()} />
    )}>
    <Button.Group
      size='mini'
      floated='right'>
      {item.copyText && (
        <CopyButtonContainer
          text={item.copyText}
          chartType={chartType} />
      )}
      <PopupButton
        popupText={formatMessage(messages['functionSensor.removeButton.popupText'])}
        icon='trash'
        negative
        disabled={deleteDisabled}
        loading={isSaving}
        onClick={e => {
          e.stopPropagation();
          onSelect(item.id, item.selectionType, false);
          onRemove(index);
        }} />
      <PopupButton
        popupText={formatMessage(messages['functionSensor.editDescriptionButton.popupText'])}
        icon='edit'
        primary
        onClick={e => {
          e.stopPropagation();
          onSetEditingId(item.id);
          onSetEditText(item.title || '');
        }} />
    </Button.Group>
  </Popup>
));

ChartKeyEditButtonGroupPopup.propTypes = {
  item: PropTypes.exact({
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    description: PropTypes.string,
    colour: PropTypes.string,
    selected: PropTypes.bool.isRequired,
    selectionType: PropTypes.oneOf(_.values(SELECTION_TYPE)),
    copyText: PropTypes.string,
    editable: PropTypes.bool.isRequired,
    removable: PropTypes.bool.isRequired
  }),
  editingItemId: PropTypes.string,
  isSaving: PropTypes.bool.isRequired,
  chartType: PropTypes.oneOf(_.values(CHART_TYPE)),
  index: PropTypes.number.isRequired,
  deleteDisabled: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  onSetEditingId: PropTypes.func.isRequired,
  onSetEditText: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired
};

ChartKeyEditButtonGroupPopup.displayName = 'ChartKeyEditButtonGroupPopup';

export default ChartKeyEditButtonGroupPopup;