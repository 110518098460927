import _ from 'lodash';
import { Grid } from 'semantic-ui-react';
import { SELECTION_TYPE, CHART_TYPE, VALUE_TYPE } from '../../../utils/constants';
import CardsColumn from './CardsColumn';
import PropTypes from 'prop-types';

const widths = {
  function: {
    mobile: 16,
    tablet: 16,
    computer: 16,
    largeScreen: 16,
    widescreen: 16
  },
  input: {
    mobile: 16,
    tablet: 16,
    computer: 16,
    largeScreen: 11,
    widescreen: 11
  },
  output: {
    mobile: 16,
    tablet: 16,
    computer: 16,
    largeScreen: 5,
    widescreen: 5
  }
};

const selectionTypes = {
  function: SELECTION_TYPE.FUNCTION,
  input: SELECTION_TYPE.INPUT,
  output: SELECTION_TYPE.OUTPUT
};

const CardsChart = ({
  cardsByType,
  isLoading,
  chartType,
  onClickCard
}) => (
  <Grid
    stackable
    className='no-padding'>
    <Grid.Row>
      {_.map(cardsByType, (cards, type) => (
        <CardsColumn
          key={type}
          widths={widths[type]}
          cards={cards}
          isLoading={isLoading}
          chartType={chartType}
          selectionType={selectionTypes[type]}
          onClickCard={onClickCard} />
      ))}
    </Grid.Row>
  </Grid>
);

const cardPropTypes = PropTypes.exact({
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  description: PropTypes.string,
  ranges: PropTypes.arrayOf(PropTypes.exact({
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    severity: PropTypes.number.isRequired
  })),
  unitsOfMeasurement: PropTypes.string,
  valueType: PropTypes.oneOf(_.values(VALUE_TYPE)),
  selected: PropTypes.bool.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
  value: PropTypes.any,
  noData: PropTypes.bool,
  severity: PropTypes.number,
  scale: PropTypes.number
});

CardsChart.propTypes = {
  cardsByType: PropTypes.exact({
    function: PropTypes.arrayOf(cardPropTypes),
    input: PropTypes.arrayOf(cardPropTypes),
    output: PropTypes.arrayOf(cardPropTypes)
  }),
  isLoading: PropTypes.bool.isRequired,
  chartType: PropTypes.oneOf(_.values(CHART_TYPE)),
  onClickCard: PropTypes.func.isRequired
};

CardsChart.displayName = 'CardsChart';

export default CardsChart;