import _ from 'lodash';
import Prism from 'prismjs';
import 'prismjs/components/prism-handlebars';
import 'prismjs/components/prism-markup-templating';
import { injectIntl } from 'react-intl';
import Editor from 'react-simple-code-editor';
import { Form, Icon, Segment } from 'semantic-ui-react';
import EntityFormContainer from '../../../containers/entity/EntityFormContainer';
import { CREATE_TYPE_ID, INPUT_TYPE, INPUT_TYPE_FORM_ELEMENT, INPUT_TYPE_VALUE_TYPE, LITERAL_OR_ATTRIBUTE, OPTION_TYPE, SELECT_LISTS, EMPTY_STRING, DATE_FORMAT, INVERTED_THEME } from '../../../utils/constants';
import { selectListToOptions } from '../../../utils/helpers';
import SelectEntityElementContainer from '../../../containers/entity/SelectEntityElementContainer';
import ScrollAwareFormSelect from '../../shared/ScrollAwareFormSelect';
import ConditionsElement from '../ConditionsElement';
import ListElement from '../ListElement';
import { getDefaultOptionValues, getLiteralOrAttributeOptionValues } from './TypesElement.helpers';
import messages from './TypesElement.messages';
import { evaluate } from 'feelin';
import EnumerationStateElementContainer from 'containers/entity/EnumerationStateElementContainer';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import moment from 'moment';

const hasFlag = (settings, flag) => (settings & flag) === flag;

const OptionsForm = injectIntl(({
  typesById,
  selectedTypeId,
  settingsValues,
  errorFields,
  entityMessages,
  readonly,
  conditionTypes,
  selectContextLists = {},
  scrollContextRef,
  inlineCreation,
  onChangeSetting,
  onChangeSettingConditionFilter,
  onAddSettingCondition,
  onRemoveSettingCondition,
  onChangeSettingCondition,
  intl: {
    formatMessage,
    locale
  }
}) => {
  const formatKeyedMessage = (messageKeys, messageKey, defaultMessage) => {
    const message = messageKeys[messageKey];
    return message ? formatMessage(message) : (defaultMessage || messageKey);
  };

  const formatEntityMessage = (messageKey, defaultMessage) => {
    return formatKeyedMessage(entityMessages, messageKey, defaultMessage);
  };

  const dependencyContext = _.chain((typesById[selectedTypeId] || {}).options)
    .map((optionSettings, key) => [
      key,
      _.find(
        selectListToOptions(
          SELECT_LISTS[optionSettings.selectListName] || selectContextLists[optionSettings.selectContextListName],
          formatMessage
        ),
        option => option.value === settingsValues[key]
      ) || {}
    ])
    .fromPairs()
    .value();

  return _.map((typesById[selectedTypeId] || {}).options, (optionSettings, key) => {
    if (optionSettings.dependency && !evaluate(optionSettings.dependency, dependencyContext)) {
      return null;
    };
    const {
      OptionWrapper,
      optionWrapperProps,
      onChangeLiteralSetting,
      onChangeAttributeSetting,
      literalInputDisabled,
      attributeInputDisabled,
      literalValue,
      attributeValue
    } =
      optionSettings.optionType === OPTION_TYPE.LITERAL_OR_ATTRIBUTE
        ? getLiteralOrAttributeOptionValues(key, settingsValues, onChangeSetting)
        : getDefaultOptionValues(key, settingsValues, onChangeSetting);

    const label = formatEntityMessage(
      typesById[selectedTypeId].parentType
        ? `type.${typesById[selectedTypeId].parentType}.${selectedTypeId}.options.${key}.label`
        : `type.${selectedTypeId}.options.${key}.label`,
      key
    );

    const placeholder = formatEntityMessage(
      typesById[selectedTypeId].parentType
        ? `type.${typesById[selectedTypeId].parentType}.${selectedTypeId}.options.${key}.placeholder`
        : `type.${selectedTypeId}.options.${key}.placeholder`,
      key
    );

    return (
      <OptionWrapper
        key={key}
        {...optionWrapperProps}>
        {(() => {
          if (optionSettings.optionType === OPTION_TYPE.ATTRIBUTE) {
            return (
              <ScrollAwareFormSelect
                key={key}
                scrollContextRef={scrollContextRef}
                error={_.includes(errorFields, `settings.${key}`)}
                label={label}
                placeholder={placeholder}
                fluid
                search
                clearable
                onChange={(event, { value }) => onChangeSetting(key, value || null)}
                options={selectListToOptions(
                  _.filter(
                    selectContextLists.attributes,
                    attribute => (
                      attribute.valueType === INPUT_TYPE_VALUE_TYPE[optionSettings.inputType]
                      && attribute.enumeration === optionSettings.enumeration
                      && (!attribute.source || hasFlag(optionSettings.attributeType, attribute.source))
                    ),
                    formatMessage
                  )
                )}
                value={_.get(settingsValues, key) || null} />
            );
          } else if (optionSettings.inputType === INPUT_TYPE.HTML_TEMPLATE || optionSettings.inputType === INPUT_TYPE.XML) {
            return (
              <Form.Input
                key={key}
                error={_.includes(errorFields, `settings.${key}`)}
                label={label}>
                <Segment
                  secondary
                  style={{
                    width: '100%',
                    maxHeight: '50vh',
                    overflow: 'auto',
                    ..._.includes(errorFields, `settings.${key}`) ? { borderColor: '#d9534f' } : {}
                  }}>
                  <Editor
                    value={settingsValues[key] || EMPTY_STRING}
                    onValueChange={!readonly ? (val => onChangeSetting(key, val)) : undefined}
                    highlight={val => Prism.highlight(val, Prism.languages.handlebars, optionSettings.inputType === INPUT_TYPE.HTML_TEMPLATE ? 'handlebars' : 'xml')}
                    style={{
                      fontFamily: 'Consolas, monospace'
                    }} />
                </Segment>
              </Form.Input>
            );
          } else if (optionSettings.inputType === INPUT_TYPE.TEXT_AREA) {
            return (
              <Form.TextArea
                key={key}
                error={_.includes(errorFields, `settings.${key}`)}
                autoHeight
                value={settingsValues[key] || EMPTY_STRING}
                rows={2}
                label={label}
                placeholder={placeholder}
                onChange={!readonly ? (event => onChangeSetting(key, event.target.value)) : undefined} />
            );
          } else if (optionSettings.inputType === INPUT_TYPE.SELECT) {
            const options = selectListToOptions(
              SELECT_LISTS[optionSettings.selectListName] || selectContextLists[optionSettings.selectContextListName],
              formatMessage
            );
            const emptyValue = optionSettings.multiple
              ? []
              : EMPTY_STRING;
            return (
              <ScrollAwareFormSelect
                key={key}
                scrollContextRef={scrollContextRef}
                value={settingsValues[key] || emptyValue}
                error={_.includes(errorFields, `settings.${key}`)}
                fluid
                multiple={!!optionSettings.multiple}
                clearable={!!optionSettings.multiple}
                hideAddAll
                label={label}
                placeholder={placeholder}
                options={options}
                onChange={!readonly ? ((event, { value }) => onChangeSetting(key, value)) : undefined} />
            );
          } else if (optionSettings.inputType === INPUT_TYPE.SELECT_ENTITY) {
            return (
              <SelectEntityElementContainer
                key={key}
                scrollContextRef={scrollContextRef}
                entityType={optionSettings.entityType}
                label={label}
                placeholder={placeholder}
                value={key in settingsValues ? settingsValues[key] : null}
                error={_.includes(errorFields, `settings.${key}`)}
                onChange={!readonly ? (value => onChangeSetting(key, value)) : undefined} />
            );
          } else if (optionSettings.inputType === INPUT_TYPE.CONDITIONS) {
            const { filter, list = [] } = settingsValues[key] || {};
            return (
              <ConditionsElement
                key={key}
                filter={filter}
                list={list}
                defaultValue={optionSettings.defaultValue}
                lockFilter={optionSettings.lockFilter}
                conditionTypeFilter={optionSettings.filter}
                enumerationPath={optionSettings.enumerationPath}
                conditionForm={_.chain(optionSettings.conditionOptions)
                  .toPairs()
                  .map(([name, optionSettings]) => {
                    const selectList = SELECT_LISTS[optionSettings.selectListName]
                      || selectContextLists[optionSettings.selectContextListName];
                    return {
                      name,
                      dependency: optionSettings.dependency,
                      type: INPUT_TYPE_FORM_ELEMENT[optionSettings.inputType],
                      filter: optionSettings.filter,
                      options: selectList && selectListToOptions(selectList, formatMessage)
                    };
                  })
                  .value()}
                conditionTypes={conditionTypes}
                disabled={false}
                loading={false}
                errorFields={errorFields}
                getErrorPath={index => `settings.${key}.list[${index}]`}
                errorPathPrefix={`settings.${key}`}
                scrollContextRef={scrollContextRef}
                selectContextLists={selectContextLists}
                onAddCondition={_.partial(onAddSettingCondition, key)}
                onChangeCondition={_.partial(onChangeSettingCondition, key)}
                onRemoveCondition={_.partial(onRemoveSettingCondition, key)}
                onChangeFilter={_.partial(onChangeSettingConditionFilter, key)} />
            );
          } else if (optionSettings.inputType === INPUT_TYPE.LIST) {
            return (
              <ListElement
                key={key}
                label={label}
                state={settingsValues[key]}
                context={{
                  standaloneCreation: !inlineCreation,
                  inlineCreation
                }}
                form={_.chain(optionSettings.listOptions)
                  .toPairs()
                  .map(([name, optionSettings]) => {
                    const selectList = SELECT_LISTS[optionSettings.selectListName]
                      || selectContextLists[optionSettings.selectContextListName];
                    return {
                      name,
                      dependency: optionSettings.dependency,
                      type: INPUT_TYPE_FORM_ELEMENT[optionSettings.inputType],
                      filter: optionSettings.filter,
                      entityType: optionSettings.entityType,
                      options: selectList && selectListToOptions(selectList, formatMessage)
                    };
                  })
                  .value()}
                errorFields={errorFields}
                getErrorPath={index => `settings.${key}[${index}]`}
                errorPathPrefix={`settings.${key}`}
                scrollContextRef={scrollContextRef}
                selectContextLists={selectContextLists}
                onChange={_.partial(onChangeLiteralSetting, key)} />
            );
          } else if (optionSettings.inputType === INPUT_TYPE.GEO_SHAPE) {
            return (
              <ListElement
                key={key}
                label={label}
                state={settingsValues[key]
                  ? _.map(settingsValues[key].coordinates[0], point => ({
                    latitude: point[0],
                    longitude: point[1]
                  }))
                  : []}
                form={[
                  {
                    name: 'latitude',
                    inputType: 'number'
                  },
                  {
                    name: 'longitude',
                    inputType: 'number'
                  }
                ]}
                errorFields={errorFields}
                getErrorPath={index => `${key}.coordinates[${index}]`}
                errorPath={key}
                selectContextLists={selectContextLists}
                onChange={(values, errorPath) => onChangeSetting(
                  key,
                  _.some(values)
                    ? {
                      type: 'Polygon',
                      coordinates: [
                        _.map(values, point => [point.latitude, point.longitude])
                      ]
                    }
                    : null,
                  errorPath
                )} />
            );
          } else if (optionSettings.inputType === INPUT_TYPE.ENUMERATION_STATE) {
            return (
              <EnumerationStateElementContainer
                key={key}
                error={_.includes(errorFields, `settings.${key}`)}
                enumerationId={optionSettings.enumeration}
                upward
                readOnly={readonly}
                value={key in settingsValues ? settingsValues[key] : null}
                label={label}
                placeholder={readonly ? null : placeholder}
                onChange={!readonly ? (value => onChangeSetting(key, value)) : undefined} />
            );
          } else if (optionSettings.inputType === INPUT_TYPE.DATE_TIME) {
            return (
              <Form.Field
                error={_.includes(errorFields, `settings.${key}`)}
                className='force-fluid'>
                <SemanticDatepicker
                  inverted={INVERTED_THEME}
                  readOnly={readonly}
                  label={label}
                  placeholder={readonly ? null : placeholder}
                  clearable={!readonly}
                  error={_.includes(errorFields, `settings.${key}`)}
                  autoComplete='off'
                  closable
                  showToday={false}
                  pointing='top left'
                  format={DATE_FORMAT}
                  value={key in settingsValues && settingsValues[key]
                    ? moment.utc(settingsValues[key]).local().toDate()
                    : undefined}
                  onChange={readonly
                    ? _.noop
                    : ((event, { value }) => onChangeSetting(key, value && moment(value).utc().toISOString()))}
                  name='dateTime'
                  iconPosition='left'
                  localization={locale} />
              </Form.Field>
            );
          } else {
            return (
              <Form.Input
                key={key}
                error={_.includes(errorFields, `settings.${key}`)}
                fluid
                readOnly={readonly}
                type={optionSettings.inputType === INPUT_TYPE.NUMBER
                  ? 'number'
                  : 'text'}
                disabled={literalInputDisabled}
                value={literalValue}
                label={label}
                placeholder={readonly ? null : placeholder}
                onChange={!readonly
                  ? (event => {
                    onChangeLiteralSetting(
                      key,
                      optionSettings.inputType === INPUT_TYPE.NUMBER
                        ? parseFloat(event.target.value)
                        : event.target.value
                    );
                  })
                  : undefined} />
            );
          }
        })()}
        {optionSettings.optionType === OPTION_TYPE.LITERAL_OR_ATTRIBUTE && (
          <ScrollAwareFormSelect
            key={LITERAL_OR_ATTRIBUTE.ATTRIBUTE}
            scrollContextRef={scrollContextRef}
            error={_.includes(errorFields, `settings.${key}`)}
            label='&nbsp;'
            placeholder={formatMessage(messages['types.literalOrAttribute.attribute.placeholder'])}
            fluid
            disabled={attributeInputDisabled}
            search
            clearable
            onChange={(event, { value }) => onChangeAttributeSetting(key, value)}
            options={selectListToOptions(
              _.filter(
                selectContextLists.attributes,
                attribute => (
                  attribute.valueType === INPUT_TYPE_VALUE_TYPE[optionSettings.inputType]
                  && attribute.enumeration === optionSettings.enumeration
                ),
                formatMessage
              )
            )}
            value={attributeValue} />
        )}
      </OptionWrapper>
    );
  });
});

const TypesElement = injectIntl(({
  autoFocus,
  typesById,
  typeEntityType,
  search,
  create,
  hideCreateFields,
  isLoading,
  selectedTypeId,
  settingsValues,
  entityMessages,
  lockType,
  hideType,
  errorFields,
  hideSettings,
  readonly,
  conditionTypes,
  selectContextLists = {},
  scrollContextRef,
  parentId,
  inlineCreation,
  onChangeSetting,
  onChangeSettingConditionFilter,
  onAddSettingCondition,
  onRemoveSettingCondition,
  onChangeSettingCondition,
  onChangeType,
  onSearchChange,
  intl: {
    formatMessage,
    locale
  }
}) => {
  const formatKeyedMessage = (messageKeys, messageKey, defaultMessage) => {
    const message = messageKeys[messageKey];
    return message ? formatMessage(message) : (defaultMessage || messageKey);
  };

  const formatEntityMessage = (messageKey, defaultMessage) => {
    return formatKeyedMessage(entityMessages, messageKey, defaultMessage);
  };

  return (
    <>
      {hideType || (
        <ScrollAwareFormSelect
          scrollContextRef={scrollContextRef}
          error={_.includes(errorFields, 'type')}
          autoFocus={autoFocus}
          disabled={lockType}
          fluid
          clearable
          search={search}
          loading={isLoading}
          label={entityMessages['types.label']
            ? formatEntityMessage('types.label')
            : formatMessage(messages['types.label'])}
          options={[
            ...create
              ? [{
                key: CREATE_TYPE_ID,
                text: <><Icon name='plus' />{formatMessage(messages['types.create'])}</>,
                value: CREATE_TYPE_ID
              }]
              : [],
            ..._.map(_.values(typesById), type => ({
              key: type.id,
              text: formatEntityMessage(
                type.parentType
                  ? `type.${type.parentType}.${type.id}.name`
                  : `type.${type.id}.name`,
                type.name
              ),
              value: type.id
            }))
          ]}
          placeholder={entityMessages['types.placeholder']
            ? formatEntityMessage('types.placeholder')
            : formatMessage(messages['types.placeholder'])}
          value={selectedTypeId}
          onSearchChange={readonly
            ? undefined
            : ((event, { searchQuery }) => onSearchChange(searchQuery))}
          onChange={!readonly ? ((event, { value }) => onChangeType(value)) : undefined} />
      )}
      {selectedTypeId === CREATE_TYPE_ID && (
        <EntityFormContainer
          noFormWrapper
          inlineTypeCreation
          hideFields={hideCreateFields}
          entityType={typeEntityType}
          filterTypes={type => type.supportsInlineCreation}
          selectContextLists={selectContextLists}
          scrollContextRef={scrollContextRef}
          parentId={parentId} />
      )}
      {!hideSettings && (
        <OptionsForm
          typesById={typesById}
          selectedTypeId={selectedTypeId}
          settingsValues={settingsValues}
          errorFields={errorFields}
          entityMessages={entityMessages}
          readonly={readonly}
          conditionTypes={conditionTypes}
          selectContextLists={selectContextLists}
          scrollContextRef={scrollContextRef}
          inlineCreation={inlineCreation}
          onChangeSetting={onChangeSetting}
          onChangeSettingConditionFilter={onChangeSettingConditionFilter}
          onAddSettingCondition={onAddSettingCondition}
          onRemoveSettingCondition={onRemoveSettingCondition}
          onChangeSettingCondition={onChangeSettingCondition} />
      )}
    </>
  );
});

TypesElement.displayName = 'TypesElement';

export default TypesElement;