import { SET_FEATURES_TOGGLE } from '../../../../actions/chart';

export default (state = false, action) => {
  switch (action.type) {
    case SET_FEATURES_TOGGLE:
      return action.payload.value;
    default:
      return state;
  };
};

export const getFeaturesToggle = state => state;