import { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createSelector } from 'reselect';
import { Loader } from 'semantic-ui-react';
import { fetchById } from '../../../state/actions/entity';
import * as fromState from '../../../state/reducers';
import { ENTITY_TYPE } from '../../constants';

const mapStateToProps = createSelector(
  [
    (state, { id }) => id,
    state => fromState.getAll(state, ENTITY_TYPE.SENSOR_CONNECTOR)
  ],
  (
    id,
    allSensorConnectors
  ) => ({
    sensorConnector: allSensorConnectors[id]
  })
);

const mapDispatchToProps = (dispatch, { id }) => bindActionCreators({
  fetchSensorConnector: () => fetchById(ENTITY_TYPE.SENSOR_CONNECTOR, id)
}, dispatch);

function SensorConnectorText({
  id,
  sensorConnector,
  fetchSensorConnector
}) {
  useEffect(() => {
    if (id && !sensorConnector) {
      fetchSensorConnector();
    };
  });
  if (!id) {
    return null;
  };
  return sensorConnector
    ? sensorConnector.name
    : (
      <Loader
        inline
        active />
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(SensorConnectorText);