import { routerMiddleware } from 'connected-react-router';
import { applyMiddleware, compose, createStore } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import rootEpic from './state/epics';
import createRootReducer, { getPlaybackIsPlaying, getPlaybackMode, getPlaybackTime, getQuickRange, getView, getDashboardView, getPlaybackReviewId, getPlaybackReviewTrendId, getTenant, getCardSize, getShowCardDetails } from './state/reducers';
import reduxQuerySync from './state/enhancers/reduxQuerySync';
import * as api from './utils/api';
import { changePlaybackTime, setIsPlaying, setPlaybackMode, setReviewId, setReviewTrendId } from './state/actions/playback';
import moment from 'moment';
import { CHART_TYPE, PLAYBACK_MODE, CHART_VIEW, DASHBOARD_VIEW, CARD_SIZE } from './utils/constants';
import { setQuickRange, changeView } from './state/actions/chart';
import { setCardSize, setDashboardView, setShowCardDetails } from 'state/actions/dashboard';
import { setTenant } from 'state/actions/auth';

const epicMiddleware = createEpicMiddleware({
  dependencies: {
    api
  }
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true })
  : compose;

export default history => {
  const store = createStore(
    createRootReducer(history),
    composeEnhancers(      
      applyMiddleware(
        routerMiddleware(history),
        epicMiddleware
      ),
      reduxQuerySync.enhancer({
        history,
        params: {
          time: {
            selector: getPlaybackTime,
            action: changePlaybackTime,
            defaultValue: moment.utc().toISOString()
          },
          mode: {
            selector: getPlaybackMode,
            action: setPlaybackMode,
            defaultValue: PLAYBACK_MODE.LIVE
          },
          isPlaying: {
            selector: getPlaybackIsPlaying,
            action: setIsPlaying,
            defaultValue: true,
            valueToString: value => `${value}`,
            stringToValue: value => value === 'true'
          },
          window: {
            selector: state => getQuickRange(state, CHART_TYPE.MAIN),
            action: value => setQuickRange(CHART_TYPE.MAIN, value),
            defaultValue: 'P1D'
          },
          view: {
            selector: getView,
            action: changeView,
            defaultValue: CHART_VIEW.CARDS
          },
          dashboard: {
            selector: getDashboardView,
            action: setDashboardView,
            defaultValue: DASHBOARD_VIEW.UNITS
          },
          review: {
            selector: getPlaybackReviewId,
            action: setReviewId,
            defaultValue: null
          },
          reviewTrend: {
            selector: getPlaybackReviewTrendId,
            action: setReviewTrendId,
            defaultValue: null
          },
          tenant: {
            selector: getTenant,
            action: setTenant,
            defaultValue: null
          },
          cardSize: {
            selector: getCardSize,
            action: setCardSize,
            defaultValue: CARD_SIZE.LARGE,
            valueToString: value => `${value}`,
            stringToValue: value => parseInt(value)
          },
          showCardDetails: {
            selector: getShowCardDetails,
            action: setShowCardDetails,
            defaultValue: true,
            valueToString: value => `${value}`,
            stringToValue: value => value === 'true'
          }
        },
        initialTruth: 'location',
        replaceState: true
      })
    )    
  );

  epicMiddleware.run(rootEpic);

  return store;
};