import _ from 'lodash';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getSearchQuery } from 'state/selectors/entity';
import EntityPage from '../../components/entity/EntityPage';
import { deleteByQuery, fetchConditionTypes, fetchLabels, fetchPage, fetchTypes, pageChanged, performSearch, setFormValues, toggleModal } from '../../state/actions/entity';
import * as fromState from '../../state/reducers';
import { ENTITY_CONFIG, ENTITY_FORM_TYPE } from '../../utils/constants';
import entityConfig from '../../utils/entities';
import { matchesPath } from '../../utils/helpers';

const mapStateToProps = (state, { entityType, path }) => {
  const pageQueryString = queryString.parse(state.router.location.search).page;
  const page = _.isUndefined(pageQueryString) ? 0 : pageQueryString - 1;
  const queryQueryString = queryString.parse(state.router.location.search).query;
  const urlQuery = _.isUndefined(queryQueryString) ? null : queryQueryString;
  const isOnPage = !path || matchesPath(
    state.router.location.pathname,
    path
  );
  return {
    isLoading: fromState.getEntityIsLoading(state, entityType),
    labels: entityConfig[entityType][ENTITY_CONFIG.LABELS],
    page,
    urlQuery,
    formQuery: getSearchQuery(state, entityType),
    conditionTypes: fromState.getConditionTypes(state, entityType),
    isConnector: entityConfig[entityType][ENTITY_CONFIG.IS_CONNECTOR],
    addEnabled: !entityConfig[entityType][ENTITY_CONFIG.HIDE_ADD],
    importEnabled: entityConfig[entityType][ENTITY_CONFIG.IMPORT],
    additionalImports: entityConfig[entityType][ENTITY_CONFIG.ADDITIONAL_IMPORTS],
    searchAddEntityTypes: entityConfig[entityType][ENTITY_CONFIG.SEARCH_ADD_ENTITY_TYPES],
    searchEnabled: !!entityConfig[entityType][ENTITY_CONFIG.SEARCH],
    entityType,
    form: entityConfig[entityType].form,
    createEntity: entityConfig[entityType].createEntity,
    isOnPage
  };
};

const mapDispatchToProps = (dispatch, { entityType, parentId }) => ({
  ...bindActionCreators({
    ..._.mapValues({
      toggleModal,
      fetchPage,
      fetchTypes,
      fetchLabels,
      fetchConditionTypes,
      onSearch: entityType => performSearch(entityType, parentId),
      onSearchAndDelete: entityType => deleteByQuery(entityType, parentId)
    }, actionCreator => _.partial(actionCreator, entityType)),
    onToggleOtherEntityModal: toggleModal
  }, dispatch),
  onClearSearch: () => {
    dispatch(setFormValues(entityType, ENTITY_FORM_TYPE.SEARCH, {}));
    dispatch(pageChanged(entityType, 0, parentId));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(EntityPage);