import { ofType } from 'redux-observable';
import { filter, map, withLatestFrom } from 'rxjs/operators';
import { setFormValues, TOGGLE_MODAL } from '../../actions/entity';
import entityConfig from '../../../utils/entities';
import * as fromState from '../../reducers';
import { ENTITY_FORM_TYPE } from '../../../utils/constants';

export default (action$, state$) => action$.pipe(
  ofType(TOGGLE_MODAL),
  withLatestFrom(state$),
  filter(([action, state]) => action.payload.modal === 'edit' && fromState.getModalOpen(state, action.entityType, 'edit')),
  map(([action, state]) =>
    setFormValues(action.entityType, ENTITY_FORM_TYPE.ADD_OR_EDIT, entityConfig[action.entityType].populateForm(fromState.getById(state, action.entityType, action.payload.id))))
);