import _ from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';
import { setMuteModal } from '../../state/actions/dashboard';
import DashboardCard from '../../components/dashboard/DashboardCard';
import { CHART_TYPE, COLOUR, ENTITY_TYPE, DEFAULT_ALARM_RANGES, ENTITY_PROPERTY, PAGE_URL, ENTITY_MODAL, DASHBOARD_VIEW, EMPTY_STRING } from '../../utils/constants';
import * as fromState from '../../state/reducers';
import * as dashboardSelectors from '../../state/selectors/dashboard';
import { getColourFromSeverity, toPath, getModelOutputUnitsOfMeasurement } from '../../utils/helpers';
import { compose, withHandlers } from 'recompose';
import { edit, toggleModal, deleteById } from '../../state/actions/entity';
import { setAlarmModalIsOpen } from '../../state/actions/alarm';
import { querySyncPush } from '../../state/actions/navigation';
import { getUnitPaths } from 'state/selectors/entity';
import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';
import { bindActionCreators } from 'redux';

const getStateLabel = (predictor, isEditing) => {
  const state = predictor.state;
  if (!state) {
    return null;
  };
  const iconButtons = (predictor.from || predictor.to)
    ? [
      {
        name: 'rerun',
        icon: 'redo',
        popupMessageId: 'predictor.dashboardCard.footerLabel.iconButton.rerun.popupText',
        popupDefaultMessage: 'Re-run',
        disabled: isEditing,
        loading: isEditing
      }
    ]
    : [];
  switch (state.state) {
    case 'historical':
      return {
        progress: true,
        messageId: 'alertMapping.columns.status.historical',
        color: 'yellow',
        percent: _.chain(state.history)
          .findLast(['type', 'progress'])
          .get('settings.percentage')
          .toNumber()
          .value() || 0,
        iconButtons
      };
    case 'live':
      return {
        color: 'green',
        messageId: 'alertMapping.columns.status.live',
        iconButtons
      };
    case 'complete':
      return {
        messageId: 'alertMapping.columns.status.complete',
        iconButtons
      };
    case 'error':
      return {
        color: 'red',
        content: 'alertMapping.columns.status.error',
        detail: _.chain(state.history)
          .findLast(['type', 'error'])
          .get('settings.message')
          .value(),
        iconButtons
      };
    default:
      return null;
  };
};

const getUnitsOfMeasurement = createSelector(
  [
    (state, { predictor }) => predictor,
    (state, { predictor }) => fromState.getById(state, ENTITY_TYPE.MODEL, predictor.type),
    state => fromState.getAll(state, ENTITY_TYPE.SENSOR)
  ],
  (predictor, model, allSensors) => {
    if (!model) {
      return EMPTY_STRING;
    }

    const inputSensors = _.chain(predictor)
      .get(ENTITY_PROPERTY.PREDICTOR.INPUT_MAPPINGS)
      .map(inputMapping => [inputMapping.input, allSensors[inputMapping.sensor]])
      .fromPairs()
      .value();

    return getModelOutputUnitsOfMeasurement(
      model,
      _.first(model.outputs).name,
      inputSensors
    );
  }
);

const getPredictorCardLabels = createSelector(
  [
    (state, { view }) => view,
    (state, { predictor }) => predictor,
    (state, { predictor }) => fromState.getById(state, ENTITY_TYPE.MODEL, predictor.type),
    state => fromState.getAll(state, ENTITY_TYPE.UNIT_TYPE),
    state => fromState.getAll(state, ENTITY_TYPE.UNIT_TYPE_PREDICTOR)
  ],
  (view, predictor, model, allUnitTypes, allUnitTypePredictors) => {
    if (view === DASHBOARD_VIEW.UNIT_TYPES) {
      return [];
    }

    const unitTypePredictor = allUnitTypePredictors[predictor.unitTypePredictor];
    const unitType = unitTypePredictor
      ? allUnitTypes[unitTypePredictor.unitType]
      : null;

    return [{
      type: ENTITY_TYPE.MODEL,
      icon: predictor.type
        ? 'lightbulb outline'
        : 'thermometer half',
      color: 'violet',
      detail: unitType && unitType.name,
      messageId: model
        ? `model.type.${model.type}.name`
        : 'model.type.sensorGroup.name',
      id: model
        ? model.id
        : null
    }];
  }
);

const isActive = predictor => !predictor.mutedUntil || moment.utc(predictor.mutedUntil) < moment().utc();

const mapStateToProps = createCachedSelector(
  [
    getUnitPaths,
    (state, { predictor }) => fromState.getById(state, ENTITY_TYPE.UNIT, predictor.unitId),
    (state, { predictor }) => fromState.getById(state, ENTITY_TYPE.MODEL, predictor.type),
    (state, { predictor }) => dashboardSelectors.getPredictorSeverity(state, { id: predictor.id }),
    (state, { predictor }) => _.some(predictor.outputMappings)
      ? fromState.getLatestOutputValue(state, CHART_TYPE.MAIN, predictor.id, _.first(predictor.outputMappings).output)
      : null,
    state => fromState.getAll(state, ENTITY_TYPE.ENUMERATION),
    state => fromState.getAll(state, ENTITY_TYPE.UNIT_TYPE_PREDICTOR),
    state => fromState.getEditingId(state, ENTITY_TYPE.PREDICTOR),
    (state, { predictor }) => predictor,
    (state, { view }) => view,
    state => fromState.getShowCardDetails(state),
    getUnitsOfMeasurement,
    getPredictorCardLabels
  ],
  (
    unitPaths,
    unit,
    model,
    severity,
    outputValue,
    allEnumerations,
    allUnitTypePredictors,
    editingId,
    predictor,
    view,
    showDetails,
    unitsOfMeasurement,
    predictorCardLabels
  ) => {
    const unitPath = unitPaths[predictor.unitId] || predictor.unitId;
    const active = isActive(predictor);
    const {
      valueType,
      enumeration: enumerationId
    } = model ? _.first(model.outputs) : {};
    const enumeration = allEnumerations[enumerationId];
    const unitTypePredictor = allUnitTypePredictors[predictor.unitTypePredictor];
    const alarmRanges = _.chain(predictor.outputMappings)
      .first()
      .get(ENTITY_PROPERTY.PREDICTOR.OUTPUT_MAPPING.ALARM_RANGES)
      .value();

    // If displaying an instance of a unit type predictor, need to display the full
    // path to the unit since units at different locations may have the same name.
    const name = view === DASHBOARD_VIEW.UNITS
      ? predictor.name
      : unitPath;

    return {
      id: predictor.id,
      linkTo: toPath(PAGE_URL.PREDICTOR, {
        unitPath,
        groupId: predictor.id
      }),
      name,
      description: view === DASHBOARD_VIEW.UNITS
        ? null
        : _.get(unit, ENTITY_PROPERTY.UNIT.DESCRIPTION),
      labels: predictorCardLabels,
      footerLabel: !!predictor.type && getStateLabel(predictor, editingId === predictor.id),
      canMute: true,
      canDelete: !unitTypePredictor,
      canConfigure: !unitTypePredictor,
      entityType: ENTITY_TYPE.PREDICTOR,
      noModel: !predictor.type,
      severity,
      unitPath,
      alarmRanges: alarmRanges || DEFAULT_ALARM_RANGES,
      valueType,
      outputValue,
      outputLabel: enumeration && enumeration.states[outputValue],
      fill: active ? getColourFromSeverity(severity) : COLOUR.GREY,
      active,
      unitsOfMeasurement,
      showDetails
    };
  }
)(
  (state, { predictor, view }) => `${predictor.id}.${view}`
);

const mapDispatchToProps = (dispatch, { predictor }) => bindActionCreators({
  onClickAlarm: () => setMuteModal(isActive(predictor) ? 'mute' : 'unmute', true, predictor.id),
  clickConfiguration: (id, unitPath) => querySyncPush(toPath(PAGE_URL.PREDICTOR_CONFIGURATION, {
    unitPath,
    parentId: id
  })),
  onClickAdd: () => setAlarmModalIsOpen(true),
  onClickDelete: () => toggleModal(ENTITY_TYPE.PREDICTOR, ENTITY_MODAL.DELETE, predictor.id),
  onDelete: () => deleteById(ENTITY_TYPE.PREDICTOR, predictor.id),
  onClickFooterLabelIconButton: () => {
    return edit(
      ENTITY_TYPE.PREDICTOR,
      {
        ...predictor,
        state: {
          state: 'historical',
          history: [
            ..._.reject(predictor.state.history, ['type', 'progress']),
            {
              type: 'rerun',
              settings: {}
            }
          ]
        }
      }
    );
  }
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    onClickConfiguration: ({ clickConfiguration, id, unitPath }) => () => clickConfiguration(id, unitPath)
  })
)(DashboardCard);