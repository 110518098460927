import { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createSelector } from 'reselect';
import { Loader } from 'semantic-ui-react';
import { fetchById } from '../../../state/actions/entity';
import * as fromState from '../../../state/reducers';
import { ENTITY_TYPE } from '../../constants';

const mapStateToProps = createSelector(
  [
    (state, { id }) => id,
    state => fromState.getAll(state, ENTITY_TYPE.UNIT_TYPE_PREDICTOR)
  ],
  (
    id,
    allUnitTypePredictors
  ) => ({
    unitTypePredictor: allUnitTypePredictors[id]
  })
);

const mapDispatchToProps = (dispatch, { id }) => bindActionCreators({
  fetchUnitTypePredictor: () => fetchById(ENTITY_TYPE.UNIT_TYPE_PREDICTOR, id)
}, dispatch);

const UnitTypePredictorText = ({
  id,
  unitTypePredictor,
  fetchUnitTypePredictor
}) => {
  useEffect(() => {
    if (id && !unitTypePredictor) {
      fetchUnitTypePredictor();
    };
  });
  if (!id) {
    return null;
  };
  return unitTypePredictor
    ? unitTypePredictor.name
    : (
      <Loader
        inline
        active />
    );
};
UnitTypePredictorText.displayName = 'UnitTypePredictorText';

export default connect(mapStateToProps, mapDispatchToProps)(UnitTypePredictorText);