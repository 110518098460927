import { ofType } from 'redux-observable';
import { map, withLatestFrom } from 'rxjs/operators';
import { pageChanged, PERFORM_SEARCH } from '../../actions/entity';
import * as entitySelectors from '../../selectors/entity';

export default (action$, state$) => action$.pipe(
  ofType(PERFORM_SEARCH),
  withLatestFrom(state$),
  map(([action, state]) =>
    pageChanged(
      action.entityType,
      0,
      action.payload.parentId,
      entitySelectors.getSearchQuery(state, action.entityType)
    ))
);