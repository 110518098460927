import { SET_DASHBOARD_VIEW } from '../../actions/dashboard';
import { DASHBOARD_VIEW } from 'utils/constants';

export default (state: string = DASHBOARD_VIEW.UNITS, action) => {
  switch (action.type) {
    case SET_DASHBOARD_VIEW:
      return action.payload.view;
    default:
      return state;
  }
};

export const getView = (state: string) => state;