import AlarmRangesModal from '../../components/alarm/AlarmRangesModal';
import { connect } from 'react-redux';
import * as fromState from '../../state/reducers';
import { setAlarmModalIsOpen } from '../../state/actions/alarm';

const mapStateToProps = state => ({
  open: fromState.getAlarmModalIsOpen(state),
  alarmRanges: fromState.getAlarmRanges(state),
  isSaving: fromState.getAlarmIsSaving(state),
  isValid: fromState.getAlarmRangesValid(state)
});

const mapDispatchToProps = {
  onClose: () => setAlarmModalIsOpen(false)
};

export default connect(mapStateToProps, mapDispatchToProps)(AlarmRangesModal);