import { defineMessages } from 'react-intl';

export default defineMessages({
  'prophes': {
    id: 'authMenu.prophes',
    defaultMessage: 'PROPHES'
  },
  'explorer': {
    id: 'authMenu.explorer',
    defaultMessage: 'PROPHES Explorer'
  },
  'logout': {
    id: 'authMenu.logout',
    defaultMessage: 'Logout'
  }
});