import EntityText from 'components/entity/EntityText';
import TypesElement from 'components/entity/TypesElement';
import ActionsTableContainer from 'containers/chart/ActionsTableContainer';
import moment from 'moment';
import { CSSProperties, ReactNode } from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';
import { Form, Header, Popup } from 'semantic-ui-react';
import { Trend, TrendType } from 'types';
import { INVERTED_THEME, ENTITY_TYPE, DATE_FORMAT } from 'utils/constants';
import styles from './TrendTimeline.styles';
import entityConfig from 'utils/entities';

interface Props {
  trigger: ReactNode;
  trend: Trend;
  allTrendTypes: { [id: string]: TrendType; };
  intl: InjectedIntl;
}
export default injectIntl(function TrendLabelPopup({
  trigger,
  trend,
  allTrendTypes,
  intl: {
    formatMessage
  }
}: Props) {
  return (
    <Popup
      trigger={trigger}
      inverted={INVERTED_THEME}
      style={styles.trendLabelPopup}
      wide
      onMouseMove={e => e.stopPropagation()}
      onClick={e => e.stopPropagation()}
      hoverable
      basic
      on='hover'
      position='bottom center'>
      <Header
        inverted={INVERTED_THEME}
        textAlign='center'>
        {trend.name}
        <Header.Subheader style={styles.trendLabelUnitSubheader}>
          <EntityText
            id={trend.unitId}
            entityType={ENTITY_TYPE.UNIT} />
        </Header.Subheader>
        <Header.Subheader>
          {moment(trend.to).local().format(DATE_FORMAT)}
        </Header.Subheader>
      </Header>
      <div style={styles.trendLabelPopupContent as CSSProperties}>
        <Form
          inverted={INVERTED_THEME}>
          <Form.TextArea
            label={formatMessage(entityConfig.trend.messages['form.description.label'])}
            value={trend.description}
            readOnly />
          {trend && (
            <TypesElement
              typesById={allTrendTypes}
              selectedTypeId={trend.type}
              settingsValues={trend.settings}
              entityMessages={entityConfig.trend.messages}
              readonly
              lockType />
          )}
        </Form>
        <Header
          style={styles.trendLabelDividingHeader}
          dividing
          content='Actions' />
        <ActionsTableContainer trend={trend} />
      </div>
    </Popup>
  );
});