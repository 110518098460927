import _ from 'lodash';
import { defineMessages, injectIntl } from 'react-intl';
import AddEntityModalContainer from '../../../containers/entity/AddEntityModalContainer';
import ConnectorAccessTokenModalContainer from '../../../containers/entity/ConnectorAccessTokenModalContainer';
import EntityImportModalContainer from '../../../containers/entity/EntityImportModalContainer';
import { ENTITY_MODAL } from '../../../utils/constants';
import AnimatedButton from '../../shared/AnimatedButton';
import { Grid } from 'semantic-ui-react';

const messages = defineMessages({
  'addButton.text': {
    id: 'entityPage.addButton.text',
    defaultMessage: 'Add'
  },
  'addConnectorButton.text': {
    id: 'entityPage.addConnectorButton.text',
    defaultMessage: 'Add Connector'
  },
  'importButton.text': {
    id: 'entityPage.importButton.text',
    defaultMessage: 'Import'
  }
});

const AdminButtons = injectIntl(({
  entityType,
  isConnector,
  isLoading,
  parentId,
  form,
  createEntity,
  addEnabled,
  importEnabled,
  additionalImports,
  onToggleModal,
  onToggleOtherEntityModal,
  intl: {
    formatMessage
  }
}) => (
  <Grid className='button-bar'>
    <Grid.Column>
      {addEnabled && (
        <AddEntityModalContainer
          trigger={(
            <AnimatedButton
              size='small'
              text={isConnector
                ? formatMessage(messages['addConnectorButton.text'])
                : formatMessage(messages['addButton.text'])}
              icon='add'
              positive
              floated='right'
              onClick={() => onToggleModal(ENTITY_MODAL.ADD)}
              disabled={isLoading} />
          )}
          entityType={entityType}
          form={form}
          createEntity={createEntity}
          parentId={parentId} />
      )}
      {importEnabled && (
        <EntityImportModalContainer
          entityType={entityType}
          parentId={parentId}
          trigger={(
            <AnimatedButton
              size='small'
              text={formatMessage(messages['importButton.text'])}
              icon='upload'
              floated='right'
              positive
              onClick={() => onToggleModal(ENTITY_MODAL.IMPORT)}
              disabled={isLoading} />
          )} />
      )}
      {_.map(additionalImports, additionalImport => (
        <EntityImportModalContainer
          key={additionalImport.entityType}
          entityType={additionalImport.entityType}
          parentId={parentId}
          trigger={(
            <AnimatedButton
              size='small'
              text={formatMessage(additionalImport.message)}
              icon='upload'
              floated='right'
              positive
              onClick={() => onToggleOtherEntityModal(additionalImport.entityType, ENTITY_MODAL.IMPORT)}
              disabled={isLoading} />
          )} />
      ))}
      <ConnectorAccessTokenModalContainer entityType={entityType} />
    </Grid.Column>
  </Grid>
));

AdminButtons.displayName = 'AdminButtons';

export default AdminButtons;