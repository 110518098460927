import _ from 'lodash';
import createCachedSelector from 're-reselect';
import { getPredictor } from '../getPredictor';

export const getOutputAlarmRanges = createCachedSelector(
  [getPredictor],
  predictor => _.chain(predictor)
    .get('outputMappings')
    .map(({ output, alarmRanges }) => [
      output,
      alarmRanges
    ])
    .fromPairs()
    .value()
)(
  (state, chartType) => chartType
);