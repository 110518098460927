import { ofType } from 'redux-observable';
import { EMPTY, merge, of } from 'rxjs';
import { delay, filter, mergeMap, withLatestFrom } from 'rxjs/operators';
import { ENTITY_CONFIG, ENTITY_MODAL, PAGE_URL } from '../../../utils/constants';
import entityConfig from '../../../utils/entities';
import { ADD_FULFILLED, openEntity, setIsAdding, toggleModal } from '../../actions/entity';
import { getUnitPaths } from '../../selectors/entity';
import { toPath } from '../../../utils/helpers';
import { querySyncPush } from '../../actions/navigation';

export default (action$, state$) => action$.pipe(
  ofType(ADD_FULFILLED),
  withLatestFrom(state$),
  filter(([action]) =>
    !entityConfig[action.entityType][ENTITY_CONFIG.IS_CONNECTOR]
    && (
      entityConfig[action.entityType][ENTITY_CONFIG.NAVIGATE_ON_ADD]
      || entityConfig[action.entityType][ENTITY_CONFIG.OPEN_ON_ADD]
    )),
  mergeMap(([action, state]) => merge(
    of(
      setIsAdding(action.entityType, true)
    ),
    of(null).pipe(
      delay(3000),
      mergeMap(() => merge(
        entityConfig[action.entityType][ENTITY_CONFIG.NAVIGATE_ON_ADD]
          ? of(
            querySyncPush(
              entityConfig[action.entityType][ENTITY_CONFIG.ITEM_URL]
                ? entityConfig[action.entityType][ENTITY_CONFIG.ITEM_URL](action.payload.entity, getUnitPaths(state))
                : toPath(PAGE_URL.ENTITY, {
                  pageUrl: entityConfig[action.entityType][ENTITY_CONFIG.PAGE_URL],
                  entityId: action.payload.entity.id
                })
            )
          )
          : EMPTY,
        entityConfig[action.entityType][ENTITY_CONFIG.OPEN_ON_ADD]
          ? of(
            openEntity(action.entityType, action.payload.entity)
          )
          : EMPTY,
        of(
          setIsAdding(action.entityType, false),
          toggleModal(action.entityType, ENTITY_MODAL.ADD)
        )
      ))
    )
  ))
);