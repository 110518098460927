import { Fragment, useEffect } from 'react';
import { defineMessages, InjectedIntl, injectIntl } from 'react-intl';
import { Form } from 'semantic-ui-react';
import conditionMessages from '../../utils/i18n/shared/conditions';
import TypedList from './TypedList';
import _ from 'lodash';
import * as helpers from '../../utils/helpers';
import { EMPTY_STRING } from 'utils/constants';
import { Condition, ConditionElement, ConditionFilter, ConditionType } from 'types';

const messages = defineMessages({
  'conditions.label': {
    id: 'entityForm.conditions.label',
    defaultMessage: 'Conditions'
  },
  'conditions.filter.all': {
    id: 'entityForm.conditions.filter.all',
    defaultMessage: 'Match ALL of these conditions'
  },
  'conditions.filter.any': {
    id: 'entityForm.conditions.filter.any',
    defaultMessage: 'Match ANY of these conditions'
  }
});

const getOptionUnitsOfMeasurement = (
  propertyName: string,
  condition: Condition,
  form: ConditionElement[]
) => _.chain(form)
  .find(['name', propertyName])
  .get('options')
  .find(['value', condition[propertyName]])
  .get('unitsOfMeasurement')
  .value() || EMPTY_STRING;

const getFeatureUnitsOfMeasurement = (condition, form) =>
  helpers.getFeatureUnitsOfMeasurement(
    condition.feature,
    getOptionUnitsOfMeasurement('input', condition, form),
    getOptionUnitsOfMeasurement('featureInput', condition, form)
  );

const getUnitsOfMeasurement = (condition, form) => {
  if (condition.name) {
    return getOptionUnitsOfMeasurement('name', condition, form);
  };
  if (condition.input) {
    return getFeatureUnitsOfMeasurement(condition, form);
  };
  return null;
};

interface Props {
  filter: ConditionFilter;
  list: Condition[];
  defaultValue: any;
  lockFilter: boolean;
  conditionForm: ConditionElement[];
  conditionTypes: ConditionType[];
  conditionTypeFilter: string;
  enumerationPath: string;
  disabled: boolean;
  loading: boolean;
  errorFields: any[];
  getErrorPath: (conditionIndex: number) => string;
  errorPathPrefix: string;
  selectContextLists: { [key: string]: any[]; };
  onAddCondition: any;
  onChangeCondition: any;
  onRemoveCondition: any;
  onChangeFilter: any;
  intl: InjectedIntl;
}
const ConditionsElement = injectIntl(({
  intl: {
    formatMessage
  },
  filter,
  list,
  defaultValue,
  lockFilter,
  conditionForm,
  conditionTypes,
  conditionTypeFilter,
  enumerationPath,
  disabled,
  loading,
  errorFields,
  getErrorPath,
  errorPathPrefix,
  selectContextLists,
  onAddCondition,
  onChangeCondition,
  onRemoveCondition,
  onChangeFilter
}: Props) => {
  const formatKeyedMessage = (messageKeys, messageKey) => {
    const message = messageKeys[messageKey];
    return message ? formatMessage(message) : messageKey;
  };

  const formatMessageMessage = (messageKey) => {
    return formatKeyedMessage(messages, messageKey);
  };

  const formatConditionMessage = (messageKey) => {
    return formatKeyedMessage(conditionMessages, messageKey);
  };

  useEffect(() => {
    if (defaultValue && !filter) {
      onChangeFilter(defaultValue.filter);
    };
  }, [defaultValue, filter, onChangeFilter]);

  return (
    <Fragment>
      <Form.Field>
        <label>{formatMessageMessage('conditions.label')}</label>
        <Form.Select
          disabled={lockFilter || disabled}
          value={filter}
          options={[
            {
              key: 'All',
              text: formatMessageMessage('conditions.filter.all'),
              value: 'All'
            },
            {
              key: 'Any',
              text: formatMessageMessage('conditions.filter.any'),
              value: 'Any'
            }
          ]}
          onChange={(event, { value }) => onChangeFilter(value)} />
      </Form.Field>
      <TypedList
        list={list}
        form={conditionForm}
        types={conditionTypes}
        getTypeText={typeId => formatConditionMessage(`condition.${typeId}.name`)}
        getTypeOptionPlaceholder={(typeId, option) => formatConditionMessage(`condition.${typeId}.options.${option}.placeholder`)}
        typeFilter={conditionTypeFilter}
        enumerationPath={enumerationPath}
        disabled={disabled}
        loading={loading}
        errorFields={errorFields}
        getErrorPath={(conditionIndex, elementName) => `${getErrorPath(conditionIndex)}.${elementName}`}
        getSettingErrorPath={(conditionIndex, option) => `${getErrorPath(conditionIndex)}.settings.${option}`}
        errorPathPrefix={errorPathPrefix}
        getUnitsOfMeasurement={getUnitsOfMeasurement}
        selectContextLists={selectContextLists}
        onAddItem={onAddCondition}
        onChangeItem={onChangeCondition}
        onRemoveItem={onRemoveCondition} />
    </Fragment>
  );
});

ConditionsElement.displayName = 'ConditionsElement';

export default ConditionsElement;