import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import auth, * as fromAuth from './auth';
import createEntityReducer, * as fromEntity from './entity';
import chart, * as fromChart from './chart';
import dashboard, * as fromDashboard from './dashboard';
import data, * as fromData from './data';
import alarm, * as fromAlarm from './alarm';
import playback, * as fromPlayback from './playback';
import loading, * as fromLoading from './loading';
import _ from 'lodash';
import { CHART_TYPE, CHART_VIEW, ENTITY_TYPE } from '../../utils/constants';
import { RESET_APP } from '../actions/auth';
import createCachedSelector from 're-reselect';
import { AuthGroup, Entity } from 'types';
import { RootState } from 'index';
import { HoverLineState } from './chart/shared/trend/hoverLine';

export default history => {
  const appReducer = combineReducers({
    router: connectRouter(history),
    auth,
    chart,
    dashboard,
    data: combineReducers({
      [CHART_TYPE.MAIN]: data(CHART_TYPE.MAIN),
      [CHART_TYPE.COMPARE]: data(CHART_TYPE.COMPARE)
    }),
    alarm,
    playback,
    loading,
    entities: combineReducers(_.chain(ENTITY_TYPE).invert().mapValues((key, entityType) => createEntityReducer(entityType)).value())
  });

  return (state, action) => {
    if (action.type === RESET_APP) {
      return appReducer(undefined, action);
    }

    return appReducer(state, action);
  };
};

export const getAuthFormValues = (state, authType) => fromAuth.getValues(state.auth, authType);
export const getAuthGroupId = state => fromAuth.getAuthGroupId(state.auth);
export const getAuthIsLoading = (state, authType) => fromAuth.getIsLoading(state.auth, authType);
export const getAuthErrorResponse = (state, authType) => fromAuth.getErrorResponse(state.auth, authType);
export const getAuthErrorCodes = (state, authType) => fromAuth.getErrorCodes(state.auth, authType);
export const getAuthErrorKeys = (state, authType) => fromAuth.getErrorKeys(state.auth, authType);
export const getShowPassword = (state, authType) => fromAuth.getShowPassword(state.auth, authType);
export const getUser = state => fromAuth.getUser(state.auth);
export const getAuthMessage = state => fromAuth.getMessage(state.auth);
export const getTenant = state => fromAuth.getTenant(state.auth);
export const getIsInRole = (state, role) => {
  if (!role) {
    return true;
  }

  const authGroupId = getAuthGroupId(state);
  const authGroup = getById<AuthGroup>(state, ENTITY_TYPE.AUTH_GROUP, authGroupId);
  if (!authGroup) {
    return false;
  }

  return authGroup && _.includes(authGroup.roles, role.id);
};

export const getAddErrorResponse = (state, entityType) => fromEntity.getAddErrorResponse(state.entities[entityType]);
export const getAddErrorRequest = (state, entityType) => fromEntity.getAddErrorRequest(state.entities[entityType]);
export const getAddErrorCodes = (state, entityType, errorPathPrefixes) => fromEntity.getAddErrorCodes(state.entities[entityType], errorPathPrefixes);
export const getAddErrorMessages = (state, entityType, errorPathPrefixes) => fromEntity.getAddErrorMessages(state.entities[entityType], errorPathPrefixes);
export const getAllIds = (state, entityType) => fromEntity.getAllIds(state.entities[entityType]);
export const getAllTypeIds = (state, entityType) => fromEntity.getAllTypeIds(state.entities[entityType]);
export const getById = <T extends Entity>(state, entityType, id) => fromEntity.getById<T>(state.entities[entityType], id);
export const getAll = <T extends Entity>(state, entityType) => fromEntity.getAll<T>(state.entities[entityType]);
export const getEditErrorResponse = (state, entityType) => fromEntity.getEditErrorResponse(state.entities[entityType]);
export const getEditErrorRequest = (state, entityType) => fromEntity.getEditErrorRequest(state.entities[entityType]);
export const getEditErrorCodes = (state, entityType, errorPathPrefixes) => fromEntity.getEditErrorCodes(state.entities[entityType], errorPathPrefixes);
export const getEditErrorMessages = (state, entityType, errorPathPrefixes) => fromEntity.getEditErrorMessages(state.entities[entityType], errorPathPrefixes);
export const getAllFormSearchResults = (state, entityType) => fromEntity.getAllFormSearchResults(state.entities[entityType]);
export const getIsAdding = (state, entityType) => fromEntity.getIsAdding(state.entities[entityType]);
export const getIsDeleting = (state, entityType) => fromEntity.getIsDeleting(state.entities[entityType]);
export const getIsEditing = (state, entityType) => fromEntity.getIsEditing(state.entities[entityType]);
export const getEntityIsLoading = (state, entityType) => fromEntity.getIsLoading(state.entities[entityType]);
export const getIsFetchingAll = (state, entityType) => fromEntity.getIsFetchingAll(state.entities[entityType]);
export const getPage = (state, entityType) => fromEntity.getPage(state.entities[entityType]);
export const getPageSize = (state, entityType) => fromEntity.getPageSize(state.entities[entityType]);
export const getTotal = (state, entityType) => fromEntity.getTotal(state.entities[entityType]);
export const getAllTypes = createCachedSelector(
  [
    (state: RootState, entityType: string) => state.entities[entityType]
  ],
  entityState => fromEntity.getAllTypes(entityState)
)(
  (state, entityType) => entityType
);
export const getTypesById = (state, entityType) => fromEntity.getTypesById(state.entities[entityType]);
export const getTypeById = (state, entityType, id) => fromEntity.getTypeById(state.entities[entityType], id);
export const getConditionTypes = (state, entityType) => fromEntity.getConditionTypes(state.entities[entityType]);
export const getFormValue = (state, entityType, formType, key) => fromEntity.getFormValue(state.entities[entityType], formType, key);
export const getFormValues = createCachedSelector(
  [
    (state, entityType, formType) => fromEntity.getFormValues(state.entities[entityType], formType),
    (state, entityType, formType) => fromEntity.getFormSettings(state.entities[entityType], formType),
    (state, entityType, formType) => fromEntity.getFormConditions(state.entities[entityType], formType)
  ],
  (values, settings, conditions) => ({
    ...values,
    settings,
    conditions
  })
)(
  (state, entityType, formType) => `${entityType}.${formType}`
);
export const getFormSettings = (state, entityType, formType) => fromEntity.getFormSettings(state.entities[entityType], formType);
export const getFormSetting = (state, entityType, formType, key) => fromEntity.getFormSetting(state.entities[entityType], formType, key);
export const getFormConditionsFilter = (state, entityType, formType) => fromEntity.getFormConditionsFilter(state.entities[entityType], formType);
export const getFormConditionsList = (state, entityType, formType) => fromEntity.getFormConditionsList(state.entities[entityType], formType);
export const getModalId = (state, entityType, name) => fromEntity.getModalId(state.entities[entityType], name);
export const getModalOpen = (state, entityType, name) => fromEntity.getModalOpen(state.entities[entityType], name);
export const getModalOpenById = (state, entityType, name, id) => fromEntity.getModalOpenById(state.entities[entityType], name, id);
export const getModals = (state, entityType) => fromEntity.getModals(state.entities[entityType]);
export const getErrorFields = (state, entityType) => fromEntity.getErrorFields(state.entities[entityType]);
export const getAccessToken = (state, entityType) => fromEntity.getAccessToken(state.entities[entityType]);
export const getLabels = (state, entityType) => fromEntity.getLabels(state.entities[entityType]);
export const getIsImporting = (state, entityType) => fromEntity.getIsImporting(state.entities[entityType]);
export const getImportErrorCodes = (state, entityType) => fromEntity.getImportErrorCodes(state.entities[entityType]);
export const getImportErrorRow = (state, entityType) => fromEntity.getImportErrorRow(state.entities[entityType]);
export const getImportErrorTotal = (state, entityType) => fromEntity.getImportErrorTotal(state.entities[entityType]);
export const getDeleteErrorResponse = (state, entityType) => fromEntity.getDeleteErrorResponse(state.entities[entityType]);
export const getDeleteErrorRequest = (state, entityType) => fromEntity.getDeleteErrorRequest(state.entities[entityType]);
export const getDeleteErrorCodes = createCachedSelector(
  [
    (state, entityType) => state.entities[entityType]
  ],
  fromEntity.getDeleteErrorCodes
)(
  (state, entityType) => entityType
);
export const getDeleteErrorMessages = (state, entityType) => fromEntity.getDeleteErrorMessages(state.entities[entityType]);
export const getSort = (state, entityType) => fromEntity.getSort(state.entities[entityType]);
export const getEditingId = (state, entityType) => fromEntity.getEditingId(state.entities[entityType]);

export const getView = (state: any): CHART_VIEW => fromChart.getView(state.chart);
export const getSelected = (state, chartType, selectionType) => fromChart.getSelected(state.chart, chartType, selectionType);
export const getQuickRange = (state: any, chartType: CHART_TYPE): string => fromChart.getQuickRange(state.chart, chartType);
export const getFromDate = (state: any, chartType: CHART_TYPE): string => fromChart.getFromDate(state.chart, chartType);
export const getToDate = (state: any, chartType: CHART_TYPE): string => fromChart.getToDate(state.chart, chartType);
export const getGroup = (state, chartType) => fromChart.getGroup(state.chart, chartType);
export const getHoverLine = (
  state: any,
  chartType: CHART_TYPE
): HoverLineState => fromChart.getHoverLine(state.chart, chartType);
export const getBrushStartTimestamp = (state, chartType: CHART_TYPE) => fromChart.getBrushStartTimestamp(state.chart, chartType);
export const getBrushEndTimestamp = (state, chartType: CHART_TYPE) => fromChart.getBrushEndTimestamp(state.chart, chartType);
export const getCopiedText = (state, chartType) => fromChart.getCopiedText(state.chart, chartType);
export const getEditingFunction = (state, chartType) => fromChart.getEditingFunction(state.chart, chartType);
export const getFunctionDescriptionEditText = (state, chartType) => fromChart.getFunctionDescriptionEditText(state.chart, chartType);
export const getFeaturesToggle = (state, chartType) => fromChart.getFeaturesToggle(state.chart, chartType);
export const getSelectedFeature = (state, chartType) => fromChart.getSelectedFeature(state.chart, chartType);
export const getSelectedTrends = state => fromChart.getSelectedTrends(state.chart);
export const getFeatureWindow = (state, chartType) => fromChart.getFeatureWindow(state.chart, chartType);
export const getSelectedProperty = (state, chartType) => fromChart.getSelectedProperty(state.chart, chartType);
export const getTrendTimelineHeight = state => fromChart.getTrendTimelineHeight(state.chart);
export const getCompareOpen = state => fromChart.getCompareOpen(state.chart);
export const getTrendIds = state => fromChart.getTrendIds(state.chart);
export const getCompareTrend = (state: any): string | null => fromChart.getCompareTrend(state.chart);
export const getCompareAll = state => fromChart.getCompareAll(state.chart);
export const getCompareFromDate = state => fromChart.getCompareFromDate(state.chart);
export const getCompareToDate = state => fromChart.getCompareToDate(state.chart);
export const getCompareLabels = state => fromChart.getCompareLabels(state.chart);
export const getTrendCount = state => fromChart.getTrendCount(state.chart);
export const getChartIsLoading = (state, namespace) => fromChart.getIsLoading(state.chart, namespace);

export const getPredictors = state => fromDashboard.getPredictors(state.dashboard);
export const getLoadingPredictors = (state, unitId) => fromDashboard.getLoadingPredictors(state.dashboard, unitId);
export const getMuteModalId = (state, modal) => fromDashboard.getMuteModalId(state.dashboard, modal);
export const getMuteModalOpen = (state, modal) => fromDashboard.getMuteModalOpen(state.dashboard, modal);
export const getMuteModalUntil = state => fromDashboard.getMuteModalUntil(state.dashboard);
export const getMuteModalIsSaving = (state, modal) => fromDashboard.getMuteModalIsSaving(state.dashboard, modal);
export const getDashboardView = state => fromDashboard.getView(state.dashboard);
export const getUnitPath = state => fromDashboard.getUnitPath(state.dashboard);
export const getCardSize = state => fromDashboard.getCardSize(state.dashboard);
export const getShowCardDetails = state => fromDashboard.getShowCardDetails(state.dashboard);
export const getCollapsedDecks = state => fromDashboard.getCollapsedDecks(state.dashboard);

export const getLatestSensorValue = (state, chartType, id) => fromData.getLatestSensorValue(state.data[chartType], id);
export const getAllLatestSensorValues = (state, chartType) => fromData.getAllLatestSensorValues(state.data[chartType]);
export const getLatestOutputValue = (state, chartType, group, id) => fromData.getLatestOutputValue(state.data[chartType], group, id);
export const getAllLatestOutputValues = (state, chartType) => fromData.getAllLatestOutputValues(state.data[chartType]);
export const getAllSensorRanges = (state, chartType) => fromData.getAllSensorRanges(state.data[chartType]);
export const getAllOutputRanges = (state, chartType) => fromData.getAllOutputRanges(state.data[chartType]);
export const getDataPredictor = (state, chartType) => fromData.getPredictor(state.data[chartType]);
export const getDataFromDate = (state, chartType) => fromData.getFromDate(state.data[chartType]);
export const getDataToDate = (state, chartType) => fromData.getToDate(state.data[chartType]);
export const getDataSensors = (state, chartType) => fromData.getSensors(state.data[chartType]);
export const getDataOutputs = (state, chartType) => fromData.getOutputs(state.data[chartType]);

export const getAlarmRanges = state => fromAlarm.getAlarmRanges(state.alarm);
export const getAlarmRangesValid = state => fromAlarm.getAlarmRangesValid(state.alarm);
export const getAlarmLevels = state => fromAlarm.getAlarmLevels(state.alarm);
export const getAlarmModalIsOpen = state => fromAlarm.getModalIsOpen(state.alarm);
export const getAlarmIsSaving = state => fromAlarm.getIsSaving(state.alarm);

export const getPlaybackIsPlaying = state => fromPlayback.getIsPlaying(state.playback);
export const getPlaybackMode = state => fromPlayback.getMode(state.playback);
export const getPlaybackSpeed = state => fromPlayback.getSpeed(state.playback);
export const getPlaybackTime = state => fromPlayback.getTime(state.playback);
export const getPlaybackReviewId = state => fromPlayback.getReviewId(state.playback);
export const getPlaybackReviewTrendId = state => fromPlayback.getReviewTrendId(state.playback);

export const getIsLoading = state => fromLoading.getIsLoading(state.loading);