import moment from 'moment';
import { useState } from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { TimeInput } from 'semantic-ui-calendar-react';
import { Button, Icon, Popup } from 'semantic-ui-react';
import { DATE_FORMAT_NO_TIME, DATE_FORMAT_TIME_ONLY, INVERTED_THEME, PLAYBACK_MODE } from '../../../utils/constants';
import PopupButton from '../../shared/PopupButton';
import styles from './PlaybackButtons.styles';

const messages = defineMessages({
  'dateButton.popupText': {
    id: 'playbackButtons.dateButton.popupText',
    defaultMessage: 'Playback Date'
  },
  'timeButton.popupText': {
    id: 'playbackButtons.timeButton.popupText',
    defaultMessage: 'Playback Time'
  },
  'playButton.popupText': {
    id: 'playbackButtons.playButton.popupText',
    defaultMessage: 'Play'
  },
  'pauseButton.popupText': {
    id: 'playbackButtons.pauseButton.popupText',
    defaultMessage: 'Pause'
  }
});

const DateTimeButtonGroup = injectIntl(({
  momentTime,
  isPlaying,
  mode,
  onChangeTime,
  onClickPause,
  onClickPlay,
  intl: {
    formatMessage,
    locale
  }
}) => {
  const [datePopupOpen, setDatePopupOpen] = useState(false);
  const [timePopupOpen, setTimePopupOpen] = useState(false);
  const [timeHour, setTimeHour] = useState(null);
  const [initialDate, setInitialDate] = useState(null);
  return (
    <Button.Group
      size='small'
      style={styles.dateTimeButtonGroup}>
      <Popup
        open={datePopupOpen}
        onOpen={() => {
          setDatePopupOpen(true);
          setInitialDate(momentTime.clone().local().toDate());
        }}
        onClose={() => {
          setDatePopupOpen(false);
          setInitialDate(null);
        }}
        trigger={(
          <PopupButton
            popupText={formatMessage(messages['dateButton.popupText'])}
            compact
            style={styles.dateButton}>
            <div style={styles.dateTimeTextWrapperDiv}>
              {momentTime.clone().local().format(DATE_FORMAT_NO_TIME)}
            </div>
            <Icon
              name='caret down'
              style={styles.dateTimeCaretIcon} />
          </PopupButton>
        )}
        style={styles.datePopup}
        on='click'>
        <SemanticDatepicker
          inverted={INVERTED_THEME}
          inline
          fluid
          showToday={false}
          clearOnSameDateClick={false}
          date={initialDate}
          value={initialDate}
          maxDate={moment().local().toDate()}
          onChange={(event, { value }) => {
            setDatePopupOpen(false);
            setInitialDate(null);
            const time = momentTime.clone().local();
            const newTime = moment(value, DATE_FORMAT_NO_TIME);
            newTime.set({
              hour: time.get('hour'),
              minute: time.get('minute'),
              second: time.get('second')
            });
            if (newTime.utc() > moment.utc()) {
              return onChangeTime(moment.utc().toISOString());
            };
            return onChangeTime(newTime.utc().toISOString());
          }}
          localization={locale}
          format={DATE_FORMAT_NO_TIME} />
      </Popup>
      <Popup
        open={timePopupOpen}
        onOpen={() => setTimePopupOpen(true)}
        onClose={() => {
          setTimePopupOpen(false);
          setTimeHour(null);
        }}
        trigger={(
          <PopupButton
            popupText={formatMessage(messages['timeButton.popupText'])}
            color='blue'
            compact
            style={styles.timeButton}>
            <div style={styles.dateTimeTextWrapperDiv}>
              {momentTime.clone().local().format(DATE_FORMAT_TIME_ONLY)}
            </div>
            <Icon
              name='caret down'
              style={styles.dateTimeCaretIcon} />
          </PopupButton>
        )}
        style={styles.timePopup}
        on='click'>
        <TimeInput
          inline
          closable
          fluid
          value={timeHour || momentTime.clone().local().format('HH:mm')}
          pickerStyle={styles.timePicker}
          onChange={(event, { value }) => {
            if (!timeHour) {
              return setTimeHour(value);
            };
            setTimePopupOpen(false);
            setTimeHour(null);
            const time = moment(value, 'HH:mm');
            const newTime = momentTime.clone().local();
            newTime.set({
              hour: time.get('hour'),
              minute: time.get('minute'),
              second: time.get('second')
            });
            if (newTime.utc() > moment.utc()) {
              return onChangeTime(moment.utc().toISOString());
            };
            return onChangeTime(newTime.utc().toISOString());
          }}
          localization={locale} />
      </Popup>
      {mode !== PLAYBACK_MODE.REVIEW && (
        <PopupButton
          popupText={isPlaying ? formatMessage(messages['pauseButton.popupText']) : formatMessage(messages['playButton.popupText'])}
          color={isPlaying ? 'blue' : 'green'}
          icon={isPlaying ? 'pause' : 'play'}
          onClick={isPlaying ? onClickPause : onClickPlay} />
      )}
    </Button.Group>
  );
});

DateTimeButtonGroup.displayName = 'DateTimeButtonGroup';

export default DateTimeButtonGroup;