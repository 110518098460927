import { DELETE_BY_ID, DELETE_BY_ID_FULFILLED, DELETE_BY_QUERY, SET_IS_DELETING, DELETE_ERROR } from '../../actions/entity';

export default (state = false, action) => {
  switch (action.type) {
    case DELETE_BY_ID:
    case DELETE_BY_QUERY:
      return true;
    case DELETE_BY_ID_FULFILLED:
    case SET_IS_DELETING:
    case DELETE_ERROR:
      return false;
    default:
      return state;
  }
};

export const getIsDeleting = state => state;