import id, * as fromId from './id';
import open, * as fromOpen from './open';
import until, * as fromUntil from './until';
import isSaving, * as fromIsSaving from './isSaving';
import { combineReducers } from 'redux';

const muteModalReducer = combineReducers({
  id,
  open,
  until,
  isSaving
});

export default modal => (state, action) => {
  if (action.payload && action.payload.modal && action.payload.modal !== modal) {
    return state;
  }

  return muteModalReducer(state, action);
};

export const getId = state => fromId.getId(state.id);
export const getOpen = state => fromOpen.getOpen(state.open);
export const getUntil = state => fromUntil.getUntil(state.until);
export const getIsSaving = state => fromIsSaving.getIsSaving(state.isSaving);