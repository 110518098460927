import _ from 'lodash';
import createCachedSelector from 're-reselect';
import * as fromState from '../../../reducers';
import { getFunctionSensorIds } from '../getFunctionSensorIds';

const getAllSensors = state => fromState.getAll(state, 'sensor');

export const getFunctionSensors = createCachedSelector(
  [getFunctionSensorIds, getAllSensors],
  (ids, allSensors) => _.chain(ids).map(id => [id, allSensors[id]]).fromPairs().value()
)(
  (state, chartType) => chartType
);