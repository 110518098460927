import _ from 'lodash';
import moment from 'moment';
import queryString from 'query-string';
import { defineMessages } from 'react-intl';
import { Label } from 'semantic-ui-react';
import uuid from 'uuid/v1';
import EntityText from 'components/entity/EntityText';
import { CHART_VIEW, DATE_FORMAT, ENTITY_FORM_ELEMENT_TYPE, ENTITY_TYPE, PAGE_URL, PLAYBACK_MODE, SELECT_CONTEXT_LIST } from 'utils/constants';
import { getTrendQuickRange, getTrendToDate, toPath, unescapeRegex } from 'utils/helpers';
import selectContextListsSelector from './selectContextListsSelector';
import { EntityConfig, Trend } from 'types';

const messages = defineMessages({
  'title': {
    id: 'trend.title',
    defaultMessage: 'Trends'
  },
  'description': {
    id: 'trend.description',
    defaultMessage: 'Trends are labelled data'
  },
  'columns.name.title': {
    id: 'trend.columns.name.title',
    defaultMessage: 'Name'
  },
  'columns.from.title': {
    id: 'trend.columns.from.title',
    defaultMessage: 'From'
  },
  'columns.to.title': {
    id: 'trend.columns.to.title',
    defaultMessage: 'To'
  },
  'columns.unitId.title': {
    id: 'trend.columns.unitId.title',
    defaultMessage: 'Unit'
  },
  'columns.labels.title': {
    id: 'trend.columns.labels.title',
    defaultMessage: 'Labels'
  },
  'form.name.label': {
    id: 'trend.form.name.label',
    defaultMessage: 'Name'
  },
  'form.name.placeholder': {
    id: 'trend.form.name.placeholder',
    defaultMessage: 'Name'
  },
  'form.description.label': {
    id: 'trend.form.description.label',
    defaultMessage: 'Description'
  },
  'form.description.placeholder': {
    id: 'trend.form.description.placeholder',
    defaultMessage: 'Description'
  },
  'form.unitId.label': {
    id: 'trend.form.unitId.label',
    defaultMessage: 'Unit'
  },
  'form.unitId.placeholder': {
    id: 'trend.form.unitId.placeholder',
    defaultMessage: 'Unit'
  },
  'form.from.label': {
    id: 'trend.form.from.label',
    defaultMessage: 'From'
  },
  'form.from.placeholder': {
    id: 'trend.form.from.placeholder',
    defaultMessage: 'From'
  },
  'form.to.label': {
    id: 'trend.form.to.label',
    defaultMessage: 'To'
  },
  'form.to.placeholder': {
    id: 'trend.form.to.placeholder',
    defaultMessage: 'To'
  },
  'form.labels.label': {
    id: 'trend.form.labels.label',
    defaultMessage: 'Labels'
  },
  'form.labels.placeholder': {
    id: 'trend.form.labels.placeholder',
    defaultMessage: 'Labels'
  },
  'type.label.name': {
    id: 'trend.type.label.name',
    defaultMessage: 'Label'
  },
  'type.investigate.options.instructions.label': {
    id: 'trend.type.investigate.options.instructions.label',
    defaultMessage: 'Instructions'
  },
  'type.investigate.options.instructions.placeholder': {
    id: 'trend.type.investigate.options.instructions.placeholder',
    defaultMessage: 'Instructions'
  },
  'type.investigate.name': {
    id: 'trend.type.investigate.name',
    defaultMessage: 'Investigate'
  },
  'searchAddEntityType.review.button.text': {
    id: 'trend.searchAddEntityType.review.button.text',
    defaultMessage: 'Start Review'
  }
});

const conditionToFilter = condition => {
  switch (condition.type) {
    case 'greaterThan':
      return {
        [`Settings.${condition.name}`]: {
          $gt: parseFloat(_.get(condition, 'settings.value'))
        }
      };
    case 'lessThan':
      return {
        [`Settings.${condition.name}`]: {
          $lt: parseFloat(_.get(condition, 'settings.value'))
        }
      };
    case 'equalsFloat':
      return {
        [`Settings.${condition.name}`]: {
          $eq: parseFloat(_.get(condition, 'settings.value'))
        }
      };
    case 'greaterThanDate':
      return {
        [`Settings.${condition.name}`]: {
          $gte: _.get(condition, 'settings.value') || null
        }
      };
    case 'lessThanDate':
      return {
        [`Settings.${condition.name}`]: {
          $lte: _.get(condition, 'settings.value') || null
        }
      };
    case 'equals':
      return {
        $or: [{
          [`Settings.${condition.name}`]: {
            $eq: _.get(condition, 'settings.value') || null
          }
        }]
      };
    case 'containsCaseInsensitive':
      return {
        $or: [{
          [`Settings.${condition.name}`]: {
            $regex: _.escapeRegExp(_.get(condition, 'settings.value') || ''),
            $options: 'i'
          }
        }]
      };
    case 'containsCaseSensitive':
      return {
        $or: [{
          [`Settings.${condition.name}`]: {
            $regex: _.escapeRegExp(_.get(condition, 'settings.value') || '')
          }
        }]
      };
    case 'inString':
      return {
        $or: [{
          [`Settings.${condition.name}`]: {
            $in: _.get(condition, 'settings.value') || []
          }
        }]
      };
    case 'inState':
      return {
        $and: [{
          [`Settings.${condition.name}`]: {
            $eq: _.get(condition, 'settings.value.state') || null
          }
        }]
      };
    case 'inEnumeration':
      return {
        $and: [{
          [`Settings.${condition.name}.state`]: {
            $in: _.get(condition, 'settings.value.state') || []
          }
        }]
      };
    default:
      return {
        [`Settings.${condition.name}`]: {
          $eq: _.get(condition, 'settings.value') || null
        }
      };
  };
};

const filterToCondition = filter => {
  let operator = _.findKey(filter);
  let name, nestedOperator;
  switch (operator) {
    case '$or':
      name = _.findKey(filter[operator][0]);
      nestedOperator = _.findKey(filter[operator][0][name], _.constant(true));
      switch (nestedOperator) {
        case '$eq':
          return {
            name: name.substring('Settings.'.length),
            type: 'equals',
            settings: {
              value: filter[operator][0][name][nestedOperator]
            }
          };
        case '$in':
          return {
            name: name.substring('Settings.'.length),
            type: 'inString',
            settings: {
              value: filter[operator][0][name][nestedOperator]
            }
          };
        case '$regex':
          return {
            name: name.substring('Settings.'.length),
            type: filter[operator][0][name].$options === 'i'
              ? 'containsCaseInsensitive'
              : 'containsCaseSensitive',
            settings: {
              value: unescapeRegex(filter[operator][0][name][nestedOperator])
            }
          };
        default:
          throw new Error(`Unknown string operator ${nestedOperator}`);
      };
    case '$and':
      name = _.findKey(filter[operator][0]);
      nestedOperator = _.findKey(filter[operator][0][name]);
      switch (nestedOperator) {
        case '$eq':
          return {
            name: name.substring('Settings.'.length),
            type: 'inState',
            settings: {
              value: {
                state: filter[operator][0][name][nestedOperator]
              }
            }
          };
        case '$in':
          return {
            name: name.split('.')[1],
            type: 'inEnumeration',
            settings: {
              value: {
                state: filter[operator][0][name][nestedOperator]
              }
            }
          };
        default:
          throw new Error(`Unknown enumeration operator ${nestedOperator}`);
      };
    default:
      name = operator;
      operator = _.findKey(filter[name]);
      switch (operator) {
        case '$gt':
          return {
            name: name.substring('Settings.'.length),
            type: 'greaterThan',
            settings: {
              value: filter[name][operator]
            }
          };
        case '$lt':
          return {
            name: name.substring('Settings.'.length),
            type: 'lessThan',
            settings: {
              value: filter[name][operator]
            }
          };
        case '$gte':
          return {
            name: name.substring('Settings.'.length),
            type: 'greaterThanDate',
            settings: {
              value: filter[name][operator]
            }
          };
        case '$lte':
          return {
            name: name.substring('Settings.'.length),
            type: 'lessThanDate',
            settings: {
              value: filter[name][operator]
            }
          };
        case '$eq':
          return {
            name: name.substring('Settings.'.length),
            type: 'equalsFloat',
            settings: {
              value: filter[name][operator]
            }
          };
        default:
          return [];
      };
  };
};

const config: EntityConfig<Trend> = {
  apiPath: 'trend',
  pageUrl: 'trends',
  icon: 'chart line',
  messages,
  labels: true,
  conditions: true,
  navigateOnClick: true,
  selectContextListsSelector,
  import: true,
  searchAddEntityTypes: [
    {
      entityType: ENTITY_TYPE.REVIEW,
      message: messages['searchAddEntityType.review.button.text']
    }
  ],
  search: [{
    name: 'name',
    type: ENTITY_FORM_ELEMENT_TYPE.INPUT
  }, {
    name: 'description',
    type: ENTITY_FORM_ELEMENT_TYPE.INPUT
  }, {
    name: 'unitId',
    type: ENTITY_FORM_ELEMENT_TYPE.SEARCH,
    searchEntityType: ENTITY_TYPE.UNIT,
    transformResult: unit => ({
      key: unit.id,
      text: unit.name,
      value: unit.id
    })
  }, {
    name: 'range',
    type: ENTITY_FORM_ELEMENT_TYPE.DATE_RANGE
  }, {
    name: 'labels',
    type: ENTITY_FORM_ELEMENT_TYPE.LABELS,
    multiple: true
  },
  {
    type: ENTITY_FORM_ELEMENT_TYPE.TYPES,
    hideSettings: true
  }, {
    type: ENTITY_FORM_ELEMENT_TYPE.CONDITIONS,
    filter: (values, conditionTypes, selectContextLists) =>
      !!values.type && _.some(conditionTypes) && _.some(selectContextLists.attributes),
    conditionTypeFilter: '$.valueType = name.valueType',
    enumerationPath: 'name.enumeration',
    conditionForm: [
      {
        name: 'name',
        type: ENTITY_FORM_ELEMENT_TYPE.SELECT,
        selectContextListName: SELECT_CONTEXT_LIST.ATTRIBUTES
      }
    ],
    getErrorPath: index => `conditions.list[${index}].condition`,
    errorPathPrefix: 'conditions'
  }],
  createSearchQuery: ({
    name,
    description,
    unitId,
    range,
    labels,
    type,
    conditions
  }, {
    by,
    direction
  }) => {
    const searchFilterName = name
      ? {
        $regex: _.escapeRegExp(name),
        $options: 'i'
      }
      : undefined;

    const searchFilterDescription = description
      ? {
        $regex: _.escapeRegExp(description),
        $options: 'i'
      }
      : undefined;

    const searchFilterUnitId = unitId
      ? {
        $eq: unitId
      }
      : undefined;

    const searchFilterTo = _.get(range, 'from')
      ? {
        $gte: moment.utc(_.get(range, 'from'), DATE_FORMAT).toISOString()
      }
      : undefined;

    const searchFilterFrom = _.get(range, 'to')
      ? {
        $lte: moment.utc(_.get(range, 'to'), DATE_FORMAT).toISOString()
      }
      : undefined;

    const searchFilterLabels = _.some(_.get(labels, 'value'))
      ? {
        $all: _.get(labels, 'value')
      }
      : undefined;

    const searchFilterType = type
      ? {
        $eq: type
      }
      : undefined;

    const searchFilter = {
      Name: searchFilterName,
      Description: searchFilterDescription,
      UnitId: searchFilterUnitId,
      To: searchFilterTo,
      From: searchFilterFrom,
      Labels: searchFilterLabels,
      Type: searchFilterType
    };

    const conditionFilters = _.map(conditions.list, conditionToFilter);
    const sortBy = {
      name: 'Name',
      from: 'From',
      to: 'To'
    }[by];
    const sortDirection = {
      descending: -1,
      ascending: 1
    }[direction];
    return {
      sort: sortBy && sortDirection
        ? {
          [sortBy]: sortDirection
        }
        : {},
      filter: {
        $and: [
          searchFilter,
          ..._.some(conditions.list)
            ? [
              {
                [conditions.filter === 'Any'
                  ? '$or'
                  : '$and']: conditionFilters
              }
            ]
            : []
        ]
      }
    };
  },
  parseSearchFilter: ({
    $and: [searchFilter, conditionFilter]
  }) => {
    const values = _.mapValues({
      name: 'Name.$regex',
      description: 'Description.$regex',
      type: 'Type.$eq',
      unitId: 'UnitId.$eq',
      to: 'From.$lte',
      from: 'To.$gte',
      labels: 'Labels.$all'
    }, path => _.get(searchFilter, path));
    const list = conditionFilter
      ? filterToCondition(_.chain(conditionFilter)
        .values()
        .first()
        .value())
      : [];
    return {
      name: unescapeRegex(values.name || ''),
      description: unescapeRegex(values.description || ''),
      type: values.type,
      unitId: values.unitId,
      range: {
        to: values.to && moment.utc(values.to).format(DATE_FORMAT),
        from: values.from && moment.utc(values.from).format(DATE_FORMAT)
      },
      labels: {
        value: values.labels,
        labels: []
      },
      conditions: {
        filter: _.findKey(conditionFilter) === '$or'
          ? 'Any'
          : 'All',
        list
      }
    };
  },
  parseSearchSort: sort => {
    const key = _.findKey(sort);
    if (_.isUndefined(key)) {
      return {};
    }

    return {
      by: {
        Name: 'name',
        From: 'from',
        To: 'to'
      }[key] || null,
      direction: {
        [-1]: 'descending',
        1: 'ascending'
      }[sort[key]] || null
    };
  },
  itemUrl: (entity, unitPaths) => {
    if (entity.predictor === null) {
      return null;
    }

    return {
      pathname: toPath(PAGE_URL.PREDICTOR, {
        unitPath: unitPaths[entity.unitId],
        groupId: entity.predictor
      }),
      search: queryString.stringify({
        time: getTrendToDate(entity),
        mode: PLAYBACK_MODE.HISTORICAL,
        window: getTrendQuickRange(entity),
        view: CHART_VIEW.TIME_SERIES
      })
    };
  },
  columns: [
    {
      name: 'name',
      width: 3,
      render: ({ name }) => name,
      sortable: true
    },
    {
      name: 'unitId',
      width: 3,
      render: ({ unitId }) => (
        <EntityText
          id={unitId}
          entityType={ENTITY_TYPE.UNIT} />
      )
    },
    {
      name: 'from',
      width: 3,
      render: ({ from }) => moment.utc(from).local().format(DATE_FORMAT),
      sortable: true
    },
    {
      name: 'to',
      width: 3,
      render: ({ to }) => moment.utc(to).local().format(DATE_FORMAT),
      sortable: true
    },
    {
      name: 'labels',
      width: 3,
      render: ({ labels }) => (
        <Label.Group size='small'>
          {_.map(labels, label => (
            <Label key={label}>{label}</Label>
          ))}
        </Label.Group>
      )
    }
  ],
  form: [{
    name: 'name',
    type: ENTITY_FORM_ELEMENT_TYPE.INPUT,
    errorPath: 'id'
  }, {
    name: 'description',
    type: ENTITY_FORM_ELEMENT_TYPE.TEXT_AREA
  }, {
    name: 'unitId',
    type: ENTITY_FORM_ELEMENT_TYPE.SEARCH,
    searchEntityType: ENTITY_TYPE.UNIT,
    transformResult: unit => ({
      key: unit.id,
      text: unit.name,
      description: unit.description,
      value: unit.id
    })
  }, {
    name: 'range',
    type: ENTITY_FORM_ELEMENT_TYPE.DATE_RANGE,
    errorPaths: ['from', 'to'],
    fromErrorPath: 'from',
    toErrorPath: 'to'
  }, {
    name: 'labels',
    type: ENTITY_FORM_ELEMENT_TYPE.LABELS,
    allowAdditions: true,
    multiple: true
  }, {
    type: ENTITY_FORM_ELEMENT_TYPE.TYPES
  }],
  createEntity: values => ({
    id: uuid(),
    name: values.name,
    type: values.type,
    settings: values.settings,
    description: values.description,
    to: moment.utc(_.get(values, 'range.to'), DATE_FORMAT).toISOString(),
    from: moment.utc(_.get(values, 'range.from'), DATE_FORMAT).toISOString(),
    labels: _.get(values, 'labels.value') || [],
    unitId: values.unitId
  }),
  populateForm: entity => ({
    name: entity.name,
    type: entity.type,
    settings: entity.settings,
    description: entity.description,
    unitId: entity.unitId,
    range: {
      to: moment.utc(entity.to).format(DATE_FORMAT),
      from: moment.utc(entity.from).format(DATE_FORMAT)
    },
    labels: {
      value: entity.labels,
      labels: []
    }
  })
};

export default config;