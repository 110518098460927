import _ from 'lodash';
import createCachedSelector from 're-reselect';
import * as fromState from '../../../state/reducers';
import { getInputAlarmRanges, getInputDetails, getInputs, getInputSensors, getInputEnumerations } from '../../../state/selectors/chart';
import { SELECTION_TYPE } from '../../../utils/constants';

export default createCachedSelector(
  [
    getInputs,
    _.partialRight(fromState.getSelected, SELECTION_TYPE.INPUT),
    getInputSensors,
    getInputAlarmRanges,
    getInputDetails,
    getInputEnumerations
  ],
  (
    inputs,
    selectedInputs,
    inputSensors,
    inputAlarmRanges,
    inputDetails,
    inputEnumerations
  ) => _.chain(inputs)
    .filter(id => inputSensors[id])
    .map(id => ({
      id,
      name: id,
      description: inputSensors[id].name,
      ranges: inputAlarmRanges[id],
      unitsOfMeasurement: inputSensors[id].unitsOfMeasurement,
      valueType: inputSensors[id].valueType,
      selected: _.includes(selectedInputs, id),
      min: inputDetails[id].min,
      max: inputDetails[id].max,
      value: inputEnumerations[id]
        ? inputEnumerations[id].states[inputDetails[id].value]
        : inputDetails[id].value,
      noData: inputDetails[id].noData,
      severity: inputDetails[id].severity,
      scale: 1
    }))
    .value()
)(
  (state, chartType) => chartType
);