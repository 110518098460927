import _ from 'lodash';
import createCachedSelector from 're-reselect';
import * as fromState from '../../../state/reducers';
import * as chartSelectors from '../../../state/selectors/chart';
import { SELECTION_TYPE, ENTITY_PROPERTY } from '../../../utils/constants';

export default createCachedSelector(
  [
    chartSelectors.getOutputs,
    _.partialRight(fromState.getSelected, SELECTION_TYPE.OUTPUT),
    chartSelectors.getOutputOffsetScale,
    chartSelectors.getOutputAlarmRanges,
    chartSelectors.getColours,
    chartSelectors.getOutputProperties
  ],
  (outputs, selectedOutputs, outputOffsetScale, outputAlarmRanges, colours, outputProperties) => {
    return _.chain(outputs)
      .filter(output => _.includes(selectedOutputs, output))
      .map(output => ({
        id: output,
        className: _.replace(output, / /g, '-'),
        offset: _.get(outputOffsetScale[output], 'offset'),
        scale: _.get(outputOffsetScale[output], 'scale'),
        ranges: _.filter(outputAlarmRanges[output], alarmRange => alarmRange.min !== alarmRange.max),
        colour: colours[output],
        description: _.get(outputProperties[output], ENTITY_PROPERTY.MODEL.OUTPUT.NAME) || output,
        strokeWidthMultiplier: 1.5,
        valueScale: _.get(outputProperties[output], ENTITY_PROPERTY.MODEL.OUTPUT.UNITS_OF_MEASUREMENT) === '%' ? 100 : 1,
        unitsOfMeasurement: _.get(outputProperties[output], ENTITY_PROPERTY.MODEL.OUTPUT.UNITS_OF_MEASUREMENT),
        valueType: _.get(outputProperties[output], ENTITY_PROPERTY.SENSOR.VALUE_TYPE)
      }))
      .value();
  }
)(
  (state, chartType) => chartType
);