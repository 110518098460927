import _ from 'lodash';
import { createSelector } from 'reselect';
import * as fromState from '../../../state/reducers';
import { ENTITY_TYPE } from '../../../utils/constants';

export default createSelector(
  state => fromState.getAll(state, ENTITY_TYPE.AUTH_GROUP),
  authGroups => _.map(authGroups, authGroup => ({
    key: authGroup.id,
    text: authGroup.name,
    value: authGroup.id
  }))
);