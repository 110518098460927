import { TOGGLE_MODAL, ADD_ERROR, EDIT_ERROR, SET_FORM_VALUES, FORM_SEARCH_CHANGED, FORM_VALUE_CHANGED, FORM_SETTING_CHANGED, UPDATE_CONDITION, REMOVE_CONDITION, FORM_COMPLEX_SETTING_CHANGED, FORM_SETTING_SEARCH_CHANGED, CLEAR_ADD_ERROR } from '../../actions/entity';
import _ from 'lodash';
import { INPUT_TYPE } from '../../../utils/constants';

export default (state = [], action) => {
  switch (action.type) {
    case EDIT_ERROR:
    case ADD_ERROR:
      return _.keys(_.get(action.payload.err, 'response.errors'));
    case CLEAR_ADD_ERROR:
    case SET_FORM_VALUES:
    case TOGGLE_MODAL:
      return [];
    case FORM_SEARCH_CHANGED:
    case FORM_SETTING_SEARCH_CHANGED:
    case FORM_VALUE_CHANGED:
      return _.without(state, action.payload.errorPath || action.payload.key);
    case FORM_SETTING_CHANGED:
      return _.without(state, `settings.${action.payload.key}`);
    case FORM_COMPLEX_SETTING_CHANGED:
      switch (action.inputType) {
        case INPUT_TYPE.CONDITIONS:
          switch (action.payload.action.type) {
            case UPDATE_CONDITION:
            case REMOVE_CONDITION:
              return _.filter(state, f => !_.startsWith(f, action.payload.action.payload.errorPathPrefix));
            default:
              return state;
          };
        default:
          return state;
      };
    case UPDATE_CONDITION:
    case REMOVE_CONDITION:
      return _.filter(state, f => !_.startsWith(f, action.payload.errorPathPrefix));
    default:
      return state;
  }
};

export const getErrorFields = state => state;