import { ofType } from 'redux-observable';
import { map, withLatestFrom, filter } from 'rxjs/operators';
import { ENTITY_MODAL } from '../../../utils/constants';
import { DELETE_BY_ID_FULFILLED, toggleModal } from '../../actions/entity';
import * as fromState from '../../reducers';

export default (action$, state$) => action$.pipe(
  ofType(DELETE_BY_ID_FULFILLED),
  withLatestFrom(state$),
  filter(([action, state]) => fromState.getModalOpen(state, action.entityType, ENTITY_MODAL.DELETE)),
  map(([action]) => toggleModal(action.entityType, ENTITY_MODAL.DELETE))
);