import { useState, useRef, useEffect, useCallback, Fragment } from 'react';
import { DropdownProps, Form, Icon } from 'semantic-ui-react';
import _ from 'lodash';
import { defineMessages, injectIntl } from 'react-intl';

const messages = defineMessages({
  'addAll.text': {
    id: 'scrollAwareFormSelect.addAll.text',
    defaultMessage: 'Add All'
  }
});

const DROPDOWN_ITEM_PROPS = ['description', 'disabled', 'flag', 'icon', 'label', 'selected', 'text', 'value'];

const getUpward = (element, scrollElement) => {
  const elementDimensions = element.getBoundingClientRect();
  const scrollElementDimensions = scrollElement.getBoundingClientRect();
  return elementDimensions.top - scrollElementDimensions.top > scrollElementDimensions.height / 2;
};

const ScrollAwareFormSelect = ({
  scrollContextRef,
  options,
  hideAddAll,
  intl: {
    formatMessage
  },
  ...props
}) => {
  const [upward, setUpward] = useState(props.upward);
  const ref = useRef(null);
  const scrollElement = scrollContextRef && scrollContextRef.current;
  const setRef = useCallback(node => {
    if (node && scrollElement) {
      setUpward(getUpward(node, scrollElement));
    };
    ref.current = node;
  }, [scrollElement]);
  const element = ref.current;
  useEffect(() => {
    if (!element) {
      return;
    };
    if (!scrollElement) {
      return;
    };
    const handleScroll = () => setUpward(getUpward(element, scrollElement));
    scrollElement.addEventListener('scroll', handleScroll);
    return () => scrollElement.removeEventListener('scroll', handleScroll);
  }, [element, scrollElement]);
  const selectedOption = _.find(options, ['value', props.value]);
  return (
    <Fragment>
      <div ref={setRef} />
      <Form.Select
        {...props}
        text={_.get(selectedOption, 'icon')
          ? (
            <Fragment>
              <Icon
                {..._.isObject(selectedOption.icon)
                  ? selectedOption.icon
                  : { name: selectedOption.icon }} />
              {selectedOption.text}
            </Fragment>
          )
          : _.get(selectedOption, 'text')}
        onChange={props.onChange && ((event, data: DropdownProps) => {
          const addAllSelected = _.isArray(data.value) && _.includes(data.value, 'addAll');

          if (!props.multiple || hideAddAll || !addAllSelected) {
            return props.onChange(event, data);
          };
          const modifiedData = {
            ...data,
            value: _.map(options, 'value')
          };
          return props.onChange(event, modifiedData);
        })}
        upward={upward}
        options={[
          ...props.multiple && !hideAddAll && _.size(props.value) < _.size(options)
            ? [
              {
                key: 'addAll',
                text: formatMessage(messages['addAll.text']),
                value: 'addAll',
                icon: 'plus'
              }
            ]
            : [],
          ..._.map(options, option => _.pick(option, DROPDOWN_ITEM_PROPS))
        ]} />
    </Fragment>
  );
};

ScrollAwareFormSelect.displayName = 'ScrollAwareFormSelect';

export default injectIntl(ScrollAwareFormSelect);