import { SET_COPIED_TEXT } from '../../../actions/chart';

export default (state = null, action) => {
  switch (action.type) {
    case SET_COPIED_TEXT:
      return action.payload.text;
    default:
      return state;
  }
};

export const getCopiedText = state => state;