import _ from 'lodash';
import moment from 'moment';
import { of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { gatewayTimeout, notFound, serviceUnavailable, unauthorised } from '../../state/actions/auth';
import { map } from 'rxjs/operators';
import { DATE_FORMAT } from '../constants';
import queryString from 'query-string';

export const URL = process.env.REACT_APP_API_URL || '/';
export const ROOT_URL = `${URL}api/`;
export const AUTH_URL = `${URL}auth/`;

const getJSON = (url, headers = {}) => {
  return ajax.getJSON(url, {
    ...headers,
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache',
    'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
  });
};

export const onError = (err, caught) => {
  if (_.includes([401, 403], err.status)) {
    return of(unauthorised());
  }

  if (caught.attempt < 3) {
    return {
      ...caught,
      attempt: caught.attempt + 1
    };
  }

  if (err.status === 503) {
    return of(serviceUnavailable());
  }

  if (err.status === 504) {
    return of(gatewayTimeout());
  }

  if (err.status === 404) {
    return of(notFound());
  }

  return err;
};

export const search = ({
  controller,
  searchText,
  unit,
  token
}) => getJSON(
  `${ROOT_URL}${controller}/search?searchText=${searchText}${unit ? `&unit=${unit}` : ''}`,
  { 'Authorization': `Bearer ${token}` }
);

export const create = ({
  controller,
  entity,
  token
}) => ajax.post(
  `${ROOT_URL}${controller}/`,
  entity,
  {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`
  }
);

export const createUnitTypeAttributeWithInstances = ({
  attribute,
  editedInstances,
  token
}) => ajax.post(
  `${ROOT_URL}unitTypeAttribute/addWithInstances`,
  {
    attribute: attribute,
    editedInstances: editedInstances
  },
  {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`
  }
);

export const createUnitTypeAttributeWithTemplateMapping = ({
  attribute,
  template,
  token
}) => ajax.post(
  `${ROOT_URL}unitTypeAttribute/addWithTemplateMapping`,
  {
    attribute: attribute,
    template: template
  },
  {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`
  }
);

export const createUnitTypeParameterWithInstances = ({
  parameter,
  editedInstances,
  token
}) => ajax.post(
  `${ROOT_URL}unitTypeParameter/addWithInstances`,
  {
    parameter: parameter,
    editedInstances: editedInstances
  },
  {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`
  }
);

export const get = ({
  controller,
  id,
  token
}) => getJSON(
  `${ROOT_URL}${controller}/${id}`,
  { 'Authorization': `Bearer ${token}` }
);

export const types = ({
  controller,
  prefix,
  parentId,
  token
}) => getJSON(
  `${ROOT_URL}${controller}/${prefix ? `${prefix}/${parentId}/` : ''}types`,
  { 'Authorization': `Bearer ${token}` }
);

export const page = ({
  controller,
  prefix,
  parentId,
  page,
  unit,
  token
}) => {
  let url = `${ROOT_URL}${controller}/${prefix ? `${prefix}/${parentId}/` : ''}page/${page}`;
  if (unit) {
    url = `${url}?unit=${unit}`;
  }
  return getJSON(
    url,
    { 'Authorization': `Bearer ${token}` }
  );
};

export const getByPredictor = ({
  controller,
  predictor,
  token
}) => getJSON(
  `${ROOT_URL}${controller}/predictor/${predictor}`,
  { 'Authorization': `Bearer ${token}` }
);

export const deleteById = ({
  controller,
  id,
  token
}) => ajax.delete(
  `${ROOT_URL}${controller}/${id}`,
  { 'Authorization': `Bearer ${token}` }
);

export const getAll = ({
  controller,
  token
}) => ajax.getJSON(
  `${ROOT_URL}${controller}/all`,
  { 'Authorization': `Bearer ${token}` }
);

export const edit = ({
  controller,
  entity,
  token
}) => ajax.put(
  `${ROOT_URL}${controller}/`,
  entity,
  {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`
  }
);

export const getMultiple = ({
  controller,
  ids,
  token
}) => ajax.post(
  `${ROOT_URL}${controller}/multiple`,
  ids,
  {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`
  }
).pipe(
  map(({ response }) => response)
);

export const getLatest = ({
  predictorIds,
  fromDate,
  toDate,
  token
}) => {
  return _.some(predictorIds)
    ? ajax.post(
      `${ROOT_URL}sensorvalue/latest?from=${fromDate}&to=${toDate}`,
      predictorIds,
      {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    ).pipe(
      map(({ response }) => response)
    )
    : of({});
};

export const getSensorsLatest = ({
  sensorIds,
  fromDate,
  toDate,
  token
}) => {
  return _.some(sensorIds)
    ? ajax.post(
      `${ROOT_URL}sensorvalue/sensors/latest?from=${fromDate}&to=${toDate}`,
      sensorIds,
      {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    ).pipe(
      map(({ response }) => response)
    )
    : of({});
};

export const getWindow = ({
  predictor,
  fromDate,
  toDate,
  sensors,
  outputs,
  token
}) => {
  const query = {
    from: moment.utc(fromDate, DATE_FORMAT).toISOString(),
    to: moment.utc(toDate, DATE_FORMAT).toISOString(),
    sensors: undefined,
    outputs: undefined
  };
  if (_.some(sensors)) {
    query.sensors = sensors;
  };
  if (_.some(outputs)) {
    query.outputs = outputs;
  };
  return ajax.getJSON(
    `${ROOT_URL}sensorvalue/window/${predictor}?${queryString.stringify(query)}`,
    { 'Authorization': `Bearer ${token}` }
  );
};

export const getSensorsWindow = ({
  ids,
  fromDate,
  toDate,
  token
}) => ajax.getJSON(
  `${ROOT_URL}sensorvalue/sensors/window?from=${moment.utc(fromDate, DATE_FORMAT).toISOString()}&to=${moment.utc(toDate, DATE_FORMAT).toISOString()}&${_.chain(ids).map(id => `ids=${id}`).join('&').value()}`,
  { 'Authorization': `Bearer ${token}` }
);

export const getLabels = ({
  controller,
  token
}) => ajax.getJSON(
  `${ROOT_URL}${controller}/labels`,
  { 'Authorization': `Bearer ${token}` }
);

export const getConditionTypes = ({
  controller,
  token
}) => ajax.getJSON(
  `${ROOT_URL}${controller}/conditions`,
  { 'Authorization': `Bearer ${token}` }
);

export const getActions = ({
  controller,
  id,
  token
}) => ajax.getJSON(
  `${ROOT_URL}${controller}/${id}/actions`,
  { 'Authorization': `Bearer ${token}` }
);

interface QueryProps {
  controller: string;
  prefix?: string;
  parentId?: string;
  page: number;
  pageSize: number;
  query: any;
  token: string;
}
export const query = ({
  controller,
  prefix,
  parentId,
  page,
  pageSize,
  query,
  token
}: QueryProps) => ajax.post(
  `${ROOT_URL}${controller}/${prefix ? `${prefix}/${parentId}/` : ''}query?page=${page}${pageSize ? `&pageSize=${pageSize}` : ''}`,
  {
    sort: {},
    filter: {},
    ...query
  },
  {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`
  }
).pipe(
  map(({ response }) => response)
);

export const deleteByQuery = ({
  controller,
  prefix,
  parentId,
  query,
  token
}) => ajax.post(
  `${ROOT_URL}${controller}/${prefix ? `${prefix}/${parentId}/` : ''}deleteByQuery`,
  {
    sort: {},
    filter: {},
    ...query
  },
  {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`
  }
).pipe(
  map(({ response }) => response)
);

export const importFile = ({
  controller,
  apiPrefix,
  parentId,
  file,
  token
}) => {
  const formData = new FormData();
  formData.append('file', file);
  return ajax.post(
    apiPrefix ? `${ROOT_URL}${controller}/${apiPrefix}/${parentId}/import` : `${ROOT_URL}${controller}/import`,
    formData,
    {
      'Authorization': `Bearer ${token}`,
      'Cache-Control': 'no-cache'
    }
  );
};

export const importUnitTypeInstancesWithContext = ({
  file,
  unitTypeId,
  token
}) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('unitTypeId', unitTypeId);
  return ajax.post(
    `${ROOT_URL}unitTypeInstance/importWithContext`,
    formData,
    {
      'Authorization': `Bearer ${token}`,
      'Cache-Control': 'no-cache'
    }
  );
};

export const getImportHeaders = ({
  controller,
  parentId,
  apiPrefix,
  token
}) => ajax.getJSON(
  apiPrefix ? `${ROOT_URL}${controller}/${apiPrefix}/${parentId}/importHeaders` : `${ROOT_URL}${controller}/importHeaders`,
  {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`
  }
);

export const getUnitTypeInstanceImportHeadersWithContext = ({
  unitTypeId,
  token
}) => ajax.getJSON(
  `${ROOT_URL}unitTypeInstance/importHeadersWithContext?${queryString.stringify({ unitTypeId })}`,
  {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`
  }
);

export const login = details => ajax.post(
  `${AUTH_URL}login`,
  details,
  { 'Content-Type': 'application/json' }
);

export const register = details => ajax.post(
  `${AUTH_URL}register`,
  details,
  { 'Content-Type': 'application/json' }
);

export const forgot = details => ajax.post(
  `${AUTH_URL}forgot`,
  details,
  { 'Content-Type': 'application/json' }
);

export const change = (details, token) => ajax.post(
  `${AUTH_URL}change`,
  details,
  {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`
  }
);

export const join = (details, token) => ajax.post(
  `${AUTH_URL}join`,
  details,
  {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`
  }
);

export const confirm = (details, token) => ajax.post(
  `${AUTH_URL}confirm`,
  details,
  {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`
  }
);

export const resend = details => ajax.post(
  `${AUTH_URL}resend`,
  details,
  { 'Content-Type': 'application/json' }
);