import _ from 'lodash';
import { Fragment } from 'react';
import { Route } from 'react-router-dom';
import { Breadcrumb, Icon } from 'semantic-ui-react';
import BreadcrumbsItem from './BreadcrumbsItem';
import { matchesPath, toPath, getPathParams } from '../../utils/helpers';
import { PAGE_URL, ENTITY_PROPERTY, ENTITY_TYPE } from '../../utils/constants';
import entityConfig from '../../utils/entities';
import EntityText from '../entity/EntityText';
import QuerySyncLink from '../../containers/navigation/QuerySyncLink';

const getUnitPaths = (units, unitPath) => {
  const dictionary = _.reduce(units, (memo, unit) => {
    memo[unit.path] = unit;
    return memo;
  }, {});
  const parents = (unit, chain = []) => unit.parentPath
    ? parents(dictionary[unit.parentPath], [unit, ...chain])
    : [unit, ...chain];

  const unitPaths = _.map(
    dictionary[unitPath]
      ? parents(dictionary[unitPath])
      : [],
    unit => [
      toPath(PAGE_URL.UNIT, { unitPath: unit.path }),
      unit.name,
      unit.description,
      unit.icon
    ]
  );
  if (!_.isEmpty(unitPaths)) {
    unitPaths.pop();
  };

  return unitPaths;
};

const Breadcrumbs = ({
  units,
  unitPath,
  pathname
}) => {
  const paths = [];
  _.reduce(pathname.substr(1).split('/'), (prev, curr, index) => {
    paths[index] = `${prev}/${curr}`;
    return paths[index];
  }, '');
  const filteredPaths = _.filter(paths, path => path !== '/unit' && path !== '/unittype' && !_.endsWith(path, '/predictor') && !_.endsWith(path, '/group') && !_.endsWith(path, '/trend'));

  const unitPaths = getUnitPaths(units, unitPath);

  const getRouteName = path => {
    if (matchesPath(path, PAGE_URL.UNIT)) {
      return _.chain(units)
        .find(['path', getPathParams(PAGE_URL.UNIT, path).unitPath])
        .get('name')
        .value() || null;
    };

    if (matchesPath(path, PAGE_URL.UNIT_TYPE_PREDICTOR)) {
      const params = getPathParams(PAGE_URL.UNIT_TYPE_PREDICTOR, path);
      return (
        <EntityText
          id={params.unitTypePredictorId}
          entityType={ENTITY_TYPE.UNIT_TYPE_PREDICTOR} />
      );
    };

    if (matchesPath(path, PAGE_URL.UNIT_TYPE)) {
      const params = getPathParams(PAGE_URL.UNIT_TYPE, path);
      return (
        <EntityText
          id={params.unitTypeId}
          entityType={ENTITY_TYPE.UNIT_TYPE} />
      );
    };

    if (matchesPath(path, PAGE_URL.ENTITY)) {
      const params = getPathParams(PAGE_URL.ENTITY, path);
      if (params.pageUrl === entityConfig[ENTITY_TYPE.UNIT].pageUrl) {
        return _.chain(units)
          .find(['id', params.entityId])
          .get('name')
          .value();
      };
      const entityType = _.findKey(
        entityConfig,
        config => config.pageUrl === params.pageUrl && !config.parentEntityType
      );
      return (
        <EntityText
          id={params.entityId}
          entityType={entityType} />
      );
    };

    const matchingItemPageEntityType = _.findKey(
      entityConfig,
      config => config.itemPageUrl && matchesPath(path, config.itemPageUrl)
    );
    if (matchingItemPageEntityType) {
      const params = getPathParams(entityConfig[matchingItemPageEntityType].itemPageUrl, path);
      return (
        <EntityText
          id={params.parentId}
          entityType={matchingItemPageEntityType} />
      );
    };

    return null;
  };

  const getRouteDescription = path => {
    if (matchesPath(path, PAGE_URL.UNIT)) {
      return _.chain(units)
        .find(['path', getPathParams(PAGE_URL.UNIT, path).unitPath])
        .get(ENTITY_PROPERTY.UNIT.DESCRIPTION)
        .value() || null;
    };

    if (matchesPath(path, PAGE_URL.ENTITY)) {
      const params = getPathParams(PAGE_URL.ENTITY, path);
      if (params.pageUrl === entityConfig[ENTITY_TYPE.UNIT].pageUrl) {
        return _.chain(units)
          .find(['id', params.entityId])
          .get('description')
          .value();
      };
    };

    return null;
  };

  const getRouteIcon = path => {
    if (matchesPath(path, PAGE_URL.UNIT)) {
      return _.chain(units)
        .find(['path', getPathParams(PAGE_URL.UNIT, path).unitPath])
        .get('icon')
        .value() || null;
    };

    if (matchesPath(path, PAGE_URL.ENTITY)) {
      const params = getPathParams(PAGE_URL.ENTITY, path);
      if (params.pageUrl === entityConfig[ENTITY_TYPE.UNIT].pageUrl) {
        return _.chain(units)
          .find(['id', params.entityId])
          .get('icon')
          .value();
      };
    };

    return null;
  };

  return (
    <Fragment>
      <div style={{
        padding: '0.5rem',
        paddingLeft: '160px',
        display: 'inline-block',
        height: '100%'
      }}>
        <Route path={PAGE_URL.NOT_HOME}>
          <Breadcrumb
            size='large'
            style={{
              display: 'flex',
              alignItems: 'center',
              height: '100%'
            }}>
            <Breadcrumb.Section
              as={QuerySyncLink}
              to={PAGE_URL.HOME}><Icon name='home' /></Breadcrumb.Section>
            {_.map(unitPaths, ([path, name, description, icon]) => (
              <BreadcrumbsItem
                routeName={name}
                key={path}
                description={description}
                icon={icon}
                url={path}
                pathname={pathname} />
            ))}
            {_.map(filteredPaths, path => (
              <Route
                key={path}
                path={_.chain(path).replace(')', '\\)').replace('(', '\\(').value()}
                render={({ match }) => (
                  <BreadcrumbsItem
                    url={match.url}
                    path={match.path}
                    isExact={match.isExact}
                    routeName={getRouteName(path)}
                    description={getRouteDescription(path)}
                    icon={getRouteIcon(path)}
                    pathname={pathname} />
                )} />
            ))}
          </Breadcrumb>
        </Route>
      </div>
    </Fragment>
  );
};

Breadcrumbs.displayName = 'Breadcrumbs';

export default Breadcrumbs;