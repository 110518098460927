import _ from 'lodash';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { injectIntl } from 'react-intl';
import { Dropdown, Image, Label } from 'semantic-ui-react';
import { Route, Switch } from 'react-router-dom';
import IsAuthenticated from '../../../containers/auth/IsAuthenticated';
import { AUTH_MENU_LOGOUT } from './AuthMenu.testIds';
import messages from './AuthMenu.messages';
import styles from './AuthMenu.styles';
import { ENTITY_PROPERTY, PAGE_URL, ROLE } from '../../../utils/constants';
import QuerySyncLink from '../../../containers/navigation/QuerySyncLink';
import { URL } from 'utils/api';
import IsInRole from 'containers/auth/IsInRole';

const AuthMenu = injectIntl(({
  id,
  name,
  tenantId,
  tenants,
  onClickTenant,
  intl: {
    formatMessage
  }
}) => {
  const currentTenant = _.find(tenants, [ENTITY_PROPERTY.TENANT.ID, tenantId]);
  return (
    <IsAuthenticated>
      <span
        style={styles.span}>
        <Label
          as={Dropdown}
          size='large'
          text={(
            <Fragment>
              <Image
                avatar
                crossorigin
                src={`${URL}api/user/${id}/image`} />
              {name}
            </Fragment>
          )}
          style={styles.nameLabel}>
          <Dropdown.Menu>
            <Switch>
              <Route path={PAGE_URL.EXPLORER}>
                <Dropdown.Item
                  as={QuerySyncLink}
                  to={PAGE_URL.HOME}
                  text={formatMessage(messages['prophes'])} />
              </Route>
              <IsInRole role={ROLE.USE_EXPLORER}>
                <Route>
                  <Dropdown.Item
                    as={QuerySyncLink}
                    to={PAGE_URL.EXPLORER}
                    text={formatMessage(messages['explorer'])} />
                </Route>
              </IsInRole>
            </Switch>
            <Dropdown.Item
              as={QuerySyncLink}
              data-testid={AUTH_MENU_LOGOUT}
              to={PAGE_URL.LOGOUT}
              text={formatMessage(messages['logout'])}
              icon='sign out' />
          </Dropdown.Menu>
        </Label>
        {_.size(tenants) > 1
          ? (
            <Label
              as={Dropdown}
              size='large'
              text={_.get(currentTenant, ENTITY_PROPERTY.TENANT.NAME)}
              style={styles.tenantLabel}>
              <Dropdown.Menu direction='left'>
                {_.chain(tenants)
                  .reject([ENTITY_PROPERTY.TENANT.ID, tenantId])
                  .map(({ id, name }) => (
                    <Dropdown.Item
                      key={id}
                      active={id === tenantId}
                      text={name}
                      icon='external alternate'
                      onClick={() => onClickTenant(id)} />
                  ))
                  .value()}
              </Dropdown.Menu>
            </Label>
          )
          : (
            <Label
              content={_.get(currentTenant, ENTITY_PROPERTY.TENANT.NAME)}
              size='large'
              style={styles.tenantLabel}>
            </Label>
          )}
      </span>
    </IsAuthenticated>
  );
});

AuthMenu.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  tenantId: PropTypes.string,
  tenants: PropTypes.arrayOf(PropTypes.exact({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  })),
  onClickTenant: PropTypes.func.isRequired
};

AuthMenu.displayName = 'AuthMenu';

export default AuthMenu;