import _ from 'lodash';
import { ofType } from 'redux-observable';
import { filter, withLatestFrom, map } from 'rxjs/operators';
import { formValueChanged, FORM_VALUE_CHANGED } from '../../../actions/entity';
import { ENTITY_FORM_TYPE, ENTITY_PROPERTY, ENTITY_TYPE } from '../../../../utils/constants';
import { getUnitTypeId } from '../../../../state/selectors/dashboard';
import * as fromState from '../../../../state/reducers';

export default (action$, state$) => action$.pipe(
  ofType(FORM_VALUE_CHANGED),
  filter(action =>
    action.entityType === ENTITY_TYPE.UNIT_TYPE_ATTRIBUTE &&
    action.payload.key === ENTITY_PROPERTY.UNIT_TYPE_ATTRIBUTE.VALUE_TYPE &&
    action.formType === ENTITY_FORM_TYPE.ADD_OR_EDIT),
  withLatestFrom(state$),
  map(([action, state]) => {
    const allUnitTypeInstances = fromState.getAll(state, ENTITY_TYPE.UNIT_TYPE_INSTANCE);
    const unitType = fromState.getById(state, ENTITY_TYPE.UNIT_TYPE, getUnitTypeId(state));
    const keyIds = _.chain(allUnitTypeInstances)
      .filter(uti => uti.type === unitType.id)
      .map('id')
      .value();

    let defaultMapping = {};
    _.forEach(keyIds, keyId => defaultMapping[keyId] = null);

    return formValueChanged(
      ENTITY_TYPE.UNIT_TYPE_ATTRIBUTE,
      ENTITY_FORM_TYPE.ADD_OR_EDIT,
      'sensorMappings',
      defaultMapping
    );
  })
);