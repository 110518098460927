import { Form } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import featureMessages from './AxesMenu.messages';
import availableFeatures from 'utils/i18n/shared/features';
import { messages as dateRangeMessages, quickRanges } from 'utils/dates';
import _ from 'lodash';
import { NO_ATTRIBUTE_ID } from 'utils/constants';
import { FEATURE, FEATURE_SETTINGS, VALUE_TYPE, INVERTED_THEME } from 'utils/constants';

export default injectIntl(function AxesMenu({
  sensors,
  attrs,
  onSetAttrs,
  features,
  onSetFeatures,
  windows,
  onSetWindows,
  intl: {
    formatMessage
  }
}) {
  const attrOptions = _.map(sensors, s => ({
    key: s.id,
    text: s.name,
    value: s.id
  }));
  const filteredFeatures = _.filter(_.values(FEATURE), f =>
    FEATURE_SETTINGS[f].VALUE_TYPE === VALUE_TYPE.FLOAT &&
    !FEATURE_SETTINGS[f].REQUIRES_INPUT);
  const featureOptions = _.map(filteredFeatures, f => ({
    key: f,
    text: formatMessage(availableFeatures[f]),
    value: f
  }));

  const windowOptions = _.map(quickRanges, text => ({
    text: formatMessage(dateRangeMessages[`quick.${text}`]),
    value: text,
    option: text
  }));

  const unusedAxisOption = {
    id: NO_ATTRIBUTE_ID,
    value: NO_ATTRIBUTE_ID,
    text: formatMessage(featureMessages['unusedAxisLabel'])
  };

  const axisIds = ['xAxis', 'yAxis', 'zAxis'];

  const setAttr = (dimensionIndex, val) => {
    let newAttrs = [...attrs];
    if (newAttrs[dimensionIndex] !== val) {
      newAttrs[dimensionIndex] = val;
      onSetAttrs(newAttrs);
    }
  };

  const setFeature = (dimensionIndex, val) => {
    let newFeatures = [...features];
    if (newFeatures[dimensionIndex] !== val) {
      newFeatures[dimensionIndex] = val;
      onSetFeatures(newFeatures);
    }
  };

  const setWindow = (dimensionIndex, val) => {
    let newWindows = [...windows];
    if (newWindows[dimensionIndex] !== val) {
      newWindows[dimensionIndex] = val;
      onSetWindows(newWindows);
    }
  };

  function AxisRow({ i, messages }) {
    return (
      <Form.Group widths='equal'>
        <Form.Select
          search
          label={formatMessage(messages[axisIds[i]])}
          value={attrs[i]}
          onChange={(_, data) => setAttr(i, data.value)}
          options={[unusedAxisOption].concat(attrOptions)} />
        <Form.Select
          label={formatMessage(messages['feature'])}
          value={features[i]}
          text={formatMessage(availableFeatures[features[i]])}
          onChange={(_, data) => setFeature(i, data.value)}
          options={featureOptions} />
        <Form.Select
          label={formatMessage(messages['window'])}
          value={windows[i]}
          text={formatMessage(dateRangeMessages[`quick.${windows[i]}`])}
          onChange={(_, data) => setWindow(i, data.value)}
          options={windowOptions} />
      </Form.Group>
    );
  }

  return (
    <div>
      <Form inverted={INVERTED_THEME}> {
        _.map([0, 1, 2], i => (
          <AxisRow
            key={i.toString()}
            i={i}
            messages={featureMessages} />
        ))
      }
      </Form>
    </div>
  );
});