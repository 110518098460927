import view, * as fromView from './view';
import compareOpen, * as fromCompareOpen from './compareOpen';
import trendIds, * as fromTrendIds from './trendIds';
import compareTrend, * as fromCompareTrend from './compareTrend';
import shared, * as fromShared from './shared';
import compareForm, * as fromCompareForm from './compareForm';
import trendCount, * as fromTrendCount from './trendCount';
import selectedTrends, * as fromSelectedTrends from './selectedTrends';
import isLoading, * as fromIsLoading from './isLoading';
import trendTimelineHeight, * as fromTrendTimelineHeight from './trendTimelineHeight';
import { combineReducers } from 'redux';
import { CHART_TYPE, CHART_VIEW, LOADING } from '../../../utils/constants';
import { RootState } from 'index';
import { HoverLineState } from './shared/trend/hoverLine';

export default combineReducers({
  view,
  compareOpen,
  trendIds,
  compareTrend,
  compareForm,
  selectedTrends,
  trendCount,
  trendTimelineHeight,
  shared: combineReducers({
    [CHART_TYPE.MAIN]: shared(CHART_TYPE.MAIN),
    [CHART_TYPE.COMPARE]: shared(CHART_TYPE.COMPARE)
  }),
  isLoading: combineReducers({
    [CHART_TYPE.MAIN]: isLoading(CHART_TYPE.MAIN),
    [CHART_TYPE.COMPARE]: isLoading(CHART_TYPE.COMPARE),
    [LOADING.TRENDS]: isLoading(LOADING.TRENDS)
  })
});

export const getView = (state: RootState): CHART_VIEW => fromView.getView(state.view);
export const getSelected = (state, chartType, selectionType) => fromShared.getSelected(state.shared[chartType], selectionType);
export const getQuickRange = (state: RootState, chartType: CHART_TYPE): string => fromShared.getQuickRange(state.shared[chartType]);
export const getFromDate = (state: RootState, chartType: CHART_TYPE): string => fromShared.getFromDate(state.shared[chartType]);
export const getToDate = (state: RootState, chartType: CHART_TYPE): string => fromShared.getToDate(state.shared[chartType]);
export const getGroup = (state, chartType) => fromShared.getGroup(state.shared[chartType]);
export const getHoverLine = (state: RootState, chartType: CHART_TYPE): HoverLineState => fromShared.getHoverLine(state.shared[chartType]);
export const getBrushStartTimestamp = (state, chartType: CHART_TYPE) => fromShared.getBrushStartTimestamp(state.shared[chartType]);
export const getBrushEndTimestamp = (state, chartType: CHART_TYPE) => fromShared.getBrushEndTimestamp(state.shared[chartType]);
export const getCopiedText = (state, chartType) => fromShared.getCopiedText(state.shared[chartType]);
export const getEditingFunction = (state, chartType) => fromShared.getEditingFunction(state.shared[chartType]);
export const getFunctionDescriptionEditText = (state, chartType) => fromShared.getFunctionDescriptionEditText(state.shared[chartType]);
export const getFeaturesToggle = (state, chartType) => fromShared.getFeaturesToggle(state.shared[chartType]);
export const getSelectedFeature = (state, chartType) => fromShared.getSelectedFeature(state.shared[chartType]);
export const getSelectedTrends = state => fromSelectedTrends.getSelectedTrends(state.selectedTrends);
export const getFeatureWindow = (state, chartType) => fromShared.getFeatureWindow(state.shared[chartType]);
export const getSelectedProperty = (state, chartType) => fromShared.getSelectedProperty(state.shared[chartType]);
export const getCompareOpen = state => fromCompareOpen.getCompareOpen(state.compareOpen);
export const getTrendIds = state => fromTrendIds.getTrendIds(state.trendIds);
export const getCompareTrend = (state: RootState): string | null => fromCompareTrend.getCompareTrend(state.compareTrend);
export const getCompareAll = state => fromCompareForm.getAll(state.compareForm);
export const getCompareFromDate = state => fromCompareForm.getFromDate(state.compareForm);
export const getCompareToDate = state => fromCompareForm.getToDate(state.compareForm);
export const getCompareLabels = state => fromCompareForm.getLabels(state.compareForm);
export const getTrendCount = state => fromTrendCount.getTrendCount(state.trendCount);
export const getTrendTimelineHeight = state => fromTrendTimelineHeight.getTrendTimelineHeight(state.trendTimelineHeight);
export const getIsLoading = (state, namespace) => fromIsLoading.getIsLoading(state.isLoading[namespace]);