import moment from 'moment';
import createCachedSelector from 're-reselect';
import * as fromState from '../../../reducers';

export const getFeatureWindowDuration = createCachedSelector(
  [
    fromState.getFeatureWindow
  ],
  featureWindow => moment.duration(featureWindow)
)(
  (state, chartType) => chartType
);