import createCachedSelector from 're-reselect';
import * as fromState from '../../../reducers';
import { Predictor } from 'types';

export const getPredictor = createCachedSelector(
  [
    state => fromState.getAll<Predictor>(state, 'predictor'),
    (state, chartType) => fromState.getGroup(state, chartType)
  ],
  (allPredictors, id) => allPredictors[id]
)(
  (state, chartType) => chartType
);