import _ from 'lodash';
import createCachedSelector from 're-reselect';
import * as chartSelectors from '../../../state/selectors/chart';
import createBucketedDataSelector from './createBucketedDataSelector';
import createDataSelector from './createDataSelector';

const getInputDescriptions = createCachedSelector(
  [chartSelectors.getInputs],
  inputs => _.chain(inputs)
    .map(input => [input, input])
    .fromPairs()
    .value()
)(
  (state, chartType) => chartType
);

export default createDataSelector(
  createBucketedDataSelector(chartSelectors.getInputData),
  chartSelectors.getInputSensors,
  getInputDescriptions,
  chartSelectors.getInputAlarmRanges,
  chartSelectors.getInputEnumerations,
  _.constant(1)
);