import { SET_ALARM_LEVELS, EDIT_ALARM_LEVEL } from '../../actions/alarm';

export default (state = [], action) => {
  switch (action.type) {
    case SET_ALARM_LEVELS:
      return action.payload.alarmLevels;
    case EDIT_ALARM_LEVEL:
      return [
        ...state.slice(0, action.payload.index),
        action.payload.severity,
        ...state.slice(action.payload.index + 1)
      ];
    default:
      return state;
  }
};

export const getAlarmLevels = state => state;