import { combineReducers } from 'redux';
import codes, * as fromCodes from './codes';
import row, * as fromRow from './row';
import total, * as fromTotal from './total';

export default combineReducers({
  codes,
  row,
  total
});

export const getCodes = state => fromCodes.getCodes(state.codes);
export const getRow = state => fromRow.getRow(state.row);
export const getTotal = state => fromTotal.getTotal(state.total);