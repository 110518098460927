import { Header, Segment, Icon } from 'semantic-ui-react';
import { defineMessages, injectIntl } from 'react-intl';
import { INVERTED_THEME } from '../../utils/constants';

const messages = defineMessages({
  'serviceUnavailable.message': {
    id: 'serviceUnavailable.message',
    defaultMessage: 'Service Unavailable'
  }
});

const ServiceUnavailablePage = injectIntl(({
  intl: {
    formatMessage
  }
}) => (
  <Segment
    basic
    textAlign='center'>
    <Header inverted={INVERTED_THEME}>
      <Icon name='cogs' />
      {formatMessage(messages['serviceUnavailable.message'])}
    </Header>
  </Segment>
));

ServiceUnavailablePage.displayName = 'ServiceUnavailablePage';

export default ServiceUnavailablePage;