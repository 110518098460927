import { ofType } from 'redux-observable';
import { switchMap, delay, map, filter } from 'rxjs/operators';
import { SET_COPIED_TEXT, setCopiedText } from '../../../actions/chart';
import { of } from 'rxjs';

export default action$ => action$.pipe(
  ofType(SET_COPIED_TEXT),
  switchMap(action => of(action).pipe(
    filter(action => action.payload.text !== null),
    delay(1000),
    map(() => setCopiedText(action.chartType, null))
  ))
);