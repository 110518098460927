import { SET_COMPARE_LABELS } from '../../../actions/chart';

export default (state = [], action) => {
  switch (action.type) {
    case SET_COMPARE_LABELS:
      return action.payload.value;
    default:
      return state;
  }
};

export const getLabels = state => state;