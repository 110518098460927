import { combineReducers } from 'redux';
import latestSensorValues, * as fromLatestSensorValues from './latestSensorValues';
import latestOutputValues, * as fromLatestOutputValues from './latestOutputValues';
import sensorRanges, * as fromSensorRanges from './sensorRanges';
import outputRanges, * as fromOutputRanges from './outputRanges';
import contents, * as fromContents from './contents';

const dataReducer = combineReducers({
  latestSensorValues,
  latestOutputValues,
  sensorRanges,
  outputRanges,
  contents
});

export default chartType => (state = {}, action) => {
  if (action.chartType && action.chartType !== chartType) {
    return state;
  }

  return dataReducer(state, action);
};

export const getLatestSensorValue = (state, id) => fromLatestSensorValues.getLatestSensorValue(state.latestSensorValues, id);
export const getAllLatestSensorValues = state => fromLatestSensorValues.getAllLatestSensorValues(state.latestSensorValues);
export const getLatestOutputValue = (state, group, id) => fromLatestOutputValues.getLatestOutputValue(state.latestOutputValues, group, id);
export const getAllLatestOutputValues = state => fromLatestOutputValues.getAllLatestOutputValues(state.latestOutputValues);
export const getAllSensorRanges = state => fromSensorRanges.getAllSensorRanges(state.sensorRanges);
export const getAllOutputRanges = state => fromOutputRanges.getAllOutputRanges(state.outputRanges);
export const getPredictor = state => fromContents.getPredictor(state.contents);
export const getFromDate = state => fromContents.getFromDate(state.contents);
export const getToDate = state => fromContents.getToDate(state.contents);
export const getSensors = state => fromContents.getSensors(state.contents);
export const getOutputs = state => fromContents.getOutputs(state.contents);