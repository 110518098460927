import { Table } from 'semantic-ui-react';
import styles from './HeatMapChart.styles';
import PropTypes from 'prop-types';

const HeatMapCell = ({
  highlighted,
  colour,
  onBlur,
  onClick,
  onFocus,
  onMouseEnter,
  onMouseLeave
}) => (
  <Table.Cell
    onBlur={onBlur}
    onClick={onClick}
    onFocus={onFocus}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
    className='custom-selectable'
    style={highlighted ? styles.highlightedCell(colour) : styles.cell(colour)} />
);

HeatMapCell.propTypes = {
  highlighted: PropTypes.bool.isRequired,
  colour: PropTypes.string.isRequired,
  onBlur: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  onMouseEnter: PropTypes.func.isRequired,
  onMouseLeave: PropTypes.func.isRequired
};

HeatMapCell.displayName = 'HeatMapCell';

export default HeatMapCell;