import _ from 'lodash';
import moment from 'moment';
import { ENTITY_PROPERTY, NEUTRAL_SEVERITY } from 'utils/constants';
import { Predictor, Sensor } from 'types';

const getFunctionSeverity = (
  predictor: Predictor,
  allLatestSensorValues: { [key: string]: number | null; },
  allSensors: { [key: string]: Sensor; }
) => _.chain(predictor.functionMappings)
  .map(({ sensor }) => {
    const latest = allLatestSensorValues[sensor];
    if (_.isNull(latest)) {
      return null;
    }

    const alarmRanges = _.get(allSensors[sensor], ENTITY_PROPERTY.SENSOR.ALARM_RANGES);
    return alarmRanges
      ? _.chain(alarmRanges)
        .filter((range, i) => (i === _.size(alarmRanges) - 1 || latest <= range.max) && (i === 0 || latest >= range.min))
        .map('severity')
        .max()
        .value()
      : NEUTRAL_SEVERITY;
  })
  .max()
  .value();

const getOutputSeverity = (
  predictor: Predictor,
  allLatestOutputValues: { [key: string]: number | null; }
) => _.chain(predictor.outputMappings)
  .map(({ output, alarmRanges }) => {
    const latest = allLatestOutputValues[`${predictor.id}/${output}`];

    if (_.isNull(latest)) {
      return null;
    };

    return alarmRanges
      ? _.chain(alarmRanges)
        .filter((range, i) => (i === _.size(alarmRanges) - 1 || latest <= range.max) && (i === 0 || latest >= range.min))
        .map('severity')
        .max()
        .value()
      : NEUTRAL_SEVERITY;
  })
  .max()
  .value();

export const getSeverity = (
  predictor: Predictor,
  allLatestSensorValues: { [key: string]: number | null; },
  allLatestOutputValues: { [key: string]: number | null; },
  allSensors: { [key: string]: Sensor; }
) => {
  if (predictor.mutedUntil && moment.utc(predictor.mutedUntil) > moment().utc()) {
    return null;
  }

  if (!predictor.type) {
    return getFunctionSeverity(predictor, allLatestSensorValues, allSensors);
  }

  return getOutputSeverity(predictor, allLatestOutputValues);
};