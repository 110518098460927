import { ofType } from 'redux-observable';
import { merge, of, concat, EMPTY } from 'rxjs';
import { debounceTime, filter, groupBy, mergeMap, switchMap, withLatestFrom, map, catchError } from 'rxjs/operators';
import * as api from '../../../utils/api';
import { FEATURE_WINDOW_CHANGED, setChartIsLoading, setFeatureWindow, SET_DATES, setBrushTimestamps } from '../../actions/chart';
import { setMultipleRanges, prependMultipleRanges, appendMultipleRanges } from '../../actions/data';
import * as fromState from '../../reducers';
import * as chartSelectors from '../../selectors/chart';
import { CHART_TYPE, CONTROLLER, TREND_TIMELINE_QUERY_PAGE_SIZE, ENTITY_TYPE, DATE_FORMAT, SENSOR_VALUE_DATE_FORMAT, ENTITY_PROPERTY } from '../../../utils/constants';
import { insertMany } from '../../actions/entity';
import _ from 'lodash';
import moment from 'moment';
import { takeUntilAppReset } from 'utils/helpers';

const OVERLAP_TYPE = {
  RIGHT: 'right',
  LEFT: 'left',
  DISJOINT: 'disjoint',
  SUBSET: 'subset',
  SUPERSET: 'superset'
};

const getOverlapType = dates => {
  const [prevFrom, prevTo, newFrom, newTo] = dates;
  const sorted = _.sortBy(dates);
  if (_.isEqual(sorted, [prevFrom, prevTo, newFrom, newTo])) {
    return OVERLAP_TYPE.DISJOINT;
  } else if (_.isEqual(sorted, [prevFrom, newFrom, prevTo, newTo])) {
    return OVERLAP_TYPE.RIGHT;
  } else if (_.isEqual(sorted, [prevFrom, newFrom, newTo, prevTo])) {
    return OVERLAP_TYPE.SUBSET;
  } else if (_.isEqual(sorted, [newFrom, prevFrom, prevTo, newTo])) {
    return OVERLAP_TYPE.SUPERSET;
  } else if (_.isEqual(sorted, [newFrom, prevFrom, newTo, prevTo])) {
    return OVERLAP_TYPE.LEFT;
  } else if (_.isEqual(sorted, [newFrom, newTo, prevFrom, prevTo])) {
    return OVERLAP_TYPE.DISJOINT;
  };
};

const WINDOW_TYPE = {
  REPLACE: 'replace',
  PREPEND: 'prepend',
  APPEND: 'append'
};

const getWindowsForUnchangedGroup = dates => {
  const overlapType = getOverlapType(dates);
  const [prevFrom, prevTo, newFrom, newTo] = dates;
  let windows;
  switch (overlapType) {
    case OVERLAP_TYPE.DISJOINT:
      windows = [[newFrom, newTo, WINDOW_TYPE.REPLACE]];
      break;
    case OVERLAP_TYPE.RIGHT:
      windows = [[prevTo, newTo, WINDOW_TYPE.APPEND, newFrom]];
      break;
    case OVERLAP_TYPE.SUBSET:
      windows = [];
      break;
    case OVERLAP_TYPE.SUPERSET:
      windows = [[newFrom, prevFrom, WINDOW_TYPE.PREPEND, newTo], [prevTo, newTo, WINDOW_TYPE.APPEND, newFrom]];
      break;
    case OVERLAP_TYPE.LEFT:
      windows = [[newFrom, prevFrom, WINDOW_TYPE.PREPEND, newTo]];
      break;
    default:
      windows = [];
  };

  return _.filter(windows, ([from, to]) => from !== to);
};

const getWindowFetchOptions = ([fromDate, toDate, windowType, cutoffDate]) => {
  switch (windowType) {
    case WINDOW_TYPE.REPLACE:
      return [
        setMultipleRanges,
        fromDate,
        toDate
      ];
    case WINDOW_TYPE.PREPEND:
      return [
        prependMultipleRanges,
        fromDate,
        moment.utc(cutoffDate).format(SENSOR_VALUE_DATE_FORMAT)
      ];
    case WINDOW_TYPE.APPEND:
    default:
      return [
        appendMultipleRanges,
        moment.utc(cutoffDate).format(SENSOR_VALUE_DATE_FORMAT),
        toDate
      ];
  };
};

const fetchWindow = (action$, window, group, chartType, token) => {
  const [
    actionCreator,
    actionFrom,
    actionTo
  ] = getWindowFetchOptions(window);
  const [fromDate, toDate, , , sensors, outputs] = window;
  if (sensors && _.isEmpty(sensors) && outputs && _.isEmpty(outputs)) {
    return EMPTY;
  };
  return api.getWindow({
    predictor: group,
    fromDate: moment.utc(fromDate).format(DATE_FORMAT),
    toDate: moment.utc(toDate).format(DATE_FORMAT),
    sensors,
    outputs,
    token
  }).pipe(
    takeUntilAppReset(action$),
    mergeMap(({ sensors, outputs }) => of(
      actionCreator(
        chartType,
        sensors,
        group,
        outputs,
        actionFrom,
        actionTo
      )
    ))
  );
};

const getWindows = (state, chartType, dates) => {
  const [prevFrom, prevTo, newFrom, newTo] = dates;
  const group = fromState.getGroup(state, chartType);
  if (group === fromState.getDataPredictor(state, chartType)) {
    return getWindowsForUnchangedGroup(dates);
  };
  if (prevFrom === newFrom && prevTo === newTo) {
    const dataSensors = fromState.getDataSensors(state, chartType);
    const dataOutputs = _.chain(fromState.getDataOutputs(state, chartType))
      .map(output => _.split(output, '/'))
      .filter(([predictor]) => predictor === group)
      .map(_.last)
      .value();
    const sensorIds = [
      ..._.chain(chartSelectors.getInputSensors(state, chartType))
        .values()
        .map(ENTITY_PROPERTY.SENSOR.ID)
        .value(),
      ...chartSelectors.getFunctionSensorIds(state, chartType)
    ];
    const outputNames = chartSelectors.getOutputs(state, chartType);
    const sensors = _.difference(sensorIds, dataSensors);
    const outputs = _.difference(outputNames, dataOutputs);
    return [[newFrom, newTo, WINDOW_TYPE.REPLACE, null, sensors, outputs]];
  };
  return [[newFrom, newTo, WINDOW_TYPE.REPLACE]];
};

export default (action$, state$) => action$.pipe(
  ofType(SET_DATES, FEATURE_WINDOW_CHANGED),
  withLatestFrom(state$),
  filter(([action, state]) => !action.payload.noDataFetch && fromState.getGroup(state, action.chartType)),
  groupBy(([action]) => action.chartType),
  mergeMap(group => group.pipe(
    debounceTime(100),
    switchMap(([action, state]) => {
      const prevFrom = fromState.getDataFromDate(state, action.chartType);
      const prevTo = fromState.getDataToDate(state, action.chartType);
      const newDataFrom = chartSelectors.getWindowFromDate(state, action.chartType).toISOString();
      const newDataTo = moment.utc(fromState.getToDate(state, action.chartType), DATE_FORMAT).toISOString();
      const newDisplayFrom = moment.utc(fromState.getFromDate(state, action.chartType), DATE_FORMAT).unix();
      const newDisplayTo = moment.utc(fromState.getToDate(state, action.chartType), DATE_FORMAT).unix();
      const windows = getWindows(state, action.chartType, [prevFrom, prevTo, newDataFrom, newDataTo]);
      const actions = action.type === FEATURE_WINDOW_CHANGED
        ? [setFeatureWindow(action.chartType, action.payload.value)]
        : [];
      return merge(
        of(
          ...prevFrom === newDataFrom && prevTo === newDataTo
            ? []
            : [setBrushTimestamps(action.chartType, newDisplayFrom, newDisplayTo)],
          setChartIsLoading(action.chartType, true)
        ),
        ...action.chartType === CHART_TYPE.COMPARE
          ? [
            api.query({
              controller: CONTROLLER.TREND,
              page: 0,
              pageSize: TREND_TIMELINE_QUERY_PAGE_SIZE,
              query: chartSelectors.getTimelineTrendQuery(state, action.chartType),
              token: fromState.getUser(state).token
            }).pipe(
              takeUntilAppReset(action$),
              map(response => insertMany(ENTITY_TYPE.TREND, response.items)),
              catchError(api.onError)
            )
          ]
          : [],
        concat(
          ..._.map(
            windows,
            window => fetchWindow(
              action$,
              window,
              fromState.getGroup(state, action.chartType),
              action.chartType,
              fromState.getUser(state).token
            )
          ),
          of(
            ...actions,
            setChartIsLoading(action.chartType, false)
          )
        )
      );
    })
  ))
);