import { ConnectedRouter as Router } from 'connected-react-router';
import { Component } from 'react';
import { IntlProvider } from 'react-intl';
import { Route, Switch } from 'react-router-dom';
import Routes from './components/navigation/Routes';
import ExplorerRoutes from './components/navigation/ExplorerRoutes';
import translations from './utils/i18n/locales';
import { LOCALE, PAGE_URL, ROLE } from './utils/constants';
import IsInRole from 'containers/auth/IsInRole';

export default class AppWrapper extends Component {
  render() {
    const locale = LOCALE;
    return (
      <IntlProvider
        locale={locale}
        key={locale}
        messages={translations[locale]}
        textComponent='span'>
        <Router history={this.props.history}>
          <Switch>
            <Route path={PAGE_URL.EXPLORER}>
              <IsInRole role={ROLE.USE_EXPLORER}>
                <ExplorerRoutes />
              </IsInRole>
            </Route>
            <Route path={PAGE_URL.HOME}>
              <Routes />
            </Route>
          </Switch>
        </Router>
      </IntlProvider>
    );
  }
};