import _ from 'lodash';
import createCachedSelector from 're-reselect';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import EntityTable from '../../components/entity/EntityTable';
import { deleteById, performSearch, setSort, toggleModal, openEntity } from '../../state/actions/entity';
import * as fromState from '../../state/reducers';
import { ENTITY_CONFIG, PAGE_URL } from '../../utils/constants';
import entityConfig from '../../utils/entities';
import { transformEntityTypeSelectors, toPath } from '../../utils/helpers';
import { getUnitPaths } from '../../state/selectors/entity';
import { querySyncPush } from '../../state/actions/navigation';
import { compose, withHandlers } from 'recompose';

const mapStateToProps = createCachedSelector(
  [
    ...transformEntityTypeSelectors([
      fromState.getEntityIsLoading,
      fromState.getIsDeleting,
      fromState.getAllIds,
      fromState.getAll,
      (state, entityType) => entityConfig[entityType][ENTITY_CONFIG.MESSAGES],
      (state, entityType) => entityConfig[entityType][ENTITY_CONFIG.NAVIGATE_ON_CLICK],
      (state, entityType) => entityConfig[entityType][ENTITY_CONFIG.OPEN_ON_CLICK],
      (state, entityType) => entityConfig[entityType][ENTITY_CONFIG.PAGE_URL],
      (state, entityType) => entityConfig[entityType][ENTITY_CONFIG.NO_ACTIONS],
      fromState.getModals,
      fromState.getUser,
      fromState.getSort,
      getUnitPaths
    ]),
    (state, { ids }) => ids,
    (state, { entityType }) => entityType,
    state => fromState.getAuthGroupId(state)
  ],
  (
    isLoading,
    isDeleting,
    allIds,
    allById,
    entityMessages,
    navigateOnClick,
    openOnClick,
    pageUrl,
    noActions,
    modals,
    user,
    sort,
    unitPaths,
    ids,
    entityType,
    authGroupId
  ) => ({
    isLoading,
    isDeleting,
    rows: _.chain(ids || allIds)
      .map(id => allById[id])
      .filter()
      .value(),
    entityMessages,
    modals,
    columns: entityConfig[entityType].columns,
    navigateOnClick,
    openOnClick,
    noActions,
    getItemUrl: entity => entityConfig[entityType][ENTITY_CONFIG.ITEM_URL]
      ? entityConfig[entityType][ENTITY_CONFIG.ITEM_URL](entity, unitPaths)
      : toPath(PAGE_URL.ENTITY, {
        pageUrl,
        entityId: entity.id
      }),
    actionsDisabledForIds: [user.id, authGroupId],
    sort
  })
)(
  (state, { entityType }) => entityType
);

const mapDispatchToProps = (dispatch, { entityType, parentId }) => {
  return {
    ...bindActionCreators({
      ..._.mapValues({
        toggleModal,
        deleteById,
        onOpenEntity: openEntity
      }, actionCreator => _.partial(actionCreator, entityType)),
      onRowClick: url => querySyncPush(url)
    }, dispatch),
    clickSortableHeader(sort) {
      dispatch(setSort(entityType, sort));
      dispatch(performSearch(entityType, parentId));
    }
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    onClickSortableHeader: ({ clickSortableHeader, sort }) => by => clickSortableHeader({
      by,
      direction: by === sort.by
        ? {
          [null]: 'descending',
          descending: 'ascending',
          ascending: null
        }[sort.direction]
        : 'descending'
    })
  })
)(EntityTable);