import { SET_TENANT } from '../../actions/auth';

const getDefaultValue = () => {
  return window.localStorage.user
    ? JSON.parse(window.localStorage.user).tenantId || null
    : null;
};

export default (state, action) => {
  switch (action.type) {
    case SET_TENANT:
      return action.payload.tenantId;
    default:
      return state || getDefaultValue();
  }
};

export const getTenant = state => state;