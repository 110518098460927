import predictors, * as fromPredictors from './predictors';
import muteModal, * as fromMuteModal from './muteModal';
import loadingPredictors, * as fromLoadingPredictors from './loadingPredictors';
import view, * as fromView from './view';
import unitPath, * as fromUnitPath from './unitPath';
import cardSize, * as fromCardSize from './cardSize';
import showCardDetails, * as fromShowCardDetails from './showCardDetails';
import collapsedDecks, * as fromCollapsedDecks from './collapsedDecks';
import { combineReducers } from 'redux';

export default combineReducers({
  predictors,
  loadingPredictors,
  view,
  unitPath,
  modals: combineReducers({
    mute: muteModal('mute'),
    unmute: muteModal('unmute')
  }),
  cardSize,
  showCardDetails,
  collapsedDecks
});

export const getPredictors = state => fromPredictors.getPredictors(state.predictors);
export const getLoadingPredictors = (state, unitId) => fromLoadingPredictors.getLoadingPredictors(state.loadingPredictors, unitId);
export const getMuteModalId = (state, modal) => fromMuteModal.getId(state.modals[modal]);
export const getMuteModalOpen = (state, modal) => fromMuteModal.getOpen(state.modals[modal]);
export const getMuteModalUntil = state => fromMuteModal.getUntil(state.modals.mute);
export const getMuteModalIsSaving = (state, modal) => fromMuteModal.getIsSaving(state.modals[modal]);
export const getView = state => fromView.getView(state.view);
export const getUnitPath = state => fromUnitPath.getUnitPath(state.unitPath);
export const getCardSize = state => fromCardSize.getCardSize(state.cardSize);
export const getShowCardDetails = state => fromShowCardDetails.getShowCardDetails(state.showCardDetails);
export const getCollapsedDecks = state => fromCollapsedDecks.getCollapsedDecks(state.collapsedDecks);