import { CSSProperties, MouseEvent } from 'react';

interface Props {
  onMouseDown: (e: MouseEvent) => void;
  className?: string;
  style?: CSSProperties;
}

export default function Resizer({
  onMouseDown,
  className,
  style
}: Props) {
  return (
    <span
      role='presentation'
      className={className}
      style={style}
      onMouseDown={event => onMouseDown(event)} />
  );
}