import { ofType } from 'redux-observable';
import { filter, map } from 'rxjs/operators';
import { ADD_FULFILLED, toggleModal } from '../../actions/entity';
import entityConfig from '../../../utils/entities';
import { ENTITY_CONFIG } from 'utils/constants';

export default action$ => action$.pipe(
  ofType(ADD_FULFILLED),
  filter(action => entityConfig[action.entityType][ENTITY_CONFIG.IS_CONNECTOR]),
  map(action => toggleModal(action.entityType, 'accessToken', action.payload.entity.id))
);