import { RootState } from 'index';
import { Fragment, ReactNode } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Role } from 'types';
import * as fromState from '../../state/reducers';

interface InputProps {
  role?: Role;
  children: ReactNode;
}

const mapStateToProps = (state: RootState, { role, children }: InputProps) => ({
  isInRole: fromState.getIsInRole(state, role),
  children: children
});

const connector = connect(mapStateToProps);

interface Props extends ConnectedProps<typeof connector> {
  isInRole: boolean;
  children: ReactNode;
}

export default connector(({
  isInRole,
  children
}: Props) => {
  if (isInRole) {
    return (
      <Fragment>
        {children}
      </Fragment>
    );
  }
  else {
    return <></>;
  }
});