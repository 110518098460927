import { SET_TREND_COUNT } from '../../actions/chart';

export default (state = 0, action) => {
  switch (action.type) {
    case SET_TREND_COUNT:
      return action.payload.count;
    default:
      return state;
  }
};

export const getTrendCount = state => state;