import _ from 'lodash';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DashboardCard from '../../components/dashboard/DashboardCard';
import * as fromState from '../../state/reducers';
import { ENTITY_TYPE, CHART_TYPE, ENTITY_MODAL, NEUTRAL_SEVERITY } from '../../utils/constants';
import { deleteUnitTypeAttribute, toggleModal } from '../../state/actions/entity';
import { getColourFromSeverity, getSensorSeverity } from '../../utils/helpers';

const getSensorIdsForAttribute = createSelector(
  [
    (state, attr) => attr,
    state => fromState.getAll(state, ENTITY_TYPE.UNIT_TYPE_INSTANCE)
  ],
  (attr, allUnitTypeInstances) => {
    _.chain(allUnitTypeInstances)
      .filter(instance => instance.type === attr.unitType && _.has(instance.settings, attr.id))
      .map(instance => instance.settings[attr.id])
      .value();
  }
);

const mapStateToProps = createSelector(
  [
    (state, { unitTypeAttribute }) => unitTypeAttribute,
    (state, { unitTypeAttribute }) => _.map(
      getSensorIdsForAttribute(state, unitTypeAttribute),
      sensorId => fromState.getById(state, ENTITY_TYPE.SENSOR, sensorId)
    ),
    state => fromState.getAllLatestSensorValues(state, CHART_TYPE.MAIN),
    state => fromState.getShowCardDetails(state)
  ],
  (unitTypeAttribute, sensors, allLatestSensorValues, showDetails) => {
    const severity = _.chain(sensors)
      .map(sensor => {
        const isDataFetchedForSensor = _.has(allLatestSensorValues, sensor.id);
        return isDataFetchedForSensor
          ? getSensorSeverity(allLatestSensorValues[sensor.id], sensor.alarmRanges)
          : NEUTRAL_SEVERITY;
      })
      .max()
      .value();

    return ({
      id: unitTypeAttribute.id,
      name: unitTypeAttribute.name,
      description: unitTypeAttribute.description,
      fill: getColourFromSeverity(severity),
      active: true,
      noModel: true,
      canMute: false,
      canDelete: true,
      canConfigure: false,
      entityType: ENTITY_TYPE.UNIT_TYPE_ATTRIBUTE,
      showDetails
    });
  }

);

const mapDispatchToProps = (dispatch, { unitTypeAttribute }) => bindActionCreators({
  onClickDelete: () => toggleModal(ENTITY_TYPE.UNIT_TYPE_ATTRIBUTE, ENTITY_MODAL.DELETE, unitTypeAttribute.id),
  onDelete: () => deleteUnitTypeAttribute(unitTypeAttribute)
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DashboardCard);