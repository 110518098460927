import _ from 'lodash';
import { Fragment } from 'react';
import { injectIntl } from 'react-intl';
import { Form } from 'semantic-ui-react';
import { EMPTY_STRING, SELECT_LISTS } from '../../../utils/constants';
import { selectListToOptions } from '../../../utils/helpers';
import TypedList from '../TypedList';
import styles from './ListElement.styles';

const ON_ADD_ITEM = 'ListElement/ON_ADD_ITEM';
const onAddItem = item => ({
  type: ON_ADD_ITEM,
  payload: {
    item
  }
});

const ON_EDIT_ITEM = 'ListElement/ON_EDIT_ITEM';
const onEditItem = (index, key, value) => ({
  type: ON_EDIT_ITEM,
  payload: {
    index,
    key,
    value
  }
});

const ON_REMOVE_ITEM = 'ListElement/ON_REMOVE_ITEM';
const onRemoveItem = index => ({
  type: ON_REMOVE_ITEM,
  payload: {
    index
  }
});

const listElementReducer = (state, action) => {
  switch (action.type) {
    case ON_ADD_ITEM:
      return [...state, action.payload.item];
    case ON_EDIT_ITEM:
      return [
        ...state.slice(0, action.payload.index),
        {
          ...state[action.payload.index],
          [action.payload.key]: action.payload.value
        },
        ...state.slice(action.payload.index + 1)
      ];
    case ON_REMOVE_ITEM:
      return [
        ...state.slice(0, action.payload.index),
        ...state.slice(action.payload.index + 1)
      ];
    default:
      return state;
  };
};

const ListElement = injectIntl(({
  label,
  context,
  state = [],
  form,
  errorPath,
  errorFields,
  getErrorPath,
  disableAdd,
  scrollContextRef,
  selectContextLists,
  onChange,
  intl: {
    formatMessage
  }
}) => {
  return (
    <Fragment>
      <Form.Field
        style={styles.labelField}
        error={_.includes(errorFields, errorPath)}>
        <label>{label}</label>
      </Form.Field>
      <TypedList
        list={state}
        context={context}
        form={_.map(form, element => {
          const selectList = SELECT_LISTS[element.selectListName];
          return {
            ...element,
            options: selectList
              ? selectListToOptions(selectList, formatMessage)
              : element.options
          };
        })}
        types={[]}
        disableAdd={disableAdd}
        errorFields={errorFields}
        getErrorPath={getErrorPath}
        errorPathPrefix={errorPath}
        scrollContextRef={scrollContextRef}
        getUnitsOfMeasurement={() => EMPTY_STRING}
        selectContextLists={selectContextLists}
        onAddItem={item => onChange(
          listElementReducer(state, onAddItem(item)),
          errorPath
        )}
        onChangeItem={(itemIndex, key, value) => onChange(
          listElementReducer(state, onEditItem(itemIndex, key, value)),
          getErrorPath(itemIndex, key)
        )}
        onRemoveItem={itemIndex => onChange(
          listElementReducer(state, onRemoveItem(itemIndex)),
          getErrorPath(itemIndex)
        )} />
    </Fragment>
  );
});

ListElement.displayName = 'ListElement';

export default ListElement;