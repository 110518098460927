import AlarmRangesModal from '../../components/alarm/AlarmRangesModal';
import { connect } from 'react-redux';
import * as fromState from '../../state/reducers';
import { toggleModal } from '../../state/actions/entity';

const mapStateToProps = (state, { id }) => ({
  open: fromState.getModalOpenById(state, 'sensor', 'alarm', id),
  alarmRanges: fromState.getAlarmRanges(state),
  isSaving: fromState.getIsEditing(state, 'sensor'),
  isValid: fromState.getAlarmRangesValid(state)
});

const mapDispatchToProps = {
  onClose: id => toggleModal('sensor', 'alarm', id)
};

export default connect(mapStateToProps, mapDispatchToProps)(AlarmRangesModal);