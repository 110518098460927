import _ from 'lodash';
import createCachedSelector from 're-reselect';
import getLines from './getLines';

export default createCachedSelector(
  [
    getLines
  ],
  lines => {
    const allPositions = _.chain(lines)
      .flatMap(line => line.points)
      .map(point => point.position)
      .value();
    if (_.isEmpty(allPositions)) {
      return null;
    };
    const minLatitude = _.minBy(allPositions, _.first);
    const maxLatitude = _.maxBy(allPositions, _.first);
    const minLongitude = _.minBy(allPositions, _.last);
    const maxLongitude = _.maxBy(allPositions, _.last);
    return [
      [minLatitude, minLongitude],
      [minLatitude, maxLongitude],
      [maxLatitude, maxLongitude],
      [maxLatitude, minLongitude]
    ];
  }
)(
  (state, chartType) => chartType
);