import { defineMessages } from 'react-intl';

export default defineMessages({
  'new': {
    id: 'status.new',
    defaultMessage: 'New'
  },
  'historical': {
    id: 'status.historical',
    defaultMessage: 'Historical'
  },
  'live': {
    id: 'status.live',
    defaultMessage: 'Live'
  },
  'inProgress': {
    id: 'status.inProgress',
    defaultMessage: 'In Progress'
  },
  'complete': {
    id: 'status.complete',
    defaultMessage: 'Complete'
  },
  'error': {
    id: 'status.error',
    defaultMessage: 'Error'
  }
});